import * as React from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import searchIcon from 'Assets/pictures/searchIcon.svg';

export default function InputAdornments(props) {
  return (
    <div>
      <TextField
        className="lawp-article-blog-page-search-textfield"
        id="input-with-icon-textfield"
        label={null}
        data-cy={props.data_cy}
        placeholder={LPTranslate('AP_Search')}
        value={props.value}
        onChange={props.onChange}
        InputProps={{
          startAdornment: (
            <InputAdornment
              style={{
                marginLeft: '5px',
              }}
              position="start"
            >
              <img src={searchIcon} alt="search" />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
    </div>
  );
}
