import { createSlice } from '@reduxjs/toolkit';
import registerSteps from 'Services/registerSteps';

const initialState = {
  value: {
    clientStepper: 0,
    lawyerStepper: 0,
    title: '',
    salutation: '',
    firstName: '',
    lastName: '',
    email: '',
    userEmail: '',
    roleEmail: '',
    lawyerSelected: null,
  },
};

const registerSlice = createSlice({
  name: 'registerStepper',
  initialState: initialState,
  reducers: {
    addTitle: (state, action) => {
      if (action.payload !== null) {
        state.value = { ...state.value, title: action.payload };
      }
    },
    addSalutation: (state, action) => {
      if (action.payload !== null) {
        state.value = { ...state.value, salutation: action.payload };
      }
    },
    addFirstname: (state, action) => {
      if (action.payload !== null) {
        state.value = { ...state.value, firstName: action.payload };
      }
    },
    addLastname: (state, action) => {
      if (action.payload !== null) {
        state.value = { ...state.value, lastName: action.payload };
      }
    },
    addRegisterEmail: (state, action) => {
      if (action.payload !== null) {
        state.value = { ...state.value, email: action.payload };
      }
    },
    addUserEmail: (state, action) => {
      if (action.payload !== null) {
        state.value = { ...state.value, userEmail: action.payload };
      }
    },
    addRoleEmail: (state, action) => {
      if (action.payload !== null) {
        state.value = { ...state.value, roleEmail: action.payload };
      }
    },
    addLawyerSelected: (state, action) => {
      if (action.payload !== null) {
        state.value = { ...state.value, lawyerSelected: action.payload };
      }
    },
    setClientStepper: (state, action) => {
      if (action.payload !== null) {
        state.value = { ...state.value, clientStepper: action.payload };
      }
    },
    setLawyerStepper: (state, action) => {
      if (action.payload !== null) {
        state.value = { ...state.value, lawyerStepper: action.payload };
        for (let i = 0; i < registerSteps.length; i++) {
          registerSteps[i].isChecked = false;
        }
        for (let i = 0; i < action.payload - 1; i++) {
          registerSteps[i].isChecked = true;
        }
      }
    },
    resetRegister: (state) => {
      state.value = {
        clientStepper: 0,
        lawyerStepper: 0,
        title: '',
        salutation: '',
        firstName: '',
        lastName: '',
        email: '',
        userEmail: '',
        roleEmail: '',
        lawyerSelected: null,
      };
    },
  },
});
export const {
  addTitle,
  addSalutation,
  addFirstname,
  addLastname,
  addRegisterEmail,
  addUserEmail,
  addRoleEmail,
  addLawyerSelected,
  setClientStepper,
  setLawyerStepper,
  resetRegister,
} = registerSlice.actions;
export default registerSlice.reducer;
