import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import SimpleTextField from 'Components/Inputs/SimpleTextField';
import SetLawyerNewPasswordModal from 'Components/Modals/SetLawyerNewPasswordModal';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import { VERIFY_USER_PASSWORD } from 'Services/Queries/common';

import styles from 'Assets/styles/updateLawyerDetails/UpdateLawyerPasswordModal.module.scss';

const UpdateLawyerPasswordModal = () => {
  const [verifyUserPassword, { data: verifyUserPasswordData, loading }] =
    useMutation(VERIFY_USER_PASSWORD);
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);
  const navigate = useNavigate();

  const [shownModal, setShownModal] = useState(0);

  const [formValues, setFormValues] = useState({ currentPassword: '' });
  const [formErrors, setFormErrors] = useState({ currentPassword: '' });
  const [errorMessage, setErrorMessage] = useState('');

  const [open, setOpen] = useState(false);

  const resetState = () => {
    setFormValues({ currentPassword: '' });
    setFormErrors({ currentPassword: '' });
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setShownModal(0);
    setOpen(false);
    resetState();
  };

  const handleChangeModal = (nr) => {
    setShownModal(nr);
  };

  const handleInputChange = (inputName, value) => {
    switch (inputName) {
      case 'currentPassword':
        setFormValues({ ...formValues, currentPassword: value });
        break;

      default:
        break;
    }
  };

  const checkPassword = async (e) => {
    e.preventDefault();

    let isError = false;
    let newInputErrors = { currentPassword: '' };

    if (formValues.currentPassword.length === 0) {
      isError = true;
      newInputErrors.currentPassword = LPTranslate(
        'Error_Actual_Password_Required'
      );
    } else if (formValues.currentPassword.length < 8) {
      isError = true;
      newInputErrors.currentPassword = LPTranslate(
        'Error_Actual_Password_Min_Length'
      );
    }

    setFormErrors(newInputErrors);

    if (isError) return;

    try {
      const returnData = await verifyUserPassword({
        variables: {
          email: clientInfoRedux.email,
          password: formValues.currentPassword,
        },
      });

      if (returnData.data.verifyUserPassword.error.length > 0) {
        setFormErrors({ currentPassword: 'Invalid is invalid' });
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (verifyUserPasswordData) {
      if (verifyUserPasswordData.verifyUserPassword.message !== '') {
        setFormErrors({ currentPassword: '' });
        setFormValues({ currentPassword: '' });
        handleChangeModal(1);
      } else if (verifyUserPasswordData.verifyUserPassword.error) {
        setErrorMessage(verifyUserPasswordData.verifyUserPassword.error);
      }
    }
  }, [verifyUserPasswordData, loading]);

  return (
    <>
      <div className={styles['button']}>
        <button
          className={styles['button-base']}
          onClick={() => handleOpen()}
          data-cy="update-password-button"
        >
          <p className={styles['button-text']}>
            {LPTranslate('CDPM_Step_Title')}
          </p>
        </button>
      </div>

      <Modal
        className={styles['update-password-modal']}
        open={open}
        onClose={() => handleClose()}
      >
        <Box className={styles['update-password-box']}>
          {shownModal === 0 ? (
            <>
              <div className={styles['modal-container']}>
                <div className={styles['stepper-content']}>
                  <div className={styles['step-icon-base']}>
                    <div className={styles['selected-icon-content']}>
                      <div className={styles['selected-dot']} />
                    </div>
                  </div>

                  <div className={styles['unselected-line']}></div>

                  <div className={styles['unselected-step-icon-base']}>
                    <div className={styles['unselected-icon-content']}>
                      <div className={styles['unselected-dot']} />
                    </div>
                  </div>
                </div>

                <div className={styles['modal-content']}>
                  <div className={styles['modal-text-and-supporting-text']}>
                    <p className={styles['modal-text']}>
                      {LPTranslate('CDPM_Step_Title')}
                    </p>
                    <p className={styles['modal-supporting-text']}>
                      {LPTranslate('CDPM_Step_Description_1')}
                    </p>
                  </div>
                  <form
                    className={styles['frame1030-input-field']}
                    id="update-password-form"
                    onSubmit={checkPassword}
                  >
                    <SimpleTextField
                      data_cy="password-input"
                      title={LPTranslate('CDPM_Step_1_Input_Title')}
                      placeholder="••••••••"
                      onChange={(value) => {
                        handleInputChange('currentPassword', value);
                        setFormErrors({ ...formErrors, currentPassword: '' });
                      }}
                      value={formValues.currentPassword}
                      textFieldWidthPercent={100}
                      type="password"
                      errorMessage={formErrors.currentPassword}
                    />
                  </form>

                  <div
                    style={{
                      marginTop:
                        formErrors.currentPassword.length > 40 ? '15px' : '',
                    }}
                    className={styles['row']}
                  >
                    <div className={styles['forgot-button']}>
                      <button
                        data-cy="forgot-password-button"
                        className={styles['forgot-button-base']}
                        onClick={() => navigate('/resetForgotPassword')}
                      >
                        <p className={styles['forgot-button-text']}>
                          {LPTranslate('CDPM_Step_1_Forgot_Password')}
                        </p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles['actions']}>
                <div className={styles['cancel-button']}>
                  <button
                    data-cy="cancel-button"
                    className={styles['cancel-button-base']}
                    onClick={() => handleClose()}
                  >
                    <p className={styles['cancel-button-text']}>
                      {LPTranslate('Button_Cancel')}
                    </p>
                  </button>
                  <button
                    data-cy="submit-button"
                    className={styles['next-button-base']}
                    type="submit"
                    form="update-password-form"
                  >
                    <p className={styles['next-button-text']}>
                      {LPTranslate('Button_Next')}
                    </p>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <SetLawyerNewPasswordModal
              shownModal={shownModal}
              errorMessage={errorMessage}
              handleClose={() => handleClose()}
              handleChangeModal={() => handleChangeModal()}
            />
          )}
        </Box>
      </Modal>
    </>
  );
};

export default UpdateLawyerPasswordModal;
