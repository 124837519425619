import React, { useState, useEffect } from 'react';

import { Typography } from '@mui/material';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/lawyer/CircleCheckbox.module.scss';

function CircleRadioFilter(props) {
  const filterOptions = props.filterOptions;

  const { meetingVal, shownModal, clearData, setMeetingVal } = props;

  const [Checked, setChecked] = useState(0);

  const handleToggle = (value) => {
    setChecked(value);
  };

  useEffect(() => {
    setChecked(meetingVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shownModal]);

  useEffect(() => {
    if (clearData) setChecked();
    setMeetingVal(Checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Checked, props]);

  var radioButton = (index) => {
    if (index === Checked) {
      return (
        <button className={styles.selected} onClick={() => handleToggle(index)}>
          <span className={styles.checkedCircle} />
        </button>
      );
    } else {
      return (
        <button
          data-cy="meeting-type"
          className={styles.unselected}
          onClick={() => handleToggle(index)}
        ></button>
      );
    }
  };

  const renderCheckboxLists = () => {
    return filterOptions.map((value, index) => (
      <React.Fragment key={index}>
        <Typography
          style={{
            width: 512,
            height: 50,
            display: 'flex',
            flexFlow: 'row wrap',
            gap: '8px',
            alignItems: 'center',
          }}
          component="div"
        >
          {radioButton(index)}

          <Typography className={styles.text} component="span">
            {value.name}
          </Typography>
        </Typography>

        {value.value === 'phone' && Checked === index && (
          <div className={styles['phone-number-text']}>
            {props.formValues.phoneNumber}
          </div>
        )}

        {value.value === 'address' && Checked === index && (
          <div className={styles['address-text']}>
            {props.formValues.addressValue.street}{' '}
            {props.formValues.addressValue.house_number}
            <br />
            {props.formValues.addressValue.zipcode !== '' &&
              props.formValues.addressValue.zipcode}
            {props.formValues.addressValue.zipcode !== '' && <br />}
            {props.formValues.addressValue.city}
            <br />
            {props.formValues.addressValue.country}
          </div>
        )}
      </React.Fragment>
    ));
  };

  return (
    <div>
      <p className={styles.title}>{LPTranslate('Common_Appointment_Type')}</p>
      {renderCheckboxLists()}
    </div>
  );
}

export default CircleRadioFilter;
