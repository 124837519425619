import React, { useState, useEffect } from 'react';

import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';

import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { UPDATE_LAWYER } from 'Services/Queries/lawyer';
import {
  GET_LAWYER,
  GET_AREAS_OF_LAW,
  GET_SPECIALITIES,
} from 'Services/Queries/lawyer';
import { addName } from 'Services/Redux/reducers/clientInfo';
import { ifNullReturnEmptyString } from 'Services/Utils/typeConversions';
import { validateEmail, validateWebsite } from 'Services/Utils/validations';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import useAlert from 'Services/Utils/hooks/useAlert';

import { parseJwt } from 'Services/Utils/token';

import SettingsMenu from 'Components/CommonComponents/SettingsMenu';
import EditSaveChangesSection from 'Components/Lawyer/Edit/EditSaveChangesSection';
import EditLawyerSectionInfo from 'Components/Lawyer/Edit/EditLawyerSectionInfo';
import EditLawyerProfileInformation from 'Components/Lawyer/Edit/EditLawyerProfileInformationSection';
import EditLawyerContactInformationSection from 'Components/Lawyer/Edit/EditLawyerContactInformationSection';
import SubscriptionFeaturesSection from 'Components/Lawyer/Edit/SubscriptionFeaturesSection';
import QuillTextFields from 'Components/Lawyer/TextEditor.js';
import ImageDropzone from 'Components/Lawyer/Edit/ImageDropzone';
import LawyerImage from 'Components/Lawyer/Edit/EditLawyerImage';
import SimpleTextField from 'Components/Inputs/SimpleTextField';

import styles from 'Assets/styles/lawyer/UpdateLawyerPage.module.scss';

const UpdateLawyerPage = () => {
  const { setAlert } = useAlert();
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);
  const logged_lawyer_id = clientInfoRedux.role_id;

  const [lawyerDTOBackupForReset, setLawyerDTOBackupForReset] = useState({});

  const [salutation, setSalutation] = useState('');
  const [title, setTitle] = useState('titleabcabc');
  const [firstName, setFirstName] = useState('first name');
  const [lastName, setLastName] = useState('last name');
  const [titleSuffix, setTitleSuffix] = useState('title suffix');
  const [jobTitle, setJobTitle] = useState('job title');
  const [lawfirmName, setLawfirmName] = useState('lawfirm name');
  const [street, setStreet] = useState('street');
  const [house_number, setHouseNumber] = useState('house_number');
  const [postcode, setPostcode] = useState('postcode');
  const [location, setLocation] = useState(null);
  const [country, setCountry] = useState('country');
  const [telephone, setTelephone] = useState('');
  const [faxNumber, setFaxNumber] = useState('');
  const [email, setEmail] = useState('email');
  const [emailError, setEmailError] = useState('');
  const [emailAlt, setEmailAlt] = useState('');
  const [website, setWebsite] = useState('website');
  const [websiteError, setWebsiteError] = useState('');
  const [specialistAttourneys, setSpecialistAttourneys] = useState(
    'specialist_attourneys'
  );
  const [shortDescription, setShortDescription] = useState('short description');
  const [selfDescription, setSelfDescription] = useState('self_description');
  const [selfLawfirmDescription, setSelfLawfirmDescription] = useState(
    'self_lawfirm_description'
  );
  const [userId, setUserId] = useState(22);
  const [subscription, setSubscription] = useState(3);
  const [areasOfLaw, setAreasOfLaw] = useState(['Erbrecht', 'Handelsrecht']);
  const [specialities, setSpecialities] = useState([]);
  const [profileImage, setProfileImage] = useState('');
  const [languages, setLanguages] = useState([
    { label: 'Deutsch', iso: 'de' },
    { label: 'English', iso: 'en' },
  ]);
  const [lawyerImages, setLawyerImages] = useState([]);

  const [areasOfLawDB, setAreaOfLawDB] = useState([]);
  const [specialitiesDB, setSpecialitiesDB] = useState([]);

  const [profileInformationErrorMessages, setProfileInformationErrorMessages] =
    useState({
      salutation: '',
      firstName: '',
      lastName: '',
    });

  const [addressErrorMessages, setAddressErrorMessages] = useState({
    street: '',
    house_number: '',
    postcode: '',
    location: '',
    country: '',
    telephone: '',
  });

  const areasOfLawQuery = useQuery(GET_AREAS_OF_LAW);
  const specialitiesQuery = useQuery(GET_SPECIALITIES);

  const [
    currentLawyer,
    { loading: currentLawyerLoading, data: currentLawyerData },
  ] = useLazyQuery(GET_LAWYER, {
    variables: { id: Number(logged_lawyer_id) },
    fetchPolicy: 'no-cache',
  });

  const [updateLawyer, { loading: updateLawyerLoading }] =
    useMutation(UPDATE_LAWYER);

  const onSaveChanges = async () => {
    let isError = false;

    if (!validateEmail(email) || email === '') {
      isError = true;
      fireSnackbar(LPTranslate('Error_Invalid_Input'), 'error');
      setEmailError('Geben Sie eine gültige E-Mail Adresse ein*');
    } else setEmailError('');

    // if (!validateWebsite(website) || website === '') {
    //   isError = true;
    //   fireSnackbar(LPTranslate('Error_Invalid_Input'), 'error');
    //   setWebsiteError('Geben Sie eine gültige webseite ein*');
    // } else setWebsiteError('');

    let profileInformationErrorMessagesCopy = {
      ...profileInformationErrorMessages,
    };
    let addressErrorMessagesCopy = { ...addressErrorMessages };

    if (salutation === '') {
      profileInformationErrorMessagesCopy.salutation =
        LPTranslate('Input_Salutation');
      isError = true;
    } else profileInformationErrorMessagesCopy.salutation = '';

    if (firstName === '') {
      profileInformationErrorMessagesCopy.firstName =
        LPTranslate('Input_First_Name');
      isError = true;
    } else profileInformationErrorMessagesCopy.firstName = '';

    if (lastName === '') {
      profileInformationErrorMessagesCopy.lastName =
        LPTranslate('Input_Last_Name');
      isError = true;
    } else profileInformationErrorMessagesCopy.lastName = '';

    if (street === '') {
      addressErrorMessagesCopy.street = LPTranslate('Input_Street') + `*`;
      isError = true;
    } else addressErrorMessagesCopy.street = '';

    if (house_number === '') {
      addressErrorMessagesCopy.house_number =
        LPTranslate('Input_House_Number') + `*`;
      isError = true;
    } else addressErrorMessagesCopy.house_number = '';

    if (postcode === '') {
      addressErrorMessagesCopy.postcode = LPTranslate('Input_Postcode') + `*`;
      isError = true;
    } else addressErrorMessagesCopy.postcode = '';

    if (location === '') {
      addressErrorMessagesCopy.location = LPTranslate('Input_City') + `*`;
      isError = true;
    } else addressErrorMessagesCopy.location = '';

    if (country === '') {
      addressErrorMessagesCopy.country = LPTranslate('Input_Country') + `*`;
      isError = true;
    } else addressErrorMessagesCopy.country = '';

    if (telephone === '') {
      addressErrorMessagesCopy.telephone = LPTranslate('Input_Telephone') + `*`;
      isError = true;
    } else addressErrorMessagesCopy.telephone = '';

    setProfileInformationErrorMessages(profileInformationErrorMessagesCopy);
    setAddressErrorMessages(addressErrorMessagesCopy);

    if (isError) return;

    try {
      let lawyerSaveData = {
        salutation: salutation.trim(),
        title: title.trim(),
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        title_suffix: titleSuffix.trim(),
        job_title: jobTitle.trim(),
        lawfirm_name: lawfirmName.trim(),
        street: street.trim(),
        house_number: house_number.trim(),
        postcode: postcode.trim(),
        location: location.trim(),
        country: country.trim(),
        telephone: telephone.trim(),
        fax_number: faxNumber.trim(),
        email: email.trim(),
        emailalt: emailAlt.trim(),
        specialist_attourneys: specialistAttourneys.trim(),
        short_description: shortDescription.trim(),

        languages: languages,
        areasOfLaw: areasOfLaw,
        specialities: specialities,

        user_id: userId,
        subscription: subscription,
      };

      if (subscription >= 2) {
        lawyerSaveData.self_description = selfDescription.trim();
        lawyerSaveData.self_lawfirm_description = selfLawfirmDescription.trim();
        lawyerSaveData.profileImage = profileImage;
        lawyerSaveData.lawyerImages = lawyerImages;
        lawyerSaveData.website = website.trim();
      }

      let data = await updateLawyer({ variables: { input: lawyerSaveData } });

      const event = new Event('UpdateUserInformationNavbar');
      document.dispatchEvent(event);

      if (data.data.updateLawyer.status === 'error') {
        fireSnackbar(LPTranslate('ULPP_Error_Message'), 'error');
        return;
      }

      currentLawyer();

      fireSnackbar(LPTranslate('ULPP_Successful_Message'), 'success');
    } catch (e) {
      console.log(e);
    }
  };

  const fireSnackbar = (message, type) => {
    setAlert(message);
  };

  const dispatch = useDispatch();

  const onChangeLawyerImages = (index, value) => {
    let images = [...lawyerImages];
    images[index] = value;
    setLawyerImages(images);
  };

  const onDeleteLawyerImages = (index) => {
    let newImages = [...lawyerImages];
    newImages.splice(index, 1);

    setLawyerImages(newImages);
  };

  const fillFields = (lawyerDTO) => {
    setSalutation(ifNullReturnEmptyString(lawyerDTO.salutation));
    setTitle(ifNullReturnEmptyString(lawyerDTO.title));
    setFirstName(ifNullReturnEmptyString(lawyerDTO.first_name));
    setLastName(ifNullReturnEmptyString(lawyerDTO.last_name));
    setTitleSuffix(ifNullReturnEmptyString(lawyerDTO.title_suffix));
    setJobTitle(ifNullReturnEmptyString(lawyerDTO.job_title));
    setLawfirmName(ifNullReturnEmptyString(lawyerDTO.lawfirm_name));
    setStreet(ifNullReturnEmptyString(lawyerDTO.street));
    setHouseNumber(ifNullReturnEmptyString(lawyerDTO.house_number));
    setPostcode(ifNullReturnEmptyString(lawyerDTO.postcode));
    setLocation(ifNullReturnEmptyString(lawyerDTO.location));
    setCountry(ifNullReturnEmptyString(lawyerDTO.country));
    setTelephone(ifNullReturnEmptyString(lawyerDTO.telephone));
    setFaxNumber(ifNullReturnEmptyString(lawyerDTO.fax_number));
    setEmail(ifNullReturnEmptyString(lawyerDTO.email));
    setEmailAlt(ifNullReturnEmptyString(lawyerDTO.emailalt));
    setWebsite(ifNullReturnEmptyString(lawyerDTO.website));
    setSpecialistAttourneys(
      ifNullReturnEmptyString(lawyerDTO.specialist_attourneys)
    );
    setShortDescription(ifNullReturnEmptyString(lawyerDTO.short_description));
    setSelfDescription(ifNullReturnEmptyString(lawyerDTO.self_description));
    setSelfLawfirmDescription(
      ifNullReturnEmptyString(lawyerDTO.self_lawfirm_description)
    );
    setUserId(ifNullReturnEmptyString(lawyerDTO.user_id));
    setSubscription(ifNullReturnEmptyString(lawyerDTO.subscription));
    setProfileImage(ifNullReturnEmptyString(lawyerDTO.profileImage));
    setLanguages(ifNullReturnEmptyString(lawyerDTO.languages));
    setLawyerImages(ifNullReturnEmptyString(lawyerDTO.lawyerImages));
    setAreasOfLaw(ifNullReturnEmptyString(lawyerDTO.areasOfLaw));
    setSpecialities(ifNullReturnEmptyString(lawyerDTO.specialities));
  };

  useEffect(() => {
    currentLawyer();
  }, [currentLawyer]);

  useEffect(() => {
    if (!currentLawyer.loading && currentLawyerData) {
      let lawyerDTO = { ...currentLawyerData.lawyer, id: 1 };

      delete lawyerDTO.__typename;

      if (lawyerDTO.lawyerAvailability !== null) {
        lawyerDTO.lawyerAvailability = {
          show_availability:
            currentLawyerData.lawyer.lawyerAvailability.show_availability,
          personally: currentLawyerData.lawyer.lawyerAvailability.personally,
          by_phone: currentLawyerData.lawyer.lawyerAvailability.by_phone,
          online_meeting:
            currentLawyerData.lawyer.lawyerAvailability.online_meeting,
        };
      } else {
        lawyerDTO.lawyerAvailability = {
          show_availability: false,
          personally: false,
          by_phone: false,
          online_meeting: false,
        };
      }

      let languages = [];
      for (let i = 0; i < lawyerDTO.languages.length; i++) {
        languages.push({
          label: lawyerDTO.languages[i].label,
          iso: lawyerDTO.languages[i].iso,
        });
      }

      lawyerDTO.languages = languages;

      fillFields(lawyerDTO);
      setLawyerDTOBackupForReset(lawyerDTO);

      dispatch(addName(currentLawyerData.lawyer.name));
    }
  }, [currentLawyerData, currentLawyer.loading, dispatch]);

  useEffect(() => {
    if (updateLawyer.error || currentLawyer.error) {
      setAlert(LPTranslate('Error_Server_Down'));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateLawyer.error, currentLawyer.error]);

  useEffect(() => {
    if (
      !areasOfLawQuery.loading &&
      areasOfLawQuery.data !== undefined &&
      areasOfLawDB.length === 0
    ) {
      let dataAreas = [];

      for (let i = 0; i < areasOfLawQuery.data.getAreasOfLaw.length; i++) {
        dataAreas.push(areasOfLawQuery.data.getAreasOfLaw[i].name);
      }

      setAreaOfLawDB(dataAreas);
    }
  }, [areasOfLawQuery, areasOfLawDB]);

  useEffect(() => {
    if (
      !specialitiesQuery.loading &&
      specialitiesQuery.data !== undefined &&
      specialitiesDB.length === 0
    ) {
      let dataSpecialities = [];

      for (let i = 0; i < specialitiesQuery.data.getSpecialities.length; i++) {
        dataSpecialities.push(
          specialitiesQuery.data.getSpecialities[i].speciality
        );
      }

      setSpecialitiesDB(dataSpecialities);
    }
  }, [specialitiesQuery, specialitiesDB]);

  const loading = updateLawyer.loading || currentLawyer.loading;

  const error = updateLawyer.error || currentLawyer.error;

  if (!currentLawyerData || currentLawyerLoading) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '200px',
            marginBottom: '200px',
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles['edit-lawyer-top-menu']}>
          <SettingsMenu
            setUpdateComponent={'/updateLawyerPage'}
            role={clientInfoRedux.role}
            subscription={parseJwt(clientInfoRedux.token).subscription}
          />
        </div>

        <div className={styles.EditSaveChangesSection}>
          <EditSaveChangesSection
            onSaveChanges={onSaveChanges}
            onResetFields={() => {
              fillFields(lawyerDTOBackupForReset);
            }}
            pageTitle={LPTranslate('Common_Public_Profile')}
            topChanges
            isLoading={updateLawyerLoading}
          />
        </div>

        <div className={styles.Divider} style={{ marginBottom: '60px' }} />
        <div className={styles.infoContainer}>
          <div className={styles.Section}>
            <div className={styles.SectionInformation}>
              <div className={styles.SectionInformationContainer}>
                <EditLawyerSectionInfo
                  subscription={1}
                  title={LPTranslate('ULPP_Profile_Title')}
                  text={LPTranslate('ULPP_Profile_Description')}
                />
              </div>
            </div>

            <div className={styles.SectionContent}>
              <div className={styles.SectionContentContainer}>
                <EditLawyerProfileInformation
                  loading={loading}
                  error={error}
                  salutation={salutation}
                  title={title}
                  firstName={firstName}
                  lastName={lastName}
                  titleSuffix={titleSuffix}
                  lawfirmName={lawfirmName}
                  languages={languages}
                  shortDescription={shortDescription}
                  areasOfLaw={areasOfLaw}
                  specialities={specialities}
                  onChangeSalutation={(value) => setSalutation(value)}
                  onChangeTitle={(value) => setTitle(value)}
                  onChangeFirstName={(value) => setFirstName(value)}
                  onChangeLastName={(value) => setLastName(value)}
                  onChangeTitleSuffix={(value) => setTitleSuffix(value)}
                  onChangeLawfirmName={(value) => setLawfirmName(value)}
                  onChangeLanguages={(value) => setLanguages(value)}
                  onChangeShortDescription={(value) =>
                    setShortDescription(value)
                  }
                  onChangeAreasOfLaw={(value) => setAreasOfLaw(value)}
                  onChangeSpecialities={(value) => setSpecialities(value)}
                  areasOfLawDB={areasOfLawDB}
                  specialitiesDB={specialitiesDB}
                  profileInformationErrorMessages={
                    profileInformationErrorMessages
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.EditSaveChangesSectionBottom}>
          <EditSaveChangesSection
            onSaveChanges={onSaveChanges}
            onResetFields={() => {
              fillFields(lawyerDTOBackupForReset);
            }}
            noText
            bottomChanges
            isLoading={updateLawyerLoading}
          />
        </div>

        <div className={styles.Divider} />

        <div className={styles.infoContainer}>
          <div className={styles.Section}>
            <div className={styles.SectionInformation}>
              <div className={styles.SectionInformationContainer}>
                <EditLawyerSectionInfo
                  subscription={1}
                  title={LPTranslate('ULPP_Contact_Title')}
                  text={LPTranslate('ULPP_Contact_Description')}
                />
              </div>
            </div>

            <div className={styles.SectionContent}>
              <div className={styles.SectionContentContainer}>
                <EditLawyerContactInformationSection
                  loading={loading}
                  error={error}
                  location={location}
                  country={country}
                  street={street}
                  house_number={house_number}
                  postcode={postcode}
                  telephone={telephone}
                  faxNumber={faxNumber}
                  email={email}
                  emailError={emailError}
                  website={website}
                  websiteError={websiteError}
                  onChangeLocation={(value) => {
                    const filteredValue = value.replace(/\//g, '');
                    setLocation(filteredValue);
                  }}
                  onChangeStreet={(value) => setStreet(value)}
                  onChangeCountry={(value) => setCountry(value)}
                  onChangeHouseNumber={(value) => setHouseNumber(value)}
                  onChangePostcode={(value) => setPostcode(value)}
                  onChangeTelephone={(value) => setTelephone(value)}
                  onChangeFaxNumber={(value) => setFaxNumber(value)}
                  onChangeEmail={(value) => setEmail(value)}
                  onChangeWebsite={(value) => setWebsite(value)}
                  addressErrorMessages={addressErrorMessages}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.EditSaveChangesSectionBottom}>
          <EditSaveChangesSection
            onSaveChanges={onSaveChanges}
            onResetFields={() => {
              fillFields(lawyerDTOBackupForReset);
            }}
            noText
            bottomChanges
            isLoading={updateLawyerLoading}
          />
        </div>
        <div className={styles.Divider} />

        <div className={styles.goldContainer}>
          <div className={styles.infoContainer}>
            <div className={styles.Section}>
              <div className={styles.SectionInformation}>
                <div className={styles.SectionInformationContainer}>
                  <EditLawyerSectionInfo
                    subscription={2}
                    title={LPTranslate('ULPP_Profile_Picture_Title_1')}
                    text={LPTranslate('ULPP_Profile_Picture_Description')}
                  />
                </div>
              </div>

              <div className={styles.SectionContent}>
                <div className={styles.SectionContentContainer}>
                  {loading || error ? (
                    <>
                      <Skeleton
                        variant="rectangular"
                        width={512}
                        height={142}
                        sx={{ mt: '20px' }}
                      />
                    </>
                  ) : profileImage !== '' ? (
                    <LawyerImage
                      delete_image_data_cy="delete-profile-image-button"
                      update_image_data_cy="update-profile-image-button"
                      imageFile={profileImage}
                      onChange={(image) => setProfileImage(image)}
                      onDelete={() => setProfileImage('')}
                      roundImage={true}
                    />
                  ) : (
                    <div
                      style={{
                        display: 'block',
                        width: 512,
                        height: 150,
                        marginTop: '20px',
                      }}
                    >
                      <ImageDropzone
                        data_cy="profile-image-input"
                        onChange={(image) => setProfileImage(image)}
                        singleImage
                        fireSnackbar={fireSnackbar}
                        compressorOptions={{
                          maxWidth: 768,
                          maxHeight: 768,
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.infoContainer}>
            <div className={styles.Section}>
              <div className={styles.SectionInformation}>
                <div className={styles.SectionInformationContainer}>
                  <EditLawyerSectionInfo
                    subscription={2}
                    title={LPTranslate('ULPP_About_Me_Title')}
                    text={LPTranslate('ULPP_About_Me_Description')}
                  />
                </div>
              </div>

              <div className={styles.SectionContent}>
                <div className={styles.SectionContentContainer}>
                  <div
                    className={styles.websiteFullSection}
                    style={{ marginTop: '15px' }}
                  >
                    {loading || error ? (
                      <>
                        <Skeleton
                          variant="rectangular"
                          width={512}
                          height={70}
                          sx={{ mt: '20px' }}
                        />
                      </>
                    ) : (
                      <SimpleTextField
                        data_cy="website-input"
                        value={website}
                        onChange={(value) => setWebsite(value)}
                        title={LPTranslate('Input_Internet_Address')}
                        textFieldWidthPercent={100}
                        errorMessage={websiteError}
                      />
                    )}
                  </div>

                  <div className={styles.PLACE_HOLDER_TEXT_EDITOR}>
                    <React.Fragment key={'ed1'}>
                      <QuillTextFields
                        data_cy="self-description-input"
                        title={LPTranslate('Input_Describe_Yourself_And_Firm')}
                        id={'ed1'}
                        name={'ed1'}
                        value={selfDescription}
                        onChange={(value) => setSelfDescription(value)}
                      />
                    </React.Fragment>
                  </div>

                  <div className={styles.PLACE_HOLDER_TEXT_EDITOR}>
                    <React.Fragment key={'ed2'}>
                      <QuillTextFields
                        data_cy="law-firm-description-input"
                        title={LPTranslate('ULPP_Law_Firm_Description')}
                        id={'ed2'}
                        name={'ed2'}
                        value={selfLawfirmDescription}
                        onChange={(value) => setSelfLawfirmDescription(value)}
                      />
                    </React.Fragment>
                  </div>

                  <div
                    style={{
                      display: 'block',
                      width: 512,
                      marginTop: '20px',
                    }}
                  >
                    <p className={styles.ImageDropzoneTitle}>
                      {' '}
                      {LPTranslate('ULPP_About_Me_Form_Insert_Image')}{' '}
                    </p>
                    <div style={{ height: 150 }}>
                      <ImageDropzone
                        data_cy="list-images-input"
                        images={lawyerImages}
                        onChange={(image) => setLawyerImages(image)}
                        fireSnackbar={fireSnackbar}
                        compressorOptions={{
                          maxWidth: 1400,
                          maxHeight: 1400,
                        }}
                      />
                    </div>
                    <div className={styles.LawyerImages}>
                      {lawyerImages.map((image, index) => (
                        <LawyerImage
                          delete_image_data_cy="delete-list-images-button"
                          update_image_data_cy="update-list-images-button"
                          key={index}
                          imageFile={image}
                          onDelete={() => onDeleteLawyerImages(index)}
                          onChange={(updatedImage) =>
                            onChangeLawyerImages(index, updatedImage)
                          }
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.Divider} />

          <div className={styles.EditSaveChangesSectionBottom}>
            <EditSaveChangesSection
              onSaveChanges={onSaveChanges}
              onResetFields={() => {
                fillFields(lawyerDTOBackupForReset);
              }}
              noText
              bottomChanges
              isLoading={updateLawyerLoading}
            />
          </div>

          {!currentLawyer.loading &&
            currentLawyerData.lawyer?.subscription < 2 && (
              <SubscriptionFeaturesSection clientInfoRedux={clientInfoRedux} />
            )}
        </div>
      </div>
    </>
  );
};

export default UpdateLawyerPage;
