import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import CalendarComponent from 'Components/Calendar/CalendarComponent';

import { ADD_LAWYER_HOLIDAYS } from 'Services/Queries/lawyer';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import purpleCalendar from 'Assets/pictures/purple-calendar.svg';
import plusIcon from 'Assets/pictures/plus-icon.svg';
import calendarIcon from 'Assets/pictures/calendar.svg';
import closeIcon from 'Assets/pictures/close-icon.svg';
import styles from 'Assets/styles/lawyer/VacationModal.module.scss';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const VacationModal = (props) => {
  const [addLawyerHolidays, { data }] = useMutation(ADD_LAWYER_HOLIDAYS);
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [state, setState] = useState(0);
  const [index, setIndex] = useState(null);

  const [calendarArr, setCalendarArr] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setState(0);
    setShow(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const test = async () => {
    // var isCalendarArrEmpty = false;
    var data = {};

    let holidaysArrData = [];

    for (let i = 0; i < calendarArr.length; i++) {
      if (
        calendarArr[i].start_date_timestamp !== undefined &&
        calendarArr[i].end_date_timestamp !== undefined
      )
        holidaysArrData.push({
          start_date_timestamp: Number(calendarArr[i].start_date_timestamp),
          end_date_timestamp: Number(calendarArr[i].end_date_timestamp),
        });
    }

    data = {
      lawyer_id: parseInt(clientInfoRedux.role_id),
      days: holidaysArrData,
    };

    // for (let i = 0; i < holidaysArrData.length; i++) {
    //   if (
    //     holidaysArrData[i].start_date_timestamp === undefined ||
    //     holidaysArrData[i].end_date_timestamp === undefined
    //   )
    //     isCalendarArrEmpty = true;
    //   else isCalendarArrEmpty = false;
    // }

    // have a look
    // if (isCalendarArrEmpty || holidaysArrData.length === 0) {
    //   setSnackbarMessage(LPTranslate('LSC_Empty_List'));
    //   setSnackbarSeverity('error');
    //   setSnackbarOpen(true);

    //   return;
    // }

    try {
      let response = await addLawyerHolidays({
        variables: {
          input: data,
        },
      });

      if (response.data.createLawyerHolidays.status === 'error') {
        setSnackbarMessage(response.data.createLawyerHolidays.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } else {
        // setSnackbarMessage(LPTranslate('LSC_Create_Successful'));
        // setSnackbarSeverity('success');
        // setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage(LPTranslate('Error_Server_Down'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    let arr = props.holidaysArr;

    if (arr.length === 0)
      setCalendarArr([
        { start_date_timestamp: undefined, end_date_timestamp: undefined },
      ]);
    else setCalendarArr(arr);
  }, [props]);

  useEffect(() => {
    if (data && data.createLawyerHolidays.message) {
      setSnackbarMessage(LPTranslate('LSC_Create_Successful'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);

      setShow(false);
    }
  }, [data]);

  const handleSubmit = () => {
    test();
  };

  const handleShowCalendar = (start, end, index) => {
    if (start !== undefined && end !== undefined) {
      setEndDate(new Date(Number(end)));
      setStartDate(new Date(Number(start)));
    } else if (start === undefined && end === undefined) {
      setEndDate(start);
      setStartDate(end);
    }

    setIndex(index);
    setState(1);
  };

  const handleVacation = (start, end, index) => {
    let newCalendarArr = [];

    for (let i = 0; i < calendarArr.length; i++) {
      if (
        calendarArr[i].start_date_timestamp &&
        calendarArr[i].end_date_timestamp
      ) {
        if (
          start.getTime() >=
            new Date(Number(calendarArr[i].start_date_timestamp)).getTime() &&
          start.getTime() <=
            new Date(Number(calendarArr[i].end_date_timestamp)).getTime()
        ) {
          setSnackbarMessage(LPTranslate('LSC_Date_Overlap'));
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          return;
        }

        if (
          end.getTime() >=
            new Date(Number(calendarArr[i].start_date_timestamp)).getTime() &&
          end.getTime() <=
            new Date(Number(calendarArr[i].end_date_timestamp)).getTime()
        ) {
          setSnackbarMessage(LPTranslate('LSC_Date_Overlap'));
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          return;
        }
      }
    }

    for (let i = 0; i < calendarArr.length; i++) {
      newCalendarArr.push({
        start_date_timestamp: calendarArr[i].start_date_timestamp,
        end_date_timestamp: calendarArr[i].end_date_timestamp,
      });
    }

    if (start !== undefined && end !== undefined) {
      newCalendarArr[index].start_date_timestamp = start.getTime();
      newCalendarArr[index].end_date_timestamp = end.getTime();
    } else {
      newCalendarArr[index].start_date_timestamp = start;
      newCalendarArr[index].end_date_timestamp = end;
    }

    setCalendarArr(newCalendarArr);
  };

  const handleStartDate = (start, index) => {
    setStartDate(start);
  };

  const handleEndDate = (end, index) => {
    setEndDate(end);
  };

  const addNewSlot = () => {
    let newCalendarArr = [...calendarArr];

    newCalendarArr.push({
      start_date_timestamp: undefined,
      end_date_timestamp: undefined,
    });

    setCalendarArr(newCalendarArr);
  };

  const removeNewCalendar = (e, index) => {
    e.stopPropagation();

    setState(0);

    let newCalendarArr = [...calendarArr];

    newCalendarArr.splice(index, 1);

    if (newCalendarArr.length === 0)
      setCalendarArr([
        { start_date_timestamp: undefined, end_date_timestamp: undefined },
      ]);
    else setCalendarArr(newCalendarArr);
  };

  return (
    <>
      <div className={styles['lawp-vacation-button-container']}>
        <button
          data-cy="holiday-modal-button"
          className={styles['lawp-vacation-button-holiday-settings']}
          onClick={handleShow}
        >
          {LPTranslate('LSC_Vacation_Settings')}
        </button>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={
            snackbarSeverity === 'error' && {
              backgroundColor: '#7f56d9 !important',
            }
          }
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Modal
        className={styles['lawp-vacation-modal']}
        open={show}
        onClose={handleClose}
      >
        <Box className={styles['lawp-vacation-box']}>
          {state === 0 ? (
            <div className={styles['lawp-vacation-modal-container']}>
              <div className={styles['lawp-vacation-content']}>
                <div className={styles['lawp-vacation-featured-icon']}>
                  <img
                    className={styles['lawp-vacation-calendar-icon']}
                    src={purpleCalendar}
                    alt="calendar-icon"
                  />
                </div>
                <div
                  className={styles['lawp-vacation-text-and-supporting-text']}
                >
                  <p className={styles['lawp-vacation-text']}>
                    {LPTranslate('LSC_Vacation_Settings')}
                  </p>

                  <p className={styles['lawp-vacation-supporting-text']}>
                    {LPTranslate('LSHM_Description_1')}
                  </p>
                </div>
                <div className={styles['lawp-vacation-checkbox']}>
                  <div
                    className={
                      styles['lawp-vacation-checkbox-text-and-supporting-text']
                    }
                  >
                    <p className={styles['lawp-vacation-checkbox-text']}>
                      {LPTranslate('LSHM_Title_2')}
                    </p>
                  </div>
                </div>
                <div className={styles['lawp-vacation-frame-1033']}>
                  {calendarArr.map((obj, index) => {
                    return (
                      <React.Fragment key={index}>
                        {obj.start_date_timestamp === undefined &&
                        obj.end_date_timestamp === undefined ? (
                          <button
                            data-cy="select-holiday-button"
                            className={
                              styles['lawp-unselected-calendar-button']
                            }
                            onClick={() =>
                              handleShowCalendar(undefined, undefined, index)
                            }
                          >
                            <img alt="calendar icon" src={calendarIcon} />
                            <p
                              className={
                                styles['lawp-unselected-calendar-button-text']
                              }
                            >
                              {LPTranslate('Button_Select_Vacation_Period')}
                            </p>
                          </button>
                        ) : (
                          <>
                            <button
                              data-cy="holiday-button"
                              className={styles['lawp-calendar-button']}
                              onClick={() =>
                                handleShowCalendar(
                                  obj.start_date_timestamp,
                                  obj.end_date_timestamp,
                                  index
                                )
                              }
                            >
                              <img alt="calendar icon" src={calendarIcon} />
                              <p
                                className={styles['lawp-calendar-button-text']}
                              >
                                {obj.start_date_timestamp !== undefined &&
                                  new Date(
                                    Number(obj.start_date_timestamp)
                                  ).getDate()}
                                .
                                {obj.start_date_timestamp !== undefined &&
                                  (parseInt(
                                    new Date(
                                      Number(obj.start_date_timestamp)
                                    ).getMonth() + 1
                                  ) < 10
                                    ? '0' +
                                      parseInt(
                                        new Date(
                                          Number(obj.start_date_timestamp)
                                        ).getMonth() + 1
                                      )
                                    : parseInt(
                                        new Date(
                                          Number(obj.start_date_timestamp)
                                        ).getMonth() + 1
                                      ))}
                                -
                                {obj.end_date_timestamp !== undefined &&
                                  new Date(
                                    Number(obj.end_date_timestamp)
                                  ).getDate()}
                                .
                                {obj.end_date_timestamp !== undefined &&
                                  (parseInt(
                                    new Date(
                                      Number(obj.end_date_timestamp)
                                    ).getMonth() + 1
                                  ) < 10
                                    ? '0' +
                                      parseInt(
                                        new Date(
                                          Number(obj.end_date_timestamp)
                                        ).getMonth() + 1
                                      )
                                    : parseInt(
                                        new Date(
                                          Number(obj.end_date_timestamp)
                                        ).getMonth() + 1
                                      ))}
                              </p>
                              <div
                                data-cy="delete-holiday-button"
                                onClick={(e) => removeNewCalendar(e, index)}
                                style={{
                                  border: 'none',
                                  background: 'none',
                                  padding: 0,
                                  height: 20,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <img alt="close icon" src={closeIcon} />
                              </div>
                            </button>
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
                {calendarArr.length !== 0 && (
                  <div className={styles['lawp-vacation-frame-1034']}>
                    <div className={styles['lawp-vacation-radio-buttons']}>
                      <div
                        className={styles['lawp-vacation-add-vacation-button']}
                      >
                        <button
                          data-cy="add-new-vacation-button"
                          className={
                            styles['lawp-vacation-add-vacation-button-base']
                          }
                          onClick={addNewSlot}
                        >
                          <img alt="plus" src={plusIcon} />
                          <p
                            className={
                              styles['lawp-vacation-add-vacation-button-text']
                            }
                          >
                            {LPTranslate('LSHM_Add_New_Holiday')}
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles['lawp-vacation-actions']}>
                <div className={styles['lawp-vacation-empty-button']} />
                <div className={styles['lawp-vacation-buttons']}>
                  <div className={styles['lawp-vacation-cancel-button']}>
                    <button
                      data-cy="vacation-cancel-button"
                      className={styles['lawp-vacation-cancel-button-base']}
                      onClick={handleClose}
                    >
                      <p className={styles['lawp-vacation-cancel-text']}>
                        {LPTranslate('Button_Cancel')}
                      </p>
                    </button>
                  </div>

                  <div className={styles['lawp-vacation-apply-button']}>
                    <button
                      data-cy="vacation-apply-button"
                      className={styles['lawp-vacation-apply-button-base']}
                      onClick={handleSubmit}
                    >
                      <p className={styles['lawp-vacation-apply-text']}>
                        {LPTranslate('Button_Save')}
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <CalendarComponent
              startDate={startDate}
              endDate={endDate}
              index={index}
              handleVacation={handleVacation}
              handleStartDate={handleStartDate}
              handleEndDate={handleEndDate}
              removeNewCalendar={removeNewCalendar}
              handleState={(val) => setState(val)}
            />
          )}
        </Box>
      </Modal>
    </>
  );
};

export default VacationModal;
