import { useNavigate } from 'react-router-dom';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import logo from 'Assets/pictures/anwado-logo2.svg';
import stepperCheck from 'Assets/pictures/stepperCheck.svg';
import styles from 'Assets/styles/lawyer/DeleteComponentsStyle/DeleteStepper.module.scss';

const DeleteStepper = (props) => {
  const { state, eligible, deleteState } = props;

  const navigate = useNavigate();
  return (
    <>
      <div className={styles['delete-stepper-container']}>
        <div className={styles['anwado-logo']}>
          <img
            className={styles['verifyLogo']}
            src={logo}
            alt="logo"
            onClick={() => navigate('/')}
          />
        </div>
        <div className={styles['stepper-content-container']}>
          {state === 1 && (
            <>
              <div className={styles['delete-stepper']}>
                <div className={styles['stepper-progration-container']}>
                  <div className={styles['step-icon-base']}>
                    <div className={styles['selected-icon-content']}>
                      <div className={styles['selected-dot']} />
                    </div>
                  </div>
                  <div className={styles['delete-stepper-connector-gray']} />
                </div>
                <div className={styles['delete-stepper-text-container']}>
                  <div className={styles['delete-sepper-text-title']}>
                    {LPTranslate('DA_Stepper_Title')}
                  </div>
                  <div className={styles['delete-sepper-text-details']}>
                    {LPTranslate('DA_Stepper_1_Description')}
                  </div>
                </div>
              </div>
              <div className={styles['delete-stepper']}>
                <div className={styles['stepper-progration-container']}>
                  <div className={styles['unselected-step-icon-base']}>
                    <div className={styles['un-selected-icon-content']}>
                      <div className={styles['un-selected-dot']} />
                    </div>
                  </div>
                </div>
                <div className={styles['delete-stepper-text-container']}>
                  <div className={styles['delete-sepper-text-title']}>
                    {LPTranslate('DA_Stepper_Title')}
                  </div>
                  <div className={styles['delete-sepper-text-details']}>
                    {LPTranslate('DA_Stepper_2_Description')}
                  </div>
                </div>
              </div>
              <div className={styles['last-delete-stepper']}>
                <div
                  className={styles['last-stepper-progration-container']}
                ></div>
              </div>
            </>
          )}
          {state === 2 && eligible === true && (
            <>
              <div className={styles['delete-stepper']}>
                <div className={styles['stepper-progration-container']}>
                  <div className={styles['step-icon-base-check']}>
                    <div className={styles['selected-icon-content']}>
                      <div className={styles['delete-stepper-check']}>
                        <img alt="check" src={stepperCheck} />
                      </div>
                    </div>
                  </div>
                  <div className={styles['delete-stepper-connector-purple']} />
                </div>
                <div className={styles['delete-stepper-text-container']}>
                  <div className={styles['delete-sepper-text-title']}>
                    {LPTranslate('DA_Stepper_Title')}
                  </div>
                  <div className={styles['delete-sepper-text-details']}>
                    {LPTranslate('DA_Stepper_1_Description')}
                  </div>
                </div>
              </div>
              <div className={styles['delete-stepper']}>
                <div className={styles['stepper-progration-container']}>
                  <div className={styles['step-icon-base']}>
                    <div className={styles['selected-icon-content']}>
                      <div className={styles['selected-dot']} />
                    </div>
                  </div>
                </div>
                <div className={styles['delete-stepper-text-container']}>
                  <div className={styles['delete-sepper-text-title']}>
                    {LPTranslate('DA_Stepper_Title')}
                  </div>
                  <div className={styles['delete-sepper-text-details']}>
                    {LPTranslate('DA_Stepper_2_Description')}
                  </div>
                </div>
              </div>
              <div className={styles['last-delete-stepper']}>
                <div
                  className={styles['last-stepper-progration-container']}
                ></div>
              </div>
            </>
          )}
          {state === 2 && eligible === false && deleteState === false && (
            <>
              <div className={styles['delete-stepper']}>
                <div className={styles['stepper-progration-container']}>
                  <div className={styles['step-icon-base-check']}>
                    <div className={styles['selected-icon-content']}>
                      <div className={styles['delete-stepper-check']}>
                        <img alt="check" src={stepperCheck} />
                      </div>
                    </div>
                  </div>
                  <div className={styles['delete-stepper-connector-purple']} />
                </div>
                <div className={styles['delete-stepper-text-container']}>
                  <div className={styles['delete-sepper-text-title']}>
                    {LPTranslate('DA_Stepper_Title')}
                  </div>
                  <div className={styles['delete-sepper-text-details']}>
                    {LPTranslate('DA_Stepper_1_Description')}
                  </div>
                </div>
              </div>
              <div className={styles['delete-stepper']}>
                <div className={styles['stepper-progration-container']}>
                  <div className={styles['step-icon-base']}>
                    <div className={styles['selected-icon-content']}>
                      <div className={styles['selected-dot']} />
                    </div>
                  </div>
                </div>
                <div className={styles['delete-stepper-text-container']}>
                  <div className={styles['delete-sepper-text-title']}>
                    {LPTranslate('DA_Stepper_Title')}
                  </div>
                  <div className={styles['delete-sepper-text-details']}>
                    {LPTranslate('DA_Stepper_2_Description')}
                  </div>
                </div>
              </div>
              <div className={styles['last-delete-stepper']}>
                <div
                  className={styles['last-stepper-progration-container']}
                ></div>
              </div>
            </>
          )}
          {state === 2 && deleteState === true && (
            <>
              <div className={styles['delete-stepper']}>
                <div className={styles['stepper-progration-container']}>
                  <div className={styles['step-icon-base-check']}>
                    <div className={styles['selected-icon-content']}>
                      <div className={styles['delete-stepper-check']}>
                        <img alt="check" src={stepperCheck} />
                      </div>
                    </div>
                  </div>
                  <div className={styles['delete-stepper-connector-purple']} />
                </div>
                <div className={styles['delete-stepper-text-container']}>
                  <div className={styles['delete-sepper-text-title']}>
                    {LPTranslate('DA_Stepper_Title')}
                  </div>
                  <div className={styles['delete-sepper-text-details']}>
                    {LPTranslate('DA_Stepper_1_Description')}
                  </div>
                </div>
              </div>
              <div className={styles['delete-stepper']}>
                <div className={styles['stepper-progration-container']}>
                  <div className={styles['step-icon-base-check']}>
                    <div className={styles['delete-stepper-check']}>
                      <img alt="check" src={stepperCheck} />
                    </div>
                  </div>
                </div>
                <div className={styles['delete-stepper-text-container']}>
                  <div className={styles['delete-sepper-text-title']}>
                    {LPTranslate('DA_Stepper_Title')}
                  </div>
                  <div className={styles['delete-sepper-text-details']}>
                    {LPTranslate('DA_Stepper_2_Description')}
                  </div>
                </div>
              </div>
            </>
          )}
          {state === 3 && deleteState === false && (
            <>
              <div className={styles['delete-stepper']}>
                <div className={styles['stepper-progration-container']}>
                  <div className={styles['step-icon-base-check']}>
                    <div className={styles['selected-icon-content']}>
                      <div className={styles['delete-stepper-check']}>
                        <img alt="check" src={stepperCheck} />
                      </div>
                    </div>
                  </div>
                  <div className={styles['delete-stepper-connector-purple']} />
                </div>
                <div className={styles['delete-stepper-text-container']}>
                  <div className={styles['delete-sepper-text-title']}>
                    {LPTranslate('DA_Stepper_Title')}
                  </div>
                  <div className={styles['delete-sepper-text-details']}>
                    {LPTranslate('DA_Stepper_1_Description')}
                  </div>
                </div>
              </div>
              <div className={styles['delete-stepper']}>
                <div className={styles['stepper-progration-container']}>
                  <div className={styles['step-icon-base']}>
                    <div className={styles['selected-icon-content']}>
                      <div className={styles['selected-dot']} />
                    </div>
                  </div>
                </div>
                <div className={styles['delete-stepper-text-container']}>
                  <div className={styles['delete-sepper-text-title']}>
                    {LPTranslate('DA_Stepper_Title')}
                  </div>
                  <div className={styles['delete-sepper-text-details']}>
                    {LPTranslate('DA_Stepper_2_Description')}
                  </div>
                </div>
              </div>
              <div className={styles['last-delete-stepper']}>
                <div
                  className={styles['last-stepper-progration-container']}
                ></div>
              </div>
            </>
          )}
          {state === 3 && deleteState === true && (
            <>
              <div className={styles['delete-stepper']}>
                <div className={styles['stepper-progration-container']}>
                  <div className={styles['step-icon-base-check']}>
                    <div className={styles['selected-icon-content']}>
                      <div className={styles['delete-stepper-check']}>
                        <img alt="check" src={stepperCheck} />
                      </div>
                    </div>
                  </div>
                  <div className={styles['delete-stepper-connector-purple']} />
                </div>
                <div className={styles['delete-stepper-text-container']}>
                  <div className={styles['delete-sepper-text-title']}>
                    {LPTranslate('DA_Stepper_Title')}
                  </div>
                  <div className={styles['delete-sepper-text-details']}>
                    {LPTranslate('DA_Stepper_1_Description')}
                  </div>
                </div>
              </div>
              <div className={styles['delete-stepper']}>
                <div className={styles['stepper-progration-container']}>
                  <div className={styles['step-icon-base-check']}>
                    <div className={styles['delete-stepper-check']}>
                      <img alt="check" src={stepperCheck} />
                    </div>
                  </div>
                </div>
                <div className={styles['delete-stepper-text-container']}>
                  <div className={styles['delete-sepper-text-title']}>
                    {LPTranslate('DA_Stepper_Title')}
                  </div>
                  <div className={styles['delete-sepper-text-details']}>
                    {LPTranslate('DA_Stepper_2_Description')}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* <div className={styles['verify-footer']}></div> */}
    </>
  );
};
export default DeleteStepper;
