import { gql } from '@apollo/client';

export const GET_REVIEWS_NR = gql`
  query GetReviewOfLawyerNr($lawyer_id: ID!) {
    getReviewNumber(lawyer_id: $lawyer_id) {
      RvNumber
    }
  }
`;

export const CANCEL_APPOINTMENT = gql`
  mutation CancelAppointment(
    $lawyer_id: ID!
    $client_id: ID!
    $appointment_id: ID!
    $appointmentDate: String!
    $appointmentHour: String!
    $meetingVal: String!
    $name: String!
    $date: String!
    $dayOfWeek: String!
    $description: String!
  ) {
    cancelAppointment(
      lawyer_id: $lawyer_id
      client_id: $client_id
      appointmentHour: $appointmentHour
      appointment_id: $appointment_id
      appointmentDate: $appointmentDate
      meetingVal: $meetingVal
      name: $name
      date: $date
      dayOfWeek: $dayOfWeek
      description: $description
    ) {
      message
      error
    }
  }
`;

export const UPDATE_APPOINTMENT = gql`
  mutation UpdateAppointment($input: SetAppointment!) {
    setAppointment(input: $input) {
      message
      error
    }
  }
`;

export const GET_REVIEWS_AVERAGE = gql`
  query GetAverageReviewOfLawyer($lawyer_id: ID!) {
    averageReviewsOfLawyer(lawyer_id: $lawyer_id) {
      averageReviewNr
    }
  }
`;

export const GET_APOINTMENTS = gql`
  query GetAppointments($id: ID!, $role: String!) {
    appointments(id: $id, role: $role) {
      id
      client_name
      lawyer_name
      time
      date
      appointment_type
      appointment_location
      appointment_subject
      appointment_description
      appointment_phone_number
      specialist_attourneys
      client_id
      lawyer_id
      isCancelled
      lawyer {
        title
        first_name
        last_name
        house_number
        street
        location
        postcode
        country
        telephone
        subscription
        lawyerAvailability {
          show_availability
          personally
          by_phone
          online_meeting
        }
        profile_image
      }
      client {
        email
        telephone
        profileImage
      }
    }
  }
`;

export const GET_REVIEWS_OF_LAWYER = gql`
  query GetReviewOfLawyer($lawyer_id: ID!) {
    getReviewsOfLawyer(lawyer_id: $lawyer_id) {
      id
      client_name
      lawyer_name
      rating
      content
      client_id
      lawyer_id
      createdAt
      comments {
        name
        content
        lawyer_id
        review_id
      }
    }
  }
`;

export const GET_REV_NUMBER = gql`
  query GetReviewNumber($lawyer_id: ID!) {
    getReviewNumber(lawyer_id: $lawyer_id) {
      RvNumber
    }
  }
`;

export const VERIFY_USER_PASSWORD = gql`
  mutation verifyUserPassword($email: String!, $password: String!) {
    verifyUserPassword(email: $email, password: $password) {
      message
      error
    }
  }
`;
export const UPDATE_USER_PASSWORD = gql`
  mutation changeUserPassword($email: String!, $password: String!) {
    changeUserPassword(email: $email, password: $password) {
      message
      error
    }
  }
`;
