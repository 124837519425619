import React from 'react';

import AvatarPlaceholder from "Assets/pictures/AvatarProfile.png";

const LawyerReviewImage = (props) => {

    return (
        <div className='lawp-review-card-lawyer-image-container'>
            <div >
                <img
                    src={props.image !== null ? props.image : AvatarPlaceholder}
                    alt="avatar"
                    className='lawp-review-card-lawyer-image' />
            </div>
        </div>
    )
}

export default LawyerReviewImage;