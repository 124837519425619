import React from 'react';
import ReactDOM from 'react-dom';

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';
import lawyersReducer from './Services/Redux/reducers/lawyer';
import clientInfoReducer from './Services/Redux/reducers/clientInfo';
import filtersReducer from './Services/Redux/reducers/filters';
import registerReducer from 'Services/Redux/reducers/RegisterReducer';
import switchCheck from 'Services/Redux/reducers/switchCheck';

import 'index.css';

const reducers = combineReducers({
  lawyers: lawyersReducer,
  clientInfo: clientInfoReducer,
  filters: filtersReducer,
  registerStepper: registerReducer,
  switchCheck: switchCheck,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

let persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
