import React, { useState, useRef, useEffect } from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

import { AreasOfLaw_Color } from 'Services/Utils/areaOfLaws';

import 'Assets/styles/lawyer/EditAutoComplete.scss';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option) => option.title,
});

/**
 * @param {{dataArray: [{title: String, selected: 0|1}], title: String, textFieldWidth: Number, noPadding: boolean, noMargin: boolean}} props
 * @returns {JSX.Element}
 */
export default function AutoCompleteFixedValues(props) {
  const { dataArray, title, textFieldWidthPercent, noPadding, noMargin } =
    props;
  const [values, setValues] = useState(dataArray);
  const [clearTextfield, setClearTextfield] = useState(false);

  const widthTextField =
    textFieldWidthPercent !== undefined ? `${textFieldWidthPercent}%` : 300;

  const containerRef = useRef(null);

  const handleChangeMultiple = (value) => {
    setClearTextfield(!clearTextfield);

    value.selected = 1;
    let newArray = [...dataArray];
    setValues(newArray);

    let newValue = [];
    for (let i = 0; i < newArray.length; i++) {
      if (newArray[i].selected === 1) {
        newValue.push(newArray[i].title);
      }
    }
    props.onChange(newValue);
  };

  const removeTag = (value) => {
    value.selected = 0;
    let newArray = [...dataArray];
    setValues(newArray);

    let newValue = [];
    for (let i = 0; i < newArray.length; i++) {
      if (newArray[i].selected === 1) {
        newValue.push(newArray[i].title);
      }
    }
    props.onChange(newValue);
  };

  useEffect(() => {
    setValues(dataArray);
  }, [dataArray]);

  const SelectedTagJSX = (value, key) => {
    return (
      <div
        className="editLawyerTagContainer"
        style={{
          color:
            props.optionsAreAreasOfLaw && AreasOfLaw_Color[value.title]
              ? AreasOfLaw_Color[value.title].color
              : '#000000',
          backgroundColor:
            props.optionsAreAreasOfLaw && AreasOfLaw_Color[value.title]
              ? AreasOfLaw_Color[value.title].background
              : '#F2F4F7',
        }}
        key={key}
      >
        <div className="editLawyerTagAlignDiv">
          <p className="editLawyerTagText">{value.title}</p>
          <div
            data-cy={
              props.delete_area_of_law_data_cy
                ? props.delete_area_of_law_data_cy
                : props.delete_legal_field_data_cy
            }
            onClick={() => removeTag(value)}
            style={{ display: 'inline-block' }}
          >
            <ClearRoundedIcon
              className="editLawyerTagRemoveIcon"
              style={{
                color:
                  props.optionsAreAreasOfLaw && AreasOfLaw_Color[value.title]
                    ? AreasOfLaw_Color[value.title].color
                    : '#000000',
                marginBottom: '4px',
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="editLawyerContainerAutoComplete"
      style={{
        width: widthTextField,
        padding: noPadding ? 0 : {},
        margin: noMargin ? 0 : {},
      }}
    >
      <p className="editLawyerTitleAutoComplete"> {title} </p>
      <Autocomplete
        data-cy={
          props.areas_of_law_data_cy
            ? props.areas_of_law_data_cy
            : props.legal_fields_data_cy
        }
        key={clearTextfield}
        ref={containerRef}
        disablePortal
        clearOnEscape
        className="editLawyerAutoCompleteStyling"
        id="filter-demo"
        options={dataArray}
        getOptionLabel={(option) => option.title}
        filterOptions={filterOptions}
        popupIcon={<KeyboardArrowDownIcon />}
        onChange={(event, value) => {
          if (value !== null) handleChangeMultiple(value);
        }}
        renderInput={(params) => (
          <TextField {...params} className="editLawyerTextFieldAutoComplete" />
        )}
      />

      {values.map((value, key) => {
        if (value.selected === 1) {
          return SelectedTagJSX(value, key);
        }

        return null;
      })}
    </div>
  );
}
