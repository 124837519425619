import React from 'react';

import EditLawyerPhoneInput from 'Components/Lawyer/Edit/EditLawyerPhoneInput';

import SimpleTextField from 'Components/Inputs/SimpleTextField';
import AutoCompleteTextFieldSingleSelected from 'Components/Lawyer/Edit/AutoCompleteFixedValuesSingleSelect';
import AutocompleteAdress from 'Components/RegisterClient/AutocompleteAdress';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/client/settings/EditClientProfileInformation.module.scss';

const EditClientProfileInformation = (props) => {
  const salutationData = [
    { title: 'Herr', selected: 0 },
    { title: 'Frau', selected: 0 },
    { title: 'Divers', selected: 0 },
  ];

  const {
    salutation,
    title,
    firstName,
    lastName,
    location,
    house_number,
    zipcode,
    city,
    telephone,
    clientInformationErrorMessages,
  } = props;
  const {
    onChangeSalutation,
    onChangeTitle,
    onChangeFirstName,
    onChangeLastName,
    onChangeAddressValue,
    onChangeHouseNumber,
    onChangeZipCode,
    onChangeCity,
    onChangeTelephone,
  } = props;

  return (
    <div className={styles['edit-client-profile-information-container']}>
      <div
        className={styles['edit-client-profile-information-full-section']}
        style={{ alignItems: 'flex-end' }}
      >
        <div
          style={{
            width: '145px',
            marginRight: '20px',
            display: 'flex',
            gap: '5px',
          }}
        >
          <AutoCompleteTextFieldSingleSelected
            title={LPTranslate('Input_Salutation')}
            onChange={(value) => onChangeSalutation(value)}
            placeholder={`${LPTranslate('Input_Salutation')}*`}
            selected={salutation}
            dataArray={salutationData}
            textFieldWidthPercent={100}
            errorMessage={clientInformationErrorMessages.salutation}
          />
        </div>
        <div style={{ width: '97px', marginRight: '20px' }}>
          <SimpleTextField
            data_cy="title-input"
            title={LPTranslate('Input_Title')}
            onChange={(value) => onChangeTitle(value)}
            value={title}
            textFieldWidthPercent={100}
          />
        </div>
      </div>
      <div
        className={styles['edit-client-profile-information-full-section']}
        style={{ marginTop: '20px' }}
      >
        <div style={{ width: '50%', marginRight: '20px' }}>
          <SimpleTextField
            data_cy="first-name-input"
            title={`${LPTranslate('ULPP_Profile_Input_First_Name')}*`}
            onChange={(value) => onChangeFirstName(value)}
            value={firstName}
            textFieldWidthPercent={100}
            errorMessage={clientInformationErrorMessages.firstName}
          />
        </div>
        <div style={{ width: '50%' }}>
          <SimpleTextField
            data_cy="last-name-input"
            title={`${LPTranslate('ULPP_Profile_Input_Last_Name')}*`}
            onChange={(value) => onChangeLastName(value)}
            value={lastName}
            textFieldWidthPercent={100}
            errorMessage={clientInformationErrorMessages.lastName}
          />
        </div>
      </div>
      <div
        className={styles['edit-client-profile-information-location']}
        style={{ marginTop: '10px' }}
      >
        <div
          className={styles['edit-client-profile-information-address-input']}
        >
          <div
            style={{ width: '80%', marginRight: '20px' }}
            className={
              styles['edit-client-profile-information-input-with-label']
            }
          >
            <AutocompleteAdress
              addressValue={location}
              onChangeAddressValue={onChangeAddressValue}
              textFieldWidthPercent={100}
              onChangeZipCode={onChangeZipCode}
              onChangeCity={onChangeCity}
              isSettingAppointment={false}
              title={LPTranslate('Input_Address')}
              errorMessage={clientInformationErrorMessages.home_address}
            />
          </div>
          <div
            style={{ width: '16%' }}
            className={
              styles['edit-client-profile-information-input-with-label']
            }
          >
            <SimpleTextField
              data_cy="nr-input"
              title="Nr."
              onChange={(value) => onChangeHouseNumber(value)}
              value={house_number}
              textFieldWidthPercent={100}
              errorMessage={clientInformationErrorMessages.house_number}
            />
          </div>
        </div>

        <div
          className={styles['edit-client-profile-information-zip-code-input']}
        >
          <div
            className={
              styles['edit-client-profile-information-input-with-label']
            }
          >
            <SimpleTextField
              data_cy="zip-code-input"
              title={LPTranslate('Input_Postcode_Placeholder')}
              onChange={(value) => onChangeZipCode(value)}
              value={zipcode}
              textFieldWidthPercent={100}
              errorMessage={clientInformationErrorMessages.zipcode}
            />
          </div>
        </div>

        <div className={styles['edit-client-profile-information-city-input']}>
          <div
            className={
              styles['edit-client-profile-information-input-with-label']
            }
          >
            <SimpleTextField
              data_cy="city-input"
              title={LPTranslate('Input_City')}
              onChange={(value) => onChangeCity(value)}
              value={city}
              textFieldWidthPercent={100}
              errorMessage={clientInformationErrorMessages.location}
            />
          </div>
        </div>
      </div>
      <div className={styles['edit-client-profile-information-phone-section']}>
        <EditLawyerPhoneInput
          value={telephone}
          onChange={(value) => onChangeTelephone(value)}
          title={LPTranslate('Input_Telephone')}
          errorMessage={clientInformationErrorMessages.telephone}
        />
      </div>
    </div>
  );
};

export default EditClientProfileInformation;
