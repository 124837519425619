import { useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';

import { REFRESH_TOKEN } from 'Services/Queries/user';
import { parseJwt } from 'Services/Utils/token';
import { addAll, resetClientInfo } from 'Services/Redux/reducers/clientInfo';
import {
  isTokenExpired,
  getTokenInfo,
  removeTokenInfo,
} from 'Services/Utils/token';

const LoggedInMiddleware = () => {
  const [refreshToken] = useMutation(REFRESH_TOKEN, {
    fetchPolicy: 'network-only',
  });

  const dispatch = useDispatch();

  const handleRefreshToken = async (token, refresh_token) => {
    try {
      if (token && refresh_token) {
        const response = await refreshToken({
          variables: {
            input: {
              token: token,
              refresh_token: refresh_token,
            },
          },
        });

        if (
          response.data.refreshToken &&
          response.data.refreshToken.status === '200'
        ) {
          let jwtToken = response.data.refreshToken.jwtToken;
          let refresh_token = response.data.refreshToken.refresh_token;

          const token_decoded = parseJwt(jwtToken);

          let tokenInfo = {
            token: jwtToken,
            refresh_token: refresh_token,
            role: token_decoded.role,
            email: token_decoded.email,
            name: token_decoded.username,
            first_name: token_decoded.first_name,
            title: token_decoded.title,
            last_name: token_decoded.last_name,
            user_id: parseInt(token_decoded.user_id),
            role_id: parseInt(token_decoded.role_id),
          };

          removeTokenInfo();
          localStorage.setItem('anwadoTokenInfo', JSON.stringify(tokenInfo));

          dispatch(addAll(tokenInfo));
        } else {
          removeTokenInfo();

          window.location.href = '/login';
        }
      }
    } catch (error) {
      console.log('Router error', error);
    }
  };

  useEffect(() => {
    const tokenInfo = getTokenInfo();
    const tokenInfoParsed = JSON.parse(tokenInfo);

    if (tokenInfoParsed) {
      dispatch(addAll(tokenInfoParsed));
    } else {
      dispatch(resetClientInfo());
      return;
    }

    let checkToken = async () => {
      const tokenExpired = await isTokenExpired();

      if (tokenExpired)
        handleRefreshToken(
          tokenInfoParsed.token,
          tokenInfoParsed.refresh_token
        );
    };

    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default LoggedInMiddleware;
