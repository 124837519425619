import React, { useState, useEffect } from 'react';

import { useParams, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';

import CircularProgress from '@mui/material/CircularProgress';

import RegisterCarousel from 'Components/RegisterClient/RegisterCarousel';
import RegisterFormComponent from 'Components/RegisterClient/RegisterFormComponent';
import VerifyNewClientProcess from 'Components/RegisterClient/VerifyNewClientProcess';
import MetaDecorator from 'Layouts/MetaDecorator';

import {
  addFirstname,
  addLastname,
  addRegisterEmail,
  setClientStepper,
} from 'Services/Redux/reducers/RegisterReducer';

import { parseJwt } from 'Services/Utils/token';
import { VERIFY_PARAM_TOKEN_MUTATION } from 'Services/Queries/user';
import {
  CHECK_IF_CLIENT_EXISTS,
  REGISTER_EMAIL_PASSWORD_CLIENT,
  GENERATE_OTP_CODE,
} from 'Services/Queries/client';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import { validateEmail } from 'Services/Utils/validations';
import registerSteps from 'Services/registerClientSteps';

import styles from 'Assets/styles/LoginPage.module.scss';

const RegisterClientPage = () => {
  let location = useLocation();
  const dispatch = useDispatch();

  const [
    checkIfClientExists,
    { data: checkIfClientExistsData, loading: checkIfClientExistsLoading },
  ] = useMutation(CHECK_IF_CLIENT_EXISTS);
  const [registerUserClient, { loading: registerUserClientLoading }] =
    useMutation(REGISTER_EMAIL_PASSWORD_CLIENT);
  const [verifyParamToken, verifyParamTokenReturn] = useMutation(
    VERIFY_PARAM_TOKEN_MUTATION
  );

  const [errorMessage, setErrorMessage] = useState('');

  const registerStepperRedux = useSelector(
    (state) => state.registerStepper.value
  );

  const [generateOtpCode, { loading: generateOtpCodeLoading }] =
    useMutation(GENERATE_OTP_CODE);

  const [registerFromOtherComponent, setRegisterFromOtherComponent] =
    useState(false);

  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const [tokenAccepted, setTokenAccepted] = useState(false);
  const [marketingAccepted, setMarketingAccepted] = useState(false);
  const { token } = useParams();

  const handleInputChange = (inputName, value) => {
    switch (inputName) {
      case 'firstName':
        setFormValues({ ...formValues, firstName: value });
        break;
      case 'lastName':
        setFormValues({ ...formValues, lastName: value });
        break;
      case 'email':
        setFormValues({ ...formValues, email: value });
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (location.state) {
      // handleInputChange('firstName', location.state.firstname);
      // handleInputChange('lastName', location.state.lastname);
      // handleInputChange('email', location.state.email);

      dispatch(addFirstname(location.state.firstname));
      dispatch(addLastname(location.state.lastname));
      dispatch(addRegisterEmail(location.state.email));

      setRegisterFromOtherComponent(location.state.registerFromOtherComponent);

      registerSteps[0].isChecked = false;
      registerSteps[1].isChecked = false;
      registerSteps[2].isChecked = false;
      registerSteps[3].isChecked = false;
      registerSteps[4].isChecked = false;

      dispatch(setClientStepper(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const verify = async () => {
      if (token) {
        try {
          let verifyToken = await verifyParamToken({
            variables: { input: { token: token } },
          });

          if (!verifyToken) {
            setErrorMessage(LPTranslate('Error_Server_Down'));
          }
        } catch (error) {
          setErrorMessage(LPTranslate('Error_Server_Down'));
        }
      }
    };

    verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    let newInputErrors = {};
    let isError = false;
    if (
      checkIfClientExistsData &&
      checkIfClientExistsData.checkIfClientExists
    ) {
      if (
        checkIfClientExistsData.checkIfClientExists.error ===
        'Client is already registered and verified!'
      ) {
        isError = true;
        newInputErrors.email = LPTranslate('Error_Already_Existing_Email');
      } else if (
        checkIfClientExistsData.checkIfClientExists.error ===
        'Another account is already signed up'
      ) {
        isError = true;
        setErrorMessage(
          LPTranslate('Error_Register_With_Already_Existing_Account')
        );
      }
    }

    if (isError) {
      setFormErrors(newInputErrors);
    }
  }, [checkIfClientExistsData]);

  useEffect(() => {
    const verify = async () => {
      if (
        verifyParamTokenReturn.data &&
        verifyParamTokenReturn.data.verifyParamTokenMutation
      ) {
        if (
          verifyParamTokenReturn.data.verifyParamTokenMutation.status ===
          'error' &&
          registerStepperRedux.clientStepper < 3
        ) {
          console.log(
            'verify param token: ',
            verifyParamTokenReturn.data.verifyParamTokenMutation
          );

          setErrorMessage(LPTranslate('Error_Invalid_Token'));
          dispatch(setClientStepper(0));
        } else if (
          verifyParamTokenReturn.data.verifyParamTokenMutation.message ===
          'Token accepted'
        ) {
          console.log(
            'verify param token: ',
            verifyParamTokenReturn.data.verifyParamTokenMutation
          );

          console.log('proceeding to register client steps');
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'client-email-verified-link-custom-event',
          });
          handleInputChange('firstName', parseJwt(token).firstname);
          handleInputChange('lastName', parseJwt(token).lastname);
          dispatch(setClientStepper(1));
          setTokenAccepted(true);
        }
      }
    };
    verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyParamTokenReturn.data]);

  useEffect(() => { }, [formErrors]);

  if (checkIfClientExists.loading) return 'Submitting...';
  if (checkIfClientExists.error)
    return `Submission error! ${checkIfClientExists.error.message}`;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let isError = false;
      let newInputErrors = {};

      if (checkIfClientExistsLoading) return;
      if (registerUserClientLoading) return;
      if (generateOtpCodeLoading) return;

      if (formValues.email.length === 0) {
        isError = true;
        newInputErrors.email = LPTranslate('Error_Email_Required');
      } else if (!validateEmail(formValues.email)) {
        isError = true;
        newInputErrors.email = LPTranslate('Error_Valid_Email');
      }

      if (formValues.firstName.length === 0) {
        isError = true;
        newInputErrors.firstName = LPTranslate('Error_First_Name_Required');
      } else if (formValues.firstName.length > 128) {
        isError = true;
        newInputErrors.firstName = LPTranslate('Error_First_Name_Too_Long');
      }
      if (formValues.lastName.length === 0) {
        isError = true;
        newInputErrors.lastName = LPTranslate('Error_Last_Name_Required');
      } else if (formValues.lastName.length > 128) {
        isError = true;
        newInputErrors.lastName = LPTranslate('Error_Last_Name_Too_Long');
      }

      setFormErrors(newInputErrors);

      if (isError) return;
      if (checkIfClientExistsLoading) return;

      let check = await checkIfClientExists({
        variables: {
          firstName: formValues.firstName.trim(),
          lastName: formValues.lastName.trim(),
          email: formValues.email.trim(),
        },
      });

      setFormErrors(newInputErrors);

      if (isError) return;

      if (check.data.checkIfClientExists.message === "Client doesn't exist!") {
        let user = await registerUserClient({
          variables: {
            firstName: formValues.firstName.trim(),
            lastName: formValues.lastName.trim(),
            email: formValues.email.trim(),
            role: 'client',
            marketingAccepted: marketingAccepted,
          },
        });

        if (
          user.data.registerClient.message === 'Data has been sent to database!'
        ) {
          registerSteps[0].isChecked = false;
          registerSteps[1].isChecked = false;
          registerSteps[2].isChecked = false;
          registerSteps[3].isChecked = false;
          registerSteps[4].isChecked = false;

          try {
            let generateCode = await generateOtpCode({
              variables: {
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                email: formValues.email,
              },
            });

            if (generateCode.data.generateClientOtpCode.message) {
              dispatch(addFirstname(formValues.firstName));
              dispatch(addLastname(formValues.lastName));
              dispatch(addRegisterEmail(formValues.email));
              dispatch(setClientStepper(1));
            }
          } catch (error) {
            setErrorMessage(LPTranslate('Error_Server_Down'));
          }
        } else {
          setErrorMessage(LPTranslate('Error_Server_Down'));
        }
      } else if (
        check.data.checkIfClientExists.message ===
        'Client is activating the account'
      ) {
        registerSteps[0].isChecked = false;
        registerSteps[1].isChecked = false;
        registerSteps[2].isChecked = false;
        registerSteps[3].isChecked = false;
        registerSteps[4].isChecked = false;

        try {
          let generateCode = await generateOtpCode({
            variables: {
              firstName: formValues.firstName,
              lastName: formValues.lastName,
              email: formValues.email,
            },
          });

          if (generateCode.data.generateClientOtpCode.message) {
            dispatch(addFirstname(formValues.firstName));
            dispatch(addLastname(formValues.lastName));
            dispatch(addRegisterEmail(formValues.email));
            dispatch(setClientStepper(1));
          }
        } catch (error) { }
      }
    } catch (error) { }
  };

  if (verifyParamTokenReturn.loading === true) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '200px',
            marginBottom: '200px',
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      </>
    );
  }

  return (
    <>
      {registerStepperRedux.clientStepper === 0 && (
        <>
          <MetaDecorator
            title={LPTranslate('RC_MetaDecorator_Title')}
            description={LPTranslate('RC_MetaDecorator_Description')}
            canonical={'canonical'}
            link={`${process.env.REACT_APP_DNS_URI}/registerClient`}
          />
          <div className={styles['register-client-form-carousel-container']}>
            <RegisterFormComponent
              handleSubmit={handleSubmit}
              checkIfClientExistsLoading={checkIfClientExistsLoading}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              errorMessage={errorMessage}
              formValues={formValues}
              handleInputChange={handleInputChange}
              marketingAccepted={marketingAccepted}
              setMarketingAccepted={setMarketingAccepted}
            />
            <RegisterCarousel />
          </div>
        </>
      )}

      {registerStepperRedux.clientStepper >= 1 && (
        <VerifyNewClientProcess
          tokenAccepted={tokenAccepted}
          token={token}
          registerFromOtherComponent={registerFromOtherComponent}
        />
      )}
    </>
  );
};

export default RegisterClientPage;
