import React, { useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client';

import ReviewCard from 'Components/Lawyer/ReviewCard';
import AddLawyerReviewModal from 'Components/Lawyer/AddLawyerReviewModal';
import DeleteReviewModal from 'Components/Lawyer/DeleteReviewModal';
import SettingsMenu from 'Components/CommonComponents/SettingsMenu';
import ApiResponseModal from 'Components/Modals/ApiResponseModal';

import { GET_REVIEWS_OF_CLIENT } from 'Services/Queries/client';
import { DELETE_REVIEW } from 'Services/Queries/client';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/client/ClientReviews.scss';

const ClientReviews = () => {
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const [apiIsError, setApiIsError] = useState(false);
  const [apiResponseModalOpen, setApiResponseModalOpen] = useState(false);
  const [apiResponseModalTitle, setApiResponseModalTitle] = useState('');
  const apiResponseModalDescription = '';

  const [lawyer, setLawyer] = useState({
    id: 0,
    first_name: '',
    last_name: '',
    areasOfLaw: [],
    rating: '',
    reviews_count: '',
    profile_picture: '',
    specialities: [],
  });
  const [selectedReview, setSelectedReview] = useState({
    lawyer: { rating: null },
  });
  const [removeLawyerReviewModalOpen, setRemoveLawyerReviewModalOpen] =
    useState(false);
  const [AddLawyerReviewModalOpen, setAddLawyerReviewModalOpen] =
    useState(false);
  const [reviews, setReviews] = useState([]);

  const [deleteReview] = useMutation(DELETE_REVIEW);

  const [reviewsQuery, { data }] = useLazyQuery(GET_REVIEWS_OF_CLIENT, {
    fetchPolicy: 'network-only',
  });

  const [lawyer_id, setLawyerId] = useState(0);

  const [ratingData, setRatingData] = useState({
    reviewTitle: '',
    reviewText: '',
    rating: '',
  });

  const refetchReviews = useCallback(async () => {
    reviewsQuery({
      variables: {
        client_id: clientInfoRedux.role_id,
      },
    });
  }, [reviewsQuery, clientInfoRedux.role_id]);

  useEffect(() => {
    refetchReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratingData]);

  useEffect(() => {
    if (data) {
      setReviews(data);
    }
  }, [data]);

  const openUpdateModal = (lawyerData) => {
    setLawyer({
      id: lawyerData.lawyer.id,
      title: lawyerData.lawyer.title,
      first_name: lawyerData.lawyer.first_name,
      last_name: lawyerData.lawyer.last_name,
      title_suffix: lawyerData.lawyer.title_suffix,
      rating: lawyerData.lawyer.rating,
      reviews_count: lawyerData.lawyer.review_count,
      areasOfLaw: lawyerData.lawyer.areas_of_law,
      profileImage: lawyerData.lawyer.profile_image,
      specialities: lawyerData.lawyer.specialities,
    });
    setAddLawyerReviewModalOpen(true);
  };

  const openDeleteModal = (reviewData) => {
    setSelectedReview(reviewData);
    setRemoveLawyerReviewModalOpen(true);
  };

  const onDeleteReview = async (reviewID, message) => {
    try {
      await deleteReview({
        variables: {
          input: {
            review_id: reviewID,
            delete_reason: message,
          },
        },
      });

      refetchReviews();
      setRemoveLawyerReviewModalOpen(false);

      setApiIsError(false);
      setApiResponseModalOpen(true);
      setApiResponseModalTitle(LPTranslate('RS_Review_Deleted'));
      // setApiResponseModalDescription(LPTranslate(''));
      // it causes: Cannot read properties of undefined (reading 'en')
    } catch (error) {
      setApiIsError(true);
      setApiResponseModalOpen(true);
      setApiResponseModalTitle(LPTranslate('RS_Api_Error'));
      // setApiResponseModalDescription(LPTranslate(''));
      // it causes: Cannot read properties of undefined (reading 'en')
    }
  };

  return (
    <div className="lawp-client-reviews-client-profile-settings-page-container ">
      <div className="lawp-client-reviews-client-profile-settings-top-menu">
        <SettingsMenu
          setUpdateComponent={'/updateClientProfile'}
          role={clientInfoRedux.role}
        />
      </div>

      {reviews.reviewsOfClient && reviews.reviewsOfClient.length > 0 ? (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row',
              flexWrap: 'wrap',
              width: '100%',
            }}
          >
            {reviews.reviewsOfClient &&
              reviews.reviewsOfClient.map((review, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="lawp-client-reviews-client-profile-card-container">
                      <ReviewCard
                        review={review}
                        lawyer_id={lawyer_id}
                        setLawyerId={setLawyerId}
                        onUpdate={(lawyerData) => openUpdateModal(lawyerData)}
                        onDelete={(reviewData) => openDeleteModal(reviewData)}
                        onRefetch={() => refetchReviews()}
                      />
                    </div>
                  </React.Fragment>
                );
              })}
          </div>

          <AddLawyerReviewModal
            open={AddLawyerReviewModalOpen}
            handleClose={() => setAddLawyerReviewModalOpen(false)}
            lawyer={lawyer}
            averageNr={lawyer.rating}
            setAverageNr={() => {}}
            reviewsNr={lawyer.reviews_count}
            setReviewsNr={() => {}}
            setRatingData={setRatingData}
            onComplete={() => refetchReviews()}
          />

          <DeleteReviewModal
            open={removeLawyerReviewModalOpen}
            handleClose={() => setRemoveLawyerReviewModalOpen(false)}
            review={selectedReview}
            onDelete={onDeleteReview}
          />
        </>
      ) : (
        <>
          <div className="lawp-client-reviews-no-reviews-container">
            <p className="lawp-client-reviews-no-reviews-container-text">
              {LPTranslate('Error_No_Reviews')}
            </p>
          </div>
        </>
      )}

      <ApiResponseModal
        show={apiResponseModalOpen}
        isError={apiIsError}
        titleMessage={apiResponseModalTitle}
        hintMessage={apiResponseModalDescription}
        buttonText={LPTranslate('RS_Confirm_Button_Message')}
        handleClose={() => setApiResponseModalOpen(false)}
      />
    </div>
  );
};

export default ClientReviews;
