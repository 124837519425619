import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import 'Assets/styles/tables/ReviewReportsTable.scss';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_REVIEW,
  GET_LAWYER,
  GET_CLIENT,
  DELETE_REVIEW,
  DELETE_REVIEW_REPORT,
  GET_ALL_REVIEW_REPORTS,
} from 'Services/Queries/operator';

import {
  millisecondsToTimeAgo,
  milisecondsToDDMMYYYY,
} from 'Services/Utils/moment';

const columns = [
  { id: 'report_id', label: 'Report ID', minWidth: 120 },
  { id: 'created_at', label: 'Created At', minWidth: 170 },
  {
    id: 'report_reason',
    label: 'Report Reason',
    minWidth: 170,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'report_message',
    label: 'Report Message',
    minWidth: 170,
    maxWidth: 200,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 170,
    align: 'left',
    format: (value) => value.toFixed(2),
  },
];

const ReviewModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
  maxHeight: '80%',
  overflow: 'auto',
  border: 'none',
  borderRadius: '10px',
  fontFamily: 'Inter',
};

const EmailSubjectAndBody = {
  SubjectDeleteReport: 'Review Report Deleted',
  BodyDeleteReport: 'Your review report has been deleted by the operator.',
  SubjectDeleteReview: 'Review Deleted',
  BodyDeleteReview: 'Your review has been deleted by the operator.',
};

export default function StickyHeadTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [numberOfReports, setNumberOfReports] = React.useState(0);
  const [rows, setRows] = React.useState([]);

  const [reviewReports, setReviewReports] = React.useState([]);

  const [reviewReportsQuery, { data: reviewReportsDTO }] = useLazyQuery(
    GET_ALL_REVIEW_REPORTS
  );

  React.useEffect(() => {
    reviewReportsQuery({
      variables: {
        input: {
          page: page,
          limit: rowsPerPage,
        },
      },
    });
  }, [page, reviewReportsQuery, rowsPerPage]);

  React.useEffect(() => {
    if (reviewReportsDTO) {
      setReviewReports(reviewReportsDTO.reviewReports.reports);
      setNumberOfReports(reviewReportsDTO.reviewReports.number_of_reports);
    }
  }, [reviewReportsDTO]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    if (reviewReports.length > 0) {
      const rowsData = [];
      reviewReports.forEach((report) => {
        rowsData.push({
          report_id: report.id,
          created_at: millisecondsToTimeAgo(report.createdAt),
          report_reason: report.report_reason,
          report_message: report.report_message,

          review_id: report.review_id,
          lawyer_id: report.lawyer_id,
          client_id: report.client_id,
        });
      });

      setRows(rowsData);
    }
  }, [reviewReports]);

  const [profileImageLawyer, setProfileImageLawyer] = React.useState(null);
  const [profileImageClient, setProfileImageClient] = React.useState(null);

  //Report View Data:
  const [report, setReport] = React.useState({});
  const [reportModalOpen, setReportModalOpen] = React.useState(false);
  const handleOpenReportModal = (e, report_id) => {
    const reportData = reviewReports.find((report) => report.id === report_id);

    setReport(reportData);

    setReportModalOpen(true);

    e.stopPropagation();
  };
  const handleCloseReportModal = () => setReportModalOpen(false);

  //Review View Data:
  const [getReview, { data: reviewData }] = useLazyQuery(GET_REVIEW);
  const [reviewModalOpen, setReviewModalOpen] = React.useState(false);
  const handleOpenReviewModal = (e, id) => {
    setReviewModalOpen(true);
    getReview({ variables: { id: id } });

    e.stopPropagation();
  };
  const handleCloseReviewModal = () => setReviewModalOpen(false);

  //Client View Data:
  const [getClient, { data: clientData }] = useLazyQuery(GET_CLIENT);
  const [clientModalOpen, setClientModalOpen] = React.useState(false);
  const handleOpenClientModal = (e, id) => {
    setClientModalOpen(true);
    getClient({ variables: { id: id } });
    e.stopPropagation();
  };
  const handleCloseClientModal = () => setClientModalOpen(false);

  React.useEffect(() => {
    if (clientData && clientData.clientByOperator.profileImage) {
      setProfileImageClient(clientData.clientByOperator.profileImage);
    } else {
      setProfileImageClient(null);
    }
  }, [clientData]);

  //Lawyer View Data:
  const [getLawyer, { data: lawyerData }] = useLazyQuery(GET_LAWYER);
  const [lawyerModalOpen, setLawyerModalOpen] = React.useState(false);
  const handleOpenLawyerModal = (e, id) => {
    setLawyerModalOpen(true);
    getLawyer({ variables: { id: id } });
    e.stopPropagation();
  };
  const handleCloseLawyerModal = () => setLawyerModalOpen(false);

  React.useEffect(() => {
    if (lawyerData && lawyerData.lawyerByOperator.profile_image) {
      setProfileImageLawyer(lawyerData.lawyerByOperator.profile_image);
    } else {
      setProfileImageLawyer(null);
    }
  }, [lawyerData]);

  //Delete Review Report:
  const [deleteReviewReport] = useMutation(DELETE_REVIEW_REPORT, {
    onCompleted: () => {
      window.location.reload();
    },
  });
  const [deleteReportId, setDeleteReportId] = React.useState(null);
  const [deleteReportModalOpen, setDeleteReportModalOpen] =
    React.useState(false);
  const handleOpenDeleteReportModal = (e, id, lawyer_id, client_id) => {
    getLawyer({ variables: { id: lawyer_id } });
    getClient({ variables: { id: client_id } });

    setDeleteReportModalOpen(true);
    setDeleteReportId(id);
    e.stopPropagation();
  };
  const handleCloseDeleteReportModal = () => setDeleteReportModalOpen(false);

  //Delete Review:
  const [deleteReview] = useMutation(DELETE_REVIEW, {
    onCompleted: () => {
      window.location.reload();
    },
  });
  const [deleteReviewId, setDeleteReviewId] = React.useState(null);
  const [deleteReviewModalOpen, setDeleteReviewModalOpen] =
    React.useState(false);
  const handleOpenDeleteReviewModal = (e, id, lawyer_id, client_id) => {
    getLawyer({ variables: { id: lawyer_id } });
    getClient({ variables: { id: client_id } });

    setDeleteReviewModalOpen(true);
    setDeleteReviewId(id);
    e.stopPropagation();
  };
  const handleCloseDeleteReviewModal = () => setDeleteReviewModalOpen(false);

  return (
    <Paper className="lawp-review-reports-table-container">
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                  }}
                >
                  <span className="table-head-text">{column.label}</span>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  onClick={(e) => {
                    handleOpenReportModal(e, row.report_id);
                  }}
                >
                  {columns.map((column) => {
                    const value = row[column.id];

                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          maxWidth: column.maxWidth,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {column.id === 'actions' ? (
                          <div className="button-container">
                            <button
                              className="table-action-button"
                              onClick={(e) => {
                                handleOpenReviewModal(e, row.review_id);
                              }}
                            >
                              Review
                            </button>

                            <button
                              className="table-action-button"
                              onClick={(e) => {
                                handleOpenClientModal(e, row.client_id);
                              }}
                            >
                              Client
                            </button>

                            <button
                              className="table-action-button"
                              onClick={(e) => {
                                handleOpenLawyerModal(e, row.lawyer_id);
                              }}
                            >
                              Lawyer
                            </button>

                            <button
                              className="table-action-button"
                              onClick={(e) => {
                                handleOpenDeleteReportModal(
                                  e,
                                  row.report_id,
                                  row.lawyer_id,
                                  row.client_id
                                );
                              }}
                            >
                              Delete Report
                            </button>
                            <button
                              className="table-action-button"
                              onClick={(e) => {
                                handleOpenDeleteReviewModal(
                                  e,
                                  row.review_id,
                                  row.lawyer_id,
                                  row.client_id
                                );
                              }}
                            >
                              Delete Review
                            </button>
                          </div>
                        ) : column.format && typeof value === 'number' ? (
                          column.format(value)
                        ) : (
                          <span className="table-body-text">{value}</span>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={numberOfReports}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Modal open={reportModalOpen} onClose={handleCloseReportModal}>
        <Box sx={ReviewModalStyle}>
          {report ? (
            <>
              <p>ID: {report.id}</p>
              <p>Review ID: {report.review_id}</p>
              <p>Client ID: {report.client_id}</p>
              <p>Lawyer ID: {report.lawyer_id}</p>
              <p>report_message: {report.report_message}</p>
              <p>report_reason: {report.report_reason}</p>
              <p>Created at: {milisecondsToDDMMYYYY(report.createdAt)}</p>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </Box>
      </Modal>

      <Modal open={reviewModalOpen} onClose={handleCloseReviewModal}>
        <Box sx={ReviewModalStyle}>
          {reviewData ? (
            <>
              <p>ID: {reviewData.review.id}</p>
              <p>Client ID: {reviewData.review.client_id}</p>
              <p>Lawyer ID: {reviewData.review.lawyer_id}</p>
              <p>Client Name: {reviewData.review.client_name}</p>
              <p>Lawyer Name: {reviewData.review.lawyer_name}</p>
              <p>Rating: {reviewData.review.rating}</p>
              <p>
                Created At: {milisecondsToDDMMYYYY(reviewData.review.createdAt)}
              </p>
              <p>Review: {reviewData.review.content}</p>
              <p>Response: {reviewData.review.response}</p>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </Box>
      </Modal>

      <Modal open={clientModalOpen} onClose={handleCloseClientModal}>
        <Box sx={ReviewModalStyle}>
          {clientData ? (
            <>
              {profileImageClient && (
                <img
                  src={profileImageClient}
                  alt="client"
                  style={{ width: 100, height: 100, objectFit: 'cover' }}
                />
              )}
              <p>id: {clientData.clientByOperator.id}</p>
              <p>salutation: {clientData.clientByOperator.salutation}</p>
              <p>title: {clientData.clientByOperator.title}</p>
              <p>title_suffix: {clientData.clientByOperator.title_suffix}</p>
              <p>first_name: {clientData.clientByOperator.first_name}</p>
              <p>last_name: {clientData.clientByOperator.last_name}</p>
              <p>location: {clientData.clientByOperator.location}</p>
              <p>email: {clientData.clientByOperator.email}</p>
              <p>emailalt: {clientData.clientByOperator.emailalt}</p>
              <p>home_address: {clientData.clientByOperator.home_address}</p>
              <p>house_number: {clientData.clientByOperator.house_number}</p>
              <p>zipcode: {clientData.clientByOperator.zipcode}</p>
              <p>telephone: {clientData.clientByOperator.telephone}</p>
              <p>user_id: {clientData.clientByOperator.user_id}</p>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </Box>
      </Modal>

      <Modal open={lawyerModalOpen} onClose={handleCloseLawyerModal}>
        <Box sx={ReviewModalStyle}>
          {lawyerData ? (
            <>
              {profileImageLawyer && (
                <img
                  src={profileImageLawyer}
                  alt="lawyer"
                  style={{ width: 100, height: 100, objectFit: 'cover' }}
                />
              )}
              <p>id: {lawyerData.lawyerByOperator.id}</p>
              <p>salutation: {lawyerData.lawyerByOperator.salutation}</p>
              <p>title: {lawyerData.lawyerByOperator.title}</p>
              <p>title_suffix: {lawyerData.lawyerByOperator.title_suffix}</p>
              <p>first_name: {lawyerData.lawyerByOperator.first_name}</p>
              <p>last_name: {lawyerData.lawyerByOperator.last_name}</p>
              <p>job_title: {lawyerData.lawyerByOperator.job_title}</p>
              <p>lawfirm_name: {lawyerData.lawyerByOperator.lawfirm_name}</p>
              <p>street: {lawyerData.lawyerByOperator.street}</p>
              <p>house_number: {lawyerData.lawyerByOperator.house_number}</p>
              <p>postcode: {lawyerData.lawyerByOperator.postcode}</p>
              <p>location: {lawyerData.lawyerByOperator.location}</p>
              <p>country: {lawyerData.lawyerByOperator.country}</p>
              <p>telephone: {lawyerData.lawyerByOperator.telephone}</p>
              <p>fax_number: {lawyerData.lawyerByOperator.fax_number}</p>
              <p>email: {lawyerData.lawyerByOperator.email}</p>
              <p>emailalt: {lawyerData.lawyerByOperator.emailalt}</p>
              <p>rating: {lawyerData.lawyerByOperator.rating}</p>
              <p>user_id: {lawyerData.lawyerByOperator.user_id}</p>
              <p>subscription: {lawyerData.lawyerByOperator.subscription}</p>
              <p>review_count: {lawyerData.lawyerByOperator.review_count}</p>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </Box>
      </Modal>

      <Modal
        open={deleteReportModalOpen}
        onClose={handleCloseDeleteReportModal}
      >
        <Box sx={ReviewModalStyle}>
          {deleteReportId && lawyerData && clientData ? (
            <>
              <p>
                Are you sure you want to delete the report with the id:{' '}
                {deleteReportId}
              </p>

              <p>
                <p
                  onClick={() => {
                    window.location.href = `mailto:${lawyerData.lawyerByOperator.email}?subject=${EmailSubjectAndBody.SubjectDeleteReport}&body=${EmailSubjectAndBody.BodyDeleteReport}`;
                  }}
                >
                  Lawyer Email:{' '}
                  <span style={{ cursor: 'pointer', color: '#7f56d9' }}>
                    {lawyerData.lawyerByOperator.email}
                  </span>
                </p>
                <p
                  onClick={() => {
                    window.location.href = `mailto:${clientData.clientByOperator.email}?subject=${EmailSubjectAndBody.SubjectDeleteReport}&body=${EmailSubjectAndBody.BodyDeleteReport}`;
                  }}
                >
                  Client Email:{' '}
                  <span style={{ cursor: 'pointer', color: '#7f56d9' }}>
                    {clientData.clientByOperator.email}
                  </span>
                </p>
              </p>

              <div className="lawp-review-reports-table-button-container">
                <button
                  className="button"
                  onClick={handleCloseDeleteReportModal}
                >
                  Cancel
                </button>
                <button
                  className="button"
                  onClick={() => {
                    deleteReviewReport({ variables: { id: deleteReportId } });
                  }}
                >
                  Delete
                </button>
              </div>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </Box>
      </Modal>

      <Modal
        open={deleteReviewModalOpen}
        onClose={handleCloseDeleteReviewModal}
      >
        <Box sx={ReviewModalStyle}>
          {deleteReviewId && lawyerData && clientData ? (
            <>
              <p>
                Are you sure you want to delete the review with the id:{' '}
                {deleteReviewId}
              </p>

              <p>
                <p
                  onClick={() => {
                    window.location.href = `mailto:${lawyerData.lawyerByOperator.email}?subject=${EmailSubjectAndBody.SubjectDeleteReview}&body=${EmailSubjectAndBody.BodyDeleteReview}`;
                  }}
                >
                  Lawyer Email:{' '}
                  <span style={{ cursor: 'pointer', color: '#7f56d9' }}>
                    {lawyerData.lawyerByOperator.email}
                  </span>
                </p>
                <p
                  onClick={() => {
                    window.location.href = `mailto:${clientData.clientByOperator.email}?subject=${EmailSubjectAndBody.SubjectDeleteReview}&body=${EmailSubjectAndBody.BodyDeleteReview}`;
                  }}
                >
                  Client Email:{' '}
                  <span style={{ cursor: 'pointer', color: '#7f56d9' }}>
                    {clientData.clientByOperator.email}
                  </span>
                </p>
              </p>

              <div className="lawp-review-reports-table-button-container">
                <button
                  className="button"
                  onClick={handleCloseDeleteReviewModal}
                >
                  Cancel
                </button>
                <button
                  className="button"
                  onClick={() => {
                    deleteReview({ variables: { id: deleteReviewId } });
                  }}
                >
                  Delete
                </button>
              </div>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </Box>
      </Modal>
    </Paper>
  );
}
