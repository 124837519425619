import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { VERIFY_USER_PASSWORD } from 'Services/Queries/common';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import SimpleTextField from 'Components/Inputs/SimpleTextField';
import SetLawyerNewEmailModal from 'Components/Modals/SetLawyerNewEmailModal';

import styles from 'Assets/styles/updateLawyerDetails/ConfirmNewEmailModal.module.scss';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UpdateLawyerEmailModal = (props) => {
  const navigate = useNavigate();

  const [verifyUserPassword, { data: verifyUserPasswordData }] =
    useMutation(VERIFY_USER_PASSWORD);
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);
  const [open, setOpen] = useState(false);

  const [formValues, setFormValues] = useState({ currentPassword: '' });
  const [formErrors, setFormErrors] = useState({ currentPassword: '' });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState(false);

  const [shownModal, setShownModal] = useState(0);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setFormValues({ currentPassword: '' });
    setFormErrors({ currentPassword: '' });
    setShownModal(0);
    setOpen(false);
  };

  const changeModal = () => {
    setShownModal(1);
  };

  const handleSubmit = () => {
    handleOpen();
  };

  const resetStatePassword = () => {
    setFormErrors({ currentPassword: '' });
    setFormValues({ currentPassword: '' });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleInputChange = (inputName, value) => {
    switch (inputName) {
      case 'currentPassword':
        setFormValues({ ...formValues, currentPassword: value });
        break;

      default:
        break;
    }
  };

  const checkPassword = async (e) => {
    e.preventDefault();

    let isError = false;
    let newInputErrors = { currentPassword: '' };

    if (formValues.currentPassword.length === 0) {
      isError = true;
      newInputErrors.currentPassword = LPTranslate(
        'Error_Actual_Password_Required'
      );
    } else if (formValues.currentPassword.length < 8) {
      isError = true;
      newInputErrors.currentPassword = LPTranslate(
        'Error_Actual_Password_Min_Length'
      );
    }

    setFormErrors(newInputErrors);

    if (isError) return;

    try {
      await verifyUserPassword({
        variables: {
          email: clientInfoRedux.email,
          password: formValues.currentPassword,
        },
      });
    } catch (error) {
      setSnackbarMessage(LPTranslate('Error_Server_Down'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      resetStatePassword();
    }
  };

  useEffect(() => {
    if (verifyUserPasswordData) {
      if (verifyUserPasswordData.verifyUserPassword.message.length > 0) {
        changeModal(1);
      } else if (verifyUserPasswordData.verifyUserPassword.error.length > 0) {
        setSnackbarMessage(LPTranslate('Error_Server_Down'));
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  }, [verifyUserPasswordData]);

  return (
    <>
      <div className={styles['button']}>
        <button
          className={styles['button-base']}
          onClick={handleSubmit}
          data-cy="update-email-button"
        >
          <p className={styles['button-text']}>
            {LPTranslate('AUS_Email_Update_Button')}
          </p>
        </button>
      </div>

      <Modal className={styles['modal']} open={open} onClose={handleClose}>
        <>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            autoHideDuration={2000}
            onClose={handleCloseSnackbar}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={snackbarSeverity}
              sx={
                snackbarSeverity === 'error' && {
                  backgroundColor: '#7f56d9 !important',
                }
              }
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
          {shownModal === 0 ? (
            <Box className={styles['box']}>
              <div className={styles['modal-container']}>
                <div className={styles['stepper-content']}>
                  <div className={styles['step-icon-base']}>
                    <div className={styles['selected-icon-content']}>
                      <div className={styles['selected-dot']} />
                    </div>
                  </div>

                  <div className={styles['unselected-line']}></div>

                  <div className={styles['unselected-step-icon-base']}>
                    <div className={styles['unselected-icon-content']}>
                      <div className={styles['unselected-dot']} />
                    </div>
                  </div>
                </div>

                <div className={styles['content']}>
                  <div className={styles['text-and-supporting-text']}>
                    <p className={styles['text']}>
                      {LPTranslate('CUEM_Title_Confirm')}
                    </p>

                    <p className={styles['supporting-text']}>
                      {LPTranslate('CUEM_Verify_Password_Description')}
                    </p>
                  </div>

                  <form
                    className={styles['frame1030-input-field']}
                    onSubmit={checkPassword}
                    id="verify-password-form"
                  >
                    <SimpleTextField
                      data_cy="password-input"
                      title={LPTranslate('CDPM_Step_1_Input_Title')}
                      placeholder="••••••••"
                      onChange={(value) => {
                        handleInputChange('currentPassword', value);
                        setFormErrors({ ...formErrors, currentPassword: '' });
                      }}
                      value={formValues.currentPassword}
                      textFieldWidthPercent={100}
                      type="password"
                      errorMessage={formErrors.currentPassword}
                    />
                  </form>

                  <div className={styles['row']}>
                    <div className={styles['forgot-button']}>
                      <button
                        data-cy="forgot-password-button"
                        className={styles['forgot-button-base']}
                        onClick={() => navigate('/resetForgotPassword')}
                      >
                        <p className={styles['forgot-button-text']}>
                          {LPTranslate('CDPM_Step_1_Forgot_Password')}
                        </p>
                      </button>
                    </div>
                  </div>
                </div>

                <div className={styles['actions']}>
                  <div className={styles['cancel-button']}>
                    <button
                      data-cy="cancel-button"
                      className={styles['cancel-button-base']}
                      onClick={handleClose}
                    >
                      <p className={styles['cancel-button-text']}>
                        {LPTranslate('Button_Cancel')}
                      </p>
                    </button>
                  </div>

                  <div className={styles['confirm-button']}>
                    <button
                      data-cy="submit-button"
                      className={styles['confirm-button-base']}
                      onClick={checkPassword}
                      type="submit"
                      form="verify-password-form"
                    >
                      <p className={styles['confirm-button-text']}>
                        {LPTranslate('Button_Next')}
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          ) : (
            <SetLawyerNewEmailModal
              changeModal={() => setShownModal(2)}
              handleClose={handleClose}
              handleUpdatedEmail={props.handleUpdatedEmail}
              handleSnackbarMessage={(message) => setSnackbarMessage(message)}
              handleSnackbarSeverity={(severity) =>
                setSnackbarSeverity(severity)
              }
              setCheck={props.setCheck}
              handleSnackbarOpen={() => setSnackbarOpen(true)}
              resetStatePassword={resetStatePassword}
              shownModal={shownModal}
              email={props.email}
              password={formValues.currentPassword}
            />
          )}
        </>
      </Modal>
    </>
  );
};

export default UpdateLawyerEmailModal;
