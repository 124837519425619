/* eslint-disable no-new-wrappers */
import React from 'react';
import { useSelector } from 'react-redux';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/registerLawyer/VerifyLawyerOnRegister.module.scss';
import check from 'Assets/pictures/checkCircle.svg';

const VerifyLawyerOnRegister = (props) => {
  const { handleNext } = props;

  const lawyerRegisterRedux = useSelector(
    (state) => state.registerStepper.value
  );

  const checkEmailStep = () => {

    if (checkEmails()) {
      handleNext(5);
    } else {
      handleNext(4);
    }
  };

  const checkEmails = () => {
    return (
      new String(lawyerRegisterRedux.userEmail).valueOf() ===
      new String(lawyerRegisterRedux.roleEmail).valueOf()
    );
  };

  return (
    <>
      <div className={styles.emailCheckerSection}>
        <div className={styles.emailCheckerContainer}>
          <div className={styles.frame2}>
            <div className={styles.content}>
              <div className={styles.header}>
                <div className={styles.featuredIcon}>
                  <div className={styles.checkCircle}>
                    <img src={check} alt="key" />
                  </div>
                </div>

                <div className={styles.textAndSupportingText}>
                  <p className={styles.text}>
                    {LPTranslate('RL_Email_Verified_Title')}
                  </p>

                  <p className={styles.supportingText}>
                    {checkEmails()
                      ? LPTranslate('RL_Email_Verified_Description')
                      : LPTranslate('RL_Email_Verified_Description_Extra_Step')}
                  </p>
                </div>
              </div>

              <div className={styles.setPasswordButtonContainer}>
                <div className={styles.setPasswordCodeButton}>
                  <button
                    data-cy="submit-button"
                    className={styles.setPasswordButtonBase}
                    onClick={checkEmailStep}
                  >
                    <p className={styles.setPasswordButtonText}>
                      {checkEmails()
                        ? LPTranslate('RL_Email_Verified_Button')
                        : LPTranslate('RL_Email_Verified_Button_Extra_Step')}
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyLawyerOnRegister;
