import React, { useEffect } from 'react';

import { useMutation } from '@apollo/client';

import { SET_CLIENT_NOT_VERIFIED } from 'Services/Queries/client';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/registerLawyer/VerifyLawyerOnRegister.module.scss';
import check from 'Assets/pictures/checkCircle.svg';

const VerifyLawyerOnRegister = (props) => {
  const [{ data }] = useMutation(SET_CLIENT_NOT_VERIFIED);

  const handleButton = async () => {
    props.handleNext(3);
  };

  useEffect(() => {
    if (data && data.setClientNotVerified.message === 'User has been updated') {
      props.handleBack(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div className={styles.emailCheckerSection}>
        <div className={styles.emailCheckerContainer}>
          <div className={styles.frame2}>
            <div className={styles.content}>
              <div className={styles.header}>
                <div className={styles.featuredIcon}>
                  <div className={styles.checkCircle}>
                    <img src={check} alt="key" />
                  </div>
                </div>

                <div className={styles.textAndSupportingText}>
                  <p className={styles.text}>
                    {LPTranslate('RC_Page_3_Title')}
                  </p>

                  <p className={styles.supportingText}>
                    {LPTranslate('RC_Page_3_Description')}
                  </p>
                </div>
              </div>

              <div className={styles.setPasswordButtonContainer}>
                <div className={styles.setPasswordCodeButton}>
                  <button
                    className={styles.setPasswordButtonBase}
                    onClick={handleButton}
                    data-cy="submit-button"
                  >
                    <p className={styles.setPasswordButtonText}>
                      {LPTranslate('Button_Next')}
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyLawyerOnRegister;
