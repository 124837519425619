import React, { useEffect, useState } from 'react';

import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import LoopRoundedIcon from '@mui/icons-material/LoopRounded';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';

import FilterCheckBoxes from 'Components/Lawyer/FilterCheckBoxes';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/lawyer/Filters.scss';

const AnwadoTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '14px !important',
    fontFamily: 'Inter !important',
    backgroundColor: '#f9f5ff !important',
    color: '#6941c6 !important',
    padding: '10px !important',
  },
}));

//If you change this, also  change the logic in the useEffect below
let filters = {
  'Sort By': {
    type: 'radio',
    label: `${LPTranslate('LPP_Bio_Sort')}`,
    boxes: {
      Rating: {
        checked: false,
        available: true,
        label: `${LPTranslate('LPP_Bio_Rating')}`,
      },
        Distance: {
          checked: false,
          available: true,
          label: `${LPTranslate('LPP_Bio_Distance')}`,
        },
    },
  },
  Filter: {
    type: 'checkboxes',
    label: `${LPTranslate('SL_Filters_Subscription_Name')}`,
    boxes: {
      Speciality: {
        checked: false,
        available: true,
        label: `${LPTranslate('LPP_Bio_Speciality')}`,
      },
      'Online appointment booking': {
        checked: false,
        available: true,
        label: `${LPTranslate('LPP_Bio_Booking')}`,
      },
    },
  },
  Bewertung: {
    type: 'radio',
    label: `${LPTranslate('SL_Filters_Rating_Name')}`,
    boxes: {
      '4+ Stars': {
        checked: false,
        available: true,
        label: `${LPTranslate('SL_Filters_Rating_Type_1')}`,
      },
      '4.5+ Stars': {
        checked: false,
        available: true,
        label: `${LPTranslate('SL_Filters_Rating_Type_2')}`,
      },
      '5 Stars': {
        checked: false,
        available: true,
        label: `${LPTranslate('SL_Filters_Rating_Type_3')}`,
      },
    },
  },
  Reviews: {
    type: 'radio',
    label: `${LPTranslate('SL_Filters_Reviews_Name')}`,
    boxes: {
      '5+ Reviews': {
        checked: false,
        available: true,
        label: `${LPTranslate('SL_Filters_Reviews_Type_1')}`,
      },
      '10+ Reviews': {
        checked: false,
        available: true,
        label: `${LPTranslate('SL_Filters_Reviews_Type_2')}`,
      },
      '20+ Reviews': {
        checked: false,
        available: true,
        label: `${LPTranslate('SL_Filters_Reviews_Type_3')}`,
      },
    },
  },
};

const Filters = (props) => {
  const { setFilterCheckBoxes } = props;
  const [noFilters, setNoFilters] = useState(true);
  const [arrayFilters, setArrayFilters] = useState([]);

  const updateBoxes = () => {
    let array = [];
    for (let key in filters) {
      array.push(filters[key].boxes);
    }

    if (props.disableDistanceCheckbox && array[0].Distance)
      array[0].Distance.available = false;

    setArrayFilters(array);
  };

  useEffect(() => {
    updateBoxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearAll = () => {
    for (let key in filters) {
      for (let subKey in filters[key].boxes) {
        filters[key].boxes[subKey].checked = false;
      }
    }
    props.clearFilters();
    updateBoxes();
    setNoFilters(true);
  };

  useEffect(() => {
    for (let key in filters) {
      for (let subKey in filters[key].boxes) {
        filters[key].boxes[subKey].checked = false;
      }
    }

    if (props.filters && props.filters.sortBy) {
      switch (props.filters.sortBy) {
        case 'rating':
          filters['Sort By'].boxes.Rating.checked = true;
          filters['Sort By'].boxes.Distance.checked = false;
          setNoFilters(false);
          break;
        case 'distance':
          filters['Sort By'].boxes.Rating.checked = false;
          filters['Sort By'].boxes.Distance.checked = true;
          setNoFilters(false);
          break;
        default:
          filters['Sort By'].boxes.Rating.checked = false;
          filters['Sort By'].boxes.Distance.checked = false;
          break;
      }
    }

    if (props.filters && props.filters.hasSpeciality) {
      filters.Filter.boxes.Speciality.checked = true;
      setNoFilters(false);
    }

    if (props.filters && props.filters.OnlineAppointmentBooking) {
      filters.Filter.boxes['Online appointment booking'].checked = true;
    }

    if (props.filters && props.filters.minRating !== 0) {
      switch (props.filters.minRating) {
        case 4:
          filters.Bewertung.boxes['4+ Stars'].checked = true;
          setNoFilters(false);
          break;
        case 4.5:
          filters.Bewertung.boxes['4.5+ Stars'].checked = true;
          setNoFilters(false);
          break;
        case 5:
          filters.Bewertung.boxes['5 Stars'].checked = true;
          setNoFilters(false);
          break;
        default:
          break;
      }
    }

    if (props.filters && props.filters.minReviews !== 0) {
      switch (props.filters.minReviews) {
        case 5:
          filters.Reviews.boxes['5+ Reviews'].checked = true;
          setNoFilters(false);
          break;
        case 10:
          filters.Reviews.boxes['10+ Reviews'].checked = true;
          setNoFilters(false);
          break;
        case 20:
          filters.Reviews.boxes['20+ Reviews'].checked = true;
          setNoFilters(false);
          break;
        default:
          break;
      }
    }
  }, [props.resultsType, props.filters]);

  const updateParentState = () => {
    if (arrayFilters.length > 0) {
      let checkboxesObject = {
        sortBy: '',
        hasSpeciality: false,
        OnlineAppointmentBooking: false,
        minRating: 0,
        minReviews: 0,
      };

      if (arrayFilters[0].Rating.checked) {
        checkboxesObject.sortBy = 'rating';
      }
      else if (arrayFilters[0].Distance.checked) {
        checkboxesObject.sortBy = 'distance';
      }

      checkboxesObject.hasSpeciality = arrayFilters[1].Speciality.checked;
      checkboxesObject.OnlineAppointmentBooking =
        arrayFilters[1]['Online appointment booking'].checked;

      let minRating = 0;
      if (arrayFilters[2]['4+ Stars'].checked) minRating = 4;
      if (arrayFilters[2]['4.5+ Stars'].checked) minRating = 4.5;
      if (arrayFilters[2]['5 Stars'].checked) minRating = 5;
      checkboxesObject.minRating = minRating;

      let minReviews = 0;
      if (arrayFilters[3]['5+ Reviews'].checked) minReviews = 5;
      if (arrayFilters[3]['10+ Reviews'].checked) minReviews = 10;
      if (arrayFilters[3]['20+ Reviews'].checked) minReviews = 20;
      checkboxesObject.minReviews = minReviews;

      if (
        checkboxesObject.sortBy !== '' ||
        checkboxesObject.hasSpeciality ||
        checkboxesObject.minRating !== 0 ||
        checkboxesObject.minReviews !== 0 ||
        checkboxesObject.OnlineAppointmentBooking
      ) {
        setNoFilters(false);
      } else {
        setNoFilters(true);
      }

      setFilterCheckBoxes(checkboxesObject);
    }
  };

  const filterChange = (filter, subFilter) => {
    let boxType = filters[filter].type;
    let boxGroup = filters[filter].boxes;

    if (boxType === 'radio') {
      boxGroup[subFilter].checked = !boxGroup[subFilter].checked;

      for (let key in boxGroup) {
        if (key !== subFilter) {
          boxGroup[key].checked = false;
        }
      }
    } else if (boxType === 'checkboxes') {
      boxGroup[subFilter].checked = !boxGroup[subFilter].checked;
    }

    updateBoxes();
    updateParentState();
  };

  return (
    <div className={'lawp-filters-container'}>
      <div
        className="drawer-menu-top"
        style={
          props.removeClearFilterButtonIfNoFilters && noFilters
            ? { justifyContent: 'flex-end' }
            : { justifyContent: 'space-between' }
        }
      >
        {noFilters && props.removeClearFilterButtonIfNoFilters ? (
          <>
            {' '}
            {noFilters} {props.removeClearFilterButtonIfNoFilters}{' '}
          </>
        ) : (
          <AnwadoTooltip
            PopperProps={{
              disablePortal: true,
            }}
            classes={{
              popper: {
                border: '30px solid red',
              },
            }}
            title={LPTranslate('Common_Reset_Filters_Tooltip')}
            placement="right"
            TransitionComponent={Zoom}
          >
            <LoopRoundedIcon
              onClick={() => {
                clearAll();
              }}
              data-cy={'reset-filters'}
            />
          </AnwadoTooltip>
        )}
        <div className="mobile-close-button" data-cy={'close-filters'}>
          <CloseRoundedIcon />
        </div>
      </div>
      {arrayFilters.map((value, key) => {
        return (
          <div key={key}>
            {key !== 0 && <Divider className="divider" />}

            <Typography
              className="checkboxesTitle"
              gutterBottom
              variant="subtitle1"
            >
              {filters[Object.keys(filters)[key]].label}
            </Typography>

            <FilterCheckBoxes
              filterChange={filterChange}
              filter={Object.keys(filters)[key]}
              boxes={value}
              lawyerLoading={props.lawyerLoading}
              lawyerError={props.lawyerError}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Filters;
