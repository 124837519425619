import { gql } from '@apollo/client';

//test query

export const MINI_REGISTER = gql`
  mutation MiniRegister($input: MiniRegisterInput!) {
    miniRegister(input: $input) {
      ... on TokenRefreshToken {
        jwtToken
        refresh_token
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const GET_AREAS_OF_LAW = gql`
  query GetAreasOfLaw {
    areasOfLaws {
      name
    }
  }
`;

export const GET_USERS = gql`
  {
    users {
      id
      username
      email
      role
      lawyer {
        id
        name
        email
      }
    }
  }
`;

export const SEND_MESSAGE_TO_LAWYER_VIA_EMAIL = gql`
  mutation sendMessageToLawyer($input: SendMessage) {
    sendMessageToLawyer(input: $input) {
      message
      error
    }
  }
`;

export const ASK_LAWYER_TO_SET_SCHEDULE_VIA_EMAIl = gql`
  mutation askLawyerToSetSchedule($input: SendEmail) {
    askLawyerToSetSchedule(input: $input) {
      message
      error
    }
  }
`;

//register lawyer
export const REGISTER_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      ... on User {
        id
        username
        email
        role
        token
        isAccountVerified
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const REGISTER_USER_LAWYER = gql`
  mutation createUserLawyer(
    $firstName: String
    $lastName: String
    $email: String
  ) {
    createUserLawyer(
      firstName: $firstName
      lastName: $lastName
      email: $email
    ) {
      message
      error
    }
  }
`;

export const CHANGE_LAWYER_DETAILS = gql`
  mutation changeLawyerDetails($input: ChangeLawyerDetails!) {
    changeLawyerDetails(input: $input) {
      message
      error
    }
  }
`;

export const GENERATE_OTP_CODE = gql`
  mutation generateOtpCode($input: GenerateOtpInput!) {
    generateOtpCode(input: $input) {
      message
      error
    }
  }
`;

export const VERIFY_OTP_CODE = gql`
  mutation verifyOtpCode($input: VerifyOtpInput!) {
    verifyOtpCode(input: $input) {
      message
      error
    }
  }
`;

export const VERIFY_OTP_CODE_AND_JWT = gql`
  mutation verifyOtpCodeAndJwt($input: VerifyOtpAndJwtInput!) {
    verifyOtpCodeAndJwt(input: $input) {
      message
      error
    }
  }
`;

export const SET_PASSWORD_ON_REGISTER = gql`
  mutation setPasswordOnRegister($input: SetPasswordOnRegister!) {
    setPasswordOnRegister(input: $input) {
      message
      error
    }
  }
`;

export const SEND_EMAIL_CONFIRMATION_AFTER_REGISTER = gql`
  mutation sendEmailConfirmationAfterRegister($name: String!, $email: String!) {
    sendEmailConfirmationAfterRegister(name: $name, email: $email) {
      message
      error
    }
  }
`;

export const ADD_COMMENT = gql`
  mutation AddComment($input: CreateCommentInput!) {
    addComment(input: $input) {
      name
      content
      lawyer_id
      review_id
    }
  }
`;

export const LOGIN_USER = gql`
  mutation LoginUser($input: LoginUserInput!) {
    loginUser(input: $input) {
      ... on TokenRefreshToken {
        jwtToken
        refresh_token
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;
export const SET_ELIGIBILITY = gql`
  mutation setEligibility(
    $first_name: String!
    $last_name: String!
    $email: String!
    $token: String!
  ) {
    setEligibility(
      first_name: $first_name
      last_name: $last_name
      email: $email
      token: $token
    ) {
      message
      error
    }
  }
`;
export const VERIFY_PASSWORD_AND_ELIGIBILITY = gql`
  mutation verifyPasswordAndEligibility($id: ID!, $password: String!) {
    verifyPasswordAndEligibility(id: $id, password: $password) {
      message
      error
    }
  }
`;

export const GET_USER = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      id
      username
      email
      password
      role
    }
  }
`;

export const GET_SITEMAP = gql`
  query getSitemap($type: String!) {
    sitemap(type: $type) {
      xml
    }
  }
`;

export const GET_USER_PROFILE_IMAGE = gql`
  query GetUserInformationNavbar($id: ID!) {
    userInformationNavbar(id: $id) {
      profileImage
      username
      email
    }
  }
`;

export const GET_PROFILE_PICTURE = gql`
  query GetUserProfileImage($id: ID!, $role: String!) {
    userAvatar(id: $id, role: $role)
  }
`;

export const GET_USER_OPERATOR = gql`
  query GetUserOperator($id: ID!) {
    user(id: $id) {
      id
      username
      email
      role
      operator {
        id
        name
        picture
        professional_title
        home_address
        telephone
        email
      }
    }
  }
`;

export const GET_USER_LAWYER = gql`
  query GetUserLawyer($id: ID!) {
    user(id: $id) {
      id
      username
      email
      role
      lawyer {
        id
        last_name
        first_name
        location
        specialist_attourneys
        rating
        email
        website
        subscription
      }
    }
  }
`;

export const GET_USER_CLIENT = gql`
  query GetUserClient($id: ID!) {
    user(id: $id) {
      id
      username
      email
      role
      client {
        id
        name
        picture
        home_address
        telephone
        email
      }
    }
  }
`;

export const DELETE_USER_CLIENT = gql`
  mutation deleteUserClient(
    $deleteReason: String
    $token: String!
    $email: String!
    $first_name: String!
    $last_name: String!
  ) {
    deleteUserClient(
      deleteReason: $deleteReason
      token: $token
      email: $email
      first_name: $first_name
      last_name: $last_name
    ) {
      message
      error
    }
  }
`;

export const DELETE_USER_LAWYER = gql`
  mutation deleteUser(
    $token: String!
    $email: String!
    $first_name: String!
    $last_name: String!
  ) {
    deleteUser(
      token: $token
      email: $email
      first_name: $first_name
      last_name: $last_name
    ) {
      message
      error
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUser) {
    updateUser(input: $input) {
      ... on User {
        username
        email
        password
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation PostForgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      message
      error
    }
  }
`;

export const CHANGE_USER_PASSWORD_WITH_TOKEN = gql`
  mutation changeUserPasswordWithToken(
    $input: changeUserPasswordWithTokenInput
  ) {
    changeUserPasswordWithToken(input: $input) {
      message
      error
    }
  }
`;

export const VERIFY_PARAM_TOKEN = gql`
  query verifyParamToken($token: String!) {
    verifyParamToken(token: $token) {
      message
      error
    }
  }
`;

export const VERIFY_PARAM_TOKEN_MUTATION = gql`
  mutation verifyParamTokenMutation($input: VerifyParamTokenInput) {
    verifyParamTokenMutation(input: $input) {
      ... on VerifyParamStep {
        step
        unique_email_step
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const SEND_REPORT = gql`
  mutation sendReport($input: SendReportInput!) {
    sendReport(input: $input) {
      message
      error
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken($input: RefreshTokenInput!) {
    refreshToken(input: $input) {
      ... on RefreshToken {
        status
        jwtToken
        refresh_token
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;
