import React from 'react';

import IOSSwitch from 'Components/Lawyer/Edit/IOSSwitch';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/lawyer/EditLawyerAvailabilitySection.module.scss';
const EditLawyerAvailabilitySection = (props) => {
  const available = props.lawyerAvailability;

  const handleSwitchPress = (switchName) => {
    let newAvailability = { ...available };
    newAvailability[switchName] = !available[switchName];

    if (newAvailability['personally'] || newAvailability['by_phone'])
      newAvailability['show_availability'] = true;
    else newAvailability['show_availability'] = false;

    props.onChangeAvailability(newAvailability['show_availability']);

    switch (switchName) {
      case 'personally':
        props.onChangePersonally(newAvailability[switchName]);
        break;
      case 'by_phone':
        props.onChangeByPhone(newAvailability[switchName]);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <p>{LPTranslate('ULPP_Availability_Appointment_Type')}</p>
        </div>

        <div className={styles.Switches}>
          {/* adaugat in componenta separata:  */}
          <div className={styles.SwitchRow}>
            <div className={styles.Switch}>
              <IOSSwitch
                data_cy="personally-available-checkbox"
                checked={available.personally}
                onChange={() => handleSwitchPress('personally')}
              />
            </div>

            <div className={styles.SwitchTitleContainer}>
              <p className={styles.SwitchTitleText}>
                {LPTranslate('ULPP_Availability_In_Person_Title')}
              </p>

              <p className={styles.SwitchDescriptionText}>
                {LPTranslate('ULPP_Availability_In_Person_Description')}
              </p>
            </div>
          </div>

          <div className={styles.SwitchRow}>
            <div className={styles.Switch}>
              <IOSSwitch
                data_cy="by-phone-available-checkbox"
                checked={available.by_phone}
                onChange={() => handleSwitchPress('by_phone')}
              />
            </div>

            <div className={styles.SwitchTitleContainer}>
              <p className={styles.SwitchTitleText}>
                {' '}
                {LPTranslate('ULPP_Availability_By_Phone_Title')}
              </p>

              <p className={styles.SwitchDescriptionText}>
                {LPTranslate('ULPP_Availability_By_Phone_Description')}
              </p>
            </div>
          </div>

          {/* <div className={styles.SwitchRow}>
            <div className={styles.Switch}>
              <IOSSwitch
                checked={available.online_meeting}
                onChange={() => handleSwitchPress('online_meeting')}
              />
            </div>

            <div className={styles.SwitchTitleContainer}>
                        <p className={styles.SwitchTitleText}>Online Meeting (Platin-Mitglied)</p>

                        <p className={styles.SwitchDescriptionText}>Führe Gespräche mit deinen Mandanten per Videoanruf</p>
                    </div>
          </div> */}
        </div>

        {/* <div className={styles.CheckBoxes}> */}
        {/* adaugat in componenta separata:  */}
        {/* <div className={styles.SwitchRow}> */}
        {/* <div className={styles.CheckBox}> */}
        {/* <CheckBox */}
        {/* data_cy="availability-checkbox" */}
        {/* checked={available.personally || available.by_phone} */}
        {/* // onChange={() => handleSwitchPress('show_availability')} */}
        {/* /> */}
        {/* </div> */}

        {/* <div className={styles.SwitchTitleContainer}> */}
        {/* <p className={styles.SwitchTitleText}> */}
        {/* {LPTranslate('ULPP_Availability_Display_Availability_Title')}{' '} */}
        {/* </p> */}

        {/* <p className={styles.SwitchDescriptionText}> */}
        {/* {LPTranslate( */}
        {/* 'ULPP_Availability_Display_Availability_Description' */}
        {/* )} */}
        {/* </p> */}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default EditLawyerAvailabilitySection;
