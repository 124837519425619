import React, { useState, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import CircularProgress from '@mui/material/CircularProgress';

import ReviewCardPublicSeeMoreModal from 'Components/Lawyer/ReviewCardPublicSeeMoreModal.js';
import ReviewCardPublic from 'Components/Lawyer/ReviewCardPublic';

import { GET_REVIEWS_OF_LAWYER } from 'Services/Queries/lawyer';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import rightArrow from 'Assets/pictures/rightArrow.svg';
import leftArrow from 'Assets/pictures/leftArrow.svg';

import 'Assets/styles/lawyer/ReviewsView.scss';

const NUMBER_OF_REVIEWS_PER_PAGE = 9;

const ReviewsView = (props) => {
  const [checkFullReviewModalOpen, setCheckFullReviewModalOpen] =
    useState(false);
  const [checkFullReviewModalData, setCheckFullReviewModalData] = useState({});
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const { lawyer, changeContent } = props;

  const [
    reviewsLawyerQuery,
    { loading: loadingLawyer, error: errorLawyer, data: dataLawyer },
  ] = useLazyQuery(GET_REVIEWS_OF_LAWYER);

  const refetchLawyerReviews = async () => {
    reviewsLawyerQuery({
      variables: {
        input: {
          lawyer_id: lawyer.id,
          page: page,
          limit: NUMBER_OF_REVIEWS_PER_PAGE,
        },
      },
    });
  };

  const handlePageChange = (value) => {
    if (value >= 1 && value <= pageCount) {
      setPage(value);
    }
  };

  const onBackButtonEvent = () => {
    changeContent('overview');
    return;
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refetchLawyerReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (dataLawyer) {
      setPageCount(dataLawyer.reviewsOfLawyer.number_of_pages);
    }
  }, [dataLawyer]);

  useEffect(() => {
    refetchLawyerReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ratingData]);

  if (loadingLawyer || errorLawyer !== undefined) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '200px',
            marginBottom: '200px',
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      </>
    );
  }

  const onSeeMorePress = (index) => {
    setCheckFullReviewModalData(dataLawyer.reviewsOfLawyer.reviews[index]);
    setCheckFullReviewModalOpen(true);
  };

  return (
    <div className="lawp-reviews-view-container">
      <ReviewCardPublicSeeMoreModal
        review={checkFullReviewModalData}
        open={checkFullReviewModalOpen}
        handleClose={() => setCheckFullReviewModalOpen(false)}
      />

      <div className="lawp-reviews-view-back-to-profile-overview-button-container">
        <Button
          className="lawp-reviews-view-back-to-profile-overview-button"
          onClick={() => changeContent('overview')}
          startIcon={<ArrowBackRoundedIcon />}
        >
          {LPTranslate('ARM_Action_Button_Return')}
        </Button>
      </div>

      <div className="lawp-reviews-view-content-container">
        <div className="lawp-reviews-view-reviews-container">
          {dataLawyer?.reviewsOfLawyer &&
            dataLawyer.reviewsOfLawyer.reviews &&
            dataLawyer.reviewsOfLawyer.reviews.map((review, index) => {
              return (
                <ReviewCardPublic
                  key={index}
                  review={review}
                  onRefetch={() => refetchLawyerReviews()}
                  onPressSeeMore={() => onSeeMorePress(index)}
                  handleIsModalOpened={(value) => {}}
                  seeMoreButtonDisplayed={true}
                  index={review.id}
                  noDrag={true}
                />
              );
            })}
        </div>

        {dataLawyer && dataLawyer.reviewsOfLawyer.number_of_pages > 1 && (
          <>
            <div className="lawp-reviews-view-article-pagination-divider" />

            <div className="lawp-reviews-view-articles-pagination-container">
              <Button
                disableRipple
                className="lawp-reviews-view-left-arrow-button"
                startIcon={<img src={leftArrow} alt="leftArrow" />}
                onClick={() => {
                  handlePageChange(page - 1);
                }}
              >
                {LPTranslate('Button_Previous')}
              </Button>

              <Stack>
                <Pagination
                  className="lawp-reviews-view-pagination"
                  count={pageCount}
                  page={page}
                  onChange={(event, value) => {
                    handlePageChange(value);
                  }}
                />
              </Stack>

              <Button
                disableRipple
                className="lawp-reviews-view-right-arrow-button"
                endIcon={<img src={rightArrow} alt="leftArrow" />}
                onClick={() => handlePageChange(page + 1)}
              >
                {LPTranslate('Button_Next')}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ReviewsView;
