import React, { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

import PartOfCommunityMockupSection from 'Components/help/PartOfCommunityMockupSection';
import FAQExpandableSection from 'Components/help/FAQExpandableSection';
import MetaDecorator from 'Layouts/MetaDecorator';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import mailIcon from 'Assets/pictures/mailIcon.svg';
import lightIcon from 'Assets/pictures/lightIcon.svg';
import statisticsIcon from 'Assets/pictures/statisticsIcon.svg';
import smileIcon from 'Assets/pictures/smileIcon.svg';
import MacBookMockUp from 'Assets/pictures/macBookMockUp.png';
import MacBookMockUp2 from 'Assets/pictures/macBookMockUp2.png';
import LawyerProfileScreenshot from 'Assets/pictures/lawyerProfileScreenshot2.png';

import 'Assets/styles/help/FAQPage.scss';

let faqsDummyComponentData = [
  {
    cardText: LPTranslate('LPP_FAQ_Card_1_Description'),
    cardTitle: LPTranslate('LPP_FAQ_Card_1_Title'),
    picture: mailIcon,
  },
  {
    cardText: LPTranslate('LPP_FAQ_Card_2_Description'),
    cardTitle: LPTranslate('LPP_FAQ_Card_2_Title'),
    picture: lightIcon,
  },
  {
    cardText: LPTranslate('LPP_FAQ_Card_3_Description'),
    cardTitle: LPTranslate('LPP_FAQ_Card_3_Title'),
    picture: statisticsIcon,
  },
  {
    cardText: LPTranslate('LPP_FAQ_Card_4_Description'),
    cardTitle: LPTranslate('LPP_FAQ_Card_4_Title'),
    picture: smileIcon,
  },
];

let faqsDummyData = [
  {
    question: LPTranslate('Support_FAQ_Question_1'),
    answer: LPTranslate('Support_FAQ_Answer_1'),
  },
  {
    question: LPTranslate('Support_FAQ_Question_2'),
    answer: LPTranslate('Support_FAQ_Answer_2'),
  },
  {
    question: LPTranslate('Support_FAQ_Question_3'),
    answer: LPTranslate('Support_FAQ_Answer_3'),
  },
  {
    question: LPTranslate('Support_FAQ_Question_4'),
    answer: LPTranslate('Support_FAQ_Answer_4'),
  },
  {
    question: LPTranslate('Support_FAQ_Question_5'),
    answer: LPTranslate('Support_FAQ_Answer_5'),
  },
  {
    question: LPTranslate('Support_FAQ_Question_6'),
    answer: LPTranslate('Support_FAQ_Answer_6'),
  },
  {
    question: LPTranslate('Support_FAQ_Question_7'),
    answer: LPTranslate('Support_FAQ_Answer_7'),
  },
  {
    question: LPTranslate('Support_FAQ_Question_8'),
    answer: LPTranslate('Support_FAQ_Answer_8'),
  },
  {
    question: LPTranslate('Support_FAQ_Question_9'),
    answer: LPTranslate('Support_FAQ_Answer_9'),
  },
  {
    question: LPTranslate('Support_FAQ_Question_10'),
    answer: LPTranslate('Support_FAQ_Answer_10'),
  },
  {
    question: LPTranslate('Support_FAQ_Question_11'),
    answer: LPTranslate('Support_FAQ_Answer_11'),
  },
  {
    question: LPTranslate('Support_FAQ_Question_12'),
    answer: LPTranslate('Support_FAQ_Answer_12'),
  },
];

const FAQPage = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const navigate = useNavigate();

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);
  }, []);

  return (
    <>
      <div className="lawp-faq-page-top-container">
        <MetaDecorator
          title={LPTranslate('Support_Meta_Title_1')}
          description={LPTranslate('Support_Meta_Description_1')}
          canonical={'canonical'}
          link={`${process.env.REACT_APP_DNS_URI}/faq`}
        />
        <div className="lawp-faq-page-top-container-content">
          <div className="lawp-faq-page-top-container-content-left">
            {/* <h3> {LPTranslate('Support_Second_Title')} </h3> */}
            {/* <br /> */}
            <h2>{LPTranslate('Support_Title')}</h2>
          </div>

          {/* <div className="lawp-faq-page-top-container-content-right">
            <p>{LPTranslate('Support_Description')}</p>
          </div> */}
        </div>
      </div>
      <div className="lawp-faq-page-bottom-container">
        <div className="lawp-faq-page-bottom-container-content">
          <div className="lawp-faq-page-faq-container">
            <div className="lawp-faq-page-faq-container-info">
              {/* <h4>{LPTranslate('Support_Second_Title')}</h4> */}
              <h2>FAQs</h2>
              <h3>{LPTranslate('Support_FAQ_Description')}</h3>
            </div>
            <div className="lawp-faq-page-faq-container-expandable-questions">
              <FAQExpandableSection questions={faqsDummyData} />
            </div>
          </div>

          <div className="lawp-faq-page-join-container">
            <h2>{LPTranslate('Anwado_Any_Questions')}</h2>
            <h3>{LPTranslate('Anwado_Join_And_Offer_Services')}</h3>

            <div className="lawp-faq-page-join-container-buttons">
              <div className="buttons-container">
                <Button
                  className="client-button"
                  data-cy="contact-us"
                  onClick={() => navigate('/kontakt')}
                >
                  <a
                    href={`${process.env.REACT_APP_DNS_URI}/kontakt`}
                    style={{ color: '#344054' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {LPTranslate('Common_Contact_Us')}
                  </a>
                </Button>

                <Button
                  className="lawyer-button"
                  data-cy="register"
                  onClick={() =>
                    navigate('/registerLawyer', {
                      state: {
                        isSentFromLawyerPage: false,
                      },
                    })
                  }
                >
                  <a
                    href={`${process.env.REACT_APP_DNS_URI}/registerLawyer`}
                    style={{ color: 'white' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {LPTranslate('Button_Register_Yourself')}
                  </a>
                </Button>
              </div>
            </div>
          </div>

          <div className="lawp-faq-page-useful-information-container">
            <div className="lawp-faq-page-faq-container-info">
              <h4>{LPTranslate('Support_Things_To_Know')}</h4>
              <h1>{LPTranslate('Support_Features_Title')}</h1>
              <h3>{LPTranslate('Support_Features_Description')}</h3>
            </div>
          </div>

          <div className="lawp-faq-page-faq-information-container">
            <div className="content">
              {faqsDummyComponentData.map((item, index) => {
                return (
                  <div key={index} className="value">
                    <div className="image-container">
                      <img src={item.picture} alt="value1" className="image" />
                    </div>
                    <h2 className="value-title">{item.cardTitle}</h2>
                    <h3 className="value-text">{item.cardText}</h3>
                  </div>
                );
              })}
            </div>

            <div className="mockup">
              <div className="gray-container">
                <div className="iphone-mockup-container">
                  <img
                    src={
                      windowSize.innerWidth > 1480
                        ? MacBookMockUp2
                        : MacBookMockUp
                    }
                    alt="Iphone Mockup"
                    className="iphone-mockup"
                  />
                  <img
                    src={LawyerProfileScreenshot}
                    alt="Iphone Mockup"
                    className="iphone-mockup-image"
                  />
                </div>
              </div>
            </div>
          </div>

          <PartOfCommunityMockupSection />
        </div>
      </div>
    </>
  );
};

export default FAQPage;
