import React, { useState, useEffect, useRef } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { alpha } from '@material-ui/core/styles/colorManipulator';

import ImageDropzone from 'Components/Lawyer/Edit/ImageDropzone';
import EditLawyerSectionInfo from 'Components/Lawyer/Edit/EditLawyerSectionInfo';
import SimpleTextField from 'Components/Inputs/SimpleTextField';
import AutoCompleteTextFieldWithTags from 'Components/Lawyer/Edit/AutoCompleteFixedValuesWithTags';
import QuillTextFields from 'Components/Lawyer/TextEditor.js';

import { parseFromBase64, convertToBase64 } from 'Services/Utils/blobToBase64';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/lawyer/AddLawyerArticleModal.module.scss';

const EDIT_LAYWER_INFO_SECTION_FONT_SIZE = 13;
const EDIT_LAYWER_INFO_SECTION_LINE_HEIGHT = 18;

export default function BasicModal(props) {
  const [articleID, setArticleID] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [areasOfLaw, setAreasOfLaw] = useState([]);

  const inputFile = useRef(null);

  const handleClose = () => props.handleClose();

  const onDeleteCoverImage = () => {
    props.onChange('coverImage', null, articleID);
    setCoverImage(null);
  };

  const validateFile = (file) => {
    const validTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/svg',
      'image/x-icon',
    ];

    if (validTypes.indexOf(file.type) === -1) return false;

    return true;
  };

  const pickNewImageHandler = () => {
    inputFile.current.click();
  };

  const updateImage = (e) => {
    if (!validateFile(e.target.files[0])) return;

    let file = e.target.files[0];

    const convert = async () => {
      let data = await convertToBase64(file);

      props.onImageUpdate(data);
    };

    convert();
  };

  useEffect(() => {
    if (props.article.id !== undefined) {
      setArticleID(props.article.id);
    }
  }, [props.article.id]);

  useEffect(() => {
    if (props.article.coverImage) {
      let path;

      const converImage = async () => {
        let imageConverted;

        if (
          props.article.coverImage &&
          props.article.coverImage.length < 1000
        ) {
          setCoverImage(props.article.coverImage);
        } else {
          if (props.article.coverImage.image_data !== undefined) {
            imageConverted = await parseFromBase64(
              props.article.coverImage.image_data
            );
          } else {
            imageConverted = await parseFromBase64(props.article.coverImage);
          }

          path = URL.createObjectURL(imageConverted);

          setCoverImage(path);
        }
      };
      converImage();
    }
  }, [props.article.coverImage]);

  useEffect(() => {
    if (props.areasOfLaw.length > 0 && props.article.areasOfLaw !== undefined) {
      let areas = [];
      for (let i = 0; i < props.areasOfLaw.length; i++) {
        areas.push({ title: props.areasOfLaw[i], selected: 0 });

        for (let j = 0; j < props.article.areasOfLaw.length; j++) {
          if (props.areasOfLaw[i] === props.article.areasOfLaw[j]) {
            areas[i].selected = 1;
            break;
          }
        }
      }

      setAreasOfLaw(areas);
    }
  }, [props.article.areasOfLaw, props.areasOfLaw]);

  return (
    <div>
      <Modal
        open={props.modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          '& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop': {
            backgroundColor: alpha('#344054', 0.6),

            backdropFilter: 'blur(16px)',
          },
        }}
      >
        <Box className={styles.boxContainer}>
          <div className={styles.InnerContainer}>
            <div className={styles.titleContainer}>
              <span className={styles.titleText}>
                {LPTranslate('AAM_Title')}
              </span>
            </div>
            <div className={styles.contentContainer}>
              <span className={styles.contentText}>
                {LPTranslate('AAM_Description')}
              </span>
            </div>
            <div className={styles.Divider} />
            <div className={styles.SectionRow}>
              <div className={styles.SectionRowInformation}>
                <EditLawyerSectionInfo
                  subscription={0}
                  title={LPTranslate('AAM_Cover_Image_Title')}
                  text={LPTranslate('AAM_Cover_Image_Description')}
                  titleFontSize={EDIT_LAYWER_INFO_SECTION_FONT_SIZE}
                  contentFontSize={EDIT_LAYWER_INFO_SECTION_FONT_SIZE}
                  titleLineHeight={EDIT_LAYWER_INFO_SECTION_LINE_HEIGHT}
                  contentLineHeight={EDIT_LAYWER_INFO_SECTION_LINE_HEIGHT}
                />
              </div>

              <div className={styles.SectionRowContent}>
                {props.article.coverImage === null ? (
                  <div style={{ width: 512 }}>
                    <ImageDropzone
                      data_cy="aam-file-input"
                      onChange={(image) =>
                        props.onChange('coverImage', image, articleID)
                      }
                      singleImage
                      compressorOptions={{
                        maxWidth: 1300,
                        maxHeight: 1024,
                      }}
                    />

                    {props.inputErrors.coverImage && (
                      <p
                        className={styles['errorMessage']}
                        style={{
                          marginTop: '14px',
                          marginBottom: '0px',
                          lineHeight: '0px',
                        }}
                      >
                        {props.inputErrors.coverImage}
                      </p>
                    )}
                  </div>
                ) : (
                  <div className={styles.ImageContainer}>
                    <div>
                      <img
                        src={coverImage}
                        alt="avatar"
                        className={styles.Image}
                      />
                    </div>
                    <span
                      data-cy="aam-delete-image-button"
                      className={`${styles.Buttons} ${styles.ButtonsTextDelete}`}
                      onClick={onDeleteCoverImage}
                    >
                      {LPTranslate('AAM_Cover_Image_Delete')}
                    </span>{' '}
                    <span
                      onClick={pickNewImageHandler}
                      className={`${styles.Buttons} ${styles.ButtonsTextUpdate}`}
                    >
                      {LPTranslate('AAM_Cover_Image_Update')}
                    </span>
                    <input
                      data-cy="aam-update-image-button"
                      type="file"
                      onChange={updateImage}
                      id="file"
                      ref={inputFile}
                      style={{ display: 'none' }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.Divider} />
            <div className={styles.SectionRow}>
              <div className={styles.SectionRowInformation}>
                <EditLawyerSectionInfo
                  subscription={0}
                  title={LPTranslate('AAM_Form_1_Title')}
                  text={LPTranslate('AAM_Form_1_Description')}
                  titleFontSize={EDIT_LAYWER_INFO_SECTION_FONT_SIZE}
                  contentFontSize={EDIT_LAYWER_INFO_SECTION_FONT_SIZE}
                  titleLineHeight={EDIT_LAYWER_INFO_SECTION_LINE_HEIGHT}
                  contentLineHeight={EDIT_LAYWER_INFO_SECTION_LINE_HEIGHT}
                />
              </div>
              <div className={styles.SectionRowContent}>
                <div className={styles.TitleContainer}>
                  <SimpleTextField
                    data_cy="aam-title-input"
                    title={LPTranslate('Input_Title')}
                    onChange={(value) => props.onChange('title', value)}
                    value={props.article.title}
                    textFieldWidthPercent={100}
                    placeholder={LPTranslate(
                      'AAM_Form_1_Input_Title_Placeholder'
                    )}
                    maxLength={250}
                    hideHowManyCharactersLeft
                    errorMessage={props.inputErrors.title}
                  />
                </div>

                <div className={styles.DescriptionContainer}>
                  <SimpleTextField
                    data_cy="aam-short-description-input"
                    title={LPTranslate(
                      'AAM_Form_1_Input_Short_Description_Title'
                    )}
                    onChange={(value) => props.onChange('description', value)}
                    value={props.article.description}
                    textFieldWidthPercent={100}
                    rows={2}
                    multiline
                    maxLength={250}
                    placeholder={LPTranslate(
                      'AAM_Form_1_Input_Short_Description_Placeholder'
                    )}
                    errorMessage={props.inputErrors.description}
                  />
                </div>

                <div className={styles.PLACE_HOLDER_TEXT_EDITOR}>
                  <div key={'editor-3'}>
                    <QuillTextFields
                      quill_buttons_data_cy="add-lawyer-article"
                      ql_size_option_data_cy="aam-option-button"
                      data_cy="aam-description-input"
                      title={LPTranslate('AAM_Form_1_Input_Post')}
                      id={'editor-3'}
                      name={'editor-3'}
                      value={props.article.content}
                      onChange={(value) =>
                        props.onChange('content', value, articleID)
                      }
                      titleAbove={true}
                    />
                    <br />
                  </div>
                </div>
                {props.inputErrors.content && (
                  <p
                    className={styles['errorMessage']}
                    style={{
                      marginTop: '-7px',
                      marginBottom: '0px',
                      lineHeight: '0px',
                    }}
                  >
                    {props.inputErrors.content}
                  </p>
                )}
              </div>
            </div>

            <div className={styles.Divider} />
            <div className={styles.SectionRow}>
              <div className={styles.SectionRowInformation}>
                <EditLawyerSectionInfo
                  subscription={0}
                  title={LPTranslate('AAM_Cover_Law_Firms_Title')}
                  titleFontSize={EDIT_LAYWER_INFO_SECTION_FONT_SIZE}
                  titleLineHeight={EDIT_LAYWER_INFO_SECTION_LINE_HEIGHT}
                />
              </div>
              <div className={styles.SectionRowContent}>
                <div className={styles.AreasOfLawContainer}>
                  <AutoCompleteTextFieldWithTags
                    delete_area_of_law_data_cy="delete-area-of-law-button"
                    areas_of_law_data_cy="areas-of-law-input"
                    title={LPTranslate('AAM_Form_1_Input_Areas_Of_Law_Title')}
                    dataArray={areasOfLaw}
                    AAM_Form_1_Input_Areas_Of_Law_Title
                    optionsAreAreasOfLaw={true}
                    onChange={(value) =>
                      props.onChange('areasOfLaw', value, articleID)
                    }
                    textFieldWidthPercent={100}
                    tagColors={['#175CD3', '#EFF8FF', '#2E90FA']}
                    noPadding
                    noMargin
                  />
                </div>
                {props.inputErrors.areasOfLaw && (
                  <p
                    className={styles['errorMessage']}
                    style={{
                      marginTop: '15px',
                      marginBottom: '0px',
                      lineHeight: '0px',
                    }}
                  >
                    {props.inputErrors.areasOfLaw}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.Divider} />
            <div className={styles.BottomButtonsContainer}>
              <Button
                data-cy="cancel-add-article-button"
                className={styles.revertButton}
                onClick={() => {
                  props.handleClose();
                }}
              >
                {LPTranslate('Button_Cancel')}
              </Button>
              <Button
                data-cy="submit-add-article-button"
                className={styles.saveButton}
                onClick={() => {
                  props.onCreate();
                }}
              >
                {LPTranslate('AAM_Publish')}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
