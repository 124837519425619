import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';

import { GET_APOINTMENTS } from 'Services/Queries/common';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import UpcomingEvents from './UpcomingEvents';

import styles from 'Assets/styles/client/EventsSection.module.scss';

const EventsSection = () => {
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const [getAppointments, { data, loading }] = useLazyQuery(GET_APOINTMENTS, {
    variables: { id: clientInfoRedux.role_id, role: clientInfoRedux.role },
    fetchPolicy: 'no-cache',
  });

  // let appointments = getAppointments.data?.appointments;

  // console.log(appointments);

  let [menuButtonSelected, setMenuButtonSelected] = useState(0);
  const [appointments, setAppointments] = useState([]);

  const [futureAppointmentsArr, setFutureAppointmentsArr] = useState([]);
  const [pastAppointmentsArr, setPastAppointmentsArr] = useState([]);
  const [timeUntilAppointment] = useState(0);
  let todayDate = moment().format('X');

  const refetchAppointments = () => {
    getAppointments();
  };

  useEffect(() => {
    refetchAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data && data.appointments) {
      setPastAppointmentsArr([]);
      setFutureAppointmentsArr([]);
      setAppointments(data.appointments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const fetch = () => {
      for (let i = 0; i < appointments?.length; i++) {
        let appointmentDate = moment
          .unix(appointments[i].date)
          .format('DD.MM.YYYY');

        let dateAndTime = moment(
          appointmentDate + ' ' + appointments[i].time.slice(0, 5),
          'DD.MM.YYYY HH:mm:ss'
        ).toDate();

        let appointmentTimestamp = moment(dateAndTime).format('X');

        if (appointmentTimestamp < todayDate)
          setPastAppointmentsArr((old) => [...old, appointments[i]]);
        else if (appointmentTimestamp > todayDate)
          setFutureAppointmentsArr((old) => [...old, appointments[i]]);
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointments]);

  const handleMenuButton = (index) => {
    if (index === 0) setMenuButtonSelected(1);

    if (index === 1) setMenuButtonSelected(0);
  };

  const handlePastAppointmentsArr = (element) => {
    setPastAppointmentsArr((old) => [...old, element]);
  };

  var menuButtons = () => {
    if (menuButtonSelected === 0) {
      return (
        <div className={styles['events-section-tabs']}>
          <div
            className={
              styles['events-section-tab-upcoming-button-base-selected']
            }
          >
            <button
              data-cy="soon-button"
              className={styles['events-section-tab-upcoming-button-selected']}
              onClick={() => handleMenuButton(1)}
            >
              {LPTranslate('Button_Soon')}
            </button>
            <div className={styles['events-section-bottom-border']} />
          </div>

          <div
            className={styles['events-section-tab-past-button-base-unselected']}
          >
            <button
              data-cy="past-button"
              className={styles['events-section-tab-past-button-unselected']}
              onClick={() => handleMenuButton(0)}
            >
              {LPTranslate('Button_Past')}
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles['events-section-tabs']}>
          <div
            className={
              styles['events-section-tab-upcoming-button-base-unselected']
            }
          >
            <button
              data-cy="soon-button"
              className={
                styles['events-section-tab-upcoming-button-unselected']
              }
              onClick={() => handleMenuButton(1)}
            >
              {LPTranslate('Button_Soon')}
            </button>
          </div>

          <div
            className={styles['events-section-tab-past-button-base-selected']}
          >
            <button
              data-cy="past-button"
              className={styles['events-section-tab-past-button-selected']}
              onClick={() => handleMenuButton(0)}
            >
              {LPTranslate('Button_Past')}
            </button>
            <div className={styles['events-section-bottom-border']} />
          </div>
        </div>
      );
    }
  };

  if (loading) {
    return (
      <>
        <div className="loading-overlay">
          <CircularProgress color="secondary" />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles['events-section-section']}>
        <div className={styles['events-section-split-section']}>
          <div className={styles['events-section-inner-section']}>
            <div className={styles['events-section-container']}>
              <div className={styles['events-section-horizontal-tabs']}>
                {menuButtons()}

                <div className={styles['events-section-divider']} />
              </div>

              <UpcomingEvents
                // appointments={data?.appointments}
                resetAppointmentsList={() => setAppointments([])}
                handleRefetch={() => refetchAppointments()}
                futureAppointmentsArr={futureAppointmentsArr}
                pastAppointmentsArr={pastAppointmentsArr}
                menuButtonSelected={menuButtonSelected}
                timeUntilAppointment={timeUntilAppointment}
                todayDate={todayDate}
                handlePastAppointmentsArr={handlePastAppointmentsArr}
                role={clientInfoRedux.role}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventsSection;
