import React, { useState } from 'react';

import { useMutation } from '@apollo/client';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import SimpleTextField from 'Components/Inputs/SimpleTextField';

import { VERIFY_PASSWORD_AND_ELIGIBILITY } from 'Services/Queries/user';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import buttonStyles from 'Assets/styles/lawyer/DeleteComponentsStyle/DeleteAccount.module.scss';
import styles from 'Assets/styles/lawyer/DeleteComponentsStyle/DeleteComponentFirstState.module.scss';
import stylesPassword from 'Assets/styles/lawyer/DeleteComponentsStyle/DeletePasswordCheck.module.scss';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DeleteAccountFirstCopmonent = (props) => {
  const {
    deleteTitle,
    deleteDetails,
    deleteSubDetails,
    keepAccountButton,
    user_id,
    handleToken,
    onClick,
    underButtonText,
  } = props;
  const [verifyPass] = useMutation(VERIFY_PASSWORD_AND_ELIGIBILITY);

  const [formValues, setFormValues] = useState({ password: '' });
  const [formErrors, setFormErrors] = useState({ password: '' });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
  const [snackbarMessage, setSnackbarMessage] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isError = false;
    let newInputErrors = { password: '' };

    if (formValues.password.length === 0) {
      isError = true;
      newInputErrors.password = 'Password required*';
    } else if (formValues.password.length < 8) {
      isError = true;
      newInputErrors.password = 'Password must be minimum 8 chars*';
    }

    setFormErrors(newInputErrors);

    if (isError) return;

    try {
      let passwordVerification = await verifyPass({
        variables: {
          id: user_id,
          password: formValues.password,
        },
      });

      if (passwordVerification.data.verifyPasswordAndEligibility.message) {
        handleToken(
          passwordVerification.data.verifyPasswordAndEligibility.message
        );
        onClick();
      } else if (passwordVerification.data.verifyPasswordAndEligibility.error) {
        setSnackbarMessage(LPTranslate('Error_Password_Invalid'));
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleInputChange = (value) => {
    setFormValues({ ...formValues, password: value });
  };

  return (
    <div className={styles['first-state']}>
      <div className={styles['delete-header-text']}>
        <p className={styles['text']}>{deleteTitle}</p>

        <p className={styles['text-details']}>
          {deleteDetails}
          <br />
          <br />

          {deleteSubDetails}
        </p>
      </div>
      <form
        className={stylesPassword.formContent}
        id="verifyForm"
        onSubmit={handleSubmit}
      >
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={
              snackbarSeverity === 'error' && {
                backgroundColor: '#7f56d9 !important',
              }
            }
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <div className={stylesPassword.passwordInput}>
          <div className={stylesPassword.inputWithLabel}>
            <SimpleTextField
              data_cy="verify-password-input"
              title="Passwort"
              placeholder="••••••••"
              onChange={(value) => {
                handleInputChange(value);
                setFormErrors({ ...formErrors, password: '' });
              }}
              value={formValues.password}
              textFieldWidthPercent={100}
              type="password"
              errorMessage={formErrors.password}
            />
          </div>
        </div>
        <div className={buttonStyles['delete-account-button']}>
          <button
            data-cy="submit-button"
            className={buttonStyles['delete-account-button-base']}
            form="verifyForm"
            type="submit"
          >
            <p className={buttonStyles['delete-account-button-text']}>
              {underButtonText}
            </p>
          </button>

          <button
            data-cy="cancel-button"
            className={styles['button-base']}
            onClick={(event) => {
              event.preventDefault();
              props.onBackButtonEvent();
            }}
          >
            <p className={styles['button-text']}>{keepAccountButton}</p>
          </button>
        </div>
      </form>
    </div>
  );
};

export default DeleteAccountFirstCopmonent;
