import React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import check from 'Assets/pictures/checkCircle.svg';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import 'Assets/styles/common/ApiResponseModal.scss';

const ApiResponseModal = (props) => {
  const handleClose = () => {
    props.handleClose();
  };

  return (
    <Modal open={props.show} onClose={handleClose}>
      <Box
        className={'lawp-api-response-modal-confirm-message-modal-container'}
      >
        <div
          className={
            props.isError ? 'icon-container-error' : 'icon-container-success'
          }
        >
          {props.isError ? (
            <ErrorOutlineIcon className="error-icon" />
          ) : (
            <img src={check} alt="key" />
          )}
        </div>
        <div className="text-container">
          <div className="title-text">{props.titleMessage}</div>
          <div className="description-text">{props.hintMessage}</div>
        </div>

        <div>
          <button
            data-cy="back-to-home-button"
            className="confirm-botton"
            onClick={() => {
              handleClose();
            }}
          >
            {props.buttonText}
          </button>
        </div>
      </Box>
    </Modal>
  );
};
export default ApiResponseModal;
