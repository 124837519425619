import React, { useState, useEffect } from 'react';

import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';

import CircularProgress from '@mui/material/CircularProgress';

import RegisterLawyerProcess from 'Components/RegisterLawyer/NewRegister/RegisterLawyerProcess';
import RegisterLawyerForm from 'Components/RegisterLawyer/NewRegister/RegisterLawyerForm';
import MetaDecorator from 'Layouts/MetaDecorator';

import { validateEmail } from 'Services/Utils/validations';
import { removeTokenInfo } from 'Services/Utils/token';
import { resetClientInfo } from 'Services/Redux/reducers/clientInfo';

import { VERIFY_PARAM_TOKEN_MUTATION } from 'Services/Queries/user';

import {
  REGISTER_LAWYER_2,
  GET_AVAILABLE_LAWYERS,
  CHECK_IF_LAWYER_EXISTS,
} from 'Services/Queries/lawyer';

import {
  addFirstname,
  addLastname,
  addRegisterEmail,
  setLawyerStepper,
  addRoleEmail,
  addTitle,
  addSalutation,
} from 'Services/Redux/reducers/RegisterReducer';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import { parseJwt } from 'Services/Utils/token';

const RegisterLawyerPage = () => {
  const [verifyParamToken, verifyParamTokenReturn] = useMutation(
    VERIFY_PARAM_TOKEN_MUTATION
  );

  const [getAvailableLawyers, { data: getAvailableLawyersData }] = useMutation(
    GET_AVAILABLE_LAWYERS
  );

  const [registerLawyer, { data: registerLawyerData }] =
    useMutation(REGISTER_LAWYER_2);
  const [checkIfLawyerExists] = useMutation(CHECK_IF_LAWYER_EXISTS);

  const registerStepperRedux = useSelector(
    (state) => state.registerStepper.value
  );

  const salutationData = [
    { title: 'Herr', selected: 0 },
    { title: 'Frau', selected: 0 },
    { title: 'Divers', selected: 0 },
  ];

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [marketingAccepted, setMarketingAccepted] = useState(false);

  const [availableLawyers, setAvailableLawyers] = useState([]);

  const [formValues, setFormValues] = useState({
    title: '',
    salutation: '',
    firstName: '',
    lastName: '',
    email: '',
  });
  const [formErrors, setFormErrors] = useState({
    salutation: '',
    firstName: '',
    lastName: '',
    email: '',
  });

  const [isSentFromLawyerPage, setIsSentFromLawyerPage] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    setFormValues({
      title: searchParams.get('title') || '',
      salutation: searchParams.get('salutation') || '',
      firstName: searchParams.get('firstName') || '',
      lastName: searchParams.get('lastName') || '',
      email: searchParams.get('email') || '',
    });

    if (searchParams.get('isSentFromLawyerPage')) {
      setIsSentFromLawyerPage(true);
    }

    removeTokenInfo();
    sessionStorage.clear();
    dispatch(resetClientInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [errorMessage, setErrorMessage] = useState('');

  let tokenAccepted = false;
  const { token } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const resetState = () => {
    setFormValues({
      title: '',
      salutation: '',
      firstName: '',
      lastName: '',
      email: '',
    });
    setTermsAccepted(false);
  };

  const handleInputChange = (inputName, value) => {
    switch (inputName) {
      case 'title':
        setFormValues({ ...formValues, title: value });
        break;
      case 'salutation':
        setFormValues({ ...formValues, salutation: value });
        break;
      case 'firstName':
        setFormValues({ ...formValues, firstName: value });
        break;
      case 'lastName':
        setFormValues({ ...formValues, lastName: value });
        break;
      case 'email':
        setFormValues({ ...formValues, email: value });
        break;

      default:
        break;
    }
  };

  const handleRegisterLawyer = async (
    lawyerSelected,
    title,
    salutation,
    email,
    firstName,
    lastName,
    randomEmail
  ) => {
    try {
      let isError = false;
      let newInputErrors = {};

      let check = await checkIfLawyerExists({
        variables: {
          firstName: formValues.firstName.trim(),
          lastName: formValues.lastName.trim(),
          email: formValues.email.trim(),
        },
      });

      if (check.data.checkIfLawyerExists.message !== 'ok') {
        isError = true;
        newInputErrors.email = LPTranslate('Error_Already_Existing_Email');
      }

      setFormErrors(newInputErrors);

      if (isError) return;

      await registerLawyer({
        variables: {
          input: {
            lawyer_id: Number(lawyerSelected),
            title: title,
            salutation: salutation,
            email: email,
            firstName: firstName,
            lastName: lastName,
            randomEmail: randomEmail,
            marketingAccepted: marketingAccepted,
          },
        },
      });
    } catch (error) {
      console.log('eroare la register: ', error);
    }
  };

  useEffect(() => {
    const verify = async () => {
      if (token) {
        try {
          const verifyToken = await verifyParamToken({
            variables: { input: { token: token, type: 'register' } },
          });

          if (!verifyToken) {
            setErrorMessage(LPTranslate('Error_Server_Down'));
          }
        } catch (error) {
          setErrorMessage(LPTranslate('Error_Server_Down'));
        }
      }
    };

    verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (
      verifyParamTokenReturn.data &&
      verifyParamTokenReturn.data.verifyParamTokenMutation
    ) {
      if (
        verifyParamTokenReturn.data.verifyParamTokenMutation.status &&
        verifyParamTokenReturn.data.verifyParamTokenMutation.status === 'error'
      ) {
        console.log('error');
      } else if (verifyParamTokenReturn.data.verifyParamTokenMutation.step) {
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
          event: 'lawyer-email-verified-link-custom-event',
        });
        const step = verifyParamTokenReturn.data.verifyParamTokenMutation.step;

        dispatch(addFirstname(parseJwt(token).firstname));
        dispatch(addLastname(parseJwt(token).lastname));
        dispatch(addRoleEmail(parseJwt(token).temporaryEmail));
        dispatch(addRegisterEmail(parseJwt(token).userEmail));
        if (step) {
          dispatch(setLawyerStepper(step));
        } else {
          dispatch(setLawyerStepper(4));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyParamTokenReturn.data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let isError = false;
      let newInputErrors = {};

      if (formValues.email.length === 0) {
        isError = true;
        newInputErrors.email = LPTranslate('Error_Email_Required');
      } else if (!validateEmail(formValues.email)) {
        isError = true;
        newInputErrors.email = LPTranslate('Error_Valid_Email');
      }

      if (formValues.salutation.length === 0) {
        isError = true;
        newInputErrors.salutation = LPTranslate('Error_Salutation_Required');
      }
      if (formValues.firstName.length === 0) {
        isError = true;
        newInputErrors.firstName = LPTranslate('Error_First_Name_Required');
      } else if (formValues.firstName.length > 128) {
        isError = true;
        newInputErrors.firstName = LPTranslate('Error_First_Name_Too_Long');
      }
      if (formValues.lastName.length === 0) {
        isError = true;
        newInputErrors.lastName = LPTranslate('Error_Last_Name_Required');
      } else if (formValues.lastName.length > 128) {
        isError = true;
        newInputErrors.lastName = LPTranslate('Error_Last_Name_Too_Long');
      }

      let check = await checkIfLawyerExists({
        variables: {
          firstName: formValues.firstName.trim(),
          lastName: formValues.lastName.trim(),
          email: formValues.email.trim(),
        },
      });

      if (check.data.checkIfLawyerExists.message !== 'ok') {
        isError = true;
        newInputErrors.email = LPTranslate('Error_Already_Existing_Email');
      }

      setFormErrors(newInputErrors);

      if (isError) return;

      dispatch(addTitle(formValues.title));
      dispatch(addSalutation(formValues.salutation));

      localStorage.removeItem('ClaimedLawyerId');

      await getAvailableLawyers({
        variables: {
          email: formValues.email.trim(),
        },
      });
    } catch (error) {
      setErrorMessage(LPTranslate('Error_Server_Down'));
    }
  };

  useEffect(() => {
    if (getAvailableLawyersData) {
      if (getAvailableLawyersData.getAvailableLawyers) {
        setAvailableLawyers(getAvailableLawyersData.getAvailableLawyers);
        if (
          getAvailableLawyersData.getAvailableLawyers.length < 1 &&
          registerStepperRedux.lawyerStepper === 0
        ) {
          let isError = false;
          let newInputErrors = {};

          checkIfLawyerExists({
            variables: {
              firstName: formValues.firstName.trim(),
              lastName: formValues.lastName.trim(),
              email: formValues.email.trim(),
            },
          })
            .then((check) => {
              if (check.data.checkIfLawyerExists.message !== 'ok') {
                isError = true;
                newInputErrors.email = LPTranslate(
                  'Error_Already_Existing_Email'
                );
              }

              setFormErrors(newInputErrors);

              if (isError) return;

              return registerLawyer({
                variables: {
                  input: {
                    lawyer_id: -1,
                    title: formValues.title,
                    salutation: formValues.salutation,
                    email: formValues.email,
                    firstName: formValues.firstName,
                    lastName: formValues.lastName,
                    randomEmail: false,
                    marketingAccepted: marketingAccepted,
                  },
                },
              });
            })
            .then(() => {
              dispatch(setLawyerStepper(2));
            })
            .catch((error) => {
              // Handle any error that occurred during the async operations
              console.log(error);
            });
        } else {
          dispatch(setLawyerStepper(1));
        }
      } else {
        console.log('eroare');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAvailableLawyersData]);

  if (verifyParamTokenReturn.loading === true) {
    return (
      <>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTtop: '-50px',
            marginLeft: '-50px',
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      </>
    );
  }

  return (
    <>
      <MetaDecorator
        title={LPTranslate('RL_Photo_Content_title')}
        description={LPTranslate('RL_Photo_Content_Description_1')}
        canonical={'canonical'}
        link={`${process.env.REACT_APP_DNS_URI}/registerLawyer`}
      />
      {registerStepperRedux.lawyerStepper === 0 && (
        <RegisterLawyerForm
          salutationData={salutationData}
          handleSubmit={handleSubmit}
          handleInputChange={handleInputChange}
          setFormErrors={setFormErrors}
          setTermsAccepted={setTermsAccepted}
          formValues={formValues}
          formErrors={formErrors}
          termsAccepted={termsAccepted}
          navigate={navigate}
          errorMessage={errorMessage}
          isSentFromLawyerPage={isSentFromLawyerPage}
          marketingAccepted={marketingAccepted}
          setMarketingAccepted={setMarketingAccepted}
        />
      )}

      {registerStepperRedux.lawyerStepper >= 1 && (
        <RegisterLawyerProcess
          availableLawyers={availableLawyers}
          formValues={formValues}
          resetState={resetState}
          tokenAccepted={tokenAccepted}
          token={token}
          location={location}
          handleRegisterLawyer={handleRegisterLawyer}
          registerLawyerData={registerLawyerData}
        />
      )}
    </>
  );
};

export default RegisterLawyerPage;
