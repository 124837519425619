import React from 'react';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import clientLoginImage from 'Assets/pictures/clientLoginImage.svg';

import styles from 'Assets/styles/CarouselComponent.module.scss';

const CarouselComponent = () => {
  return (
    <div className={styles['Image']}>
      <img
        className={styles['Image']}
        src={clientLoginImage}
        alt="clientloginimage"
        width={'576'}
        height={'768'}
      />
      <div className={styles['Bottom-panel']}>
        <div className={styles['divider']} />

        <div className={styles['attribution-card']}>
          <h2 className={styles['quote']}>{LPTranslate('LC_Carousel_Text')}</h2>
        </div>
      </div>
    </div>
  );
};

export default CarouselComponent;
