import React from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import 'Assets/styles/skeletons/LawyerCardSkeleton.scss';

const LawyerCardSkeleton = (props) => {
  return (
    <Box key={props.cardKey} className="lawp-lawyer-card-skeleton-container">
      <Skeleton variant="rectangular" className="card-image" />
      <Box className="card-info">
        <Box className="info-column">
          <Skeleton width="200px" />
          <Skeleton width="350px" />
          <Skeleton width="400px" />
        </Box>

        <Box className="info-row">
          <Skeleton width="150px" sx={{ mr: '30px' }} />
          <Skeleton width="150px" sx={{ mr: '30px' }} />
          <Skeleton width="150px" />
        </Box>
      </Box>
    </Box>
  );
};

export default LawyerCardSkeleton;
