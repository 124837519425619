import React from 'react';

import { useDispatch } from 'react-redux';

import CarouselComponent from 'Components/Carousel/CarouselComponent';
import LoginFormComponent from 'Components/LoginFormComponent';
import MetaDecorator from 'Layouts/MetaDecorator';

import { resetRegister } from 'Services/Redux/reducers/RegisterReducer';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/LoginPage.module.scss';

const LoginPage = () => {
  const dispatch = useDispatch();

  dispatch(resetRegister());

  return (
    <div className={styles['form-carousel-container']}>
      <MetaDecorator
        title={LPTranslate('LC_MetaDecoration_Title')}
        description={LPTranslate('LC_MetaDecoration_Description')}
        canonical={'canonical'}
        link={`${process.env.REACT_APP_DNS_URI}/login`}
      />
      <LoginFormComponent />
      <CarouselComponent />
    </div>
  );
};

export default LoginPage;
