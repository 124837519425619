import React from 'react';

import { useNavigate } from 'react-router-dom';

import SubscriptionUpdateModal from 'Components/Modals/SubscriptionUpdateModal';
import SubscriptionModalCardBenefiets from 'Components/Subscription/SubscriptionModalCardBenefiets.js';

import subscriptionTextVariables from 'Services/TextVariables/subscriptionCardTextVariables.js';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/lawyer/SubscriptionModal/SubscriptionModalCard.module.scss';

const SubscriptionModalCard = (props) => {
  const {
    subscriptionPageTextVariables,
    recurring_interval,
    cardTier,
    SubscriptionCardPrice,
    currentSubscription,
    lawyerSubscriptionRecurringInterval,
    handlePayButton,
    handleUpdate,
    priceId,
    schedule,
    buttonRedirect,
    role,
  } = props;
  const navigate = useNavigate();

  const ButtonShown = () => {
    if (buttonRedirect) {
      switch (role) {
        case 'lawyer':
          return (
            <button
              className={styles['update-subscription-button']}
              onClick={() => {
                navigate('/subscriptionPage', {
                  state: {
                    isSentFromLawyerPage: false,
                  },
                });
              }}
            >
              {LPTranslate('LSS_Get_Started_Plan_Title')}
            </button>
          );

        case '':
          return (
            <button
              className={styles['update-subscription-button']}
              data-cy="subscription-button"
              onClick={() => {
                localStorage.setItem('recurringInterval', recurring_interval);
                localStorage.setItem('cardTier', cardTier);
                navigate('/registerLawyer', {
                  state: {
                    isSentFromLawyerPage: false,
                  },
                });
              }}
            >
              {LPTranslate('LSS_Get_Started_Plan_Title')}
            </button>
          );

        default:
          break;
      }
    }
    let type = 'update';
    switch (currentSubscription) {
      case 'Platin':
        if (recurring_interval === 'year') {
          if (lawyerSubscriptionRecurringInterval === 'year') {
            if (cardTier === 'Silver' || cardTier === 'Gold') {
              type = 'downgrade';
            } else if (cardTier === 'Platin') {
              return (
                <button className={styles['actual-subscription-button']}>
                  {LPTranslate('LSS_Actual_Plan_Title')}
                </button>
              );
            }
          } else if (lawyerSubscriptionRecurringInterval === 'month') {
            if (cardTier === 'Silver') {
              type = 'downgrade';
            } else {
              type = 'update';
            }
          }
        } else if (recurring_interval === 'month') {
          if (lawyerSubscriptionRecurringInterval === 'year') {
            type = 'downgrade';
          } else if (lawyerSubscriptionRecurringInterval === 'month') {
            if (cardTier === 'Silver' || cardTier === 'Gold') {
              type = 'downgrade';
            } else if (cardTier === 'Platin') {
              return (
                <button className={styles['actual-subscription-button']}>
                  {LPTranslate('LSS_Actual_Plan_Title')}
                </button>
              );
            }
          }
        }
        if (
          schedule.recurringInterval === recurring_interval &&
          schedule.type === cardTier
        ) {
          type = 'CancelSchedule';
        }
        return (
          <SubscriptionUpdateModal
            type={type}
            new_recurring_interval={recurring_interval}
            old_recurring_interval={lawyerSubscriptionRecurringInterval}
            price_id={priceId}
            currentSubscription={currentSubscription}
            newSubscription={cardTier}
            handleUpdate={handleUpdate}
          />
        );
      case 'Gold':
        if (recurring_interval === 'year') {
          if (lawyerSubscriptionRecurringInterval === 'year') {
            if (cardTier === 'Silver') {
              type = 'downgrade';
            } else if (cardTier === 'Platin') {
              type = 'update';
            } else if (cardTier === 'Gold') {
              return (
                <button className={styles['actual-subscription-button']}>
                  {LPTranslate('LSS_Actual_Plan_Title')}
                </button>
              );
            }
          } else if (lawyerSubscriptionRecurringInterval === 'month') {
            if (cardTier === 'Silver') {
              type = 'downgrade';
            } else {
              type = 'update';
            }
          }
        } else if (recurring_interval === 'month') {
          if (lawyerSubscriptionRecurringInterval === 'year') {
            type = 'downgrade';
          } else if (lawyerSubscriptionRecurringInterval === 'month') {
            if (cardTier === 'Silver') {
              type = 'downgrade';
            } else if (cardTier === 'Platin') {
              type = 'update';
            } else if (cardTier === 'Gold') {
              return (
                <button className={styles['actual-subscription-button']}>
                  {LPTranslate('LSS_Actual_Plan_Title')}
                </button>
              );
            }
          }
        }
        if (
          schedule.recurringInterval === recurring_interval &&
          schedule.type === cardTier
        ) {
          type = 'CancelSchedule';
        }
        return (
          <SubscriptionUpdateModal
            type={type}
            new_recurring_interval={recurring_interval}
            old_recurring_interval={lawyerSubscriptionRecurringInterval}
            price_id={priceId}
            currentSubscription={currentSubscription}
            newSubscription={cardTier}
            handleUpdate={handleUpdate}
          />
        );
      case 'Silver':
        if (cardTier === 'Silver') {
          return (
            <button className={styles['actual-subscription-button']}>
              {LPTranslate('LSS_Actual_Plan_Title')}
            </button>
          );
        } else if (cardTier === 'Platin' || cardTier === 'Gold') {
          return (
            <button
              className={styles['update-subscription-button']}
              onClick={() => handlePayButton(priceId)}
            >
              {LPTranslate('LSS_Get_Started_Plan_Title')}
            </button>
          );
        }
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className={styles['card-component']}>
        <div className={styles['card-header']}>
          <div className={styles['card-header-and-price']}>
            <div className={styles['card-header-and-badge']}>
              <div className={styles['heading-text']}>
                {subscriptionPageTextVariables[cardTier].cardTitle}
              </div>
              {cardTier === 'Gold' && (
                <div className={styles['badge']}>
                  <div className={styles['badge-color']}>
                    <div className={styles['badge-text']}>
                      {LPTranslate('LSUS_Most_Popular')}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles['price-container']}>
              {recurring_interval === 'month' ? (
                <div className={styles['price-text']}>
                  €{SubscriptionCardPrice}
                </div>
              ) : (
                <div className={styles['price-text']}>
                  €{SubscriptionCardPrice / 12}
                </div>
              )}

              <div className={styles['price-per-time']}>
                <div className={styles['price-per-time-text']}>
                  {subscriptionPageTextVariables.perMonth}
                </div>
              </div>
            </div>
            <div className={styles['under-price-text']}>
              {cardTier === 'Silver'
                ? `${subscriptionPageTextVariables[cardTier].priceDescription_1}`
                : `${subscriptionPageTextVariables[cardTier].priceDescription_1}${SubscriptionCardPrice}€${subscriptionPageTextVariables[cardTier].priceDescription_2}`}
            </div>
          </div>

          <div className={styles['button-container']}>{ButtonShown()}</div>
        </div>
        <div className={styles['divider']} />
        <div className={styles['card-content']}>
          <div className={styles['card-content-header']}>
            <div className={styles['card-content-header-title']}>
              {subscriptionPageTextVariables[cardTier].benefietsTitle}
            </div>
            <div className={styles['card-content-header-undertitle']}>
              {subscriptionPageTextVariables[cardTier].benefietsUnderTitle}
            </div>
          </div>
        </div>
        <div className={styles['card-content-container']}>
          {cardTier === 'Silver' &&
            subscriptionTextVariables.Benefietsbasic.map((row, index) => (
              <SubscriptionModalCardBenefiets checkDetails={row} key={index} />
            ))}

          {cardTier === 'Gold' &&
            subscriptionTextVariables.Benefietsgold.map((row, index) => (
              <SubscriptionModalCardBenefiets checkDetails={row} key={index} />
            ))}

          {cardTier === 'Platin' &&
            subscriptionTextVariables.Benefietsplat.map((row, index) => (
              <SubscriptionModalCardBenefiets checkDetails={row} key={index} />
            ))}
        </div>
      </div>
    </>
  );
};
export default SubscriptionModalCard;
