import React, { useState } from 'react';

import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/inputs/SimpleTextField.scss';

const SimpleTextField = (props) => {
  const {
    title,
    multiline,
    rows,
    maxLength,
    hideHowManyCharactersLeft,
    placeholder,
    type,
    errorMessage,
    hasHintText,
    readonly,
    disabled,
  } = props;

  const [passwordVisibility, setPasswordVisibility] = useState(false);

  return (
    <div className="lawp-simple-text-field-container">
      {title && <p className="title">{title}</p>}

      <FormControl className="form-control">
        <InputBase
          readOnly={readonly}
          className={`${errorMessage ? 'error' : ''} ${
            disabled ? 'disabled' : ''
          }`}
          onChange={(e) => props.onChange(e.target.value)}
          multiline={multiline ? multiline : false}
          rows={rows ? rows : 1}
          type={type && !passwordVisibility ? type : 'text'}
          value={props.value}
          inputProps={{ maxLength: maxLength ? maxLength : undefined }}
          placeholder={placeholder !== undefined ? placeholder : ''}
          data-cy={props.data_cy}
          disabled={disabled}
        />
        {type === 'password' && [
          !passwordVisibility ? (
            <VisibilityIcon
              key={1}
              data-cy={'show-password-button'}
              onClick={() => setPasswordVisibility(true)}
              sx={{
                position: 'absolute',
                top: '12px',
                right: '15px',
                cursor: 'pointer',
              }}
            />
          ) : (
            <VisibilityOffIcon
              key={2}
              onClick={() => setPasswordVisibility(false)}
              data-cy={'hide-password-button'}
              sx={{
                position: 'absolute',
                top: '12px',
                right: '15px',
                cursor: 'pointer',
              }}
            />
          ),
        ]}
      </FormControl>

      {!errorMessage &&
        maxLength &&
        !hideHowManyCharactersLeft &&
        props.value !== null && (
          <p className="characters-left">
            {`${maxLength - props.value.length} ${LPTranslate(
              'ULPP_Profile_Input_Short_Description_Max_Chars'
            )}`}
          </p>
        )}

      {!errorMessage && hasHintText && (
        <p className="characters-left">{props.hintText}</p>
      )}

      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default SimpleTextField;
