import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useMutation, useQuery } from '@apollo/client';

import {
  CREATE_PAYMENT_SESSION,
  GET_SUBSCRIPTION_INFORMATION,
} from 'Services/Queries/stripe';
import subscriptionPageTextVariables from 'Services/TextVariables/subscriptionPageTextVariables.js';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/registerLawyer/SubscriptionStep.module.scss';
import SubscriptionCard from './SubscriptionCard';

const SubscriptionStep = (props) => {
  const { currentStep, handleNext } = props;
  const [recurringInterval, setRecurringInterval] = useState('year');
  const [DbPrices, setDbPrices] = useState([]);
  const [price_id, setPrice_id] = useState('');
  const [plans, setPlans] = useState({
    month: {
      Silver: 0,
      Gold: 0,
      Platin: 0,
    },
    year: {
      Silver: 0,
      Gold: 0,
      Platin: 0,
    },
  });
  const [isDisabled, setIsDisabled] = useState(false);

  const yearly = subscriptionPageTextVariables.yearly;
  const monthly = subscriptionPageTextVariables.monthly;
  const percentage = subscriptionPageTextVariables.persentage;

  const clientInfoRedux = useSelector((state) => state.clientInfo.value);
  const lawyerRegisterRedux = useSelector(
    (state) => state.registerStepper.value
  );

  const subscriptionInformation = useQuery(GET_SUBSCRIPTION_INFORMATION, {
    variables: { lawyer_id: clientInfoRedux.role_id },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [createPaymentSession] = useMutation(CREATE_PAYMENT_SESSION);

  useEffect(() => {
    const isPlanSelected = Object.keys(plans).some((key) => {
      return Object.keys(plans[key]).some((key2) => {
        return plans[key][key2] === 1;
      });
    });

    if (isPlanSelected) {
      localStorage.removeItem('subscriptionPlan');

      localStorage.setItem(
        'subscriptionPlan',
        Object.keys(plans[recurringInterval])[0]
      );
      setIsDisabled(true);
      handleCreatePaymentSession();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans]);

  useEffect(() => {
    if (subscriptionInformation.data) {
      setDbPrices(
        subscriptionInformation.data.stripeSubscriptionInformation.products
      );
      const products =
        subscriptionInformation.data.stripeSubscriptionInformation.products;

      let interval = 'year';
      let type = 'Gold';
      if (localStorage.getItem('cardTier')) {
        resetPlans();
        const object = { [localStorage.getItem('cardTier')]: 1 };
        setPlans((prevState) => ({
          ...prevState,
          [localStorage.getItem('recurringInterval')]: object,
        }));
        interval = localStorage.getItem('recurringInterval');
        type = localStorage.getItem('cardTier');
      }

      for (let i = 0; i < products.length; i++) {
        if (products[i].recurring_interval === interval) {
          if (products[i].stripe_product.tier === type) {
            setPrice_id(products[i].price_id);
          }
        }
      }
    }
  }, [subscriptionInformation.data]);

  const handleCreatePaymentSession = async () => {
    try {
      if (plans[recurringInterval].Silver === 1) {
        handleNext(7);
      } else {
        if (isLoading) return;

        setIsLoading(true);
        const CreatePaymentSessionData = await createPaymentSession({
          variables: {
            input: {
              payment_id: price_id,
              email: lawyerRegisterRedux.userEmail,
              temporary_email: lawyerRegisterRedux.roleEmail
                ? lawyerRegisterRedux.roleEmail
                : null,
              type: 'register',
            },
          },
        });
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'lawyer-buy-subscription-custom-event',
        });
        window.location.href =
          CreatePaymentSessionData.data.createPaymentSession.session_url;

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleRecurringInterval = (e, buttonPressed) => {
    switch (buttonPressed) {
      case 'month':
        const yearly = document.getElementById(
          'lawp-subscription-process-modal-year-button'
        );
        const badge = document.getElementById(
          'lawp-subscription-process-modal-year-button-badge'
        );
        if (yearly.classList.contains(styles['horizontal-tabs-year-active'])) {
          yearly.classList.remove(styles['horizontal-tabs-year-active']);
          badge.classList.remove(styles['horizontal-tabs-year-badge-active']);
        }
        if (
          !e.currentTarget.classList.contains(
            styles['horizontal-tabs-month-active']
          )
        ) {
          e.currentTarget.classList.add(styles['horizontal-tabs-month-active']);
          setRecurringInterval('month');
        }
        break;
      case 'year':
        const monthly = document.getElementById(
          'lawp-subscription-process-modal-month-button'
        );
        const badge2 = document.getElementById(
          'lawp-subscription-process-modal-year-button-badge'
        );
        if (
          !e.currentTarget.classList.contains(
            styles['horizontal-tabs-year-active']
          )
        ) {
          if (
            monthly.classList.contains(styles['horizontal-tabs-month-active'])
          ) {
            monthly.classList.remove(styles['horizontal-tabs-month-active']);
          }
          e.currentTarget.classList.add(styles['horizontal-tabs-year-active']);

          badge2.classList.add(styles['horizontal-tabs-year-badge-active']);
          setRecurringInterval('year');
        }
        break;

      default:
        break;
    }
  };

  const handleOpen = () => (window.location.href = '/paymentSettings');

  const resetPlans = () => {
    setPlans({
      month: {
        Silver: 0,
        Gold: 0,
        Platin: 0,
      },
      year: {
        Silver: 0,
        Gold: 0,
        Platin: 0,
      },
    });
  };

  const handleChangePlan = (recurring_interval, cardTier) => {
    resetPlans();

    const object = { [cardTier]: 1 };
    for (let i = 0; i < DbPrices.length; i++) {
      if (DbPrices[i].recurring_interval === recurring_interval) {
        if (DbPrices[i].stripe_product.tier === cardTier) {
          setPrice_id(DbPrices[i].price_id);
        }
      }
    }
    setPlans((prevState) => ({
      ...prevState,
      [recurring_interval]: object,
    }));
  };

  return (
    <div
      style={
        currentStep === 6
          ? {
            width: '100%',
          }
          : {}
      }
      className={styles['subscription-step-container']}
    >
      <div className={styles['subscription-step-header']}>
        <div
          className={styles['subscription-step-header-heading-support-text']}
        >
          <div
            className={styles['subscription-step-header-heading-sub-heading']}
          >
            <div className={styles['subscription-step-header-sub-heading']}>
              <button
                className={
                  styles['subscription-step-header-sub-heading-button']
                }
                onClick={handleOpen}
              >
                {LPTranslate('Common_Subscription_For_Lawyers')}
              </button>
            </div>
            <div className={styles['subscription-step-header-heading']}>
              {LPTranslate('LSUS_Title')}{' '}
            </div>
          </div>
        </div>
        <div className={styles['subscription-step-header-button']}>
          {/* month button */}
          <button
            id="lawp-subscription-process-modal-month-button"
            className={styles['horizontal-tabs-month']}
            onClick={(e) => handleRecurringInterval(e, 'month')}
          >
            {monthly}
          </button>
          {/* yearly button */}
          <div
            id="lawp-subscription-process-modal-year-button"
            className={`${styles['horizontal-tabs-year']} ${styles['horizontal-tabs-year-active']}`}
            onClick={(e) => handleRecurringInterval(e, 'year')}
          >
            <div className={styles['horizontal-tabs-year-text']}>{yearly}</div>
            <div
              id="lawp-subscription-process-modal-year-button-badge"
              className={`${styles['horizontal-tabs-year-badge']} ${styles['horizontal-tabs-year-badge-active']}`}
            >
              -{percentage}
            </div>
          </div>
        </div>
      </div>
      <div className={styles['subscription-step-content']}>
        {DbPrices?.length > 0 ? (
          <>
            {DbPrices.map(
              (price, index) =>
                price.recurring_interval === recurringInterval && (
                  <SubscriptionCard
                    key={index}
                    subscriptionPageTextVariables={
                      subscriptionPageTextVariables
                    }
                    recurring_interval={price.recurring_interval}
                    cardTier={price.stripe_product.tier}
                    SubscriptionCardPrice={price.unit_amount / 100}
                    handleChangePlan={handleChangePlan}
                    plans={plans}
                    isDisabled={isDisabled}
                  />
                )
            )}
          </>
        ) : (
          <div></div>
        )}
      </div>
      {/* <div className={styles['subscription-step-actions']}>
        <button
          data-cy="submit-button"
          className={styles['update-subscription-button']}
          onClick={() => handleCreatePaymentSession()}
        >
          {LPTranslate('Button_Next_Step')}
        </button>
      </div> */}
    </div>
  );
};

export default SubscriptionStep;
