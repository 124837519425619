import React, { useEffect, useState } from 'react';

import moment from 'moment';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import purpleCheckIcon from 'Assets/pictures/purpleCheckIcon.png';
import file from 'Assets/pictures/file.svg';
import success from 'Assets/pictures/success.svg';
import arrowDown from 'Assets/pictures/arrow-down.svg';

import styles from 'Assets/styles/subscription/subscriptionTable.module.scss';

const SubscriptionTable = (props) => {
  const [data, setData] = useState(props.list);
  const [order, setOrder] = useState('DSC');
  const [checkbox, setCheckbox] = useState(false);

  const indexWeekday = {
    Jan: LPTranslate('Anwado_January'),
    Feb: LPTranslate('Anwado_February'),
    Mar: LPTranslate('Anwado_March'),
    Apr: LPTranslate('Anwado_April'),
    May: LPTranslate('Anwado_May'),
    Jun: LPTranslate('Anwado_June'),
    Jul: LPTranslate('Anwado_July'),
    Aug: LPTranslate('Anwado_August'),
    Sep: LPTranslate('Anwado_September'),
    Oct: LPTranslate('Anwado_October'),
    Nov: LPTranslate('Anwado_November'),
    Dec: LPTranslate('Anwado_December'),
  };

  useEffect(() => {
    setData(props.list);
  }, [props.list]);

  const sorting = () => {
    if (order === 'ASC') {
      const sorted = props.list.sort(
        (a, b) => moment(b.date).format('X') - moment(a.date).format('X')
      );

      setData(sorted);
      setOrder('DSC');
    } else if (order === 'DSC') {
      const sorted = props.list.sort(
        (a, b) => moment(a.date).format('X') - moment(b.date).format('X')
      );

      setData(sorted);
      setOrder('ASC');
    }
  };

  const checkAll = (checkbox) => {
    const selectAll = [...data];

    if (checkbox === true) {
      setCheckbox(false);
      for (let i of selectAll) {
        i.selected = false;
      }
    } else {
      setCheckbox(true);
      for (let i of selectAll) {
        i.selected = true;
      }
    }

    props.handleInvoiceList(selectAll);

    setData(selectAll);
  };

  const handleInputChange = (id) => {
    const selectOne = [...data];

    for (let i of selectOne) {
      if (i.id === id) {
        i.selected = !i.selected;
        break;
      }
    }

    props.handleInvoiceList(selectOne);

    setData(selectOne);
  };

  return (
    <>
      <table className={styles['subscription']}>
        <thead className={styles['thead']}>
          <tr>
            {props.colNames.map((headerItem, index) => (
              <th key={index} className={styles[`${headerItem}`]}>
                {headerItem === 'Invoice' ? (
                  <>
                    <div className={styles['invoice-header']}>
                      <div className={styles['checkbox-label-container']}>
                        <div
                          className={
                            checkbox
                              ? styles['checkbox-container-selected']
                              : styles['checkbox-container']
                          }
                        >
                          <button
                            data-cy="check-all-button"
                            className={styles['check-button']}
                            onClick={() => checkAll(checkbox)}
                          >
                            <img
                              src={purpleCheckIcon}
                              className={
                                checkbox
                                  ? styles['check-style-selected-img']
                                  : styles['check-style-img']
                              }
                              alt="check"
                            />
                          </button>
                        </div>
                      </div>
                      <p className={styles[`${headerItem}-text`]}>
                        {headerItem}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    {headerItem !== 'Datum' && headerItem !== 'download' && (
                      <p className={styles[`${headerItem}-text`]}>
                        {headerItem}
                      </p>
                    )}
                  </>
                )}

                {headerItem === 'Datum' && (
                  <>
                    <button
                      data-cy="datum-button"
                      className={styles['Datum-button']}
                      onClick={() => sorting()}
                    >
                      <p className={styles[`${headerItem}-text`]}>
                        {headerItem}
                      </p>
                      <div className={styles['arrow-down']}>
                        {order === 'DSC' && (
                          <img src={arrowDown} alt="arrow down" />
                        )}
                        {order === 'ASC' && (
                          <img
                            alt="arrow down"
                            src={arrowDown}
                            style={{ transform: 'rotate(180deg)' }}
                          />
                        )}
                      </div>
                    </button>
                  </>
                )}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.map((object) => (
            <tr key={object.id}>
              <td className={styles['name']}>
                <div className={styles['invoice-container']}>
                  <div
                    data-cy="checkbox-button"
                    className={styles['checkbox-label-container']}
                  >
                    <div
                      className={
                        object.selected
                          ? styles['checkbox-container-selected']
                          : styles['checkbox-container']
                      }
                    >
                      <div
                        className={styles['check-button']}
                        onClick={() => handleInputChange(object.id)}
                      >
                        <img
                          src={purpleCheckIcon}
                          className={
                            object.selected
                              ? styles['check-style-selected-img']
                              : styles['check-style-img']
                          }
                          alt="check"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles['featured-icon']}>
                    <img src={file} alt="file" />
                  </div>
                  <p className={styles['text']}>
                    {' '}
                    {object.name.split(' ')[0] +
                      ' ' +
                      object.name.split(' ')[1] +
                      ' ' +
                      object.name.split(' ')[2] +
                      ' ' +
                      indexWeekday[object.name.split(' ')[3]] +
                      ' ' +
                      object.name.split(' ')[4] +
                      ' ' +
                      object.name.split(' ')[5]}
                  </p>
                </div>
              </td>
              <td className={styles['date']}>
                <p className={styles['date-text']}>
                  {object.date.split(' ')[0] +
                    ' ' +
                    object.date.split(' ')[1] +
                    ' ' +
                    object.date.split(' ')[2]}
                </p>
              </td>
              <td className={styles['status']}>
                <div className={styles['badge-base']}>
                  <img
                    src={success}
                    className={styles['success-image']}
                    alt="success"
                  />
                  <p className={styles['badge-text']}>{object.status}</p>
                </div>
              </td>
              <td className={styles['totalValue']}>
                <p className={styles['totalValue-text']}>
                  {object.totalValue / 100 + ' €'}
                </p>
              </td>
              <td className={styles['plan']}>
                <p className={styles['plan-text']}>{object.plan}</p>
              </td>

              <td className={styles['table-download-cell']}>
                <div className={styles['button']}>
                  <button
                    data-cy="download-invoice-button"
                    className={styles['button-base']}
                    onClick={() => props.handleDownloadSingle(object.id)}
                  >
                    <p className={styles['button-text']}>Download</p>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default SubscriptionTable;
