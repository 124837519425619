import React, { useEffect, useState } from 'react';

import jwt from 'jwt-decode';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

import SimpleTextField from 'Components/Inputs/SimpleTextField';

import { validateEmail } from 'Services/Utils/validations';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import { addAll } from 'Services/Redux/reducers/clientInfo';

// import { setJwtToken, setRefreshToken } from 'Services/Utils/token';
import { addName } from 'Services/Redux/reducers/clientInfo';

import { LOGIN_USER } from 'Services/Queries/user';

import styles from 'Assets/styles/LoginFormComponent.module.scss';

const LoginFormComponent = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loginUser, { data }] = useMutation(LOGIN_USER);

  const [formValues, setFormValues] = useState({ email: '', password: '' });
  const [formErrors, setFormErrors] = useState({ email: '', password: '' });

  const resetState = () => {
    setFormValues({ email: '', password: '' });
  };

  const handleInputChange = (inputName, value) => {
    switch (inputName) {
      case 'email':
        setFormValues({ ...formValues, email: value });
        break;
      case 'password':
        setFormValues({ ...formValues, password: value });
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    let emailURL = params.get('email');

    if (emailURL) {
      emailURL = emailURL.replace(/ /g, '+');
      setFormValues({ ...formValues, email: emailURL });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();
    let isError = false;
    let newInputErrors = { email: '', password: '' };

    if (formValues.email.length === 0) {
      isError = true;
      newInputErrors.email = LPTranslate('Error_Email_Required');
    } else if (!validateEmail(formValues.email)) {
      isError = true;
      newInputErrors.email = LPTranslate('Error_Valid_Email');
    }

    if (formValues.password.length === 0) {
      isError = true;
      newInputErrors.password = LPTranslate('Error_Password_Required');
    } else if (formValues.password.length < 8) {
      isError = true;
      newInputErrors.password = LPTranslate(
        'Error_Password_Required_Min_8_Characters'
      );
    }

    setFormErrors(newInputErrors);

    if (isError) return;

    try {
      await loginUser({
        variables: {
          input: {
            email: formValues.email,
            password: formValues.password,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const login = async () => {
      if (data && data.loginUser.jwtToken) {
        let { jwtToken, refresh_token } = data.loginUser;
        if (localStorage.getItem('cardTier')) {
          localStorage.removeItem('cardTier');
          localStorage.removeItem('recurringInterval');
        }

        const token_decoded = await jwt(jwtToken);

        let tokenInfo = {
          token: jwtToken,
          refresh_token: refresh_token,
          role: token_decoded.role,
          email: token_decoded.email,
          name: token_decoded.username,
          first_name: token_decoded.first_name,
          title: token_decoded.title,
          last_name: token_decoded.last_name,
          user_id: parseInt(token_decoded.user_id),
          role_id: parseInt(token_decoded.role_id),
        };
        dispatch(addName(token_decoded.username));

        localStorage.setItem('anwadoTokenInfo', JSON.stringify(tokenInfo));
        localStorage.setItem('remember', true);

        dispatch(addAll(tokenInfo));
        if (token_decoded.role) {
          if (token_decoded.role === 'operator') {
            navigate('/OperatorAccount');
          } else if (token_decoded.role === 'client') {
            navigate('/updateClientProfile');
          } else if (token_decoded.role === 'lawyer') {
            navigate('/updateLawyerPage');
          }
        }
        resetState();
      }
    };

    login();

    return () => {
      resetState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={styles['login-container']}>
      <div className={styles['main-section']}>
        <div className={styles['main-container']}>
          <div className={styles['client-content-container']}>
            <div className={styles['header-and-form']}>
              <div className={styles['header-login']}>
                <div className={styles['text-supporting-text']}>
                  <h2 className={styles['header-text']}>
                    {LPTranslate('LC_Title')}
                  </h2>
                  <h3 className={styles['header-supporting-text']}>
                    {LPTranslate('LC_Description')}
                  </h3>
                </div>
              </div>
              <form
                className={styles['form-login']}
                id="login-form"
                onSubmit={handleLogin}
              >
                <div className={styles['email-form-login']}>
                  <div className={styles['input-with-label']}>
                    <SimpleTextField
                      title="Email"
                      data_cy="email-input"
                      placeholder={LPTranslate('LC_Email_Placeholder')}
                      onChange={(value) => {
                        handleInputChange('email', value);
                        setFormErrors({ ...formErrors, email: '' });
                      }}
                      value={formValues.email}
                      textFieldWidthPercent={100}
                      type="text"
                      errorMessage={formErrors.email}
                    />
                  </div>
                </div>
                <div className={styles['password-form-login']}>
                  <div className={styles['input-with-label']}>
                    <SimpleTextField
                      title={LPTranslate('Input_Password')}
                      placeholder="••••••••"
                      data_cy="password-input"
                      onChange={(value) => {
                        handleInputChange('password', value);
                        setFormErrors({ ...formErrors, password: '' });
                      }}
                      value={formValues.password}
                      textFieldWidthPercent={100}
                      maxLength={100}
                      hideHowManyCharactersLeft={true}
                      type="password"
                      errorMessage={formErrors.password}
                    />
                  </div>
                </div>
                <div className={styles['row-form-login']}>
                  <div className={styles['checkbox-label-container']}>
                    {/* <div
                      className={
                        remember
                          ? styles['checkbox-container-selected']
                          : styles['checkbox-container']
                      }
                    >
                      <Button
                        className={styles['check-button']}
                        onClick={() => handleInputChange('remember', '')}
                        data-cy={'remember-me-button'}
                      >
                        <img
                          src={purpleCheckIcon}
                          className={
                            remember
                              ? styles['check-style-selected-img']
                              : styles['check-style-img']
                          }
                          alt="check"
                        />
                      </Button>
                    </div> */}
                    {/* <h4 className={styles['checkbox-text-label']}>
                      {LPTranslate('LC_Remember_Me')}
                    </h4> */}
                  </div>

                  <a
                    className={styles['row-button']}
                    data-cy={'forgot-password-button'}
                    href={`${process.env.REACT_APP_DNS_URI}/resetForgotPassword`}
                  >
                    {LPTranslate('LL_Forgot_Password')}
                  </a>
                </div>
                <div className={styles['actions-form-login']}>
                  {data && data.loginUser.status === 'Error' && (
                    <p className={styles['error-message']}>
                      {LPTranslate(data.loginUser.message)}
                    </p>
                  )}

                  <div className={styles['action-login-button-container']}>
                    <button
                      className={styles['login-button']}
                      type="submit"
                      form="login-form"
                      data-cy="login-button"
                    >
                      {LPTranslate('LC_Login_Button_Text')}
                    </button>
                  </div>
                </div>
              </form>

              <div className={styles['row-login']}>
                <h4
                  className={
                    props.roleLogin && props.roleLogin === 'client'
                      ? styles['client-row-text']
                      : styles['row-text']
                  }
                >
                  {props.roleLogin && props.roleLogin === 'client'
                    ? LPTranslate('LC_No_Account1')
                    : LPTranslate('LL_No_Account2')}
                </h4>

                <a
                  className={styles['client-row-button']}
                  data-cy={'register-button'}
                  href={`${process.env.REACT_APP_DNS_URI}/registerClient`}
                >
                  {LPTranslate('Common_Register')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginFormComponent;
