import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: {
    lawyers: [],
  },
};

const lawyersSlice = createSlice({
  name: 'lawyers',
  initialState: { value: initialState },
  reducers: {
    addLawyers: (state, action) => {
      if (action.payload) {
        state.value = {
          ...state.value,
          lawyers: action.payload,
        };
      }
    },
    resetLawyers: (state) => {
      state.value = [];
    },
  },
});
export const { addLawyers, resetLawyers } = lawyersSlice.actions;
export default lawyersSlice.reducer;
