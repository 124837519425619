import React from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/lawyer/EditSaveChangesSection.module.scss';

const EditLawyerSectionInfo = (props) => {
  const { noText, pageTitle, bottomChanges, topChanges, isLoading } = props;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftSection}>
          {noText !== true && (
            <>
              <div className={styles.titleContainer}>
                <span className={styles.titleText}>{pageTitle}</span>
              </div>
            </>
          )}
        </div>

        {pageTitle !== 'Konto' &&
          pageTitle !== 'Favoriten' &&
          pageTitle !== 'Termine' &&
          (bottomChanges || topChanges) && (
            <div className={styles.rightSection}>
              <Button
                data-cy="cancel-button"
                className={styles.revertButton}
                onClick={props.onResetFields}
              >
                {LPTranslate('Button_Cancel')}
              </Button>
              <Button
                data-cy="submit-button"
                className={styles.saveButton}
                onClick={props.onSaveChanges}
                form="update-form"
                disabled={isLoading}
              >
                <CircularProgress
                  size={24}
                  color="inherit"
                  style={{
                    color: 'white',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                    visibility: isLoading ? 'visible' : 'hidden',
                  }}
                />
                <span style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
                  {LPTranslate('Button_Save')}
                </span>
              </Button>
            </div>
          )}
      </div>

      {!noText && <div className={styles['Divider']} />}
    </>
  );
};

export default EditLawyerSectionInfo;
