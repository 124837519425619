import React, { useEffect, useState } from 'react';

// import Box from '@mui/material/Box';
// import Slider from '@mui/material/Slider';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

// import SearchTextField from 'Components/Inputs/SearchTextField';
import AutocompleteSelectAOL from 'Components/Inputs/AutocompleteSelectAOL';

import { addLocation } from 'Services/Redux/reducers/filters';
import { changeDropdownEvent } from 'Services/Redux/reducers/clientInfo';
import { resetLawyers } from 'Services/Redux/reducers/lawyer';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

// import compass from 'Assets/pictures/compass.svg';
// import mapPin from 'Assets/pictures/map-pin.svg';
// import downArrow from 'Assets/pictures/chevron-down.svg';

import 'Assets/styles/SearchBar.scss';
import styles from 'Assets/styles/searchBar/SearchBar.module.scss';

function SearchBarComponent(props) {
  const [locationDropdownTitle] = useState(
    LPTranslate('LP_SearchBar_Location_Dropdown_Placeholder')
  );

  const dispatch = useDispatch();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    // var dropdownLocation = document.getElementById('dropdown-menu-location-id');

    const searchStick = () => {
      // !!! ADD AGAIN IF SEARCHBAR DROPDOWN IS ADDED BACK
      // let dropdownModifier = window.innerWidth < 1060 ? 400 : 100;
      // if (window.pageYOffset > dropdownModifier) {
      //   dropdownLocation.classList.add('dropdown-menu-location-down');
      // } else {
      //   dropdownLocation.classList.remove('dropdown-menu-location-down');
      // }
    };

    window.addEventListener('scroll', searchStick);

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      dispatch(resetLawyers());
      dispatch(changeDropdownEvent('mesaj'));
      window.removeEventListener('scroll', searchStick);
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [dispatch]);

  // const handleLocationDropdown = (value) => {
  //   if (value) {
  //     setLocationDropdownTitle(value);
  //   } else {
  //     setLocationDropdownTitle(
  //       LPTranslate('LP_SearchBar_Location_Dropdown_Placeholder')
  //     );
  //   }
  // };

  useEffect(() => {
    // adaugam event listener-ul de click dupa randare doar o singura data pentru dropdowns
    window.addEventListener('click', handleDropdown);
    return () => {
      window.removeEventListener('click', handleDropdown);
    };
  }, []);

  useEffect(() => {
    if (locationDropdownTitle.length > 0) {
      dispatch(addLocation(locationDropdownTitle));
    }
  }, [dispatch, locationDropdownTitle]);

  const handleSearchLawyers = async () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'search-lawyer-custom-event',
    });
    props.updateLawyers();
  };

  const handleDropdown = (e) => {
    // verificam daca am apasat pe un buton dropdown, activam dropdown-ul si ascudem celelalte dropdowns
    const isDropdownButton = e.target.matches('[data-dropdown-button]');
    if (!isDropdownButton && e.target.closest('[data-dropdown]') != null)
      return;

    let currentDropdown;

    if (isDropdownButton) {
      currentDropdown = e.target.closest('[data-dropdown]');
      currentDropdown.classList.toggle('active');
    }
    document.querySelectorAll('[data-dropdown].active').forEach((dropdown) => {
      if (dropdown === currentDropdown) return;
      dropdown.classList.remove('active');
    });
  };

  return (
    <div className={styles['search-bar-container']} id="searchBar">
      <div className={styles['search-field-container']}>
        <div className={styles['input-container']}>
          <div className={styles['input-field-label-container']}>
            <input
              type="text"
              name="search"
              placeholder={LPTranslate('LP_SearchBar_Input_Placeholder')}
              className={styles['input-search']}
              style={{
                width: '100%',
              }}
              value={props.searchInput}
              onChange={(e) => props.setSearchInput(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  props.onEnterKeyPress();
                }
              }}
              data-cy="input-name"
            />
          </div>

          {/* <SearchTextField
                        // data-cy="location-input"
                        locationInput={props.locationInput}
                        setLocationInput={props.setLocationInput}
                        handleLocationDropdown={handleLocationDropdown}
                        isDisabled={
                          props.locationDistanceInput !== 0 ? true : false
                        }
                        title={LPTranslate('Common_Location')}
                        onEnterKeyPress={props. }
                      /> */}

          <div className={styles['dropdown-container']}>
            <div className={styles['input-field-label-container-city']}>
              <input
                type="text"
                name="search"
                placeholder={LPTranslate('Common_Location')}
                className={styles['input-search']}
                style={{
                  width: '100%',
                }}
                value={props.locationInput}
                onChange={(e) => props.setLocationInput(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    props.onEnterKeyPress();
                  }
                }}
                data-cy="input-city"
              />
            </div>

            <div className={styles['input-dropdown-rechtsgebiete']}>
              <AutocompleteSelectAOL
                boxes={props.areasOfLawBoxes}
                onChange={props.onChangeAreasOfLaw}
                data-cy="areas-dropdown"
              />
            </div>

            {/* <div className={styles['input-dropdown-location']}>
              <div className="dropdown-location" data-dropdown>
                <button
                  className="filter1"
                  data-dropdown-button
                  data-cy="location-dropdown"
                >
                  <img
                    className={styles['map-pin']}
                    src={mapPin}
                    alt="mapPin"
                    data-dropdown-button
                  />

                  {props.locationDistanceInput > 0
                    ? props.locationDistanceInput + ' km'
                    : [
                        props.locationInput.length > 0
                          ? props.locationInput
                          : locationDropdownTitle,
                      ]}

                  <img
                    className="downArrow"
                    src={downArrow}
                    alt="downArrow"
                    data-dropdown-button
                  />
                </button>
                <div
                  className="dropdown-menu-location dropdown-menu-location-up"
                  id="dropdown-menu-location-id"
                >
                  <div className={styles['dropdown-menu-location-title']}>
                    <img
                      className={styles['map-pin']}
                      src={compass}
                      alt="Compass"
                    />
                    {LPTranslate('LP_SearchBar_Location_Dropdown_Title')}
                  </div>
                  <div className={styles['line']}></div>
                  <div
                    className={
                      styles['dropdown-menu-location-content-container']
                    }
                  >
                    <div
                      className={
                        styles['dropdown-menu-location-input-label-container']
                      }
                    >
                      <SearchTextField
                        // data-cy="location-input"
                        locationInput={props.locationInput}
                        setLocationInput={props.setLocationInput}
                        handleLocationDropdown={handleLocationDropdown}
                        isDisabled={
                          props.locationDistanceInput !== 0 ? true : false
                        }
                        title={LPTranslate('Common_Location')}
                        onEnterKeyPress={props.onEnterKeyPress}
                      />

                      <Box style={{ marginTop: '5px' }} width={180}>
                        <h4 className="lawp-search-bar-component-search-title">
                          {LPTranslate(
                            'LP_SearchBar_Location_User_Distance_Title'
                          )}
                        </h4>

                        <Slider
                          data-cy="distance-slider"
                          className={`lawp-search-bar-component-distance-slider 
                            ${
                              props.locationDistanceInput === 0
                                ? 'lawp-search-bar-component-distance-slider-disabled'
                                : ''
                            }
                            `}
                          sx={{
                            color: props.locationInput
                              ? 'gray !important'
                              : '#7f56d9 !important',
                          }}
                          valueLabelDisplay="auto"
                          aria-label="pretto slider"
                          min={0}
                          max={160}
                          step={5}
                          disabled={
                            props.locationInput ||
                            props.locationDistanceInputDisabled === 'denied'
                              ? true
                              : false
                          }
                          value={props.locationDistanceInput}
                          valueLabelFormat={(value) =>
                            value !== 0
                              ? `${value}km`
                              : LPTranslate(
                                  'LP_SearchBar_Location_No_Selection'
                                )
                          }
                          onChange={(e, newValue) => {
                            props.onChangeLocationDistanceSlider(newValue);
                          }}
                        />
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>*/}
          </div>
        </div>

        <div className={styles['input-button-container']}>
          <Button
            className="lawp-search-bar-search-button"
            onClick={() => handleSearchLawyers()}
            disableRipple
            data-cy="search-lawyer"
          >
            {windowSize.innerWidth > 1060 ? (
              LPTranslate('LP_SearchBar_Button_Placeholder')
            ) : (
              <SearchRoundedIcon className="icon-search-button" />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export default SearchBarComponent;
