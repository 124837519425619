import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
// import jwt from 'jwt-decode';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';

import RatingStars from 'Components/RatingStars/RatingStars';
import SimpleTextField from 'Components/Inputs/SimpleTextField';
import ConfirmAddReviewLawyerModal from 'Components/Modals/ConfirmAddReviewLawyerModal';
import LawyerReviewImage from 'Components/Lawyer/LawyerReviewImage.js';

import { validateEmail } from 'Services/Utils/validations';
import { MINI_REGISTER } from 'Services/Queries/user';
// import { ADD_REVIEW } from 'Services/Queries/client';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import arrowLeft from 'Assets/pictures/purple-left-arrow.svg';

import 'Assets/styles/lawyer/AddLawyerReviewModal.scss';
import RegisterCheckboxComponent from 'Components/CommonComponents/RegisterCheckboxComponent';

export default function AddLawyerReviewNotLoggedNameBox(props) {
  let {
    articleImage,
    lawyerNameTitle,
    firstname,
    lastname,
    lawyer_email,
    lawyerNameSuffixTitle,
    rating,
    newRating,
    reviewsNr,

    reviewsNumber,
    short_description,
    areasOfLaw,
    AreaOfLawTagJSX,
    reviewTitle,
    reviewText,

    lawyer_id,
    shownModal,
    formValues,
    formErrors,
    specialities,
  } = props;

  // const [addReview, { data: addReviewData }] = useMutation(ADD_REVIEW);
  const [miniRegister, { data: miniRegisterData }] = useMutation(MINI_REGISTER);
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const [errorMessage, setErrorMessage] = useState('');

  // const [goToRegister, setGoToRegister] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [marketingAccepted, setMarketingAccepted] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (clientInfoRedux.role) {
      props.handleCloseModal(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shownModal]);

  // const callSendMessage = async (token, token_decoded) => {
  //   try {
  //     await addReview({
  //       variables: {
  //         input: {
  //           token: token,
  //           client_id: parseInt(token_decoded.role_id),
  //           lawyer_id: parseInt(lawyer_id),
  //           lawyer_name: `${firstname} ${lastname}`,
  //           title: reviewTitle.trim(),
  //           content: reviewText.trim(),
  //           rating: newRating,
  //           client_name: `${formValues.firstName} ${formValues.lastName}`,
  //         },
  //       },
  //     });
  //   } catch (error) {
  //     setErrorMessage(error.message);
  //   }
  // };

  const submitHandler = async (e) => {
    e.preventDefault();

    let isError = false;
    let newInputErrors = {};

    if (formValues.email.length === 0) {
      isError = true;
      newInputErrors.email = LPTranslate('Error_Email_Required');
    } else if (!validateEmail(formValues.email)) {
      isError = true;
      newInputErrors.email = LPTranslate('Error_Valid_Email');
    }

    if (formValues.firstName.length === 0) {
      isError = true;
      newInputErrors.firstName = LPTranslate('Error_First_Name_Required');
    } else if (formValues.firstName.length > 128) {
      isError = true;
      newInputErrors.firstName = LPTranslate('Error_First_Name_Too_Long');
    }
    if (formValues.lastName.length === 0) {
      isError = true;
      newInputErrors.lastName = LPTranslate('Error_Last_Name_Required');
    } else if (formValues.lastName.length > 128) {
      isError = true;
      newInputErrors.lastName = LPTranslate('Error_Last_Name_Too_Long');
    }

    props.setFormErrors(newInputErrors);

    if (isError) return;

    try {
      await miniRegister({
        variables: {
          input: {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            lawyer_id: parseInt(lawyer_id),
            lawyer_name: `${firstname} ${lastname}`,
            lawyer_email: lawyer_email,
            email: formValues.email,
            role: 'client',
            title: reviewTitle.trim(),
            content: reviewText.trim(),
            rating: newRating,
            type: 'mini_register_review',
            marketingAccepted: marketingAccepted,
          },
        },
      });
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    if (miniRegisterData && miniRegisterData.miniRegister) {
      if (miniRegisterData.miniRegister.status === '204') {
        setErrorMessage(miniRegisterData.miniRegister.message);
      } else if (miniRegisterData.miniRegister.jwtToken) {
        props.setShownModal(2);
        // mutatie set appointment
        // const token_decoded = jwt(miniRegisterData.miniRegister.jwtToken);
        // callSendMessage(
        //   `Bearer ${miniRegisterData.miniRegister.jwtToken}`,
        //   token_decoded
        // );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [miniRegisterData]);

  // useEffect(() => {
  //   if (
  //     addReviewData &&
  //     addReviewData.addReview &&
  //     addReviewData.addReview.status === 'error'
  //   ) {
  //     setErrorMessage(addReviewData.addReview.message);
  //     return;
  //   }

  //   if (addReviewData && addReviewData.addReview !== null) {
  //     setGoToRegister(true);
  //     props.handleCloseModal(2);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [addReviewData]);

  const handleConfirm = () => {
    // if (miniRegisterData && miniRegisterData.miniRegister) {
    //   navigate('/registerClient', {
    //     state: {
    //       firstname: props.client_firstname,
    //       lastname: props.client_lastname,
    //       email: props.client_email,
    //       registerFromOtherComponent: true,
    //     },
    //   });
    // } else if (clientInfoRedux.role) {
    //   props.setRatingData({
    //     ...props.ratingData,
    //     reviewTitle: reviewTitle,
    //   });
    //   props.setRatingData({
    //     ...props.ratingData,
    //     reviewText: reviewText,
    //   });
    //   props.setRatingData({ ...props.ratingData, rating: newRating });
    //   props.handleCloseModal(0);
    //   props.handleClose();
    // } else {
    props.setReviewTitle('');
    props.setReviewText('');
    props.setRating('');
    props.setTermsAccepted(false);
    props.handleCloseModal(0);
    props.handleClose();
    // }
  };

  return (
    <>
      <Box className="lawp-add-lawyer-review-modal">
        <div className="lawp-add-lawyer-review-modal-container">
          <div className="lawp-add-lawyer-review-modal-container-left">
            <Button
              className="lawp-add-lawyer-review-modal-back-to-profile-overview-button"
              onClick={props.handleClose}
              startIcon={<ArrowBackRoundedIcon />}
            >
              {LPTranslate('ARM_Action_Button_Return')}
            </Button>

            <LawyerReviewImage image={articleImage} />

            <h4 className="lawp-add-lawyer-review-modal-lawyer-specialization">
              {specialities.length > 0 &&
                specialities.map((speciality, index) => {
                  if (index === 0) {
                    return (
                      <>{`${LPTranslate(
                        'SL_Lawyer_Card_Specialist'
                      )} ${speciality}, `}</>
                    );
                  } else if (index !== specialities.length - 1) {
                    return <>{`${speciality}, `}</>;
                  } else {
                    return <>{`${speciality}`}</>;
                  }
                })}
            </h4>

            <h2 className="lawp-add-lawyer-review-modal-lawyer-name-text">
              {lawyerNameTitle && lawyerNameTitle + ' '}
              {firstname + ' ' + lastname}
              {lawyerNameSuffixTitle && ', ' + lawyerNameSuffixTitle}
            </h2>

            <div className="lawp-add-lawyer-review-modal-rating-container">
              <RatingStars
                value={Number(rating)}
                width={20}
                height={20}
                readonly={true}
                colorFilter={
                  'invert(91%) sepia(69%) saturate(1377%) hue-rotate(317deg) brightness(99%) contrast(102%)'
                }
              />
              <h3 className="lawp-add-lawyer-review-modal-rating-text">
                {Number(rating)}
              </h3>

              <h3 className="lawp-add-lawyer-review-modal-rating-reviews">
                {reviewsNumber === 1 && reviewsNumber !== 0
                  ? `${LPTranslate(
                      'LPP_Header_From'
                    )} ${reviewsNr} ${LPTranslate('Common_Review')}`
                  : `${LPTranslate(
                      'LPP_Header_From'
                    )} ${reviewsNr} ${LPTranslate('Common_Reviews')}`}
              </h3>
            </div>

            <div className="lawp-add-lawyer-review-modal-lawyer-description">
              <h3 className="lawp-add-lawyer-review-modal-lawyer-description-text">
                {short_description}
              </h3>
            </div>

            <div className="lawp-add-lawyer-review-modal-areas-of-law-container">
              {areasOfLaw.map(AreaOfLawTagJSX)}
            </div>
          </div>

          <div
            className="lawp-add-lawyer-review-modal-container-right"
            // style={{ height: '100%' }}
          >
            <div className="lawp-add-lawyer-review-modal-container-right-centered">
              <div
                className={`lawp-add-lawyer-review-modal-container-right-back-button-container${
                  shownModal === 2 ? '-last-modal' : ''
                }`}
              >
                <button
                  className={
                    'lawp-add-lawyer-review-modal-container-right-row-arrow-button-back'
                  }
                  onClick={() => props.setShownModal(0)}
                >
                  <img src={arrowLeft} alt="arrow" />
                </button>
              </div>
              {shownModal === 1 ? (
                <>
                  <div className="lawp-add-lawyer-review-modal-container-heading-and-supporting-text">
                    <h1 className="lawp-add-lawyer-review-modal-title-text">
                      {LPTranslate('USA_Title')}
                    </h1>

                    <h3 className="lawp-add-lawyer-review-modal-title-description-text">
                      {LPTranslate('USA_Description')}
                    </h3>
                  </div>

                  <div className="lawp-add-lawyer-review-modal-bewertung">
                    <RatingStars
                      value={Number(newRating)}
                      width={65.8}
                      height={65.8}
                      readonly={true}
                      colorFilter={
                        'invert(73%) sepia(82%) saturate(1547%) hue-rotate(341deg) brightness(104%) contrast(98%)'
                      }
                    />

                    <div className="lawp-add-lawyer-review-modal-frame1034">
                      <h2 className="lawp-add-lawyer-review-modal-frame1034-text">
                        {reviewTitle}
                      </h2>
                      <h3 className="lawp-add-lawyer-review-modal-frame1034-supporting-text">
                        “{reviewText}”
                      </h3>
                    </div>
                  </div>

                  <div className="lawp-add-lawyer-review-modal-content">
                    <form
                      className="lawp-add-lawyer-review-modal-form"
                      id="add-review-not-logged-form"
                      onSubmit={submitHandler}
                    >
                      <div className="lawp-add-lawyer-review-modal-textfield-container">
                        <SimpleTextField
                          data_cy="first-name-input"
                          title={LPTranslate('ULPP_Profile_Input_First_Name')}
                          placeholder={LPTranslate(
                            'Input_First_Name_Placeholder'
                          )}
                          onChange={(value) => {
                            props.handleInputChange('firstName', value);
                            props.setFormErrors({
                              ...formErrors,
                              firstName: '',
                            });
                          }}
                          value={formValues.firstName}
                          type="text"
                          errorMessage={formErrors.firstName}
                        />
                      </div>

                      <div className="lawp-add-lawyer-review-modal-textfield-container">
                        <SimpleTextField
                          data_cy="last-name-input"
                          title={LPTranslate('Input_Last_Name')}
                          placeholder={LPTranslate(
                            'Input_Last_Name_Placeholder'
                          )}
                          onChange={(value) => {
                            props.handleInputChange('lastName', value);
                            props.setFormErrors({
                              ...formErrors,
                              lastname: '',
                            });
                          }}
                          value={formValues.lastName}
                          type="text"
                          errorMessage={formErrors.lastName}
                        />
                      </div>

                      <div className="lawp-add-lawyer-review-modal-textfield-container">
                        <SimpleTextField
                          data_cy="email-address-input"
                          title="Email*"
                          placeholder={LPTranslate('Common_Email_Placeholder')}
                          onChange={(value) => {
                            props.handleInputChange('email', value);
                            props.setFormErrors({
                              ...formErrors,
                              email: '',
                            });
                          }}
                          value={formValues.email}
                          type="text"
                          errorMessage={formErrors.email}
                        />
                      </div>
                    </form>

                    {errorMessage && (
                      <p className="lawp-add-lawyer-review-not-logged-error-message">
                        {errorMessage}
                      </p>
                    )}

                    <div className="lawp-add-lawyer-review-modal-actions">
                      <div
                        style={{
                          marginTop: '-20px',
                        }}
                      >
                        <RegisterCheckboxComponent
                          termsAccepted={termsAccepted}
                          setTermsAccepted={setTermsAccepted}
                          marketingAccepted={marketingAccepted}
                          setMarketingAccepted={setMarketingAccepted}
                        />
                      </div>

                      {termsAccepted ? (
                        <Button
                          data-cy="submit-button"
                          type="submit"
                          form="add-review-not-logged-form"
                          className="lawp-add-lawyer-review-modal-submit-button"
                        >
                          {/* {LPTranslate('USA_Action_Button')} */}
                          OK
                        </Button>
                      ) : (
                        <Button
                          data-cy="submit-button"
                          type="submit"
                          form="add-review-not-logged-form"
                          className="lawp-add-lawyer-review-modal-submit-button-disabled"
                          disabled
                        >
                          {/* {LPTranslate('USA_Action_Button')} */}
                          OK
                        </Button>
                      )}
                    </div>
                    <div className="lawp-add-lawyer-review-modal-row">
                      <h4 className="lawp-add-lawyer-review-modal-row-text">
                        {LPTranslate('Common_Already_Have_Account_Question')}
                      </h4>
                      <button
                        data-cy="login-button"
                        className="lawp-add-lawyer-review-modal-row-button"
                        onClick={() => navigate('/login')}
                      >
                        {LPTranslate('Common_Login_Now')}
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <ConfirmAddReviewLawyerModal
                  handleClose={props.handleClose}
                  handleCloseModal={props.handleCloseModal}
                  setReviewTitle={props.setReviewTitle}
                  setReviewText={props.setReviewText}
                  setRating={props.setRating}
                  setTermsAccepted={props.setTermsAccepted}
                  clientInfoRedux={clientInfoRedux}
                  onReloadChanges={props.onReloadChanges}
                  // goToRegister={goToRegister}
                  client_firstname={formValues.firstName}
                  client_lastname={formValues.lastName}
                  client_email={formValues.email}
                  handleBackButton={() => props.setShownModal(0)}
                  handleConfirm={handleConfirm}
                />
              )}
            </div>
          </div>
        </div>
      </Box>
    </>
  );
}
