import React, { useState, useEffect, useRef } from 'react';

import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/lawyer/GoogleLocationsAutocompleteTextfield.scss';

let autoComplete;
let zipCode;
let city;

const loadScript = (url, callback) => {
  let script = document.createElement('script');
  script.type = 'text/javascript';

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    {
      fields: ['address_components', 'name', 'formatted_address'],
      types: ['address'],
    }
  );

  autoComplete.setFields(['address_components', 'formatted_address']);
  autoComplete.addListener('place_changed', () =>
    handlePlaceSelect(updateQuery)
  );
}

async function handlePlaceSelect(updateQuery) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.name;

  updateQuery(query);
  if (addressObject.address_components !== undefined) {
    for (var i = 0; i < addressObject.address_components.length; i++) {
      if (
        addressObject.address_components[i].types[0] === 'locality' ||
        addressObject.address_components[i].types[0] === 'postal_town'
      )
        city = addressObject.address_components[i].long_name;
      if (
        i === addressObject.address_components.length - 1 &&
        addressObject.address_components[i].types[0] === 'postal_code'
      )
        zipCode = addressObject.address_components[i].short_name;
      else {
        zipCode = '';
      }
    }
  }
}

const CustomTextField = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 8,

    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? 'white' : '#2b2b2b',
    border: '1px solid #D0D5DD',
    fontSize: 16,
    height: 24,
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    fontFamily: ['Inter'].join(','),

    '&:focus': {
      borderRadius: 8,
      borderColor: '#7f56d9',
    },
  },
}));

function AutocompleteAdress(props) {
  const [query, setQuery] = useState(null);
  const autoCompleteRef = useRef(null);

  const { textFieldWidthPercent, multiline, rows, maxLength, errorMessage } =
    props;

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyDN4Fv1yTCOpDY5HssJx4IRBOxXhZwPjKU&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  const widthTextField =
    textFieldWidthPercent !== undefined ? `${textFieldWidthPercent}%` : 300;

  useEffect(() => {
    if (props.addressValue !== query && query !== null) {
      props.onChangeAddressValue(query);

      if (!props.isSettingAppointment && query !== '') {
        props.onChangeZipCode(zipCode ? zipCode : '');
        props.onChangeCity(city ? city : '');
      }
    }
  }, [query, props]);

  return (
    <div
      style={{
        display: 'block',
        width: widthTextField,
      }}
    >
      <p
        style={{
          fontFamily: 'Inter',
          fontWeight: 500,
          fontSize: 14,
          color: '#344054',
          marginBottom: 4,
        }}
      >
        {' '}
        {props.title === 'Adresse' &&
          !props.isSettingAppointment &&
          props.title}
        {props.title !== 'Adresse' &&
          !props.isSettingAppointment &&
          props.title}
      </p>
      <FormControl
        sx={{ width: '100%', marginTop: '0px !important' }}
        variant="standard"
      >
        <CustomTextField
          data-cy="address-autocomplete-input"
          inputRef={autoCompleteRef}
          onChange={(event) => setQuery(event.target.value)}
          multiline={multiline ? multiline : false}
          rows={rows ? rows : 1}
          value={props.addressValue}
          inputProps={{ maxLength: maxLength ? maxLength : undefined }}
          placeholder={LPTranslate('Input_Address_Placeholder')}
          sx={{
            '& .MuiInputBase-input': {
              border: errorMessage ? '1px solid #ff0000' : '1px solid #D0D5DD',
            },
          }}
        />
      </FormControl>

      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
}

export default AutocompleteAdress;
