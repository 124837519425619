import styles from 'Assets/styles/lawyer/DeleteComponentsStyle/DeleteComponentThirdState.module.scss';
import purpleCheckMark from 'Assets/pictures/purpleCheckMark.svg';

const DeleteAccountThirdCopmonent = (props) => {
  const {
    deleteTitle,
    deleteDetails,
    deleteSubDetails,
    benefits1,
    benefits2,
    benefits3,
    deleteComponentSubTitle,
  } = props;
  return (
    <div className={styles['third-state']}>
      <p className={styles['text']}>{deleteTitle}</p>
      <div className={styles['third-state-title']}>
        <div className={styles['no-delete-title']}>
          <div className={styles['text-details']}>
            {deleteDetails}
            <br />
            <br />
            {deleteSubDetails}
          </div>
        </div>
      </div>

      <div className={styles['container']}>
        <div className={styles['benefits-sub-text']}>
          {deleteComponentSubTitle}
        </div>
        {/* <div className={styles['container-position']}> */}
        <div className={styles['container-benefits']}>
          <div className={styles['row-details']}>
            <div className={styles['purple-circle']}>
              <div className={styles['check-mark']}>
                <img src={purpleCheckMark} alt="Purple Check Mark" />
              </div>
            </div>

            <div className={styles['check-mark-text']}>{benefits1}</div>
          </div>
          <div className={styles['delete-lawyer-row-details']}>
            <div className={styles['purple-circle']}>
              <div className={styles['check-mark']}>
                <img src={purpleCheckMark} alt="Purple Check Mark" />
              </div>
            </div>

            <div className={styles['check-mark-text']}>{benefits2}</div>
          </div>
          <div className={styles['row-details']}>
            <div className={styles['purple-circle']}>
              <div className={styles['check-mark']}>
                <img src={purpleCheckMark} alt="Purple Check Mark" />
              </div>
            </div>

            <div className={styles['check-mark-text']}>{benefits3}</div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};
export default DeleteAccountThirdCopmonent;
