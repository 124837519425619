import React, { useCallback, useEffect, useState } from 'react';

import * as ReactQuill from 'react-quill';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import desk1 from 'Assets/pictures/desk1.png';
import desk2 from 'Assets/pictures/desk2.png';
import styles from 'Assets/styles/lawyer/LawyerDetailsCard.module.scss';

const LawyerDetailsCard = (props) => {
  let { lawyer } = props;

  const [lawyerImages, setLawyerImages] = useState([desk1, desk2]);

  let setImages = useCallback(async () => {
    let images = [];

    if (lawyer.lawyerImages.length > 0) {
      for (let i = 0; i < lawyer.lawyerImages.length; i++) {
        let image = lawyer.lawyerImages[i];

        images.push(image);
      }
    }

    setLawyerImages(images);
  }, [lawyer.lawyerImages]);

  useEffect(() => {
    setImages();
  }, [setImages]);

  return (
    <>
      {lawyer.subscription > 1 && (
        <div className={styles.lawyerDetailsCardOuterContainer}>
          <div className={styles.lawyerDetailsCardInnerContainer}>
            <div className={styles.lawyerDetailsCardBottomContent}>
              {lawyer.self_lawfirm_description && (
                <>
                  <h2 className={styles.lawyerDetailsCardPointsTitle}>
                    {LPTranslate('LPP_Details_Main_Focus')}
                  </h2>

                  <div className={styles.lawyerDetailsCardList}>
                    <ReactQuill
                      value={lawyer.self_lawfirm_description}
                      readOnly={true}
                      theme={'bubble'}
                    />
                  </div>
                </>
              )}

              <div className={styles.lawyerDetailsCardImageContent}>
                {lawyerImages.map((image, index) => (
                  <img
                    key={index}
                    className={styles.lawyerDetailsImage}
                    src={image}
                    alt="lawyerImg"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LawyerDetailsCard;
