import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

const subscriptionTextVariables = {
  Benefietsbasic: [
    LPTranslate('LSUS_Card_Silver_Info_1'),
    LPTranslate('LSUS_Card_Silver_Info_3'),
    LPTranslate('LSUS_Card_Silver_Info_4'),
    LPTranslate('LSUS_Card_Silver_Info_5'),
    LPTranslate('LSUS_Card_Silver_Info_6'),
  ],

  Benefietsgold: [
    LPTranslate('LSUS_Card_Gold_Info_1'),
    LPTranslate('LSUS_Card_Silver_Info_2'),
    LPTranslate('LSUS_Card_Gold_Info_2'),
    LPTranslate('LSUS_Card_Gold_Info_3'),
    LPTranslate('LSUS_Card_Gold_Info_4'),
    LPTranslate('LSUS_Card_Gold_Info_5'),
    LPTranslate('LSUS_Card_Gold_Info_6'),
    LPTranslate('LSUS_Card_Gold_Info_7'),
    LPTranslate('LSUS_Card_Gold_Info_8'),
    // LPTranslate('LSUS_Card_Gold_Info_9'), // nu exista
  ],
  Benefietsplat: [
    LPTranslate('LSUS_Card_Platin_Info_1'),
    LPTranslate('LSUS_Card_Platin_Info_2'),
    LPTranslate('LSUS_Card_Platin_Info_3'),
    LPTranslate('LSUS_Card_Platin_Info_4'),
  ],

  BenefietsbasicYear: ['year', ' Lorem ipsum dolor sit ame'],

  BenefietsgoldYear: [
    'year',
    '200+ consectetur adipiscing elit.',
    '1+ consectetur adipiscing elit.',
    'consectetur adipiscing elit.',
    'consectetur 100+',
    'Priority chat and email support',
  ],
  BenefietsplatYear: [
    'year',
    'Advanced consectetur adipiscing elit.',
    'Audit adipiscing elit.',
    'Unlimited consectetur ',
    'Unlimited adipiscing elit. users',
    'Personalised elit. users',
  ],

  buttonTextNext: ['Auswählen'],
  buttonTextCurrentPlan: [' Aktueller Plan'],
};

export default subscriptionTextVariables;
