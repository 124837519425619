import React from 'react';

import Step from 'Components/Stepper/step.js';

import styles from 'Assets/styles/step.module.scss';

const stepNavigation = (props) => {
  return (
    <>
      <div
        className={
          props.currentStep === 7 && props.role === 'lawyer'
            ? styles.horizontalStepWrapperSubscription
            : styles.horizontalStepWrapper
        }
      >
        {props.labelArray.map((item, index) => (
          <Step
            last={props.labelArray.length - 1}
            key={index}
            index={index}
            label={item.label}
            description={item.description}
            isChecked={item.isChecked}
            selected={props.currentStep === index + 1}
            updateStep={props.updateStep}
          />
        ))}
      </div>

      <div
        style={
          props.currentStep === 7 && props.role === 'lawyer'
            ? { display: 'none' }
            : {}
        }
        className={styles.verticalStepWrapper}
      >
        {props.labelArray.map((item, index) => (
          <Step
            last={props.labelArray.length - 1}
            key={index}
            index={index}
            label={item.label}
            description={item.description}
            isChecked={item.isChecked}
            selected={props.currentStep === index + 1}
            updateStep={props.updateStep}
          />
        ))}
      </div>
    </>
  );
};

export default stepNavigation;
