import React from 'react';

import Button from '@mui/material/Button';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import purpleCheckIcon from 'Assets/pictures/purpleCheckIcon.png';

import styles from 'Assets/styles/common/RegisterCheckboxComponent.module.scss';

const RegisterCheckboxComponent = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.rowContainer}>
        <div
          className={
            props.termsAccepted
              ? styles['checkbox-container-selected']
              : styles['checkbox-container']
          }
        >
          <Button
            data-cy="check-button"
            className={styles['check-button']}
            onClick={() => props.setTermsAccepted(!props.termsAccepted)}
          >
            <img
              src={purpleCheckIcon}
              className={
                props.termsAccepted
                  ? styles['check-style-selected-img']
                  : styles['check-style-img']
              }
              alt="check"
            />
          </Button>
        </div>
        <h4 className={styles['checkbox-text-label']}>
          {LPTranslate('Common_Accept_Terms_On_Register_1')}
          <a
            className={styles['checkbox-link']}
            href={`${process.env.REACT_APP_DNS_URI}/agb`}
          >
            {LPTranslate('Common_Accept_Terms_On_Register_2')}
          </a>
          {LPTranslate('Common_Accept_Terms_On_Register_3')}
          <a
            className={styles['checkbox-link']}
            href={`${process.env.REACT_APP_DNS_URI}/datenschutz-cookies`}
          >
            {LPTranslate('Common_Accept_Terms_On_Register_4')}
          </a>
          {LPTranslate('Common_Accept_Terms_On_Register_5')}
        </h4>
      </div>

      <div className={styles.rowContainer}>
        <div
          className={
            props.marketingAccepted
              ? styles['checkbox-container-selected']
              : styles['checkbox-container']
          }
        >
          <Button
            data-cy="check-button"
            className={styles['check-button']}
            onClick={() => props.setMarketingAccepted(!props.marketingAccepted)}
          >
            <img
              src={purpleCheckIcon}
              className={
                props.marketingAccepted
                  ? styles['check-style-selected-img']
                  : styles['check-style-img']
              }
              alt="check"
            />
          </Button>
        </div>
        <h4 className={styles['checkbox-text-label']}>
          {LPTranslate('Common_Accept_Terms_On_Register_6')}
        </h4>
      </div>
      {/* <div className={styles['under-checkbox-container']}>
        <h5 className={styles['under-checkbox-label']}>
          {LPTranslate('Common_Accept_Terms_On_Register_6')}
        </h5>
      </div> */}
    </div>
  );
};

export default RegisterCheckboxComponent;
