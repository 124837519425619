import React from 'react';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/help/CookiesPage.scss';

const TermsOfServiceClient = () => {
  return (
    <div className="lawp-cookies-page-container">
      <div className="lawp-cookies-page-header-section">
        <h4 className="lawp-cookies-page-subheader">
          {LPTranslate('DTAC_Pre_Title')}
        </h4>

        <h1 className="lawp-cookies-page-header">
          {LPTranslate('GTCC_Title')}
        </h1>

        <h3 className="lawp-cookies-page-description">
          {LPTranslate('GTCC_Post_Title_1')}
          <a
            className={'lawp-cookies-page-link'}
            href={`${process.env.REACT_APP_DNS_URI}`}
          >
            {LPTranslate('GTCC_Post_Title_2')}
          </a>
          {LPTranslate('GTCC_Post_Title_3')}
        </h3>
      </div>

      <h2 className="lawp-cookies-page-faq-section-title">
        {LPTranslate('GTCC_Description_Title_1')}
      </h2>

      <h3 className="lawp-cookies-page-text-block">
        {LPTranslate('GTCC_Description_1_1')}
        <a
          className={'lawp-cookies-page-link'}
          href={`${process.env.REACT_APP_DNS_URI}`}
        >
          {LPTranslate('GTCC_Description_1_2')}
        </a>
        {LPTranslate('GTCC_Description_1_3')}
        <a
          className={'lawp-cookies-page-link'}
          href={`${process.env.REACT_APP_DNS_URI}/agb`}
        >
          {LPTranslate('GTCC_Description_1_4')}
        </a>
        {LPTranslate('GTCC_Description_1_5')}
      </h3>

      <ol>
        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCC_Paragraph_1_Title')}
          </h2>

          <h3 className="lawp-cookies-page-text-block">
            {LPTranslate('GTCC_Paragraph_1_1_Description')}
            <a
              className={'lawp-cookies-page-link'}
              href={`${process.env.REACT_APP_DNS_URI}`}
            >
              {LPTranslate('GTCC_Paragraph_1_2_Description')}
            </a>
            {LPTranslate('GTCC_Paragraph_1_3_Description')}
          </h3>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCC_Paragraph_2_Title')}
          </h2>

          <h3 className="lawp-cookies-page-text-block">
            {LPTranslate('GTCC_Paragraph_2_Description')}
          </h3>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCC_Paragraph_3_Title')}
          </h2>

          <ol>
            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_3_Description_1')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_3_Description_2')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_3_Description_3')}
              </h3>
            </li>
          </ol>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCC_Paragraph_4_Title')}
          </h2>

          <ol>
            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_4_Description_1')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_4_Description_2_1')}
                <a
                  className={'lawp-cookies-page-link'}
                  href={`${process.env.REACT_APP_DNS_URI}`}
                >
                  {LPTranslate('GTCC_Paragraph_4_Description_2_2')}
                </a>
                {LPTranslate('GTCC_Paragraph_4_Description_2_3')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_4_Description_3')}
              </h3>
            </li>
          </ol>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCC_Paragraph_5_Title')}
          </h2>

          <ol>
            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_5_Description_1')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_5_Description_2')}
              </h3>
            </li>
          </ol>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCC_Paragraph_6_Title')}
          </h2>

          <ol>
            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_6_Description_1')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_6_Description_2_1')}
                <a
                  className={'lawp-cookies-page-link'}
                  href={`${process.env.REACT_APP_DNS_URI}/bewertungsrichtlinien`}
                >
                  {LPTranslate('GTCC_Paragraph_6_Description_2_2')}
                </a>
                {LPTranslate('GTCC_Paragraph_6_Description_2_3')}
              </h3>
            </li>
          </ol>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCC_Paragraph_7_Title')}
          </h2>

          <ol>
            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_7_Description_1')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_7_Description_2')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_7_Description_3')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_7_Description_4')}
              </h3>
            </li>
          </ol>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCC_Paragraph_8_Title')}
          </h2>

          <ol>
            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_8_Description_1')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_8_Description_2')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_8_Description_3')}
              </h3>
            </li>
          </ol>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCC_Paragraph_9_Title')}
          </h2>

          <ol>
            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_9_Description_1_1')}
                <a
                  className={'lawp-cookies-page-link'}
                  href={`${process.env.REACT_APP_DNS_URI}`}
                >
                  {LPTranslate('GTCC_Paragraph_9_Description_1_2')}
                </a>
                {LPTranslate('GTCC_Paragraph_9_Description_1_3')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_9_Description_2')}
              </h3>
            </li>
          </ol>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCC_Paragraph_10_Title')}
          </h2>

          <ol>
            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_10_Description_1')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_10_Description_2')}
              </h3>
            </li>
          </ol>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCC_Paragraph_11_Title')}
          </h2>

          <ol>
            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_11_Description_1')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_11_Description_2')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_11_Description_3')}
              </h3>
            </li>
          </ol>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCC_Paragraph_12_Title')}
          </h2>

          <ol>
            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_12_Description_1')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_12_Description_2')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_12_Description_3')}
              </h3>
            </li>
          </ol>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCC_Paragraph_13_Title')}
          </h2>

          <ol>
            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_13_Description_1')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_13_Description_2')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_13_Description_3')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCC_Paragraph_13_Description_4')}
              </h3>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

export default TermsOfServiceClient;
