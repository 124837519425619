import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import IconButton from '@mui/material/IconButton';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';

import CheckBox from 'Components/Lawyer/FilterCheckBox';
import HourInterval from 'Components/Lawyer/HourInterval';

import {
  ADD_LAWYER_AVAILABILITY_TIME_INTERVAL,
  GET_LAWYER_AVAILABILITY_TIME_INTERVAL,
} from 'Services/Queries/lawyer';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/lawyer/TimeIntervalView.scss';

let dataDays = [
  {
    day: 'Monday',
    enabled: false,
    intervals: [
      {
        start: '08:00',
        end: '09:00',
      },
    ],
  },
  {
    day: 'Tuesday',
    enabled: false,
    intervals: [
      {
        start: '08:00',
        end: '09:00',
      },
    ],
  },
  {
    day: 'Wednesday',
    enabled: false,
    intervals: [
      {
        start: '08:00',
        end: '09:00',
      },
    ],
  },
  {
    day: 'Thursday',
    enabled: false,
    intervals: [
      {
        start: '08:00',
        end: '09:00',
      },
    ],
  },
  {
    day: 'Friday',
    enabled: false,
    intervals: [
      {
        start: '08:00',
        end: '09:00',
      },
    ],
  },
  {
    day: 'Saturday',
    enabled: false,
    intervals: [
      {
        start: '08:00',
        end: '09:00',
      },
    ],
  },
  {
    day: 'Sunday',
    enabled: false,
    intervals: [
      {
        start: '08:00',
        end: '09:00',
      },
    ],
  },
];

const daysTranslated = {
  Monday: LPTranslate('Anwado_Monday'),
  Tuesday: LPTranslate('Anwado_Tuesday'),
  Wednesday: LPTranslate('Anwado_Wednesday'),
  Thursday: LPTranslate('Anwado_Thursday'),
  Friday: LPTranslate('Anwado_Friday'),
  Saturday: LPTranslate('Anwado_Saturday'),
  Sunday: LPTranslate('Anwado_Sunday'),
};

const TimeIntervalView = (props) => {
  const [revertDaysValue, setRevertDaysValue] = useState(dataDays);
  const [days, setDays] = useState(dataDays);

  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const LawyerResponse = useQuery(GET_LAWYER_AVAILABILITY_TIME_INTERVAL, {
    variables: { id: parseInt(clientInfoRedux.role_id) },
  });

  const [addLawyerAvailabilityTimeInterval] = useMutation(
    ADD_LAWYER_AVAILABILITY_TIME_INTERVAL
  );

  const addIntervals = async () => {
    for (let i = 0; i < days.length; i++) {
      if (days[i].enabled) {
        for (let j = 0; j < days[i].intervals.length; j++) {
          if (
            days[i].intervals[j].start[0] === '' ||
            days[i].intervals[j].start[1] === '' ||
            days[i].intervals[j].end[0] === '' ||
            days[i].intervals[j].end[1] === ''
          ) {
            props.setSnackbarMessage(LPTranslate('LSC_Timeslots_Empty'));
            props.onChangeSnackbar();
            return 0;
          }
        }
      }
    }

    let response = await addLawyerAvailabilityTimeInterval({
      variables: {
        input: {
          lawyer_id: parseInt(clientInfoRedux.role_id),
          days: days,
          lawyerAvailability: props.lawyerAvailability,
        },
      },
    });

    if (response.data.createLawyerAvailabilityTimeInterval.status === 'error') {
      props.setSnackbarMessage(
        response.data.createLawyerAvailabilityTimeInterval.message
      );
      props.onChangeSnackbar();
      return 0;
    }

    props.setSnackbarMessage(LPTranslate('LCS_Timeslots_Updated'));
    props.onChangeSnackbar();
  };

  useEffect(() => {
    if (props.ifSaveButtonPressed) {
      addIntervals();
      props.onChangeSaveButtonPressed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ifSaveButtonPressed]);

  useEffect(() => {
    if (props.ifRevertButtonPressed) {
      let newArray = JSON.parse(JSON.stringify(revertDaysValue));

      setDays([...newArray]);
      props.onChangeRevertButtonPressed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ifRevertButtonPressed]);

  useEffect(() => {
    if (LawyerResponse.data) {
      let QueryData = [
        ...LawyerResponse.data.getLawyerAvailabilityTimeInterval.days,
      ];

      let DBDays = QueryData.map((day) => {
        return {
          day: day.day,
          enabled: day.enabled,
          intervals: day.intervals.map((interval) => {
            return {
              start: interval.start.substring(0, 5),
              end: interval.end.substring(0, 5),
            };
          }),
        };
      });

      let newDays = [];
      for (let i = 0; i < dataDays.length; i++) {
        let isFound = false;
        for (let j = 0; j < DBDays.length; j++) {
          if (dataDays[i].day === DBDays[j].day) {
            isFound = true;
            newDays.push(DBDays[j]);
          }
        }

        if (!isFound) {
          newDays.push(dataDays[i]);
        }
      }

      let revertArray = [];
      revertArray = JSON.parse(JSON.stringify(newDays));

      setRevertDaysValue(revertArray);
      setDays([...newDays]);
    }
  }, [LawyerResponse.data]);

  const checkDay = (value) => {
    let newDays = [...days];

    //---
    let preChangeEnabledDays = 0;
    for (let i = 0; i < newDays.length; i++)
      if (newDays[i].enabled) preChangeEnabledDays++;
    //---

    newDays.forEach((day) => {
      if (day.day === value) {
        day.enabled = !day.enabled;
      }
    });

    //---
    let postChangeEnabledDays = 0;
    for (let i = 0; i < newDays.length; i++)
      if (newDays[i].enabled) postChangeEnabledDays++;
    //---

    if (preChangeEnabledDays === 0 && postChangeEnabledDays === 1)
      props.updateLawyerAvailabilityBasedOnDaysSelected(true);
    if (preChangeEnabledDays === 1 && postChangeEnabledDays === 0)
      props.updateLawyerAvailabilityBasedOnDaysSelected(false);

    setDays(newDays);
  };

  const updateHours = (value, index, day) => {
    let newDays = [...days];

    for (let i = 0; i < newDays.length; i++)
      if (newDays[i].day === day && value !== undefined)
        newDays[i].intervals[index] = value;
  };

  const addNewInterval = (day) => {
    let newDays = [...days];

    for (let i = 0; i < newDays.length; i++)
      if (newDays[i].day === day)
        newDays[i].intervals.push({ start: ['', ''], end: ['', ''] });

    setDays(newDays);
  };

  const removeInterval = (day, index) => {
    let newDays = [...days];

    for (let i = 0; i < newDays.length; i++)
      if (newDays[i].day === day) newDays[i].intervals.splice(index, 1);

    setDays(newDays);
  };

  return (
    <div className="lawp-time-interval-view-container">
      {days.map((day, dayIndex) => {
        return (
          <React.Fragment key={dayIndex}>
            <div className="lawp-time-interval-view-day-section">
              <div className="lawp-time-interval-view-checkbox-container">
                <CheckBox
                  data_cy="interval-checkbox"
                  checked={day.enabled}
                  onChange={() => checkDay(day.day)}
                />
                <div>
                  <p> {daysTranslated[day.day]} </p>
                </div>
              </div>

              <div className="lawp-time-interval-view-intervals-list">
                {day.enabled ? (
                  day.intervals.map((interval, intervalIndex) => {
                    return (
                      <div
                        key={`${intervalIndex}interval`}
                        className="lawp-time-interval-view-textfields-and-icon-container"
                      >
                        <div className="lawp-time-interval-view-time-textfields-container">
                          <HourInterval
                            fixedMinutes={true}
                            interval={interval}
                            onChange={(value) =>
                              updateHours(value, intervalIndex, day.day)
                            }
                          />
                        </div>

                        <IconButton
                          data-cy="add-remove-time-interval-button"
                          className="lawp-time-interval-view-add-remove-icon"
                          onClick={() => {
                            if (intervalIndex === 0) {
                              addNewInterval(day.day);
                            } else {
                              removeInterval(day.day, intervalIndex);
                            }
                          }}
                        >
                          {intervalIndex === 0 ? (
                            <AddRoundedIcon />
                          ) : (
                            <RemoveRoundedIcon />
                          )}
                        </IconButton>
                      </div>
                    );
                  })
                ) : (
                  <div className="lawp-time-interval-view-disabled-day-container">
                    <p> {LPTranslate('LSC_Not_Enabled')}</p>
                  </div>
                )}
              </div>
            </div>

            {dayIndex !== days.length - 1 && (
              <div
                key={`${dayIndex}divider`}
                className="lawp-time-interval-view-divider"
              ></div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default TimeIntervalView;
