import React from 'react';
import 'Assets/styles/common/LawyerPageFaqTitle.css';
const FaqTitleComponent = (props) => {
  let { title, subtitle } = props;
  return (
    <div className="faqTitleCard">
      <h2 className="faqTitle">
        <>{`${title}`}</>
      </h2>

      <h3 className="faqSubTitle">
        <>{`${subtitle}`}</>
      </h3>
    </div>
  );
};

export default FaqTitleComponent;
