import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useQuery, useLazyQuery } from '@apollo/client';

import SettingsMenu from 'Components/CommonComponents/SettingsMenu';
import EditLawyerArticleSection from 'Components/Lawyer/Edit/EditLawyerArticleSection';
import { GET_AREAS_OF_LAW } from 'Services/Queries/lawyer';
import { GET_USER_PROFILE_IMAGE } from 'Services/Queries/user';
// import useAlert from 'Services/Utils/hooks/useAlert';
import { parseJwt } from 'Services/Utils/token';

import styles from 'Assets/styles/lawyer/LawyerArticlesPage.module.scss';

const LawyerArticlesPage = () => {
  // const { setAlert } = useAlert();
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);
  const logged_user_id = clientInfoRedux.user_id;

  const areasOfLawQuery = useQuery(GET_AREAS_OF_LAW);
  const [areasOfLawDB, setAreaOfLawDB] = useState([]);

  const [userImage, { data: userImageData }] = useLazyQuery(
    GET_USER_PROFILE_IMAGE,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [profileImage, setProfileImage] = useState('');

  const fetchUserProfileImage = React.useCallback(async () => {
    if (logged_user_id) {
      await userImage({
        variables: { id: logged_user_id },
        fetchPolicy: 'network-only',
      });
    }
  }, [logged_user_id, userImage]);

  useEffect(() => {
    fetchUserProfileImage();
  }, [fetchUserProfileImage]);

  useEffect(() => {
    if (
      !areasOfLawQuery.loading &&
      areasOfLawQuery.data !== undefined &&
      areasOfLawDB.length === 0
    ) {
      let dataAreas = [];

      for (let i = 0; i < areasOfLawQuery.data.getAreasOfLaw.length; i++) {
        dataAreas.push(areasOfLawQuery.data.getAreasOfLaw[i].name);
      }

      setAreaOfLawDB(dataAreas);
    }
  }, [areasOfLawQuery, areasOfLawDB]);

  useEffect(() => {
    if (
      userImageData &&
      userImageData.userInformationNavbar &&
      userImageData.userInformationNavbar.profileImage
    ) {
      setProfileImage(userImageData.userInformationNavbar.profileImage);
    }
  }, [userImageData]);

  return (
    <div className={styles.container}>
      <div className={styles['edit-lawyer-top-menu']}>
        <SettingsMenu
          setUpdateComponent={'/updateLawyerPage'}
          role={clientInfoRedux.role}
          subscription={parseJwt(clientInfoRedux.token).subscription}
        />
      </div>
      <div>
        <EditLawyerArticleSection
          areasOfLawDB={areasOfLawDB}
          userId={logged_user_id}
          profileImage={profileImage ? profileImage : ''}
        />
      </div>
    </div>
  );
};

export default LawyerArticlesPage;
