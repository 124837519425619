import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/registerLawyer/EmailChecker.module.scss';
import mailIcon from 'Assets/pictures/mailIcon.svg';

const EmailChecker = (props) => {
  const { handleNext, hashedEmail, returnToRegister } = props;
  const handleClick = () => {
    handleNext(2);
  };

  return (
    <>
      <div className={styles.emailCheckerSection}>
        <div className={styles.emailCheckerContainer}>
          <div className={styles.frame2}>
            <div className={styles.content}>
              <div className={styles.header}>
                <div className={styles.featuredIcon}>
                  <div className={styles.mail}>
                    <img src={mailIcon} alt="key" />
                  </div>
                </div>

                <div className={styles.textAndSupportingText}>
                  <p className={styles.EmailCheckerTitle}>
                    {LPTranslate('Common_Check_Email_Address')}
                  </p>

                  <p className={styles.supportingText}>
                    {LPTranslate('Common_Verification_Email_Sent_1')}{' '}
                    {hashedEmail}{' '}
                    {LPTranslate('Common_Verification_Email_Sent_2')}
                  </p>
                </div>
              </div>

              <div className={styles.sendCodeButtonContainer}>
                <div className={styles.sendCodeButton}>
                  <button
                    data-cy="submit-button"
                    className={styles.sendCodeButtonBase}
                    onClick={handleClick}
                  >
                    <p className={styles.sendCodeButtonText}>
                      {LPTranslate('RL_Page_2_Enter_Code')}
                    </p>
                  </button>
                </div>
              </div>

              <div className={styles.loginButtonContainer}>
                <button
                  data-cy="login-button"
                  className={styles.loginButtonBase}
                  onClick={returnToRegister}
                >
                  <ArrowBackIcon sx={{ color: '#667085' }} />
                  <p className={styles.loginButtonText}>
                    {LPTranslate('Common_Back_To_Login')}
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailChecker;
