import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';

import { GET_LAWYER_SCHEDULE } from 'Services/Queries/client';

import NewMeetingCalendar from 'Components/Calendar/NewMeetingCalendar';

import arrowLeft from 'Assets/pictures/purple-left-arrow.svg';

const SetMeetingCalendarModal = (props) => {
  let { lawyer_id } = props;

  const getLawyerSchedule = useQuery(GET_LAWYER_SCHEDULE, {
    variables: { lawyer_id: lawyer_id },
    fetchPolicy: 'no-cache',
  });

  const [backendData, setBackendData] = useState({});
  const [nextStepDate, setNextStepDate] = useState('');
  const [nextStepHour, setNextStepHour] = useState('');

  useEffect(() => {
    if (getLawyerSchedule.data) {
      setBackendData(getLawyerSchedule.data.getWeekDaysSchedule);
    }
  }, [getLawyerSchedule.data]);

  useEffect(() => {
    if (nextStepDate.length > 0 && nextStepHour.length > 0) {
      props.handleShownModal(nextStepDate, nextStepHour, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextStepDate, nextStepHour]);

  return (
    <>
      <button
        style={{ alignSelf: 'flex-start' }}
        className={
          'lawp-add-lawyer-review-modal-container-right-row-arrow-button-back'
        }
        onClick={props.handleClose}
      >
        <img src={arrowLeft} alt="arrow" />
      </button>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
        }}
      >
        {Object.keys(backendData).length > 0 ? (
          <NewMeetingCalendar
            backendData={backendData}
            setNextStepDate={setNextStepDate}
            setNextStepHour={setNextStepHour}
          />
        ) : (
          <div>loading</div>
        )}
      </div>
    </>
  );
};
export default SetMeetingCalendarModal;
