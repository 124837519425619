import React from 'react';

import Button from '@mui/material/Button';

import { Cookies } from 'react-cookie-consent';
import NorthEastIcon from '@mui/icons-material/NorthEast';

import SendMessageToLawyerModal from 'Components/Modals/SendMessageToLawyerModal';
import SendMessageToLawyerNotLoggedModal from 'Components/Modals/SendMessageToLawyerNotLoggedModal';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/lawyer/ContactLawyerComponent.scss';

const ContactLawyerCommponent = (props) => {
  let {
    telephone,
    website,
    postcode,
    street,
    lawfirm_name,
    location,
    house_number,
    country,
    lawyer,
    client,
  } = props;

  const [mapAllowed, setMapAllowed] = React.useState(
    Cookies.get('allowGoogleMap')
  );

  const allMapDataIsAvailable = () => {
    if (!location || !country) return false;

    return true;
  };

  const generateAddressString = (
    street,
    house_number,
    postcode,
    country,
    location
  ) => {
    let components = [];

    if (street) {
      let streetAndHouse = street;

      if (house_number) streetAndHouse += ` ${house_number}`;

      components.push(streetAndHouse);
    }

    if (postcode) {
      components.push(postcode);
    }

    if (location) {
      components.push(location);
    }

    if (country) {
      components.push(country);
    }

    if (components.length === 0) return '';

    return components.join(', ');
  };

  return (
    <div className="lawp-contact-lawyer-component-map-component-card">
      <div className="parentComponent">
        <div className="mapTitleElement">
          <h2 className="FindUsHere">{LPTranslate('LPP_Map_Find_Us_Here')}</h2>
        </div>

        <div className="elements">
          {allMapDataIsAvailable() && (
            <div className="google-map-container">
              {mapAllowed === 'true' ? (
                <iframe
                  title="Google Map"
                  className="gMap"
                  referrerPolicy="no-referrer-when-downgrade"
                  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBsBxc62YFCnQGlMUrsBzxaEvQmBUiMzc8&q=${encodeURIComponent(
                    generateAddressString(
                      street,
                      house_number,
                      postcode,
                      country,
                      location
                    )
                  )}`}
                  allowFullScreen
                ></iframe>
              ) : (
                <div className="map-placeholder-container">
                  <p className="map-placeholder-description-text">
                    {LPTranslate('Common_Google_Map_Placeholder')}
                  </p>
                  <p
                    className="map-placeholder-learn-more-text"
                    onClick={() => {
                      window.open(
                        'https://policies.google.com/privacy',
                        '_blank'
                      );
                    }}
                  >
                    {LPTranslate('Common_Learn_More')}
                  </p>
                  <Button
                    className="map-allow-button"
                    onClick={() => {
                      Cookies.set('allowGoogleMap', 'true', { expires: 365 });
                      setMapAllowed('true');
                    }}
                  >
                    {LPTranslate('Common_Show_Map')}
                  </Button>{' '}
                </div>
              )}
            </div>
          )}

          <div
            className="contactDiv"
            style={{
              width: !allMapDataIsAvailable() ? '100%' : '',
            }}
          >
            {lawfirm_name && (
              <div className="officeNameContainer">
                <div className="officeName">
                  <h2 className="OfficeText">{lawfirm_name}</h2>
                </div>
              </div>
            )}

            <div
              className="adressData"
              style={{
                //if allDataIsAvailable is false, we want flex direction to be row, else remain unchanged from the css file
                flexDirection: !allMapDataIsAvailable() ? 'row' : '',
              }}
            >
              {postcode && location && country && (
                <div className="adressElement">
                  <h2 className="TitleText">{LPTranslate('Input_Address')}</h2>
                  <div>
                    {street && (
                      <h3 className="NormalText">
                        {street} {house_number}{' '}
                      </h3>
                    )}
                    <h3 className="NormalText">
                      {postcode} {location}
                    </h3>
                    <h3 className="NormalText">{country}</h3>
                  </div>
                </div>
              )}

              {lawyer.subscription > 1 && website && (
                <div className="websiteElement">
                  <div className="TitleText">
                    {LPTranslate('Common_Website')}
                    <a
                      className={'websiteLink'}
                      data-cy={'website-button'}
                      href={`https://${lawyer.website}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {lawyer.website}
                      <NorthEastIcon transform="scale(0.7)" />
                    </a>
                  </div>
                </div>
              )}

              {telephone && (
                <div className="telephoneElement">
                  <h2 className="TitleText">
                    {LPTranslate('Input_Telephone')}
                  </h2>
                  <h3 className="PurpleText">{telephone}</h3>
                </div>
              )}

              <div className="emailElement">
                <h2 className="TitleText">Email</h2>
                {client.role.length !== 0 ? (
                  <SendMessageToLawyerModal lawyer={lawyer} client={client} />
                ) : (
                  <SendMessageToLawyerNotLoggedModal
                    lawyer={lawyer}
                    client={client}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactLawyerCommponent;
