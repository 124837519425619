import React from 'react';

import Divider from '@material-ui/core/Divider';

import NavBarLanguageSelect from 'Layouts/NavBarLanguageSelect';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import logo from 'Assets/pictures/anwado-logo2.svg';

import 'Assets/styles/footer.scss';

const aboutAnwado = [
  {
    title: LPTranslate('Button_What_We_Do'),
    link: `${process.env.REACT_APP_DNS_URI}/anwado`,
    data_cy: 'footer-about-us',
  },
  {
    title: LPTranslate('Button_Legal_Tips'),
    link: `${process.env.REACT_APP_DNS_URI}/rechtsinfos`,
    data_cy: 'footer-legal-tips',
  },
  {
    title: LPTranslate('Button_For_Lawyer'),
    link: `${process.env.REACT_APP_DNS_URI}/anwaelte`,
    data_cy: 'footer-home-page-lawyer',
  },
  {
    title: LPTranslate('LPB_Top_Lawyers_Action_Button_1'),
    link: `${process.env.REACT_APP_DNS_URI}/faq`,
    data_cy: 'footer-faq',
  },
  {
    title: LPTranslate('Common_Contact'),
    link: `${process.env.REACT_APP_DNS_URI}/kontakt`,
    data_cy: 'footer-contact-us',
  },
];

const anwadoPromise = [
  {
    title: LPTranslate('Common_Quality_Promise'),
    link: `${process.env.REACT_APP_DNS_URI}/qualitaetsversprechen`,
    data_cy: 'footer-quality-promise',
  },
  {
    title: LPTranslate('Common_Evaluation'),
    link: `${process.env.REACT_APP_DNS_URI}/bewertungsrichtlinien`,
    data_cy: 'footer-scoring-policies',
  },
  {
    title: LPTranslate('Common_Price_List'),
    link: `${process.env.REACT_APP_DNS_URI}/preise`,
    data_cy: 'footer-price',
  },
  {
    title: 'AGB',
    link: `${process.env.REACT_APP_DNS_URI}/agb`,
    data_cy: 'footer-agb',
  },
  {
    title: LPTranslate('Button_To_Article_Search'),
    link: `${process.env.REACT_APP_DNS_URI}/anwado-suche`,
    data_cy: 'footer-search',
  },
];

const bottom = [
  {
    title: LPTranslate('LN_Title'),
    link: `${process.env.REACT_APP_DNS_URI}/impressum`,
    data_cy: 'footer-imprint',
  },
  {
    title: LPTranslate('Footer_Privacy'),
    link: `${process.env.REACT_APP_DNS_URI}/datenschutz-cookies`,
    data_cy: 'footer-privacy',
  },
];

const Footer = () => {
  return (
    <div className="lawp-footer-container">
      <div className="top">
        <div className="logo-container">
          <img className="logo" src={logo} alt="logo" />

          <h3 className="logo-description">
            {LPTranslate('Footer_Description')}
          </h3>
        </div>

        <div className="link-container">
          <div className="link-container-list">
            <h3 className="title">{LPTranslate('Footer_About')}</h3>

            {aboutAnwado.map((item, index) => (
              <a
                className="list-element"
                href={item.link}
                data-cy={item.data_cy}
                key={index}
              >
                {item.title}
              </a>
            ))}
          </div>

          <div className="link-container-list">
            <h3 className="title">{LPTranslate('Footer_Promise')}</h3>

            {anwadoPromise.map((item, index) => (
              <a
                className="list-element"
                href={item.link}
                data-cy={item.data_cy}
                key={index}
              >
                {item.title}
              </a>
            ))}
          </div>
        </div>
      </div>

      <Divider className="divider" />

      <div className="bottom">
        <div className="left">
          {/* <h3>© ANWADO. {LPTranslate('Footer_All_Rights_Reserved')}</h3> */}

          <h4 id="app-version-info">Version 1.0.124</h4>
        </div>

        <div className="right">
          {bottom.map((item, index) => (
            <a
              className="bottom-link"
              href={item.link}
              data-cy={item.data_cy}
              key={index}
            >
              {item.title}
            </a>
          ))}

          <NavBarLanguageSelect />
        </div>
      </div>
    </div>
  );
};
export default Footer;
