import React, { useState, useEffect } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import AutocompleteAdress from 'Components/RegisterClient/AutocompleteAdress';
// import PhoneInputField from 'Components/Inputs/PhoneInputField';
import SimpleTextField from 'Components/Inputs/SimpleTextField';
// import AutoCompleteTextFieldSingleSelected from 'Components/Lawyer/Edit/AutoCompleteFixedValuesSingleSelect';

import useAlert from 'Services/Utils/hooks/useAlert';
import { UPDATE_LAWYER_ADDRESS_ON_REGISTER } from 'Services/Queries/lawyer';
import { GET_CLAIMED_LAWYER_ADDRESS_ON_REGISTER } from 'Services/Queries/lawyer';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/registerClient/setClientDetailsOnRegister.module.scss';
import anwadoLogo from 'Assets/pictures/logomark.svg';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SetLawyerAdressDetailsOnRegister = (props) => {
  const { setAlert } = useAlert();

  const [
    SetLawyerAdressDetailsOnRegister,
    { data: SetLawyerAdressDetailsOnRegisterData },
  ] = useMutation(UPDATE_LAWYER_ADDRESS_ON_REGISTER);

  const [
    GetClaimedLawyerAddressOnRegister,
    { data: GetClaimedLawyerAddressOnRegisterData },
  ] = useLazyQuery(GET_CLAIMED_LAWYER_ADDRESS_ON_REGISTER);

  const [formErrors, setFormErrors] = useState({
    StreetValue: '',
    HouseNumberValue: '',
    ZipCodeValue: '',
    CityValue: '',
    CountryValue: '',
    phoneNumber: '',
  });

  // const countriesList = [
  //   { title: 'Australia', code: 'AU', selected: 0 },
  //   { title: 'Austria', code: 'AT', selected: 0 },
  //   { title: 'Belgium', code: 'BE', selected: 0 },
  //   { title: 'Brazil', code: 'BR', selected: 0 },
  //   { title: 'Bulgaria', code: 'BG', selected: 0 },
  //   { title: 'Canada', code: 'CA', selected: 0 },
  //   { title: 'Croatia', code: 'HR', selected: 0 },
  //   { title: 'Cyprus', code: 'CY', selected: 0 },
  //   { title: 'Czech Republic', code: 'CZ', selected: 0 },
  //   { title: 'Denmark', code: 'DK', selected: 0 },
  //   { title: 'Estonia', code: 'EE', selected: 0 },
  //   { title: 'Finland', code: 'FI', selected: 0 },
  //   { title: 'France', code: 'FR', selected: 0 },
  //   { title: 'Germany', code: 'DE', selected: 0 },
  //   { title: 'Gibraltar', code: 'GI', selected: 0 },
  //   { title: 'Greece', code: 'GR', selected: 0 },
  //   { title: 'Hong Kong', code: 'HK', selected: 0 },
  //   { title: 'Hungary', code: 'HU', selected: 0 },
  //   { title: 'India', code: 'IN', selected: 0 },
  //   { title: 'Indonesia', code: 'ID', selected: 0 },
  //   { title: 'Ireland', code: 'IE', selected: 0 },
  //   { title: 'Italy', code: 'IT', selected: 0 },
  //   { title: 'Japan', code: 'JP', selected: 0 },
  //   { title: 'Latvia', code: 'LV', selected: 0 },
  //   { title: 'Liechtenstein', code: 'LI', selected: 0 },
  //   { title: 'Lithuania', code: 'LT', selected: 0 },
  //   { title: 'Luxembourg', code: 'LU', selected: 0 },
  //   { title: 'Malaysia', code: 'MY', selected: 0 },
  //   { title: 'Malta', code: 'MT', selected: 0 },
  //   { title: 'Mexico ', code: 'MX', selected: 0 },
  //   { title: 'Netherlands', code: 'NL', selected: 0 },
  //   { title: 'New Zealand', code: 'NZ', selected: 0 },
  //   { title: 'Norway', code: 'NO', selected: 0 },
  //   { title: 'Poland', code: 'PL', selected: 0 },
  //   { title: 'Portugal', code: 'PT', selected: 0 },
  //   { title: 'Romania', code: 'RO', selected: 0 },
  //   { title: 'Singapore', code: 'SG', selected: 0 },
  //   { title: 'Slovakia', code: 'SK', selected: 0 },
  //   { title: 'Slovenia', code: 'SI', selected: 0 },
  //   { title: 'Spain', code: 'ES', selected: 0 },
  //   { title: 'Sweden', code: 'SE', selected: 0 },
  //   { title: 'Switzerland', code: 'CH', selected: 0 },
  //   { title: 'Thailand', code: 'TH', selected: 0 },
  //   { title: 'United Arab Emirates', code: 'AE', selected: 0 },
  //   { title: 'United Kingdom', code: 'GB', selected: 0 },
  //   { title: 'United States', code: 'US', selected: 0 },
  // ];

  const [StreetValue, setStreetValue] = useState('');
  const [HouseNumberValue, setHouseNumberValue] = useState('');
  const [ZipCodeValue, setZipCodeValue] = useState('');
  const [CityValue, setCityValue] = useState('');
  const [CountryValue, setCountryValue] = useState('DE');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      if (
        !StreetValue ||
        !HouseNumberValue ||
        !ZipCodeValue ||
        !CityValue ||
        !CountryValue ||
        !phoneNumber
      ) {
        let errors = {};

        if (!StreetValue)
          errors.StreetValue = LPTranslate('Error_Required_Field');
        if (!HouseNumberValue)
          errors.HouseNumberValue = LPTranslate('Error_Required_Field');
        if (!ZipCodeValue)
          errors.ZipCodeValue = LPTranslate('Error_Required_Field');
        if (!CityValue) errors.CityValue = LPTranslate('Error_Required_Field');
        if (!CountryValue)
          errors.CountryValue = LPTranslate('Error_Required_Field');
        if (!phoneNumber)
          errors.phoneNumber = LPTranslate('Error_Required_Field');

        setFormErrors(errors);

        setAlert(LPTranslate('Error_Fill_All_Fields'));
        return;
      }

      await SetLawyerAdressDetailsOnRegister({
        variables: {
          input: {
            lawyer_email: props.lawyerRegisterRedux.roleEmail,
            street: StreetValue,
            house_number: HouseNumberValue,
            postcode: ZipCodeValue,
            location: CityValue,
            country: CountryValue,
            phone_number: phoneNumber,
          },
        },
      });
    } catch (error) {
      setSnackbarMessage(LPTranslate('Error_Server_Down'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const getLawyerAddress = async (lawyer_id) => {
    try {
      await GetClaimedLawyerAddressOnRegister({
        variables: {
          input: {
            lawyer_id: lawyer_id,
          },
        },
      });
    } catch (error) {
      setSnackbarMessage(LPTranslate('Error_Server_Down'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    const claimedLawyerId = localStorage.getItem('ClaimedLawyerId');

    if (claimedLawyerId) getLawyerAddress(claimedLawyerId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      GetClaimedLawyerAddressOnRegisterData &&
      GetClaimedLawyerAddressOnRegisterData.getClaimedLawyerAddressOnRegister
    ) {
      let addressInfo =
        GetClaimedLawyerAddressOnRegisterData.getClaimedLawyerAddressOnRegister;

      setStreetValue(addressInfo.street);
      setHouseNumberValue(addressInfo.house_number);
      setZipCodeValue(addressInfo.postcode);
      setCityValue(addressInfo.location);
      setCountryValue(addressInfo.country);
      setPhoneNumber(addressInfo.telephone);
    }
  }, [GetClaimedLawyerAddressOnRegisterData]);

  useEffect(() => {
    try {
      if (SetLawyerAdressDetailsOnRegisterData) {
        if (
          (SetLawyerAdressDetailsOnRegisterData.updateLawyerAddressOnRegister.message =
            'Next step')
        ) {
          props.handleNext();
        }
      }
    } catch (e) {
      setSnackbarMessage(LPTranslate('Error_Server_Down'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SetLawyerAdressDetailsOnRegisterData]);

  const onChangeStreetValue = (value) => {
    setStreetValue(value);
  };

  const onChangeZipCode = (value) => {
    setZipCodeValue(value);
  };

  const onChangeCity = (value) => {
    setCityValue(value);
  };

  const onChangeTelephone = (value) => {
    setPhoneNumber(value);
  };

  return (
    <>
      <div className={styles.emailCheckerSection}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={
              snackbarSeverity === 'error' && {
                backgroundColor: '#7f56d9 !important',
              }
            }
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <div className={styles.emailCheckerContainer}>
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.textAndSupportingText}>
                <img
                  className={styles.logomark}
                  src={anwadoLogo}
                  alt="logomark"
                />
                <p className={styles.text}>{LPTranslate('RC_Page_4_Title')}</p>

                <p className={styles.supportingText}>
                  {LPTranslate('RC_Page_4_Description')}
                </p>
              </div>
            </div>

            <form
              className={styles.formContent}
              id="setPassword-form"
              onSubmit={handleSubmit}
            >
              <div
                style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}
                className={styles['address-input']}
              >
                <div style={{ width: '70%' }} className={styles.inputWithLabel}>
                  <AutocompleteAdress
                    addressValue={StreetValue}
                    textFieldWidthPercent={100}
                    onChangeAddressValue={onChangeStreetValue}
                    onChangeZipCode={onChangeZipCode}
                    onChangeCity={onChangeCity}
                    isSettingAppointment={false}
                    title={`${LPTranslate('Input_Street')}*`}
                    errorMessage={formErrors.StreetValue}
                  />
                </div>

                <div
                  style={{ width: '24%', marginRight: '20px' }}
                  className={styles.inputWithLabel}
                >
                  <label
                    style={{ width: '100%' }}
                    className={styles.passwordLabel}
                  >
                    Nr.*
                  </label>
                  <SimpleTextField
                    style={{ width: '100%' }}
                    data_cy="building-nr-input"
                    className={styles.setPasswordInput}
                    name="HouseNumberValue"
                    type="text"
                    placeholder="Nr."
                    maxLength={10}
                    hideHowManyCharactersLeft={true}
                    value={HouseNumberValue}
                    onChange={(value) => setHouseNumberValue(value)}
                    errorMessage={formErrors.HouseNumberValue}
                  />
                </div>
              </div>

              <div className={styles['zip-code-input']}>
                <div className={styles.inputWithLabel}>
                  <label className={styles.passwordLabel}>
                    {LPTranslate('Input_Postcode')}*
                  </label>

                  <SimpleTextField
                    className={styles.setPasswordInput}
                    data_cy="zip-code-input"
                    name="ZipCodeValue"
                    type="text"
                    placeholder={LPTranslate('Input_Postcode_Placeholder')}
                    value={ZipCodeValue}
                    onChange={(value) => setZipCodeValue(value)}
                    errorMessage={formErrors.ZipCodeValue}
                  />
                </div>
              </div>

              <div className={styles['city-input']}>
                <div className={styles.inputWithLabel}>
                  <label className={styles.passwordLabel}>
                    {LPTranslate('Input_City')}*
                  </label>

                  <SimpleTextField
                    data_cy="city-input"
                    className={styles.setPasswordInput}
                    name="CityValue"
                    type="text"
                    placeholder={LPTranslate('Input_City_Placeholder')}
                    value={CityValue}
                    onChange={(value) => setCityValue(value)}
                    errorMessage={formErrors.CityValue}
                  />
                </div>
              </div>

              {/* Change to Country: */}
              {/* <div className={styles['city-input']}>
                <div className={styles.inputWithLabel}>
                  <label className={styles.passwordLabel}>
                    {LPTranslate('Input_Country')}*
                  </label>

                  <SimpleTextField
                    data_cy="country-input"
                    className={styles.setPasswordInput}
                    name="CountryValue"
                    type="text"
                    placeholder={LPTranslate('Input_Country_Placeholder')}
                    value={CountryValue}
                    onChange={(value) => setCountryValue(value)}
                    errorMessage={formErrors.CountryValue}
                  />
                </div>
              </div> */}

              <div className={styles['phone-number-input']}>
                <div className={styles.inputWithLabel}>
                  {/* <PhoneInputField
                    specialLabel=""
                    country={'de'}
                    regions="europe"
                    autoFocus={true}
                    onChangeHandler={(e) => onChangeTelephone(e)}
                    value={phoneNumber}
                    phoneNumber={phoneNumber}
                    title={`${LPTranslate('Input_Telephone')}*`}
                    name="PhoneNumber"
                    placeholder="+49 123 4567890"
                    popupIcon={<KeyboardArrowDownIcon />}
                    req={true}
                    width="100%"
                    helperText=""
                    error={true}
                    isSelect={false}
                    errorMessage={formErrors.phoneNumber}
                  /> */}
                  <SimpleTextField
                    value={phoneNumber}
                    placeholder="+49 123 4567890"
                    onChange={(value) => onChangeTelephone(value)}
                    title={`${LPTranslate('Input_Telephone')}*`}
                    textFieldWidthPercent={100}
                    errorMessage={formErrors.phoneNumber}
                  />
                </div>
              </div>

              <div className={styles.setPasswordButtonContainer}>
                <button
                  data-cy="submit-button"
                  className={styles.setPasswordButtonBase}
                  type="submit"
                  form="setPassword-form"
                >
                  <p className={styles.setPasswordButtonText}>
                    {LPTranslate('Button_Next')}
                  </p>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetLawyerAdressDetailsOnRegister;
