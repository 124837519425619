import React, { useEffect, useState } from 'react';

import moment from 'moment';

import AppointmentCard from './AppointmentCard';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/client/UpcomingEvents.module.scss';

const UpcomingEvents = (props) => {
  let {
    handleRefetch,
    futureAppointmentsArr,
    pastAppointmentsArr,
    menuButtonSelected,
    handlePastAppointmentsArr,
    role,
  } = props;

  const [appointments, setAppointments] = useState(futureAppointmentsArr);
  // const [appointmentID, setAppointmentID] = useState(0);
  // const [updateData, setUpdateData] = useState({});
  // const [updateAppointmentID, setUpdateAppointmentID] = useState(0);

  const [formValuesUpdate, setFormValuesUpdate] = useState({
    title: '',
    description: '',
  });

  let todayDate = new Date();

  const updateAppointmentsMinutes = () => {
    if (menuButtonSelected === 0) {
      var arr = futureAppointmentsArr?.map((v) => {
        let todayDate = moment().format('X');
        let appointmentDate = moment.unix(v.date).format('DD.MM.YYYY');

        let dateAndTime = moment(
          appointmentDate + ' ' + v.time.slice(0, 5),
          'DD.MM.YYYY HH:mm:ss'
        ).toDate();

        let appointmentTimestamp = moment(dateAndTime).format('X');

        // if (updateAppointmentID === parseInt(v.id)) {
        //   let o = Object.assign({}, v);
        //   o.appointment_type = updateData.meetingVal;
        //   o.date = updateData.selectedDate;
        //   o.time = updateData.selectedHour;

        //   if (formValuesUpdate.title !== '')
        //     o.appointment_subject = formValuesUpdate.title;
        //   if (formValuesUpdate.description !== '')
        //     o.appointment_description = formValuesUpdate.description;

        //   let objIndex = futureAppointmentsArr.findIndex(
        //     (obj) => parseInt(obj.id) === updateAppointmentID
        //   );

        //   futureAppointmentsArr[objIndex] = o;

        //   setUpdateData({});
        //   setUpdateAppointmentID(0);
        //   setFormValuesUpdate({ title: '', description: '' });

        //   return o;
        // }

        // if (appointmentID === parseInt(v.id)) {
        //   const indexOfObject = futureAppointmentsArr.findIndex((object) => {
        //     return parseInt(object.id) === appointmentID;
        //   });
        //   futureAppointmentsArr.splice(indexOfObject, 1);
        //   setAppointmentID(0);
        // }

        if (appointmentTimestamp - todayDate <= 0) {
          let o = Object.assign({}, v);

          handlePastAppointmentsArr(v);
          futureAppointmentsArr.shift();
          return o;
        } else if (appointmentTimestamp - todayDate < 3600) {
          let o = Object.assign({}, v);
          o.minutesLeft = Math.round((appointmentTimestamp - todayDate) / 60);

          return o;
        }
        return v;
      });
      setAppointments(arr);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => updateAppointmentsMinutes(), 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    futureAppointmentsArr,
    menuButtonSelected,
    // appointmentID,
    // updateAppointmentID,
  ]);

  useEffect(() => {
    futureAppointmentsArr.sort((a, b) =>
      a.date < b.date ||
      (a.date === b.date &&
        parseInt(a.time.slice(0, 2)) <= parseInt(b.time.slice(0, 2)) &&
        (parseInt(a.time.slice(0, 2)) - todayDate.getHours()) * 60 -
          (todayDate.getMinutes() - parseInt(a.time.slice(3, 5))) <
          (parseInt(b.time.slice(0, 2)) - todayDate.getHours()) * 60 -
            (todayDate.getMinutes() - parseInt(b.time.slice(3, 5))))
        ? -1
        : 1
    );

    setAppointments(futureAppointmentsArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [futureAppointmentsArr]);

  useEffect(() => {
    if (menuButtonSelected === 0) setAppointments(futureAppointmentsArr);
    else if (menuButtonSelected === 1) {
      pastAppointmentsArr.sort((a, b) =>
        a.date > b.date ||
        (a.date === b.date &&
          (parseInt(a.time.slice(0, 2)) - todayDate.getHours()) * 60 -
            (todayDate.getMinutes() - parseInt(a.time.slice(3, 5))) >
            (parseInt(b.time.slice(0, 2)) - todayDate.getHours()) * 60 -
              (todayDate.getMinutes() - parseInt(b.time.slice(3, 5))))
          ? -1
          : 1
      );
      setAppointments(pastAppointmentsArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuButtonSelected]);

  return (
    <>
      {appointments.length === 0
        ? [
            appointments.length === 0 && menuButtonSelected === 0 ? (
              <div key={'text_1'} className={styles['noMeetingsContainer']}>
                <div className={styles['emptyListText']}>
                  {LPTranslate('Error_No_Future_Meetings')}
                </div>
              </div>
            ) : (
              <div key={'text_2'} className={styles['noMeetingsContainer']}>
                <div className={styles['emptyListText']}>
                  {LPTranslate('Error_No_Past_Meetings')}
                </div>
              </div>
            ),
          ]
        : appointments?.map((data, index) => {
            return (
              <React.Fragment key={index}>
                <div key={index} style={{ width: '100%' }}>
                  <AppointmentCard
                    // resetAppointmentsList={() => props.resetAppointmentsList()}
                    handleRefetch={() => handleRefetch()}
                    cardData={data}
                    role={role}
                    menuButtonSelected={menuButtonSelected}
                    // setAppointmentID={setAppointmentID}
                    appointments={appointments}
                    setFormValuesUpdate={setFormValuesUpdate}
                    formValuesUpdate={formValuesUpdate}
                    // setUpdateData={setUpdateData}
                    // setUpdateAppointmentID={setUpdateAppointmentID}
                  />
                </div>
              </React.Fragment>
            );
          })}
    </>
  );
};

export default UpcomingEvents;
