import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import AutoCompleteTextFieldWithTags from 'Components/Lawyer/Edit/AutoCompleteFixedValuesWithTags';
import AutoCompleteTextFieldSingleSelected from 'Components/Lawyer/Edit/AutoCompleteFixedValuesSingleSelect';
import AutoCompleteTextFieldLanguages from 'Components/Lawyer/Edit/AutoCompleteFixedValuesLanguages';
import SimpleTextField from 'Components/Inputs/SimpleTextField';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/lawyer/EditLawyerSections.module.scss';

const salutationData = [
  { title: 'Herr', selected: 0 },
  { title: 'Frau', selected: 0 },
  { title: 'Divers', selected: 0 },
];

const EditLawyerProfileInformation = (props) => {
  const { areasOfLawDB, specialitiesDB, loading, error } = props;

  const {
    salutation,
    title,
    firstName,
    lastName,
    titleSuffix,
    lawfirmName,
    languages,
    shortDescription,
    areasOfLaw,
    specialities,
    profileInformationErrorMessages,
  } = props;

  const {
    onChangeSalutation,
    onChangeTitle,
    onChangeFirstName,
    onChangeLastName,
    onChangeTitleSuffix,
    onChangeLawfirmName,
    onChangeLanguages,
    onChangeShortDescription,
    onChangeAreasOfLaw,
    onChangeSpecialities,
  } = props;

  const [areasOfLawData, setAreasOfLawData] = useState([]);
  const [specialitiesData, setSpecialitiesData] = useState([]);

  useEffect(() => {
    if (areasOfLawDB.length > 0 && areasOfLaw !== undefined) {
      let areas = [];
      for (let i = 0; i < areasOfLawDB.length; i++) {
        areas.push({ title: areasOfLawDB[i], selected: 0 });

        for (let j = 0; j < areasOfLaw.length; j++) {
          if (areasOfLawDB[i] === areasOfLaw[j]) {
            areas[i].selected = 1;
            break;
          }
        }
      }

      setAreasOfLawData(areas);
    }
  }, [areasOfLawDB, areasOfLaw]);

  useEffect(() => {
    if (specialitiesDB.length > 0 && specialities !== undefined) {
      let specialitiesArray = [];
      for (let i = 0; i < specialitiesDB.length; i++) {
        specialitiesArray.push({ title: specialitiesDB[i], selected: 0 });

        for (let j = 0; j < specialities.length; j++) {
          if (specialitiesDB[i] === specialities[j]) {
            specialitiesArray[i].selected = 1;
            break;
          }
        }
      }

      setSpecialitiesData(specialitiesArray);
    }
  }, [specialitiesDB, specialities]);

  return (
    <div className={styles.container}>
      <div className={styles.oneThirdSection}>
        {loading || error ? (
          <>
            <Skeleton
              variant="rectangular"
              width={119}
              height={70}
              sx={{ mr: '20px' }}
            />
            <Skeleton variant="rectangular" width={68} height={70} />
          </>
        ) : (
          <>
            <div style={{ width: '60%', marginRight: '20px' }}>
              <AutoCompleteTextFieldSingleSelected
                title={`${LPTranslate('Input_Salutation')}*`}
                onChange={(value) => onChangeSalutation(value)}
                placeholder={LPTranslate('Input_Salutation')}
                selected={salutation}
                dataArray={salutationData}
                textFieldWidthPercent={100}
                errorMessage={profileInformationErrorMessages.salutation}
              />
            </div>

            <div style={{ width: '40%', marginRight: '20px' }}>
              <SimpleTextField
                data_cy="prefix-title-input"
                title={LPTranslate('Input_Title')}
                onChange={(value) => onChangeTitle(value)}
                value={title}
                textFieldWidthPercent={100}
              />
            </div>
          </>
        )}
      </div>

      <div className={styles.fullSection} style={{ marginTop: '20px' }}>
        {loading || error ? (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Skeleton
                  variant="rectangular"
                  width={193}
                  height={70}
                  sx={{ mr: '20px' }}
                />
                <Skeleton
                  variant="rectangular"
                  width={202}
                  height={70}
                  sx={{ mr: '20px' }}
                />
                <Skeleton variant="rectangular" width={77} height={70} />
              </Box>
              <Skeleton
                variant="rectangular"
                width={512}
                height={70}
                sx={{ mt: '16px' }}
              />
            </Box>
          </>
        ) : (
          <>
            <div style={{ width: '40%', marginRight: '20px' }}>
              <SimpleTextField
                data_cy="first-name-input"
                title={`${LPTranslate('ULPP_Profile_Input_First_Name')}*`}
                onChange={(value) => onChangeFirstName(value)}
                value={firstName}
                textFieldWidthPercent={100}
                errorMessage={profileInformationErrorMessages.firstName}
              />
            </div>
            <div style={{ width: '40%', marginRight: '20px' }}>
              <SimpleTextField
                data_cy="last-name-input"
                title={`${LPTranslate('ULPP_Profile_Input_Last_Name')}*`}
                onChange={(value) => onChangeLastName(value)}
                value={lastName}
                textFieldWidthPercent={100}
                errorMessage={profileInformationErrorMessages.lastName}
              />
            </div>
            <div style={{ width: '20%', marginRight: '0px' }}>
              <SimpleTextField
                data_cy="title-suffix-input"
                title={LPTranslate('Input_Title')}
                onChange={(value) => onChangeTitleSuffix(value)}
                value={titleSuffix}
                textFieldWidthPercent={100}
              />
            </div>
          </>
        )}
      </div>

      <div className={styles.fullSection} style={{ marginTop: '25px' }}>
        {loading || error ? (
          <>
            <Skeleton variant="rectangular" width={512} height={70} />
          </>
        ) : (
          <div style={{ width: '100%' }}>
            <AutoCompleteTextFieldWithTags
              delete_area_of_law_data_cy="delete-area-of-law-button"
              areas_of_law_data_cy="areas-of-law-input"
              title={LPTranslate('Input_Specialist_Lawyer')}
              dataArray={specialitiesData}
              optionsAreAreasOfLaw={true}
              onChange={(value) => onChangeSpecialities(value)}
              textFieldWidthPercent={100}
            />
          </div>
        )}
      </div>

      <div className={styles.fullSection} style={{ marginTop: '25px' }}>
        {loading || error ? (
          <>
            <Skeleton variant="rectangular" width={512} height={70} />
          </>
        ) : (
          <div style={{ width: '100%' }}>
            <AutoCompleteTextFieldWithTags
              delete_legal_field_data_cy="delete-legal-field-button"
              legal_fields_data_cy="legal-fields-input"
              title={LPTranslate('Common_Legal_Fields')}
              dataArray={areasOfLawData}
              optionsAreAreasOfLaw={true}
              onChange={(value) => onChangeAreasOfLaw(value)}
              textFieldWidthPercent={100}
              tagColors={['#175CD3', '#EFF8FF', '#2E90FA']}
            />
          </div>
        )}
      </div>

      <div className={styles.fullSection}>
        {loading || error ? (
          <>
            <Skeleton
              variant="rectangular"
              width={512}
              height={70}
              sx={{ mt: '20px' }}
            />
          </>
        ) : (
          <div style={{ width: '100%', marginTop: '20px' }}>
            <SimpleTextField
              data_cy="law-firm-input"
              title={LPTranslate('ULPP_Profile_Input_Law_Firm_Name')}
              onChange={(value) => onChangeLawfirmName(value)}
              value={lawfirmName}
              textFieldWidthPercent={100}
            />
          </div>
        )}
      </div>

      <div className={styles.fullSection}>
        {loading || error ? (
          <>
            <Skeleton
              variant="rectangular"
              width={512}
              height={70}
              sx={{ mt: '20px' }}
            />
          </>
        ) : (
          <div style={{ width: '100%', marginTop: '20px' }}>
            <AutoCompleteTextFieldLanguages
              title={LPTranslate('Input_Languages')}
              value={languages}
              onChange={(value) => onChangeLanguages(value)}
              textFieldWidthPercent={100}
              tagColors={['#6941C6', '#F9F5FF', '#6941C6']}
            />
          </div>
        )}
      </div>

      <div className={styles.fullSection}>
        {loading || error ? (
          <>
            <Skeleton
              variant="rectangular"
              width={505}
              height={163}
              sx={{ mt: '20px' }}
            />
          </>
        ) : (
          <div style={{ width: '100%', marginTop: '20px' }}>
            <SimpleTextField
              data_cy="short-description-input"
              title={LPTranslate('Input_Short_Description')}
              value={shortDescription}
              onChange={(value) => onChangeShortDescription(value)}
              textFieldWidthPercent={100}
              multiline={true}
              rows={6}
              maxLength={250}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EditLawyerProfileInformation;
