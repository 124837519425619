import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { ACTIVE_APPOINTMENTS_COUNT } from 'Services/Queries/lawyer';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import arrowRight from 'Assets/pictures/arrow-right-red.svg';
import styles from 'Assets/styles/common/DeleteAccountModalLawyer.module.scss';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DeleteAccountModalLawyer = (props) => {
  const navigate = useNavigate();

  const [getActiveAppointmentsCount] = useLazyQuery(ACTIVE_APPOINTMENTS_COUNT, {
    //no cache
    fetchPolicy: 'no-cache',
  });

  const [open, setOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const snackbarSeverity = 'success';
  const snackbarMessage = false;

  const handleOpen = async () => {
    let activeAppointments = await getActiveAppointmentsCount();

    if (!activeAppointments.data) return;

    const count = activeAppointments.data.activeAppointmentsCount;

    if (count > 0) {
      setOpen(true);
      return;
    }

    handleNext();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleNext = async () => {
    props.handleNext();
  };

  return (
    <>
      <button
        data-cy="delete-client-button"
        className={styles['lawp-delete-account-button']}
        onClick={handleOpen}
        disabled={false}
      >
        {LPTranslate('DA_Delete_Button')}
      </button>

      <Modal
        className={styles['lawp-delete-account-modal']}
        open={open}
        onClose={handleClose}
      >
        <>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            autoHideDuration={2000}
            onClose={handleCloseSnackbar}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={snackbarSeverity}
              sx={
                snackbarSeverity === 'error' && {
                  backgroundColor: '#7f56d9 !important',
                }
              }
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>

          <Box className={styles['lawp-delete-account-modal-box']}>
            <div className={styles['lawp-delete-account-box-container']}>
              {/* Stepper container */}

              <div className={styles['lawp-delete-account-box-content']}>
                {/* Text container */}
                <div
                  className={
                    styles[
                      'lawp-delete-account-box-content-text-and-support-container'
                    ]
                  }
                >
                  <div
                    className={
                      styles['lawp-delete-account-box-content-text-container']
                    }
                  >
                    {LPTranslate('DA_Cannot_Delete_Account')}
                  </div>
                </div>
                {/* Alert container */}
                <div
                  className={
                    styles['lawp-delete-account-box-content-alert-container']
                  }
                >
                  <div
                    className={
                      styles['lawp-delete-account-box-content-alert-content']
                    }
                  >
                    <div
                      className={
                        styles[
                          'lawp-delete-account-box-content-alert-content-support'
                        ]
                      }
                    >
                      {LPTranslate('DA_Appointments_Still_Exist')}
                    </div>
                  </div>
                  <div
                    className={
                      styles['lawp-delete-account-box-content-alert-actions']
                    }
                  >
                    <div
                      onClick={() => {
                        navigate('/eventSettings');
                      }}
                      className={
                        styles[
                          'lawp-delete-account-box-content-alert-button-container'
                        ]
                      }
                    >
                      <div
                        className={
                          styles['lawp-delete-account-box-content-alert-button']
                        }
                      >
                        <div
                          className={
                            styles[
                              'lawp-delete-account-box-content-alert-button-text'
                            ]
                          }
                        >
                          {LPTranslate('CDAM_Step_1_Info_Action_Button')}
                        </div>
                        <div
                          className={
                            styles[
                              'lawp-delete-account-box-content-alert-button-arrow'
                            ]
                          }
                        >
                          <img src={arrowRight} alt="arrowRight"></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Actions container */}
                <div
                  className={
                    styles['lawp-delete-account-box-content-actions-container']
                  }
                >
                  <div
                    data-cy="cancel-button"
                    onClick={handleClose}
                    className={
                      styles[
                        'lawp-delete-account-box-content-actions-cancel-button-container'
                      ]
                    }
                  >
                    <div
                      className={
                        styles[
                          'lawp-delete-account-box-content-actions-cancel-button'
                        ]
                      }
                    >
                      {open === true
                        ? LPTranslate('DM_Confirm_Button')
                        : LPTranslate('Button_Cancel')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </>
      </Modal>
    </>
  );
};

export default DeleteAccountModalLawyer;
