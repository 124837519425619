import { gql } from '@apollo/client';

export const GET_PRODUCTS = gql`
  query getProducts {
    products {
      price_id
      price
      image
      name
      recurring_interval
    }
  }
`;
export const GET_PRICES = gql`
  query getPrices {
    prices {
      id
      price_id
      recurring_interval
      unit_amount
      currency
      product_id
      payment_id
      stripe_product {
        tier
      }
    }
  }
`;

export const GET_LAWYER_INVOICES = gql`
  query getLawyerInvoices($lawyer_id: ID!) {
    lawyerInvoices(lawyer_id: $lawyer_id) {
      name
      date
      sum
      status
      plan
      downloadLink
    }
  }
`;

export const GET_LAWYER_INVOICES_AS_ZIP_BASE64_ENCODED = gql`
  query getLawyerInvoicesPDFAsZipBase64Encoded($input: [InvoiceData!]!) {
    lawyerInvoicesPDFAsZipBase64Encoded(input: $input)
  }
`;

export const GET_SUBSCRIPTION_TYPE = gql`
  query getSubscriptionType($lawyer_id: ID!) {
    stripeSubscription(lawyer_id: $lawyer_id)
  }
`;

export const GET_LAWYER_SUBSCRIPTION_INFORMATION = gql`
  query getLawyerSubscriptionInformation($lawyer_id: ID!) {
    lawyerSubscriptionInformation(lawyer_id: $lawyer_id) {
      subscription_name
      subscription_price
      recurring_interval
      card_brand
      card_country
      card_exp_month
      card_exp_year
      card_last4
      card_name
      card_email
    }
  }
`;

export const GET_SUBSCRIPTION_INFORMATION = gql`
  query getSubscriptionInformation($lawyer_id: ID!) {
    stripeSubscriptionInformation(lawyer_id: $lawyer_id) {
      products {
        id
        price_id
        recurring_interval
        unit_amount
        currency
        product_id
        payment_id
        stripe_product {
          tier
        }
      }
      subscriptionType
      recurring_interval
      schedule {
        type
        recurring_interval
      }
    }
  }
`;

export const GET_INVOICE_UPDATED = gql`
  mutation checkInvoiceAfterUpdate($input: checkInvoiceAfterUpdateInput!) {
    checkInvoiceAfterUpdate(input: $input) {
      ... on checkInvoiceAfterUpdateOutput {
        subscription_price
        new_price
        start_date
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const CREATE_PAYMENT_SESSION = gql`
  mutation CreatePaymentSession($input: CreatePaymentSessionInput!) {
    createPaymentSession(input: $input) {
      ... on PaymentSession {
        session_url
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const CREATE_PAYMENT_INTENT = gql`
  mutation CreatePaymentIntent($input: CreatePaymentIntentInput!) {
    createPaymentIntent(input: $input) {
      ... on CreatePaymentIntentOutput {
        message
        client {
          payment_method
          client_secret
          session_url
        }
        status
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription($input: UpdateSubscriptionInput!) {
    updateSubscription(input: $input) {
      ... on UpdateSubscriptionOutput {
        message
        status
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const UPDATE_SUBSCRIPTION_V2 = gql`
  mutation UpdateSubscriptionV2($input: UpdateSubscriptionV2Input!) {
    updateSubscriptionV2(input: $input) {
      ... on UpdateSubscriptionV2Output {
        redirect_url
        status
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription($input: CancelSubscriptionInput!) {
    cancelSubscription(input: $input) {
      status
      message
    }
  }
`;

export const CREATE_SILVER_SCHEDULE = gql`
  mutation CreateSilverSchedule(
    $input: CreateSilverScheduleInput!
  ) {
    createSilverSchedule(input: $input) {
      message
      status
    }
  }
`;
export const CANCEL_SCHEDULE_SUBSCRIPTION = gql`
  mutation CancelScheduleSubscription(
    $input: CancelScheduleSubscriptionInput!
  ) {
    cancelScheduleSubscription(input: $input) {
      message
      status
    }
  }
`;

export const REDIRECT_CUSTOMER_PORTAL = gql`
  mutation RedirectCustomerPortal($input: RedirectCustomerPortalInput!) {
    redirectCustomerPortal(input: $input) {
      ... on RedirectCustomerPortalOutput {
        session_url
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;
