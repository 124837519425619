import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useQuery } from '@apollo/client';

import SubscriptionModalCard from 'Components/Subscription/SubscriptionModalCard';
import FAQExpandableSection from 'Components/help/FAQExpandableSection';
import SubscriptionFooterComponent from 'Components/Subscription/SubscriptionFooterComponent.js';
import MetaDecorator from 'Layouts/MetaDecorator';

import subscriptionPageTextVariables from 'Services/TextVariables/subscriptionPageTextVariables.js';
import { GET_PRICES } from 'Services/Queries/stripe';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/lawyer/SubscriptionModal/SubscriptionModal.module.scss';

let faqsDummyData = [
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_1'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_1'),
  },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_2'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_2'),
  },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_3'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_3'),
  },
  // {
  //   question: LPTranslate('Support_FAQ_Lawyer_Question_4'),
  //   answer: LPTranslate('Support_FAQ_Lawyer_Answer_4'),
  // },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_5'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_5'),
  },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_6'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_6'),
  },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_7'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_7'),
  },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_8'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_8_1'),
    answer_link: LPTranslate('Support_FAQ_Lawyer_Answer_8_2'),
  },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_9'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_9'),
  },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_10'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_10_1'),
    answer_link: LPTranslate('Support_FAQ_Lawyer_Answer_10_2'),
  },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_11'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_11_1'),
    answer_link: LPTranslate('Support_FAQ_Lawyer_Answer_11_2'),
  },
];

const PricePage = () => {
  const [recurringInterval, setRecurringInterval] = useState('year');

  const processInformation = {
    schedule: {
      type: '',
      recurringInterval: '',
    },
  };
  const [DbPrices, setDbPrices] = useState([]);
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const subscriptionModalSubheading =
    subscriptionPageTextVariables.subscriptionModalSubheading;
  const subscriptionModalHeading =
    subscriptionPageTextVariables.subscriptionModalHeading;
  const subscriptionModalHeadingSupportText =
    subscriptionPageTextVariables.subscriptionModalHeadingSupportText;

  const yearly = subscriptionPageTextVariables.yearly;
  const monthly = subscriptionPageTextVariables.monthly;
  const percentage = subscriptionPageTextVariables.persentage;

  const pricesQuery = useQuery(GET_PRICES);

  useEffect(() => {
    if (pricesQuery.data) {
      setDbPrices(pricesQuery.data.prices);
    }
  }, [pricesQuery.data]);

  const handleRecurringInterval = (e, buttonPressed) => {
    switch (buttonPressed) {
      case 'month':
        const yearly = document.getElementById(
          'lawp-subscription-process-modal-year-button'
        );
        const badge = document.getElementById(
          'lawp-subscription-process-modal-year-button-badge'
        );
        if (yearly.classList.contains(styles['horizontal-tabs-year-active'])) {
          yearly.classList.remove(styles['horizontal-tabs-year-active']);
          badge.classList.remove(styles['horizontal-tabs-year-badge-active']);
        }
        if (
          !e.currentTarget.classList.contains(
            styles['horizontal-tabs-month-active']
          )
        ) {
          e.currentTarget.classList.add(styles['horizontal-tabs-month-active']);
          setRecurringInterval('month');
        }
        break;
      case 'year':
        const monthly = document.getElementById(
          'lawp-subscription-process-modal-month-button'
        );
        const badge2 = document.getElementById(
          'lawp-subscription-process-modal-year-button-badge'
        );
        if (
          !e.currentTarget.classList.contains(
            styles['horizontal-tabs-year-active']
          )
        ) {
          if (
            monthly.classList.contains(styles['horizontal-tabs-month-active'])
          ) {
            monthly.classList.remove(styles['horizontal-tabs-month-active']);
          }
          e.currentTarget.classList.add(styles['horizontal-tabs-year-active']);

          badge2.classList.add(styles['horizontal-tabs-year-badge-active']);
          setRecurringInterval('year');
        }
        break;

      default:
        break;
    }
  };
  const handleOpen = () => (window.location.href = '/preise');
  return (
    <>
      <MetaDecorator
        title={LPTranslate('PRICE_PAGE_Meta_Title_1')}
        description={LPTranslate('PRICE_PAGE_Meta_Description_1')}
        canonical={'canonical'}
        link={`${process.env.REACT_APP_DNS_URI}/preise`}
      />
      <div className={styles['modal-body']}>
        <div className={styles['subscription-overlay']}>
          <div className={styles['subscription-modal-title-component']}>
            <div
              className={styles['subscription-modal-title-compoent-container']}
            >
              {' '}
              <div
                className={
                  styles['subscription-modal-title-compoent-container-content']
                }
              >
                <div className={styles['heading-and-supporting-text']}>
                  <div className={styles['heading-and-subheading']}>
                    <button
                      className={styles['subheading']}
                      onClick={handleOpen}
                    >
                      {subscriptionModalSubheading}
                    </button>
                    <div className={styles['heading']}>
                      {subscriptionModalHeading}
                    </div>
                  </div>
                  <div className={styles['suport-text']}>
                    {subscriptionModalHeadingSupportText}
                  </div>
                </div>
                {/* recurring interval select buttons */}
                <div className={styles['header-horizontal-tabs']}>
                  {/* month button */}
                  <button
                    id="lawp-subscription-process-modal-month-button"
                    className={styles['horizontal-tabs-month']}
                    onClick={(e) => handleRecurringInterval(e, 'month')}
                  >
                    {monthly}
                  </button>
                  {/* yearly button */}
                  <div
                    id="lawp-subscription-process-modal-year-button"
                    className={`${styles['horizontal-tabs-year']} ${styles['horizontal-tabs-year-active']}`}
                    onClick={(e) => handleRecurringInterval(e, 'year')}
                  >
                    <div className={styles['horizontal-tabs-year-text']}>
                      {yearly}
                    </div>
                    <div
                      id="lawp-subscription-process-modal-year-button-badge"
                      className={`${styles['horizontal-tabs-year-badge']} ${styles['horizontal-tabs-year-badge-active']}`}
                    >
                      -{percentage}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles['subscription-card-section']}>
            <div className={styles['subscription-card-container']}>
              <div className={styles['subscription-card-content']}>
                {DbPrices?.length > 0 ? (
                  <>
                    {DbPrices.map(
                      (price, index) =>
                        price.recurring_interval === recurringInterval && (
                          <SubscriptionModalCard
                            key={index}
                            subscriptionPageTextVariables={
                              subscriptionPageTextVariables
                            }
                            recurring_interval={price.recurring_interval}
                            cardTier={price.stripe_product.tier}
                            SubscriptionCardPrice={price.unit_amount / 100}
                            buttonRedirect={'/registerLawyer'}
                            role={clientInfoRedux.role}
                            priceId={price.price_id}
                            schedule={processInformation.schedule}
                          />
                        )
                    )}
                  </>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
          <div className="faq">
            <FAQExpandableSection questions={faqsDummyData} />
            <SubscriptionFooterComponent />
          </div>
        </div>
      </div>
    </>
  );
};

export default PricePage;
