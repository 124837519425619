import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import SubscriptionUpdateBox from 'Components/Subscription/SubscriptionUpdateBox';

import { GET_INVOICE_UPDATED } from 'Services/Queries/stripe';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/subscription/SubscriptionUpdateModal.module.scss';

const NewSubscriptionUpdateModal = (props) => {
  // de facut query pentru a afla invoice-ul cu noul pret, de afisat acele informatii,
  // si de a trimit catre back end in caz ca se apasa pe "Yes, i am sure", acolo apeland subscription.update .
  const {
    type,
    new_recurring_interval,
    old_recurring_interval,
    price_id,
    currentSubscription,
    newSubscription,
    handleUpdate,
  } = props;

  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const [newPrice, setNewPrice] = useState(0);
  const [subscriptionPrice, setSubscriptionPrice] = useState(0);
  const [startDate, setStartDate] = useState('');

  const [getInvoiceUpdated] = useMutation(GET_INVOICE_UPDATED);

  const [open, setOpen] = useState(false);

  const handleOpen = async (buttonType) => {
    const getInvoiceUpdatedData = await getInvoiceUpdated({
      variables: {
        input: {
          lawyer_id: clientInfoRedux.role_id,
          price_id: props.price_id,
          buttonType: buttonType,
        },
      },
    });
    let intPrice = 0;
    let intSubscriptionPrice = 0;
    intPrice = (
      getInvoiceUpdatedData.data.checkInvoiceAfterUpdate.new_price / 100
    ).toFixed(2);
    intSubscriptionPrice = (
      getInvoiceUpdatedData.data.checkInvoiceAfterUpdate.subscription_price /
      100
    ).toFixed(2);

    setStartDate(getInvoiceUpdatedData.data.checkInvoiceAfterUpdate.start_date);
    setSubscriptionPrice(intSubscriptionPrice);
    setNewPrice(intPrice);

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {props.type === 'CancelSchedule' && (
        <button
          className={styles['lawp-update-subscription-downgrade-modal-button']}
          onClick={() => handleOpen('CancelSchedule')}
        >
          {LPTranslate('LSS_cancel_schedule_Plan_Title')}
        </button>
      )}
      {props.type === 'downgrade' && (
        <button
          className={styles['lawp-update-subscription-downgrade-modal-button']}
          onClick={() => handleOpen('downgrade')}
        >
          {LPTranslate('LSS_Downgrade_Plan_Title_1')}
          {props.newSubscription}
          {LPTranslate('LSS_Downgrade_Plan_Title_2')}
        </button>
      )}
      {props.type === 'update' && (
        <button
          className={styles['lawp-update-subscription-modal-button']}
          onClick={() => handleOpen('update')}
        >
          {LPTranslate('LSS_upgrade_Plan_Title_1')}
          {props.newSubscription}
          {LPTranslate('LSS_upgrade_Plan_Title_2')}
        </button>
      )}

      <Modal
        className={styles['lawp-update-subscription-modal']}
        open={open}
        onClose={handleClose}
      >
        <Box className={styles['lawp-update-subscription-modal-box']}>
          <SubscriptionUpdateBox
            handleClose={handleClose}
            handleUpdateSubscription={handleUpdate}
            subscriptionPrice={subscriptionPrice}
            newPrice={newPrice}
            startDate={startDate}
            currentSubscription={currentSubscription}
            newSubscription={newSubscription}
            old_recurring_interval={old_recurring_interval}
            new_recurring_interval={new_recurring_interval}
            type={type}
            price_id={price_id}
          />
        </Box>
      </Modal>
    </>
  );
};

export default NewSubscriptionUpdateModal;
