import React, { useState, useEffect } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

import AreasOfLawDropdown from 'Components/AreasOfLawDropdown';

import { AreasOfLaw_Color } from 'Services/Utils/areaOfLaws';
import { createUrlSlug } from 'Services/Utils/metaData';
import { milisecondsToDDMMYYYY } from 'Services/Utils/moment';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { readingTime } from 'Services/Utils/readingTime';
import PlaceholderImage from 'Assets/pictures/anwadoLogo.png';

import goToArticleIcon from 'Assets/pictures/goToArticleIcon.png';
import styles from 'Assets/styles/articles/ArticleCard.module.scss';

const ArticleCard = (props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [articleImage, setArticleImage] = useState(null);
  const [areasOfLawHasOverflow, setAreasOfLawHasOverflow] = useState(false);
  const [areasOfLawOverflowIndexStart, setAreasOfLawOverflowIndexStart] =
    useState(0);

  let areasOfLawOverflowRef = React.createRef();

  useEffect(() => {
    if (props.article.cover_image) {
      setIsLoading(true);
      setArticleImage(props.article.cover_image);
      setIsLoading(false);
    }
  }, [props.article]);

  useEffect(() => {
    if (
      areasOfLawOverflowRef.current &&
      areasOfLawOverflowRef.current.scrollHeight >
        areasOfLawOverflowRef.current.clientHeight
    ) {
      setAreasOfLawHasOverflow(true);

      // Figure out the id of the last child on the first line. The elements inside the select
      // element(...) will display the elements starting from the id of the last child on the first line

      let initialYPos =
        areasOfLawOverflowRef.current.children[0].getBoundingClientRect().y;

      let secondRowYPos = 0;
      for (let i = 1; i < areasOfLawOverflowRef.current.children.length; i++) {
        if (
          areasOfLawOverflowRef.current.children[i].getBoundingClientRect()
            .y !== initialYPos
        ) {
          secondRowYPos =
            areasOfLawOverflowRef.current.children[i].getBoundingClientRect().y;
          break;
        }
      }

      let lastChildOnFirstLineIndex = 0;

      for (let i = 1; i < areasOfLawOverflowRef.current.children.length; i++) {
        if (
          areasOfLawOverflowRef.current.children[i].getBoundingClientRect()
            .y !== initialYPos &&
          areasOfLawOverflowRef.current.children[i].getBoundingClientRect()
            .y !== secondRowYPos
        ) {
          lastChildOnFirstLineIndex = i;
          break;
        }
      }

      // Remove the elements that are not on the first line
      while (
        areasOfLawOverflowRef.current.children.length >
        lastChildOnFirstLineIndex
      ) {
        areasOfLawOverflowRef.current.children[
          areasOfLawOverflowRef.current.children.length - 1
        ].remove();
      }

      setAreasOfLawOverflowIndexStart(lastChildOnFirstLineIndex);
    }
  }, [areasOfLawOverflowRef]);

  const AreaOfLawTagJSX = (value, key) => {
    return (
      <div
        className="lawp-add-lawyer-review-modal-area-of-law-tag-container"
        style={{
          display: 'inline-block',
          color: AreasOfLaw_Color[value]?.color || 'black',
          backgroundColor: AreasOfLaw_Color[value]?.background || 'white',
        }}
        key={key}
        onClick={(e) => {
          e.stopPropagation();

          let aol = value.toLowerCase();

          aol = aol.replace(/\s+/g, '-');

          navigate(`/aol/${aol}`);
        }}
      >
        <h3 className="lawp-add-lawyer-review-modal-area-of-law-tag-text">
          {value}
        </h3>
      </div>
    );
  };

  let onCardClickNavigateURL = () => {
    let slug = createUrlSlug(
      '',
      props.article.title,
      props.article.author_name,
      ''
    );

    slug += `-${props.article.id}`;
    return `/rechtsinfos/${slug}`;
  };

  const handleClick = () => {
    navigate(onCardClickNavigateURL(), {
      state: { previousPage: props.previousPage },
    });

    if (props.previousPage) {
      localStorage.setItem(
        'ArticleBlogPageDetailsPreviousPage',
        props.previousPage
      );
    } else {
      localStorage.setItem('ArticleBlogPageDetailsPreviousPage', null);
    }
  };

  if (isLoading) {
    return (
      <>
        <div
          style={{
            width: '384px',
            height: '500px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '200px',
            marginBottom: '200px',
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      </>
    );
  }

  return (
    <div
      key={props.keyIndex}
      className={styles['article-card-container']}
      data-cy="article-card"
      onClick={handleClick}
    >
      <div className={styles['image-container']}>
        <img
          src={articleImage ? articleImage : PlaceholderImage}
          alt={'test'}
          className={styles['article-image']}
        />
        <div className={styles.ContainerTag}>
          <div className={styles.ArticleTypeTag}>
            <span>
              {LPTranslate('AAM_Form_1_Input_Description_Reading_Time_1')}
            </span>
          </div>
          <span className={styles.ArticleReadTime}>
            {readingTime(props.article.content)}
            {LPTranslate('AAM_Form_1_Input_Description_Reading_Time_2')}
          </span>
        </div>
      </div>

      <h4 className={styles['name-and-date-paragraph']}>
        {props.article.author_name} •{' '}
        {milisecondsToDDMMYYYY(props.article.created_at)}
      </h4>

      <div className={styles['title-container']}>
        <div className={styles['title']}>
          <a
            href={`${window.location.origin}` + onCardClickNavigateURL()}
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={styles['title-text']}
          >
            {props.article.title}
          </a>
        </div>
        <div className={styles['go-to-article-icon-container']}>
          <img
            className={styles['go-to-article-icon']}
            src={goToArticleIcon}
            alt={'arrow'}
            onClick={handleClick}
          />
        </div>
      </div>

      <div className={styles['description-container']}>
        <h3 className={styles['description-text']}>
          {props.article.description}
        </h3>
      </div>

      <div className={styles['areas-of-law-and-overflow-button-container']}>
        <div className={styles['area-of-laws-container']}>
          <div
            className={styles['area-of-law-text']}
            ref={areasOfLawOverflowRef}
          >
            {props.article.areas_of_law.map((value, key) => {
              return AreaOfLawTagJSX(value, key);
            })}
          </div>
        </div>
        {areasOfLawHasOverflow && (
          <div className={styles['area-of-law-overflow']}>
            <AreasOfLawDropdown
              areasOfLaw={props.article.areas_of_law}
              areasOfLawOverflowIndexStart={areasOfLawOverflowIndexStart}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticleCard;
