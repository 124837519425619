import React from 'react';

import PhoneInput from 'react-phone-input-2';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import 'Assets/styles/lawyer/EditLawyerPhoneInput.scss';

const Input = (props) => {
  return (
    <div
      data-cy="phone-input"
      style={{
        display: 'block',
        width: '100%',
        height: 46,
      }}
    >
      <PhoneInput
        inputClass={props.errorMessage ? 'phone-input-error' : ''}
        specialLabel=""
        country="de"
        enableAreaCodes={true}
        regions={'europe'}
        placeholder={'+49 089 12345678'}
        {...props}
      />
    </div>
  );
};

const EditLawyerPhoneInput = (props) => {
  const { title, value, onChange, errorMessage } = props;

  const onChangeHandler = (value) => {
    onChange(value);
  };

  return (
    <div
      style={{
        display: 'block',
        width: '100%',
        marginTop: 10,
      }}
    >
      <p
        style={{
          fontFamily: 'Inter',
          fontWeight: 500,
          fontSize: 14,
          marginBottom: '6px',
        }}
      >
        {' '}
        {title}{' '}
      </p>
      <Input
        onChange={onChangeHandler}
        value={value}
        label={'Mobile Phone'}
        title={title}
        popupIcon={<KeyboardArrowDownIcon />}
        req={true}
        helperText={''}
        error={true}
        isSelect={false}
        errorMessage={errorMessage}
        {...props.input}
        {...props.meta}
        {...props.custom}
      />

      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default EditLawyerPhoneInput;
