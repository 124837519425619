import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import DeleteClientBox from 'Components/Client/Settings/Delete/DeleteClientBox';
import ConfirmationDeleteClientBox from 'Components/Client/Settings/Delete/ConfirmationDeleteClientBox';

import { DELETE_USER_CLIENT } from 'Services/Queries/user';
import { GET_CLIENT_ACTIVE_APPOINTMENTS_COUNT } from 'Services/Queries/client';
import { resetClientInfo } from 'Services/Redux/reducers/clientInfo';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/common/DeleteAccountModal.module.scss';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DeleteAccountModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [deleteClient] = useMutation(DELETE_USER_CLIENT);
  //add data with the name appointmentsCountData
  const [getClientActiveAppointmentsCount, { data }] = useLazyQuery(
    GET_CLIENT_ACTIVE_APPOINTMENTS_COUNT,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const [deleteText, setDeleteText] = useState('');
  const [deleteTextError, setDeleteTextError] = useState('');
  const [open, setOpen] = useState(false);
  const [shownModal, setShownModal] = useState(0);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState(false);

  const handleOpen = async () => {
    await getClientActiveAppointmentsCount({
      variables: {
        client_id: clientInfoRedux.role_id,
      },
    });

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShownModal(0);
    setDeleteText('');
    setDeleteTextError('');
  };

  const changeModal = () => {
    setShownModal(!shownModal);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleDelete = async () => {
    // let isError = false;

    // if (deleteText.length === 0) {
    //   isError = true;
    //   setDeleteTextError(LPTranslate('Error_Delete_Text_Required'));
    // }

    // if (isError) return;

    try {
      let deleteAccount = await deleteClient({
        variables: {
          deleteReason: deleteText,
          email: clientInfoRedux.email,
          token: clientInfoRedux.token,
          first_name: clientInfoRedux.first_name,
          last_name: clientInfoRedux.last_name,
        },
      });
      if (deleteAccount.data.deleteUserClient.message) {
        if (!clientInfoRedux.remember) {
          localStorage.clear();
          sessionStorage.clear();
        }
        dispatch(resetClientInfo());
        handleClose();
        navigate('/');
      } else {
        setSnackbarMessage(LPTranslate('Error_Server_Down'));
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage(LPTranslate('Error_Server_Down'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <button
        data-cy="delete-client-button"
        className={styles['lawp-delete-account-button']}
        onClick={handleOpen}
        disabled={false}
      >
        {LPTranslate('DA_Delete_Button')}
      </button>

      <Modal
        className={styles['lawp-delete-account-modal']}
        open={open}
        onClose={handleClose}
      >
        <>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            autoHideDuration={2000}
            onClose={handleCloseSnackbar}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={snackbarSeverity}
              sx={
                snackbarSeverity === 'error' && {
                  backgroundColor: '#7f56d9 !important',
                }
              }
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>

          <Box className={styles['lawp-delete-account-modal-box']}>
            {shownModal === 0 ? (
              <DeleteClientBox
                handleClose={handleClose}
                changeModal={changeModal}
                activeAppointments={data?.getClientActiveAppointmentsCount}
              />
            ) : (
              <ConfirmationDeleteClientBox
                deleteText={deleteText}
                deleteTextError={deleteTextError}
                handleDelete={handleDelete}
                handleClose={handleClose}
                changeModal={changeModal}
                handleDeleteText={(value) => {
                  setDeleteText(value);
                }}
                handleDeleteTextError={(value) => setDeleteTextError(value)}
              />
            )}
          </Box>
        </>
      </Modal>
    </>
  );
};

export default DeleteAccountModal;
