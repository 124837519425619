import React from 'react';
import { Helmet } from 'react-helmet-async';

const MetaDecorator = ({ title, description, canonical, link }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {canonical && <link rel={canonical} href={link} />}
    </Helmet>
  );
};

export default MetaDecorator;
