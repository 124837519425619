import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

import Filters from 'Components/Lawyer/Filters';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/lawyer/FiltersDrawer.scss';

export default function FiltersDrawer(props) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      className="lawp-filters-drawer-drawer"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Filters
        filters={props.filters}
        setFilterCheckBoxes={props.setFilterCheckBoxes}
        clearFilters={props.clearFilters}
        resultsType={props.resultsType}
        removeClearFilterButtonIfNoFilters={
          props.removeClearFilterButtonIfNoFilters
        }
        disableDistanceCheckbox={props.disableDistanceCheckbox}
      />
    </Box>
  );

  return (
    <div className={'lawp-filters-drawer-container'}>
      <React.Fragment key={'left'}>
        <Button
          onClick={toggleDrawer('left', true)}
          data-cy={'filters-button'}
          disableRipple
        >
          <MenuRoundedIcon />{' '}
          <span className="hamburger-text">
            {LPTranslate('SL_Filters_Subscription_Name')}
          </span>
        </Button>
        <Drawer
          anchor={'left'}
          open={state['left']}
          onClose={toggleDrawer('left', false)}
        >
          {list('left')}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
