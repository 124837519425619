import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import RatingStars from 'Components/RatingStars/RatingStars';
import CheckBox from 'Components/Lawyer/FilterCheckBox';
import AddLawyerReviewTextfield from 'Components/Lawyer/AddLawyerReviewTextfield';
import LawyerReviewImage from 'Components/Lawyer/LawyerReviewImage.js';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import arrowLeft from 'Assets/pictures/purple-left-arrow.svg';

import 'Assets/styles/lawyer/AddLawyerReviewModal.scss';

export default function AddLawyerReviewBox(props) {
  let {
    clientId,
    articleImage,
    lawyerNameTitle,
    firstname,
    lastname,
    lawyerNameSuffixTitle,
    rating,
    newRating,
    reviewsNr,
    short_description,
    areasOfLaw,
    AreaOfLawTagJSX,
    reviewTitle,
    reviewText,
    termsAccepted,
    specialities,
    errorMessage,
  } = props;

  const closeButton = () => {
    if (clientId) props.resetState();

    props.handleClose();
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', closeButton);
    return () => {
      window.removeEventListener('popstate', closeButton);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="lawp-add-lawyer-review-modal">
      <div className="lawp-add-lawyer-review-modal-container">
        <div className="lawp-add-lawyer-review-modal-container-left">
          <Button
            data-cy="back-button"
            className="lawp-add-lawyer-review-modal-back-to-profile-overview-button"
            onClick={closeButton}
            startIcon={<ArrowBackRoundedIcon />}
          >
            {LPTranslate('ARM_Action_Button_Return')}
          </Button>

          <LawyerReviewImage image={articleImage} />

          <h4 className="lawp-add-lawyer-review-modal-lawyer-specialization">
            {specialities.length > 0 &&
              specialities.map((speciality, index) => {
                if (index === 0) {
                  return (
                    <>{`${LPTranslate(
                      'SL_Lawyer_Card_Specialist'
                    )} ${speciality}${
                      specialities.length === 1 ? '' : ','
                    } `}</>
                  );
                } else if (index !== specialities.length - 1) {
                  return <>{`${speciality}, `}</>;
                } else {
                  return <>{`${speciality}`}</>;
                }
              })}
          </h4>

          <h2 className="lawp-add-lawyer-review-modal-lawyer-name-text">
            {lawyerNameTitle && lawyerNameTitle + ' '}
            {firstname + ' ' + lastname}
            {lawyerNameSuffixTitle && ', ' + lawyerNameSuffixTitle}
          </h2>

          <div className="lawp-add-lawyer-review-modal-rating-container">
            <RatingStars
              data_cy="stars"
              value={Number(rating)}
              width={20}
              height={20}
              readonly={true}
              colorFilter={
                'invert(91%) sepia(69%) saturate(1377%) hue-rotate(317deg) brightness(99%) contrast(102%)'
              }
            />
            <h3 className="lawp-add-lawyer-review-modal-rating-text">
              {Number(rating)}
            </h3>

            <h3 className="lawp-add-lawyer-review-modal-rating-reviews">
              {reviewsNr === 1 && reviewsNr !== 0
                ? `${LPTranslate('LPP_Header_From')} ${reviewsNr} ${LPTranslate(
                    'Common_Review'
                  )}`
                : `${LPTranslate('LPP_Header_From')} ${reviewsNr} ${LPTranslate(
                    'Common_Reviews'
                  )}`}
            </h3>
          </div>

          <div className="lawp-add-lawyer-review-modal-lawyer-description">
            <h3 className="lawp-add-lawyer-review-modal-lawyer-description-text">
              {short_description}
            </h3>
          </div>

          <div className="lawp-add-lawyer-review-modal-areas-of-law-container">
            {areasOfLaw.map(AreaOfLawTagJSX)}
          </div>
        </div>

        <div className="lawp-add-lawyer-review-modal-container-right">
          <div className="lawp-add-lawyer-review-modal-container-right-centered">
            <div className="lawp-add-lawyer-review-modal-container-right-back-button-container_first_step">
              <button
                data-cy="back-button"
                className={
                  'lawp-add-lawyer-review-modal-container-right-row-arrow-button-back'
                }
                onClick={closeButton}
              >
                <img src={arrowLeft} alt="arrow" />
              </button>
            </div>
            <h1 className="lawp-add-lawyer-review-modal-title-text">
              {' '}
              {LPTranslate('ARM_Title')}{' '}
            </h1>

            <h3 className="lawp-add-lawyer-review-modal-title-description-text">
              {' '}
              {LPTranslate('ARM_Description')}{' '}
            </h3>

            <RatingStars
              data_cy="select-rating"
              value={newRating}
              width={65.8}
              height={65.8}
              readonly={false}
              colorFilter={
                'invert(73%) sepia(82%) saturate(1547%) hue-rotate(341deg) brightness(104%) contrast(98%)'
              }
              handleRating={props.handleRating}
            />

            <div className="lawp-add-lawyer-review-modal-textfield-container">
              <AddLawyerReviewTextfield
                data_cy="title-input"
                title={LPTranslate('ARM_Input_1_Title')}
                onChange={(value) => props.setReviewTitle(value)}
                value={reviewTitle}
                textFieldWidthPercent={100}
                placeholder={LPTranslate('ARM_Input_1_Placeholder')}
              />
            </div>

            <div className="lawp-add-lawyer-review-modal-textfield-container">
              <AddLawyerReviewTextfield
                data_cy="review-input"
                title={LPTranslate('ARM_Input_2_Title')}
                onChange={(value) => props.setReviewText(value)}
                value={reviewText}
                textFieldWidthPercent={100}
                placeholder={LPTranslate('ARM_Input_2_Placeholder')}
                multiline
                rows={4}
              />
            </div>

            <div className="lawp-add-lawyer-review-modal-checkbox-container">
              <p className="lawp-add-lawyer-review-modal-checkbox-title">
                {' '}
                {LPTranslate('ARM_Confirmation_Title')}{' '}
              </p>
              <div style={{ display: 'flex', gap: '12px' }}>
                <div className="lawp-add-lawyer-review-modal-checkbox">
                  <CheckBox
                    data_cy="checkbox"
                    checked={termsAccepted}
                    onChange={() => props.setTermsAccepted((prev) => !prev)}
                  />
                </div>

                <div className="lawp-add-lawyer-review-modal-switch-title-container">
                  <h3 className="lawp-add-lawyer-review-modal-switch-title-text">
                    {LPTranslate('ARM_Confirmation_Description')}{' '}
                    {firstname + ' ' + lastname + '.'}
                  </h3>
                </div>
              </div>

              {errorMessage && (
                <p className="lawp-add-lawyer-review-modal-error-message">
                  {errorMessage}
                </p>
              )}
            </div>
            {termsAccepted === true &&
            reviewTitle.trim('').length > 0 &&
            reviewText.trim('').length > 0 ? (
              <Button
                data-cy="submit-button"
                className="lawp-add-lawyer-review-modal-submit-button"
                onClick={props.submitHandler}
              >
                {LPTranslate('ARM_Action_Button_Accept')}
              </Button>
            ) : (
              <Button
                data-cy="submit-button"
                className="lawp-add-lawyer-review-modal-submit-button-disabled"
                disabled
              >
                {LPTranslate('ARM_Action_Button_Accept')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Box>
  );
}
