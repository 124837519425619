const fetchUserIP = async () => {
  // free service to get user ip. https://api.ipify.org/
  const ip = await fetch('https://api.ipify.org/?format=json')
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => console.log(err));

  return ip;
};

export { fetchUserIP };
