import React from 'react';

import check from 'Assets/pictures/checkCircle.svg';

import styles from 'Assets/styles/common/UpdatePasswordConfirmationModal.module.scss';

const UpdatePasswordConfirmationModal = (props) => {
  return (
    <div style={{ width: '400px' }}>
      <div className={styles['confirm-message-modal-container']}>
        <div className={styles['confirm-message-modal-featured-icon']}>
          <img src={check} alt="key" />
        </div>
        <div
          className={styles['confirm-message-modal-text-and-supporting-text']}
        >
          <h2 className={styles['confirm-message-modal-text']}>
            {props.titleMessage}
          </h2>
          <h3 className={styles['confirm-message-modal-supporting-text']}>
            {props.hintMessage}
          </h3>
        </div>

        <div className={styles['confirm-message-modal-actions']}>
          <button
            data-cy="confirm-appointment-request"
            className={styles['confirm-message-modal-success-button']}
            onClick={() => {
              props.handleClose();
            }}
          >
            {props.buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};
export default UpdatePasswordConfirmationModal;
