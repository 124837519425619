import React, { useState, useEffect } from 'react';

import RatingStars from 'Components/RatingStars/RatingStars';

import LawyerSectionImagePlaceholder1 from 'Assets/pictures/userPlaceholderImage.png';
import 'Assets/styles/lawyer/LawyerSectionCard.scss';

const LawyerSectionCard = (props) => {
  const [profileImage, setProfileImage] = useState(null);

  useEffect(() => {
    if (props.image) {
      setProfileImage(props.image);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="lawp-lawyer-section-card-container"
      ref={props.cardRef}
      onClick={() => props.handleClickOnCard()}
      data-cy={'lawyer-section-card'}
    >
      <img
        draggable="false"
        className="image"
        src={profileImage ? profileImage : LawyerSectionImagePlaceholder1}
        alt="Lawyer Section Card"
      />

      <div className="info-container">
        <RatingStars
          value={props.rating}
          width={20}
          height={20}
          readonly={true}
          colorFilter={
            'invert(91%) sepia(69%) saturate(1377%) hue-rotate(317deg) brightness(99%) contrast(102%)'
          }
        />

        <h2
          className="name"
          style={{ color: profileImage ? 'white' : 'black' }}
        >
          {props.name}
        </h2>

        {props.specialist && (
          <h3
            className="specialist"
            style={
              props.lawFirm
                ? {
                    color: profileImage ? 'white' : 'black',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '100%',
                  }
                : {
                    minHeight: 65,
                    color: profileImage ? 'white' : 'black',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: '3' /* limit to 3 lines */,
                  }
            }
          >
            {props.specialist}
          </h3>
        )}

        {props.lawFirm && (
          <h4
            className="law-firm"
            style={
              props.specialist
                ? {
                    color: profileImage ? 'white' : 'black',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }
                : {
                    color: profileImage ? 'white' : 'black',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: '3' /* limit to 3 lines */,
                  }
            }
          >
            {props.lawFirm}
          </h4>
        )}
      </div>
    </div>
  );
};

export default LawyerSectionCard;
