import { useNavigate } from 'react-router-dom';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { createUrlSlug } from 'Services/Utils/metaData';

import styles from 'Assets/styles/articles/ArticleGrayFooter.module.scss';

const ArticleGrayFooter = (props) => {
  const { titleText, role, author_name } = props;

  const paragraph = `${LPTranslate(
    'Article_Blog_Page_Details_Gray_Description_1'
  )} ${author_name} ${LPTranslate(
    'Article_Blog_Page_Details_Gray_Description_2'
  )} ${author_name} ${LPTranslate(
    'Article_Blog_Page_Details_Gray_Description_3'
  )}`;

  const navigate = useNavigate();
  const handleClick = () => {
    if (role === 'operator') navigate(`/`);
    else {
      let slug = createUrlSlug(
        '',
        props.lawyer_first_name,
        props.lawyer_last_name,
        ''
      );

      slug += `-${props.lawyer_id}`;
      navigate(`/anwaelte/${slug}`);
    }
  };

  return (
    <div className={styles['gray-container']}>
      <div className={styles['content']}>
        <div className={styles['gray-container-header']}>
          {LPTranslate('Article_Blog_Page_Details_Gray_Title')}
          {titleText + '?'}
        </div>
        <div className={styles['gray-container-paragraph']}>{paragraph}</div>
        <button
          className={styles['button-text']}
          onClick={handleClick}
          data-cy="purple-button"
        >
          {role === 'operator'
            ? LPTranslate('Article_Blog_Page_Details_Gray_Button_Find_Lawyer')
            : LPTranslate('Article_Blog_Page_Details_Gray_Button_Lawyer_Page')}
        </button>
      </div>
    </div>
  );
};
export default ArticleGrayFooter;
