import React, { useState } from 'react';

import TermsOfServiceLawyer from 'Components/Lawyer/TermsOfServiceLawyer';
import TermsOfServiceClient from 'Components/Client/TermsOfServiceClient';
import PartOfCommunityMockupSection from 'Components/help/PartOfCommunityMockupSection';
import MetaDecorator from 'Layouts/MetaDecorator';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/help/CookiesPage.scss';

const TermsOfServicePage = () => {
  const [switchTerms, setSwitchTerms] = useState('clients');

  const handleSwitch = (e, buttonPressed) => {
    switch (buttonPressed) {
      case 'lawyers':
        const clientSwitch = document.getElementById(
          'lawp-cookies-page-client-button'
        );
        if (
          clientSwitch.classList.contains(
            'lawp-cookies-page-horizontal-tabs-client-active'
          ) ||
          clientSwitch.classList.contains('active')
        ) {
          clientSwitch.classList.remove(
            'lawp-cookies-page-horizontal-tabs-client-active'
          );
          clientSwitch.classList.remove('active');
        }
        if (
          !e.currentTarget.classList.contains(
            'lawp-cookies-page-orizontal-tabs-lawyer-active'
          )
        ) {
          e.currentTarget.classList.add(
            'lawp-cookies-page-horizontal-tabs-lawyer-active'
          );
          setSwitchTerms('lawyers');
        }
        break;
      case 'clients':
        const lawyerSwitch = document.getElementById(
          'lawp-cookies-page-lawyer-button'
        );
        if (
          !e.currentTarget.classList.contains(
            'lawp-cookies-page-horizontal-tabs-client-active'
          )
        ) {
          if (
            lawyerSwitch.classList.contains(
              'lawp-cookies-page-horizontal-tabs-lawyer-active'
            )
          ) {
            lawyerSwitch.classList.remove(
              'lawp-cookies-page-horizontal-tabs-lawyer-active'
            );
          }
          e.currentTarget.classList.add(
            'lawp-cookies-page-horizontal-tabs-client-active'
          );
          setSwitchTerms('clients');
        }
        break;

      default:
        break;
    }
  };

  return (
    <div className="lawp-cookies-page-container">
      <MetaDecorator
        title={LPTranslate('DTAC_Meta_Title_1')}
        description={LPTranslate('DTAC_Meta_Description_1')}
        canonical={'canonical'}
        link={`${process.env.REACT_APP_DNS_URI}/agb`}
      />
      <div className="lawp-cookies-page-faq-container">
        <div className={'lawp-cookies-page-header-horizontal-tabs'}>
          <div
            id="lawp-cookies-page-lawyer-button"
            className={'lawp-cookies-page-horizontal-tabs-lawyer'}
            onClick={(e) => handleSwitch(e, 'lawyers')}
          >
            {LPTranslate('DTAC_For_Lawyers_Switch')}
          </div>
          <div
            id="lawp-cookies-page-client-button"
            className="lawp-cookies-page-horizontal-tabs-client active"
            onClick={(e) => handleSwitch(e, 'clients')}
          >
            <div className="lawp-cookies-page-horizontal-tabs-client-text">
              {LPTranslate('DTAC_For_Clients_Switch')}
            </div>
          </div>
        </div>

        {switchTerms === 'lawyers' ? (
          <TermsOfServiceLawyer />
        ) : (
          <TermsOfServiceClient />
        )}
      </div>

      <div
        style={{
          width: '90%',
        }}
      >
        <PartOfCommunityMockupSection language="de" />
      </div>
    </div>
  );
};

export default TermsOfServicePage;
