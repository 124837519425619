import React, { useState } from 'react';

import Star from 'Assets/pictures/Star.svg';

import styles from 'Assets/styles/RatingStars.module.scss';

const RatingStars = (props) => {
  const { value, readonly, width, height, colorFilter, data_cy } = props;

  const [hover, setHover] = useState(0);

  const style = {
    on: {
      width: `${width}px`,
      height: `${height}px`,
      filter: colorFilter,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: props.pointerOnHover ? 'pointer' : 'default',
    },

    off: {
      width: `${width}px`,
      height: `${height}px`,
      filter:
        'invert(95%) sepia(15%) saturate(40%) hue-rotate(175deg) brightness(97%) contrast(101%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: props.pointerOnHover ? 'pointer' : 'default',
    },
  };
  return (
    <div
      className={styles['star-rating']}
      onClick={props.onClickHandler}
      style={{
        cursor: props.pointerOnHover ? 'pointer' : 'default',
      }}
    >
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <img
            data-cy={data_cy}
            src={Star}
            alt="star"
            key={index}
            className={
              !readonly
                ? styles['star-rating-pointer']
                : styles['star-rating-default']
            }
            style={index <= (hover || value) ? style.on : style.off}
            onClick={() => {
              if (!readonly) {
                props.handleRating(index);
              }
            }}
            onMouseEnter={() => !readonly && setHover(index)}
            onMouseLeave={() => !readonly && setHover(value)}
          />
        );
      })}
    </div>
  );
};

export default RatingStars;
