import React, { useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_REVIEWS_OF_LAWYER } from 'Services/Queries/lawyer';
import { DELETE_REVIEW } from 'Services/Queries/client';

import ReviewCardLawyerSettings from 'Components/Lawyer/ReviewCardLawyerSettings';
import AddLawyerReviewModal from 'Components/Lawyer/AddLawyerReviewModal';
import DeleteReviewModal from 'Components/Lawyer/DeleteReviewModal';
import SettingsMenu from 'Components/CommonComponents/SettingsMenu';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/client/ClientReviews.scss';
import { parseJwt } from 'Services/Utils/token';

const LawyerReviews = () => {
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const [lawyer, setLawyer] = useState({
    id: 0,
    first_name: '',
    last_name: '',
    areasOfLaw: [],
    rating: '',
    reviews_count: '',
    profile_picture: '',
    specialities: [],
  });
  const [selectedReview, setSelectedReview] = useState({
    lawyer: { rating: null },
  });
  const [removeLawyerReviewModalOpen, setRemoveLawyerReviewModalOpen] =
    useState(false);
  const [AddLawyerReviewModalOpen, setAddLawyerReviewModalOpen] =
    useState(false);
  const [reviews, setReviews] = useState([]);

  const [deleteReview] = useMutation(DELETE_REVIEW);

  const [reviewsQuery, { data }] = useLazyQuery(GET_REVIEWS_OF_LAWYER, {
    fetchPolicy: 'network-only',
  });

  const [ratingData, setRatingData] = useState({
    reviewTitle: '',
    reviewText: '',
    rating: '',
  });

  const refetchReviews = useCallback(async () => {
    await reviewsQuery({
      variables: {
        input: {
          lawyer_id: clientInfoRedux.role_id,
          page: 1,
          limit: 100,
        },
      },
      fetchPolicy: 'no-cache',
    });
  }, [reviewsQuery, clientInfoRedux.role_id]);

  useEffect(() => {
    refetchReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratingData]);

  useEffect(() => {
    if (data) {
      setReviews(data);
    }
  }, [data]);

  const openUpdateModal = (lawyerData) => {
    setLawyer({
      id: lawyerData.lawyer.id,
      first_name: lawyerData.lawyer.first_name,
      last_name: lawyerData.lawyer.last_name,
      rating: lawyerData.lawyer.rating,
      reviews_count: lawyerData.lawyer.review_count,
      areasOfLaw: lawyerData.lawyer.areas_of_law,
      profileImage: lawyerData.lawyer.profile_image,
      specialities: lawyerData.lawyer.specialities,
    });
    setAddLawyerReviewModalOpen(true);
  };

  const openDeleteModal = (reviewData) => {
    setSelectedReview(reviewData);
    setRemoveLawyerReviewModalOpen(true);
  };

  const onDeleteReview = async (reviewID, message) => {
    await deleteReview({
      variables: {
        input: {
          review_id: reviewID,
          delete_reason: message,
        },
      },
    });

    refetchReviews();
    setRemoveLawyerReviewModalOpen(false);
  };

  return (
    <div className="lawp-client-reviews-client-profile-settings-page-container ">
      <div className="lawp-client-reviews-client-profile-settings-top-menu">
        <SettingsMenu
          setUpdateComponent={'/updateClientProfile'}
          role={clientInfoRedux.role}
          subscription={parseJwt(clientInfoRedux.token).subscription}
        />
      </div>

      {reviews.reviewsOfLawyer && reviews.reviewsOfLawyer.reviews.length > 0 ? (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row',
              flexWrap: 'wrap',
              width: '100%',
            }}
          >
            {reviews.reviewsOfLawyer &&
              reviews.reviewsOfLawyer.reviews.map((review, index) => {
                return (
                  <ReviewCardLawyerSettings
                    key={index}
                    review={review}
                    client_id={review.client_id}
                    onUpdate={(lawyerData) => openUpdateModal(lawyerData)}
                    onDelete={(reviewData) => openDeleteModal(reviewData)}
                    onRefetch={() => refetchReviews()}
                  />
                );
              })}
          </div>

          <AddLawyerReviewModal
            open={AddLawyerReviewModalOpen}
            handleClose={() => setAddLawyerReviewModalOpen(false)}
            lawyer={lawyer}
            averageNr={lawyer.rating}
            setAverageNr={() => {}}
            reviewsNr={lawyer.reviews_count}
            setReviewsNr={() => {}}
            setRatingData={setRatingData}
            onComplete={() => refetchReviews()}
          />

          <DeleteReviewModal
            open={removeLawyerReviewModalOpen}
            handleClose={() => setRemoveLawyerReviewModalOpen(false)}
            review={selectedReview}
            onDelete={onDeleteReview}
          />
        </>
      ) : (
        <>
          <div className="lawp-client-reviews-no-reviews-container">
            <p className="lawp-client-reviews-no-reviews-container-text">
              {LPTranslate('Error_No_Reviews')}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default LawyerReviews;
