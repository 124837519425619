import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import FilterCheckBox from 'Components/Lawyer/FilterCheckBox';
import SimpleTextField from 'Components/Inputs/SimpleTextField';
import MetaDecorator from 'Layouts/MetaDecorator';

import { useMutation } from '@apollo/client';
import { SEND_REPORT } from 'Services/Queries/user';
import { validateEmail } from 'Services/Utils/validations';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import ContactUsFormBackground from 'Assets/pictures/ContactUsFormBackground.png';
import ContactUsMap from 'Assets/pictures/contactUsMap.png';
import 'Assets/styles/help/ContactUsPage.scss';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ContactUsPage = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [howToHelpMessage, setHowToHelpMessage] = useState('');
  const [legalCheckbox, setLegalCheckbox] = useState(false);
  const [inputErrors, setInputErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    telephone: '',
    howToHelpMessage: '',
    services: '',
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [sendReport, { data }] = useMutation(SEND_REPORT);

  const onSubmit = () => {
    console.log('intra?');
    let isError = false;
    let newInputErrors = {};
    let servicesString = '';

    if (firstName.trim() === '') {
      isError = true;
      newInputErrors.firstName = LPTranslate('Error_First_Name_Required');
    }

    if (lastName.trim() === '') {
      isError = true;
      newInputErrors.lastName = LPTranslate('Error_Last_Name_Required');
    }

    if (!validateEmail(email)) {
      isError = true;
      newInputErrors.email = LPTranslate('Error_Email_Invalid');
    }

    // if (telephone.trim() === '') {
    //   isError = true;
    //   newInputErrors.telephone = LPTranslate('Error_Telephone_Required');
    // }

    if (howToHelpMessage.trim() === '') {
      isError = true;
      newInputErrors.howToHelpMessage = LPTranslate(
        'Error_How_To_Help_Message_Required'
      );
    }

    setInputErrors(newInputErrors);
    if (isError) return;

    sendReport({
      variables: {
        input: {
          first_name: firstName.trim(),
          last_name: lastName.trim(),
          email: email.trim(),
          telephone: telephone.trim(),
          message: howToHelpMessage.trim(),
          service: servicesString.trim(),
        },
      },
    });
  };

  useEffect(() => {
    if (data && data.sendReport.message === 'Success') {
      setFirstName('');
      setLastName('');
      setEmail('');
      setTelephone('');
      setHowToHelpMessage('');

      setSnackbarOpen(true);
      setSnackbarSeverity('success');
      setSnackbarMessage(LPTranslate('Common_Contact_Us_Page_Success_Message'));
    }
  }, [data]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <div className="lawp-contact-us-page-container">
      <MetaDecorator
        title={LPTranslate('CU_Form_Meta_Title_1')}
        description={LPTranslate('CU_Form_Meta_Description_1')}
        canonical={'canonical'}
        link={`${process.env.REACT_APP_DNS_URI}/kontakt`}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          // severity= {type === "error" ? "error" : "success"}
          severity={snackbarSeverity}
          className={'snackbar'}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <div className="form-container">
        <img
          src={ContactUsFormBackground}
          alt="Contact Us Form Background"
          className="form-container-background"
        />
        <div className="form-container-content">
          <h1 className="title-text"> {LPTranslate('Common_Contact_Us')} </h1>

          <h2 className="subtitle-text">
            {LPTranslate('Common_Contact_Us_Description')}{' '}
            {LPTranslate('Common_Contact_Us_Description_2')}
          </h2>

          <div className="textfields-container">
            <div className="firstName">
              <SimpleTextField
                data_cy="first-name-input"
                title={LPTranslate('ULPP_Profile_Input_First_Name')}
                placeholder={LPTranslate('Input_First_Name_Placeholder')}
                onChange={(value) => {
                  setFirstName(value);
                  setInputErrors({ ...inputErrors, firstName: '' });
                }}
                value={firstName}
                errorMessage={inputErrors.firstName}
              />
            </div>

            <div className="lastName">
              <SimpleTextField
                data_cy="last-name-input"
                title={LPTranslate('ULPP_Profile_Input_Last_Name')}
                placeholder={LPTranslate('Input_Last_Name_Placeholder')}
                onChange={(value) => {
                  setLastName(value);
                  setInputErrors({ ...inputErrors, lastName: '' });
                }}
                value={lastName}
                errorMessage={inputErrors.lastName}
              />
            </div>
          </div>

          <div className="email">
            <SimpleTextField
              data_cy="email-address-input"
              title="E-Mail Adresse"
              placeholder={LPTranslate('Common_Email_Placeholder')}
              onChange={(value) => {
                setEmail(value);
                setInputErrors({ ...inputErrors, email: '' });
              }}
              value={email}
              textFieldWidthPercent={100}
              errorMessage={inputErrors.email}
            />
          </div>

          {/* <div className="phone">
            <EditLawyerPhoneInput
              value={telephone}
              onChange={(value) => {
                setTelephone(value);
                setInputErrors({ ...inputErrors, telephone: '' });
              }}
              title={LPTranslate('Input_Telephone')}
              errorMessage={inputErrors.telephone}
            />
          </div> */}

          <div className="how-can-we-help">
            <SimpleTextField
              data_cy="message-input"
              title={LPTranslate('CU_Form_Bug_Input_Title')}
              value={howToHelpMessage}
              placeholder={LPTranslate('CU_Form_Bug_Input_Placeholder')}
              onChange={(value) => {
                setHowToHelpMessage(value);
                setInputErrors({ ...inputErrors, howToHelpMessage: '' });
              }}
              textFieldWidthPercent={100}
              multiline={true}
              rows={4}
              errorMessage={inputErrors.howToHelpMessage}
            />
          </div>

          <div className="legal-checkbox">
            <FilterCheckBox
              data_cy="legal-checkbox"
              checked={legalCheckbox}
              onChange={() => setLegalCheckbox(!legalCheckbox)}
            />

            <div className="checkbox-text-container">
              <p className="legal-checkbox-text">
                {LPTranslate('CU_Form_Checkbox_Title_1')}
              </p>
              &nbsp;
              <a
                href={`${process.env.REACT_APP_DNS_URI}/datenschutz-cookies`}
                className="checkbox-link"
              >
                {LPTranslate('CU_Form_Checkbox_Title_2')}
              </a>
              &nbsp;
              <p className="legal-checkbox-text">
                {LPTranslate('CU_Form_Checkbox_Title_3')}
              </p>
            </div>
          </div>

          {data && <p className="error-message">{data.sendReport.error}</p>}
          <div className="submit-button-container">
            <Button
              className="button-style"
              onClick={() => onSubmit()}
              data-cy="submit-button"
              disabled={!legalCheckbox}
              sx={{
                backgroundColor: legalCheckbox ? null : 'gray !important',
              }}
            >
              {LPTranslate('CU_Form_Action_Button')}
            </Button>
          </div>
        </div>
      </div>

      <div className="lawp-contact-us-page-our-values-container">
        <div
          className="lawp-contact-us-page-our-values-container-info"
          style={{ width: '60%' }}
        >
          <h4>{LPTranslate('Common_Contact_Us')}</h4>
          <h2>{LPTranslate('CU_Image_Title')}</h2>
          <h3>{LPTranslate('CU_Image_Description')}</h3>
        </div>
      </div>

      <div className="lawp-contact-us-page-map-container">
        <img src={ContactUsMap} alt="Contact Us Map" className="map-image" />
      </div>
    </div>
  );
};

export default ContactUsPage;
