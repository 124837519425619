import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';

import SimpleTextField from 'Components/Inputs/SimpleTextField';
import ConfirmChangingLawyerEmailModal from 'Components/Modals/ConfirmChangingLawyerEmailModal';

import { CONFIRM_EMAIL_ADRESS_UPDATE } from 'Services/Queries/lawyer';
import { validateEmail } from 'Services/Utils/validations';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import check from 'Assets/pictures/stepperCheck.svg';

import styles from 'Assets/styles/updateLawyerDetails/SetLawyerNewPasswordModal.module.scss';

const SetLawyerNewEmailModal = (props) => {
  const [sendEmail, { data }] = useMutation(CONFIRM_EMAIL_ADRESS_UPDATE);

  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const [formValues, setFormValues] = useState({
    email: '',
  });
  const [formErrors, setFormErrors] = useState({
    email: '',
  });

  const resetState = () => {
    setFormValues({
      email: '',
    });
    setFormErrors({
      email: '',
    });
  };
  const [resendEmailCooldown, setResendEmailCooldown] = useState(false);

  const updateEmail = async (e) => {
    e.preventDefault();

    let isError = false;
    let newInputErrors = { email: '' };

    if (formValues.email.length === 0) {
      isError = true;
      newInputErrors.email = LPTranslate('Error_Email_Required');
    } else if (!validateEmail(formValues.email)) {
      isError = true;
      newInputErrors.email = LPTranslate('Error_Valid_Email');
    }

    setFormErrors(newInputErrors);

    if (isError) return;

    if (resendEmailCooldown) return;

    setResendEmailCooldown(true);

    try {
      await sendEmail({
        variables: {
          firstName: clientInfoRedux.first_name,
          lastName: clientInfoRedux.last_name,
          actualEmail: clientInfoRedux.email,
          newEmail: formValues.email,
          password: props.password,
        },
      });
    } catch (error) {
      props.handleSnackbarMessage(LPTranslate('Error_Server_Down'));
      props.handleSnackbarSeverity('error');
      props.handleSnackbarOpen();
    }

    setTimeout(() => {
      setResendEmailCooldown(false);
    }, 30000);
  };
  useEffect(() => {
    if (
      data &&
      data.sendEmailConfirmationToUpdateEmail.message === 'Mail has been sent!'
    ) {
      props.setCheck();
      props.handleUpdatedEmail(formValues.email);
      props.resetStatePassword();
      props.changeModal();
    } else if (
      data &&
      data.sendEmailConfirmationToUpdateEmail.error ===
        'Type a new email address!'
    ) {
      props.handleSnackbarMessage(LPTranslate('Error_New_Email_Required'));
      props.handleSnackbarSeverity('error');
      props.handleSnackbarOpen();
    } else if (
      data &&
      data.sendEmailConfirmationToUpdateEmail.error ===
        'Email already exists in database!'
    ) {
      props.handleSnackbarMessage(LPTranslate('Error_Email_Already_Existing'));
      props.handleSnackbarSeverity('error');
      props.handleSnackbarOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {props.shownModal === 1 ? (
        <Box className={styles['update-password-box']}>
          <div className={styles['modal-container']}>
            <div className={styles['stepper-content']}>
              <div className={styles['check-step-icon-base']}>
                <div className={styles['checked-icon-content']}>
                  <img
                    className={styles['check-icon']}
                    src={check}
                    alt="checked"
                  />
                </div>
              </div>
              <div className={styles['unselected-line']}></div>
              <div className={styles['step-icon-base']}>
                <div className={styles['selected-icon-content']}>
                  <div className={styles['selected-dot']} />
                </div>
              </div>
            </div>

            <div className={styles['modal-content-set-email']}>
              <div className={styles['modal-text-and-supporting-text']}>
                <p className={styles['modal-text']}>
                  {LPTranslate('CUEM_Set_Email_Title')}
                </p>
                <p className={styles['modal-supporting-text']}>
                  {LPTranslate('CUEM_Set_New_Email_Description')}
                </p>
              </div>

              <form
                className={styles['frame1030-input-field']}
                onSubmit={updateEmail}
                id="set-new-email-form"
              >
                <SimpleTextField
                  data_cy="email-input"
                  title={LPTranslate('CUEM_New_Login_Email')}
                  placeholder={LPTranslate('CUEM_Email_Hint')}
                  onChange={(value) => {
                    setFormValues({ ...formValues, email: value });
                    setFormErrors({ ...formErrors, email: '' });
                  }}
                  value={formValues.email}
                  textFieldWidthPercent={100}
                  type="text"
                  errorMessage={formErrors.email}
                />
              </form>
            </div>

            <div className={styles['actions']}>
              <div className={styles['cancel-button']}>
                <button
                  data-cy="cancel-button"
                  className={styles['cancel-button-base']}
                  onClick={props.handleClose}
                >
                  <p className={styles['cancel-button-text']}>
                    {LPTranslate('Button_Cancel')}
                  </p>
                </button>
              </div>

              <div className={styles['next-button']}>
                <button
                  data-cy="submit-button"
                  className={styles['next-button-base']}
                  type="submit"
                  form="set-new-email-form"
                  onClick={updateEmail}
                >
                  <p className={styles['next-button-text']}>
                    {LPTranslate('Button_Next')}
                  </p>
                </button>
              </div>
            </div>
          </div>
        </Box>
      ) : (
        <ConfirmChangingLawyerEmailModal
          resetState={resetState}
          handleClose={props.handleClose}
          email={formValues.email}
          updateEmail={updateEmail}
        />
      )}
    </>
  );
};

export default SetLawyerNewEmailModal;
