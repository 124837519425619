import React, { useState, useEffect, useRef } from 'react';

import { parseFromBase64, convertToBase64 } from 'Services/Utils/blobToBase64';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import userAvatar from 'Assets/pictures/avatarIcon.svg';

import styles from 'Assets/styles/lawyer/EditLawyerImage.module.scss';

const EditLawyerImage = (props) => {
  const [image, setImage] = useState('');
  const inputFile = useRef(null);

  useEffect(() => {
    if (props.temp === true) {
      setImage(props.imageFile);
    } else {
      if (typeof props.imageFile === 'string' && props.imageFile.length > 0) {
        if (props.imageFile.length < 1000) {
          setImage(props.imageFile);
        } else {
          const convert = async () => {
            let data = await parseFromBase64(props.imageFile);

            let path = URL.createObjectURL(data);

            setImage(path);
          };

          convert();
        }
      }
    }
  }, [props]);

  const validateFile = (file) => {
    const validTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/svg',
      'image/x-icon',
    ];

    if (validTypes.indexOf(file.type) === -1) return false;

    return true;
  };

  const pickNewImageHandler = () => {
    inputFile.current.click();
  };

  const updateImage = (e) => {
    if (!validateFile(e.target.files[0])) return;

    let file = e.target.files[0];

    const convert = async () => {
      let data = await convertToBase64(file);

      props.onChange(data);
    };

    convert();
  };

  return (
    <div className={styles.container}>
      <div>
        {image !== '' ? (
          <img
            src={image}
            alt="avatar"
            className={props.roundImage ? styles.imageRound : styles.image}
          />
        ) : (
          <img
            src={userAvatar}
            alt="default avatar"
            style={{ objectFit: 'unset' }}
            className={styles.imageRound}
          />
        )}
      </div>
      <span
        data-cy={props.delete_image_data_cy}
        className={`${styles.buttons} ${styles.buttonsTextDelete}`}
        onClick={props.onDelete}
      >
        {LPTranslate('Common_Delete')}
      </span>
      <span
        className={`${styles.buttons} ${styles.buttonsTextUpdate}`}
        onClick={pickNewImageHandler}
      >
        {LPTranslate('Common_Update')}
      </span>

      <input
        data-cy={props.update_image_data_cy}
        type="file"
        onChange={updateImage}
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default EditLawyerImage;
