export const parseJwt = (token) => {
  const decodeBase64Url = (base64Url) => {
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var padding = base64.length % 4;
    if (padding !== 0) {
      base64 += '='.repeat(4 - padding);
    }
    return decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  };

  try {
    var base64Url = token.split('.')[1];
    var jsonPayload = decodeBase64Url(base64Url);
    return JSON.parse(jsonPayload);
  } catch (err) {
    console.log(err);
  }
};

export const getTokenInfo = () => {
  let tokenInfo = null;

  if (localStorage.getItem('anwadoTokenInfo'))
    tokenInfo = localStorage.getItem('anwadoTokenInfo');

  // if (sessionStorage.getItem('anwadoTokenInfo'))
  //   tokenInfo = sessionStorage.getItem('anwadoTokenInfo');

  return tokenInfo;
};

export const removeTokenInfo = () => {
  localStorage.removeItem('anwadoTokenInfo');
  sessionStorage.removeItem('anwadoTokenInfo');
};

export const requireTokenUpdate = () => {
  localStorage.setItem('tokenUpdate', true);
};

export const isTokenExpired = async () => {
  const requireTokenUpdate = localStorage.getItem('tokenUpdate');
  if (requireTokenUpdate) {
    await new Promise((resolve) => setTimeout(resolve, 2000));

    localStorage.removeItem('tokenUpdate');
    return true;
  }

  const tokenInfo = getTokenInfo();
  const tokenInfoParsed = JSON.parse(tokenInfo);

  if (tokenInfoParsed) {
    let token = parseJwt(tokenInfoParsed.token);
    // console.log('token: ', token);
    if (token && token.subscriptionExpireDate) {
      if (token.subscriptionExpireDate < new Date().toISOString()) return true;
    }

    // 900 seconds = 15 minutes;
    if (token.exp < (Date.now() / 1000 + 900).toFixed(0)) return true;
    return false;
  }
  return false;
};
