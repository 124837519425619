import React from 'react';

import { useLazyQuery } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { ACTIVE_APPOINTMENTS_COUNT } from 'Services/Queries/lawyer';

import styles from 'Assets/styles/settings/common/SettingsMenu.module.scss';

const SettingsMenu = (props) => {
  const { role } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const [getActiveAppointmentsCount, { data }] = useLazyQuery(
    ACTIVE_APPOINTMENTS_COUNT
  );

  React.useEffect(() => {
    if (role === 'lawyer') getActiveAppointmentsCount();
  }, [role, getActiveAppointmentsCount]);

  const menuButtonHandler = (index) => {
    if (role === 'operator') {
      if (index === 0) {
        navigate('/operatorAccount');
      } else if (index === 1) {
        navigate('/operatorArticles');
      } else if (index === 2) {
        navigate('/operatorReviewReports');
      } else if (index === 3) {
        navigate('/operatorSocialMedia');
      } else if (index === 4) {
        navigate('/operatorStripeInvoices');
      }
    } else if (role === 'lawyer') {
      if (index === 0) {
        navigate('/updateLawyerPage');
      } else if (index === 1) {
        navigate('/updateLawyerArticles');
      } else if (index === 2) {
        navigate('/updateLawyerReviews');
      } else if (index === 3) {
        navigate('/updateLawyerDetails');
      } else if (index === 4) {
        navigate('/calendarSettings');
      } else if (index === 5) {
        navigate('/paymentSettings');
      } else if (index === 6) {
        navigate('/favoriteLawyer');
      } else if (index === 7) {
        navigate('/eventSettings');
      }
    } else {
      if (index === 0) {
        navigate('/updateClientProfile');
      } else if (index === 1) {
        navigate('/updateClientReviews');
      } else if (index === 2) {
        navigate('/favoriteLawyer');
      } else if (index === 3) {
        navigate('/eventSettings');
      } else if (index === 4) {
        navigate('/updateClientCredentials');
      }
    }
  };
  const clientButtons = () => {
    return (
      <Stack
        spacing={2}
        direction="row"
        className={styles['settings-menu-client-stack']}
      >
        <Button
          data-cy="settings-profile-button"
          variant="outlined"
          className={
            location.pathname === '/updateClientProfile'
              ? styles.buttonActive
              : styles.buttonInactive
          }
          onClick={() => menuButtonHandler(0)}
        >
          {LPTranslate('Common_Public_Profile')}
        </Button>
        <Button
          data-cy="settings-reviews-button"
          variant="outlined"
          className={
            location.pathname === '/updateClientReviews'
              ? styles.buttonActive
              : styles.buttonInactive
          }
          onClick={() => menuButtonHandler(1)}
        >
          {LPTranslate('Common_Reviews')}
        </Button>
        <Button
          data-cy="settings-favorites-button"
          variant="outlined"
          className={
            location.pathname === '/favoriteLawyer'
              ? styles.buttonActive
              : styles.buttonInactive
          }
          onClick={() => menuButtonHandler(2)}
        >
          {LPTranslate('Common_Favorite_Lawyers')}
        </Button>
        <Button
          data-cy="settings-appointments-button"
          variant="outlined"
          className={
            location.pathname === '/eventSettings'
              ? styles.buttonActive
              : styles.buttonInactive
          }
          onClick={() => menuButtonHandler(3)}
        >
          {LPTranslate('Common_Appointments')}
        </Button>
        <Button
          data-cy="settings-account-button"
          variant="outlined"
          className={
            location.pathname === '/updateClientCredentials'
              ? styles.buttonActive
              : styles.buttonInactive
          }
          onClick={() => menuButtonHandler(4)}
        >
          {LPTranslate('Common_Account')}
        </Button>
      </Stack>
    );
  };

  const lawyerButtons = () => {
    return (
      <Stack
        spacing={2}
        direction="row"
        className={styles['settings-menu-lawyer-stack']}
      >
        <Button
          data-cy="settings-profile-button"
          variant="outlined"
          className={
            location.pathname === '/updateLawyerPage'
              ? styles.buttonActive
              : styles.buttonInactive
          }
          onClick={() => menuButtonHandler(0)}
        >
          {LPTranslate('Common_Public_Profile')}
        </Button>

        {(props.subscription > 1 ||
          (data && data.activeAppointmentsCount > 0)) && (
          <Button
            data-cy="settings-reviews-button"
            variant="outlined"
            className={
              location.pathname === '/updateLawyerArticles'
                ? styles.buttonActive
                : styles.buttonInactive
            }
            onClick={() => menuButtonHandler(1)}
          >
            {LPTranslate('Common_Articles')}
          </Button>
        )}
        
        <Button
          data-cy="settings-reviews-button"
          variant="outlined"
          className={
            location.pathname === '/updateLawyerReviews'
              ? styles.buttonActive
              : styles.buttonInactive
          }
          onClick={() => menuButtonHandler(2)}
        >
          {LPTranslate('Common_Reviews')}
        </Button>
        <Button
          data-cy="settings-account-button"
          variant="outlined"
          className={
            location.pathname === '/updateLawyerDetails'
              ? styles.buttonActive
              : styles.buttonInactive
          }
          onClick={() => menuButtonHandler(3)}
        >
          {LPTranslate('Common_Account')}
        </Button>

        {props.subscription > 1 && (
          <Button
            data-cy="settings-calendar-button"
            variant="outlined"
            className={
              location.pathname === '/calendarSettings'
                ? styles.buttonActive
                : styles.buttonInactive
            }
            onClick={() => menuButtonHandler(4)}
          >
            {LPTranslate('Common_Calendar')}
          </Button>
        )}

        <Button
          data-cy="settings-subscription-button"
          variant="outlined"
          className={
            location.pathname === '/paymentSettings'
              ? styles.buttonActive
              : styles.buttonInactive
          }
          onClick={() => menuButtonHandler(5)}
        >
          {LPTranslate('Common_Subscription')}
        </Button>
        <Button
          data-cy="settings-favorites-button"
          variant="outlined"
          className={
            location.pathname === '/favoriteLawyer'
              ? styles.buttonActive
              : styles.buttonInactive
          }
          onClick={() => menuButtonHandler(6)}
        >
          {LPTranslate('Common_Favorite_Lawyers')}
        </Button>
        {(props.subscription > 1 ||
          (data && data.activeAppointmentsCount > 0)) && (
          <Button
            data-cy="settings-appointments-button"
            variant="outlined"
            className={
              location.pathname === '/eventSettings'
                ? styles.buttonActive
                : styles.buttonInactive
            }
            onClick={() => menuButtonHandler(7)}
          >
            {LPTranslate('Common_Appointments')}
          </Button>
        )}
      </Stack>
    );
  };

  const operatorButtons = () => {
    return (
      <Stack
        spacing={2}
        direction="row"
        className={styles['settings-menu-lawyer-stack']}
      >
        <Button
          variant="outlined"
          className={
            location.pathname === '/updateLawyerPage'
              ? styles.buttonActive
              : styles.buttonInactive
          }
          onClick={() => menuButtonHandler(0)}
        >
          Account
        </Button>
        <Button
          variant="outlined"
          className={
            location.pathname === '/updateLawyerReviews'
              ? styles.buttonActive
              : styles.buttonInactive
          }
          onClick={() => menuButtonHandler(1)}
        >
          Articles
        </Button>
        <Button
          variant="outlined"
          className={
            location.pathname === '/updateLawyerReviews'
              ? styles.buttonActive
              : styles.buttonInactive
          }
          onClick={() => menuButtonHandler(2)}
        >
          Review Reports
        </Button>
        <Button
          variant="outlined"
          className={
            location.pathname === '/updateLawyerReviews'
              ? styles.buttonActive
              : styles.buttonInactive
          }
          onClick={() => menuButtonHandler(3)}
        >
          Social Media
        </Button>
        <Button
          variant="outlined"
          className={
            location.pathname === '/updateLawyerReviews'
              ? styles.buttonActive
              : styles.buttonInactive
          }
          onClick={() => menuButtonHandler(4)}
        >
          Stripe Invoice
        </Button>
      </Stack>
    );
  };

  return (
    <div className={styles['settings-menu-container']}>
      <div className={styles['settings-menu']}>
        <div className={styles['title-text-container']}>
          <div className={styles['title-container']}>
            {LPTranslate('SIS_Top_Title')}
          </div>
          <div className={styles['text-container']}>
            {LPTranslate('SIS_Top_Description')}
          </div>
        </div>
        <div className={styles['settings-menu-buttons-container']}>
          {role === 'lawyer' && lawyerButtons()}
          {role === 'client' && clientButtons()}
          {role === 'operator' && operatorButtons()}
        </div>
      </div>
      {/* <div className={styles['settings-menu-divider']}></div> */}
    </div>
  );
};

export default SettingsMenu;
