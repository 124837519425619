import React from 'react';

import { useNavigate } from 'react-router';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import checkCircle from 'Assets/pictures/checkCircle.svg';
import arrowLeft from 'Assets/pictures/arrow-left.svg';
import 'Assets/styles/lawyer/CompleteSettingMeeting.scss';

const CompleteSettingMeeting = (props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    // console.log(props);
    // return;
    if (props.goToRegister) {
      navigate('/registerClient', {
        state: {
          firstname: props.client_firstname,
          lastname: props.client_lastname,
          email: props.client_email,
          registerFromOtherComponent: true,
        },
      });
    } else if (!props.goToRegister && props.client_token !== '') {
      navigate('/eventSettings');
    }
  };

  return (
    <div className={'lawp-complete-meeting-container'}>
      <div className={'lawp-complete-meeting-box-container'}>
        <div className={'lawp-complete-meeting-header'}>
          <div className={'lawp-complete-meeting-complete-header-icon'}>
            <img src={checkCircle} alt="checkCircle Icon" />
          </div>
          <div
            className={'lawp-complete-meeting-header-text-support-container'}
          >
            <h1 className={'lawp-complete-meeting-header-text'}>
              {LPTranslate('CSA_Completed_Title')}
            </h1>
            <h3 className={'lawp-complete-meeting-header-support'}>
              {LPTranslate('CSA_Completed_Description')}
            </h3>
          </div>
        </div>

        <div className={'lawp-complete-meeting-form-button-container'}>
          <button
            data-cy="submit-button"
            className={'lawp-complete-meeting-form-button'}
            onClick={handleClick}
          >
            {/*  */}
            {props.goToRegister
              ? LPTranslate('Button_Register_Yourself')
              : LPTranslate('CSA_Completed_Action_Button_Next')}
          </button>
        </div>

        <div className={'lawp-complete-meeting-back-button-text-container'}>
          <div className={'lawp-complete-meeting-back-button'}>
            <img src={arrowLeft} alt="arrow left" />
          </div>
          <h4
            data-cy="landing-page-button"
            className={'lawp-complete-meeting-back-text'}
            onClick={(e) => navigate('/')}
          >
            {LPTranslate('Button_Back_To_Homepage')}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default CompleteSettingMeeting;
