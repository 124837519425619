import { gql } from '@apollo/client';

export const GET_CLIENT = gql`
  query GetClient($id: ID!) {
    client(id: $id) {
      salutation
      title
      first_name
      last_name
      location
      name
      profileImage
      home_address
      house_number
      zipcode
      telephone
      email
    }
  }
`;

export const ADD_CLIENT = gql`
  mutation CreateClient($input: CreateClientInput!) {
    createClient(input: $input) {
      ... on Client {
        id
        name
        profileImage
        home_address
        zipcode
        location
        telephone
        email
        user_id
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation UpdateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      ... on Client {
        salutation
        title
        first_name
        last_name
        name
        location
        telephone
        profileImage
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const ADD_FAVORITELAWYER = gql`
  mutation createFavoriteLawyer($input: CreateFavoriteLawyerInput!) {
    createFavoriteLawyer(input: $input) {
      ... on FavoriteLawyer {
        user_id
        lawyer_id
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const DELETE_FAVORITELAWYER = gql`
  mutation DeleteFavoriteLawyer($input: deleteFavoriteLawyerInput!) {
    deleteFavoriteLawyer(input: $input) {
      ... on FavoriteLawyer {
        user_id
        lawyer_id
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const GET_FAVORITELAWYER = gql`
  query GetFavoriteLawyer($input: GetFavoriteLawyerInput!) {
    favoriteLawyer(input: $input) {
      ... on FavoriteLawyer {
        user_id
        lawyer_id
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const GET_FAVORITELAWYERSBYIDS = gql`
  mutation GetFavoriteLawyersByIds($ids: [Int!]) {
    getLawyersByIds(ids: $ids) {
      id
      salutation
      title
      attorney
      first_name
      name
      title_suffix
      professional_title
      office_name
      street
      postcode
      location
      country
      postofficebox
      telephoneint
      phoneart
      faxint
      email
      website
      specialistattorneys
      areasoflaw
      rating
    }
  }
`;
export const GET_FAVORITELAWYERIDS = gql`
  query GetFavoriteLawyersIds($client_id: Int!) {
    getLawyersIds(client_id: $client_id) {
      client_id
      lawyer_id
    }
  }
`;

export const GET_APOINTMENTS_OF_CLIENT = gql`
  query GetAppointmentsOfClient($client_id: ID!) {
    apointmentsOfClient(client_id: $client_id) {
      id
      client_name
      lawyer_name
      time
      date
      appointment_type
      specialist_attourneys
      client_id
      lawyer_id
    }
  }
`;

export const GET_APOINTMENTS_OF_LAWYER = gql`
  query GetApointmentsOfLawyer($lawyer_id: ID!) {
    apointmentsOfLawyer(lawyer_id: $lawyer_id) {
      id
      client_name
      time
      date
      client_id
      lawyer_id
    }
  }
`;

export const GET_LAWYER_AVAILABILITY = gql`
  query GetLawyerAvailability($lawyer_id: ID!) {
    getLawyerAvailability(lawyer_id: $lawyer_id) {
      ID
      time_day
      enabled
    }
  }
`;

export const GET_LAWYER_HOLIDAYS = gql`
  query GetLawyerHolidays($lawyer_id: ID!) {
    getLawyerHolidays(lawyer_id: $lawyer_id) {
      start_date_timestamp
      end_date_timestamp
    }
  }
`;

export const GET_LAWYER_APPOINTMENTS = gql`
  query GetLawyerAppointments($lawyer_id: ID!) {
    getLawyerAppointments(lawyer_id: $lawyer_id) {
      date
      time
    }
  }
`;

export const GET_LAWYER_SCHEDULE = gql`
  query getWeekDaysSchedule($lawyer_id: ID!) {
    getWeekDaysSchedule(lawyer_id: $lawyer_id) {
      schedule {
        time_day
        enabled
        lawyer_availability_time_day_intervals {
          time_start
          time_end
        }
      }
      reserved {
        date
        hour
      }
      holidays {
        start_date_timestamp
        end_date_timestamp
      }
    }
  }
`;

export const GET_LAWYER_AVAILABLE_HOURS = gql`
  query GetLawyerAvailableHours($day_id: Int) {
    getLawyerAvailableHours(day_id: $day_id) {
      time_start
      time_end
    }
  }
`;

export const SET_APPOINTMENT = gql`
  mutation SetAppointment($input: SetAppointment!) {
    setAppointment(input: $input) {
      message
      error
    }
  }
`;

export const ADD_REVIEW = gql`
  mutation AddReview($input: CreateReviewInput!) {
    addReview(input: $input) {
      ... on Review {
        client_name
        lawyer_name
        lawyer_email
        title
        content
        rating
        count
        averageRatingValue
        client_id
        lawyer_id
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const ADD_REVIEW_NOT_LOGGED = gql`
  mutation addReviewNotLogged($input: CreateReviewNotLoggedInput!) {
    addReviewNotLogged(input: $input) {
      message
      error
    }
  }
`;

export const GET_REVIEW_OF_CLIENT_FOR_LAWYER = gql`
  query GetReviewOfClientForLawyer($client_id: ID!, $lawyer_id: ID!) {
    getReviewOfClientForLawyer(client_id: $client_id, lawyer_id: $lawyer_id) {
      id
      client_name
      lawyer_name
      title
      content
      rating
      lawyer_id
      client_id
      createdAt
    }
  }
`;

// get all client reviews
export const GET_REVIEWS_OF_CLIENT = gql`
  query GetReviewsOfClient($client_id: ID!) {
    reviewsOfClient(client_id: $client_id) {
      id
      client_name
      lawyer_name
      title
      content
      rating
      lawyer_id
      client_id
      createdAt
      lawyer {
        id
        title
        first_name
        last_name
        title_suffix
        profile_image
        review_count
        areas_of_law
        rating
        review_count
        specialities
      }
      response
    }
  }
`;

//delete review
export const DELETE_REVIEW = gql`
  mutation DeleteReview($input: DeleteReviewInput!) {
    deleteReview(input: $input) {
      ... on Review {
        id
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const CHECK_IF_CLIENT_EXISTS = gql`
  mutation CheckClient(
    $email: String
    $firstName: String
    $lastName: String
    $token: String
  ) {
    checkIfClientExists(
      email: $email
      firstName: $firstName
      lastName: $lastName
      token: $token
    ) {
      message
      error
    }
  }
`;

export const REGISTER_EMAIL_PASSWORD_CLIENT = gql`
  mutation registerClient(
    $firstName: String
    $lastName: String
    $email: String
    $role: String
    $marketingAccepted: Boolean
  ) {
    registerClient(
      firstName: $firstName
      lastName: $lastName
      email: $email
      role: $role
      marketingAccepted: $marketingAccepted
    ) {
      message
      error
    }
  }
`;

export const GENERATE_OTP_CODE = gql`
  mutation generateClientOtpCode(
    $firstName: String
    $lastName: String
    $email: String
  ) {
    generateClientOtpCode(
      firstName: $firstName
      lastName: $lastName
      email: $email
    ) {
      message
      error
    }
  }
`;

export const VERIFY_OTP_CODE = gql`
  mutation verifyClientOtpCode($input: VerifyOtpInput!) {
    verifyOtpCode(input: $input) {
      message
      error
    }
  }
`;

export const VERIFY_OTP_CODE_AND_JWT = gql`
  mutation verifyClientOtpCodeAndJwt($input: VerifyClientOtpAndJwtInput!) {
    verifyClientOtpCodeAndJwt(input: $input) {
      message
      error
    }
  }
`;

export const VERIFY_ACCOUNT_ON_SETTING_MEETING = gql`
  mutation verifyClientAccountOnSettingMeeting(
    $input: VerifyClientAccountOnSettingMeeting!
  ) {
    verifyClientAccountOnSettingMeeting(input: $input) {
      message
      error
    }
  }
`;

export const SET_CLIENT_NOT_VERIFIED = gql`
  mutation setClientNotVerified($email: String) {
    setClientNotVerified(email: $email) {
      message
      error
    }
  }
`;

export const SET_NEW_PASSWORD_AUTOMATICALLY = gql`
  mutation setNewPasswordAutomatically($input: SetNewPasswordAutomatically!) {
    setNewPasswordAutomatically(input: $input) {
      message
      error
    }
  }
`;

export const SET_CLIENT_NAME_ON_REGISTER = gql`
  mutation setClientNameOnRegister($input: SetClientName!) {
    setClientNameOnRegister(input: $input) {
      message
      error
    }
  }
`;

export const SET_DETAIlS_ON_REGISTER = gql`
  mutation setClientDetailsOnRegister($input: SetClientDetails!) {
    setClientDetailsOnRegister(input: $input) {
      message
      error
    }
  }
`;
export const DELETE_CLIENT_FROM_REGISTER = gql`
  mutation deleteClientFromRegister($email: String!) {
    deleteClientFromRegister(email: $email) {
      message
      error
    }
  }
`;

export const GET_CLIENT_ACTIVE_APPOINTMENTS_COUNT = gql`
  query getClientActiveAppointmentsCount($client_id: ID!) {
    getClientActiveAppointmentsCount(client_id: $client_id)
  }
`;
