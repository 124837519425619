const countryToLanguage = {
  //English
  Bulgaria: 'bg',
  'Czech Republic': 'cs',
  Czechia: 'cs',
  Denmark: 'da',
  Germany: 'de',
  Greece: 'el',
  'United Kingdom': 'en',
  Spain: 'es',
  Estonia: 'et',
  Finland: 'fi',
  France: 'fr',
  Ireland: 'ga',
  Croatia: 'hr',
  Hungary: 'hu',
  Italy: 'it',
  Lithuania: 'lt',
  Latvia: 'lv',
  Malta: 'mt',
  Netherlands: 'nl',
  Poland: 'pl',
  Portugal: 'pt',
  Romania: 'ro',
  Slovakia: 'sk',
  Slovenia: 'sl',
  Sweden: 'se',

  //German
  Deutschland: 'de',
};

export { countryToLanguage };
