import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { GET_LAWYER_SCHEDULE } from 'Services/Queries/client';
// de adaugat o noua mutatie cu join intre orele de lucru pe saptamana si programul lui actual

import Button from '@mui/material/Button';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import LawyerReviewImage from 'Components/Lawyer/LawyerReviewImage.js';
import NewMeetingCalendar from 'Components/Calendar/NewMeetingCalendar.js';
import RatingStars from 'Components/RatingStars/RatingStars';
import MetaDecorator from 'Layouts/MetaDecorator';

import CompleteSettingMeeting from 'Components/Lawyer/CompleteSettingMeeting.js';
import SetMeetingDetailsBetweenClientAndLawyerModal from 'Components/Lawyer/SetMeetingDetailsBetweenClientAndLawyerModal.js';
import SetMeetingNameBetweenClientAndLawyerModal from 'Components/Lawyer/SetMeetingNameBetweenClientAndLawyerModal.js';

import { AreasOfLaw_Color } from 'Services/Utils/areaOfLaws';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import arrowLeft from 'Assets/pictures/purple-left-arrow.svg';
import 'Assets/styles/lawyer/SetMeetingBetweenClientAndLawyerModal.scss';

const SetMeetingBetweenClientAndLawyerModal = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  let id = location.state.id;
  let lawyer = location.state.lawyer;
  let reviewsNr = location.state.reviewsNr;
  let backButtonText = location.state.backButtonText;
  let lawyerAvailability = location.state.availability;

  const [formValues, setFormValues] = useState({});

  const [profileImageLawyer, setProfileImageLawyer] = useState(null);

  const [formErrors, setFormErrors] = useState({
    title: '',
    description: '',
    meetingVal: '',
  });

  const [formValuesName, setFormValuesName] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [formErrorsName, setFormErrorsName] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  // cip
  const getLawyerSchedule = useQuery(GET_LAWYER_SCHEDULE, {
    variables: { lawyer_id: id },
    fetchPolicy: 'no-cache',
  });

  const [backendData, setBackendData] = useState({});
  const [nextStepDate, setNextStepDate] = useState('');
  const [nextStepHour, setNextStepHour] = useState('');

  // cip

  const [state, setState] = useState(0);

  //setErrorMessage is not being used anywhere
  // const [errorMessage, setErrorMessage] = useState('');
  let errorMessage = '';

  const [goToRegister, setGoToRegister] = useState(false);

  const [meetingVal, setMeetingVal] = useState('');

  useEffect(() => {
    if (lawyerAvailability.by_phone && lawyerAvailability.personally) {
      setFormValues({
        title: '',
        description: '',
        phoneNumber: lawyer.telephone,
        addressValue: {
          street: lawyer.street,
          city: lawyer.location,
          house_number: lawyer.house_number,
          zipcode: lawyer.postcode,
          country: lawyer.country,
        },
      });
    } else if (!lawyerAvailability.by_phone && lawyerAvailability.personally) {
      setFormValues({
        title: '',
        description: '',
        addressValue: {
          street: lawyer.street,
          city: lawyer.location,
          house_number: lawyer.house_number,
          zipcode: lawyer.postcode,
          country: lawyer.country,
        },
      });
    } else if (lawyerAvailability.by_phone && !lawyerAvailability.personally) {
      setFormValues({
        title: '',
        description: '',
        phoneNumber: lawyer.telephone,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.state.lawyer.picture) {
      setProfileImageLawyer(location.state.lawyer.picture);
    } else if (location.state.lawyer.profileImage) {
      setProfileImageLawyer(location.state.lawyer.profileImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getLawyerSchedule.data) {
      setBackendData(getLawyerSchedule.data.getWeekDaysSchedule);
    }
  }, [getLawyerSchedule.data]);

  useEffect(() => {
    if (nextStepDate.length > 0 && nextStepHour.length > 0) {
      // appointment is set, next phase
      setState(1);
    }
  }, [nextStepDate, nextStepHour]);

  // aici pun culorile
  const AreaOfLawTagJSX = (value, key) => {
    return (
      <React.Fragment key={key}>
        <div
          className="lawp-add-lawyer-review-modal-area-of-law-tag-container"
          style={{
            color: AreasOfLaw_Color[value]?.color || 'black',
            backgroundColor: AreasOfLaw_Color[value]?.background || 'white',
          }}
          onClick={(e) => {
            e.stopPropagation();

            let aol = value;

            aol = aol.replace(/\s+/g, '-');

            navigate(`/aol/${aol}`);
          }}
        >
          <h3 className="lawp-add-lawyer-review-modal-area-of-law-tag-text">
            {value}
          </h3>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div
      className="lawp-add-meeting-modal-container"
      style={state === 2 ? { height: '950px' } : {}}
    >
      <MetaDecorator
        title={'Book Your Appointment | Schedule Your Visit'}
        description={
          'Reserve your spot in just a few clicks. Use our easy online scheduling system to find a convenient time for your appointment.'
        }
        canonical={'canonical'}
        link={`${process.env.REACT_APP_DNS_URI}/setAppointment`}
      />

      <div className="lawp-add-meeting-modal-container-left">
        <Button
          data-cy="back-to-landing-page-button"
          className="lawp-add-meeting-modal-back-to-profile-overview-button"
          onClick={() => navigate(-1)}
          startIcon={<ArrowBackRoundedIcon />}
        >
          {backButtonText
            ? LPTranslate('ARM_Action_Button_Return')
            : LPTranslate('Button_Back')}
        </Button>

        {profileImageLawyer ? (
          <img
            alt="profile"
            src={profileImageLawyer}
            className="lawp-add-meeting-modal-profile-image"
          />
        ) : (
          <LawyerReviewImage image={null} />
        )}

        <h4 className="lawp-add-meeting-modal-lawyer-specialization">
          {location.state.specialities.length > 0 &&
            location.state.specialities.map((speciality, index) => {
              if (index === 0) {
                return (
                  <React.Fragment key={index}>{`${LPTranslate(
                    'SL_Lawyer_Card_Specialist'
                  )} ${speciality}${
                    location.state.specialities.length === 1 ? '' : ','
                  } `}</React.Fragment>
                );
              } else if (index !== location.state.specialities.length - 1) {
                return (
                  <React.Fragment
                    key={index}
                  >{`${speciality}, `}</React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={index}>{`${speciality}`}</React.Fragment>
                );
              }
            })}
        </h4>

        <h2 className="lawp-add-meeting-modal-lawyer-name-text">
          {lawyer.title && lawyer.title + ' '}
          {lawyer.first_name + ' ' + lawyer.last_name}
          {lawyer.title_suffix && ', ' + lawyer.title_suffix}
        </h2>

        <div className="lawp-add-meeting-modal-rating-container">
          <RatingStars
            value={location.state.averageNr}
            width={20}
            height={20}
            readonly={true}
            colorFilter={
              'invert(91%) sepia(69%) saturate(1377%) hue-rotate(317deg) brightness(99%) contrast(102%)'
            }
          />
          <h3 className="lawp-add-meeting-modal-rating-text">
            {Number(location.state.averageNr)}
          </h3>

          <h3 className="lawp-add-meeting-modal-rating-reviews">
            {reviewsNr === 1 && reviewsNr !== 0
              ? `${LPTranslate('LPP_Header_From')} ${reviewsNr} ${LPTranslate(
                  'Common_Review'
                )}`
              : `${LPTranslate('LPP_Header_From')} ${reviewsNr} ${LPTranslate(
                  'Common_Reviews'
                )}`}
          </h3>
        </div>

        <div className="lawp-add-meeting-modal-lawyer-description">
          <h3 className="lawp-add-meeting-modal-lawyer-description-text">
            {lawyer.short_description}
          </h3>
        </div>

        <div className="lawp-add-meeting-modal-areas-of-law-container">
          {lawyer.areasOfLaw.map(AreaOfLawTagJSX)}
        </div>
      </div>

      <div
        className={
          state === 1
            ? 'lawp-add-meeting-modal-container-right-second-step'
            : 'lawp-add-meeting-modal-container-right'
        }
      >
        <div className="lawp-add-meeting-modal-container-right-centered">
          {state === 0 && (
            <div className="lawp-add-meeting-modal-container-right-back-button-container">
              <button
                className={
                  'lawp-add-meeting-modal-container-right-row-arrow-button-back'
                }
                onClick={() => navigate(-1)}
              >
                <img src={arrowLeft} alt="arrow" />
              </button>
            </div>
          )}
          <div className="lawp-add-meeting-modal-container-right-content">
            {state === 0 && (
              <>
                <div className="lawp-add-meeting-modal-container-right-heading-and-supporting-text">
                  <h1 className="lawp-add-meeting-modal-container-right-heading">
                    {LPTranslate('CSA_Title')}
                  </h1>
                  <h3 className="lawp-add-meeting-modal-container-right-supporting-text">
                    {LPTranslate('CSA_Description_Step_1')}
                  </h3>
                </div>
                {Object.keys(backendData).length > 0 ? (
                  <NewMeetingCalendar
                    backendData={backendData}
                    setNextStepDate={setNextStepDate}
                    setNextStepHour={setNextStepHour}
                  />
                ) : (
                  <div>loading</div>
                )}
              </>
            )}
            {state === 1 && (
              <SetMeetingDetailsBetweenClientAndLawyerModal
                setState={setState}
                nextStepDate={nextStepDate}
                nextStepHour={nextStepHour}
                appointmentHour={''}
                formValues={formValues}
                setFormValues={setFormValues}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
                meetingVal={meetingVal}
                setMeetingVal={(val) => {
                  if (val === meetingVal) return;

                  setFormErrors({ ...formErrors, meetingVal: '' });
                  setMeetingVal(val);
                }}
                errorMessage={errorMessage}
                lawyer_id={id}
                client_id={clientInfoRedux.role_id}
                availability={lawyerAvailability}
              />
            )}

            {state === 2 && clientInfoRedux.token === '' && (
              <SetMeetingNameBetweenClientAndLawyerModal
                setState={setState}
                nextStepDate={nextStepDate}
                nextStepHour={nextStepHour}
                appointmentHour={''}
                formValues={formValuesName}
                setFormValues={setFormValuesName}
                formErrors={formErrorsName}
                setFormErrors={setFormErrorsName}
                detailsFormValues={formValues}
                meetingVal={meetingVal}
                lawyer_id={id}
                client_id={clientInfoRedux.role_id}
                setGoToRegister={setGoToRegister}
              />
            )}

            {state === 3 && (
              <CompleteSettingMeeting
                client_firstname={formValuesName.firstName}
                client_lastname={formValuesName.lastName}
                client_email={formValuesName.email}
                client_token={clientInfoRedux.token}
                goToRegister={goToRegister}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetMeetingBetweenClientAndLawyerModal;
