import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { SET_ELIGIBILITY } from 'Services/Queries/user';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import check from 'Assets/pictures/checkCircle.svg';
import styles from 'Assets/styles/lawyer/DeleteComponentsStyle/DeleteModal.module.scss';

const DeleteModalSubscripion = (props) => {
  const {
    keepAccountButton,
    deleteState,
    token,
    email,
    last_name,
    first_name,
  } = props;

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [setEligibility] = useMutation(SET_ELIGIBILITY);
  if (deleteState === true) {
    setOpen(true);
  }

  const handleSubmit = async () => {
    try {
      let eligibilitySet = await setEligibility({
        variables: {
          first_name: first_name,
          last_name: last_name,
          email: email,
          token: token,
        },
      });
      if (eligibilitySet.data.setEligibility.message) {
        handleOpen();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={styles['delete-buttons-container']}>
        <div className={styles['no-delete-button']}>
          <div className={styles['button']}>
            <button
              data-cy="accept-offer-button"
              className={styles['button-base']}
              onClick={handleSubmit}
            >
              <p className={styles['button-text']}>{keepAccountButton}</p>
            </button>
          </div>
        </div>

        <Modal className={styles['add-comment-success-modal']} open={open}>
          <Box className={styles['add-comment-success-box']}>
            <div className={styles['featured-icon']}>
              <div className={styles['check-circle']}>
                <img src={check} alt="key" />
              </div>
            </div>

            <div className={styles['text-and-supporting-text']}>
              <p className={styles['text']}>
                {LPTranslate('DA_Modal_3_Months_Description')}
              </p>
            </div>

            <div className={styles['success-modal-actions']}>
              <button
                data-cy="success-button"
                className={styles['success-button']}
                onClick={(event) => {
                  event.preventDefault();
                  navigate('/');
                }}
              >
                <p className={styles['success-button-text']}>
                  {LPTranslate('Button_Go_To_Homepage')}
                </p>
              </button>
            </div>
          </Box>
        </Modal>

        <div className={styles['delete-modal-parent']}></div>
      </div>
    </>
  );
};
export default DeleteModalSubscripion;
