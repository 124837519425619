import React, { useCallback } from 'react';

import { useDropzone } from 'react-dropzone';
import Compressor from 'compressorjs';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import DropdownIcon from 'Assets/pictures/DropdownIcon.svg';
import styles from 'Assets/styles/lawyer/ImageDropzone.module.scss';

import { convertToBase64 } from 'Services/Utils/blobToBase64';

const ImageDropzone = (props) => {
  const validateFile = (file) => {
    const validTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/svg',
      'image/x-icon',
    ];

    if (validTypes.indexOf(file.type) === -1) return false;

    return true;
  };

  const imageCompress = async (img) => {
    const image = img;

    return new Promise(async (resolve, reject) => {
      new Compressor(image, {
        quality: 1,
        maxHeight: props.compressorOptions.maxHeight || null,
        maxWidth: props.compressorOptions.maxWidth || null,
        success: (compressedResult) => {
          resolve(compressedResult);
        },
      });
    });
  };

  const checkImageSize = async (image) => {
    let newImage = image;

    // // if image larger than 2MB, return error
    if (newImage.size > 2097152) {
      newImage = await imageCompress(image);
      if (newImage.size > 2097152) {
        props.fireSnackbar('Image size is too large', 'error');
        return 0;
      }
    }

    return newImage;
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      for (let i = 0; i < acceptedFiles.length; i++) {
        if (!validateFile(acceptedFiles[i])) return;
      }

      if (props.singleImage) {
        let image = acceptedFiles[0];

        image = await checkImageSize(image);

        if (image === 0) return;

        const convert = async () => {
          let data = await convertToBase64(image);

          props.onChange(data);
        };

        convert();
      } else {
        let files = [];

        const saveFiles = (data) => {
          files = props.images.concat(data);
          props.onChange(files);
        };

        let data = [];
        for (let i = 0; i < acceptedFiles.length; i++) {
          let image = acceptedFiles[i];

          image = await checkImageSize(image);

          if (image === 0) return;

          const convert = async () => {
            let image64 = await convertToBase64(image);

            data.push(image64);

            if (i === acceptedFiles.length - 1) {
              saveFiles(data);
            }
          };

          convert();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className={styles.Dropzone}>
      <div {...getRootProps()}>
        <input {...getInputProps()} data-cy={props.data_cy} />
        {isDragActive ? (
          <div className={styles.DropzoneContainerHovered}>
            <p>
              {LPTranslate(
                'ULPP_About_Me_Form_Insert_Image_Drag_And_Drop_Text'
              )}
            </p>
          </div>
        ) : (
          <div className={styles.DropzoneContainer}>
            <img
              src={DropdownIcon}
              className={styles.DropdownIcon}
              alt="dropdown"
            />

            <div className={styles.DescriptionContainer}>
              <div className={styles.DescriptionContainerSectionLeft}>
                <span className={styles.SpanPurple}>
                  {LPTranslate('ULPP_About_Me_Form_Insert_Image_Inner_Text_1')}
                </span>
              </div>

              <div className={styles.DescriptionContainerSectionRight}>
                <span className={styles.SpanGrey}>
                  {LPTranslate('ULPP_About_Me_Form_Insert_Image_Inner_Text_2')}
                </span>
              </div>
            </div>

            <div className={styles.AcceptedFilesContainer}>
              <span className={styles.SpanGrey}>
                SVG, PNG, JPG or GIF (max. {props.compressorOptions.maxWidth}x
                {props.compressorOptions.maxHeight}px)
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageDropzone;
