import React, { useState, useEffect } from 'react';

import LawyerReviewImage from 'Components/Lawyer/LawyerReviewImage.js';
import { useMutation } from '@apollo/client';

import {
  ADD_REVIEW_REPORT,
  CREATE_REVIEW_RESPONSE,
} from 'Services/Queries/lawyer';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import ReviewCardMenu from 'Components/Lawyer/ReviewCardMenu.js';
import RatingStars from 'Components/RatingStars/RatingStars';
import ApiResponseModal from 'Components/Modals/ApiResponseModal';

import 'Assets/styles/lawyer/ReviewCard.scss';

const ReviewCard = (props) => {
  const { review, onUpdate } = props;

  const [reviewImage, setReviewImage] = useState(null);

  const [apiIsError, setApiIsError] = useState(false);
  const [apiResponseModalOpen, setApiResponseModalOpen] = useState(false);
  const [apiResponseModalTitle, setApiResponseModalTitle] = useState('');
  const [apiResponseModalDescription, setApiResponseModalDescription] =
    useState('');

  const [addReviewReport] = useMutation(ADD_REVIEW_REPORT);
  const [createReviewResponse] = useMutation(CREATE_REVIEW_RESPONSE);

  useEffect(() => {
    if (review.client.profileImage) {
      setReviewImage(review.client.profileImage);
    }
  }, [review]);

  const onUpdatePress = () => {
    onUpdate(review);
  };

  const onDeletePress = async () => {
    props.onDelete(review);
  };

  const onResponsePress = async (response) => {
    try {
      const res = await createReviewResponse({
        variables: {
          input: {
            review_id: review.id,
            client_id: review.client_id,
            lawyer_id: review.lawyer_id,
            response_message: response.trim(),
          },
        },
      });

      if (res.data.createReviewResponse.error === 'success') {
        setApiResponseModalTitle(LPTranslate('RS_Response_Successful_Title'));
        setApiResponseModalDescription('');
        setApiIsError(false);
      } else {
        setApiResponseModalTitle(LPTranslate('RS_Api_Error'));
        setApiResponseModalDescription('');
        setApiIsError(true);
      }

      setApiResponseModalOpen(true);
    } catch (e) {
      setApiResponseModalTitle(LPTranslate('RS_Api_Error'));
      setApiResponseModalDescription('');
      setApiResponseModalOpen(true);
      setApiIsError(true);
    }
  };

  const onReportPress = async (report) => {
    try {
      const res = await addReviewReport({
        variables: {
          input: {
            review_id: review.id,
            client_id: review.client_id,
            lawyer_id: review.lawyer_id,
            report_reason: report.reportReason,
            report_message: report.reportMessage,
          },
        },
      });

      if (res.data.createReviewReport.error === 'success') {
        setApiResponseModalTitle(LPTranslate('RS_Report_Successful_Title'));
        setApiResponseModalDescription(
          LPTranslate('RS_Report_Successful_Description')
        );
      } else {
        setApiResponseModalTitle(LPTranslate('RS_Api_Error'));
        setApiResponseModalDescription('');
      }

      setApiResponseModalOpen(true);
    } catch (e) {
      setApiResponseModalTitle(LPTranslate('RS_Api_Error'));
      setApiResponseModalDescription('');
      setApiResponseModalOpen(true);
    }
  };

  return (
    <>
      <div className="lawp-review-card-container">
        <div className="lawp-review-card-center-content-container">
          <div className="lawp-review-card-menu-container">
            <ReviewCardMenu
              CardType="client"
              review={review}
              lawyerID={review.client_id}
              onUpdatePress={() => onUpdatePress()}
              onDeletePress={() => onDeletePress()}
              onReportPress={(report) => onReportPress(report)}
              onResponsePress={(response) => onResponsePress(response)}
            />
          </div>

          <div
            className={
              reviewImage !== null
                ? 'lawp-review-card-image-container'
                : 'lawp-review-card-image-container-placeholder'
            }
          >
            <LawyerReviewImage image={reviewImage} />
          </div>
          <div className="lawp-review-card-lawyer-name-text">
            {review.client_name}
          </div>

          <div className="lawp-review-card-rating-container">
            <RatingStars
              value={review.rating}
              width={20}
              height={20}
              readonly={true}
              colorFilter={
                'invert(91%) sepia(69%) saturate(1377%) hue-rotate(317deg) brightness(99%) contrast(102%)'
              }
            />

            {/* <Box className="lawp-review-card-rating-reviews">
              {`${milisecondsToTimeAgo(review.createdAt)}`}
            </Box> */}
          </div>
        </div>

        <div className="lawp-review-card-lawyer-description">
          <p className="lawp-review-card-lawyer-description-text">
            {review.content}
          </p>
        </div>

        <ApiResponseModal
          show={apiResponseModalOpen}
          isError={apiIsError}
          titleMessage={apiResponseModalTitle}
          hintMessage={apiResponseModalDescription}
          buttonText={LPTranslate('RS_Confirm_Button_Message')}
          handleClose={() => setApiResponseModalOpen(false)}
        />
      </div>
    </>
  );
};

export default ReviewCard;
