import React, { useState, useEffect } from 'react';

import moment from 'moment';

import { useMutation } from '@apollo/client';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

import ConfirmSendingMessageToLawyerModal from 'Components/Modals/ConfirmSendingMessageToLawyerModal';
import SendMessageToLawyerLoggedBox from 'Components/Client/Settings/SendMessageToLawyer/SendMessageToLawyerLoggedBox';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import { SEND_MESSAGE_TO_LAWYER_VIA_EMAIL } from 'Services/Queries/user';

import styles from 'Assets/styles/lawyer/SendMessageToLawyerModal.module.scss';

const SendMessageToLawyerModal = (props) => {
  let { lawyer, client, openMessage } = props;

  const [sendMessageToLawyer, { data: sendMessageToLawyerData }] = useMutation(
    SEND_MESSAGE_TO_LAWYER_VIA_EMAIL
  );

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const [errorMessage, setErrorMessage] = useState('');

  const [formValues, setFormValues] = useState({
    description: '',
  });
  const [formErrors, setFormErrors] = useState({
    description: '',
  });
  const [articleImage, setArticleImage] = useState(null);
  const [openSecondModal, setOpenSecondModal] = useState(false);

  const [shownModal, setShownModal] = useState(0);

  const indexWeekday = {
    1: 'Montag',
    2: 'Dienstag',
    3: 'Mittwoch',
    4: 'Donnerstag',
    5: 'Freitag',
    6: 'Samstag',
    7: 'Sonntag',
  };

  const handleInputChange = (value) => {
    setFormValues({ ...formValues, description: value });
  };

  useEffect(() => {
    if (lawyer.profileImage) {
      setArticleImage(lawyer.profileImage);
    } else {
      setArticleImage(null);
    }
  }, [lawyer.profileImage]);

  const handleClose = () => {
    setFormValues({ description: '' });
    setFormErrors({ description: '' });
    setShownModal(0);
    setOpen(false);
  };

  const changeModal = () => {
    setShownModal(!shownModal);
  };

  const sendMessage = async (e) => {
    e.preventDefault();

    let isError = false;
    let newInputErrors = {};

    if (formValues.description.length === 0) {
      isError = true;
      newInputErrors.description = LPTranslate('Error_Email_Required');
    }

    setFormErrors(newInputErrors);

    if (isError) return;

    let date = moment();

    try {
      await sendMessageToLawyer({
        variables: {
          input: {
            lawyer_name:
              lawyer.first_name.trim() + ' ' + lawyer.last_name.trim(),
            client_firstname: client.first_name.trim(),
            client_lastname: client.last_name.trim(),
            client_email: client.email.trim(),
            lawyer_email: lawyer.email.trim(),
            description: formValues.description.trim(),
            messageDate:
              indexWeekday[date.weekday()] +
              ', ' +
              date.format('DD.MM.YYYY, hh:mm') +
              ' Uhr',
            userRole: client.role,
            lawyer_id: Number(lawyer.id),
          },
        },
      });
    } catch (error) {
      setErrorMessage(
        'Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal...'
      );
    }
  };

  useEffect(() => {
    if (
      sendMessageToLawyerData &&
      sendMessageToLawyerData.sendMessageToLawyer.message === 'message arrived!'
    ) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'client-send-message-custom-event',
      });
      setErrorMessage('');
      changeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMessageToLawyerData]);

  var MessageButton = () => {
    if (lawyer.email === client.email) {
      return (
        <Tooltip
          className="tooltip-medium-size"
          PopperProps={{
            disablePortal: true,
          }}
          title={LPTranslate('Error_Lawyer_Cannot_Send_Message')}
          placement="top"
          TransitionComponent={Zoom}
        >
          <Button
            disableRipple
            data-cy={'send-message-button'}
            style={{
              margin: '0px 0px',
              color: '#344054',
              borderRadius: '8px',
              border: '1px solid #D0D5DD',
              boxSizing: 'border-box',
              boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
              background: '#FFFFFF',
              textTransform: 'none',
              opacity: 0.3,
            }}
            variant="text"
          >
            {LPTranslate('LPP_Header_Send_Message')}
          </Button>
        </Tooltip>
      );
    } else if (lawyer.email !== client.email) {
      return (
        <Button
          style={{
            margin: '0px 0px',
            color: '#344054',
            borderRadius: '8px',
            border: '1px solid #D0D5DD',
            boxSizing: 'border-box',
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            background: '#FFFFFF',
            textTransform: 'none',
          }}
          variant="text"
          onClick={handleOpen}
          data-cy="send-message-to-lawyer"
        >
          {LPTranslate('LPP_Header_Send_Message')}
        </Button>
      );
    }
  };

  var EmailMessageButton = () => {
    if (lawyer.email === client.email) {
      return (
        <Tooltip
          className="tooltip-medium-size"
          PopperProps={{
            disablePortal: true,
          }}
          title={LPTranslate('Error_Lawyer_Cannot_Send_Message')}
          placement="top"
          TransitionComponent={Zoom}
        >
          <button
            style={lawyer.email === client.email && { color: 'gray' }}
            data-cy={'send-message-button'}
            className={
              styles['send-message-to-lawyer-modal-lawyer-email-button']
            }
          >
            {lawyer.email}
            <NorthEastIcon transform="scale(0.7)" />
          </button>
        </Tooltip>
      );
    } else if (lawyer.email !== client.email) {
      return (
        <button
          className={styles['send-message-to-lawyer-modal-lawyer-email-button']}
          data-cy={'send-message-button'}
          onClick={handleOpen}
        >
          {lawyer.email}
          <NorthEastIcon transform="scale(0.7)" />
        </button>
      );
    }
  };

  return (
    <>
      {openMessage ? MessageButton() : EmailMessageButton()}

      <Modal
        className={styles['send-message-to-lawyer-modal']}
        open={open}
        onClose={handleClose}
      >
        <Box>
          {shownModal === 0 ? (
            <SendMessageToLawyerLoggedBox
              lawyer={lawyer}
              articleImage={articleImage}
              formValues={formValues}
              formErrors={formErrors}
              handleClose={handleClose}
              sendMessage={sendMessage}
              handleInputChange={handleInputChange}
              setFormErrors={setFormErrors}
              errorMessage={errorMessage}
            />
          ) : (
            <ConfirmSendingMessageToLawyerModal
              lawyer_name={lawyer.first_name + ' ' + lawyer.last_name}
              client={client}
              openSecondModal={openSecondModal}
              handleClose={handleClose}
              setOpenSecondModal={setOpenSecondModal}
            />
          )}
        </Box>
      </Modal>
    </>
  );
};
export default SendMessageToLawyerModal;
