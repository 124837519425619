import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router';
import { useMutation } from '@apollo/client';
// import jwt from 'jwt-decode';

import Box from '@mui/material/Box';

import { MINI_REGISTER } from 'Services/Queries/user';
import { ASK_LAWYER_TO_SET_SCHEDULE_VIA_EMAIl } from '../../Services/Queries/user';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { validateEmail } from 'Services/Utils/validations';

import SimpleTextField from 'Components/Inputs/SimpleTextField';
import UpdatePasswordConfirmationModal from './UpdatePasswordConfirmationModal';

import styles from 'Assets/styles/lawyer/SendMessageToLawyerNotLoggedNameModal.module.scss';

import avatar from 'Assets/pictures/AvatarProfile.png';
import arrowLeft from 'Assets/pictures/black-arrow-left.svg';
import check from 'Assets/pictures/whiteStepperCheck.svg';

import RegisterCheckboxComponent from 'Components/CommonComponents/RegisterCheckboxComponent';

const RequestAppointmentMiniRegisterModal = (props) => {
  let {
    shownModal,
    showMiniregister,
    first_name,
    last_name,
    email,
    profileImage,
  } = props;
  let { handleClose, setShowMiniregister, setShownModal } = props;

  const [miniRegister, { data: miniRegisterData }] = useMutation(MINI_REGISTER);
  const [askLawyerToSetSchedule, { data: askLawyer }] = useMutation(
    ASK_LAWYER_TO_SET_SCHEDULE_VIA_EMAIl
  );

  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [marketingAccepted, setMarketingAccepted] = useState(false);

  const navigate = useNavigate();

  const handleBack = (e) => {
    e.preventDefault();
    setShowMiniregister(false);
  };

  const handleInputChange = (inputName, value) => {
    switch (inputName) {
      case 'firstName':
        setFormValues({ ...formValues, firstName: value });
        break;
      case 'lastName':
        setFormValues({ ...formValues, lastName: value });
        break;
      case 'email':
        setFormValues({ ...formValues, email: value });
        break;

      default:
        break;
    }
  };

  const callSendRequest = async (token) => {
    try {
      await askLawyerToSetSchedule({
        variables: {
          input: {
            token: token,
            lawyer_name: first_name + ' ' + last_name,
            client_name: formValues.firstName + ' ' + formValues.lastName,
            client_email: formValues.email,
            lawyer_email: email,
          },
        },
      });
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isError = false;
    let newInputErrors = {};

    if (formValues.email.length === 0) {
      isError = true;
      newInputErrors.email = LPTranslate('Error_Email_Required');
    } else if (!validateEmail(formValues.email)) {
      isError = true;
      newInputErrors.email = LPTranslate('Error_Valid_Email');
    }

    if (formValues.firstName.length === 0) {
      isError = true;
      newInputErrors.firstName = LPTranslate('Error_First_Name_Required');
    } else if (formValues.firstName.length > 128) {
      isError = true;
      newInputErrors.firstName = LPTranslate('Error_First_Name_Too_Long');
    }
    if (formValues.lastName.length === 0) {
      isError = true;
      newInputErrors.lastName = LPTranslate('Error_Last_Name_Required');
    } else if (formValues.lastName.length > 128) {
      isError = true;
      newInputErrors.lastName = LPTranslate('Error_Last_Name_Too_Long');
    }

    setFormErrors(newInputErrors);

    if (isError) return;

    try {
      await miniRegister({
        variables: {
          input: {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            email: formValues.email,
            role: 'client',
            type: 'mini_register_message',
            marketingAccepted: marketingAccepted,
          },
        },
      });
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    if (miniRegisterData && miniRegisterData.miniRegister) {
      if (miniRegisterData.miniRegister.status === '204') {
        setErrorMessage(miniRegisterData.miniRegister.message);
      } else if (miniRegisterData.miniRegister.jwtToken) {
        // mutatie set appointment

        callSendRequest(`Bearer ${miniRegisterData.miniRegister.jwtToken}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [miniRegisterData]);

  useEffect(() => {
    if (
      askLawyer &&
      askLawyer.askLawyerToSetSchedule.message === 'message arrived!'
    ) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'client-mini-register-request-appointment-custom-event',
      });
      setShownModal(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [askLawyer]);

  return (
    <>
      {showMiniregister && shownModal === 0 ? (
        <Box
          className={
            styles['send-message-to-lawyer-not-logged-name-modal-container']
          }
        >
          <Box
            className={
              styles['send-message-to-lawyer-not-logged-scroll-container']
            }
          >
            <form
              className={
                styles['send-message-to-lawyer-not-logged-name-modal-content']
              }
              id={'send-lawyer-not-logged-name-message-form'}
              onSubmit={handleSubmit}
            >
              <div
                className={
                  styles[
                    'send-message-to-lawyer-not-logged-name-modal-text-and-supporting-text'
                  ]
                }
              >
                <img
                  alt="arrow left"
                  data-cy="arrow-left"
                  src={arrowLeft}
                  onClick={(e) => handleBack(e)}
                  className={
                    styles[
                      'send-message-to-lawyer-not-logged-name-modal-arrow-left'
                    ]
                  }
                />

                <h2
                  className={
                    styles['send-message-to-lawyer-not-logged-name-modal-text']
                  }
                >
                  {LPTranslate('CSA_Request_Appointment_Miniregister_Title_1')}{' '}
                  {first_name} {last_name} {LPTranslate('USM_Title_2_Step_2')}
                </h2>
                <h3
                  className={
                    styles[
                      'send-message-to-lawyer-not-logged-name-modal-supporting-text'
                    ]
                  }
                >
                  {LPTranslate(
                    'CSA_Request_Appointment_Miniregister_Description_1'
                  )}{' '}
                  {first_name + ' ' + last_name}
                  {LPTranslate('USM_Description_2')}.
                </h3>
              </div>

              <div
                className={
                  styles[
                    'send-message-to-lawyer-not-logged-name-modal-avatar-label-group'
                  ]
                }
              >
                {profileImage !== null ? (
                  <img
                    alt="profile"
                    src={profileImage}
                    className={
                      styles['ask-lawyer-to-set-schedule-modal-profile-image']
                    }
                  />
                ) : (
                  <img
                    alt="avatar"
                    src={avatar}
                    className={
                      styles['ask-lawyer-to-set-schedule-modal-profile-image']
                    }
                  />
                )}
                <div
                  className={
                    styles[
                      'send-message-to-lawyer-not-logged-name-modal-name-and-email'
                    ]
                  }
                >
                  <h3
                    className={
                      styles[
                        'send-message-to-lawyer-not-logged-name-modal-name'
                      ]
                    }
                  >
                    {first_name} {last_name}
                  </h3>

                  <h3
                    className={
                      styles[
                        'send-message-to-lawyer-not-logged-name-modal-email'
                      ]
                    }
                  >
                    {email}
                  </h3>
                </div>
              </div>

              <div
                className={
                  styles[
                    'send-message-to-lawyer-not-logged-name-modal-first-name-input-field'
                  ]
                }
              >
                <SimpleTextField
                  data_cy="first-name-input"
                  title={LPTranslate('Input_First_Name')}
                  placeholder={LPTranslate('Input_First_Name_Placeholder')}
                  onChange={(value) => {
                    handleInputChange('firstName', value);
                    setFormErrors({
                      ...formErrors,
                      firstName: '',
                    });
                  }}
                  value={formValues.firstName}
                  type="text"
                  errorMessage={formErrors.firstName}
                />
              </div>

              <div
                className={
                  styles[
                    'send-message-to-lawyer-not-logged-name-modal-last-name-input-field'
                  ]
                }
              >
                <SimpleTextField
                  data_cy="last-name-input"
                  title={LPTranslate('Input_Last_Name')}
                  placeholder={LPTranslate('Input_Last_Name_Placeholder')}
                  onChange={(value) => {
                    handleInputChange('lastName', value);
                    setFormErrors({
                      ...formErrors,
                      lastName: '',
                    });
                  }}
                  value={formValues.lastName}
                  type="text"
                  errorMessage={formErrors.lastName}
                />
              </div>

              <div
                className={
                  styles[
                    'send-message-to-lawyer-not-logged-name-modal-email-input-field'
                  ]
                }
              >
                <SimpleTextField
                  data_cy="email-input"
                  title="Email*"
                  placeholder={LPTranslate('Common_Email_Placeholder')}
                  onChange={(value) => {
                    handleInputChange('email', value);
                    setFormErrors({
                      ...formErrors,
                      email: '',
                    });
                  }}
                  value={formValues.email}
                  type="text"
                  errorMessage={formErrors.email}
                />
              </div>

              <RegisterCheckboxComponent
                termsAccepted={termsAccepted}
                setTermsAccepted={setTermsAccepted}
                marketingAccepted={marketingAccepted}
                setMarketingAccepted={setMarketingAccepted}
              />
            </form>
            {errorMessage && (
              <p
                className={
                  styles[
                    'send-message-to-lawyer-not-logged-modal-error-message'
                  ]
                }
              >
                {errorMessage}
              </p>
            )}

            <div
              className={
                styles['send-message-to-lawyer-not-logged-name-modal-actions']
              }
            >
              <button
                data-cy="cancel-button"
                className={
                  styles[
                    'send-message-to-lawyer-not-logged-name-modal-cancel-button'
                  ]
                }
                onClick={props.handleClose}
              >
                {LPTranslate('Button_Cancel')}
              </button>

              {termsAccepted ? (
                <button
                  data-cy="submit-button"
                  className={
                    styles[
                      'send-message-to-lawyer-not-logged-modal-success-button'
                    ]
                  }
                  type="submit"
                  form="send-lawyer-not-logged-name-message-form"
                >
                  {/* {LPTranslate('LPP_Header_Send_Message')} */}
                  OK
                </button>
              ) : (
                <button
                  data-cy="submit-button"
                  disabled
                  className={
                    styles[
                      'send-message-to-lawyer-not-logged-modal-success-button-disabled'
                    ]
                  }
                  type="submit"
                  form="send-lawyer-not-logged-name-message-form"
                >
                  {/* {LPTranslate('LPP_Header_Send_Message')} */}
                  OK
                </button>
              )}
            </div>

            <div className={styles['row']}>
              <h3 className={styles['loginText']}>
                {LPTranslate('Common_Already_Have_Account_Question')}
              </h3>

              <div className={styles['loginButton']}>
                <button
                  className={styles['loginButtonBase']}
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  <h3 className={styles['loginButtonText']}>
                    {LPTranslate('Common_Login_Now')}
                  </h3>
                </button>
              </div>
            </div>

            <div className={styles['stepper-content']}>
              <div className={styles['check-step-icon-base']}>
                <div className={styles['checked-icon-content']}>
                  <img
                    className={styles['check-icon']}
                    src={check}
                    alt="checked"
                  />
                </div>
              </div>

              <div className={styles['step-icon-base']}>
                <div className={styles['selected-icon-content']}>
                  <div className={styles['selected-dot']} />
                </div>
              </div>
            </div>
          </Box>
        </Box>
      ) : (
        <UpdatePasswordConfirmationModal
          handleClose={handleClose}
          titleMessage={LPTranslate(
            'CSA_Request_Appointment_Miniregister_Successful_Title'
          )}
          hintMessage={LPTranslate(
            'CSA_Create_Appointment_Message_Successful_Description'
          )}
          buttonText={LPTranslate('Button_Close_Modal')}
        />
      )}
    </>
  );
};

export default RequestAppointmentMiniRegisterModal;
