const createUrlSlug = (title, first_name, last_name, location) => {
  const specialChars = /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/;
  const initialString =
    title + ' ' + first_name + ' ' + last_name + ' ' + location;

  const splited = initialString.split(specialChars);

  const filteredArray = splited.filter((str) => str.length > 0);
  const res = filteredArray.join('-');

  return res.toLowerCase();
};

const createMetaDataTitle = (
  title,
  first_name,
  last_name,
  location,
  salutation
) => {
  const specialChars = /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/;
  const initialString = `${
    salutation === 'Frau' ? 'Rechtsanwältin' : 'Rechtsanwalt'
  } ${first_name} ${last_name}`;

  const splited = initialString.split(specialChars);
  const filteredArray = splited.filter((str) => str.length > 0);

  const res = filteredArray.join(' ');

  return res;
};

const createMetaDataDescription = (
  areaOfLaw,
  first_name,
  last_name,
  location
) => {
  const description = `Von Anwado geprüfter Rechtsanwalt für ${areaOfLaw} in ${location} - ${first_name} ${last_name}`;
  return description;
};

export { createUrlSlug, createMetaDataDescription, createMetaDataTitle };
