import React from 'react';

import { useSelector } from 'react-redux';

import SettingsMenu from 'Components/CommonComponents/SettingsMenu';
import ReviewReportsTable from 'Components/Tables/ReviewReportsTable';

import 'Assets/styles/operator/OperatorSettingsPage.scss';

const OperatorSettingsPage = () => {
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  return (
    <>
      <h1>Operator Article Page</h1>

      <SettingsMenu
        setUpdateComponent={'/OperatorArticle'}
        role={clientInfoRedux.role}
      />

      <ReviewReportsTable />
    </>
  );
};

export default OperatorSettingsPage;
