import React, { useState } from 'react';
// pasul 5 si 3 trb si la verificarea codului sa activezi contul si sa faci legatura
// cand pui celalalt email trebuie afisat ca si hashed email in modal done

import { useMutation } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import SimpleTextField from 'Components/Inputs/SimpleTextField';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { validateEmail } from 'Services/Utils/validations';
import {
  CHANGE_USER_LAWYER_EMAIL,
  CHECK_IF_LAWYER_EXISTS,
} from 'Services/Queries/lawyer';
import {
  addUserEmail,
  addRoleEmail,
  setLawyerStepper,
} from 'Services/Redux/reducers/RegisterReducer';

import styles from 'Assets/styles/registerLawyer/NewLawyerEmail.module.scss';
import key from 'Assets/pictures/keyIcon.svg';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NewLawyerEmail = (props) => {
  const { hashEmail } = props;
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({
    email: '',
  });
  const [formErrors, setFormErrors] = useState({
    email: '',
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const snackbarSeverity = 'success';
  const snackbarMessage = false;

  const [changeEmail] = useMutation(CHANGE_USER_LAWYER_EMAIL);
  const [checkIfLawyerExists] = useMutation(CHECK_IF_LAWYER_EXISTS);

  const registerStepperRedux = useSelector(
    (state) => state.registerStepper.value
  );

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleInputChange = (inputName, value) => {
    switch (inputName) {
      case 'email':
        setFormValues({ ...formValues, email: value });
        break;

      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let isError = false;
      let newInputErrors = {};

      if (formValues.email.length === 0) {
        isError = true;
        newInputErrors.email = LPTranslate('Error_Email_Required');
      } else if (!validateEmail(formValues.email)) {
        isError = true;
        newInputErrors.email = LPTranslate('Error_Valid_Email');
      }

      let check = await checkIfLawyerExists({
        variables: {
          firstName: registerStepperRedux.firstName.trim(),
          lastName: registerStepperRedux.lastName.trim(),
          email: formValues.email.trim(),
        },
      });

      if (check.data.checkIfLawyerExists.message !== 'ok') {
        isError = true;
        newInputErrors.email = LPTranslate('Error_Already_Existing_Email');
      }

      setFormErrors(newInputErrors);

      if (isError) return;

      await changeEmail({
        variables: {
          input: {
            userEmail: registerStepperRedux.userEmail,
            temporaryEmail: registerStepperRedux.roleEmail,
            lawyer_id: registerStepperRedux.lawyerSelected,
            newUserEmail: formValues.email,
          },
        },
      });
      // hashed email
      hashEmail(formValues.email);
      dispatch(addUserEmail(formValues.email));
      dispatch(addRoleEmail(formValues.email));
      dispatch(setLawyerStepper(2));
    } catch (error) {
      console.log('eroare: ', error);
    }
  };

  return (
    <>
      <div className={styles.emailCheckerSection}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={
              snackbarSeverity === 'error' && {
                backgroundColor: '#7f56d9 !important',
              }
            }
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <div className={styles.emailCheckerContainer}>
          <div className={styles.frame2}>
            <div className={styles.content}>
              <div className={styles.header}>
                <div className={styles.featuredIcon}>
                  <div className={styles.key}>
                    <img src={key} alt="key" />
                  </div>
                </div>

                <div className={styles.textAndSupportingText}>
                  <p className={styles.text}>
                    {LPTranslate('RL_Stepper_5_Title')}
                  </p>

                  <p className={styles.supportingText}>
                    {LPTranslate('RL_Stepper_5_Description')}
                  </p>
                </div>
              </div>

              <form
                className={styles.formContent}
                id="setEmail-form"
                onSubmit={handleSubmit}
              >
                <div className={styles.emailInput}>
                  <div className={styles.inputWithLabel}>
                    <SimpleTextField
                      data_cy="email-address-input"
                      title="Email*"
                      placeholder={LPTranslate('Common_Email_Placeholder')}
                      onChange={(value) => {
                        handleInputChange('email', value);
                        setFormErrors({
                          ...formErrors,
                          email: '',
                        });
                      }}
                      value={formValues.email}
                      type="text"
                      errorMessage={formErrors.email}
                    />
                  </div>
                </div>

                <div className={styles.setEmailButtonContainer}>
                  <button
                    data-cy="submit-button"
                    className={styles.setEmailButtonBase}
                    type="submit"
                    form="setEmail-form"
                  >
                    <p className={styles.setEmailButtonText}>
                      {LPTranslate('Button_Next')}
                    </p>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewLawyerEmail;
