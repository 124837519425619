/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';

import { useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import { SEARCH_L } from 'Services/Queries/lawyer';
import SearchBarComponent from 'Layouts/searchBar/SearchBarComponent';
import LawyerCard from 'Components/Lawyer/LawyerCard';
import LawyerCardSkeleton from 'Components/Skeletons/LawyerCardSkeleton';
import Filters from 'Components/Lawyer/Filters';
import FiltersDrawer from 'Components/Lawyer/FiltersDrawer';
import LandingPageTopView from 'Components/LandingPageTopView';
import MetaDecorator from 'Layouts/MetaDecorator';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { fetchUserIP } from 'Services/Utils/apify';
import useAlert from 'Services/Utils/hooks/useAlert';

import leftArrow from 'Assets/pictures/leftArrow.svg';
import rightArrow from 'Assets/pictures/rightArrow.svg';

import styles from 'Assets/styles/user/UserClientPage.module.scss';

const LandingPage = () => {
  const { setAlert } = useAlert();

  const [firstSearch, setFirstSearch] = useState(true);

  const [, setSearchParams] = useSearchParams();
  const [resultsType, setResultsType] = useState('default');
  const [lawyers, setLawyers] = useState([]);

  const [areasOfLawBoxes, setAreasOfLawBoxes] = useState([]);

  const [searchInput, setSearchInput] = useState('');
  const [locationInput, setLocationInput] = useState('');
  const [locationDistanceInput, setLocationDistanceInput] = useState(0);

  const [filterCheckBoxes, setFilterCheckBoxes] = useState({
    sortBy: '',
    hasSpeciality: false,
    OnlineAppointmentBooking: false,
    minRating: 0,
    minReviews: 0,
  });

  const [searchData, setSearchData] = useState({
    searchInput: '',
    locationInput: '',
    locationDistanceInput: 0,
    areasOfLawBoxes: [],
  });

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);

  const location = useLocation();

  const firstUpdate = useRef(true);
  const backButtonPressed = useRef(false);
  const [firstParamSet, setFirstParamSet] = useState(false);

  const [lawyersDB, { loading, error, data }] = useLazyQuery(SEARCH_L);

  const areaOfLawChangeHandler = (areaOfLawID) => {
    let newAreasOfLaw = [...areasOfLawBoxes];

    if (newAreasOfLaw.includes(areaOfLawID)) {
      newAreasOfLaw = newAreasOfLaw.filter((item) => item !== areaOfLawID);
    } else {
      newAreasOfLaw.push(areaOfLawID);
    }

    setAreasOfLawBoxes(newAreasOfLaw);
  };

  const clearFilters = () => {
    setFilterCheckBoxes({
      sortBy: '',
      hasSpeciality: false,
      OnlineAppointmentBooking: false,
      minRating: 0,
      minReviews: 0,
    });
  };

  const checkInput = () => {
    let searchRelative;

    if (locationDistanceInput || filterCheckBoxes.sortBy === 'distance') {
      searchRelative = 'user';
    } else if (locationInput) {
      searchRelative = 'city';
    } else if (searchRelative === undefined) {
      searchRelative = '';
    }

    return searchRelative;
  };

  const moveWindowToTheFirstLawyerCard = () => {
    const topLandingPageSection = document.getElementById(
      'landing-page-top-view'
    );

    if (window.pageYOffset >= 0 && window.pageYOffset <= 200) {
      window.scrollTo({
        top: topLandingPageSection.offsetHeight,
        behavior: 'smooth',
      });
    }
  };

  const generateUrl = (searchRelative) => {
    let navigateUrl = `?`;

    if (searchInput) navigateUrl += `src=${searchInput}&`;

    if (locationInput) navigateUrl += `loc=${locationInput}&`;

    if (locationDistanceInput)
      navigateUrl += `srcdis=${locationDistanceInput}&`;

    if (areasOfLawBoxes.length > 0) navigateUrl += `aol=${areasOfLawBoxes}&`;

    if (searchRelative) navigateUrl += `searchRelative=${searchRelative}&`;

    if (
      filterCheckBoxes.sortBy !== '' ||
      filterCheckBoxes.hasSpeciality !== false ||
      filterCheckBoxes.OnlineAppointmentBooking !== false ||
      filterCheckBoxes.minRating !== 0 ||
      filterCheckBoxes.minReviews !== 0
    )
      navigateUrl += `filters=${JSON.stringify(filterCheckBoxes)}&`;

    if (page !== 1) navigateUrl += `page=${page}&`;

    navigateUrl = navigateUrl.slice(0, -1);

    return navigateUrl;
  };

  const [customLoading, setCustomLoading] = useState(true);

  const fetchLawyers = async () => {
    let searchRelative = checkInput();

    if (!firstSearch && !backButtonPressed.current) {
      let url = generateUrl(searchRelative);

      setSearchParams(url);
    }

    let ip = '0.0.0.0';

    if (searchRelative === 'user') ip = await fetchUserIP();

    await lawyersDB({
      variables: {
        input: {
          areaOfLawFilter: searchData.areasOfLawBoxes,
          distanceSearch: Number(searchData.locationDistanceInput),
          filterCheckBoxes: filterCheckBoxes,
          searchRelativeTo: searchRelative,
          cityFilter: searchData.locationInput,
          search: searchData.searchInput,
          page: page,
          pageLimit: 10,
          userIP: ip.ip,
        },
      },
    });

    backButtonPressed.current = false;
    setFirstSearch(false);
    setResultsType(searchRelative);
  };

  const handleSearchButton = () => {
    setSearchData({
      searchInput: searchInput,
      locationInput: locationInput,
      locationDistanceInput: locationDistanceInput,
      areasOfLawBoxes: areasOfLawBoxes,
    });

    if (!firstSearch) clearFilters();
  };

  useEffect(() => {
    if (!firstUpdate.current) {
      fetchLawyers();
    } else {
      firstUpdate.current = false;
    }
  }, [searchData]);

  useEffect(() => {
    if (
      Object.keys(filterCheckBoxes).length !== 0 &&
      !firstSearch &&
      !backButtonPressed.current
    ) {
      setPage(1);
      fetchLawyers();
    }
  }, [filterCheckBoxes]);

  useEffect(() => {
    if (lawyers.length !== 0 && !firstSearch) {
      fetchLawyers();
    }
  }, [page]);

  useEffect(() => {
    getDataFromParams(location.search);

    window.onpopstate = () => {
      backButtonPressed.current = true;
      setFirstParamSet(false);

      let params = window.location.href.substring(
        window.location.href.indexOf('?')
      );

      getDataFromParams(params);
    };
  }, []);

  const getDataFromParams = (params) => {
    const searchParams = new URLSearchParams(params);

    if (searchParams.get('src') !== null) {
      setSearchInput(searchParams.get('src'));
    }

    if (searchParams.get('loc') !== null)
      setLocationInput(searchParams.get('loc'));

    if (searchParams.get('srcdis') !== null) {
      setLocationDistanceInput(Number(searchParams.get('srcdis')));
    }

    if (searchParams.get('aol') !== null) {
      let filters = searchParams.get('aol');

      if (filters === '') {
        filters = [];
      } else {
        filters = filters.split(',').map((filter) => Number(filter));
      }

      setAreasOfLawBoxes(filters);
    } else {
      setAreasOfLawBoxes([]);
    }

    if (searchParams.get('filters') !== null) {
      setFilterCheckBoxes(JSON.parse(searchParams.get('filters')));
    } else {
      setFilterCheckBoxes({
        sortBy: '',
        hasSpeciality: false,
        OnlineAppointmentBooking: false,
        minRating: 0,
        minReviews: 0,
      });
    }

    if (searchParams.get('page') !== null)
      setPage(Number(searchParams.get('page')));

    setFirstParamSet(true);
  };

  useEffect(() => {
    if (firstParamSet) handleSearchButton();
  }, [firstParamSet]);

  useEffect(() => {
    if (data && data.searchL && data.searchL.lawyers) {
      setLawyers(data.searchL.lawyers);
      setPageCount(data.searchL.number_of_pages);

      if (data.searchL.lawyers.length > 0) {
        moveWindowToTheFirstLawyerCard();
      }

      setCustomLoading(false);
    } else {
      setCustomLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (error !== undefined && error !== null) {
      setAlert(LPTranslate('Error_Server_Down'));

      setCustomLoading(false);
    }
  }, [error]);

  const handlePageChange = (value) => {
    if (value >= 1 && value <= pageCount) {
      setPage(value);
    }
  };

  const handleEnterKeyPress = (event) => {
    handleSearchButton();
  };

  return (
    <div className={styles['lawp-landing-page-container']}>
      <MetaDecorator
        title={LPTranslate('LP_Info_1_Meta_Title_1')}
        description={LPTranslate('LP_Info_1_Meta_Description_1')}
        canonical={'canonical'}
        link={`${process.env.REACT_APP_DNS_URI}/suche`}
      />

      <LandingPageTopView lawyerLoading={loading} lawyerError={error} />

      <SearchBarComponent
        updateLawyers={handleSearchButton}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        locationInput={locationInput}
        setLocationInput={setLocationInput}
        locationDistanceInput={locationDistanceInput}
        onChangeLocationDistanceSlider={setLocationDistanceInput}
        areasOfLawBoxes={areasOfLawBoxes}
        onChangeAreasOfLaw={areaOfLawChangeHandler}
        onEnterKeyPress={handleEnterKeyPress}
      />

      <div id="lawyerSearchContainer" className={styles.lawyerSearchContainer}>
        <div className={styles.lawyerFilterContainer}>
          <Filters
            lawyerLoading={loading}
            lawyerError={error}
            filters={filterCheckBoxes}
            setFilterCheckBoxes={setFilterCheckBoxes}
            clearFilters={clearFilters}
            resultsType={resultsType}
            removeClearFilterButtonIfNoFilters={true}
          />
        </div>

        <div className={styles.lawyerCardsContainer}>
          <div className={styles.hamburgerMenu}>
            <FiltersDrawer
              filters={filterCheckBoxes}
              setFilterCheckBoxes={setFilterCheckBoxes}
              clearFilters={clearFilters}
              resultsType={resultsType}
              removeClearFilterButtonIfNoFilters={true}
            />
          </div>

          <div className={styles.lawyerCards}>
            <div className={styles.lawyerCardsResultsContainer}>
              {loading || customLoading ? (
                Array.from(new Array(10)).map((value, key) => (
                  <LawyerCardSkeleton key={key} />
                ))
              ) : lawyers.length > 0 ? (
                lawyers.map((lawyer, key) => (
                  <LawyerCard key={lawyer.id} lawyer={lawyer} />
                ))
              ) : (
                <p
                  className={styles['lawp-article-blog-page-no-lawyers-text']}
                  data-cy="no-lawyers-found"
                >
                  {LPTranslate('LLP_No_Lawyers_Found')}
                </p>
              )}
            </div>

            {lawyers.length > 0 && pageCount > 1 && (
              <div
                className={
                  styles['lawp-article-blog-page-articles-pagination-container']
                }
              >
                <Button
                  disableRipple
                  data-cy="back-button"
                  disabled={page === 1}
                  className={styles['lawp-article-blog-page-left-arrow-button']}
                  startIcon={
                    page === 1 ? null : <img src={leftArrow} alt="leftArrow" />
                  }
                  onClick={() => {
                    handlePageChange(page - 1);
                  }}
                >
                  {page === 1 ? '' : LPTranslate('Button_Back')}
                </Button>

                <Stack className="lawp-article-blog-page-pagination-container">
                  <Pagination
                    className="lawp-article-blog-page-pagination"
                    count={pageCount}
                    page={page}
                    onChange={(event, value) => {
                      handlePageChange(value);
                    }}
                    //display 10 pages at a time
                  />
                  {/* {page < 3 && pageCount > page + 2 && (
                    <p
                      style={{
                        fontSize: '12px',
                        color: 'black',
                        fontWeight: 'lighter',
                        fontFamily: 'Roboto',
                        marginTop: '16px',
                        letterSpacing: '1.2px',
                      }}
                    >
                      ...
                    </p>
                  )} */}
                </Stack>

                <Button
                  disableRipple
                  data-cy="next-button"
                  disabled={page === pageCount}
                  className={styles['lawp-article-blog-page-left-arrow-button']}
                  endIcon={
                    page === pageCount ? null : (
                      <img src={rightArrow} alt="leftArrow" />
                    )
                  }
                  onClick={() => handlePageChange(page + 1)}
                >
                  {page === pageCount ? '' : LPTranslate('Button_Next')}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
