import React, { useState, useEffect } from 'react';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import NorthEastIcon from '@mui/icons-material/NorthEast';

import SendMessageToLawyerNotLoggedNameModal from 'Components/Modals/SendMessageToLawyerNotLoggedNameModal';
import SendMessageToLawyerNotLoggedBox from 'Components/Client/Settings/SendMessageToLawyer/SendMessageToLawyerNotLoggedBox';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/lawyer/SendMessageToLawyerNotLoggedModal.module.scss';

const SendMessageToLawyerNotLoggedModal = (props) => {
  let { lawyer, client, openMessage } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity] = useState('error');
  const [snackbarMessage] = useState(false);

  const [formValues, setFormValues] = useState({
    description: '',
    firstName: '',
    lastName: '',
    email: '',
  });
  const [formErrors, setFormErrors] = useState({
    description: '',
    firstName: '',
    lastName: '',
    email: '',
  });

  const handleInputChange = (inputName, value) => {
    switch (inputName) {
      case 'description':
        setFormValues({ ...formValues, description: value });
        break;
      case 'firstName':
        setFormValues({ ...formValues, firstName: value });
        break;
      case 'lastName':
        setFormValues({ ...formValues, lastName: value });
        break;
      case 'email':
        setFormValues({ ...formValues, email: value });
        break;

      default:
        break;
    }
  };

  const [articleImage, setArticleImage] = useState(null);
  const [openSecondModal, setOpenSecondModal] = useState(false);

  const [shownModal, setShownModal] = useState(0);

  const resetState = () => {
    setFormValues({ description: '', firstName: '', lastName: '', email: '' });
    setFormErrors({ description: '', firstName: '', lastName: '', email: '' });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (lawyer.profileImage) {
      setArticleImage(lawyer.profileImage);
    } else {
      setArticleImage(null);
    }
    return () => {
      setArticleImage(null);
    };
  }, [lawyer.profileImage]);

  const handleClose = () => {
    setShownModal(0);
    setOpen(false);
    resetState();
  };

  const handleCloseModal = (modalNr) => {
    setShownModal(modalNr);
  };

  const changeModal = (nr) => {
    setShownModal(nr);
  };

  const sendMessage = async (e) => {
    e.preventDefault();

    let isError = false;
    let newInputErrors = {};

    if (formValues.description.length === 0) {
      isError = true;
      newInputErrors.description = LPTranslate('Error_Email_Required');
    }

    setFormErrors(newInputErrors);

    if (isError) return;

    changeModal(1);
  };

  return (
    <>
      {openMessage ? (
        <Button
          // data-cy={'send-message-button'}
          style={{
            margin: '0px 0px',
            color: '#344054',
            borderRadius: '8px',
            border: '1px solid #D0D5DD',
            boxSizing: 'border-box',
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            background: '#FFFFFF',
            textTransform: 'none',
          }}
          variant="text"
          onClick={handleOpen}
          data-cy="send-message-to-lawyer"
        >
          {LPTranslate('LPP_Header_Send_Message')}
        </Button>
      ) : (
        <button
          data-cy={'send-message-button'}
          className={
            styles[
              'send-message-to-lawyer-not-logged-modal-lawyer-email-button'
            ]
          }
          onClick={handleOpen}
        >
          {lawyer.email}
          <NorthEastIcon transform="scale(0.7)" />
        </button>
      )}

      <Modal
        className={styles['send-message-to-lawyer-not-logged-modal']}
        open={open}
        onClose={handleClose}
      >
        <Box>
          {shownModal === 0 ? (
            <SendMessageToLawyerNotLoggedBox
              lawyer={lawyer}
              articleImage={articleImage}
              formValues={formValues}
              formErrors={formErrors}
              handleClose={handleClose}
              sendMessage={sendMessage}
              handleCloseSnackbar={handleCloseSnackbar}
              snackbarSeverity={snackbarSeverity}
              snackbarOpen={snackbarOpen}
              snackbarMessage={snackbarMessage}
              handleInputChange={handleInputChange}
              setFormErrors={setFormErrors}
            />
          ) : (
            <SendMessageToLawyerNotLoggedNameModal
              shownModal={shownModal}
              client={client}
              openNotLoggedSecondModal={openSecondModal}
              setOpenNotLoggedSecondModal={setOpenSecondModal}
              handleClose={handleClose}
              description={formValues.description}
              lawyer={lawyer}
              lawyer_name={lawyer.first_name + ' ' + lawyer.last_name}
              handleCloseModal={handleCloseModal}
              changeModal={changeModal}
              formValues={formValues}
              formErrors={formErrors}
              handleInputChange={handleInputChange}
              setFormErrors={setFormErrors}
            />
          )}
        </Box>
      </Modal>
    </>
  );
};
export default SendMessageToLawyerNotLoggedModal;
