import React, { useEffect, useState } from 'react';
import xml2js from 'xml2js';

import { useQuery } from '@apollo/client';
import { GET_SITEMAP } from '../Services/Queries/user';

const SitemapLawyerPage = () => {
  const [sitemapData, setSitemapData] = useState('');

  const { loading, data } = useQuery(GET_SITEMAP, {
    variables: {
      type: 'lawyers',
    },
  });

  useEffect(() => {
    if (data && data.sitemap) {
      const parser = new xml2js.Parser({
        explicitArray: false, // Set this option to false to parse XML with properties as direct values
        preserveChildrenOrder: true, // Preserve the order of XML children
      });
      parser.parseString(data.sitemap.xml, function (err, result) {
        setSitemapData(result);
      });
    }
  }, [data]);

  const formatXml = (xml) => {
    if (typeof xml === 'string') {
      return xml; // Return the XML string if already formatted
    }
    const builder = new xml2js.Builder({
      renderOpts: { pretty: true }, // Set the pretty option to format the XML with indentation
    });
    const formattedXml = builder.buildObject(xml);
    return formattedXml;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <pre>{formatXml(sitemapData)}</pre>
    </div>
  );
};

export default SitemapLawyerPage;
