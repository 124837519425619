import React, { useRef, useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';

import 'Assets/styles/lawyer/HourInterval.scss';

const CustomTextFieldLeft = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    position: 'relative',
    border: '1px solid #D0D5DD',
    fontSize: 16,
    height: 24,
    borderRight: 'none',
    borderRadius: '6px 0px 0px 6px',
    borderColor: '#9E77ED',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    fontFamily: ['Inter'].join(','),
  },
}));

const CustomTextFieldRight = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    position: 'relative',
    border: '1px solid #D0D5DD',
    fontSize: 16,
    height: 24,
    borderLeft: 'none',
    borderRadius: '0px 6px 6px 0px',
    borderColor: '#9E77ED',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    fontFamily: ['Inter'].join(','),
  },
}));

export default function HourInterval(props) {
  const { interval, onChange } = props;
  const [hourStart, setHourStart] = useState(['', '']);
  const [hourEnd, setHourEnd] = useState(['', '']);
  const [minuteStart, setMinuteStart] = useState('00');
  const [minuteEnd, setMinuteEnd] = useState('00');

  const hourStartSecondInputRef = useRef(null);
  const hourEndFirstInputRef = useRef(null);
  const hourEndSecondInputRef = useRef(null);

  useEffect(() => {
    if (interval) {
      if (interval.start[0] !== '') {
        setHourStart(interval.start.split(':')[0]);
        setMinuteStart(interval.start.split(':')[1]);
      } else {
        setHourStart(['', '']);
      }

      if (interval.end[0] !== '') {
        setHourEnd(interval.end.split(':')[0]);
        setMinuteEnd(interval.end.split(':')[1]);
      } else {
        setHourEnd(['', '']);
      }
    }
  }, [interval]);

  const onChangeHours = (newValue, index) => {
    let value;

    switch (index) {
      case 0:
        value = hourStart[0];
        break;
      case 1:
        value = hourStart[1];
        break;
      case 2:
        value = hourEnd[0];
        break;
      case 3:
        value = hourEnd[1];
        break;
      default:
        break;
    }

    if (newValue === '') {
      value = '';
    } else if (newValue.length === 1) {
      value = newValue;
    } else {
      value = newValue[1] === value ? newValue[0] : newValue[1];
    }

    if (!value.match(/^[0-9]$/) && value !== '') {
      return;
    }

    switch (index) {
      case 0:
        if (value >= 0 && value <= 2) {
          if (Number(value) === 2 && Number(hourStart[1]) > 3) {
            setHourStart([value, '0']);
          } else {
            setHourStart([value, hourStart[1]]);
          }
          hourStartSecondInputRef.current.focus();
        }
        break;
      case 1:
        if (hourStart[0] >= 0 && hourStart[0] <= 1) {
          setHourStart([hourStart[0], value]);
          hourEndFirstInputRef.current.focus();
        } else {
          if ((value >= 0 && value <= 3) || value === '') {
            setHourStart([hourStart[0], value]);
            hourEndFirstInputRef.current.focus();
          }
        }
        break;
      case 2:
        if (value >= 0 && value <= 2) {
          if (Number(value) === 2 && Number(hourEnd[1]) > 3) {
            setHourEnd([value, '0']);
          } else {
            setHourEnd([value, hourEnd[1]]);
          }
          hourEndSecondInputRef.current.focus();
        }
        break;
      case 3:
        if (hourEnd[0] >= 0 && hourEnd[0] <= 1) {
          setHourEnd([hourEnd[0], value]);
        } else {
          if ((value >= 0 && value <= 3) || value === '') {
            setHourEnd([hourEnd[0], value]);
          }
        }
        break;
      default:
        break;
    }
  };

  const onChangeMinutes = (index) => {
    switch (index) {
      case 0:
        if (minuteStart === '00') setMinuteStart('30');
        else if (minuteStart === '30') setMinuteStart('00');
        break;
      case 1:
        if (minuteEnd === '00') setMinuteEnd('30');
        else if (minuteEnd === '30') setMinuteEnd('00');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let newInterval;

    if (
      hourStart[0] !== '' &&
      hourStart[1] !== '' &&
      hourEnd[0] !== '' &&
      hourEnd[1] !== ''
    ) {
      newInterval = {
        start: `${hourStart[0]}${hourStart[1]}:${minuteStart}`,
        end: `${hourEnd[0]}${hourEnd[1]}:${minuteEnd}`,
      };

      onChange(newInterval);
    }
  }, [hourStart, hourEnd, minuteStart, minuteEnd, onChange]);

  return (
    <div className="lawp-hour-interval-container">
      <div>
        <p className="lawp-hour-interval-title"> Start </p>
        <div className="lawp-hour-interval-textfields-container">
          <div className="lawp-hour-interval-textfield-container">
            <FormControl
              sx={{ position: 'relative', left: '1px' }}
              variant="standard"
            >
              <CustomTextFieldLeft
                data-cy="hour-start-tens-digit-input"
                inputProps={{ style: { textAlign: 'center' } }}
                value={hourStart[0]}
                onChange={(e) => onChangeHours(e.target.value, 0)}
              />
            </FormControl>

            <div className="lawp-hour-interval-textfield-divider-line"></div>

            <FormControl
              sx={{ position: 'relative', right: '1px' }}
              variant="standard"
            >
              <CustomTextFieldRight
                data-cy="hour-start-number-of-units-input"
                inputProps={{ style: { textAlign: 'center' } }}
                value={hourStart[1]}
                onChange={(e) => onChangeHours(e.target.value, 1)}
                inputRef={hourStartSecondInputRef}
              />
            </FormControl>
          </div>

          <div className="lawp-hour-interval-textfield-divider-double-dots">
            <p> : </p>
          </div>

          <div className="lawp-hour-interval-fixed-time">
            <Button
              data-cy="minute-start-button"
              className="lawp-hour-interval-minutes-button"
              onClick={() => onChangeMinutes(0)}
            >
              {' '}
              {minuteStart}{' '}
            </Button>
          </div>
        </div>
      </div>

      <div className="lawp-hour-interval-hours-divider-line">
        <p> - </p>
      </div>

      <div>
        <p className="lawp-hour-interval-title"> End </p>
        <div className="lawp-hour-interval-textfields-container">
          <div className="lawp-hour-interval-textfield-container">
            <FormControl
              sx={{ position: 'relative', left: '1px' }}
              variant="standard"
            >
              <CustomTextFieldLeft
                data-cy="hour-end-tens-digit-input"
                inputProps={{ style: { textAlign: 'center' } }}
                value={hourEnd[0]}
                onChange={(e) => onChangeHours(e.target.value, 2)}
                inputRef={hourEndFirstInputRef}
              />
            </FormControl>

            <div className="lawp-hour-interval-textfield-divider-line"></div>

            <FormControl
              sx={{ position: 'relative', right: '1px' }}
              variant="standard"
            >
              <CustomTextFieldRight
                data-cy="hour-end-number-of-units-input"
                inputProps={{ style: { textAlign: 'center' } }}
                value={hourEnd[1]}
                onChange={(e) => onChangeHours(e.target.value, 3)}
                inputRef={hourEndSecondInputRef}
              />
            </FormControl>
          </div>

          <div className="lawp-hour-interval-textfield-divider-double-dots">
            <p> : </p>
          </div>

          <div className="lawp-hour-interval-fixed-time">
            <Button
              data-cy="minute-end-button"
              className="lawp-hour-interval-minutes-button"
              onClick={() => onChangeMinutes(1)}
            >
              {' '}
              {minuteEnd}{' '}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
