const translations = {
  Login_Invalid_Email_Or_Password: {
    en: 'Email or password are invalid!',
    de: 'E-Mail oder Passwort sind ungültig!',
    ro: 'E-mailul sau parola sunt invalide!',
  },
  Login_Account_Not_Verified: {
    en: 'Account has not been verified yet!',
    de: 'Das Konto wurde noch nicht verifiziert!',
    ro: 'Contul nu a fost încă verificat!',
  },
  Login_Use_Forgot_Password: {
    en: 'Use the forgot password option to set a new password!',
    de: 'Verwenden Sie die Option Passwort vergessen, um ein neues Passwort zu setzen!',
    ro: 'Utilizați opțiunea de parolă uitată pentru a seta o nouă parolă!',
  },

  // Anwado STATIC INFO
  Anwado_Minute: {
    en: 'minute',
    de: 'Minute',
    ro: 'Minute',
  },
  Anwado_Minutes: {
    en: 'minute',
    de: 'Minuten',
    ro: 'Proces-verbal',
  },
  Anwado_hour: {
    en: 'hour',
    de: 'Stunde',
    ro: 'Ora',
  },
  Anwado_hours: {
    en: 'hours',
    de: 'Stunden',
    ro: 'Ore',
  },
  Anwado_day: {
    en: 'day',
    de: 'Tag',
    ro: 'Ziua',
  },
  Anwado_days: {
    en: 'days',
    de: 'Tagen',
    ro: 'Zile',
  },
  Anwado_week: {
    en: 'week',
    de: 'Woche',
    ro: 'Săptămâna',
  },
  Anwado_weeks: {
    en: 'weeks',
    de: 'Wochen',
    ro: 'Săptămâni',
  },
  Anwado_month: {
    en: 'month',
    de: 'Monat',
    ro: 'Luna',
  },
  Anwado_months: {
    en: 'months',
    de: 'Monat',
    ro: 'Luna',
  },
  Anwado_year: {
    en: 'year',
    de: 'Jahr',
    ro: 'Anul',
  },
  Anwado_years: {
    en: 'years',
    de: 'Jahren',
    ro: 'Ani',
  },
  Anwado_Ago_1: {
    en: '',
    de: 'vor',
    ro: 'de la',
  },
  Anwado_Ago_2: {
    en: 'ago',
    de: '',
  },
  Anwado_Today: {
    en: 'Today',
    de: 'Heute',
    ro: 'Astăzi',
  },
  Anwado_January: {
    en: 'January',
    de: 'Januar',
    ro: 'Ianuarie',
  },
  Anwado_February: {
    en: 'February',
    de: 'Februar',
    ro: 'Februarie',
  },
  Anwado_March: {
    en: 'March',
    de: 'März',
    ro: 'Martie',
  },
  Anwado_April: {
    en: 'April',
    de: 'April',
    ro: 'Aprilie',
  },
  Anwado_May: {
    en: 'May',
    de: 'Mai',
    ro: 'Mai',
  },
  Anwado_June: {
    en: 'June',
    de: 'Juni',
    ro: 'Iunie',
  },
  Anwado_July: {
    en: 'July',
    de: 'Juli',
    ro: 'Iulie',
  },
  Anwado_August: {
    en: 'August',
    de: 'August',
    ro: 'August',
  },
  Anwado_September: {
    en: 'September',
    de: 'September',
    ro: 'Septembrie',
  },
  Anwado_October: {
    en: 'October',
    de: 'Oktober',
    ro: 'Octombrie',
  },
  Anwado_November: {
    en: 'November',
    de: 'November',
    ro: 'Noiembrie',
  },
  Anwado_December: {
    en: 'December',
    de: 'Dezember',
    ro: 'Decembrie',
  },
  Anwado_Name: {
    en: 'Anwado',
    de: 'Anwado',
    ro: 'Anwado',
  },
  Anwado_Street: {
    en: 'Max street 15',
    de: 'Maxstraße 15',
    ro: 'Maxstraße 15',
  },
  Anwado_Poscode_City: {
    en: '079463 Maxstadt',
    de: '079463 Maxstadt',
    ro: '079463 Maxstadt',
  },
  Anwado_Country: {
    en: 'Germany',
    de: 'Deutschland ',
    ro: 'Germania',
  },
  Anwado_Phonenumber_Program: {
    en: 'Phone: 060 866 97 674 (Mon - Fri 10:00 - 18:00)',
    de: 'Telefon: 060 866 97 674 (Mo - Fr 10:00 - 18:00 Uhr)',
    ro: 'Telefon: 060 866 97 674 (luni - vineri 10:00 - 18:00)',
  },
  Anwado_Email: {
    en: 'E-mail: support@Anwado.de',
    de: 'E-Mail: support@Anwado.de',
    ro: 'E-mail: support@Anwado.de',
  },
  Anwado_About_Us: {
    en: 'About us',
    de: 'Über uns',
    ro: 'Despre noi',
  },
  Anwado_Our_Values: {
    en: 'Our values',
    de: 'Unsere Werte',
    ro: 'Valorile noastre',
  },
  Anwado_Monday: {
    en: 'Monday',
    de: 'Montag',
    ro: 'Luni',
  },
  Anwado_Tuesday: {
    en: 'Tuesday',
    de: 'Dienstag',
    ro: 'Marți',
  },
  Anwado_Wednesday: {
    en: 'Wednesday',
    de: 'Mittwoch',
    ro: 'Miercuri',
  },
  Anwado_Thursday: {
    en: 'Thursday',
    de: 'Donnerstag',
    ro: 'Joi',
  },
  Anwado_Friday: {
    en: 'Friday',
    de: 'Freitag',
    ro: 'Vineri',
  },
  Anwado_Saturday: {
    en: 'Saturday',
    de: 'Samstag',
    ro: 'Sâmbătă',
  },
  Anwado_Sunday: {
    en: 'Sunday',
    de: 'Sonntag',
    ro: 'Duminică',
  },
  Anwado_Basic_Plan: {
    en: 'Silver membership',
    de: 'Silber Mitgliedschaft',
    ro: 'Membru argint',
  },
  Anwado_Gold_Plan: {
    en: 'Gold membership',
    de: 'Gold Mitgliedschaft',
    ro: 'Membru Gold',
  },
  Anwado_Platin_Plan: {
    en: 'Platinum membership',
    de: 'Platin Mitgliedschaft',
    ro: 'Membru Platinum',
  },
  Anwado_Any_Questions: {
    en: 'Any questions?',
    de: 'Noch Fragen?',
    ro: 'Aveți întrebări?',
  },
  Anwado_Any_Questions_Description: {
    en: 'Do you have any questions or suggestions? We are looking forward to your message. Please use our contact form and we will get back to you as soon as possible.',
    de: 'Haben Sie noch Fragen oder Anregungen? Wir freuen uns über Ihre Nachricht. Bitte nutzen Sie unser Kontaktformular und wir werden und so schnell wie möglich bei Ihnen melden.',
    ro: 'Aveți întrebări sau sugestii? Așteptăm cu nerăbdare să primim mesajul dumneavoastră. Vă rugăm să folosiți formularul nostru de contact și vă vom răspunde în cel mai scurt timp posibil.',
  },
  // Anwado_Join_And_Offer_Services: {
  //  en: 'Join 65,000 lawyers and benefit from the Anwado Community as effective law firm marketing.',
  //  de: 'Schließen Sie Sich 65.000 Anwälten an und profitieren Sie von der Anwado Community als effektives Kanzleimarketing.',
  //  ro: 'Alăturați-vă celor 65.000 de avocați și beneficiați de comunitatea Anwado ca marketing eficient al firmei de avocatură.',
  // },
  // NAVBAR
  Navbar_Settings: {
    en: 'Settings',
    de: 'Einstellungen',
    ro: 'Setări',
  },
  Navbar_Public_Profile: {
    en: 'View public profile',
    de: 'Öffentliches Profil anzeigen',
    ro: 'Arată profilul public',
  },
  Navbar_Logout: {
    en: 'Log out',
    de: 'Abmelden',
    ro: 'Deconectați-vă',
  },
  // ERRORS
  Error_Could_Not_Delete_Lawyer: {
    en: 'Could not delete lawyer',
    de: 'Anwalt konnte nicht gelöscht werden',
    ro: 'Nu s-a putut șterge avocatul',
  },
  Error_Fill_All_Fields: {
    en: 'Please fill in all fields',
    de: 'Bitte füllen Sie alle Felder aus',
    ro: 'Vă rugăm să completați toate câmpurile',
  },
  Error_404: {
    en: 'Sorry, the page could not be found - error code 404',
    de: 'Die Seite konnte leider nicht gefunden werden – Fehlercode 404',
    ro: 'Ne pare rău, pagina nu a putut fi găsită - cod de eroare 404',
  },
  Error_Invalid_Token: {
    en: 'Invalid Token',
    de: 'Token ungültig',
    ro: 'Token invalid',
  },
  Error_Only_One_Article_Home_Page: {
    en: 'Only one article can be displayed',
    de: 'Es kann nur ein Artikel angezeigt werden',
    ro: 'Se poate afișa doar un singur element',
  },
  Error_Lawyer_Cannot_Create_Appointment: {
    en: `You can't set an appointment as lawyer to yourself or others`,
    de: 'Sie können weder für sich noch für andere einen Termin als Anwalt vereinbaren',
    ro: 'Nu puteți face o programare ca avocat pentru dumneavoastră sau pentru alții',
  },
  Error_Lawyer_Cannot_Create_Reviews: {
    en: `You can't add reviews as lawyer to yourself or others`,
    de: 'Sie können keine Bewertungen als Anwalt für sich selbst oder andere hinzufügen',
    ro: 'Nu puteți adăuga recenzii în calitate de avocat pentru dvs. sau pentru alții',
  },
  Error_Lawyer_Cannot_Send_Message: {
    en: `You can't send a message to yourself`,
    de: 'Du kannst dir selbst keine Nachricht schicken',
    ro: 'Nu poți să-ți trimiți singur un mesaj',
  },
  Error_No_Articles_Home_Page: {
    en: '',
    de: '',
  },
  Error_No_Reviews: {
    en: 'No reviews!',
    de: 'keine Bewertungen!',
    ro: 'fără ratinguri!',
  },
  Error_User_Not_Logged_In: {
    en: 'You have to be logged in first...',
    de: 'Sie müssen zuerst eingeloggt sein...',
    ro: 'Trebuie să vă conectați mai întâi...',
  },
  Error_Password_Min_Length: {
    en: 'Must consist of at least 8 characters*.',
    de: 'Muss mindestens aus 8 Zeichen bestehen*',
    ro: 'Trebuie să fie format din cel puțin 8 caractere*.',
  },
  Error_Phone_Number_Required: {
    en: 'Phone number required',
    de: 'Telefonnummer erforderlich*',
    ro: 'Număr de telefon necesar*',
  },
  Error_Confirm_Password_Required: {
    en: 'Confirm password required*',
    de: 'Passwortbestätigung erforderlich*',
    ro: 'Este necesară confirmarea parolei*',
  },
  Error_Passwords_Dont_Match: {
    en: 'Passwords do not match*',
    de: 'Passwörter stimmen nicht überein*',
    ro: 'Parolele nu se potrivesc*',
  },
  Error_Set_Meeting_User_Already_Exists: {
    en: 'Please log in first and then create an appointment!',
    de: 'Bitte loggen Sie sich zuerst ein und erstellen Sie dann einen Termin!',
    ro: 'Vă rugăm să vă conectați mai întâi și apoi să creați o programare!',
  },
  Error_Meeting_Title_Required: {
    en: 'Topic required*',
    de: 'Thema erforderlich*',
    ro: 'Temă necesară*',
  },
  Error_Metting_Type_Required: {
    en: 'Appointment type required*',
    de: 'Terminart erforderlich*',
    ro: 'Tipul programării este necesar*',
  },
  Error_Meeting_Message_Required: {
    en: 'Message required*',
    de: 'Nachricht erforderlich*',
    ro: 'Mesaj necesar*',
  },
  Error_Invalid_Input: {
    en: 'An input is invalid. Please correct your entries and try again.',
    de: 'Eine Eingabe ist ungültig. Bitte korrigieren Sie Ihre Eingaben und versuchen Sie es erneut.',
    ro: 'O intrare nu este valabilă. Vă rugăm să vă corectați intrările și să încercați din nou.',
  },
  Error_Description_Required: {
    en: 'Description required*',
    de: 'Beschreibung erforderlich*',
    ro: 'Descriere necesară*',
  },
  Error_Image_Required: {
    en: 'Image required*',
    de: 'Bild erforderlich*',
    ro: 'Imagine necesară*',
  },
  Error_Actual_Password_Min_Length: {
    en: 'The current password must be at least 8 characters long*.',
    de: 'Das aktuelle Passwort muss mindestens 8 Zeichen lang sein*',
    ro: 'Parola curentă trebuie să aibă cel puțin 8 caractere*.',
  },
  Error_Actual_Password_Required: {
    en: 'Current password required*',
    de: 'Aktuelles Passwort erforderlichd*',
    ro: 'Este necesară parola curentă*',
  },
  Error_Password_Required: {
    en: 'Password required*',
    de: 'Passwort erforderlich*',
    ro: 'Parolă necesară*',
  },
  Error_Resend_Mail_To_Verify_On_Register: {
    en: 'The email with your new verification code has been sent successfully.',
    de: 'Die E-Mail mit Ihrem neuen Verifizierungscode wurde erfolgreich versendet.',
    ro: 'E-mailul cu noul cod de verificare a fost trimis cu succes.',
  },
  Error_OTP_Required: {
    en: 'You have to send the code from email before getting to the next step',
    de: 'Sie müssen den Code per E-Mail senden, bevor Sie zum nächsten Schritt kommen',
    ro: 'Trebuie să trimiteți codul prin e-mail înainte de a putea trece la pasul următor.',
  },
  Error_No_Future_Meetings: {
    en: `You don't have future meetings.`,
    de: 'Sie haben keine zukünftigen Termine.',
    ro: 'Nu aveți nicio programare viitoare.',
  },
  Error_No_Past_Meetings: {
    en: `You don't have past meetings`,
    de: 'Sie haben keine vergangenen Treffen',
    ro: 'Nu aveți întâlniri anterioare',
  },
  Error_Favorites_Empty_List: {
    en: `There are no favorite lawyers in the list`,
    de: 'Es gibt keine Lieblingsanwälte in der Liste',
    ro: 'Nu există avocați favoriți în listă',
  },
  Error_Password_Required_Min_8_Characters: {
    en: 'Password must be minimum 8 chars*',
    de: 'Das Passwort muss mindestens 8 Zeichen lang sein*.',
    ro: 'Parola trebuie să aibă cel puțin 8 caractere*.',
  },
  Error_Password_Invalid: {
    en: 'Password is invalid!',
    de: 'Das Passwort ist ungültig!',
    ro: 'Parola este invalidă!',
  },
  Error_Email_Invalid: {
    en: 'Email is invalid!',
    de: 'Das Email ist ungültig!',
    ro: 'E-mailul este invalid!',
  },
  Error_Confirmation_Password_Required: {
    en: 'Password confirmation required*',
    de: 'Passwortbestätigung erforderlich*',
    ro: 'Este necesară confirmarea parolei*',
  },
  Error_Password_Must_Match: {
    en: 'Passwords must match.',
    de: 'Passwörter müssen übereinstimmen.',
    ro: 'Parolele trebuie să se potrivească.',
  },
  Error_Server_Down: {
    en: 'Something went wrong. Please try again later...',
    de: 'Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal...',
    ro: 'Ceva a mers prost. Vă rugăm să încercați din nou mai târziu...',
  },
  Error_Register_With_Already_Existing_Account: {
    en: 'Another account is already signed up',
    de: 'Ein weiteres Konto ist bereits angemeldet',
    ro: 'Un alt cont este deja înregistrat',
  },
  Error_Register_With_Already_Verified_Account: {
    en: 'This account has already been verified! You can log in or reset your password if you have forgotten it!',
    de: 'Dieses Konto wurde bereits verifiziert! Sie können sich einloggen oder Ihr Passwort zurücksetzen, falls Sie es vergessen haben!',
    ro: 'Acest cont a fost deja verificat! Vă puteți autentifica sau vă puteți reseta parola dacă ați uitat-o!',
  },
  Error_Email_Update_Pending1: {
    en: 'Change request for ',
    de: 'Änderungsantrag für ',
    ro: 'Amendamentul pentru',
  },
  Error_Email_Update_Pending2: {
    en: 'is pending... Please check your emails to verify...',
    de: 'ist in Bearbeitung... Bitte überprüfen Sie Ihre E-Mails, um dies zu bestätigen.',
    ro: 'este în curs de desfășurare... Vă rugăm să vă verificați e-mailurile pentru a confirma.',
  },
  Error_Valid_Email: {
    en: 'Enter a valid email address*',
    de: 'Geben Sie eine gültige E-Mail Adresse ein*',
    ro: 'Introduceți o adresă de e-mail validă*',
  },
  Error_Already_Existing_Email: {
    en: 'Email already in use*',
    de: 'E-Mail bereits in Gebrauch*',
    ro: 'E-mail deja utilizat*',
  },
  Error_Email_Required: {
    en: 'Email required*',
    de: 'Email erforderlich*',
    ro: 'Email necesar*',
  },
  Error_First_Name_Required: {
    en: 'First name required*',
    de: 'Vorname erforderlich*',
    ro: 'Prenumele este obligatoriu*',
  },
  Error_Salutation_Required: {
    en: 'Salutation required*',
    de: 'Anrede erforderlich*',
    ro: 'Salutare necesară*',
  },
  Error_Last_Name_Required: {
    en: 'Last name required*',
    de: 'Nachname erforderlich*',
    ro: 'Numele de familie obligatoriu*',
  },
  Error_First_Name_Too_Long: {
    en: 'Length of the first name is too big*',
    de: 'Länge des Vornamens ist zu groß*',
    ro: 'Lungimea prenumelui este prea mare*',
  },
  Error_Last_Name_Too_Long: {
    en: 'Length of the first name is too big*',
    de: 'Länge des Nachnamens ist zu groß*',
    ro: 'Lungimea numelui de familie este prea mare*',
  },
  Error_Password_Invalid_Requirements: {
    en: 'Minimum 8 and maximum 20 characters, at least one uppercase letter, one lowercase letter, one number and one special character!',
    de: 'Mindestens 8 und höchstens 20 Zeichen, mindestens ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen!',
    ro: 'Minim 8 și maxim 20 de caractere, cel puțin o literă majusculă, o literă minusculă, un număr și un caracter special!',
  },
  Error_Telephone_Required: {
    en: 'Telephone number required*',
    de: 'Telefonnummer erforderlich*',
    ro: 'Număr de telefon necesar*',
  },
  Error_How_To_Help_Message_Required: {
    en: 'How can we help you message required*',
    de: 'Wie können wir Ihnen helfen Nachricht erforderlich*',
    ro: 'Cum vă putem ajuta Mesaj solicitat*',
  },
  Error_New_Email_Required: {
    en: 'Type a new email address!',
    de: 'Geben Sie eine neue E-Mail-Adresse ein!',
    ro: 'Introduceți o nouă adresă de e-mail!',
  },
  Error_Email_Already_Existing: {
    en: 'E-mail already exists in the database!',
    de: 'Die E-Mail ist bereits in der Datenbank vorhanden!',
    ro: 'E-mailul există deja în baza de date!',
  },
  Error_Delete_Text_Required: {
    en: 'Delete text required',
    de: 'Delete text erforderlich*',
    ro: 'Ștergeți textul necesar*',
  },
  Error_User_Must_Set_New_Appointment_Date: {
    en: 'Please select new date',
    de: 'Bitte neues Datum auswählen',
    ro: 'Vă rugăm să selectați o nouă dată',
  },
  Error_Street_Empty: {
    en: 'Street cannot be empty',
    de: 'Straße darf nicht leer sein',
    ro: 'Strada nu poate fi goală',
  },
  Error_HouseNumber_Empty: {
    en: 'House number *',
    de: 'Hausnummer darf nicht leer sein',
    ro: 'Numărul casei nu poate fi gol',
  },
  Error_City_Empty: {
    en: 'City cannot be empty',
    de: 'Stadt darf nicht leer sein',
    ro: 'Orașul nu poate fi gol',
  },
  Error_Postcode_Empty: {
    en: 'Postcode cannot be empty',
    de: 'Postleitzahl darf nicht leer sein',
    ro: 'Codul poștal nu poate fi gol',
  },
  Error_Country_Empty: {
    en: 'Country cannot be empty',
    de: 'Land darf nicht leer sein',
    ro: 'Țara nu poate fi goală',
  },
  Error_Required_Field: {
    en: '* Required field',
    de: '* Pflichtfeld',
    ro: '* Câmp obligatoriu',
  },
  // BUTTONS
  Button_Next_Step: {
    en: 'Next Step',
    de: 'Nächster Schritt',
    ro: 'Pasul următor',
  },
  Button_Select_Vacation_Period: {
    en: 'Please select period',
    de: 'Bitte Zeitraum wählen',
    ro: 'Vă rugăm să selectați perioada',
  },
  Button_Close_Modal: {
    en: 'Close window',
    de: 'Fenster schließen',
    ro: 'Închideți fereastra',
  },
  Button_Create_Account: {
    en: 'Create account',
    de: 'Konto erstellen',
    ro: 'Creați un cont',
  },
  Button_Back_To_Homepage: {
    en: 'Back to home page',
    de: 'Zurück zur Startseite',
    ro: 'Înapoi la pagina principală',
  },
  Button_Make_Appointment: {
    en: 'Arrange appointment',
    de: 'Termin vereinbaren',
    ro: 'Aranjați o întâlnire',
  },
  Button_Make_Appointment_Not_Registered: {
    en: 'Request an appointment',
    de: 'Termin anfragen',
    ro: 'Cereți o programare',
  },
  Button_Make_Appointment_Request: {
    de: 'Termin anfragen',
    ro: 'Cereți o programare',
    en: 'Request appointment',
  },
  Button_Request_Appointment_Mouse_Over: {
    de: 'Bitte melden Sie sich an, um diese Funktion zu nutzen.',
    ro: 'Vă rugăm să vă conectați pentru a utiliza această funcție.',
    en: 'Please sign in to use this feature.',
  },
  Button_Submit: {
    en: 'Submit',
    de: 'Absenden',
    ro: 'Trimiteți',
  },
  Button_Reset_Password: {
    en: 'Reset password',
    de: 'Passwort zurücksetzen',
    ro: 'Resetare parolă',
  },
  Button_Delete: {
    en: 'Delete',
    de: 'Löschen',
    ro: 'Ștergeți',
  },
  Button_Next: {
    en: 'Next',
    de: 'Weiter',
    ro: 'Mai departe',
  },
  Button_Previous: {
    en: 'Previous',
    de: 'Vorherige',
    ro: 'Anterior',
  },
  Button_Back: {
    en: 'Back',
    de: 'Zurück',
    ro: 'Înapoi',
  },
  Button_Cancel: {
    en: 'Cancel',
    de: 'Abbrechen',
    ro: 'Anulează',
  },
  Button_Close: {
    en: 'Close',
    de: 'Schließen',
    ro: 'Închideți',
  },
  Button_Send_Request: {
    en: 'Send a request',
    de: 'Anfrage senden',
    ro: 'Trimiteți o cerere',
  },
  Button_Save: {
    en: 'Save',
    de: 'Speichern',
    ro: 'Salvați',
  },
  Button_Login: {
    en: 'Log in',
    de: 'Anmelden',
    ro: 'Autentificare',
  },
  Button_Legal_Tips: {
    en: 'Legal tips',
    de: 'Rechtsinfos',
    ro: 'Informații juridice',
  },
  Button_What_We_Do: {
    en: 'What we do',
    de: 'Was wir tun',
    ro: 'Ce facem noi',
  },
  Button_Search: {
    en: 'Find article',
    de: 'Artikel finden',
    ro: 'Găsiți articol',
  },
  Button_To_Article_Search: {
    en: 'Anwado search',
    de: 'Anwado Suche',
    ro: 'Căutare Anwado',
  },
  Button_Refresh: {
    en: 'Refresh',
    de: 'Aktualisieren',
    ro: 'Actualizare',
  },
  Button_For_Lawyer: {
    en: 'For Lawyer',
    de: 'Für Anwälte',
    ro: 'Pentru avocați',
  },
  Button_Soon: {
    en: 'Soon',
    de: 'Bald',
    ro: 'În curând',
  },
  Button_Past: {
    en: 'By',
    de: 'Vorbei',
    ro: 'De către',
  },
  Button_Cancel_Event: {
    en: 'Cancel appointment',
    de: 'Termin absagen',
    ro: 'Anulează programarea',
  },
  Button_Suggest_New_Date: {
    en: 'Suggest a new date',
    de: 'Neuen Termin vorschlagen',
    ro: 'Propuneți o nouă dată',
  },
  Button_Done: {
    en: 'Done',
    de: 'Fertig',
    ro: 'Gata',
  },
  Button_Register_Yourself: {
    en: 'Register',
    de: 'Registrieren',
    ro: 'Înregistrare',
  },
  Button_Sign_Up: {
    en: 'Register',
    de: 'Registrieren',
    ro: 'Înregistrare',
  },
  Button_Go_To_Homepage: {
    en: 'Back to Homepage',
    de: 'Zurück zu Home',
    ro: 'Înapoi la Acasă',
  },
  Button_Back_To_Lawyer_Articles: {
    en: 'Back to lawyer articles',
    de: 'Zurück zu den Anwaltsartikeln',
    ro: 'Înapoi la articolele avocatului',
  },
  Button_Back_To_Lawyer_Page: {
    en: 'Back to lawyer page',
    de: 'Zurück zur Anwaltsseite',
    ro: 'Înapoi la pagina avocatului',
  },
  Button_Back_To_Lawyer_Settings: {
    en: 'Back to lawyer settings',
    de: 'Zurück zu den Anwaltseinstellungen',
    ro: 'Înapoi la setările avocatului',
  },
  Button_Back_To_Articles: {
    en: 'Back to articles',
    de: 'Zurück zu den Artikeln',
    ro: 'Înapoi la articole',
  },

  // COMMON
  Common_Categories: {
    en: 'Categories',
    de: 'Kategorien',
    ro: 'Categorii',
  },
  Common_Locations: {
    en: 'Locations',
    de: 'Städte',
    ro: 'Locații',
  },
  Common_Specialities: {
    en: 'Specialities',
    de: 'Fachanwälte',
    ro: 'Specialități',
  },
  Common_Areas_Of_Law: {
    en: 'Areas of law',
    de: 'Rechtsgebiete',
    ro: 'Domenii de drept',
  },
  Common_Legal_Informations: {
    en: 'Legal informations',
    de: 'Rechtsinfos',
    ro: 'Informații juridice',
  },
  Common_Reset_Filters_Tooltip: {
    en: 'Reset filters',
    de: 'Filter zurücksetzen',
    ro: 'Resetați filtrele',
  },
  Common_Articles: {
    en: 'Articles',
    de: 'Artikel',
    ro: 'Articole',
  },
  Common_Appointment_Type: {
    en: 'Appointment type',
    de: 'Terminart',
    ro: 'Tipul întâlnirii',
  },
  Common_Terms_And_Conditions_Must_Accept: {
    en: 'You must accept the terms and conditions',
    de: 'Du musst die Geschäftsbedingungen akzeptieren',
    ro: 'Trebuie sa accepti termenii si conditiile',
  },
  Common_Selected: {
    en: 'Selected',
    de: 'Ausgewählt',
    ro: 'Selectat',
  },
  Common_Select_Plan: {
    en: 'Select Plan',
    de: 'Plan auswählen',
    ro: 'Selectați planul',
  },
  Common_Add_To_Favorites: {
    en: 'Add lawyer to favorites',
    de: 'Zu Favoriten hinzufügen',
    ro: 'Adaugă la favorite',
  },
  Common_Contact_Us_Page_Success_Message: {
    en: 'Thank you, your request has been successfully sent!',
    de: 'Vielen Dank, Ihre Anfrage wurde erfolgreich versendet!',
    ro: 'Vă mulțumim, cererea dvs. a fost trimisă cu succes!',
  },
  Common_Accept_Terms_On_Register_1: {
    en: 'I have read, understood, and agree to ',
    de: 'Ich habe die ',
    ro: 'Eu am',
  },

  Common_Accept_Terms_On_Register_2: {
    en: 'the Terms and Conditions (T&Cs)',
    de: 'AGB',
    ro: 'Termenii și condițiile generale (TCG)',
  },
  Common_Accept_Terms_On_Register_3: {
    en: ' and ',
    de: ' und ',
    ro: ' și',
  },
  Common_Accept_Terms_On_Register_4: {
    en: 'Privacy Policy.',
    de: 'Datenschutzerklärung',
    ro: 'Politica de confidențialitate citită',
  },
  Common_Accept_Terms_On_Register_5: {
    en: '',
    de: ' gelesen und akzeptiere diese.',
    ro: 'Le înțeleg și le accept.',
  },
  Common_Accept_Terms_On_Register_6: {
    en: 'I agree that my email address will be used to send information, newsletters and advertising. I understand that I can revoke my consent at any time by sending an email to support@anwado.de.',
    de: 'Ich stimme zu, dass meine E-Mail-Adresse für den Versand von Informationen, Newsletter und Werbung verwendet wird. Mir ist bewusst, dass ich meine Zustimmung jederzeit widerrufen kann, indem ich eine E-Mail an support@anwado.de sende.',
    ro: 'Sunt de acord ca adresa mea de e-mail să fie folosită pentru a trimite informații, buletine informative și publicitate. Înțeleg că îmi pot revoca oricând consimțământul trimițând un e-mail la support@anwado.de.',
  },
  Common_To_Profile: {
    en: 'To the profile',
    de: 'Zum Profil',
    ro: 'Profil',
  },
  Common_View: {
    en: 'View',
    de: 'Anzeigen',
    ro: 'Vezi',
  },
  Common_Edit: {
    en: 'Edit',
    de: 'Bearbeiten',
    ro: 'Editați',
  },
  Common_Update: {
    en: 'Update',
    de: 'Aktualisierung',
    ro: 'Actualizare',
  },
  Common_Show: {
    en: 'Show',
    de: 'Anzeigen',
    ro: 'Vezi',
  },
  Common_Copy_Link: {
    en: 'Copy Link',
    de: 'Link kopieren',
    ro: 'Copiați link-ul',
  },
  Common_Delete: {
    en: 'Delete',
    de: 'Löschen',
    ro: 'Ștergeți',
  },
  Common_Quality_Promise: {
    en: 'Quality promise',
    de: 'Qualitätsversprechen',
    ro: 'Promisiunea de calitate',
  },
  Common_Evaluation: {
    en: 'Evaluation guidelines',
    de: 'Bewertungsrichtlinien',
    ro: 'Orientări de evaluare',
  },
  Common_Contact: {
    en: 'Contact',
    de: 'Kontakt',
    ro: 'Contactați',
  },
  Common_Price_List: {
    en: 'Price list',
    de: 'Preisliste',
    ro: 'Lista de prețuri',
  },
  Common_Contact_Us: {
    en: 'Contact us',
    de: 'Anwado kontaktieren',
    ro: 'Contact Anwado',
  },
  Common_Contact_Us_Description: {
    en: 'You can reach us at any time.',
    de: 'Sie können uns jederzeit erreichen.',
    ro: 'Ne puteți contacta în orice moment.',
  },
  Common_Contact_Us_Description_2: {
    en: 'Send us a message via our contact form and we will get in touch with you immediately.',
    de: 'Senden Sie uns eine Nachricht über unser Kontaktformular und wir werden uns umgehend bei Ihnen melden.',
    ro: 'Trimiteți-ne un mesaj prin intermediul formularului nostru de contact și vă vom răspunde în cel mai scurt timp posibil.',
  },
  Common_Back_To_Login: {
    en: 'Back to registration',
    de: 'Zurück zur Anmeldung',
    ro: 'Înapoi la înregistrare',
  },
  Common_Register: {
    en: 'Register',
    de: 'Registrieren',
    ro: 'Înregistrare',
  },
  Common_Location: {
    en: 'Location',
    de: 'Ort',
    ro: 'Locație',
  },
  Common_Legal_Fields: {
    en: 'Legal fields',
    de: 'Rechtsgebiete',
    ro: 'Domenii de drept',
  },
  Common_Review: {
    en: 'Review',
    de: 'Bewertung',
    ro: 'Evaluare',
  },
  Common_Reviews: {
    en: 'Reviews',
    de: 'Bewertungen',
    ro: 'Recenzii',
  },
  Common_Login_Now: {
    en: 'Login',
    de: 'Anmelden',
    ro: 'Autentificare',
  },
  Common_Email: {
    en: 'E-mail',
    de: 'E-mail',
    ro: 'E-mail',
  },
  Common_Forgot_Password: {
    en: 'Password forgotten?',
    de: 'Passwort vergessen?',
    ro: 'Ați uitat parola?',
  },
  Common_Email_Placeholder: {
    en: 'Enter email address',
    de: 'Emailadresse eingeben',
    ro: 'Introduceți adresa de e-mail',
  },
  Common_Are_You_A_Lawyer: {
    en: 'Are you a lawyer?',
    de: 'Sie sind Anwalt?',
    ro: 'Sunteți avocat?',
  },
  Common_Already_Have_Account_Question: {
    en: 'Already have an account?',
    de: 'Sie haben bereits ein Konto?',
    ro: 'Ai deja un cont?',
  },
  Common_Back_To_Profile_Information: {
    en: 'Back to profile information',
    de: 'Zurück zu Profilinformationen',
    ro: 'Înapoi la informații despre profil',
  },
  Common_Check_Email_Address: {
    en: 'Please check your email address',
    de: 'Bitte überprüfen Sie Ihre Emailadresse',
    ro: 'Vă rugăm să vă verificați adresa de e-mail',
  },
  Common_Verification_Email_Sent_1: {
    en: 'We have sent you an email for verification to',
    de: 'Wir haben Ihnen eine Email zur Verifikation an die ',
    ro: 'V-am trimis un e-mail de verificare la',
  },
  Common_Verification_Email_Sent_2: {
    en: ' for validation. Please also check your spam or junk folder if you cannot find the email in your inbox.',
    de: ' gesendet. Bitte überprüfen Sie auch Ihren Spam- oder Junk-Ordner, falls Sie die E-Mail nicht in Ihrem Posteingang finden können.',
    ro: ' pentru verificare. Vă rugăm să verificați, de asemenea, dosarul de spam sau nedorit dacă nu găsiți e-mailul în căsuța dvs. de e-mail.',
  },
  Common_Enter_Code_Manually: {
    en: 'Enter code manually',
    de: 'Code manuell eingeben',
    ro: 'Introduceți codul manual',
  },
  Common_Page_Section_Placeholder_For_Favorites_Lawyer: {
    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    de: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    ro: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  Common_Verify_Email_Address: {
    en: 'Verify email address',
    de: 'Emailadresse verifizieren',
    ro: 'Verifică adresa de e-mail',
  },
  Common_No_Email_Received: {
    en: 'You have not received an email?',
    de: 'Sie haben keine Email erhalten?',
    ro: 'Nu ați primit un e-mail?',
  },
  Common_Resend_Email: {
    en: 'Send again',
    de: 'Erneut senden',
    ro: 'Trimite din nou',
  },
  Common_Resend_Email_Message: {
    en: 'Another email has been sent to you.',
    de: 'Es wurde eine weitere E-Mail an Sie gesendet.',
    ro: 'Un alt e-mail v-a fost trimis.',
  },
  Common_Account_Created_Title: {
    en: 'Your account has been successfully created!',
    de: 'Ihr Konto wurde erfolgreich erstellt!',
    ro: 'Contul dvs. a fost creat cu succes!',
  },
  Common_Find_Rate_Make_Appointment: {
    en: 'Find ⋅ Rate ⋅ Make an appointment online',
    de: 'Finden ⋅ Bewerten ⋅ Termin online vereinbaren',
    ro: 'Găsiți ⋅ Tarif ⋅ Faceți o programare online',
  },
  Common_Website: {
    en: 'Website',
    de: 'Webseite',
    ro: 'Site web',
  },
  Common_Google_Map_Placeholder: {
    en: 'I consent to the processing of my personal data (in particular my IP address) and its transfer outside the EU by Google Maps (USA) in order to display the map.',
    de: 'Ich stimme der Verarbeitung von personenbezogenen Daten (insbesondere meiner IP-Adresse) und deren Übermittlung außerhalb der EU durch Google Maps (USA) zu, um die Karte anzuzeigen.',
    ro: 'Sunt de acord cu prelucrarea datelor cu caracter personal (în special a adresei mele IP) și cu transferul acestora în afara UE de către Google Maps (SUA) în vederea afișării hărții.',
  },
  Common_Learn_More: {
    en: 'Learn more',
    de: 'Mehr erfahren',
    ro: 'Aflați mai multe',
  },
  Common_Show_Map: {
    en: 'Show map',
    de: 'Karte anzeigen',
    ro: 'Arată harta',
  },
  Common_Check_Your_Email: {
    en: 'Check your email',
    de: 'Überprüfen Sie Ihre Email',
    ro: 'Verifică-ți e-mailul',
  },
  Common_Check_Your_Email_Description_1: {
    en: 'We have sent you an email for verification to',
    de: 'Wir haben Ihnen eine Email zur Verifikation an die',
    ro: 'V-am trimis un e-mail de verificare la',
  },
  Common_Check_Your_Email_Description_2: {
    en: ' for validation. Please also check your spam or junk folder if you cannot find the email in your inbox.',
    de: ' gesendet. Bitte überprüfen Sie auch Ihren Spam- oder Junk-Ordner, falls Sie die E-Mail nicht in Ihrem Posteingang finden können.',
    ro: ' pentru verificare. Vă rugăm să verificați, de asemenea, dosarul de spam sau nedorit dacă nu găsiți e-mailul în căsuța dvs. de e-mail.',
  },
  Common_Public_Profile: {
    en: 'Profile',
    de: 'Profil',
    ro: 'Profil',
  },
  Common_Account: {
    en: 'Account',
    de: 'Konto',
    ro: 'Cont',
  },
  Common_Calendar: {
    en: 'Calendar',
    de: 'Kalender',
    ro: 'Calendar',
  },
  Common_Appointments: {
    en: 'Dates',
    de: 'Termine',
    ro: 'Date',
  },
  Common_Subscription: {
    en: 'Subscription',
    de: 'Abonnement',
    ro: 'Abonament',
  },
  Common_Subscription_For_Lawyers: {
    en: 'Subscription for lawyers',
    de: 'Abonnement für Anwälte',
    ro: 'Abonament pentru avocați',
  },
  Common_Favorite_Lawyers: {
    en: 'Favorites',
    de: 'Favoriten',
    ro: 'Favoritele',
  },
  Common_Message: {
    en: 'Message',
    de: 'Nachricht',
    ro: 'Mesaj',
  },
  Common_Reason_For_Deletion_Placeholder: {
    en: 'Please explain the reason for the deletion....',
    de: 'Bitte erläutern Sie den Grund für die Löschung...',
    ro: 'Vă rugăm să explicați motivul eliminării....',
  },
  Common_Start_Search: {
    en: 'Start search',
    de: 'Suche starten',
    ro: 'Începeți căutarea',
  },
  Common_Per_Month: {
    en: 'per Month',
    de: 'pro Monat',
    ro: 'pe lună',
  },
  Common_Per_Year: {
    en: 'per Year',
    de: 'pro Jahr',
    ro: 'pe an',
  },
  Common_Monthly: {
    en: '(monthly)',
    de: '(monatlich)',
    ro: '(lunar)',
  },
  Common_Yearly: {
    en: '(yearly)',
    de: '(jährlich)',
    ro: '(anual)',
  },
  Common_OTP_Code_Resent: {
    en: 'We have sent you an email to *EMAIL_PLACEHOLDER* for verification. Please also check your spam or junk folder if you cannot find the email in your inbox.',
    de: 'Wir haben Ihnen eine E-Mail zur Verifikation an die *EMAIL_PLACEHOLDER* gesendet. Bitte überprüfen Sie auch Ihren Spam- oder Junk-Ordner, falls Sie die E-Mail nicht in Ihrem Posteingang finden können.',
    ro: 'V-am trimis un e-mail la *EMAIL_PLACEHOLDER* pentru verificare. Vă rugăm să verificați, de asemenea, dosarul de spam sau nedorit dacă nu găsiți e-mailul în căsuța dvs. de e-mail.',
  },
  //Section Info Settings
  SIS_Top_Title: {
    en: 'Settings',
    de: 'Einstellungen',
    ro: 'Setări',
  },
  SIS_Top_Description: {
    en: 'Manage your Anwado account here',
    de: 'Verwalten Sie hier Ihr Anwado-Konto',
    ro: 'Gestionați-vă contul Anwado aici',
  },
  SIS_Contact_Information_Title: {
    en: 'Contact Information',
    de: 'Kontakt Information',
    ro: 'Informații de contact',
  },
  SIS_Contact_Information_Description: {
    en: 'Contact information is used for appointments and messages.',
    de: 'Kontaktinformationen werden für Termin und Nachrichten verwendet.',
    ro: 'Informațiile de contact sunt folosite pentru întâlniri și mesaje.',
  },
  SIS_Update_Successfully_Message: {
    en: 'Your data has been successfully updated!',
    de: 'Ihre Daten wurden erfolgreich aktualisiert!',
    ro: 'Datele dvs. au fost actualizate cu succes!',
  },
  SIS_Account_Title: {
    en: 'Account',
    de: 'Konto',
    ro: 'Cont',
  },
  // Inputs
  Input_Address: {
    en: 'Address',
    de: 'Adresse',
    ro: 'Adresa',
  },
  Input_Street: {
    en: 'Street',
    de: 'Straße',
    ro: 'Strada',
  },
  Input_House_Number: {
    en: 'House number',
    de: 'Hausnummer',
    ro: 'Numărul casei',
  },
  Input_Country: {
    en: 'Country',
    de: 'Land',
    ro: 'Țara',
  },
  Input_Country_Placeholder: {
    en: 'Enter country',
    de: 'Land eingeben',
    ro: 'Introduceți țara',
  },
  Input_Address_Placeholder: {
    en: 'Street',
    de: 'Straße',
    ro: 'Strada',
  },
  Input_Postcode: {
    en: 'Postal code',
    de: 'Postleitzahl',
    ro: 'Cod poștal',
  },
  Input_Postcode_Placeholder: {
    en: 'Zip code',
    de: 'Postleitzahl ',
    ro: 'Cod poștal',
  },
  Input_City: {
    en: 'City',
    de: 'Stadt',
    ro: 'Orașul',
  },
  Input_City_Placeholder: {
    en: 'Enter city',
    de: 'Stadt eingeben',
    ro: 'Introduceți orașul',
  },
  Input_Telephone: {
    en: 'Phone number',
    de: 'Telefonnummer',
    ro: 'Număr de telefon',
  },
  Input_Email: {
    en: 'Email address',
    de: 'Email Adresse',
    ro: 'Adresa de e-mail',
  },
  Input_Profile_Image: {
    en: 'Account image',
    de: 'Kontobild',
    ro: 'Imaginea contului',
  },
  Input_Password: {
    en: 'Password',
    de: 'Passwort',
    ro: 'Parola',
  },
  Input_Repeat_Password: {
    en: 'Repeat password*',
    de: 'Passwort wiederholen*',
    ro: 'Repetați parola*',
  },
  Input_First_Name: {
    en: 'First name*',
    de: 'Vorname*',
    ro: 'Prenumele*',
  },
  Input_First_Name_Placeholder: {
    en: 'Enter your first name',
    de: 'Vorname eingeben',
    ro: 'Introduceți prenumele',
  },
  Input_Last_Name: {
    en: 'Last name*',
    de: 'Nachname*',
    ro: 'Numele de familie*',
  },
  Input_Last_Name_Placeholder: {
    en: 'Enter your last name',
    de: 'Nachname eingeben',
    ro: 'Introduceți numele de familie',
  },
  Input_Salutation: {
    en: 'Salutation',
    de: 'Anrede',
    ro: 'Salutare',
  },
  Input_Title: {
    en: 'Title',
    de: 'Titel',
    ro: 'Titlu',
  },
  Input_Specialist_Lawyer: {
    en: 'Specialist lawyer',
    de: 'Fachanwalt',
    ro: 'Avocat specializat',
  },
  Input_Languages: {
    en: 'Languages',
    de: 'Sprachen',
    ro: 'Limbi',
  },
  Input_Short_Description: {
    en: 'Brief description',
    de: 'Kurzbeschreibung',
    ro: 'Scurtă descriere',
  },
  Input_Telefax: {
    en: 'Fax',
    de: 'Telefax',
    ro: 'Fax',
  },
  Input_Internet_Address: {
    en: 'Internet address',
    de: 'Internet Adresse',
    ro: 'Adresa de internet',
  },
  Input_Describe_Yourself: {
    en: 'Describe yourself',
    de: 'Beschreiben Sie sich',
    ro: 'Descrieți-vă',
  },
  Input_Describe_Yourself_And_Firm: {
    en: 'Describe yourself and your firm',
    de: 'Beschreiben Sie sich und Ihre Kanzlei',
    ro: 'Descrieți-vă pe dumneavoastră și firma dumneavoastră de avocatură',
  },
  Input_Specify_Reason: {
    en: 'Please specify reason',
    de: 'Bitte Grund angeben',
    ro: 'Vă rugăm să precizați motivul',
  },
  // User Create Account
  USA_Title: {
    en: 'Create free account now and publish rating',
    de: 'Jetzt kostenloses Konto erstellen und Bewertung veröffentlichen',
    ro: 'Creați un cont gratuit acum și publicați ratingul dvs.',
  },
  USA_Description: {
    en: 'According to the Anwado rating policy, ratings can only be made by registered users. Therefore, your rating will not be published until you have activated your account.',
    de: 'Gemäß den Bewertungsrichtlinien von Anwado können Bewertungen nur von registrierten Benutzern vorgenommen werden. Ihre Bewertung wird daher erst veröffentlicht, wenn Sie Ihren Account aktiviert haben.',
    ro: 'Conform ghidului de evaluare al Anwado, evaluările pot fi făcute doar de către utilizatorii înregistrați. Prin urmare, evaluarea dvs. va fi publicată numai după ce vă activați contul.',
  },
  USA_Action_Button: {
    en: 'Create and publish account',
    de: 'Konto erstellen und veröffentlichen',
    ro: 'Creați și publicați contul',
  },
  // Landing Page variables
  LP_Founders_Citation: {
    en: 'At Anwado, we believe in transparent and accessible legal advice, where clients can find the best lawyers and lawyers can share their expertise effectively - this is legal advice reimagined.',
    de: 'Bei Anwado glauben wir an eine transparente und zugängliche Rechtsberatung, bei der Mandanten die besten Anwälte finden und Anwälte ihr Fachwissen effektiv teilen können – das ist Rechtsberatung neu gedacht.',
    ro: 'La Anwado, credem în consultanță juridică transparentă și accesibilă, în care clienții pot găsi cei mai buni avocați, iar avocații își pot împărtăși expertiza în mod eficient - aceasta este consultanța juridică regândită.',
  },
  LP_Founders_Name: {
    en: 'Anwado Team',
    de: 'Anwado Team',
    ro: 'Echipa Anwado',
  },
  LP_Content_Description_Under_Rating: {
    en: 'Fast appointment with the lawyer',
    de: 'Schneller Termin beim Anwalt',
    ro: 'Întâlnire rapidă cu avocatul',
  },
  LP_Content_Title: {
    en: 'Legal advice rethought',
    de: 'Rechtsberatung neu gedacht',
    ro: 'Consultanță juridică regândită',
  },
  LP_Content_Description_Heading: {
    en: 'With Anwado to the right lawyer.',
    de: 'Mit Anwado zum richtigen Anwalt.',
    ro: 'Găsiți avocatul potrivit cu Anwado.',
  },
  LP_SearchBar_Location_Dropdown_Title: {
    en: 'Near me',
    de: 'In meiner Nähe',
    ro: 'În apropierea mea',
  },
  LP_SearchBar_Location_User_Distance_Title: {
    en: 'Distance',
    de: 'Entfernung',
    ro: 'Distanța',
  },
  LP_SearchBar_Location_No_Selection: {
    en: 'no selection',
    de: 'keine Auswahl',
    ro: 'Nici o selecție',
  },
  LP_SearchBar_Input_Placeholder: {
    en: 'Name',
    de: 'Name',
    ro: 'Nume',
  },
  LP_SearchBar_Location_Dropdown_Placeholder: {
    en: 'Location',
    de: 'Standort',
    ro: 'Locație',
  },
  LP_SearchBar_Button_Placeholder: {
    en: 'Find a lawyer',
    de: 'Anwalt finden',
    ro: 'Găsiți un avocat',
  },
  // Search Lawyers variables
  SL_Filters_Subscription_Name: {
    en: 'Filter',
    de: 'Filter',
    ro: 'Filtru',
  },
  SL_Filters_Rating_Name: {
    en: 'Rating',
    de: 'Bewertung',
    ro: 'Evaluare',
  },
  SL_Filters_Rating_Type_1: {
    en: '4+ Stars',
    de: '4+ Sterne',
    ro: '4+ stele',
  },
  SL_Filters_Rating_Type_2: {
    en: '4.5+ Stars',
    de: '4.5+ Sterne',
    ro: '4.5+ stele',
  },
  SL_Filters_Rating_Type_3: {
    en: '5 Stars',
    de: '5 Sterne',
    ro: '5 stele',
  },
  SL_Filters_Reviews_Name: {
    en: 'Number of reviews',
    de: 'Anzahl Bewertungen',
    ro: 'Numărul de evaluări',
  },
  SL_Filters_Reviews_Type_1: {
    en: '5+ Reviews',
    de: '5+ Bewertungen',
    ro: '5+ evaluări',
  },
  SL_Filters_Reviews_Type_2: {
    en: '10+ Reviews',
    de: '10+ Bewertungen',
    ro: '10+ evaluări',
  },
  SL_Filters_Reviews_Type_3: {
    en: '20+ Reviews',
    de: '20+ Bewertungen',
    ro: '20+ recenzii',
  },
  SL_Lawyer_Card_Display: {
    en: 'Display',
    de: 'Anzeige',
    ro: 'Afișare',
  },
  SL_Lawyer_Card_Specialist: {
    en: 'Specialist lawyer for',
    de: 'Fachanwalt für',
    ro: 'Avocat specializat pentru',
  },
  // Footer text variables
  Footer_Description: {
    en: 'Legal advice rethought.',
    de: 'Rechtsberatung neu gedacht.',
    ro: 'Consultanță juridică regândită.',
  },
  Footer_About: {
    en: 'About Anwado',
    de: 'Über Anwado',
    ro: 'Despre Anwado',
  },
  Footer_Promise: {
    en: '... and more',
    de: '... und mehr',
    ro: '... și multe altele',
  },
  Footer_Privacy: {
    en: 'Privacy & Cookies',
    de: 'Datenschutz & Cookies',
    ro: 'Protecția datelor și cookie-uri',
  },
  Footer_All_Rights_Reserved: {
    en: 'All rights reserved.',
    de: 'Alle Rechte vorbehalten.',
    ro: 'Toate drepturile rezervate.',
  },
  // Login Client
  LC_MetaDecoration_Title: {
    en: 'User Login | Access Your Account',
    de: 'Benutzeranmeldung | Greife auf dein Konto zu',
    ro: 'Autentificare utilizator | Accesați contul dvs',
  },
  LC_MetaDecoration_Description: {
    en: 'Log in to your account to access your settings, preferences, saved items, and more. Your personalized experience awaits.',
    de: 'Melden Sie sich bei Ihrem Konto an, um auf Ihre Einstellungen, Präferenzen, gespeicherten Elemente und mehr zuzugreifen. Ihr persönliches Erlebnis erwartet Sie.',
    ro: 'Conectați-vă la contul dvs. pentru a vă accesa setările, preferințele, elementele salvate și multe altele. Experiența dvs. personalizată vă așteaptă.',
  },
  LC_Title: {
    en: 'Welcome back',
    de: 'Willkommen zurück',
    ro: 'Bine ai revenit',
  },
  LC_Description: {
    en: 'Welcome back, please enter your login details.',
    de: 'Willkommen zurück, bitte geben Sie Ihre Anmeldedaten ein.',
    ro: 'Bine ați revenit, vă rugăm să introduceți datele de conectare.',
  },
  LC_Email_Placeholder: {
    en: 'Enter email',
    de: 'Email eintragen',
    ro: 'Introduceți adresa de e-mail',
  },
  LC_Remember_Me: {
    en: 'Save access',
    de: 'Zugang speichern',
    ro: 'Salvați accesul',
  },
  LC_Login_Button_Text: {
    en: 'Log in',
    de: 'Einloggen',
    ro: 'Autentificare',
  },
  LC_Carousel_Text: {
    en: '„Anwado has saved us a lot of hours of work. We can get in touch with lawyers incredibly quickly.“',
    de: '„Anwado hat uns viele Arbeitsstunden erspart. Wir können unglaublich schnell mit Anwälten in Kontakt treten.“',
    ro: '"Anwado ne-a scutit de multe ore de muncă. Putem lua legătura cu avocații incredibil de rapid."',
  },
  //Login Lawyer
  LL_Forgot_Password: {
    en: 'Password forgotten',
    de: 'Passwort vergessen',
    ro: 'Parolă uitată',
  },
  LC_No_Account1: {
    en: 'No account yet?',
    de: 'Noch keinen Konto?',
    ro: 'Nu aveți încă un cont?',
  },
  LL_No_Account2: {
    en: 'You do not have an account?',
    de: 'Sie haben kein Konto?',
    ro: 'Nu aveți un cont?',
  },
  // Register Client
  RC_MetaDecorator_Title: {
    en: 'Create Your Account | Join Our Community',
    de: 'Erstellen Sie Ihr Konto | tritt unserer Gemeinschaft bei',
    ro: 'Creați-vă contul | alaturati-va comunitatii noastre',
  },
  RC_MetaDecorator_Description: {
    en: 'Register today to create your own account, enabling you to enjoy our full range of services, receive updates, and become part of our community.',
    de: 'Registrieren Sie sich noch heute, um Ihr eigenes Konto zu erstellen, damit Sie unser gesamtes Leistungsspektrum nutzen, Updates erhalten und Teil unserer Community werden können.',
    ro: 'Înregistrați-vă astăzi pentru a vă crea propriul cont, permițându-vă să vă bucurați de gama noastră completă de servicii, să primiți actualizări și să faceți parte din comunitatea noastră.',
  },
  RC_Form_Badge: {
    en: 'Are you a lawyer?',
    de: 'Sie sind Anwalt?',
    ro: 'Sunteți avocat?',
  },
  RC_Form_Title: {
    en: 'Create a free client profile now!',
    de: 'Jetzt kostenloses Mandantenprofil erstellen!',
    ro: 'Creează un profil de client gratuit acum!',
  },
  RC_Form_Description: {
    en: 'With the free Anwado account for clients, you have everything you need to always find the right lawyer for you.',
    de: 'Mit dem kostenlosen Anwado Konto für Mandanten haben Sie alles was Sie brauchen, um immer den richtigen Anwalt für sich zu finden.',
    ro: 'Cu contul gratuit Anwado pentru clienți, aveți tot ce vă trebuie pentru a găsi întotdeauna avocatul potrivit pentru dumneavoastră.',
  },
  RC_Step_1_Title: {
    en: 'Email verification',
    de: 'Email-Verifizierung',
    ro: 'Verificare e-mail',
  },
  RC_Step_1_Description: {
    en: 'You will receive an email to the email address provided.',
    de: 'Sie erhalten eine Email an die angegebene E-Mailadresse.',
    ro: 'Veți primi un e-mail la adresa de e-mail furnizată.',
  },
  RC_Step_2_Title: {
    en: 'Code verification',
    de: 'Code-Verifizierung',
    ro: 'Verificarea codului',
  },
  RC_Step_2_Description: {
    en: 'Please enter the code from the email here.',
    de: 'Bitte geben Sie den Code aus der Email hier ein.',
    ro: 'Vă rugăm să introduceți aici codul din e-mail.',
  },
  RC_Step_3_Title: {
    en: 'Account verification',
    de: 'Konto-Verifizierung',
    ro: 'Verificarea contului',
  },
  RC_Step_3_Description: {
    en: 'Your account will be verified in this step.',
    de: 'Ihr Konto wird in diesem Schritt verifiziert.',
    ro: 'În această etapă, contul dvs. va fi verificat.',
  },
  RC_Step_4_Title: {
    en: 'Complete profile',
    de: 'Profil vervollstandigen',
    ro: 'Profil complet',
  },
  RC_Step_4_Description: {
    en: 'Please enter your personal data.',
    de: 'Bitte geben Sie Ihre persönlichen Daten an.',
    ro: 'Vă rugăm să introduceți datele dumneavoastră personale.',
  },
  RC_Step_5_Title: {
    en: 'Assign password',
    de: 'Passwort vergeben',
    ro: 'Atribuiți parola',
  },
  RC_Step_5_Description: {
    en: 'Please set a password to access your account.',
    de: 'Bitte legen Sie ein Passwort fest, um Zugang zu Ihrem Konto zu erhalten.',
    ro: 'Vă rugăm să setați o parolă pentru a vă accesa contul.',
  },
  RC_Page_3_Title: {
    en: 'Email address has been verified successfully!',
    de: 'Emailadresse wurde erfolgreich verifiziert!',
    ro: 'Adresa de e-mail a fost verificată cu succes!',
  },
  RC_Page_3_Description: {
    en: 'Enter your personal data now to get the most out of Anwado.',
    de: 'Geben Sie jetzt ihre persöhnlichen Daten an, um Anwado optimal zu nutzen.',
    ro: 'Introduceți acum datele dumneavoastră personale pentru a profita la maximum de Anwado.',
  },
  RC_Page_4_Title: {
    en: 'Just one more step...',
    de: 'Nur noch ein Schritt...',
    ro: 'Încă un pas...',
  },
  RC_Page_4_Description: {
    en: 'Complete your profile to get the most out of Anwado',
    de: 'Vervollständigen Sie Ihr Profil um Anwado optimal nutzen zu können',
    ro: 'Completează-ți profilul pentru a profita la maximum de Anwado',
  },
  RC_Page_4_Form_Skip_Info: {
    en: 'You do not want to enter your data yet?',
    de: 'Sie möchten Ihre Daten noch nicht eintragen?',
    ro: 'Nu doriți să introduceți încă datele dumneavoastră?',
  },
  RC_Page_5_Description: {
    en: 'Minimum 8 and maximum 20 characters, at least one uppercase letter, one lowercase letter, one number and one special character!',
    de: 'Mindestens 8 und höchstens 20 Zeichen, mindestens ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen!',
    ro: 'Minim 8 și maxim 20 de caractere, cel puțin o literă majusculă, o literă minusculă, un număr și un caracter special!',
  },
  RC_Page_5_Email_Verified_Set_Password_Now: {
    en: 'Set password now',
    de: 'Jetzt Passwort festlegen',
    ro: 'Setați parola acum',
  },
  RC_Page_6_Description: {
    en: 'Take advantage of Anwado now',
    de: 'Nutzen Sie jetzt die Vorteile von Anwado',
    ro: 'Profitați acum de Anwado',
  },
  // Register Lawyer
  RL_Back_To_Subscription: {
    en: 'Back to Subscriptions',
    de: 'Zurück zu Abonnements',
    ro: 'Înapoi la abonamente',
  },
  RL_Multiple_Emails_Step_Title: {
    en: 'Existing lawyer profiles found',
    de: 'Bestehende Anwaltsprofile gefunden',
    ro: 'Profiluri de avocați existente găsite',
  },
  RL_Multiple_Emails_Step_Description: {
    en: 'We have found several lawyer profiles with the given email address. Please select the corresponding profile that you would like to adopt. In one of the next steps you will be asked to enter a new, unique e-mail address. This will then serve as your username.',
    de: 'Wir haben mehrere Anwaltsprofile mit der angegebenen E-Mail-Adresse gefunden. Bitte wählen Sie das entsprechende Profil aus, das Sie übernehmen möchten. In einem der nächsten Schritte werden Sie aufgefordert, eine neue, eindeutige E-Mail-Adresse zu vergeben. Diese dient dann als Ihr Benutzername.',
    ro: 'Am găsit mai multe profiluri de avocați cu adresa de e-mail menționată. Vă rugăm să selectați profilul corespunzător pe care doriți să îl adoptați. Într-unul dintre pașii următori, vi se va cere să introduceți o nouă adresă de e-mail unică. Aceasta va servi apoi ca nume de utilizator.',
  },
  RL_Stepper_1_Title: {
    en: 'Account',
    de: 'Konto',
    ro: 'Cont',
  },
  RL_Stepper_1_Description: {
    en: 'We check if an account already exists for your email address.',
    de: 'Wir überprüfen, ob zu Ihrer Emailadresse bereits ein Konto existiert.',
    ro: 'Verificăm dacă există deja un cont pentru adresa dvs. de e-mail.',
  },
  RL_Stepper_1_Modal_Title: {
    en: 'Take over profile',
    de: 'Profil übernehmen',
    ro: 'Preia profilul',
  },
  RL_Stepper_1_Modal_Description: {
    en: 'In order to take over the profile, you must have access to the stored email address.',
    de: 'Um das Profil übernehmen zu können, müssen Sie Zugriff auf die hinterlegte Email Addresse haben.',
    ro: 'Pentru a prelua profilul, trebuie să aveți acces la adresa de e-mail stocată.',
  },
  RL_Stepper_1_Modal_Input_Description_Label: {
    en: 'Justification details',
    de: 'Details zur Begründung',
    ro: 'Detalii de justificare',
  },
  RL_Stepper_1_Modal_Input_Description_Placeholder: {
    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    de: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    ro: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  RL_Stepper_1_Modal_Input_Description_Hint: {
    en: 'Your request will be processed as soon as possible.',
    de: 'Ihre Anfrage wird schnellst möglich bearbeitet.',
    ro: 'Cererea dumneavoastră va fi procesată cât mai curând posibil.',
  },
  RL_Stepper_1_Modal_Input_Email_Label: {
    en: 'New email*',
    de: 'Neue Email*',
    ro: 'E-mail nou*',
  },
  RL_Stepper_1_Modal_Data_Sent: {
    en: 'Data has been sent successfully',
    de: 'Die Daten wurden erfolgreich gesendet',
    ro: 'Datele au fost trimise cu succes',
  },
  RL_Stepper_2_Title: {
    en: 'Email verification',
    de: 'Email-Verifizierung',
    ro: 'Verificare e-mail',
  },
  RL_Stepper_2_Description: {
    en: 'You will receive an email to the email address provided.',
    de: 'Sie erhalten eine Email an die angegebene E-Mailadresse.',
    ro: 'Veți primi un e-mail la adresa de e-mail furnizată.',
  },
  RL_Stepper_3_Title: {
    en: 'Code verification',
    de: 'Code-Verifizierung',
    ro: 'Verificarea codului',
  },
  RL_Stepper_3_Description: {
    en: 'Please enter the code from the email here.',
    de: 'Bitte geben Sie den Code aus der Email hier ein.',
    ro: 'Vă rugăm să introduceți aici codul din e-mail.',
  },
  RL_Stepper_4_Title: {
    en: 'Account verification',
    de: 'Konto-Verifizierung',
    ro: 'Verificarea contului',
  },
  RL_Stepper_4_Description: {
    en: 'Your account will be verified in this step.',
    de: 'Ihr Konto wird in diesem Schritt verifiziert.',
    ro: 'În această etapă, contul dvs. va fi verificat.',
  },
  RL_Stepper_5_Title: {
    en: 'Enter a new email',
    de: 'Eine neue E-Mail eingeben',
    ro: 'Introduceți un nou e-mail',
  },
  RL_Stepper_5_Description: {
    en: 'Please set a new email to access your account.',
    de: 'Bitte geben Sie eine neue E-Mail-Adresse ein, um auf Ihr Konto zuzugreifen.',
    ro: 'Vă rugăm să introduceți o nouă adresă de e-mail pentru a vă accesa contul.',
  },
  RL_Stepper_6_Title: {
    en: 'Set up your address',
    de: 'Richten Sie Ihre Adresse ein',
    ro: 'Configurați-vă adresa',
  },
  RL_Stepper_6_Description: {
    en: 'Please set up your address and phone number.',
    de: 'Bitte geben Sie Ihre Adresse und Telefonnummer ein.',
    ro: 'Vă rugăm să vă configurați adresa și numărul de telefon.',
  },
  RL_Stepper_7_Title: {
    en: 'Choose Subscription',
    de: 'Abonnement wählen',
    ro: 'Alegeți abonamentul',
  },
  RL_Stepper_7_Description: {
    en: 'Please set a subscription to access your account.',
    de: 'Bitte legen Sie ein Abonnement fest, um Zugang zu Ihrem Konto zu erhalten.',
    ro: 'Vă rugăm să setați un abonament pentru a vă accesa contul.',
  },
  RL_Stepper_8_Title: {
    en: 'Assign password',
    de: 'Passwort vergeben',
    ro: 'Atribuiți parola',
  },
  RL_Stepper_8_Description: {
    en: 'Please set a password to access your account.',
    de: 'Bitte legen Sie ein Passwort fest, um Zugang zu Ihrem Konto zu erhalten.',
    ro: 'Vă rugăm să setați o parolă pentru a vă accesa contul.',
  },
  RL_Photo_Content_title: {
    en: 'Find and manage client requests in minutes',
    de: 'Finden und verwalten Sie Mandanten-anfragen in wenigen Minuten',
    ro: 'Găsiți și gestionați cererile clienților în câteva minute',
  },
  RL_Photo_Content_Description_1: {
    en: 'legal advice rethought',
    de: 'Rechtsberatung neu gedacht',
    ro: 'Consultanță juridică regândită',
  },
  RC_Photo_Content_title: {
    en: 'Find the right lawyer in a few minutes and make an appointment directly',
    de: 'Finden Sie den passenden Anwalt in wenigen Minuten und vereinbaren direkt einen Termin',
    ro: 'Găsiți avocatul potrivit în câteva minute și faceți o programare direct',
  },
  RC_Page_4_Next_Button: {
    en: 'Use Anwado now',
    de: 'Anwado jetzt nutzen',
    ro: 'Utilizați Anwado acum',
  },
  RL_Photo_Content_Clients_Description: {
    en: 'top lawyers are listed on Anwado',
    de: 'Top Anwälte sind auf Anwado gelistet',
    ro: 'Avocații de top sunt listați pe Anwado',
  },
  RL_Photo_Content_Lawyers_Description: {
    en: 'top lawyers are listed on Anwado',
    de: 'Top Anwälte sind auf Anwado gelistet',
    ro: 'Avocații de top sunt listați pe Anwado',
  },
  RL_Form_Title: {
    en: 'Create a free lawyer profile now',
    de: 'Jetzt kostenloses Anwaltsprofil erstellen',
    ro: 'Creați un profil de avocat gratuit acum',
  },
  RL_Form_Description: {
    en: 'The Anwado profile is your basis for effective client acquisition. Test us with the free profile.',
    de: 'Das Anwado Profil ist Ihre Basis für eine effektive Man­danten­gewinnung. Testen Sie uns mit dem kostenlosen Profil oder nutzen Sie jetzt die Vorteile einer Gold- oder Platin-Mitgliedschaft.',
    ro: 'Profilul Anwado este baza pentru o achiziție eficientă de clienți. Încercați-ne cu profilul gratuit sau profitați acum de avantajele unui abonament gold sau platinum.',
  },
  RL_Page_1_Info_Box_Badge: {
    en: 'Note',
    de: 'Hinweis',
    ro: 'Notă',
  },
  RL_Page_1_Info_Box_Title: {
    en: 'Your profile has already been created!',
    de: 'Ihr Profil wurde bereits angelegt!',
    ro: 'Profilul tău a fost deja creat!',
  },
  RL_Page_1_Info_Box_Description: {
    en: 'Good news: Your profile has already been created and already exists on Anwado',
    de: 'Gute Neuigkeiten: Ihr Profil wurde bereits angelegt und existiert bereits auf Anwado',
    ro: 'Vești bune: Profilul tău a fost deja creat și există deja pe Anwado',
  },
  RL_Page_1_Title: {
    en: 'Do you want access to this profile?',
    de: 'Wollen Sie Zugang zu diesem Profil?',
    ro: 'Doriți acces la acest profil?',
  },
  RL_Page_1_Description: {
    en: 'To take over the profile, you must have access to the stored email address.',
    de: 'Um das Profil übernehmen zu können, müssen Sie Zugriff auf die hinterlegte Email Addresse haben. ',
    ro: 'Pentru a prelua profilul, trebuie să aveți acces la adresa de e-mail stocată.',
  },
  RL_Page_1_Next_Step_Button_Text: {
    en: 'Get access',
    de: 'Zugang erhalten',
    ro: 'Obțineți acces',
  },
  RL_Page_1_Return_New_Account: {
    en: 'No, I want to create a new account',
    de: 'Nein, ich möchte ein neues Konto erstellen',
    ro: 'Nu, vreau să îmi creez un cont nou',
  },
  RL_Page_1_Email_Changed: {
    en: 'Your email has changed?',
    de: 'Ihre Email hat sich geändert?',
    ro: 'Ți s-a schimbat adresa de e-mail?',
  },
  RL_Page_1_Contact_Us: {
    en: 'Contact Anwado',
    de: 'Anwado kontaktieren',
    ro: 'Contact Anwado',
  },
  RL_Page_2_Enter_Code: {
    en: 'Enter code',
    de: 'Code eingeben',
    ro: 'Introduceți codul',
  },
  RL_Page_3_Description_1: {
    en: 'We have sent you an email for verification to ',
    de: 'Wir haben Ihnen eine Email zur Verifizierung an die ',
    ro: 'V-am trimis un e-mail de verificare la',
  },
  RL_Page_3_Description_2: {
    en: 'Please enter the code from the email here',
    de: ' gesendet. Bitte geben Sie den Code aus der Email hier ein',
    ro: ' trimis. Vă rugăm să introduceți codul din e-mail aici',
  },
  RL_Email_Verified_Title: {
    en: 'Your email was successfully verified',
    de: 'Ihre Email wurde erfolgreich verifiziert',
    ro: 'E-mailul dvs. a fost verificat cu succes',
  },
  RL_Email_Verified_Description: {
    en: 'Please set up your profile now and set a password to access your account.',
    de: 'Bitte richten Sie jetzt Ihr Profil ein und legen ein Passwort fest, um auf Ihr Konto zuzugreifen.',
    ro: 'Vă rugăm să vă configurați profilul acum și să setați o parolă pentru a vă accesa contul.',
  },
  RL_Email_Verified_Description_Extra_Step: {
    en: 'Please change your email to continue the registration.',
    de: 'Bitte ändern Sie Ihre E-Mail-Adresse, um die Registrierung fortzusetzen.',
    ro: 'Vă rugăm să vă schimbați adresa de e-mail pentru a continua înregistrarea.',
  },
  RL_Email_Verified_Button: {
    en: 'Set address now',
    de: 'Jetzt Adresse festlegen',
    ro: 'Setați adresa acum',
  },
  RL_Email_Verified_Button_Extra_Step: {
    en: 'Set email now',
    de: 'Jetzt Email festlegen',
    ro: 'Setați e-mailul acum',
  },
  // Lawyer Public Page
  // Header
  LPP_Header_From: {
    en: 'from',
    de: 'aus',
    ro: 'de la',
  },
  LPP_Header_Send_Message: {
    en: 'Send message',
    de: 'Nachricht senden',
    ro: 'Trimiteți un mesaj',
  },
  // Bio
  LPP_Bio_Availability: {
    en: 'Availability',
    de: 'Verfügbarkeit',
    ro: 'Disponibilitate',
  },
  LPP_Bio_Availability_Description: {
    en: 'Please indicate here how you want to keep appointments with your clients.',
    de: 'Bitte geben Sie hier an wie Sie Termine mit Ihren Mandanten wahrnehmen wollen.',
    ro: 'Vă rugăm să indicați aici modul în care doriți să păstrați întâlnirile cu clienții dumneavoastră.',
  },
  LPP_Bio_Rating: {
    en: 'Evaluation',
    de: 'Bewertung',
    ro: 'Evaluare',
  },
  LPP_Bio_Distance: {
    en: 'Distance',
    de: 'Entfernung',
    ro: 'Distanța',
  },
  LPP_Bio_Sort: {
    en: 'Sort By',
    de: 'Sortieren nach',
    ro: 'Sortează după',
  },
  LPP_Bio_Speciality: {
    en: 'Only specialist lawyers',
    de: 'Nur Fachanwälte',
    ro: 'Numai avocați specializați',
  },
  LPP_Bio_Booking: {
    en: 'Appointments available',
    de: 'Termine möglich',
    ro: 'Programări posibile',
  },
  LPP_Bio_Submit_Rating_Button: {
    en: 'Submit rating',
    de: 'Bewertung abgeben',
    ro: 'Trimiteți ratingul',
  },
  LPP_Bio_Claim_Account: {
    en: 'You are that lawyer?',
    de: 'Sie sind dieser Anwalt?',
    ro: 'Tu ești avocatul acela?',
  },
  LPP_Bio_Claim_Account_Button: {
    en: 'Edit account',
    de: 'Konto bearbeiten',
    ro: 'Editați contul',
  },
  LPP_Bio_Appointment_Card_Description: {
    en: 'Make an appointment directly online for an on-site meeting or a telephone appointment. This saves you the time-consuming search for an appointment by telephone.',

    de: 'Vereinbaren Sie direkt online einen Termin für eine Besprechung vor Ort oder einen Telefontermin. So sparen Sie sich die zeitaufwendige telefonische Terminsuche.',
    ro: 'Faceți o programare direct online pentru o întâlnire la fața locului sau o programare telefonică. Astfel, veți economisi timpul pierdut în căutarea unei întâlniri prin telefon.',
  },
  // Details
  LPP_Details_About_Me: {
    en: 'About me',
    de: 'Über mich',
    ro: 'Despre mine',
  },
  LPP_Details_: {
    en: '',
    de: '',
  },
  LPP_Details_Main_Focus: {
    en: 'Main focus',
    de: 'Schwerpunkte',
    ro: 'Accentul principal',
  },
  // Map
  LPP_Map_Find_Us_Here: {
    en: 'Find us here',
    de: 'Finden Sie uns hier',
    ro: 'Găsiți-ne aici',
  },
  // Articles
  LPP_Articles_Action_Button: {
    en: 'Show Rechtsinfos',
    de: 'Zu den Rechtsinfos',
    ro: 'La informațiile juridice',
  },
  // FAQ
  LPP_FAQ_Description: {
    en: 'Here you will find answers to frequently asked questions about Anwado.',
    de: 'Hier finden Sie Antworten auf häufig gestellte Fragen rund um Anwado.',
    ro: 'Aici veți găsi răspunsuri la întrebările frecvente despre Anwado.',
  },
  LPP_FAQ_Card_1_Title: {
    en: 'Why Anwado ?',
    de: 'Warum Anwado ?',
    ro: 'De ce Anwado ?',
  },
  LPP_FAQ_Card_1_Description: {
    en: 'Via Anwado you can find the right lawyer and make an appointment directly online for an on-site meeting or for a video consultation. This saves you the time-consuming search for an appointment by phone.',
    de: `Wir machen die Dinge anders:
        Einfach, schnell und unkompliziert!
        Unglaublich Schnell durch Online-Terminvereinbarung;
        keine Kosten für Mandanten und große Auswahl an Rechtsanwälten.`,
  },
  LPP_FAQ_Card_2_Title: {
    en: 'How to find lawyers on Anwado?',
    de: 'Wie findet man Anwälte auf Anwado?',
    ro: 'Cum găsești avocați pe Anwado?',
  },
  LPP_FAQ_Card_2_Description: {
    en: 'At Anwado you will find a variety of lawyers and law firms.. From these, you can easily search for the right lawyer for you using the filter function. For lawyers who are registered as gold or platinum lawyers on Anwado, you can make an appointment directly online.',
    de: 'Bei Anwado finden Sie eine Vielzahl an Anwälten und Kanzleien. Aus denen können Sie einfach über die Filterfunktion den für Sie passenden Anwalt suchen. Bei vielen Anwälten, die als Gold- oder Platin Anwälte auf Anwado registriert sind, können Sie direkt online einen Termin vereinbaren.',
    ro: 'La Anwado veți găsi un număr mare de avocați și firme de avocatură. Puteți folosi funcția de filtrare pentru a găsi avocatul potrivit pentru dumneavoastră. Cu mulți avocați care sunt înregistrați ca avocați de aur sau de platină pe Anwado, puteți face o programare direct online.',
  },
  LPP_FAQ_Card_3_Title: {
    en: 'Legal information on many areas',
    de: 'Rechtsinfos zu vielen Bereichen',
    ro: 'Informații juridice în multe domenii',
  },
  LPP_FAQ_Card_3_Description: {
    en: 'With legal info from Anwado lawyers you can find a solution to your problem.',
    de: 'Mit den Rechtsinfos von Anwado-Anwälten können Sie eine Lösung für Ihr Problem finden.',
    ro: 'Cu ajutorul informațiilor juridice oferite de avocații Anwado, puteți găsi o soluție la problema dumneavoastră.',
  },
  LPP_FAQ_Card_4_Title: {
    en: 'Anwado - the all-in-one solution',
    de: 'Anwado - die all-in-one Lösung',
    ro: 'Anwado - soluția all-in-one',
  },
  LPP_FAQ_Card_4_Description: {
    en: 'A platform to find the right lawyer online.',
    de: 'Die all-in-one Lösung für Mandanten und Anwälte. Alles auf einer Plattform.',
    ro: 'Soluția all-in-one pentru clienți și avocați. Totul pe o singură platformă.',
  },
  LPP_FAQ_Card_5_Title: {
    en: 'Why have only some lawyers deposited pictures and additional information?',
    de: 'Warum haben nur einige Anwälte Bilder und zusätzliche Informationen hinterlegt?',
    ro: 'De ce doar unii avocați au la dosar fotografii și informații suplimentare?',
  },
  LPP_FAQ_Card_5_Description: {
    en: 'Through a paid gold and platinum entry on Anwado, lawyers have the opportunity to provide the user with additional information and images. This provides them with additional, specific information in advance, which, together with the ratings, expand and supplement the first impression.',
    de: 'Durch einen kostenpflichtigen Gold- und Platin-Eintrag auf Anwado haben Anwälte die Möglichkeit, dem Nutzer weitere Informationen und Bilder zur Verfügung zu stellen. Sie erhalten dadurch bereits im Vorfeld zusätzliche, spezifische Informationen, die zusammen mit den Bewertungen den ersten Eindruck erweitern und ergänzen.',
    ro: 'În cazul unei intrări plătite de aur și de platină pe Anwado, avocații au posibilitatea de a oferi utilizatorului informații și imagini suplimentare. În acest fel, aceștia primesc în avans informații suplimentare, specifice, care, împreună cu evaluările, extind și completează prima impresie.',
  },
  LPP_FAQ_Card_6_Title: {
    en: 'How does Anwado ensure the security of my data?',
    de: 'Wie sorgt Anwado für die Sicherheit meiner Daten? ',
    ro: 'Cum asigură Anwado securitatea datelor mele?',
  },
  LPP_FAQ_Card_6_Description: {
    en: 'Security is particularly important to us. Data protection is a top priority for us. You can find more information in our privacy policy.',
    de: 'Sicherheit ist uns besonders wichtig. Datenschutz hat für uns höchste Priorität. Weitere Informationen finden Sie in unserer Datenschutzerklärung.',
    ro: 'Securitatea este deosebit de importantă pentru noi. Protecția datelor este o prioritate absolută pentru noi. Puteți găsi mai multe informații în politica noastră de confidențialitate.',
  },
  LPP_FAQ_Question: {
    en: 'Want to meet the lawyer?',
    de: 'Möchten Sie den Anwalt treffen?',
    ro: 'Vreți să vă întâlniți cu avocatul?',
  },
  LPP_FAQ_Answer: {
    en: 'Make an appointment directly online for an on-site meeting or a telephone appointment. This saves you the time-consuming search for an appointment by telephone.',
    de: 'Vereinbaren Sie direkt online einen Termin für eine Besprechung vor Ort oder einen Telefontermin. So sparen Sie sich die zeitaufwendige telefonische Terminsuche.',
    ro: 'Faceți o programare direct online pentru o întâlnire la fața locului sau o programare telefonică. Astfel, veți economisi timpul pierdut în căutarea unei întâlniri prin telefon.',
  },
  LPP_FAQ_Answer_Not_Registered: {
    en: 'Unfortunately, <lawyer> does not currently offer appointments via Anwado.',
    de: '<lawyer> bietet aktuell leider keine Termine über Anwado an.',
    ro: 'Din păcate, <lawyer> nu oferă în prezent programări prin Anwado.',
  },
  LPP_FAQ_Action_button: {
    en: 'Book appointment now',
    de: 'Termin jetzt buchen',
    ro: 'Rezervați o programare acum',
  },
  // Forgot password
  FP_Page_1_Description: {
    en: 'No problem, we will send you an email to reset your password.',
    de: 'Kein Problem, wir senden Ihnen eine Email um Ihr Passwort zurückzusetzen.',
    ro: 'Nici o problemă, vă vom trimite un e-mail pentru a vă reseta parola.',
  },
  FP_Page_3_Title: {
    en: 'Set new password',
    de: 'Neues Passwort setzen',
    ro: 'Setați o nouă parolă',
  },
  FP_Page_3_Description: {
    en: 'Your new password must be different from previously used passwords.',
    de: 'Ihr neues Passwort muss sich von zuvor verwendeten Passwörtern unterscheiden.',
    ro: 'Noua dvs. parolă trebuie să fie diferită de parolele folosite anterior.',
  },
  FP_Page_4_Title: {
    en: 'Your new password has been successfully changed!',
    de: 'Ihr neues Passwort wurde erfolgreich geändert!',
    ro: 'Noua dvs. parolă a fost schimbată cu succes!',
  },
  FP_Page_4_Description: {
    en: 'Your password has been reset successfully. Click Next to log in.',
    de: 'Ihr Passwort wurde erfolgreich zurückgesetzt. Klicken Sie auf Weiter, um sich anzumelden.',
    ro: 'Parola dvs. a fost resetată cu succes. Faceți clic pe Next (Următorul) pentru a vă autentifica.',
  },
  // Delete Modal For Great Tier
  DM_Title: {
    en: 'Delete account not possible!',
    de: 'Konto löschen nicht möglich!',
    ro: 'Ștergerea contului nu este posibilă!',
  },
  DM_Description_1: {
    en: 'You currently have a',
    de: 'Sie haben aktuell noch eine',
    ro: 'În prezent, aveți încă un',
  },
  DM_Description_2: {
    en: '. Please switch to a Silver membership before deleting your account.',
    de: '. Bitte wechseln sie zunächst auf eine Silber Mitgliedschaft, bevor Sie ihr Konto löschen.',
    ro: '. Vă rugăm să treceți mai întâi la un abonament de argint înainte de a vă șterge contul.',
  },
  DM_Confirm_Button: {
    en: 'OK',
    de: 'OK',
    ro: 'OK',
  },
  // Delete Account
  DA_Lawyer_Delete_Title: {
    en: 'Delete profile',
    de: 'Profil löschen',
    ro: 'Ștergeți profilul',
  },
  DA_Laywer_Delete_Description_1: {
    en: 'Are you sure you want to delete your profile?',
    de: 'Möchten Sie Ihr Profil wirklich löschen?',
    ro: 'Sunteți sigur că doriți să vă ștergeți profilul',
  },
  DA_Laywer_Delete_Description_2: {
    en: 'Once your profile is deleted, all data will be permanently removed and cannot be restored',
    de: 'Sobald Ihr Profil gelöscht wurde, werden alle Daten permanent entfernt und können nicht wiederhergestellt werden',
    ro: 'Odată ce profilul dvs. este șters, toate datele vor fi șterse definitiv și nu pot fi restaurate',
  },
  DA_Delete_Lawyer_Profile_Button: {
    en: 'Delete profile permanently',
    de: 'Profil dauerhaft löschen',
    ro: 'Ștergeți profilul definitiv',
  },
  DA_Claim_Lawyer_Profile_Button: {
    en: 'Update profile',
    de: 'Profil aktualisieren',
    ro: 'Actualizare profil',
  },

  DA_Lawyer_Account_Deleted: {
    en: 'Profile successfully deleted',
    de: 'Profil erfolgreich gelöscht',
    ro: 'Profil șters cu succes',
  },
  DA_Lawyer_Account_Deleted_Description: {
    en: 'Your profile has been successfully deleted. All related data has now been removed from our system.',
    de: 'hr Profil wurde erfolgreich gelöscht. Alle damit verbundenen Daten sind nun aus unserem System entfernt.',
    ro: 'Profilul dvs. a fost șters cu succes. Toate datele conexe au fost acum eliminate din sistemul nostru.',
  },
  DA_Cannot_Delete_Account: {
    en: 'Your cannot delete your account!',
    de: 'Sie können Ihr Konto nicht löschen!',
    ro: 'Nu vă puteți șterge contul!',
  },
  DA_Appointments_Still_Exist: {
    en: 'You still have active appointments! Please cancel them first.',
    de: 'Sie haben noch aktive Termine! Bitte sagen Sie diese zuerst ab.',
    ro: 'Încă aveți programări active! Vă rugăm să le anulați mai întâi.',
  },
  DA_Delete_Title: {
    en: 'Delete',
    de: 'Löschen',
    ro: 'Ștergeți',
  },
  DA_Delete_Description: {
    en: 'Be careful, this will delete your Anwado account.',
    de: 'Vorsicht, hiermit löschen Sie Ihr Anwado-Konto.',
    ro: 'Atenție, acest lucru vă va șterge contul Anwado.',
  },
  DA_Delete_Button: {
    en: 'Delete Account',
    de: 'Konto löschen',
    ro: 'Ștergeți contul',
  },
  DA_Stepper_Title: {
    en: 'Delete Account',
    de: 'Konto löschen',
    ro: 'Ștergeți contul',
  },
  DA_Stepper_1_Description: {
    en: 'Are you sure you want to delete your account?',
    de: 'Sind Sie sicher, dass Sie Ihr Konto löschen möchten?',
    ro: 'Sunteți sigur că doriți să vă ștergeți contul?',
  },
  DA_Stepper_2_Description: {
    en: 'Your account has been successfully deleted',
    de: 'Ihr Konto wurde erfolgreich gelöscht',
    ro: 'Contul dvs. a fost șters cu succes',
  },
  DA_Page_1_Title: {
    en: 'You want to delete this account?',
    de: 'Sie wollen diesen Konto löschen?',
    ro: 'Doriți să ștergeți acest cont?',
  },
  DA_Page_1_Description_1: {
    en: 'All your premium content, availabilities, appointments and client requests will be deleted.',
    de: 'Alle Ihre Premium-Inhalte, Verfügbarkeiten, Termine und Mandantenanfragen werden gelöscht. ',
    ro: 'Tot conținutul premium, disponibilitățile, programările și cererile clienților vor fi șterse.',
  },
  DA_Page_1_Description_2: {
    en: 'Are you sure?',
    de: 'Sind Sie sich sicher?',
    ro: 'Ești sigur?',
  },
  DA_Page_2_Title: {
    en: 'You give up the following benefits...',
    de: 'Sie verzichten auf folgende Vorteile...',
    ro: 'Renunțați la următoarele avantaje...',
  },
  DA_Page_2_Advantage_Title: {
    en: 'Anwado advantages',
    de: 'Anwado Vorteile',
    ro: 'Avantajele Anwado',
  },
  DA_Page_2_Advantage_1: {
    en: 'Online appointment management',
    de: 'Online-Terminmanagement',
    ro: 'Managementul programărilor online',
  },
  DA_Page_2_Advantage_2: {
    en: 'Increased visibility through legal information publication',
    de: 'Höhere Sichtbarkeit durch Veröffentlichung von Rechtsinfos',
    ro: 'Vizibilitate crescută prin publicarea de informații juridice',
  },
  DA_Page_2_Advantage_3: {
    en: 'Personalized lawyer profile',
    de: 'Personalisiertes Anwalts-Profil',
    ro: 'Profil de avocat personalizat',
  },
  DA_Page_2_Decline_Advantages_Button: {
    en: 'Yes, delete and renounce benefits',
    de: 'Ja, löschen und auf Vorteile verzichten',
    ro: 'Da, ștergeți și renunțați la beneficii',
  },
  DA_Page_2_Decline_Delete_Acoount_Button: {
    en: 'No, keep the account!',
    de: 'Nein, Konto behalten!',
    ro: 'Nu, păstrați contul!',
  },
  DA_Page_3_Title_1: {
    en: 'Once in a lifetime chance!',
    de: 'Einmalige Chance!',
    ro: 'O șansă unică în viață!',
  },
  DA_Page_3_Title_2: {
    en: '3 months free 🎉',
    de: '3 Monate gratis 🎉',
    ro: '3 luni gratuite 🎉',
  },
  DA_Page_3_Description_1: {
    en: 'You will receive your Anwado membership 3 months free of charge and can continue to use all benefits.',
    de: 'Sie erhalten Ihre Anwado Mitgliedschaft 3 Monate kostenlos und können weiterhin alle Vorteile nutzen.',
    ro: 'Veți primi gratuit abonamentul Anwado timp de 3 luni și veți putea continua să vă bucurați de toate beneficiile.',
  },
  DA_Page_3_Description_2: {
    en: 'Sounds fair?',
    de: 'Klingt fair?',
    ro: 'Ți se pare corect?',
  },
  DA_Page_3_Decline_Button: {
    en: 'Yes, accept offer',
    de: 'Ja, Angebot annehmen',
    ro: 'Da, accept oferta',
  },
  DA_Page_3_Accept_Button: {
    en: 'Yes, delete anyway and give up benefits',
    de: 'Ja, trotzdem löschen und auf Vorteile verzichten',
    ro: 'Da, ștergeți oricum și faceți fără beneficii',
  },
  DA_Modal_Title: {
    en: 'Your account has been successfully deleted!',
    de: 'Ihre Konto wurde erfolgreich gelöscht!',
    ro: 'Contul dvs. a fost șters cu succes!',
  },
  DA_Modal_Description: {
    en: 'Your free basic profile is still available on Anwado.',
    de: 'Ihr kostenloses Basisprofil ist weiterhin auf Anwado verfügbar.',
    ro: 'Profilul dvs. de bază gratuit este încă disponibil pe Anwado.',
  },
  DA_Modal_3_Months_Description: {
    en: 'You have received 3 months subscription for free.',
    de: 'Sie haben 3 Monate Abonnement kostenlos erhalten.',
    ro: 'Ați primit 3 luni de abonament gratuit.',
  },
  DA_Modal_Success_Button: {
    en: 'OK',
    de: 'OK',
    ro: 'OK',
  },
  // Settings Menu
  SM_Title: {
    en: 'Ask',
    de: 'Fragen Sie',
    ro: 'Întrebați',
  },
  SM_Description: {
    en: 'Lorem ipsum',
    de: 'Lorem ipsum',
    ro: 'Lorem ipsum',
  },
  // Update Lawyer Public Profile
  ULPP_Error_Message: {
    en: 'An error occurred while updating the Kontoe. Please try again later.',
    de: 'Beim Aktualisieren des Kontos ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
    ro: 'A apărut o eroare în timpul actualizării contului. Vă rugăm să încercați din nou mai târziu.',
  },
  ULPP_Successful_Message: {
    en: 'Your data has been successfully updated!',
    de: 'Ihre Daten wurden erfolgreich aktualisiert!',
    ro: 'Datele dvs. au fost actualizate cu succes!',
  },
  ULPP_Profile_Title: {
    en: 'Profile information',
    de: 'Profil Informationen',
    ro: 'Informații despre profil',
  },
  ULPP_Profile_Description: {
    en: 'Please enter your information.',
    de: 'Bitte geben Sie Ihre Informationen ein.',
    ro: 'Vă rugăm să introduceți informațiile dumneavoastră.',
  },
  ULPP_Profile_Input_First_Name: {
    en: 'First name',
    de: 'Vorname',
    ro: 'Prenume',
  },
  ULPP_Profile_Input_Last_Name: {
    en: 'Last name',
    de: 'Nachname',
    ro: 'Numele de familie',
  },
  ULPP_Profile_Input_Law_Firm_Name: {
    en: 'Name of the law firm',
    de: 'Name der Anwaltskanzlei',
    ro: 'Numele firmei de avocatură',
  },
  ULPP_Profile_Input_Short_Description_Max_Chars: {
    en: ' characters left',
    de: ' Zeichen übrig',
    ro: ' Caracterele rămase',
  },
  ULPP_Contact_Title: {
    en: 'Contact information',
    de: 'Kontaktinformationen',
    ro: 'Informații de contact',
  },
  ULPP_Contact_Description: {
    en: 'Please enter the information that can be seen by the clients can be seen.',
    de: 'Bitte geben Sie die Informationen ein, die von den Mandanten gesehen werden können.',
    ro: 'Vă rugăm să introduceți informațiile care pot fi văzute de către clienți.',
  },
  ULPP_Profile_Picture_Title_1: {
    en: 'Profile picture',
    de: 'Profilbild',
    ro: 'Poza de profil',
  },
  ULPP_Profile_Picture_Description: {
    en: 'Store profile picture.',
    de: 'Profilbild hinterlegen.',
    ro: 'Imaginea de profil a magazinului.',
  },
  ULPP_About_Me_Title: {
    en: 'About me',
    de: 'Über mich',
    ro: 'Despre mine',
  },
  ULPP_About_Me_Description: {
    en: 'Deposit law firm description.',
    de: 'Beschreiben Sie hier sich und Ihre Kanzlei sowie Schwerpunkte die für Ihre Mandanten wichtig sind.',
    ro: 'Descrieți-vă pe dumneavoastră și firma dumneavoastră, precum și principalele domenii care sunt importante pentru clienții dumneavoastră.',
  },
  ULPP_Law_Firm_Description: {
    en: 'Law firm description',
    de: 'Beschreibung der Anwaltskanzlei',
    ro: 'Descrierea firmei de avocatură',
  },
  ULPP_About_Me_Form_Insert_Image: {
    en: 'Add images here',
    de: 'Fügen Sie hier Bilder ein',
    ro: 'Inserați imagini aici',
  },
  ULPP_About_Me_Form_Insert_Image_Drag_And_Drop_Text: {
    en: 'Drop the files here...',
    de: 'Legen Sie die Dateien hier ab...',
    ro: 'Puneți fișierele aici...',
  },
  ULPP_About_Me_Form_Insert_Image_Inner_Text_1: {
    en: 'Click to upload',
    de: 'Klicken zum Hochladen',
    ro: 'Faceți clic pentru a încărca',
  },
  ULPP_About_Me_Form_Insert_Image_Inner_Text_2: {
    en: 'or drag the image here',
    de: 'oder das Bild hier reinziehen',
    ro: 'sau trageți imaginea aici',
  },
  ULPP_Professional_Article_Title_1: {
    en: 'Professional article',
    de: 'Fachartikel',
    ro: 'Articol profesional',
  },
  ULPP_Professional_Article_Description: {
    en: 'Publish articles, increase your your visibility',
    de: 'Veröffentlichen Sie Fachartikel, steigern Sie Ihre Sichtbarkeit',
    ro: 'Publică articole profesionale, crește-ți vizibilitatea',
  },
  ULPP_Professional_Article_Add_Title: {
    en: 'Create a new professional article',
    de: 'Ein neuen Fachartikel erstellen',
    ro: 'Creați un nou articol profesional',
  },
  ULPP_Professional_Article_Add_Description: {
    en: 'Your professional article is online.',
    de: 'Ihr Fachartikel ist online.',
    ro: 'Articolul dvs. profesional este online.',
  },
  ULPP_Professional_Article_Empty_List: {
    en: 'No articles',
    de: 'Keine Artikel',
    ro: 'Nu există articole',
  },
  ULPP_Professional_Article_Page_Title1: {
    en: 'Page',
    de: 'Seite',
    ro: 'Pagina',
  },
  ULPP_Professional_Article_Page_Title2: {
    en: 'of',
    de: 'von',
    ro: 'de la',
  },
  ULPP_Availability_Appointment_Type: {
    en: 'Dates availability',
    de: 'Verfügbarkeit der Termine ',
    ro: 'Disponibilitatea datelor',
  },
  ULPP_Availability_In_Person_Title: {
    en: 'Personal',
    de: 'Persönlich',
    ro: 'Personal',
  },
  ULPP_Availability_In_Person_Description: {
    en: 'Conduct conversations with your clients in person',
    de: 'Führe Gespräche mit deinen Mandanten persönlich',
    ro: 'Vorbiți cu clienții dumneavoastră în persoană',
  },
  ULPP_Availability_By_Phone_Title: {
    en: 'By phone',
    de: 'Telefonisch',
    ro: 'Prin telefon',
  },
  ULPP_Availability_By_Phone_Description: {
    en: 'Conduct conversations with your clients over the phone',
    de: 'Führe Gespräche mit deinen Mandanten telefonisch',
    ro: 'Conduceți conversații telefonice cu clienții dvs.',
  },
  ULPP_Availability_Display_Availability_Title: {
    en: 'Show availability in my profile',
    de: 'Zeige Verfügbarkeit im meinem Profil an ',
    ro: 'Afișează disponibilitatea în profilul meu',
  },
  ULPP_Availability_Display_Availability_Description: {
    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    de: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    ro: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  // Add Article Modal
  AAM_Title: {
    en: 'Create new professional article',
    de: 'Neuen Fachartikel erstellen',
    ro: 'Creați un nou articol profesional',
  },
  AAM_Description: {
    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    de: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    ro: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  AAM_Cover_Image_Title: {
    en: 'Cover',
    de: 'Titelbild',
    ro: 'Copertă',
  },
  AAM_Cover_Law_Firms_Title: {
    en: 'Legal fields',
    de: 'Rechtsgebiete',
    ro: 'Domenii de drept',
  },
  AAM_Cover_Image_Description: {
    en: 'Here you can publish a picture to your professional article.',
    de: 'Hier können Sie ein Bild zu Ihrem Fachartikel veröffentlichen..',
    ro: 'Aici puteți publica o imagine a articolului dumneavoastră.',
  },
  AAM_Cover_Image_Update: {
    en: 'Update',
    de: 'Aktualisierung',
    ro: 'Actualizare',
  },
  AAM_Cover_Image_Delete: {
    en: 'Delete',
    de: 'Löschen',
    ro: 'Ștergeți',
  },
  AAM_Form_1_Title: {
    en: 'Your professional article',
    de: 'Ihr Fachartikel',
    ro: 'Articolul dvs. profesional',
  },
  AAM_Form_1_Description: {
    en: 'Here you can enter a title and a short description for your professional article.',
    de: 'Hier können Sie einen Titel und eine Kurzbeschreibung für Ihren Fachartikel eingeben.',
    ro: 'Aici puteți introduce un titlu și o scurtă descriere pentru articolul dumneavoastră tehnic.',
  },
  AAM_Form_1_Input_Title_Placeholder: {
    en: 'Please enter a title...',
    de: 'Bitte geben Sie einen Titel ein...',
    ro: 'Vă rugăm să introduceți un titlu...',
  },
  AAM_Form_1_Input_Short_Description_Title: {
    en: 'Brief description',
    de: 'Kurzbeschreibung',
    ro: 'Scurtă descriere',
  },
  AAM_Form_1_Input_Short_Description_Placeholder: {
    en: 'Please enter a description...',
    de: 'Bitte Geben Sie eine Beschreibung ein...',
    ro: 'Vă rugăm să introduceți o descriere...',
  },
  AAM_Form_1_Input_Areas_Of_Law_Title: {
    en: 'Relevant areas of law',
    de: 'Relevante Rechtsgebiete',
    ro: 'Domenii de drept relevante',
  },
  AAM_Form_1_Input_Post: {
    en: 'Post',
    de: 'Beitrag',
    ro: 'Posta',
  },
  AAM_Form_1_Input_Description_Reading_Time_1: {
    en: 'Reading time:',
    de: 'Lesedauer:',
    ro: 'Timp de lectură:',
  },
  AAM_Form_1_Input_Description_Reading_Time_2: {
    en: ' min',
    de: ' min',
    ro: ' min',
  },
  AAM_Publish: {
    en: 'Publish',
    de: 'Veröffentlichen',
    ro: 'Publică',
  },
  AAM_Created_Share_Link: {
    en: 'Share link',
    de: 'Link teilen',
    ro: 'Partajați link-ul',
  },
  // Articles Page
  AP_Meta_Title_1: {
    en: 'Anwado - Knowledge Hub and Legal Information',
    de: 'Anwado - Knowledge Hub und Rechtsinfos',
    ro: 'Anwado - Knowledge Hub și informații juridice',
  },
  AP_Meta_Description_1: {
    en: 'Visit Anwado`s Knowledge Hub for valuable legal information and articles from our editorial team.',
    de: 'Besuchen Sie den Anwado Knowledge Hub für nützliche Rechtsinfos und Beiträge unserer Redaktion.',
    ro: 'Vizitați hub-ul de cunoștințe Anwado pentru informații juridice utile și articole din partea echipei noastre editoriale.',
  },
  AP_Info: {
    en: 'Anwado Knowledge Hub',
    de: 'Anwado Knowledge Hub',
    ro: 'Centrul de cunoștințe Anwado',
  },
  AP_Title: {
    en: 'Rechtsinfos',
    de: 'Rechtsinfos',
    ro: 'Informații juridice',
  },
  AP_Description: {
    en: 'Here you will find Rechtsinfos from lawyers and articles from the Anwado editorial team.',
    de: 'Hier finden Sie Rechtsinfos von Anwälten sowie Beiträge der Anwado Redaktion.',
    ro: 'Aici veți găsi informații juridice de la avocați și articole din partea redacției Anwado.',
  },
  AP_No_Articles_Found: {
    en: 'No articles found',
    de: 'Keine Artikel gefunden',
    ro: 'Nu s-au găsit articole',
  },
  // AP_Description: {
  //   en: 'Find here legal tips and articles sorted by author or legal field',
  //   de: 'Finden Sie hier Rechtstipps und Beiträge sortiert nach Autor oder Rechtsgebieten',
  //   ro: 'Găsiți aici sfaturi și articole juridice sortate după autor sau domeniu juridic',
  // },
  // AP_Description: {
  //   en: 'Current articles on important legal issues: stay up to date',
  //   de: 'Aktuelle Beiträge zu wichtigen Rechtsfragen: Bleiben Sie immer auf dem Laufenden',
  //   ro: 'Articole de actualitate privind aspecte juridice importante: Fiți mereu la curent cu noutățile',
  // },
  AP_Next_Page: {
    en: 'Next',
    de: 'Weiter',
    ro: 'Mai departe',
  },
  AP_Any: {
    en: 'Any',
    de: 'Jede',
    ro: 'Orice',
  },
  AP_Search: {
    en: 'Search',
    de: 'Suche',
    ro: 'Căutare',
  },
  // Lawyer Settings Calendar
  LSC_Date_Overlap: {
    en: 'The date overlaps with another date',
    de: 'Das Datum überschneidet sich mit einem anderen Datum',
    ro: 'Data se suprapune cu o altă dată',
  },
  LSC_PermissionText: {
    en: 'You are not allowed to access this page until you upgrade your account...',
    de: 'Sie können diese Seite erst aufrufen, wenn Sie Ihr Konto aktualisiert haben...',
    ro: 'Nu veți putea accesa această pagină până când nu vă veți actualiza contul...',
  },
  LCS_Timeslots_Updated: {
    en: 'Availability time successfully updated!',
    de: 'Verfügbarkeitszeit erfolgreich aktualisiert!',
    ro: 'Timpul de disponibilitate a fost actualizat cu succes!',
  },
  LSC_Title: {
    en: 'General times',
    de: 'Allgemeine Zeiten',
    ro: 'Orele generale',
  },
  LSC_Description: {
    en: 'Please enter the times that can be booked directly by clients.',
    de: 'Bitte geben Sie die Zeiten ein, die von Mandanten direkt gebucht werden können.',
    ro: 'Vă rugăm să introduceți orele care pot fi rezervate direct de către clienți.',
  },
  LSC_Timeslots_Empty: {
    en: 'Please fill all the fields',
    de: 'Bitte füllen Sie alle Felder aus',
    ro: 'Vă rugăm să completați toate câmpurile',
  },
  LSC_Vacation_Settings: {
    en: 'Vacation settings',
    de: 'Urlaubseinstellungen',
    ro: 'Setări de vacanță',
  },
  LSC_Not_Enabled: {
    en: 'Not bookable',
    de: 'Nicht buchbar',
    ro: 'Nu se poate rezerva',
  },
  LSC_Create_Successful: {
    en: 'Your data has been sent successfully',
    de: 'Ihre Daten wurden erfolgreich gesendet',
    ro: 'Datele dvs. au fost trimise cu succes',
  },
  LSC_Empty_List: {
    en: 'You need to set a new holiday and then you can save it',
    de: 'Sie müssen einen neuen Feiertag festlegen und können ihn dann speichern',
    ro: 'Trebuie să setați o nouă vacanță și apoi o puteți salva',
  },
  // Lawyer set Holiday Modal
  LSHM_Description_1: {
    en: 'Please indicate vacation times when clients cannot book appointments online.',
    de: 'Bitte geben Sie Urlaubszeiten an, an denen Mandanten keine Termine online buchen können.',
    ro: 'Vă rugăm să indicați perioadele de vacanță în care clienții nu pot face programări online.',
  },
  LSHM_Title_2: {
    en: 'I am temporarily unavailable',
    de: 'Ich bin vorübergehend nicht erreichbar',
    ro: 'Sunt temporar indisponibil',
  },
  LSHM_Add_New_Holiday: {
    en: 'add another period',
    de: 'weiteren Zeitraum hinzufügen',
    ro: 'Adăugați o altă perioadă',
  },
  // Lawyer Setting Appointments
  LSA_MetaDecoration_Title: {
    en: 'Book Your Appointment | Schedule Your Visit',
    de: 'Buchen Sie Ihren Termin | Planen Sie Ihren Besuch',
    ro: 'Rezervă-ți o întâlnire | Programează-ți vizita',
  },
  LSA_MetaDecoration_Description: {
    en: 'Reserve your spot in just a few clicks. Use our easy online scheduling system to find a convenient time for your appointment.',
    de: 'Reservieren Sie Ihren Platz mit nur wenigen Klicks. Nutzen Sie unser einfaches Online-Terminierungssystem, um einen passenden Zeitpunkt für Ihren Termin zu finden.',
    ro: 'Rezervă-ți locul în doar câteva clicuri. Utilizați sistemul nostru simplu de programare online pentru a găsi o oră convenabilă pentru programarea dumneavoastră.',
  },
  LSA_Action_Button_Go_To_Talk: {
    en: 'To the conversation',
    de: 'Zum Gespräch',
    ro: 'La conversație',
  },
  LSA_Hours_Left_Text: {
    en: ' Hours',
    de: ' Stunden',
    ro: ' Ore',
  },
  LSA_Minutes_Left_Text: {
    en: ' Minutes',
    de: ' Minuten',
    ro: ' Proces-verbal',
  },
  LSA_Last_Hour_Left_Text: {
    en: ' Hour',
    de: ' Stunde',
    ro: ' Ora',
  },
  LSA_Last_Minute_Left_Text: {
    en: ' Minute',
    de: ' Minuten',
    ro: ' Proces-verbal',
  },
  // Lawyer settings appointment cancel
  LSA_Cancel_Appointment_Title: {
    en: 'Your appointment is canceled',
    de: 'Ihr Termin wird abgesagt',
    ro: 'Programarea dumneavoastră este anulată',
  },
  LSA_Appointment_Type: {
    en: 'Appointment type: ',
    de: 'Termintyp: ',
    ro: 'Tipul de numire:',
  },
  LSA_Cancel_Appointment_Input_Description_1: {
    en: 'Please explain to ',
    de: 'Bitte erläutern Sie ',
    ro: 'Vă rugăm să explicați',
  },
  LSA_Cancel_Appointment_Input_Description_2: {
    en: ' the reason for the cancellation',
    de: ' den Grund für die Absage',
    ro: ' motivul anulării',
  },
  LSA_Cancel_Appointment_Accept: {
    en: 'Cancel the event',
    de: 'Absagen',
    ro: 'Anulări',
  },
  // Lawyer settings appointment update
  LSA_Update_Appointment_Details_Button: {
    en: 'Show details',
    de: 'Details anzeigen',
    ro: 'Arată detalii',
  },
  LSA_Update_Appointment_Hide_Details_Button: {
    en: 'Hide details',
    de: 'Details ausblenden',
    ro: 'Ascundeți detaliile',
  },
  LSA_Update_Appointment_Type: {
    en: 'Event type',
    de: 'Ereignistyp',
    ro: 'Tipul de eveniment',
  },
  LSA_Update_Appointment_Call: {
    en: 'Call',
    de: 'Anruf',
    ro: 'Sunați la',
  },
  LSA_Update_Appointment_Site_Meeting: {
    en: 'On-site meeting',
    de: 'Vor-Ort Meeting',
    ro: 'Întâlnire la fața locului',
  },
  // Client Delete Account Modal
  CDAM_Important_Title: {
    en: 'Your account will be irrevocably deleted. Do you want to continue?',
    de: 'Ihr Konto wird unwiderruflich gelöscht. Möchten Sie das fortsetzten?',
    ro: 'Contul dvs. va fi șters irevocabil. Doriți să continuați?',
  },
  CDAM_Important_Label: {
    en: '',
    de: '',
  },
  CDAM_Step_1_Info_Badge: {
    en: 'Attention',
    de: 'Achtung',
    ro: 'Atenție',
  },
  CDAM_Step_1_Info_Badge_Description: {
    en: 'All your data will be deleted!',
    de: 'Alle Ihre Daten werden gelöscht!',
    ro: 'Toate datele tale vor fi șterse!',
  },
  CDAM_Step_1_Info_Description: {
    en: 'This step cannot be undone. All your personal information as well as calendar entries will be removed.',
    de: 'Dieser Schritt kann nicht rückgängig gemacht werden. Alle Ihre persönlichen Informationen sowie Kalendereinträge werden entfernt.',
    ro: 'Acest pas nu poate fi inversat. Toate informațiile dvs. personale și intrările din calendar vor fi eliminate.',
  },
  CDAM_Step_1_Info_Action_Button: {
    en: 'Show calendar entries',
    de: 'Kalendereinträge anzeigen',
    ro: 'Afișați intrările din calendar',
  },
  CDAM_Step_1_Action_Button_Accept: {
    en: 'Yes, delete',
    de: 'Ja, löschen',
    ro: 'Da, ștergeți',
  },
  CDAM_Step_2_Action_Button_Accept: {
    en: 'Delete permanently',
    de: 'Entgültig löschen',
    ro: 'Ștergeți permanent',
  },
  // Client Update Password Modal
  CDPM_Step_Title: {
    en: 'Change password',
    de: 'Passwort ändern',
    ro: 'Modificați parola',
  },
  CDPM_Step_Description: {
    en: 'Please enter your current password to change your password.',
    de: 'Bitte geben Sie Ihr aktuelles Passwort ein, um Ihr Passwort zu ändern.',
    ro: 'Vă rugăm să introduceți parola actuală pentru a vă schimba parola.',
  },
  CDPM_Step_Description_1: {
    en: 'Please enter your current password to change your password.',
    de: 'Bitte geben Sie Ihr aktuelles Passwort ein, um Ihr Passwort zu ändern.',
    ro: 'Vă rugăm să introduceți parola actuală pentru a vă schimba parola.',
  },
  CDPM_Step_Description_2: {
    en: 'Please enter your new password to change your password.',
    de: 'Bitte geben Sie Ihr neues Passwort ein, um Ihr Passwort zu ändern.',
    ro: 'Vă rugăm să introduceți noua dvs. parolă pentru a vă schimba parola.',
  },
  CDPM_Step_1_Input_Title: {
    en: 'Current Password',
    de: 'Aktuelles Passwort',
    ro: 'Parola curentă',
  },
  CDPM_Step_1_Forgot_Password: {
    en: 'Password forgotten?',
    de: 'Passwort vergessen?',
    ro: 'Ați uitat parola?',
  },
  CDPM_Step_2_New_Password_Input_Title: {
    en: 'New Password',
    de: 'Neues Passwort',
    ro: 'Parolă nouă',
  },
  CDPM_Step_2_Confirm_Password_Input_Title: {
    en: 'Confirm New Password',
    de: 'Neues Passwort bestätigen',
    ro: 'Confirmați noua parolă',
  },
  CDPM_Step_3_Confirm_Password_Title_Message: {
    en: 'Your new password has been successfully changed!',
    de: 'Ihr neues Passwort wurde erfolgreich geändert!',
    ro: 'Noua dvs. parolă a fost schimbată cu succes!',
  },
  CDPM_Step_3_Confirm_Password_Hint_Message: {
    en: 'Your password has been successfully updated. Click OK to close the window.',
    de: 'Ihr Passwort wurde erfolgreich aktualisiert. Klicken Sie auf OK, um das Fenster zu schließen.',
    ro: 'Parola dvs. a fost actualizată cu succes. Faceți clic pe OK pentru a închide fereastra.',
  },
  CDPM_Step_3_Confirm_Password_Button: {
    en: 'OK',
    de: 'OK',
    ro: 'OK',
  },
  // Client Update Email Modal
  CUEM_Title_Confirm: {
    en: 'Confirm your new email address',
    de: 'Bestätigen Sie Ihre neue Email-Adresse',
    ro: 'Confirmați noua dvs. adresă de e-mail',
  },
  CUEM_New_Login_Email: {
    en: 'New Login-Mail',
    de: 'Neue Login-Mail',
    ro: 'Noul e-mail de autentificare',
  },
  CUEM_Email_Hint: {
    en: 'Please enter a new email adress',
    de: 'Bitte geben Sie eine neue E-Mail Adresse ein',
    ro: 'Vă rugăm să introduceți o nouă adresă de e-mail',
  },
  CUEM_Verify_Password_Description: {
    en: 'Please enter your current password to change your email address.',
    de: 'Bitte geben Sie ihr aktuelles Passwort an, um Ihre Emailadresse zu ändern.',
    ro: 'Vă rugăm să introduceți parola actuală pentru a vă schimba adresa de e-mail.',
  },
  CUEM_Set_Email_Title: {
    en: 'Confirm new email address',
    de: 'Neue Emailadresse bestätigen',
    ro: 'Confirmați noua adresă de e-mail',
  },
  CUEM_Set_New_Email_Description: {
    en: 'Please enter your new email adress.',
    de: 'Bitte geben Sie Ihre neue E-Mail Adresse ein.',
    ro: 'Vă rugăm să introduceți noua dvs. adresă de e-mail.',
  },
  // Client email updated completed
  CUEM_Title: {
    en: 'Your email adress is successfully updated',
    de: 'Ihre Email-Adresse wurde erfolgreich aktualisiert',
    ro: 'Adresa dvs. de e-mail a fost actualizată cu succes',
  },
  CUEM_Description: {
    en: 'Your email address has been successfully updated in our records. All future notifications and communications will be sent to this new address. If you ever need to review or change your settings, you can easily do so from the settings page.',
    de: 'Ihre E-Mail-Adresse wurde in unseren Unterlagen erfolgreich aktualisiert. Alle zukünftigen Benachrichtigungen und Mitteilungen werden an diese neue Adresse gesendet. Wenn Sie Ihre Einstellungen jemals überprüfen oder ändern müssen, können Sie dies ganz einfach über die Einstellungsseite tun.',
    ro: 'Adresa ta de e-mail a fost actualizată cu succes în evidențele noastre. Toate notificările și comunicările viitoare vor fi trimise la această nouă adresă. Dacă vreodată trebuie să revizuiți sau să modificați setările, puteți face acest lucru cu ușurință din pagina de setări.',
  },
  // User Send Message
  USM_Title: {
    en: 'Message to',
    de: 'Nachricht an',
    ro: 'Mesaj către',
  },
  USM_Description_1: {
    en: 'Send your message here to get in touch with ',
    de: 'Senden Sie hier Ihre Nachricht, um mit ',
    ro: 'Trimiteți mesajul dvs. aici pentru a intra în contact cu',
  },
  USM_Description_2: {
    en: '',
    de: ' in Kontakt zu treten',
    ro: ' pentru a intra în contact',
  },
  USM_Confirm_Title: {
    en: 'Your message was sent successfully!',
    de: 'Ihre Nachricht wurde erfolgreich versendet!',
    ro: 'Mesajul dvs. a fost trimis cu succes!',
  },
  USM_Confirm_Description_1: {
    en: ' will get in touch with you will get back to you.',
    de: ' wird sich zeitnah bei Ihnen melden.',
    ro: ' vă va răspunde în timp util.',
  },
  USM_Confirm_Description_2: {
    en: `To save time in the future, you can create a free account on Anwado now. It's worth it!`,
    de: 'Um in Zukunft Zeit zu sparen, können Sie sich jetzt einen kostenlosen Account auf Anwado erstellen. Bitte überprüfen Sie ihr Postfach und bestätigen Sie Ihre Email Addresse. Anschließend können Sie ihr Profil aktivieren. Das lohnt sich!',
    ro: 'Pentru a economisi timp în viitor, vă puteți crea acum un cont gratuit pe Anwado. Vă rugăm să vă verificați căsuța poștală și să vă confirmați adresa de e-mail. Apoi vă puteți activa profilul. Merită!',
  },
  USM_Confirm_Button: {
    en: 'OK',
    de: 'OK',
    ro: 'OK',
  },
  USM_Title_1_Step_2: {
    en: 'Your message to',
    de: 'Ihre Nachricht an',
    ro: 'Mesajul dumneavoastră către',
  },
  USM_Title_2_Step_2: {
    en: 'will be send.',
    de: 'wird versendet.',
    ro: 'este trimisă.',
  },
  // Client Set Appointment
  CSA_Title: {
    en: 'Make an appointment',
    de: 'Vereinbaren Sie einen Termin',
    ro: 'Faceți o programare',
  },
  CSA_Description: {
    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.....',
    de: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.',
    ro: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.',
  },
  CSA_Description_Step_1: {
    en: 'First select a day and then confirm one of the available times. 30 minutes will be automatically reserved for your conversation with the lawyer of your choice.',
    de: 'Wählen Sie zunächst einen Tag aus und bestätigen Sie dann eine der verfügbaren Uhrzeiten. Für Ihr Gespäch mit Ihrem Wunschanwalt werden automatisch 30 Minuten reserviert.',
    ro: 'Selectați mai întâi o zi și apoi confirmați una dintre orele disponibile. 30 de minute sunt rezervate automat pentru conversația cu avocatul ales de dvs.',
  },

  CSA_Description_Step_2: {
    en: 'Please describe as precisely as possible what your concerns are. This way, your desired attorney can best prepare for the appointment with you.',
    de: 'Bitte beschreiben Sie möglichst genau welches Anliegen Sie haben. So kann sich Ihr Wunschanwalt am besten auf den Termin mit Ihnen vorbereiten.',
    ro: 'Vă rugăm să descrieți cât mai exact posibil preocupările dumneavoastră. În acest fel, avocatul ales de dvs. se poate pregăti cât mai bine pentru întâlnirea cu dvs.',
  },
  CSA_Topic_Placeholder: {
    en: 'Topic',
    de: 'Thema',
    ro: 'Subiect',
  },
  CSA_Topic_Title: {
    en: 'Topic',
    de: 'Thema',
    ro: 'Subiect',
  },
  CSA_Message_Placeholder: {
    en: 'Please describe',
    de: 'Bitte beschreiben Sie Ihr Anliegen',
    ro: 'Vă rugăm să descrieți cererea dvs.',
  },
  CSA_Register_Title: {
    en: 'With whom should the appointment be made?',
    de: 'Mit wem soll der Termin vereinbart werden?',
    ro: 'Cu cine ar trebui să se facă numirea?',
  },
  CSA_Register_Description: {
    en: 'We still need some personal information from you to create the appointment. To make it even easier for you to use Anwado in the future, we will also send you an email to open your optional client account. Simply confirm your email address and follow the instructions.',
    de: 'Wir benötigen noch ein paar persönliche Informationen von Ihnen, um den Termin anzulegen. Damit Sie Anwado zukünftig noch einfacher benutzen können, senden wir Ihnen Ihnen zudem eine Email, um Ihr optionales Mandantenkonto zu eröffnen. Bestätigen Sie hierzu einfach Ihre Emailadresse und folgen sie den Anweisungen.',
    ro: 'Avem totuși nevoie de câteva informații personale de la dvs. pentru a crea o programare. Pentru a vă facilita și mai mult utilizarea Anwado în viitor, vă vom trimite, de asemenea, un e-mail pentru a vă deschide contul de client opțional. Nu trebuie decât să confirmați adresa de e-mail și să urmați instrucțiunile.',
  },
  CSA_Message_Hint: {
    en: 'This message is transmitted with SSL encryption and DSGVO compliant.',
    de: 'Diese Nachricht wird mit SSL-Verschlüsselung und DSGVO-konform übermittelt. ',
    ro: 'Acest mesaj este transmis prin criptare SSL și este compatibil cu DSGVO.',
  },
  CSA_Message_Subscription_0_1: {
    en: 'Unfortunately, <lawyer> currently does not offer a messaging function via Anwado.',
    de: '<lawyer> bietet aktuell leider keine Nachrichtenfunktion über Anwado an.',
    ro: 'Din păcate, <lawyer> nu oferă în prezent o funcție de mesagerie prin Anwado.',
  },
  CSA_Cancel_Appointment_Title_Hint: {
    en: 'with ',
    de: 'mit ',
    ro: 'cu',
  },
  CSA_Create_Appointment_Message_Placeholder: {
    en: 'I have a little problem with my neighbor and I would like to introduce everything to you.',
    de: 'Ich habe ein kleines Problem mit meinem Nachbarn und würde Ihnen gerne alles vorstellen.',
    ro: 'Am o mică problemă cu vecinul meu și aș dori să vă prezint totul.',
  },
  CSA_Completed_Title: {
    en: 'Appointment successful!',
    de: 'Terminvereinbarung erfolgreich!',
    ro: 'Numirea a avut succes!',
  },
  CSA_Completed_Description: {
    en: 'Your appointment has been created. Please check your email inbox.',
    de: 'Ihr Termin wurde erstellt. Bitte überprüfen Sie ihr Emailpostfach.',
    ro: 'Numirea dvs. a fost creată. Vă rugăm să vă verificați căsuța de e-mail.',
  },
  CSA_Completed_Action_Button_Next: {
    en: 'View calendar',
    de: 'Kalender ansehen',
    ro: 'Vezi calendarul',
  },
  CSA_Ask_Lawyer_To_Set_Schedule_Description_1: {
    en: ` does not currently offer any appointments through Anwado. You can ask`,
    de: ` bietet aktuell leider keine Termine über Anwado an. Sie können `,
  },
  CSA_Request_Appointment_Miniregister_Title_1: {
    en: `Your request to `,
    de: `Ihre Anfrage an `,
  },
  CSA_Request_Appointment_Miniregister_Description_1: {
    en: `Send your request here to get in touch with `,
    de: `Senden Sie hier Ihre Anfrage und nehmen Sie Kontakt auf mit `,
  },
  CSA_Request_Appointment_Miniregister_Successful_Title: {
    en: `Your request was sent successfully!`,
    de: `Ihre Anfrage wurde erfolgreich gesendet!`,
  },

  CSA_Ask_Lawyer_To_Set_Schedule_Description_2: {
    en: `to make an appointment with you by clicking 'send a request' below.`,
    de: `bitten, einen Termin mit Ihnen zu vereinbaren, indem Sie unten auf 'eine Anfrage senden' klicken.`,
  },
  CSA_Request_Appointment_Miniregister_Description_Subscription_0_1: {
    en: `Unfortunately, <lawyer> does not currently offer appointments via Anwado.`,
    de: `<lawyer> bietet aktuell leider keine Termine über Anwado an.`,
  },
  CSA_Create_Appointment_Message_Successful_Title: {
    en: 'Your message was sent successfully!',
    de: 'Ihr Anfrage wurde erfolgreich gesendet!',
    ro: 'Cererea dvs. a fost trimisă cu succes!',
  },

  CSA_Create_Appointment_Message_Successful_Description: {
    en: 'Please check your email inbox.',
    de: 'Bitte überprüfen Sie Ihr Email-Postfach.',
    ro: 'Vă rugăm să vă verificați căsuța de e-mail.',
  },
  CSA_Update_Appointment_Confirm_Title: {
    en: 'Your meeting has been updated!',
    de: 'Ihr Meeting wurde aktualisiert!',
    ro: 'Întâlnirea dumneavoastră a fost actualizată!',
  },
  CSA_Update_Appointment_Confirm_Hint: {
    en: 'Click OK to return to the schedule.',
    de: 'Klicken Sie auf OK, um zum Zeitplan zurückzukehren.',
    ro: 'Faceți clic pe OK pentru a reveni la program.',
  },
  CSA_Update_Appointment_Confirm_Button: {
    en: 'OK',
    de: 'OK',
    ro: 'OK',
  },
  CSA_Cancel_Appointment_Confirm_Title: {
    en: 'Your meeting was successfully cancelled!',
    de: 'Ihr Meeting wurde erfolgreich abgesagt!',
    ro: 'Întâlnirea dvs. a fost anulată cu succes!',
  },
  CSA_Cancel_Appointment_Confirm_Hint: {
    en: 'Click OK to return to the schedule.',
    de: 'Klicken Sie auf OK, um zum Zeitplan zurückzukehren.',
    ro: 'Faceți clic pe OK pentru a reveni la program.',
  },
  CSA_Cancel_Appointment_Confirm_Button: {
    en: 'OK',
    de: 'OK',
    ro: 'OK',
  },
  // Review component on lawyer page
  RCLP_Pre_Title: {
    en: 'Reviews',
    de: 'Bewertungen',
    ro: 'Recenzii',
  },
  RCLP_Title: {
    en: 'Reviews on',
    de: 'Bewertungen zu',
    ro: 'Recenzii pe',
  },
  RCLP_Description: {
    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    de: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    ro: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  RCLP_Button: {
    en: 'Show all reviews',
    de: 'Zeige alle Bewertungen',
    ro: 'Afișați toate recenziile',
  },
  // Reviews Settings
  RS_Description: {
    en: '',
    de: '',
  },
  RS_From: {
    en: 'from',
    de: 'vor',
    ro: 'de la',
  },
  RS_Months_Ago: {
    en: 'Months',
    de: 'Monaten',
    ro: 'Luni',
  },
  RS_Month_Ago: {
    en: 'Month',
    de: 'Monat',
    ro: 'Luna',
  },
  RS_Years_Ago: {
    en: 'Years',
    de: 'Jahren',
    ro: 'Ani',
  },
  RS_Year_Ago: {
    en: 'Year',
    de: 'Jahr',
    ro: 'Anul',
  },
  RS_Update: {
    en: 'Update rating',
    de: 'Bewertung aktualisieren',
    ro: 'Actualizarea ratingului',
  },
  RS_Delete: {
    en: 'Delete rating',
    de: 'Bewertung löschen',
    ro: 'Ștergeți ratingul',
  },
  RS_Report: {
    en: 'Report',
    de: 'Melden',
    ro: 'Raport',
  },
  RS_Rating_From: {
    en: 'Rating from',
    de: 'Bewertung von',
    ro: 'Rating de la',
  },
  RS_Report_Rating_From: {
    en: 'Reporting the rating from',
    de: 'Melden der Bewertung vom',
    ro: 'Raportarea evaluării de la',
  },
  RS_Respond: {
    en: 'Respond',
    de: 'Antworten',
    ro: 'Răspunsuri',
  },
  RS_Tick_As_Appropriate: {
    en: 'Please tick as appropriate',
    de: 'Bitte kreuzen Sie zutreffendes an:',
    ro: 'Vă rugăm să bifați ce este necesar:',
  },
  RS_Report_Reason_1: {
    en: 'Does not correspond to the truth',
    de: 'Entspricht nicht der Wahrheit',
    ro: 'Nu corespunde adevărului',
  },
  RS_Report_Reason_2: {
    en: 'No client',
    de: 'Kein Mandant',
    ro: 'Nici un client',
  },
  RS_Report_Reason_3: {
    en: 'Inappropriate language',
    de: 'Unangemessene Sprache',
    ro: 'Limbaj nepotrivit',
  },
  RS_Report_Input_Hint: {
    en: 'Anwado Support will get back to you in a timely manner.',
    de: 'Der Anwado Support wird sich zeitnah bei Ihnen melden.',
    ro: 'Serviciul de asistență Anwado vă va contacta în cel mai scurt timp posibil.',
  },
  RS_Response_Input_Hint: {
    en: 'Your answer will be publicly visible.',
    de: 'Ihre Antwort wird öffentlich sichtbar.',
    ro: 'Răspunsul dvs. va fi vizibil public.',
  },
  RS_Rating_Not_True: {
    en: 'This rating is not true?',
    de: 'Diese Bewertung entspricht nicht der Wahrheit?',
    ro: 'Această evaluare nu este adevărată?',
  },
  RS_Report_Now: {
    en: 'Report it now!',
    de: 'Jetzt melden!',
    ro: 'Raportează acum!',
  },
  RS_Confirm_Button_Message: {
    en: 'Close',
    de: 'Schließen',
    ro: 'Închideți',
  },
  RS_Response_Successful_Title: {
    en: 'Your answer has been published successfully!',
    de: 'Ihre Antwort wurde erfolgreich veröffentlicht!',
    ro: 'Răspunsul dvs. a fost publicat cu succes!',
  },
  RS_Report_Successful_Title: {
    en: 'Your message has been successfully mediated!',
    de: 'Ihre Meldung wurde erfolgreich gesendet!',
    ro: 'Mesajul dvs. a fost salvat cu succes!',
  },
  RS_Report_Successful_Description: {
    en: 'Anwado Support will get back to you shortly.',
    de: 'Der Anwado Support wird sich zeitnah bei Ihnen melden.',
    ro: 'Serviciul de asistență Anwado vă va contacta în cel mai scurt timp posibil.',
  },
  RS_Api_Error: {
    en: 'Something went wrong. Please try again later.',
    de: 'Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal.',
    ro: 'Ceva a mers prost. Vă rugăm să încercați din nou mai târziu.',
  },
  RS_Review_Deleted: {
    en: 'Your rating has been deleted successfully!',
    de: 'Ihre Bewertung wurde erfolgreich gelöscht!',
    ro: 'Evaluarea ta a fost ștearsă cu succes!',
  },
  RS_Report_Input_Placeholder: {
    en: 'Please describe why you would like to report this review.',
    de: 'Bitte schildern Sie uns warum Sie diese Bewertung melden möchten.',
    ro: 'Vă rugăm să ne spuneți de ce doriți să raportați acest rating.',
  },
  RS_Response_Input_Placeholder: {
    en: 'Please reply to this rating',
    de: 'Bitte anworten Sie auf diese Bewertung',
    ro: 'Vă rugăm să răspundeți la această evaluare',
  },
  RS_Report_a_Review: {
    en: 'Report a review',
    de: 'Eine Bewertung melden',
    ro: 'Raportați un rating',
  },
  RS_Submit_Report: {
    en: 'Publish',
    de: 'Veröffentlichen',
    ro: 'Publică',
  },
  RSD_Title: {
    en: 'Your rating will be deleted permanently!',
    de: 'Ihre Bewertung wird entgültig gelöscht!',
    ro: 'Ratingul tău va fi șters definitiv!',
  },
  // Review Setting Delete
  RSD_Input_Hint: {
    en: 'This is a hint text to help user.',
    de: '',
  },
  // Add Review Modal
  ARM_Title: {
    en: 'Rate your experience',
    de: 'Bewerten Sie Ihre Erfahrung',
    ro: 'Evaluați-vă experiența',
  },
  ARM_Description: {
    en: 'Share your experience and help others find the right lawyer. You will remain anonymous. Thank you very much!',
    de: 'Teilen Sie Ihre Erfahrungen und helfen Sie anderen damit, den richtigen Anwalt zu finden. Sie bleiben dabei anonym. Vielen Dank!',
    ro: 'Împărtășiți experiența dumneavoastră și ajutați-i pe alții să găsească avocatul potrivit. Veți rămâne anonim. Vă mulțumim foarte mult!',
  },
  ARM_Input_1_Title: {
    en: 'Please give your review a title:',
    de: 'Bitte geben Sie Ihrer Bewertung eine Überschrift:',
    ro: 'Vă rugăm să dați un titlu evaluării dumneavoastră:',
  },
  ARM_Input_1_Placeholder: {
    en: 'Summarize your experience',
    de: 'Fassen Sie Ihre Erfahrung zusammen',
    ro: 'Rezumați experiența dumneavoastră',
  },
  ARM_Input_2_Title: {
    en: 'Your rating:',
    de: 'Ihre Bewertung:',
    ro: 'Evaluarea dumneavoastră:',
  },
  ARM_Input_2_Placeholder: {
    en: 'What did you particularly like? What rather not?',
    de: 'Was hat Ihnen besonders gut gefallen? Was eher nicht?',
    ro: 'Ce ți-a plăcut în mod deosebit? Ce nu v-a plăcut?',
  },
  ARM_Confirmation_Title: {
    en: 'Please confirm:',
    de: 'Bitte bestätigen:',
    ro: 'Vă rugăm să confirmați:',
  },
  ARM_Confirmation_Description: {
    en: 'Hereby I assure that my evaluation corresponds to my personal experience. Apart from the consultation, I have no personal or business relationship with ',
    de: 'Hiermit versichere ich, dass meine Bewertung meiner persönlichen Erfahrung entspricht. Abgesehen von der Beratung, stehe ich in keiner persönlichen oder geschäftlichen Beziehung zu ',
    ro: 'Prin prezenta, garantez că evaluarea mea corespunde experienței mele personale. În afară de consultație, nu am nicio relație personală sau de afaceri cu',
  },
  ARM_Action_Button_Accept: {
    en: 'Submit rating',
    de: 'Bewertung abschicken',
    ro: 'Trimiteți ratingul',
  },
  ARM_Action_Button_Return: {
    en: 'Back to Profile Overview',
    de: 'Zurück zur Profilübersicht',
    ro: 'Înapoi la prezentarea generală a profilului',
  },
  // ADD Review Modal succes
  ARM_Succes_Title: {
    en: 'Thank you for your review!',
    de: 'Vielen Dank für Ihre Bewertung!',
    ro: 'Vă mulțumim pentru recenzia dumneavoastră!',
  },
  ARM_Succes_Description: {
    en: 'Thank you for your feedback, your work supports our community and helps others find the best lawyer!',
    de: 'Vielen Dank für Ihr Feedback, Ihre Arbeit unterstützt unsere Community und hilft anderen dabei, den besten Anwalt zu finden!',
    ro: 'Vă mulțumim pentru feedback, munca dvs. sprijină comunitatea noastră și îi ajută pe alții să găsească cel mai bun avocat!',
  },
  ARM_Succes_Action_Button_Accept: {
    en: 'OK',
    de: 'OK',
    ro: 'OK',
  },
  // Account Update Settings
  AUS_Email_Title: {
    en: 'Account (Login-Mail)',
    de: 'Konto (Login-Emailadresse)',
    ro: 'Cont (Adresa de e-mail de conectare)',
  },
  AUS_Email_Description: {
    en: 'Email address with which you created your account.',
    de: 'Emailadresse, mit dem Sie Ihr Konto angelegt haben.',
    ro: 'Adresa de e-mail pe care ați folosit-o pentru a vă crea contul.',
  },
  AUS_Password_Title: {
    en: 'Password',
    de: 'Passwort',
    ro: 'Parola',
  },
  AUS_Password_Description: {
    en: 'Please choose a password with at least 8 characters.',
    de: 'Bitte wählen ein Passwort mit mindestens 8 Zeichen.',
    ro: 'Vă rugăm să alegeți o parolă cu cel puțin 8 caractere.',
  },
  AUS_Email_Update_Button: {
    en: 'Change email address',
    de: 'Emailadresse ändern',
    ro: 'Modificarea adresei de e-mail',
  },
  // Lawyer Settings Subscription
  LSS_Title: {
    en: 'Subscription for lawyers',
    de: 'Abonnement für Anwälte',
    ro: 'Abonament pentru avocați',
  },
  LSS_Actual_Plan_Title: {
    en: 'Current Subscription',
    de: 'Aktuelles Abonnement',
    ro: 'Abonament curent',
  },
  LSS_Get_Started_Plan_Title: {
    en: 'Get Started',
    de: 'Loslegen',
    ro: 'Pleacă',
  },
  LSS_cancel_schedule_Plan_Title: {
    en: 'Cancel my schedule',
    de: 'Umstellung stornieren',
    ro: 'Anulați schimbarea',
  },
  LSS_Downgrade_Plan_Title_1: {
    en: 'Switch to ',
    de: 'Auf ',
    ro: 'La',
  },
  LSS_Downgrade_Plan_Title_2: {
    en: ' Membership',
    de: ' Mitgliedschaft umstellen',
    ro: ' Conversia calității de membru',
  },
  LSS_upgrade_Plan_Title_1: {
    en: 'Update to ',
    de: 'Update zur ',
    ro: 'Actualizare cu privire la',
  },
  LSS_upgrade_Plan_Title_2: {
    en: ' Membership',
    de: ' Mitgliedschaft',
    ro: ' Afilierea',
  },
  LSS_Plan_Month: {
    en: 'per month',
    de: 'pro Monat',
    ro: 'pe lună',
  },
  LSS_Plan_Year: {
    en: 'per year',
    de: 'pro Jähr',
    ro: 'pe an',
  },
  LSS_Plan_Upgrade: {
    en: 'Manage plan',
    de: 'Mitgliedschaft verwalten',
    ro: 'Gestionați calitatea de membru',
  },
  LSS_Payment_Method_Title: {
    en: 'Payment method',
    de: 'Bezahlmethode',
    ro: 'Metoda de plată',
  },
  LSS_Payment_Method_Description: {
    en: '',
    de: '',
  },
  LSS_Payment_Method_Action_Button: {
    en: 'Update information',
    de: 'Informationen aktualisieren',
    ro: 'Informații de actualizare',
  },
  LSS_Invoices_Title: {
    en: 'Invoices',
    de: 'Rechnungen',
    ro: 'Facturi',
  },
  LSS_Invoices_Action_Button: {
    en: 'Download all',
    de: 'Alle herunterladen',
    ro: 'Descărcați toate',
  },
  // Lawyer Settings update subscription
  LSUS_Update_Notification_Message: {
    en: 'The upgrade has been done succesfully!',
    de: 'Das Upgrade ist erfolgreich durchgeführt worden!',
    ro: 'Upgrade-ul a fost făcut cu succes!',
  },
  LSUS_Cancellation_Notification_Message: {
    en: 'The cancellation has been done succesfully!',
    de: 'Die Löschung ist erfolgreich durchgeführt worden!',
    ro: 'Anularea a fost făcută cu succes!',
  },
  LSUS_Downgrade_Notification_Message: {
    en: 'The downgrade has been done succesfully!',
    de: 'Das Downgrade ist erfolgreich durchgeführt worden!',
    ro: 'Downgrade-ul a fost făcut cu succes!',
  },
  LSUS_MetaDecorator_Title: {
    en: 'Subscribe Today | Unleash Premium Access and Benefits',
    de: 'Abonnieren Sie noch heute | Nutzen Sie Premium-Zugang und Vorteile',
    ro: 'Abonați-vă astăzi | Dezlănțuiți acces premium și beneficii',
  },
  LSUS_MetaDecorator_Description: {
    en: 'Become a member and enjoy exclusive benefits. Access premium content, receive special offers, and stay connected. Subscribe today!',
    de: 'Werden Sie Mitglied und genießen Sie exklusive Vorteile. Greifen Sie auf Premium-Inhalte zu, erhalten Sie Sonderangebote und bleiben Sie in Verbindung. Abonnieren Sie noch heute!',
    ro: 'Deveniți membru și bucurați-vă de beneficii exclusive. Accesați conținut premium, primiți oferte speciale și rămâneți conectat. Abonați-vă astăzi!',
  },
  LSUS_Title: {
    en: 'With Anwado to more clients',
    de: 'Mit Anwado zu mehr Mandanten',
    ro: 'Cu Anwado la mai mulți clienți',
  },
  LSUS_Description: {
    en: 'Anwado prices and services at a glance',
    de: 'Die Anwado Preise und Leistungen im Überblick',
    ro: 'Prețurile ci serviciile Anwado dintr-o privire',
  },
  LSUS_Switch_Month: {
    en: 'Monthly payment',
    de: 'Monatliche Zahlung',
    ro: 'Plata lunară',
  },
  LSUS_Switch_Year: {
    en: 'Annual payment',
    de: 'Jährliche Zahlung',
    ro: 'Plata anuală',
  },
  LSUS_Most_Popular: {
    en: 'Our recommendation',
    de: 'Unsere Empfehlung',
    ro: 'Recomandarea noastră',
  },
  LSUS_Cancel_Schedule_Title_1: {
    en: 'Are you sure you want to cancel your schedule for',
    de: 'Sind Sie sicher, dass Sie Ihr geplantes',
    ro: 'Sunteți sigur că doriți să vă anulați planificarea pentru abonamentul',
  },
  LSUS_Cancel_Schedule_Title_2: {
    en: ' - subscription',
    de: '-Mitgliedschaft stornieren möchten',
    ro: '',
  },
  LSUS_Update_Title_Monthly: {
    en: 'monthly',
    de: 'monatlich',
    ro: 'lunar',
  },
  LSUS_Update_Title_Yearly: {
    en: 'yearly',
    de: 'jährlich',
    ro: 'anual',
  },
  LSUS_Update_Description_Monthly: {
    en: '(monthly).',
    de: '(monatlich).',
    ro: '(lunar).',
  },
  LSUS_Update_End_Of_Current_Subscription_Yearly: {
    en: 'yearly subscription',
    de: 'Jahresabonnement',
    ro: 'Abonament anual',
  },
  LSUS_Update_End_Of_Current_Subscription_Monthly: {
    en: 'monthly subscription',
    de: 'Monatsabonnement',
    ro: 'Abonament lunar',
  },
  LSUS_Update_End_Of_Current_Subscription: {
    en: 'will take place at the end of your current subscription on',
    de: 'erfolgt zum Ende Ihres aktuellen Abonnements am',
    ro: 'va avea loc la sfârșitul abonamentului dvs. actual, la ',
  },
  LSUS_Update_Description_Yearly: {
    en: '(yearly).',
    de: '(jährlich).',
    ro: '(anual).',
  },
  LSUS_Downgrade_Title_1: {
    en: 'Are you sure you want to change your current',
    de: 'Sind Sie sicher, dass Sie Ihre aktuelle',
    ro: 'Sunteți sigur că doriți să vă schimbați actuala subscripție',
  },
  LSUS_Update_Title_1: {
    en: 'Are you sure you want to update your',
    de: 'Sind Sie sicher, dass Sie Ihre aktuelle',
    ro: 'Sunteți sigur că doriți să vă păstrați actuala',
  },
  LSUS_Update_Title_2_1: {
    en: ' subscription',
    de: '-Mitgliedschaft',
    ro: '',
  },
  LSUS_Update_Title_2_2: {
    en: ' in',
    de: ' in eine',
    ro: ' la subscripția',
  },
  LSUS_Update_Title_3_1: {
    en: ' subscription',
    de: '-Mitgliedschaft',
    ro: '',
  },
  LSUS_Update_Title_3_2: {
    en: '?',
    de: ' ändern möchten?',
    ro: '?',
  },
  LSUS_Update_Description: {
    en: 'Price information',
    de: 'Preisinformationen',
    ro: 'Informații despre preț',
  },
  LSUS_Update_Badge: {
    en: 'Price',
    de: 'Preis',
    ro: 'Preț',
  },
  LSUS_Cancel_Schedule_Information_1: {
    en: 'If you accept this cancel planned',
    de: 'Wenn Sie dieses Stornierung des geplanten ',
    ro: 'Dacă anulați această planificare',
  },
  LSUS_Cancel_Schedule_Information_2: {
    en: ' - subscription, the switch will be cancelled and you will remain in the current subscription.',
    de: '-Mitgliedschaft akzeptieren, wird der Wechsel storniert und Sie verbleiben im aktuellen Mitgliedschaft.',
    ro: ', aceasta va fi anulată și veți rămâne cu subscripția setată anterior.',
  },
  LSUS_Update_Information_1: {
    en: 'If you accept this subscription, we will charge you the following costs ',
    de: 'Wenn Sie dieses Abonnement akzeptieren, werden wir Ihnen folgende Kosten in Rechnung stellen. ',
    ro: 'Dacă acceptați acest abonament, vă vom percepe următoarele taxe:',
  },
  LSUS_Update_Information_2: {
    en: '(zzgl. Mwst.) for',
    de: '(zzgl. Mwst.) für eine',
    ro: '(plus TVA) pentru subscripția',
  },
  LSUS_Update_Information_3: {
    en: ' subscription',
    de: '-Mitgliedschaft',
    ro: '',
  },
  LSUS_Update_Information_4: {
    en: 'The conversion ',
    de: 'Die Umstellung ',
    ro: 'Conversia ',
  },
  LSUS_Update_Information_5: {
    en: 'will end on',
    de: 'erfolgt ',
    ro: 'se termină pe',
  },
  LSUS_Update_Button: {
    en: 'Show calendar entries',
    de: 'Kalendereinträge anzeigen',
    ro: 'Afișați intrările din calendar',
  },
  LSUS_Update_Button_Accept: {
    en: 'Upgrade',
    de: 'Ändern',
    ro: 'Schimbare',
  },
  LSUS_Update_Button_Cancel: {
    en: 'Cancel',
    de: 'Akzeptieren',
    ro: 'Anulează',
  },
  LSUS_Downgrade_Button: {
    en: 'Downgrade',
    de: 'Herabstufung',
    ro: 'Schimbare',
  },

  // lawyer subscription card silver
  LSUS_Card_Silver_Description: {
    en: 'Your start in the Anwado community.',
    de: 'Ihr Start in die Anwado Community.',
    ro: 'Începutul tău în comunitatea Anwado.',
  },
  LSUS_Card_Silver_Info_Title: {
    en: 'Included in Silver',
    de: 'in Silber enthalten',
    ro: 'Inclus în argint',
  },
  LSUS_Card_Silver_Info_Description: {
    en: '',
    de: '',
  },
  LSUS_Card_Silver_Info_1: {
    en: 'Manage profile data',
    de: 'Profildaten verwalten',
    ro: 'Gestionați datele de profil',
  },
  LSUS_Card_Silver_Info_2: {
    en: 'Linking to your law firm website',
    de: 'Verlinkung auf Ihre Kanzlei-Website',
    ro: 'Link către site-ul firmei de avocatură',
  },
  LSUS_Card_Silver_Info_3: {
    en: 'Notification when a new rating is received',
    de: 'Benachrichtigung, wenn eine neue Bewertung eingeht',
    ro: 'Notificare la primirea unui nou rating',
  },
  LSUS_Card_Silver_Info_4: {
    en: 'Comment ratings',
    de: 'Bewertungen kommentieren',
    ro: 'Comentariul ratingurilor',
  },
  LSUS_Card_Silver_Info_5: {
    en: 'Report reviews',
    de: 'Bewertungen melden',
    ro: 'Raportați recenzii',
  },
  LSUS_Card_Silver_Info_6: {
    en: 'Sustainably increase reputation through ratings',
    de: 'Reputation durch Bewertungen nachhaltig steigern',
    ro: 'Creșterea durabilă a reputației prin ratinguri',
  },
  // lawyer subscription card gold
  LSUS_Card_Gold_Description: {
    en: 'Your start in the Anwado community.',
    de: 'Ihr Start in die Anwado Community.',
    ro: 'Începutul tău în comunitatea Anwado.',
  },
  LSUS_Card_Price_Description_1: {
    en: 'You will have to pay the full price of ',
    de: 'Sie bezahlen den vollen Preis von ',
    ro: 'Plătiți prețul integral al',
  },
  LSUS_Card_Price_Description_2: {
    en: ' upfront (plus MwSt).',
    de: ' im Voraus (zzgl. MwSt).',
    ro: ' în avans (plus TVA).',
  },

  LSUS_Card_Gold_Info_Title: {
    en: 'Included in gold',
    de: 'in Gold enthalten',
    ro: 'Inclus în aur',
  },
  LSUS_Card_Gold_Info_Description: {
    en: 'All in Silver plus....',
    de: 'Alle Silber-Leistungen und....',
    ro: 'Toate realizările de argint și....',
  },
  LSUS_Card_Gold_Info_1: {
    en: 'Online appointment management',
    de: 'Online Terminmanagement',
    ro: 'Gestionarea online a programărilor',
  },
  LSUS_Card_Gold_Info_2: {
    en: 'Client appointments directly on the Anwado search page',
    de: 'Mandantentermine direkt auf der Anwado-Suchseite buchbar',
    ro: 'Programările clienților pot fi rezervate direct de pe pagina de căutare Anwado',
  },
  LSUS_Card_Gold_Info_3: {
    en: 'Individual lawyer profile',
    de: 'Individuelles Anwaltsprofil',
    ro: 'Profilul avocatului individual',
  },
  LSUS_Card_Gold_Info_4: {
    en: 'Portrait picture',
    de: 'Portraitbild',
    ro: 'Portret',
  },
  LSUS_Card_Gold_Info_5: {
    en: 'Prominent short description in your profile',
    de: 'Prominente Kurzbeschreibung in Ihrem Profil',
    ro: 'Scurtă descriere proeminentă în profilul dvs.',
  },
  LSUS_Card_Gold_Info_6: {
    en: 'Individual office photos',
    de: 'Erweiterter Profileintrag mit individuellen Kanzleifotos',
    ro: 'Intrare de profil extinsă cu fotografii individuale ale firmei de avocatură',
  },
  LSUS_Card_Gold_Info_7: {
    en: 'Detailed content with indication of focal points',
    de: 'Ausführlicher Inhalt mit Angabe von Schwerpunkten',
    ro: 'Conținutul detaliat cu indicarea punctelor de interes',
  },
  LSUS_Card_Gold_Info_8: {
    en: 'Publish professional articles with wide online reach',
    de: 'Fachartikel publizieren mit großer Online-Reichweite',
    ro: 'Publicarea de articole profesionale cu o largă acoperire online',
  },
  // lawyer subscription card platinum
  LSUS_Card_Platin_Description: {
    en: 'Your start in the Anwado community.',
    de: 'Ihr Start in die Anwado Community.',
    ro: 'Începutul tău în comunitatea Anwado.',
  },
  LSUS_Card_Platin_Info_Title: {
    en: 'included in platinum',
    de: 'in Platin enthalten',
    ro: 'inclus în platină',
  },
  LSUS_Card_Platin_Info_Description: {
    en: 'All in gold plus....',
    de: 'Alle Gold-Leistungen und....',
    ro: 'Toate realizările de aur și....',
  },
  LSUS_Card_Platin_Info_1: {
    en: 'Top positioning in Anwado search',
    de: 'Top-Positionierung in der Anwado-Suche',
    ro: 'Poziționare de top în căutarea Anwado',
  },
  LSUS_Card_Platin_Info_2: {
    en: 'Be found by clients first',
    de: 'Werden Sie von Mandanten zuerst gefunden',
    ro: 'Fiți găsit primul de către clienți',
  },
  LSUS_Card_Platin_Info_3: {
    en: 'High-quality content for your profile, created according to your specifications by the Anwado editorial team',
    de: 'Hochwertige Inhalte für Ihr Profil, nach Ihren Vorgaben von der Anwado Redaktion erstellt',
    ro: 'Conținut de înaltă calitate pentru profilul tău, creat în conformitate cu specificațiile tale de către echipa editorială Anwado',
  },
  LSUS_Card_Platin_Info_4: {
    en: 'Priorised customer support',
    de: 'Priorisierter Kunderservice',
    ro: 'Serviciu prioritar pentru clienți',
  },
  // lawyer subscription questions
  LSUS_FAQ_Title: {
    en: 'Frequently asked questions',
    de: 'Häufige Fragen',
    ro: 'Întrebări frecvente',
  },
  LSUS_FAQ_Description: {
    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    de: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    ro: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  LSUS_FAQ_Question_1_Title: {
    en: 'Is there a free trial available?',
    de: 'Gibt es eine kostenlose Testversion?',
    ro: 'Există o versiune de încercare gratuită?',
  },
  LSUS_FAQ_Question_1_Answer: {
    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    de: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    ro: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  LSUS_FAQ_Question_2_Title: {
    en: 'Can I change my plan later?',
    de: 'Kann ich meinen Plan später ändern?',
    ro: 'Pot să îmi schimb planul mai târziu?',
  },
  LSUS_FAQ_Question_2_Answer: {
    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.2',
    de: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.2',
    ro: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.2',
  },
  LSUS_FAQ_Question_3_Title: {
    en: 'What is your cancellation policy?',
    de: 'Wie lauten Ihre Stornierungsbedingungen?',
    ro: 'Care sunt condițiile de anulare?',
  },
  LSUS_FAQ_Question_3_Answer: {
    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.3',
    de: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.3',
    ro: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.3',
  },
  LSUS_FAQ_Question_4_Title: {
    en: 'Can other info be added to an invoice?',
    de: 'Können einer Rechnung weitere Informationen hinzugefügt werden?',
    ro: 'Se pot adăuga mai multe informații la o factură?',
  },
  LSUS_FAQ_Question_4_Answer: {
    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.4',
    de: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.4',
    ro: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.4',
  },
  LSUS_FAQ_Question_5_Title: {
    en: 'How does billing work?',
    de: 'Wie funktioniert die Rechnungsstellung?',
    ro: 'Cum funcționează facturarea?',
  },
  LSUS_FAQ_Question_5_Answer: {
    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.5Lorem ipsum dolor sit amet, consectetur adipiscing elit.5Lorem ipsum dolor sit amet, consectetur adipiscing elit.5Lorem ipsum dolor sit amet, consectetur adipiscing elit.5',
    de: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.5Lorem ipsum dolor sit amet, consectetur adipiscing elit.5Lorem ipsum dolor sit amet, consectetur adipiscing elit.5Lorem ipsum dolor sit amet, consectetur adipiscing elit.5',
    ro: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.5Lorem ipsum dolor sit amet, consectetur adipiscing elit.5Lorem ipsum dolor sit amet, consectetur adipiscing elit.5Lorem ipsum dolor sit amet, consectetur adipiscing elit.5Lorem ipsum dolor sit amet, consectetur adipiscing elit.5',
  },
  LSUS_FAQ_Question_6_Title: {
    en: 'How do I change my account email?',
    de: 'Wie ändere ich die E-Mail-Adresse meines Kontos?',
    ro: 'Cum pot schimba adresa de e-mail din contul meu?',
  },
  LSUS_FAQ_Question_6_Answer: {
    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.6 Lorem ipsum dolor sit amet, consectetur adipiscing elit.6 Lorem ipsum dolor sit amet, consectetur adipiscing elit.6Lorem ipsum dolor sit amet, consectetur adipiscing elit.6',
    de: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.6 Lorem ipsum dolor sit amet, consectetur adipiscing elit.6 Lorem ipsum dolor sit amet, consectetur adipiscing elit.6Lorem ipsum dolor sit amet, consectetur adipiscing elit.6',
    ro: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.6 Lorem ipsum dolor sit amet, consectetur adipiscing elit.6 Lorem ipsum dolor sit amet, consectetur adipiscing elit.6Lorem ipsum dolor sit amet, consectetur adipiscing elit.6Lorem ipsum dolor sit amet, consectetur adipiscing elit.6',
  },
  LSUS_Bottom_Box_Action_Button: {
    en: 'Contact sales',
    de: 'Vertrieb kontaktieren',
    ro: 'Contact vânzări',
  },
  // Static pages
  // Privacy Page
  PP_Description_Meta_Title_1: {
    en: 'Anwado - Privacy Policy',
    de: 'Anwado - Datenschutzerklärung',
    ro: 'Anwado - Politica de confidențialitate',
  },
  PP_Description_Meta_Description_1: {
    en: 'At Anwado, the protection of your personal data is important to us. Learn how we collect and process your data.',
    de: 'Bei Anwado ist uns der Schutz Ihrer personenbezogenen Daten wichtig. Erfahren Sie, wie wir Ihre Daten erheben und verarbeiten.',
    ro: 'La Anwado, protecția datelor dumneavoastră personale este importantă pentru noi. Aflați cum colectăm și procesăm datele dumneavoastră.',
  },
  PP_Pre_Title: {
    en: 'Stand Februar 2024',
    de: 'Stand Februar 2024',
    ro: 'Stand Februar 2024',
  },
  PP_Title: {
    en: 'Datenschutzerklärung',
    de: 'Datenschutzerklärung',
    ro: 'Politica de confidențialitate',
  },
  PP_Description: {
    en: 'Our privacy policy and cookie statement secure the protection of personal data and the transparent use of cookies on Anwado.',
    de: 'Unsere Datenschutz- und Cookie-Erklärung sichern den Schutz persönlicher Daten und die transparente Verwendung von Cookies auf Anwado.',
    ro: 'Declarația noastră privind confidențialitatea și cookie-urile asigură protecția datelor personale și utilizarea transparentă a cookie-urilor pe Anwado.',
  },
  PP_Paragraph: {
    en: 'Der Schutz Ihrer personenbezogenen Daten ist für uns ein wichtiges Anliegen. Wir informieren Sie nachfolgend im Detail über die Erhebung und Verarbeitung personenbezogener Daten im Rahmen unserer Dienste.',
    de: 'Der Schutz Ihrer personenbezogenen Daten ist für uns ein wichtiges Anliegen. Wir informieren Sie nachfolgend im Detail über die Erhebung und Verarbeitung personenbezogener Daten im Rahmen unserer Dienste.',
    ro: 'Protecția datelor dumneavoastră personale este o preocupare importantă pentru noi. Vă informăm în detaliu mai jos despre colectarea și prelucrarea datelor cu caracter personal în cadrul serviciilor noastre.',
  },
  PP_Paragraph_1_Title: {
    en: 'Verantwortliche Stelle und Kontakt',
    de: 'Verantwortliche Stelle und Kontakt',
    ro: 'Organismul responsabil și persoana de contact',
  },
  PP_Paragraph_1_1: {
    en: `Name und Kontaktdaten des Verantwortlichen Verantwortlich für die Datenverarbeitung auf `,
    de: `Name und Kontaktdaten des Verantwortlichen Verantwortlich für die Datenverarbeitung auf `,
  },
  PP_Paragraph_1_2: {
    en: `www.anwado.de`,
    de: `www.anwado.de`,
  },
  PP_Paragraph_1_3: {
    en: ` ist die Anwado GmbH Gartenstadtstraße 10 81825 München E-Mail: support@Anwado.de`,
    de: ` ist die Anwado GmbH Gartenstadtstraße 10 81825 München E-Mail: support@Anwado.de`,
  },
  PP_Paragraph_2_Title: {
    en: 'Betrieb der Website',
    de: 'Betrieb der Website',
    ro: 'Operarea site-ului web',
  },
  PP_Paragraph_2: {
    en: 'Die Verarbeitung personenbezogener Daten ist erforderlich, um Ihnen die auf der Webseite angebotenen Informationen zur Verfügung stellen zu können. Sofern Sie auf der Website angebotene Dienste (z.B. eine Registrierungsfunktion oder ein Kontaktformular) nutzen, verarbeiten wir die dabei eingegebenen Daten nur zur Erbringung des gewünschten Dienstes. Soweit in den folgenden Abschnitten nichts anderes angegeben ist, ist die Rechtsgrundlage für die damit verbundene Datenverarbeitung jeweils Art. 6 Abs. 1 lit. f DSGVO (Interessenabwägung - basierend auf unserem Interesse, den Internetnutzern diese Website zur Verfügung zu stellen).',
    de: 'Die Verarbeitung personenbezogener Daten ist erforderlich, um Ihnen die auf der Webseite angebotenen Informationen zur Verfügung stellen zu können. Sofern Sie auf der Website angebotene Dienste (z.B. eine Registrierungsfunktion oder ein Kontaktformular) nutzen, verarbeiten wir die dabei eingegebenen Daten nur zur Erbringung des gewünschten Dienstes. Soweit in den folgenden Abschnitten nichts anderes angegeben ist, ist die Rechtsgrundlage für die damit verbundene Datenverarbeitung jeweils Art. 6 Abs. 1 lit. f DSGVO (Interessenabwägung - basierend auf unserem Interesse, den Internetnutzern diese Website zur Verfügung zu stellen).',
    ro: 'Prelucrarea datelor cu caracter personal este necesară pentru a vă oferi informațiile oferite pe site. În cazul în care utilizați serviciile oferite pe site-ul web (de exemplu, o funcție de înregistrare sau un formular de contact), prelucrăm datele introduse în cadrul procesului doar pentru a furniza serviciul solicitat. Cu excepția cazului în care se prevede altfel în următoarele secțiuni, temeiul juridic pentru prelucrarea datelor asociate este, în fiecare caz, art. 6 alin. 1 lit. f DSGVO (echilibrarea intereselor - pe baza interesului nostru de a pune acest site web la dispoziția utilizatorilor de internet).',
  },
  PP_Paragraph_3_Title: {
    en: 'Verarbeitung Ihrer personenbezogenen Daten',
    de: 'Verarbeitung Ihrer personenbezogenen Daten',
    ro: 'Prelucrarea datelor dumneavoastră cu caracter personal',
  },
  PP_Paragraph_3: {
    en: 'In diesem Abschnitt der Datenschutzerklärung informieren wir Sie ausführlich über die Verarbeitung personenbezogener Daten im Rahmen unserer Dienste. Zur besseren Übersichtlichkeit gliedern wir diese Informationen nach bestimmten Funktionalitäten unserer Dienste. Bei der normalen Nutzung der Dienste können verschiedene Funktionalitäten und damit verschiedene Verarbeitungen nacheinander oder gleichzeitig zur Anwendung kommen.',
    de: 'In diesem Abschnitt der Datenschutzerklärung informieren wir Sie ausführlich über die Verarbeitung personenbezogener Daten im Rahmen unserer Dienste. Zur besseren Übersichtlichkeit gliedern wir diese Informationen nach bestimmten Funktionalitäten unserer Dienste. Bei der normalen Nutzung der Dienste können verschiedene Funktionalitäten und damit verschiedene Verarbeitungen nacheinander oder gleichzeitig zur Anwendung kommen.',
    ro: 'În această secțiune a politicii de confidențialitate, vă informăm în detaliu despre prelucrarea datelor cu caracter personal în cadrul serviciilor noastre. Pentru o mai bună claritate, structurăm aceste informații în funcție de anumite funcționalități ale serviciilor noastre. În timpul utilizării normale a serviciilor, diferite funcționalități și, prin urmare, diferite operațiuni de prelucrare pot fi aplicate una după alta sau în același timp.',
  },
  PP_Paragraph_3_1_1_Title: {
    en: 'Kontaktaufnahme',
    de: 'Kontaktaufnahme',
    ro: 'Contactați',
  },
  PP_Paragraph_3_1_1: {
    en: 'Auf unserer Website bieten wir Ihnen die Möglichkeit, über ein Formular oder per E-Mail mit uns in Kontakt zu treten. Nachfolgend erläutern wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn Sie mit uns Kontakt aufnehmen (z.B. per Kontaktformular oder E-Mail).',
    de: 'Auf unserer Website bieten wir Ihnen die Möglichkeit, über ein Formular oder per E-Mail mit uns in Kontakt zu treten. Nachfolgend erläutern wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn Sie mit uns Kontakt aufnehmen (z.B. per Kontaktformular oder E-Mail).',
    ro: 'Pe site-ul nostru vă oferim posibilitatea de a ne contacta prin intermediul unui formular sau prin e-mail. Mai jos vă explicăm modul în care sunt prelucrate datele dvs. personale atunci când ne contactați (de exemplu, prin intermediul formularului de contact sau prin e-mail).',
  },
  PP_Paragraph_3_1_2_Title: {
    en: 'Verarbeitete Daten: ',
    de: 'Verarbeitete Daten: ',
    ro: 'Date prelucrate:',
  },
  PP_Paragraph_3_1_2: {
    en: 'Vorname, Nachname, E-Mail-Adresse, Telefonnummer, Inhalt des Anliegens',
    de: 'Vorname, Nachname, E-Mail-Adresse, Telefonnummer, Inhalt des Anliegens',
    ro: 'Numele, prenumele, adresa de e-mail, numărul de telefon, conținutul cererii',
  },
  PP_Paragraph_3_1_3_Title: {
    en: 'Zweck: ',
    de: 'Zweck: ',
    ro: 'Scop:',
  },
  PP_Paragraph_3_1_3: {
    en: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt darin, Ihr Anliegen zuordnen und beantworten zu können. Sofern Sie uns eine Anfrage per E-Mail senden oder gesendet haben, wird der Inhalt Ihrer Nachricht sowie Ihre E-Mail-Adresse an unsere Ticketing-Plattform Freshdesk des Dienstleisters Freshworks Inc. (2950 S. Delaware Street, Suite 201, San Mateo, CA 94403) übermittelt. Der Einsatz der Ticketing-Plattform erfolgt zum Zwecke der Kundenbetreuung und der Verwaltung von Nutzeranfragen mittels eines Ticketsystems, um Nutzeranfragen effizient und sicher bearbeiten zu können (Art. 6 Abs. 1 lit. f DSGVO). Freshworks verarbeitet Ihre Daten nur insoweit, wie dies zur Erfüllung der Leistungspflichten erforderlich ist und hat unsere Weisungen in Bezug auf diese Daten zu befolgen. Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter geschlossen. Freshworks hat Compliance-Maßnahmen für internationale Datenübermittlungen umgesetzt. Diese gelten für alle weltweiten Aktivitäten, bei denen Freshworks personenbezogene Daten von natürlichen Personen in der EU verarbeitet. Diese Maßnahmen basieren auf den EU-Standardvertragsklauseln (SCCs). Freshworks ist nach dem EU-U.S. Data Privacy Framework zertifiziert.',
    de: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt darin, Ihr Anliegen zuordnen und beantworten zu können. Sofern Sie uns eine Anfrage per E-Mail senden oder gesendet haben, wird der Inhalt Ihrer Nachricht sowie Ihre E-Mail-Adresse an unsere Ticketing-Plattform Freshdesk des Dienstleisters Freshworks Inc. (2950 S. Delaware Street, Suite 201, San Mateo, CA 94403) übermittelt. Der Einsatz der Ticketing-Plattform erfolgt zum Zwecke der Kundenbetreuung und der Verwaltung von Nutzeranfragen mittels eines Ticketsystems, um Nutzeranfragen effizient und sicher bearbeiten zu können (Art. 6 Abs. 1 lit. f DSGVO). Freshworks verarbeitet Ihre Daten nur insoweit, wie dies zur Erfüllung der Leistungspflichten erforderlich ist und hat unsere Weisungen in Bezug auf diese Daten zu befolgen. Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter geschlossen. Freshworks hat Compliance-Maßnahmen für internationale Datenübermittlungen umgesetzt. Diese gelten für alle weltweiten Aktivitäten, bei denen Freshworks personenbezogene Daten von natürlichen Personen in der EU verarbeitet. Diese Maßnahmen basieren auf den EU-Standardvertragsklauseln (SCCs). Freshworks ist nach dem EU-U.S. Data Privacy Framework zertifiziert.',
    ro: 'Scopul prelucrării datelor dumneavoastră cu caracter personal este acela de a vă putea aloca și răspunde la cererea dumneavoastră de informații. Dacă ne trimiteți sau ne-ați trimis o solicitare prin e-mail, conținutul mesajului dvs. și adresa dvs. de e-mail vor fi transmise platformei noastre de ticketing Freshdesk a furnizorului de servicii Freshworks Inc. (2950 S. Delaware Street, Suite 201, San Mateo, CA 94403). Platforma de ticketing este utilizată în scopul serviciului clienți și al administrării solicitărilor utilizatorilor prin intermediul unui sistem de bilete pentru a putea procesa în mod eficient și sigur solicitările utilizatorilor (art. 6 alin. 1 lit. f GDPR). Freshworks prelucrează datele dvs. numai în măsura în care este necesar pentru a-și îndeplini obligațiile de performanță și trebuie să respecte instrucțiunile noastre cu privire la aceste date. Am încheiat un acord de prelucrare a datelor (DPA) cu furnizorul menționat mai sus. Freshworks a implementat măsuri de conformitate pentru transferurile internaționale de date. Acestea se aplică tuturor activităților globale în care Freshworks prelucrează date cu caracter personal ale persoanelor fizice din UE. Aceste măsuri se bazează pe clauzele contractuale standard ale UE (SCC). Freshworks este certificată în conformitate cu Cadrul UE-SUA privind confidențialitatea datelor.',
  },
  PP_Paragraph_3_1_4_Title: {
    en: 'Rechtsgrundlage: ',
    de: 'Rechtsgrundlage: ',
    ro: 'Temeiul juridic:',
  },
  PP_Paragraph_3_1_4: {
    en: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient Zwecke der Kundenbetreuung und der Verwaltung von Nutzeranfragen mittels eines Ticketsystems, um Nutzeranfragen effizient und sicher bearbeiten zu können (Art. 6 Abs. 1 lit. f DSGVO) sowie nach Art. 6 Abs. 1 b) DSGVO der Durchführung vorvertraglicher Maßnahmen.',
    de: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient Zwecke der Kundenbetreuung und der Verwaltung von Nutzeranfragen mittels eines Ticketsystems, um Nutzeranfragen effizient und sicher bearbeiten zu können (Art. 6 Abs. 1 lit. f DSGVO) sowie nach Art. 6 Abs. 1 b) DSGVO der Durchführung vorvertraglicher Maßnahmen.',
    ro: 'Prelucrarea datelor cu caracter personal descrise servește scopurilor de asistență pentru clienți și de administrare a solicitărilor utilizatorilor prin intermediul unui sistem de tichete, pentru a putea procesa solicitările utilizatorilor în mod eficient și sigur (art. 6 alin. 1 lit. f din GDPR) și, în conformitate cu art. 6 alin. 1 lit. b) din GDPR, pentru punerea în aplicare a măsurilor precontractuale.',
  },
  PP_Paragraph_3_1_5_Title: {
    en: 'Speicherdauer: ',
    de: 'Speicherdauer: ',
    ro: 'Perioada de depozitare:',
  },
  PP_Paragraph_3_1_5: {
    en: 'Sobald die von Ihnen gestellte Anfrage erledigt und Sie kein weitergehendes Interesse an einer Zusammenarbeit haben, werden die personenbezogenen Daten gelöscht. Sofern Ihre personenbezogenen Daten bei bestehendem Interesse an einer Zusammenarbeit in unsere Datenbank übertragen werden, gelten die einschlägigen gesetzlichen Verjährungs- und Aufbewahrungsfristen.',
    de: 'Sobald die von Ihnen gestellte Anfrage erledigt und Sie kein weitergehendes Interesse an einer Zusammenarbeit haben, werden die personenbezogenen Daten gelöscht. Sofern Ihre personenbezogenen Daten bei bestehendem Interesse an einer Zusammenarbeit in unsere Datenbank übertragen werden, gelten die einschlägigen gesetzlichen Verjährungs- und Aufbewahrungsfristen.',
    ro: 'De îndată ce solicitarea pe care ați făcut-o a fost soluționată și nu mai aveți niciun interes de cooperare, datele cu caracter personal vor fi șterse. În cazul în care datele dvs. cu caracter personal sunt transferate în baza noastră de date în cazul unui interes existent pentru o cooperare, se aplică perioadele de limitare și de păstrare prevăzute de lege relevante.',
  },
  PP_Paragraph_3_2_1_Title: {
    en: 'Registrierung und Anmeldung',
    de: 'Registrierung und Anmeldung',
    ro: 'Înregistrare și autentificare',
  },
  PP_Paragraph_3_2_1: {
    en: `Wir bieten Ihnen auf unserer Website die Möglichkeit, sich unter Angabe personenbezogener Daten zu registrieren. Mit den verarbeiteten Daten richten wir für Sie ein individualisiertes Benutzerkonto ein, mit dem Sie unsere Dienste nutzen können.`,
    de: `Wir bieten Ihnen auf unserer Website die Möglichkeit, sich unter Angabe personenbezogener Daten zu registrieren. Mit den verarbeiteten Daten richten wir für Sie ein individualisiertes Benutzerkonto ein, mit dem Sie unsere Dienste nutzen können.`,
  },
  PP_Paragraph_3_2_2_Pre_Title: {
    en: 'Registrierung Nutzer',
    de: 'Registrierung Nutzer',
    ro: 'Înregistrarea utilizatorului',
  },
  PP_Paragraph_3_2_2_Title: {
    en: 'Verarbeitete Daten: ',
    de: 'Verarbeitete Daten: ',
    ro: 'Date prelucrate:',
  },
  PP_Paragraph_3_2_2: {
    en: 'Vorname, Nachname, E-Mail-Adresse, Straße, Hausnummer, PLZ, Stadt, Telefonnummer',
    de: 'Vorname, Nachname, E-Mail-Adresse, Straße, Hausnummer, PLZ, Stadt, Telefonnummer',
    ro: 'Nume, prenume, adresa de e-mail, strada, numărul casei, codul poștal, orașul, numărul de telefon.',
  },
  PP_Paragraph_3_2_3_Title: {
    en: 'Zweck: ',
    de: 'Zweck: ',
    ro: 'Scop:',
  },
  PP_Paragraph_3_2_3: {
    en: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Bereitstellung eines Anwado-Profils.',
    de: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Bereitstellung eines Anwado-Profils.',
    ro: 'Scopul prelucrării datelor dumneavoastră cu caracter personal este de a vă oferi un profil Anwado.',
  },
  PP_Paragraph_3_2_4_Title: {
    en: 'Rechtsgrundlage: ',
    de: 'Rechtsgrundlage: ',
    ro: 'Temeiul juridic:',
  },
  PP_Paragraph_3_2_4: {
    en: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH.',
    de: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH.',
    ro: 'În conformitate cu art. 6 (1) b) DSGVO, prelucrarea datelor cu caracter personal menționate servește la îndeplinirea unui contract între dumneavoastră și Anwado GmbH.',
  },
  PP_Paragraph_3_2_5_Title: {
    en: 'Speicherdauer: ',
    de: 'Speicherdauer: ',
    ro: 'Perioada de depozitare:',
  },
  PP_Paragraph_3_2_5: {
    en: `Sobald die verarbeiteten Daten für die Vertragserfüllung nicht mehr erforderlich sind, werden sie gelöscht. Auch nach Beendigung des Vertrages kann es erforderlich sein, Ihre personenbezogenen Daten zur Erfüllung vertraglicher oder gesetzlicher Pflichten aufzubewahren.`,
    de: `Sobald die verarbeiteten Daten für die Vertragserfüllung nicht mehr erforderlich sind, werden sie gelöscht. Auch nach Beendigung des Vertrages kann es erforderlich sein, Ihre personenbezogenen Daten zur Erfüllung vertraglicher oder gesetzlicher Pflichten aufzubewahren.`,
  },
  PP_Paragraph_3_2_6_Pre_Title: {
    en: 'Registrierung Kunde (Anwalt)',
    de: 'Registrierung Kunde (Anwalt)',
    ro: 'Înregistrarea clientului (avocat)',
  },
  PP_Paragraph_3_2_6_Title: {
    en: 'Verarbeitete Daten: ',
    de: 'Verarbeitete Daten: ',
    ro: 'Date prelucrate:',
  },
  PP_Paragraph_3_2_6: {
    en: `Vorname, Nachname, E-Mail-Adresse, evtl. Kreditkarte (Nummer, Ablaufdatum, CVC, Name des Karteninhabers, Land, Lastschriftmandant (IBAN, BIC, Name), Passwort`,
    de: `Vorname, Nachname, E-Mail-Adresse, evtl. Kreditkarte (Nummer, Ablaufdatum, CVC, Name des Karteninhabers, Land, Lastschriftmandant (IBAN, BIC, Name), Passwort`,
  },
  PP_Paragraph_3_2_7_Title: {
    en: 'Zweck: ',
    de: 'Zweck: ',
    ro: 'Scop:',
  },
  PP_Paragraph_3_2_7: {
    en: `Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Bereitstellung eines Anwado-Profils.`,
    de: `Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Bereitstellung eines Anwado-Profils.`,
  },
  PP_Paragraph_3_2_8_Title: {
    en: 'Rechtsgrundlage: ',
    de: 'Rechtsgrundlage: ',
    ro: 'Temeiul juridic:',
  },
  PP_Paragraph_3_2_8: {
    en: `Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH.`,
    de: `Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH.`,
  },
  PP_Paragraph_3_2_9_Title: {
    en: 'Speicherdauer: ',
    de: 'Speicherdauer: ',
    ro: 'Perioada de depozitare:',
  },
  PP_Paragraph_3_2_9: {
    en: `Sobald die verarbeiteten Daten für die Vertragserfüllung nicht mehr erforderlich sind, werden sie gelöscht. Auch nach Beendigung des Vertrages kann es erforderlich sein, Ihre personenbezogenen Daten zur Erfüllung vertraglicher oder gesetzlicher Pflichten aufzubewahren.`,
    de: `Sobald die verarbeiteten Daten für die Vertragserfüllung nicht mehr erforderlich sind, werden sie gelöscht. Auch nach Beendigung des Vertrages kann es erforderlich sein, Ihre personenbezogenen Daten zur Erfüllung vertraglicher oder gesetzlicher Pflichten aufzubewahren.`,
  },
  PP_Paragraph_3_2_10_Pre_Title: {
    en: 'Profil Nutzer',
    de: 'Profil Nutzer',
    ro: 'Profilul utilizatorului',
  },
  PP_Paragraph_3_2_11_Title: {
    en: 'Verarbeitete Daten: ',
    de: 'Verarbeitete Daten: ',
    ro: 'Date prelucrate:',
  },
  PP_Paragraph_3_2_11: {
    en: 'Anrede, Titel, Vorname, Nachname, Straße, Hausnummer, Stadt, PLZ, Land, Telefonnummer, E-Mail, Profilbild',
    de: 'Anrede, Titel, Vorname, Nachname, Straße, Hausnummer, Stadt, PLZ, Land, Telefonnummer, E-Mail, Profilbild',
    ro: 'Salutul, titlul, numele, prenumele, numele de familie, strada, numărul casei, orașul, codul poștal, țara, numărul de telefon, e-mail, poza de profil',
  },
  PP_Paragraph_3_2_12_Title: {
    en: 'Zweck: ',
    de: 'Zweck: ',
    ro: 'Scop:',
  },
  PP_Paragraph_3_2_12: {
    en: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Bereitstellung eines Anwado-Profils.',
    de: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Bereitstellung eines Anwado-Profils.',
    ro: 'Scopul prelucrării datelor dumneavoastră cu caracter personal este de a vă oferi un profil Anwado.',
  },
  PP_Paragraph_3_2_13_Title: {
    en: 'Rechtsgrundlage: ',
    de: 'Rechtsgrundlage: ',
    ro: 'Temeiul juridic:',
  },
  PP_Paragraph_3_2_13: {
    en: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH.',
    de: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH.',
    ro: 'În conformitate cu art. 6 (1) b) DSGVO, prelucrarea datelor cu caracter personal menționate servește la îndeplinirea unui contract între dumneavoastră și Anwado GmbH.',
  },
  PP_Paragraph_3_2_14_Title: {
    en: 'Speicherdauer: ',
    de: 'Speicherdauer: ',
    ro: 'Perioada de depozitare:',
  },
  PP_Paragraph_3_2_14: {
    en: 'Sobald die verarbeiteten Daten für die Vertragserfüllung nicht mehr erforderlich sind, werden sie gelöscht. Auch nach Beendigung des Vertrages kann es erforderlich sein, Ihre personenbezogenen Daten zur Erfüllung vertraglicher oder gesetzlicher Pflichten aufzubewahren.',
    de: 'Sobald die verarbeiteten Daten für die Vertragserfüllung nicht mehr erforderlich sind, werden sie gelöscht. Auch nach Beendigung des Vertrages kann es erforderlich sein, Ihre personenbezogenen Daten zur Erfüllung vertraglicher oder gesetzlicher Pflichten aufzubewahren.',
    ro: 'De îndată ce datele prelucrate nu mai sunt necesare pentru îndeplinirea contractului, acestea vor fi șterse. Chiar și după încetarea contractului, poate fi necesar să se păstreze datele dvs. cu caracter personal pentru a îndeplini obligațiile contractuale sau legale.',
  },
  PP_Paragraph_3_2_15_Pre_Title: {
    en: 'Basisdaten Rechtsanwalt',
    de: 'Basisdaten Rechtsanwalt',
    ro: 'Avocat de date de bază',
  },
  PP_Paragraph_3_2_15: {
    en: 'Als Basisdaten veröffentlichen wir berufsbezogene Daten von niedergelassenen Rechtsanwälten. Diese Profildaten sind aus öffentlichen Quellen frei zugänglich. Das geschäftsmäßige Erheben, Speichern, Auflisten und Nutzen öffentlich zugänglicher personenbezogener Daten ist nach Art. 6 Abs. 1 lit. f) DSGVO zulässig. Die Öffentlichkeit hat ein Interesse daran, schnell und umfassend auf Daten über Rechtsanwälte zugreifen zu können. Zu diesem Zweck stellen wir unseren Nutzern diese Daten hier zur Verfügung.',
    de: 'Als Basisdaten veröffentlichen wir berufsbezogene Daten von niedergelassenen Rechtsanwälten. Diese Profildaten sind aus öffentlichen Quellen frei zugänglich. Das geschäftsmäßige Erheben, Speichern, Auflisten und Nutzen öffentlich zugänglicher personenbezogener Daten ist nach Art. 6 Abs. 1 lit. f) DSGVO zulässig. Die Öffentlichkeit hat ein Interesse daran, schnell und umfassend auf Daten über Rechtsanwälte zugreifen zu können. Zu diesem Zweck stellen wir unseren Nutzern diese Daten hier zur Verfügung.',
    ro: 'Ca date de bază, publicăm date profesionale de la avocații în exercițiu. Aceste date de profil sunt libere accesibile din surse publice. Colectarea comercială, stocarea, listarea și utilizarea datelor cu caracter personal accesibile publicului sunt permise în conformitate cu articolul 6 alineatul (1) lit. f) GDPR. Publicul este interesat de a putea accesa rapid și cuprinzător datele despre avocați. În acest scop, punem aceste date la dispoziția utilizatorilor noștri aici.',
  },
  PP_Paragraph_3_2_16_Title: {
    en: 'Verarbeitete Daten: ',
    de: 'Verarbeitete Daten: ',
    ro: 'Date prelucrate:',
  },
  PP_Paragraph_3_2_16: {
    en: 'Anrede, Name (inkl. Titel und akademische Grade), Kanzleiname, Kanzleiadresse (Straße, Hausnummer, PLZ, Ort, Land), Telefon, E-Mail-Adresse, Fachanwaltschaft(en).',
    de: 'Anrede, Name (inkl. Titel und akademische Grade), Kanzleiname, Kanzleiadresse (Straße, Hausnummer, PLZ, Ort, Land), Telefon, E-Mail-Adresse, Fachanwaltschaft(en).',
    ro: 'Salutare, nume (inclusiv titlul și gradele academice), numele cabinetului de avocatură, adresa cabinetului de avocatură (strada, numărul casei, codul poștal, localitatea, țara), telefon, fax, adresa de e-mail, site-ul web (al cabinetului de avocatură), specialitatea (specialitățile), domeniul (domeniile) de drept',
  },
  PP_Paragraph_3_2_17_Title: {
    en: 'Zweck: ',
    de: 'Zweck: ',
    ro: 'Scop:',
  },
  PP_Paragraph_3_2_17: {
    en: 'Bereitstellung von Informationen über anwaltliche Leistungen für Webseitenbesucher; Förderung der freien Rechtsanwaltswahl; Schaffung von Möglichkeiten zur Meinungsäußerung und zum Meinungsaustausch zwischen Mandanten und Rechtsanwälten, Schaffung von mehr Leistungstransparenz im Anwaltswesen.',
    de: 'Bereitstellung von Informationen über anwaltliche Leistungen für Webseitenbesucher; Förderung der freien Rechtsanwaltswahl; Schaffung von Möglichkeiten zur Meinungsäußerung und zum Meinungsaustausch zwischen Mandanten und Rechtsanwälten, Schaffung von mehr Leistungstransparenz im Anwaltswesen.',
    ro: 'Furnizarea de informații despre serviciile juridice pentru vizitatorii site-ului; promovarea alegerii libere a avocaților; crearea de oportunități pentru exprimarea și schimbul de opinii între clienți și avocați, crearea unei mai mari transparențe a serviciilor în profesia juridică.',
  },
  PP_Paragraph_3_2_18_Title: {
    en: 'Rechtsgrundlage: ',
    de: 'Rechtsgrundlage: ',
    ro: 'Temeiul juridic:',
  },
  PP_Paragraph_3_2_18: {
    en: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 f) DSGVO dem öffentlichen Interesse daran, sich Daten über Rechtsanwälte verschaffen zu können. Die in unserem Verzeichnis gelisteten Basisdaten stammen aus öffentlich zugänglichen Quellen.',
    de: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 f) DSGVO dem öffentlichen Interesse daran, sich Daten über Rechtsanwälte verschaffen zu können. Die in unserem Verzeichnis gelisteten Basisdaten stammen aus öffentlich zugänglichen Quellen.',
    ro: 'În conformitate cu articolul 6 alineatul (1) litera (f) din DSGVO, prelucrarea datelor cu caracter personal menționate servește interesului public de a putea obține date despre avocați. Datele de bază enumerate în directorul nostru provin din surse accesibile publicului.',
  },
  PP_Paragraph_3_2_19_Title: {
    en: 'Speicherdauer: ',
    de: 'Speicherdauer: ',
    ro: 'Perioada de depozitare:',
  },
  PP_Paragraph_3_2_19: {
    en: 'Sobald die Rechtsgrundlage weggefallen ist; in der Regel bis zum Ende der Tätigkeit, die zur Aufnahme in unser Verzeichnis geführt hat, werden die Daten gelöscht.',
    de: 'Sobald die Rechtsgrundlage weggefallen ist; in der Regel bis zum Ende der Tätigkeit, die zur Aufnahme in unser Verzeichnis geführt hat, werden die Daten gelöscht.',
    ro: 'De îndată ce temeiul juridic a încetat să mai existe; de regulă, la sfârșitul activității care a dus la includerea în directorul nostru, datele vor fi șterse.',
  },
  PP_Paragraph_3_2_20_Pre_Title: {
    en: 'Profil Löschen',
    de: 'Profil Löschen',
    ro: 'Ștergeți profilul',
  },
  PP_Paragraph_3_2_20_Title: {
    en: 'Verarbeitete Daten: ',
    de: 'Verarbeitete Daten: ',
    ro: 'Date prelucrate:',
  },
  PP_Paragraph_3_2_20: {
    en: 'E-Mail-Adresse, Passwort',
    de: 'E-Mail-Adresse, Passwort',
    ro: 'Adresa de e-mail, parola',
  },
  PP_Paragraph_3_2_21_Title: {
    en: 'Zweck: ',
    de: 'Zweck: ',
    ro: 'Scop:',
  },
  PP_Paragraph_3_2_21: {
    en: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Löschung Ihres Anwado-Profils.',
    de: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Löschung Ihres Anwado-Profils.',
    ro: 'Scopul prelucrării datelor dvs. personale este de a vă șterge profilul Anwado.',
  },
  PP_Paragraph_3_2_22_Title: {
    en: 'Rechtsgrundlage: ',
    de: 'Rechtsgrundlage: ',
    ro: 'Temeiul juridic:',
  },
  PP_Paragraph_3_2_22: {
    en: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH.',
    de: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH.',
    ro: 'În conformitate cu art. 6 (1) b) DSGVO, prelucrarea datelor cu caracter personal menționate servește la îndeplinirea unui contract între dumneavoastră și Anwado GmbH.',
  },
  PP_Paragraph_3_2_23_Title: {
    en: 'Speicherdauer: ',
    de: 'Speicherdauer: ',
    ro: 'Perioada de depozitare:',
  },
  PP_Paragraph_3_2_23: {
    en: 'Sobald die verarbeiteten Daten für die Vertragserfüllung nicht mehr erforderlich sind, werden sie gelöscht. Auch nach Beendigung des Vertrages kann es erforderlich sein, Ihre personenbezogenen Daten zur Erfüllung vertraglicher oder gesetzlicher Pflichten aufzubewahren.',
    de: 'Sobald die verarbeiteten Daten für die Vertragserfüllung nicht mehr erforderlich sind, werden sie gelöscht. Auch nach Beendigung des Vertrages kann es erforderlich sein, Ihre personenbezogenen Daten zur Erfüllung vertraglicher oder gesetzlicher Pflichten aufzubewahren.',
    ro: 'De îndată ce datele prelucrate nu mai sunt necesare pentru îndeplinirea contractului, acestea vor fi șterse. Chiar și după încetarea contractului, poate fi necesar să se păstreze datele dvs. cu caracter personal pentru a îndeplini obligațiile contractuale sau legale.',
  },
  PP_Paragraph_3_3_1_Title: {
    en: 'Bewertungen schreiben und kommentieren',
    de: 'Bewertungen schreiben und kommentieren',
    ro: 'Scrieți recenzii și comentați',
  },
  PP_Paragraph_3_3_1_Pre_Title: {
    en: 'Bewertung veröffentlichen',
    de: 'Bewertung veröffentlichen',
    ro: 'Publicarea ratingului',
  },
  PP_Paragraph_3_3_1: {
    en: 'Im Folgenden erläutern wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn Sie auf Anwado.de eine Bewertung verfassen und veröffentlichen.',
    de: 'Im Folgenden erläutern wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn Sie auf Anwado.de eine Bewertung verfassen und veröffentlichen.',
    ro: 'În cele ce urmează, vă explicăm modul în care sunt prelucrate datele dumneavoastră personale atunci când scrieți și publicați o recenzie pe Anwado.de.',
  },
  PP_Paragraph_3_3_2_Title: {
    en: 'Verarbeitete Daten: ',
    de: 'Verarbeitete Daten: ',
    ro: 'Date prelucrate:',
  },
  PP_Paragraph_3_3_2: {
    en: 'Inhalt der Bewertung Überschrift der Bewertung; Wenn nicht angemeldet: Vorname, Nachname, E-Mail.',
    de: 'Inhalt der Bewertung Überschrift der Bewertung; Wenn nicht angemeldet: Vorname, Nachname, E-Mail.',
    ro: 'Conținutul evaluării Titlul evaluării; Dacă nu sunteți autentificat: Nume, prenume, e-mail',
  },
  PP_Paragraph_3_3_3_Title: {
    en: 'Zweck: ',
    de: 'Zweck: ',
    ro: 'Scop:',
  },
  PP_Paragraph_3_3_3: {
    en: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Veröffentlichung der Bewertung sowie der inhaltlichen Prüfung der Bewertung auf Einhaltung unserer Nutzungsrichtlinien.',
    de: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Veröffentlichung der Bewertung sowie der inhaltlichen Prüfung der Bewertung auf Einhaltung unserer Nutzungsrichtlinien.',
    ro: 'Scopul prelucrării datelor dumneavoastră cu caracter personal este de a publica ratingul și de a verifica conținutul ratingului pentru a verifica conformitatea acestuia cu orientările noastre de utilizare.',
  },
  PP_Paragraph_3_3_4_Title: {
    en: 'Rechtsgrundlage: ',
    de: 'Rechtsgrundlage: ',
    ro: 'Temeiul juridic:',
  },
  PP_Paragraph_3_3_4: {
    en: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH.',
    de: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH.',
    ro: 'În conformitate cu art. 6 (1) b) DSGVO, prelucrarea datelor cu caracter personal menționate servește la îndeplinirea unui contract între dumneavoastră și Anwado GmbH.',
  },
  PP_Paragraph_3_3_5_Title: {
    en: 'Speicherdauer: ',
    de: 'Speicherdauer: ',
    ro: 'Perioada de depozitare:',
  },
  PP_Paragraph_3_3_5: {
    en: 'Sobald die verarbeiteten Daten für die Vertragserfüllung nicht mehr erforderlich sind, werden sie gelöscht. Auch nach Beendigung des Vertrages kann es erforderlich sein, Ihre personenbezogenen Daten zur Erfüllung vertraglicher oder gesetzlicher Pflichten aufzubewahren.',
    de: 'Sobald die verarbeiteten Daten für die Vertragserfüllung nicht mehr erforderlich sind, werden sie gelöscht. Auch nach Beendigung des Vertrages kann es erforderlich sein, Ihre personenbezogenen Daten zur Erfüllung vertraglicher oder gesetzlicher Pflichten aufzubewahren.',
    ro: 'De îndată ce datele prelucrate nu mai sunt necesare pentru îndeplinirea contractului, acestea vor fi șterse. Chiar și după încetarea contractului, poate fi necesar să se păstreze datele dvs. cu caracter personal pentru a îndeplini obligațiile contractuale sau legale.',
  },
  PP_Paragraph_3_3_6_Pre_Title: {
    en: 'Bewertung kommentieren',
    de: 'Bewertung kommentieren',
    ro: 'Comentariu rating',
  },
  PP_Paragraph_3_3_6: {
    en: 'Im Folgenden erläutern wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn Sie Kommentare zu veröffentlichten Inhalten abgeben, die anschließend auf unserer Website veröffentlicht werden. Kommentare zu Bewertungen können nur durch registrierte Nutzer abgegeben werden.',
    de: 'Im Folgenden erläutern wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn Sie Kommentare zu veröffentlichten Inhalten abgeben, die anschließend auf unserer Website veröffentlicht werden. Kommentare zu Bewertungen können nur durch registrierte Nutzer abgegeben werden.',
    ro: 'În cele ce urmează, vă explicăm modul în care sunt prelucrate datele dumneavoastră cu caracter personal atunci când trimiteți comentarii cu privire la conținutul publicat care este ulterior publicat pe site-ul nostru. Comentariile privind recenziile pot fi trimise numai de către utilizatorii înregistrați.',
  },
  PP_Paragraph_3_3_7_Title: {
    en: 'Verarbeitete Daten: ',
    de: 'Verarbeitete Daten: ',
    ro: 'Date prelucrate:',
  },
  PP_Paragraph_3_3_7: {
    en: 'Antwort auf Bewertung',
    de: 'Antwort auf Bewertung',
    ro: 'Răspunsul la evaluare',
  },
  PP_Paragraph_3_3_8_Title: {
    en: 'Zweck: ',
    de: 'Zweck: ',
    ro: 'Scop:',
  },
  PP_Paragraph_3_3_8: {
    en: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Veröffentlichung der Antwort auf eine Bewertung sowie der inhaltlichen Prüfung der Bewertung auf Einhaltung unserer Nutzungsrichtlinien.',
    de: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Veröffentlichung der Antwort auf eine Bewertung sowie der inhaltlichen Prüfung der Bewertung auf Einhaltung unserer Nutzungsrichtlinien.',
    ro: 'Scopul prelucrării datelor dumneavoastră cu caracter personal este de a publica răspunsul la un rating și de a verifica conținutul ratingului pentru a verifica conformitatea acestuia cu orientările noastre de utilizare.',
  },
  PP_Paragraph_3_3_9_Title: {
    en: 'Rechtsgrundlage: ',
    de: 'Rechtsgrundlage: ',
    ro: 'Temeiul juridic:',
  },
  PP_Paragraph_3_3_9: {
    en: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH.',
    de: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH.',
    ro: 'În conformitate cu art. 6 (1) b) DSGVO, prelucrarea datelor cu caracter personal menționate servește la îndeplinirea unui contract între dumneavoastră și Anwado GmbH.',
  },
  PP_Paragraph_3_3_10_Title: {
    en: 'Speicherdauer: ',
    de: 'Speicherdauer: ',
    ro: 'Perioada de depozitare:',
  },
  PP_Paragraph_3_3_10: {
    en: 'Sobald die verarbeiteten Daten für die Vertragserfüllung nicht mehr erforderlich sind, werden sie gelöscht. Auch nach Beendigung des Vertrages kann es erforderlich sein, Ihre personenbezogenen Daten zur Erfüllung vertraglicher oder gesetzlicher Pflichten aufzubewahren.',
    de: 'Sobald die verarbeiteten Daten für die Vertragserfüllung nicht mehr erforderlich sind, werden sie gelöscht. Auch nach Beendigung des Vertrages kann es erforderlich sein, Ihre personenbezogenen Daten zur Erfüllung vertraglicher oder gesetzlicher Pflichten aufzubewahren.',
    ro: 'De îndată ce datele prelucrate nu mai sunt necesare pentru îndeplinirea contractului, acestea vor fi șterse. Chiar și după încetarea contractului, poate fi necesar să se păstreze datele dvs. cu caracter personal pentru a îndeplini obligațiile contractuale sau legale.',
  },
  PP_Paragraph_3_3_11_Pre_Title: {
    en: 'Bewertung melden',
    de: 'Bewertung melden',
    ro: 'Raportați ratingul',
  },
  PP_Paragraph_3_3_11: {
    en: 'Im Folgenden erläutern wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn wir Bewertungen auf Authentizität, Echtheit und auf rechtsverletzende Inhalte prüfen. Bewertungen melden können nur registrierte Nutzer.',
    de: 'Im Folgenden erläutern wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn wir Bewertungen auf Authentizität, Echtheit und auf rechtsverletzende Inhalte prüfen. Bewertungen melden können nur registrierte Nutzer.',
    ro: 'Mai jos vă explicăm modul în care sunt prelucrate datele dvs. personale atunci când verificăm autenticitatea și autenticitatea recenziilor, precum și conținutul ilegal. Numai utilizatorii înregistrați pot raporta recenzii.',
  },
  PP_Paragraph_3_3_12_Title: {
    en: 'Verarbeitete Daten: ',
    de: 'Verarbeitete Daten: ',
    ro: 'Date prelucrate:',
  },
  PP_Paragraph_3_3_12: {
    en: 'Im Folgenden erläutern wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn wir Bewertungen auf Authentizität, Echtheit und auf rechtsverletzende Inhalte prüfen. Bewertungen melden können nur registrierte Nutzer.',
    de: 'Im Folgenden erläutern wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn wir Bewertungen auf Authentizität, Echtheit und auf rechtsverletzende Inhalte prüfen. Bewertungen melden können nur registrierte Nutzer.',
    ro: 'Mai jos vă explicăm modul în care sunt prelucrate datele dvs. personale atunci când verificăm autenticitatea și autenticitatea recenziilor, precum și conținutul ilegal. Numai utilizatorii înregistrați pot raporta recenzii.',
  },
  PP_Paragraph_3_3_13_Title: {
    en: 'Zweck: ',
    de: 'Zweck: ',
    ro: 'Scop:',
  },
  PP_Paragraph_3_3_13: {
    en: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der rechtlichen Prüfung von Bewertungen, Überprüfung der Authentizität von veröffentlichten oder zu veröffentlichenden Bewertungen; Verhinderung von Mehrfachberichten; Verhinderung von Kauf- und Fake-Berichten; Einhaltung unserer Nutzungsrichtlinien.',
    de: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der rechtlichen Prüfung von Bewertungen, Überprüfung der Authentizität von veröffentlichten oder zu veröffentlichenden Bewertungen; Verhinderung von Mehrfachberichten; Verhinderung von Kauf- und Fake-Berichten; Einhaltung unserer Nutzungsrichtlinien.',
    ro: 'Scopul prelucrării datelor dvs. personale este de a verifica în mod legal recenziile, de a verifica autenticitatea recenziilor publicate sau care urmează să fie publicate; de a preveni recenziile multiple; de a preveni cumpărarea și recenziile false; de a respecta politicile noastre de utilizare.',
  },
  PP_Paragraph_3_3_14_Title: {
    en: 'Rechtsgrundlage: ',
    de: 'Rechtsgrundlage: ',
    ro: 'Temeiul juridic:',
  },
  PP_Paragraph_3_3_14: {
    en: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH.',
    de: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH.',
    ro: 'În conformitate cu art. 6 (1) b) DSGVO, prelucrarea datelor cu caracter personal menționate servește la îndeplinirea unui contract între dumneavoastră și Anwado GmbH.',
  },
  PP_Paragraph_3_4_1_Title: {
    en: 'Terminorganisation',
    de: 'Terminorganisation',
    ro: 'Organizarea numirii',
  },
  PP_Paragraph_3_4_1_Pre_Title: {
    en: 'Terminverfügbarkeit',
    de: 'Terminverfügbarkeit',
    ro: 'Disponibilitatea de programare',
  },
  PP_Paragraph_3_4_1: {
    en: 'Im Folgenden erläutern wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn Sie als angemeldeter Rechtsanwalt auf Anwado.de eine Terminverfügbarkeit einstellen. Die Kalenderfunktion kann abgeschaltet werden.',
    de: 'Im Folgenden erläutern wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn Sie als angemeldeter Rechtsanwalt auf Anwado.de eine Terminverfügbarkeit einstellen. Die Kalenderfunktion kann abgeschaltet werden.',
    ro: 'În cele ce urmează, vă explicăm modul în care sunt prelucrate datele dumneavoastră personale atunci când stabiliți o disponibilitate de programare ca avocat înregistrat pe Anwado.de. Funcția de calendar poate fi dezactivată.',
  },
  PP_Paragraph_3_4_2_Title: {
    en: 'Verarbeitete Daten: ',
    de: 'Verarbeitete Daten: ',
    ro: 'Date prelucrate:',
  },
  PP_Paragraph_3_4_2: {
    en: 'Zeitslots generelle Verfügbarkeit, Wochentag, jeweils beliebige Von-Bis-Slots Optional: Zeiträume an denen Verfügbarkeit ausgesetzt werden soll, Art der Verfügbarkeit (Telefonisch/Vor Ort beim Anwalt)',
    de: 'Zeitslots generelle Verfügbarkeit, Wochentag, jeweils beliebige Von-Bis-Slots Optional: Zeiträume an denen Verfügbarkeit ausgesetzt werden soll, Art der Verfügbarkeit (Telefonisch/Vor Ort beim Anwalt)',
    ro: 'Intervalul orar disponibilitate generală, ziua săptămânii, orice interval de la până la Opțional: perioadele în care disponibilitatea urmează să fie suspendată, tipul de disponibilitate (telefonic/pe loc la cabinetul avocatului)',
  },
  PP_Paragraph_3_4_3_Title: {
    en: 'Zweck: ',
    de: 'Zweck: ',
    ro: 'Scop:',
  },
  PP_Paragraph_3_4_3: {
    en: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Veröffentlichung der buchbaren Online-Termine zur Übermittlung von Terminanfragen durch Nutzer.',
    de: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Veröffentlichung der buchbaren Online-Termine zur Übermittlung von Terminanfragen durch Nutzer.',
    ro: 'Scopul prelucrării datelor dumneavoastră cu caracter personal este de a publica programările online care pot fi rezervate pentru transmiterea cererilor de programare de către utilizatori.',
  },
  PP_Paragraph_3_4_4_Title: {
    en: 'Rechtsgrundlage: ',
    de: 'Rechtsgrundlage: ',
    ro: 'Temeiul juridic:',
  },
  PP_Paragraph_3_4_4: {
    en: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH.',
    de: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH.',
    ro: 'În conformitate cu art. 6 (1) b) DSGVO, prelucrarea datelor cu caracter personal menționate servește la îndeplinirea unui contract între dumneavoastră și Anwado GmbH.',
  },
  PP_Paragraph_3_4_5_Title: {
    en: 'Speicherdauer: ',
    de: 'Speicherdauer: ',
    ro: 'Perioada de depozitare:',
  },
  PP_Paragraph_3_4_5: {
    en: 'Sobald das Anwado-Profil gelöscht wird, werden die verarbeiteten Daten gelöscht.',
    de: 'Sobald das Anwado-Profil gelöscht wird, werden die verarbeiteten Daten gelöscht.',
    ro: 'De îndată ce profilul Anwado este șters, datele prelucrate sunt șterse.',
  },
  PP_Paragraph_3_4_6_Pre_Title: {
    en: 'Termin vereinbaren',
    de: 'Termin vereinbaren',
    ro: 'Aranjați o întâlnire',
  },
  PP_Paragraph_3_4_6: {
    en: 'Im Folgenden erläutern wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn Sie unseren Service "Online-Terminbuchung" nutzen.',
    de: 'Im Folgenden erläutern wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn Sie unseren Service "Online-Terminbuchung" nutzen.',
    ro: 'În cele ce urmează, vă explicăm modul în care sunt prelucrate datele dumneavoastră cu caracter personal atunci când utilizați serviciul nostru "Programare online".',
  },
  PP_Paragraph_3_4_7_Title: {
    en: 'Verarbeitete Daten: ',
    de: 'Verarbeitete Daten: ',
    ro: 'Date prelucrate:',
  },
  PP_Paragraph_3_4_7: {
    en: `Datum - Uhrzeit für Termin, Thema, Nachricht, Ort (Tel / Vor Ort beim Anwalt); Wenn Nutzer nicht angemeldet ist, zusätzlich: Vorname, Nachname, Email`,
    de: `Datum - Uhrzeit für Termin, Thema, Nachricht, Ort (Tel / Vor Ort beim Anwalt); Wenn Nutzer nicht angemeldet ist, zusätzlich: Vorname, Nachname, Email`,
  },
  PP_Paragraph_3_4_8_Title: {
    en: 'Zweck: ',
    de: 'Zweck: ',
    ro: 'Scop:',
  },
  PP_Paragraph_3_4_8: {
    en: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Übermittlung der Terminanfrage sowie dem Versand von Terminbestätigungen und -erinnerungen.',
    de: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Übermittlung der Terminanfrage sowie dem Versand von Terminbestätigungen und -erinnerungen.',
    ro: 'Scopul prelucrării datelor dumneavoastră cu caracter personal este de a transmite cererea de programare și de a trimite confirmări și memento-uri de programare.',
  },
  PP_Paragraph_3_4_9_Title: {
    en: 'Rechtsgrundlage: ',
    de: 'Rechtsgrundlage: ',
    ro: 'Temeiul juridic:',
  },
  PP_Paragraph_3_4_9: {
    en: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH, bei nicht angemeldeten Nutzern in der Einwilligung gem. Art. 9 Abs. 2 lit. a) DSGVO.',
    de: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH, bei nicht angemeldeten Nutzern in der Einwilligung gem. Art. 9 Abs. 2 lit. a) DSGVO.',
    ro: 'În conformitate cu art. 6 alin. 1 b) DSGVO, prelucrarea datelor cu caracter personal prezentate servește la îndeplinirea unui contract între dumneavoastră și Anwado GmbH, pentru utilizatorii neînregistrați în consimțământul conform art. 9 alin. 2 lit. a) DSGVO.',
  },
  PP_Paragraph_3_4_10_Title: {
    en: 'Speicherdauer: ',
    de: 'Speicherdauer: ',
    ro: 'Perioada de depozitare:',
  },
  PP_Paragraph_3_4_10: {
    en: 'Sobald das Nutzerkonto gelöscht wird, werden die verarbeiteten Daten gelöscht.',
    de: 'Sobald das Nutzerkonto gelöscht wird, werden die verarbeiteten Daten gelöscht.',
    ro: 'De îndată ce contul de utilizator este șters, datele prelucrate sunt șterse.',
  },
  PP_Paragraph_3_4_11_Title: {
    en: 'Empfänger der Daten: ',
    de: 'Empfänger der Daten: ',
    ro: 'Destinatarul datelor:',
  },
  PP_Paragraph_3_4_11: {
    en: 'Ihre Daten werden an den ausgewählten Anwalt zum Zwecke der Übermittlung der Terminanfrage weitergeleitet, sowie an Kommunikationsdienstleister für E-Mail-Versand.',
    de: 'Ihre Daten werden an den ausgewählten Anwalt zum Zwecke der Übermittlung der Terminanfrage weitergeleitet, sowie an Kommunikationsdienstleister für E-Mail-Versand.',
    ro: 'Datele dvs. vor fi transmise avocatului selectat în scopul transmiterii cererii de programare, precum și furnizorilor de servicii de comunicare pentru expedierea de e-mailuri.',
  },
  PP_Paragraph_3_4_12_Pre_Title: {
    en: 'Termin ändern',
    de: 'Termin ändern',
    ro: 'Modificarea numirii',
  },
  PP_Paragraph_3_4_12: {
    en: 'Im Folgenden erläutern wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn Sie einen Termin ändern.',
    de: 'Im Folgenden erläutern wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn Sie einen Termin ändern.',
    ro: 'Mai jos vă explicăm modul în care sunt prelucrate datele dumneavoastră personale atunci când schimbați o programare.',
  },
  PP_Paragraph_3_4_13_Title: {
    en: 'Verarbeitete Daten: ',
    de: 'Verarbeitete Daten: ',
    ro: 'Date prelucrate:',
  },
  PP_Paragraph_3_4_13: {
    en: 'Thema, Nachricht, Datum- Uhrzeit für Termin, Ort (Tel / Vor Ort beim Anwalt);',
    de: 'Thema, Nachricht, Datum- Uhrzeit für Termin, Ort (Tel / Vor Ort beim Anwalt);',
    ro: 'Subiect, mesaj, data, ora, locul (telefon / la fața locului, la avocat);',
  },
  PP_Paragraph_3_4_14_Title: {
    en: 'Zweck: ',
    de: 'Zweck: ',
    ro: 'Scop:',
  },
  PP_Paragraph_3_4_14: {
    en: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Übermittlung der Terminänderungsanfrage sowie dem Versand von Terminbestätigungen und -erinnerungen.',
    de: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Übermittlung der Terminänderungsanfrage sowie dem Versand von Terminbestätigungen und -erinnerungen.',
    ro: 'Scopul prelucrării datelor dumneavoastră cu caracter personal este de a transmite cererea de modificare a programării și de a trimite confirmări și memento-uri de programare.',
  },
  PP_Paragraph_3_4_15_Title: {
    en: 'Rechtsgrundlage: ',
    de: 'Rechtsgrundlage: ',
    ro: 'Temeiul juridic:',
  },
  PP_Paragraph_3_4_15: {
    en: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH.',
    de: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH.',
    ro: 'În conformitate cu art. 6 (1) b) DSGVO, prelucrarea datelor cu caracter personal menționate servește la îndeplinirea unui contract între dumneavoastră și Anwado GmbH.',
  },
  PP_Paragraph_3_4_16_Title: {
    en: 'Speicherdauer: ',
    de: 'Speicherdauer: ',
    ro: 'Perioada de depozitare:',
  },
  PP_Paragraph_3_4_16: {
    en: 'Sobald das Nutzerkonto gelöscht wird, werden die verarbeiteten Daten gelöscht.',
    de: 'Sobald das Nutzerkonto gelöscht wird, werden die verarbeiteten Daten gelöscht.',
    ro: 'De îndată ce contul de utilizator este șters, datele prelucrate sunt șterse.',
  },
  PP_Paragraph_3_4_17_Title: {
    en: 'Empfänger der Daten: ',
    de: 'Empfänger der Daten: ',
    ro: 'Destinatarul datelor:',
  },
  PP_Paragraph_3_4_17: {
    en: 'Ihre Daten werden an den ausgewählten Anwalt zum Zwecke der Übermittlung der Terminanfrage weitergeleitet, sowie an Kommunikationsdienstleister für E-Mail-Versand.',
    de: 'Ihre Daten werden an den ausgewählten Anwalt zum Zwecke der Übermittlung der Terminanfrage weitergeleitet, sowie an Kommunikationsdienstleister für E-Mail-Versand.',
    ro: 'Datele dvs. vor fi transmise avocatului selectat în scopul transmiterii cererii de programare, precum și furnizorilor de servicii de comunicare pentru expedierea de e-mailuri.',
  },
  PP_Paragraph_3_4_18_Pre_Title: {
    en: 'Termin löschen',
    de: 'Termin löschen',
    ro: 'Ștergeți numirea',
  },
  PP_Paragraph_3_4_18: {
    en: 'Im Folgenden erläutern wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn Sie einen Termin löschen.',
    de: 'Im Folgenden erläutern wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn Sie einen Termin löschen.',
    ro: 'Mai jos vă explicăm modul în care sunt procesate datele dumneavoastră personale atunci când ștergeți o programare.',
  },
  PP_Paragraph_3_4_19_Title: {
    en: 'Verarbeitete Daten: ',
    de: 'Verarbeitete Daten: ',
    ro: 'Date prelucrate:',
  },
  PP_Paragraph_3_4_19: {
    en: 'Nachricht',
    de: 'Nachricht',
    ro: 'Mesaj',
  },
  PP_Paragraph_3_4_20_Title: {
    en: 'Zweck: ',
    de: 'Zweck: ',
    ro: 'Scop:',
  },
  PP_Paragraph_3_4_20: {
    en: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Übermittlung der Terminlöschung sowie dem Versand von Terminbestätigungen und -erinnerungen.',
    de: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Übermittlung der Terminlöschung sowie dem Versand von Terminbestätigungen und -erinnerungen.',
    ro: 'Scopul prelucrării datelor dumneavoastră cu caracter personal este de a transmite anularea programării și de a trimite confirmări de programare și memento-uri.',
  },
  PP_Paragraph_3_4_21_Title: {
    en: 'Rechtsgrundlage: ',
    de: 'Rechtsgrundlage: ',
    ro: 'Temeiul juridic:',
  },
  PP_Paragraph_3_4_21: {
    en: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH.',
    de: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH.',
    ro: 'În conformitate cu art. 6 (1) b) DSGVO, prelucrarea datelor cu caracter personal menționate servește la îndeplinirea unui contract între dumneavoastră și Anwado GmbH.',
  },
  PP_Paragraph_3_4_22_Title: {
    en: 'Speicherdauer: ',
    de: 'Speicherdauer: ',
    ro: 'Perioada de depozitare:',
  },
  PP_Paragraph_3_4_22: {
    en: 'Sobald das Nutzerkonto gelöscht wird, werden die verarbeiteten Daten gelöscht.',
    de: 'Sobald das Nutzerkonto gelöscht wird, werden die verarbeiteten Daten gelöscht.',
    ro: 'De îndată ce contul de utilizator este șters, datele prelucrate sunt șterse.',
  },
  PP_Paragraph_3_4_23_Title: {
    en: 'Empfänger der Daten: ',
    de: 'Empfänger der Daten: ',
    ro: 'Destinatarul datelor:',
  },
  PP_Paragraph_3_4_23: {
    en: 'Ihre Daten werden an den ausgewählten Anwalt zum Zwecke der Übermittlung der Terminanfrage weitergeleitet, sowie an Kommunikationsdienstleister für E-Mail-Versand.',
    de: 'Ihre Daten werden an den ausgewählten Anwalt zum Zwecke der Übermittlung der Terminanfrage weitergeleitet, sowie an Kommunikationsdienstleister für E-Mail-Versand.',
    ro: 'Datele dvs. vor fi transmise avocatului selectat în scopul transmiterii cererii de programare, precum și furnizorilor de servicii de comunicare pentru expedierea de e-mailuri.',
  },
  PP_Paragraph_3_5_1_Title: {
    en: 'Kommunikation',
    de: 'Kommunikation',
    ro: 'Comunicare',
  },
  PP_Paragraph_3_5_1: {
    en: 'Wir bieten Ihnen als Service die Möglichkeit, eine private Nachricht an einen angemeldeten Anwalt zu senden. Bevor Sie einem Anwalt eine Nachricht senden, bittet die Anwado GmbH Sie um Ihre Zustimmung, die in der Nachricht enthaltenen personenbezogenen Daten zu verarbeiten und dem Anwalt zur Verfügung zu stellen. Nachfolgend beschreiben wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn Sie unseren Service nutzen und einem Anwalt eine Nachricht senden.',
    de: 'Wir bieten Ihnen als Service die Möglichkeit, eine private Nachricht an einen angemeldeten Anwalt zu senden. Bevor Sie einem Anwalt eine Nachricht senden, bittet die Anwado GmbH Sie um Ihre Zustimmung, die in der Nachricht enthaltenen personenbezogenen Daten zu verarbeiten und dem Anwalt zur Verfügung zu stellen. Nachfolgend beschreiben wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn Sie unseren Service nutzen und einem Anwalt eine Nachricht senden.',
    ro: 'Vă oferim ca serviciu posibilitatea de a trimite un mesaj privat unui avocat. Înainte de a trimite un mesaj unui avocat, Anwado GmbH vă solicită consimțământul pentru a prelucra datele cu caracter personal conținute în mesaj și pentru a le pune la dispoziția avocatului. Mai jos vă descriem modul în care sunt prelucrate datele dumneavoastră personale atunci când utilizați serviciul nostru și trimiteți un mesaj unui avocat.',
  },
  PP_Paragraph_3_5_2_Title: {
    en: 'Verarbeitete Daten: ',
    de: 'Verarbeitete Daten: ',
    ro: 'Date prelucrate:',
  },
  PP_Paragraph_3_5_2: {
    en: 'Nachricht; Wenn Nutzer nicht angemeldet ist, zusätzlich: Vorname, Nachname, Email-Adresse',
    de: 'Nachricht; Wenn Nutzer nicht angemeldet ist, zusätzlich: Vorname, Nachname, Email-Adresse',
    ro: 'Mesaj; dacă utilizatorul nu este conectat, în plus: nume, prenume, nume de familie, adresă de e-mail',
  },
  PP_Paragraph_3_5_3_Title: {
    en: 'Zweck: ',
    de: 'Zweck: ',
    ro: 'Scop:',
  },
  PP_Paragraph_3_5_3: {
    en: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Übermittlung der Nachricht.',
    de: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Übermittlung der Nachricht.',
    ro: 'Scopul prelucrării datelor dumneavoastră cu caracter personal este de a trimite mesajul.',
  },
  PP_Paragraph_3_5_4_Title: {
    en: 'Rechtsgrundlage: ',
    de: 'Rechtsgrundlage: ',
    ro: 'Temeiul juridic:',
  },
  PP_Paragraph_3_5_4: {
    en: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH, bei nicht angemeldeten Nutzern in der Einwilligung gem. Art. 9 Abs. 2 lit. a) DSGVO.',
    de: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH, bei nicht angemeldeten Nutzern in der Einwilligung gem. Art. 9 Abs. 2 lit. a) DSGVO.',
    ro: 'În conformitate cu art. 6 alin. 1 b) DSGVO, prelucrarea datelor cu caracter personal prezentate servește la îndeplinirea unui contract între dumneavoastră și Anwado GmbH, pentru utilizatorii neînregistrați în consimțământul conform art. 9 alin. 2 lit. a) DSGVO.',
  },
  PP_Paragraph_3_5_5_Title: {
    en: 'Speicherdauer: ',
    de: 'Speicherdauer: ',
    ro: 'Perioada de depozitare:',
  },
  PP_Paragraph_3_5_5: {
    en: 'Sobald das Nutzerkonto gelöscht wird, werden die verarbeiteten Daten gelöscht.',
    de: 'Sobald das Nutzerkonto gelöscht wird, werden die verarbeiteten Daten gelöscht.',
    ro: 'De îndată ce contul de utilizator este șters, datele prelucrate sunt șterse.',
  },
  PP_Paragraph_3_6_1_Title: {
    en: 'Veröffentlichung von Rechtsinformationen („Rechtsinfos“)',
    de: 'Veröffentlichung von Rechtsinformationen („Rechtsinfos“)',
    ro: 'Publicarea de informații juridice ("Informații juridice")',
  },
  PP_Paragraph_3_6_1: {
    en: 'Wir bieten Ihnen als Service die Möglichkeit, als angemeldeter Anwalt, Fachbeiträge („Rechtsinfos“), zu veröffentlichen. Nachfolgend beschreiben wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn Sie unseren Service nutzen und einen Fachartikel veröffentlichen.',
    de: 'Wir bieten Ihnen als Service die Möglichkeit, als angemeldeter Anwalt, Fachbeiträge („Rechtsinfos“), zu veröffentlichen. Nachfolgend beschreiben wir, wie Ihre personenbezogenen Daten verarbeitet werden, wenn Sie unseren Service nutzen und einen Fachartikel veröffentlichen.',
    ro: 'În calitate de avocat înregistrat, vă oferim posibilitatea de a publica articole juridice ("Rechtsinfos"). Mai jos vă descriem modul în care sunt prelucrate datele dumneavoastră cu caracter personal atunci când utilizați serviciul nostru și publicați un articol juridic.',
  },
  PP_Paragraph_3_6_2_Pre_Title: {
    en: 'Artikel schreiben',
    de: 'Artikel schreiben',
    ro: 'Scrieți articolul',
  },
  PP_Paragraph_3_6_2_Title: {
    en: 'Verarbeitete Daten: ',
    de: 'Verarbeitete Daten: ',
    ro: 'Date prelucrate:',
  },
  PP_Paragraph_3_6_2: {
    en: 'Titelbild, Titel, Kurzbeschreibung, Beitrag, Rechtsgebiet(e)',
    de: 'Titelbild, Titel, Kurzbeschreibung, Beitrag, Rechtsgebiet(e)',
    ro: 'Copertă, Titlu, Scurtă descriere, Contribuție, Domeniul (domeniile) de drept',
  },
  PP_Paragraph_3_6_3_Title: {
    en: 'Zweck: ',
    de: 'Zweck: ',
    ro: 'Scop:',
  },
  PP_Paragraph_3_6_3: {
    en: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Veröffentlichung von Fachartikeln auf der Anwado-Webseite.',
    de: 'Der Zweck der Verarbeitung Ihrer personenbezogenen Daten liegt in der Veröffentlichung von Fachartikeln auf der Anwado-Webseite.',
    ro: 'Scopul prelucrării datelor dumneavoastră cu caracter personal este de a publica articole profesionale pe site-ul Anwado.',
  },
  PP_Paragraph_3_6_4_Title: {
    en: 'Rechtsgrundlage: ',
    de: 'Rechtsgrundlage: ',
    ro: 'Temeiul juridic:',
  },
  PP_Paragraph_3_6_4: {
    en: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH.',
    de: 'Die Verarbeitung der dargelegten personenbezogenen Daten dient nach Art. 6 Abs. 1 b) DSGVO der Erfüllung eines Vertrages zwischen Ihnen und der Anwado GmbH.',
    ro: 'În conformitate cu art. 6 (1) b) DSGVO, prelucrarea datelor cu caracter personal menționate servește la îndeplinirea unui contract între dumneavoastră și Anwado GmbH.',
  },
  PP_Paragraph_3_6_5_Title: {
    en: 'Speicherdauer: ',
    de: 'Speicherdauer: ',
    ro: 'Perioada de depozitare:',
  },
  PP_Paragraph_3_6_5: {
    en: 'Sobald die verarbeiteten Daten für die Vertragserfüllung nicht mehr erforderlich sind, werden sie gelöscht.',
    de: 'Sobald die verarbeiteten Daten für die Vertragserfüllung nicht mehr erforderlich sind, werden sie gelöscht.',
    ro: 'De îndată ce datele prelucrate nu mai sunt necesare pentru executarea contractului, acestea sunt șterse.',
  },
  PP_Paragraph_3_7_1_Title: {
    en: 'Automatische Verarbeitung von Daten beim Besuch der Webseite',
    de: 'Automatische Verarbeitung von Daten beim Besuch der Webseite',
    ro: 'Prelucrarea automată a datelor atunci când vizitați site-ul web',
  },
  PP_Paragraph_3_7_1: {
    en: 'Bei Aufruf unserer Website erheben wir die personenbezogenen Daten, die Ihr Browser automatisch an unseren Servern übermittelt. Um Ihnen auf der Website die für Sie relevanten Informationen anzeigen zu können, verarbeiten wir außerdem folgende Daten, die temporär in einem sog. Logfile gespeichert werden:',
    de: 'Bei Aufruf unserer Website erheben wir die personenbezogenen Daten, die Ihr Browser automatisch an unseren Servern übermittelt. Um Ihnen auf der Website die für Sie relevanten Informationen anzeigen zu können, verarbeiten wir außerdem folgende Daten, die temporär in einem sog. Logfile gespeichert werden:',
    ro: 'Atunci când accesați site-ul nostru web, colectăm datele cu caracter personal pe care browserul dumneavoastră le transmite automat serverelor noastre. Pentru a putea afișa informațiile relevante pentru dvs. pe site-ul web, prelucrăm, de asemenea, următoarele date, care sunt stocate temporar într-un așa-numit fișier jurnal:',
  },
  PP_Paragraph_3_7_1_1: {
    en: 'IP-Adresse',
    de: 'IP-Adresse',
    ro: 'Adresa IP',
  },
  PP_Paragraph_3_7_1_2: {
    en: 'Datum und Uhrzeit der Anfrage',
    de: 'Datum und Uhrzeit der Anfrage',
    ro: 'Data și ora solicitării',
  },
  PP_Paragraph_3_7_1_3: {
    en: 'Inhalt der Anforderung (konkrete Seite)',
    de: 'Inhalt der Anforderung (konkrete Seite)',
    ro: 'Conținutul cererii (pagina specifică)',
  },
  PP_Paragraph_3_7_1_4: {
    en: 'Zugriffsstatus/HTTP-Statuscode',
    de: 'Zugriffsstatus/HTTP-Statuscode',
    ro: 'Stare de acces/cod de stare HTTP',
  },
  PP_Paragraph_3_7_1_5: {
    en: 'jeweils übertragene Datenmenge',
    de: 'jeweils übertragene Datenmenge',
    ro: 'Cantitatea de date transferate în fiecare caz',
  },
  PP_Paragraph_3_7_1_6: {
    en: 'Website, von der die Anforderung kommt',
    de: 'Website, von der die Anforderung kommt',
    ro: 'Site-ul de pe care provine cererea',
  },
  PP_Paragraph_3_7_1_7: {
    en: 'Browser',
    de: 'Browser',
    ro: 'Browser',
  },
  PP_Paragraph_3_7_1_8: {
    en: 'Betriebssystem und dessen Oberfläche',
    de: 'Betriebssystem und dessen Oberfläche',
    ro: 'Sistemul de operare și interfața acestuia',
  },
  PP_Paragraph_4_Title: {
    en: 'Verwendung von Cookies',
    de: 'Verwendung von Cookies',
    ro: 'Utilizarea cookie-urilor',
  },
  PP_Paragraph_4_1_Title: {
    en: 'Grundlegende Informationen',
    de: 'Grundlegende Informationen',
    ro: 'Informații de bază',
  },
  PP_Paragraph_4_1: {
    en: `Diese Cookie-Richtlinie erläutert den Einsatz von Cookies auf unserer Webseite, deren Zweck und die verschiedenen Möglichkeiten, wie Sie mit Cookies umgehen können. Unsere Website verwendet Technologien, die die Nutzung der Website vereinfachen, die Benutzerfreundlichkeit erhöhen und verschiedene Funktionen bereitstellen (und somit insgesamt zur Benutzerfreundlichkeit, Effizienz und Sicherheit der Website beitragen). Zu diesen Technologien gehören unter anderem Cookies, Web Beacons und Scripts (nachfolgend zusammen „Cookies“ genannt). Cookies sind kleine Dateien, die auf Ihrem Endgerät gespeichert werden und Informationen wie persönliche Seiteneinstellungen und Anmeldedaten enthalten. Diese Datei wird vom Webserver, mit dem Ihr Webbrowser verbunden ist, erstellt und an Sie übermittelt.`,
    de: `Diese Cookie-Richtlinie erläutert den Einsatz von Cookies auf unserer Webseite, deren Zweck und die verschiedenen Möglichkeiten, wie Sie mit Cookies umgehen können. Unsere Website verwendet Technologien, die die Nutzung der Website vereinfachen, die Benutzerfreundlichkeit erhöhen und verschiedene Funktionen bereitstellen (und somit insgesamt zur Benutzerfreundlichkeit, Effizienz und Sicherheit der Website beitragen). Zu diesen Technologien gehören unter anderem Cookies, Web Beacons und Scripts (nachfolgend zusammen „Cookies“ genannt). Cookies sind kleine Dateien, die auf Ihrem Endgerät gespeichert werden und Informationen wie persönliche Seiteneinstellungen und Anmeldedaten enthalten. Diese Datei wird vom Webserver, mit dem Ihr Webbrowser verbunden ist, erstellt und an Sie übermittelt.`,
  },
  PP_Paragraph_4_2_Title: {
    en: 'Umgang mit Cookies',
    de: 'Umgang mit Cookies',
    ro: 'Tratarea cu cookie-uri',
  },
  PP_Paragraph_4_2: {
    en: `Grundsätzlich können Sie unsere Webseite auch ohne Cookies betrachten. Um jedoch alle Funktionen unserer Website vollumfänglich nutzen zu können, empfehlen wir Ihnen, Cookies zu akzeptieren, welche einige Funktionen erst ermöglichen bzw. die Nutzung vereinfachen. In der Regel sind Webbrowser so eingestellt, dass sie alle Cookies automatisch akzeptieren. Sie können das Speichern von Cookies jedoch deaktivieren, indem Sie Ihren Browser so einstellen, dass er keine oder nur bestimmte Cookies annimmt oder Sie benachrichtigt, sobald Cookies gesendet werden. Bitte beachten Sie, dass in diesem Fall möglicherweise nicht alle Funktionen unserer Website vollumfänglich zur Verfügung stehen. Sofern Sie in Ihren Browsereinstellungen Cookies zulassen, erklären Sie sich mit der Verwendung dieser Cookies bei der Nutzung unserer Webseite einverstanden. Bitte beachten Sie, dass die Einstellungen Ihres Browsers nur für den jeweiligen Browser gelten. Wenn Sie andere Browser oder Geräte verwenden, müssen Sie die Einstellungen erneut vornehmen. Sie können Cookies auch jederzeit wieder aus Ihrem Speicher entfernen. Informationen zu den Cookie-Einstellungen, deren Änderung und zum Löschen von Cookies finden Sie in der Hilfe-Funktion Ihres Webbrowsers. Es gibt auch Softwareprodukte, die die Verwaltung von Cookies für Sie übernehmen können. Weitere Informationen über Cookies und den Umgang mit Cookies finden Sie auf den entsprechenden Hilfeseiten im Internet.`,
    de: `Grundsätzlich können Sie unsere Webseite auch ohne Cookies betrachten. Um jedoch alle Funktionen unserer Website vollumfänglich nutzen zu können, empfehlen wir Ihnen, Cookies zu akzeptieren, welche einige Funktionen erst ermöglichen bzw. die Nutzung vereinfachen. In der Regel sind Webbrowser so eingestellt, dass sie alle Cookies automatisch akzeptieren. Sie können das Speichern von Cookies jedoch deaktivieren, indem Sie Ihren Browser so einstellen, dass er keine oder nur bestimmte Cookies annimmt oder Sie benachrichtigt, sobald Cookies gesendet werden. Bitte beachten Sie, dass in diesem Fall möglicherweise nicht alle Funktionen unserer Website vollumfänglich zur Verfügung stehen. Sofern Sie in Ihren Browsereinstellungen Cookies zulassen, erklären Sie sich mit der Verwendung dieser Cookies bei der Nutzung unserer Webseite einverstanden. Bitte beachten Sie, dass die Einstellungen Ihres Browsers nur für den jeweiligen Browser gelten. Wenn Sie andere Browser oder Geräte verwenden, müssen Sie die Einstellungen erneut vornehmen. Sie können Cookies auch jederzeit wieder aus Ihrem Speicher entfernen. Informationen zu den Cookie-Einstellungen, deren Änderung und zum Löschen von Cookies finden Sie in der Hilfe-Funktion Ihres Webbrowsers. Es gibt auch Softwareprodukte, die die Verwaltung von Cookies für Sie übernehmen können. Weitere Informationen über Cookies und den Umgang mit Cookies finden Sie auf den entsprechenden Hilfeseiten im Internet.`,
  },
  PP_Paragraph_4_3_Title: {
    en: 'Welche Arten von Cookies werden verwendet?',
    de: 'Welche Arten von Cookies werden verwendet?',
    ro: 'Ce tipuri de cookie-uri sunt utilizate?',
  },
  PP_Paragraph_4_3_1_Title: {
    en: 'Cookie-Kategorien',
    de: 'Cookie-Kategorien',
    ro: 'Categorii de cookie-uri',
  },
  PP_Paragraph_4_3_1: {
    en: `Die auf Anwado verwendeten Cookies lassen sich in folgende Kategorien einteilen: Unverzichtbare Cookies Diese Cookies sind für das Funktionieren der Website unerlässlich. Ohne diese Cookies würden Dienste wie z.B. die Anwaltssuche nicht funktionieren. Funktionale Cookies Diese Cookies ermöglichen es der Website, bereits eingegebene Informationen (z. B. Benutzernamen, Konfigurationen, Spracheinstellungen) zu speichern und dem Nutzer verbesserte, personalisierte Funktionen anzubieten. Marketing-Cookies Diese Cookies werden verwendet, um dem Nutzer relevantere und auf seine Interessen zugeschnittene Inhalte anbieten zu können. Sie registrieren, ob eine Website besucht wurde und welche Inhalte genutzt wurden. Dies basiert auf einer eindeutigen Identifizierung des Browsers und des Internetgeräts des Nutzers.`,
    de: `Die auf Anwado verwendeten Cookies lassen sich in folgende Kategorien einteilen: Unverzichtbare Cookies Diese Cookies sind für das Funktionieren der Website unerlässlich. Ohne diese Cookies würden Dienste wie z.B. die Anwaltssuche nicht funktionieren. Funktionale Cookies Diese Cookies ermöglichen es der Website, bereits eingegebene Informationen (z. B. Benutzernamen, Konfigurationen, Spracheinstellungen) zu speichern und dem Nutzer verbesserte, personalisierte Funktionen anzubieten. Marketing-Cookies Diese Cookies werden verwendet, um dem Nutzer relevantere und auf seine Interessen zugeschnittene Inhalte anbieten zu können. Sie registrieren, ob eine Website besucht wurde und welche Inhalte genutzt wurden. Dies basiert auf einer eindeutigen Identifizierung des Browsers und des Internetgeräts des Nutzers.`,
  },
  PP_Paragraph_4_3_2_Title: {
    en: 'Dauer der Speicherung',
    de: 'Dauer der Speicherung',
    ro: 'Durata de depozitare',
  },
  PP_Paragraph_4_3_2: {
    en: `Cookies können Dateien dauerhaft oder nur temporär auf Ihrem Gerät ablegen. Dauerhafte Cookies Sogenannte dauerhafte Cookies speichern eine Datei für einen bestimmten Zeitraum bis zu einem vorgegebenen Verfallsdatum (oder bis zur vorzeitigen Löschung) auf Ihrem Gerät. Auf diese Weise werden z.B. Spracheinstellungen gespeichert, damit Sie diese für unsere Website nicht jedes Mal neu vornehmen müssen. Sitzungs-Cookies Ein sogenanntes Session-Cookie speichert temporär eine Session-ID, solange Sie auf der Website aktiv sind. Dadurch wird beispielsweise vermieden, dass Sie sich beim Wechseln der Seite erneut anmelden müssen. Sitzungscookies werden gelöscht, wenn Sie sich ausloggen, oder verlieren ihre Gültigkeit, sobald Ihre Sitzung beendet ist.`,
    de: `Cookies können Dateien dauerhaft oder nur temporär auf Ihrem Gerät ablegen. Dauerhafte Cookies Sogenannte dauerhafte Cookies speichern eine Datei für einen bestimmten Zeitraum bis zu einem vorgegebenen Verfallsdatum (oder bis zur vorzeitigen Löschung) auf Ihrem Gerät. Auf diese Weise werden z.B. Spracheinstellungen gespeichert, damit Sie diese für unsere Website nicht jedes Mal neu vornehmen müssen. Sitzungs-Cookies Ein sogenanntes Session-Cookie speichert temporär eine Session-ID, solange Sie auf der Website aktiv sind. Dadurch wird beispielsweise vermieden, dass Sie sich beim Wechseln der Seite erneut anmelden müssen. Sitzungscookies werden gelöscht, wenn Sie sich ausloggen, oder verlieren ihre Gültigkeit, sobald Ihre Sitzung beendet ist.`,
  },
  PP_Paragraph_4_4_Title: {
    en: 'Eingesetzte Cookies',
    de: 'Eingesetzte Cookies',
    ro: 'Cookie-uri utilizate',
  },
  PP_Paragraph_4_Description_gcl_au: {
    en: 'Wird von Google AdSense zum Experimentieren mit Werbungseffizienz auf Webseiten verwendet, die ihre Dienste nutzen.',
    de: 'Wird von Google AdSense zum Experimentieren mit Werbungseffizienz auf Webseiten verwendet, die ihre Dienste nutzen.',
    ro: 'Folosit de Google AdSense pentru a experimenta eficiența publicității pe site-urile web care utilizează serviciile sale.',
  },
  PP_Paragraph_4_Description_ga_number: {
    en: 'Sammelt Daten dazu, wie oftein Benutzer eine Website besucht hat, sowie Daten für den ersten und letzten Besuch. Von Google Analytics verwendet.',
    de: 'Sammelt Daten dazu, wie oftein Benutzer eine Website besucht hat, sowie Daten für den ersten und letzten Besuch. Von Google Analytics verwendet.',
    ro: 'Colectează date privind frecvența cu care un utilizator a vizitat un site web, precum și date privind prima și ultima vizită. Utilizat de Google Analytics.',
  },
  PP_Paragraph_4_Description_ga: {
    en: 'Registriert eine eindeutige ID für anonyme statistische Daten über die Websitenutzung.',
    de: 'Registriert eine eindeutige ID für anonyme statistische Daten über die Websitenutzung.',
    ro: 'Înregistrează un ID unic pentru date statistice anonime despre utilizarea site-ului web.',
  },
  PP_Paragraph_4_Description_1: {
    en: 'Dieses Cookie ermöglicht es dem Nutzer, auf dem Profil eines Anwalts dessen Standort mit Hilfe von Google Maps anzuzeigen.',
    de: 'Dieses Cookie ermöglicht es dem Nutzer, auf dem Profil eines Anwalts dessen Standort mit Hilfe von Google Maps anzuzeigen.',
    ro: 'Acest modul cookie permite utilizatorului să afișeze locația unui avocat pe profilul său cu ajutorul Google Maps.',
  },
  PP_Paragraph_4_Type_1: {
    en: 'Sitzung',
    de: 'Sitzung',
    ro: 'Sesiunea',
  },
  PP_Paragraph_4_Category_1: {
    en: 'Funktional',
    de: 'Funktional',
    ro: 'Funcțional',
  },
  PP_Paragraph_4_Description_2: {
    en: 'Dieses Cookie speichert Informationen über die Zustimmung des Nutzers zu Cookies.',
    de: 'Dieses Cookie speichert Informationen über die Zustimmung des Nutzers zu Cookies.',
    ro: 'Acest modul cookie stochează informații despre consimțământul utilizatorului cu privire la modulele cookie.',
  },
  PP_Paragraph_4_Type_2: {
    en: 'SitDauerhaftzung',
    de: 'SitDauerhaftzung',
    ro: 'SitDing permanent',
  },
  PP_Paragraph_4_Category_2: {
    en: 'Funktional',
    de: 'Funktional',
    ro: 'Funcțional',
  },
  PP_Paragraph_4_Description_3: {
    en: 'Dieses Cookie speichert Informationen über die Abonnementpräferenzen eines Anwalts vor der Registrierung.',
    de: 'Dieses Cookie speichert Informationen über die Abonnementpräferenzen eines Anwalts vor der Registrierung.',
    ro: 'Acest modul cookie stochează informații despre preferințele de abonare ale unui avocat înainte de înregistrare.',
  },
  PP_Paragraph_4_Type_3: {
    en: 'Sitzung',
    de: 'Sitzung',
    ro: 'Sesiunea',
  },
  PP_Paragraph_4_Category_3: {
    en: 'Funktional',
    de: 'Funktional',
    ro: 'Funcțional',
  },
  PP_Paragraph_4_Description_4: {
    en: 'Dieses Cookie verwendet und speichert die IP-Adresse des Nutzers für eine optimierte Anwaltssuche.',
    de: 'Dieses Cookie verwendet und speichert die IP-Adresse des Nutzers für eine optimierte Anwaltssuche.',
    ro: 'Acest modul cookie utilizează și stochează adresa IP a utilizatorului pentru o căutare optimizată a avocaților.',
  },
  PP_Paragraph_4_Type_4: {
    en: 'Sitzung',
    de: 'Sitzung',
    ro: 'Sesiunea',
  },
  PP_Paragraph_4_Category_4: {
    en: 'Funktional',
    de: 'Funktional',
    ro: 'Funcțional',
  },
  PP_Paragraph_4_Description_5: {
    en: 'Dieses Cookie verwendet und speichert den Standort des Nutzers für eine optimierte Anwaltssuche.',
    de: 'Dieses Cookie verwendet und speichert den Standort des Nutzers für eine optimierte Anwaltssuche.',
    ro: 'Acest modul cookie utilizează și stochează locația utilizatorului pentru o căutare optimizată a avocaților.',
  },
  PP_Paragraph_4_Type_5: {
    en: 'Sitzung',
    de: 'Sitzung',
    ro: 'Sesiunea',
  },
  PP_Paragraph_4_Category_5: {
    en: 'Funktional',
    de: 'Funktional',
    ro: 'Funcțional',
  },
  PP_Paragraph_4_Description_6: {
    en: 'Dieses Cookie ermöglicht das automatische Einloggen in zukünftige Sitzungen, wenn der Nutzer die aktuelle Sitzung verlässt.',
    de: 'Dieses Cookie ermöglicht das automatische Einloggen in zukünftige Sitzungen, wenn der Nutzer die aktuelle Sitzung verlässt.',
    ro: 'Acest modul cookie permite conectarea automată la sesiunile viitoare atunci când utilizatorul părăsește sesiunea curentă.',
  },
  PP_Paragraph_4_Type_6: {
    en: 'Dauerhaft',
    de: 'Dauerhaft',
    ro: 'Permanent',
  },
  PP_Paragraph_4_Category_6: {
    en: 'Funktional',
    de: 'Funktional',
    ro: 'Funcțional',
  },
  PP_Paragraph_4_Description_7: {
    en: 'Dieses Cookie speichert Informationen über die Registrierung des Benutzers, wie Geschlecht/Titel/E-Mail/Vorname/Nachname.',
    de: 'Dieses Cookie speichert Informationen über die Registrierung des Benutzers, wie Geschlecht/Titel/E-Mail/Vorname/Nachname.',
    ro: 'Acest modul cookie stochează informații despre înregistrarea utilizatorului, cum ar fi sexul/titlul/emailul/prenumele/numele de familie.',
  },
  PP_Paragraph_4_Type_7: {
    en: 'Sitzung',
    de: 'Sitzung',
    ro: 'Sesiunea',
  },
  PP_Paragraph_4_Category_7: {
    en: 'Unverzichtbar',
    de: 'Unverzichtbar',
    ro: 'Indispensabil',
  },
  PP_Paragraph_4_Description_8: {
    en: 'Dieses Cookie speichert Sitzungsinformationen, um mit dem Backend zu kommunizieren, wenn Sie angemeldet sind.',
    de: 'Dieses Cookie speichert Sitzungsinformationen, um mit dem Backend zu kommunizieren, wenn Sie angemeldet sind.',
    ro: 'Acest modul cookie stochează informații de sesiune pentru a comunica cu backend-ul atunci când sunteți conectat.',
  },
  PP_Paragraph_4_Type_8: {
    en: 'Sitzung',
    de: 'Sitzung',
    ro: 'Sesiunea',
  },
  PP_Paragraph_4_Category_8: {
    en: 'Unverzichtbar',
    de: 'Unverzichtbar',
    ro: 'Indispensabil',
  },
  PP_Paragraph_4_Description_9: {
    en: 'Dieses Cookie wird verwendet, um Informationen über Mandanten/Anwälte auf unserer Website zu veröffentlichen.',
    de: 'Dieses Cookie wird verwendet, um Informationen über Mandanten/Anwälte auf unserer Website zu veröffentlichen.',
    ro: 'Acest modul cookie este utilizat pentru a publica informații despre clienți/avocați pe site-ul nostru.',
  },
  PP_Paragraph_4_Type_9: {
    en: 'Sitzung',
    de: 'Sitzung',
    ro: 'Sesiunea',
  },
  PP_Paragraph_4_Category_9: {
    en: 'Unverzichtbar',
    de: 'Unverzichtbar',
    ro: 'Indispensabil',
  },
  PP_Paragraph_5_Title: {
    en: 'Tracking Tools zur Website-Analyse',
    de: 'Tracking Tools zur Website-Analyse',
    ro: 'Instrumente de urmărire pentru analiza site-ului web',
  },
  PP_Paragraph_5: {
    en: 'Wir möchten, dass Sie unsere Webseiten optimal nutzen können. Deshalb setzen wir so genannte Tracking-Tools ein, um unsere Online-Angebote zu verbessern. Die Tracking-Tools ermöglichen es uns, die Nutzung unserer Online-Angebote und die Wirksamkeit unserer Online-Werbung zu messen.',
    de: 'Wir möchten, dass Sie unsere Webseiten optimal nutzen können. Deshalb setzen wir so genannte Tracking-Tools ein, um unsere Online-Angebote zu verbessern. Die Tracking-Tools ermöglichen es uns, die Nutzung unserer Online-Angebote und die Wirksamkeit unserer Online-Werbung zu messen.',
    ro: 'Dorim să puteți utiliza site-urile noastre web în mod optim. Prin urmare, folosim așa-numitele instrumente de urmărire pentru a ne îmbunătăți ofertele online. Instrumentele de urmărire ne permit să măsurăm utilizarea ofertelor noastre online și eficiența publicității noastre online.',
  },
  PP_Paragraph_5_1_Title: {
    en: 'Google Analytics',
    de: 'Google Analytics',
    ro: 'Google Analytics',
  },
  PP_Paragraph_5_1_1_Title: {
    en: 'Beschreibung',
    de: 'Beschreibung',
    ro: 'Descriere',
  },
  PP_Paragraph_5_1_1: {
    en: `Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Ireland Limited, Gordon House Barrow Street Dublin 4, Irland (nachstehend: Google). Google Analytics verwendet Cookies, die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Website (s.u.) wird Ihre IP-Adresse von Google jedoch innerhalb der Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dann dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Website-Aktivitäten zusammenzustellen und um weitere mit der Website-Nutzung und der Internetnutzug verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Google Analytics wird auf dieser Website mit der Erweiterung „_anonymizeIp()“ verwendet. Dadurch werden IP-Adressen gekürzt weiterverarbeitet und einen Personenbeziehbarkeit kann damit ausgeschlossen werden. Soweit den über Sie erhobenen Daten ein Personenbezug zukommt, wird dieser also sofort ausgeschlossen und die personenbezogenen Daten damit umgehend gelöscht. Google unterwirft sich mit den Standardvertragsklauseln dem europäischen Datenschutz und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten.`,
    de: `Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Ireland Limited, Gordon House Barrow Street Dublin 4, Irland (nachstehend: Google). Google Analytics verwendet Cookies, die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Website (s.u.) wird Ihre IP-Adresse von Google jedoch innerhalb der Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dann dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Website-Aktivitäten zusammenzustellen und um weitere mit der Website-Nutzung und der Internetnutzug verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Google Analytics wird auf dieser Website mit der Erweiterung „_anonymizeIp()“ verwendet. Dadurch werden IP-Adressen gekürzt weiterverarbeitet und einen Personenbeziehbarkeit kann damit ausgeschlossen werden. Soweit den über Sie erhobenen Daten ein Personenbezug zukommt, wird dieser also sofort ausgeschlossen und die personenbezogenen Daten damit umgehend gelöscht. Google unterwirft sich mit den Standardvertragsklauseln dem europäischen Datenschutz und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten.`,
  },
  PP_Paragraph_5_1_2_Title: {
    en: 'Zweck und Rechtsgrundlage der Datenverarbeitung',
    de: 'Zweck und Rechtsgrundlage der Datenverarbeitung',
    ro: 'Scopul și temeiul juridic al prelucrării datelor',
  },
  PP_Paragraph_5_1_2_1: {
    en: `Wir nutzen Google Analytics, um die Nutzung unserer Website zu analysieren und so das Nutzererlebnis regelmäßig verbessern zu können. Über die gewonnenen Statistiken können wir unser Angebot verbessern und für Sie interessanter ausgestalten. Rechtsgrundlage für die Verarbeitung Ihrer Daten ist die von Ihnen über das Cookie-Consent-Tool erteilte Einwilligung (Art. 6 Abs. 1 lit. a) DSGVO). Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plug-in herunterladen und installieren: `,
    de: `Wir nutzen Google Analytics, um die Nutzung unserer Website zu analysieren und so das Nutzererlebnis regelmäßig verbessern zu können. Über die gewonnenen Statistiken können wir unser Angebot verbessern und für Sie interessanter ausgestalten. Rechtsgrundlage für die Verarbeitung Ihrer Daten ist die von Ihnen über das Cookie-Consent-Tool erteilte Einwilligung (Art. 6 Abs. 1 lit. a) DSGVO). Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plug-in herunterladen und installieren: `,
  },
  PP_Paragraph_5_1_2_2: {
    en: `http://tools.google.com/dlpage/gaoptout?hl=de`,
    de: `http://tools.google.com/dlpage/gaoptout?hl=de`,
  },
  PP_Paragraph_5_1_3_Title: {
    en: 'Informationen des Drittanbieters',
    de: 'Informationen des Drittanbieters',
    ro: 'Informații de la furnizorul terț',
  },
  PP_Paragraph_5_1_3_1: {
    en: `Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001. Nutzerbedingungen: `,
    de: `Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001. Nutzerbedingungen: `,
  },
  PP_Paragraph_5_1_3_2: {
    en: `http://google.com/analytics/terms/de.html`,
    de: `http://google.com/analytics/terms/de.html`,
  },
  PP_Paragraph_5_1_3_3: {
    en: `, sowie die Datenschutzerklärung: `,
    de: `, sowie die Datenschutzerklärung: `,
  },
  PP_Paragraph_5_1_3_4: {
    en: `http://www.google.de/intl/de/policies/privacy`,
    de: `http://www.google.de/intl/de/policies/privacy`,
  },
  PP_Paragraph_5_2_Title: {
    en: 'Google-Tag-Manager',
    de: 'Google-Tag-Manager',
    ro: 'Google Tag Manager',
  },
  PP_Paragraph_5_2_1: {
    en: `Diese Website benutzt den Google-Tag-Manager. Durch diesen Dienst können Website-Tags über eine Oberfläche verwalten werden. Der Google-Tag-Manager selbst setzt keine Cookies, sondern lediglich Tags und erfasst keine personenbezogenen Daten. Der Dienst sorgt für die Auslösung anderer Tags, die ihrerseits unter Umständen Daten erfassen. Der Google Tag Manager greift jedoch nicht auf diese Daten zu. Wenn auf Domain- oder Cookie-Ebene eine Deaktivierung vorgenommen wurde, bleibt diese für alle Tracking-Tags bestehen, die mit Google-Tag-Manager implementiert werden. Weitere Informationen zum Google Tag Manager finden Sie in den Nutzungsrichtlinien für dieses Produkt unter (`,
    de: `Diese Website benutzt den Google-Tag-Manager. Durch diesen Dienst können Website-Tags über eine Oberfläche verwalten werden. Der Google-Tag-Manager selbst setzt keine Cookies, sondern lediglich Tags und erfasst keine personenbezogenen Daten. Der Dienst sorgt für die Auslösung anderer Tags, die ihrerseits unter Umständen Daten erfassen. Der Google Tag Manager greift jedoch nicht auf diese Daten zu. Wenn auf Domain- oder Cookie-Ebene eine Deaktivierung vorgenommen wurde, bleibt diese für alle Tracking-Tags bestehen, die mit Google-Tag-Manager implementiert werden. Weitere Informationen zum Google Tag Manager finden Sie in den Nutzungsrichtlinien für dieses Produkt unter (`,
  },
  PP_Paragraph_5_2_2: {
    en: `https://marketingplatform.google.com/intl/de/about/analytics/tag-manager/use-policy/`,
    de: `https://marketingplatform.google.com/intl/de/about/analytics/tag-manager/use-policy/`,
  },
  PP_Paragraph_5_3_Title: {
    en: 'Sonstige Dienste',
    de: 'Sonstige Dienste',
    ro: 'Alte servicii',
  },
  PP_Paragraph_5_3_1: {
    en: `Auf unserer Website ist für Sie Google Maps zur Darstellung einer interaktiven Karte eingebunden, ein Dienst der Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland. Wir nutzen Google Maps aufgrund Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO. Durch den bloßen Aufruf unserer Website werden keine Daten an Google übermittelt. Erst wenn Sie auf die Karte klicken, aktivieren Sie die interaktive Karte von Google Maps und willigen damit in die Übermittlung der Daten an Google ein. Google erfasst gerätebezogene Informationen, Protokolldaten inklusive der IP-Adresse sowie standortbezogene Informationen. Nach dem Aktivieren des Links werden Ihre personenbezogenen Daten über die Nutzung automatisch von der Plattform verarbeitet, so als wenn Sie Google Maps direkt besuchen würden. Für diese Verarbeitung ist allein die Google Maps verantwortlich. Google verwendet diese Informationen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Google wird diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Google sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen von Google unter `,
    de: `Auf unserer Website ist für Sie Google Maps zur Darstellung einer interaktiven Karte eingebunden, ein Dienst der Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland. Wir nutzen Google Maps aufgrund Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO. Durch den bloßen Aufruf unserer Website werden keine Daten an Google übermittelt. Erst wenn Sie auf die Karte klicken, aktivieren Sie die interaktive Karte von Google Maps und willigen damit in die Übermittlung der Daten an Google ein. Google erfasst gerätebezogene Informationen, Protokolldaten inklusive der IP-Adresse sowie standortbezogene Informationen. Nach dem Aktivieren des Links werden Ihre personenbezogenen Daten über die Nutzung automatisch von der Plattform verarbeitet, so als wenn Sie Google Maps direkt besuchen würden. Für diese Verarbeitung ist allein die Google Maps verantwortlich. Google verwendet diese Informationen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Google wird diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Google sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen von Google unter `,
  },
  PP_Paragraph_5_3_2: {
    en: `https://policies.google.com/privacy?hl=de&gl=de`,
    de: `https://policies.google.com/privacy?hl=de&gl=de`,
  },
  PP_Paragraph_6_Title: {
    en: 'Weitere Empfänger von personenbezogenen Daten',
    de: 'Weitere Empfänger von personenbezogenen Daten',
    ro: 'Alți destinatari de date cu caracter personal',
  },
  PP_Paragraph_6_1: {
    en: `Für den Betrieb unserer Website beauftragen wir noch weitere externe Dienstleister mit der Datenverarbeitung (z.B. Rechenzentren). Soweit erforderlich, verarbeiten diese Dienstleister dabei auch personenbezogene Daten. Wir wollen einen möglichst sicheren Betrieb unserer Website gewährleisten. Dies dient insbesondere auch dem Datenschutz. Die Dienstleister werden von uns sorgfältig ausgewählt und überwacht. Zur Abwicklung von Zahlungen bedienen wir uns eines Zahlungsdienstleisters. Hierbei kommt es zu einer Verarbeitung von Daten außerhalb der EU. Um sicherzustellen, dass ein angemessenes Datenschutzniveau eingehalten wird, haben wir entsprechende vertragliche Vereinbarungen getroffen (z.B. EU-Standardvertragsklauseln).`,
    de: `Für den Betrieb unserer Website beauftragen wir noch weitere externe Dienstleister mit der Datenverarbeitung (z.B. Rechenzentren). Soweit erforderlich, verarbeiten diese Dienstleister dabei auch personenbezogene Daten. Wir wollen einen möglichst sicheren Betrieb unserer Website gewährleisten. Dies dient insbesondere auch dem Datenschutz. Die Dienstleister werden von uns sorgfältig ausgewählt und überwacht. Zur Abwicklung von Zahlungen bedienen wir uns eines Zahlungsdienstleisters. Hierbei kommt es zu einer Verarbeitung von Daten außerhalb der EU. Um sicherzustellen, dass ein angemessenes Datenschutzniveau eingehalten wird, haben wir entsprechende vertragliche Vereinbarungen getroffen (z.B. EU-Standardvertragsklauseln).`,
  },
  PP_Paragraph_6_2: {
    en: `Die o. g. personenbezogenen Daten werden auf den Servern des Hosters Scaleway (8 rue de la Ville l’Evêque, 75008 Paris, France) in der EU gespeichert. Das externe Hosting erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Scaleway verarbeitet Ihre Daten nur insoweit, wie dies zur Erfüllung der Leistungspflichten erforderlich ist und hat unsere Weisungen in Bezug auf diese Daten zu befolgen. Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter geschlossen.`,
    de: `Die o. g. personenbezogenen Daten werden auf den Servern des Hosters Scaleway (8 rue de la Ville l’Evêque, 75008 Paris, France) in der EU gespeichert. Das externe Hosting erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Scaleway verarbeitet Ihre Daten nur insoweit, wie dies zur Erfüllung der Leistungspflichten erforderlich ist und hat unsere Weisungen in Bezug auf diese Daten zu befolgen. Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter geschlossen.`,
  },
  PP_Paragraph_6_3: {
    en: `Technischer Betreiber und Entwickler unserer Plattform ist die Yoursoft Alternative Srl, (Sector 6, Str. Liniei Nr. 37, Bl. 6 C, Bukarest, RO) sowie ihr Subunternehmer, die Aquasoft Srl, Str. Virtutii Nr. 6, Sector 6, Bukarest, RO). Das externe Betreiben der Plattform erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Yoursoft und seine Subunternehmer verarbeiten die Daten nur insoweit, wie dies zur Erfüllung der Leistungspflichten zwingend erforderlich ist und hat unsere Weisungen in Bezug auf diese Daten zu befolgen. Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter geschlossen.`,
    de: `Technischer Betreiber und Entwickler unserer Plattform ist die Yoursoft Alternative Srl, (Sector 6, Str. Liniei Nr. 37, Bl. 6 C, Bukarest, RO) sowie ihr Subunternehmer, die Aquasoft Srl, Str. Virtutii Nr. 6, Sector 6, Bukarest, RO). Das externe Betreiben der Plattform erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Yoursoft und seine Subunternehmer verarbeiten die Daten nur insoweit, wie dies zur Erfüllung der Leistungspflichten zwingend erforderlich ist und hat unsere Weisungen in Bezug auf diese Daten zu befolgen. Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter geschlossen.`,
  },
  PP_Paragraph_6_4: {
    en: `Die personenbezogenen Daten in Form Ihres Namens und Ihrer E-Mail-Adresse werden zum Zwecke des E-Mail-Versands und des Kontakts mit Ihnen an den Dienstleister Brevo (Sendinblue GmbH, Köpenicker Straße 126, 10179 Berlin) weitergegeben. Die Datenverarbeitung erfolgt auf in Deutschland befindlichen Servern. Die Datenweitergabe erfolgt zum Zwecke der Kontaktaufnahme mit Ihnen per Mail im Wege einer sicheren und effizienten Abwicklung durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter geschlossen.`,
    de: `Die personenbezogenen Daten in Form Ihres Namens und Ihrer E-Mail-Adresse werden zum Zwecke des E-Mail-Versands und des Kontakts mit Ihnen an den Dienstleister Brevo (Sendinblue GmbH, Köpenicker Straße 126, 10179 Berlin) weitergegeben. Die Datenverarbeitung erfolgt auf in Deutschland befindlichen Servern. Die Datenweitergabe erfolgt zum Zwecke der Kontaktaufnahme mit Ihnen per Mail im Wege einer sicheren und effizienten Abwicklung durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter geschlossen.`,
  },
  PP_Paragraph_6_5: {
    en: `Sofern Sie uns eine Anfrage per E-Mail senden oder gesendet haben, wird der Inhalt Ihrer Nachricht sowie Ihre E-Mail-Adresse an unsere Ticketing-Plattform Freshdesk des Dienstleisters Freshworks Inc. (2950 S. Delaware Street, Suite 201, San Mateo, CA 94403) übermittelt. Der Einsatz der Ticketing-Plattform erfolgt zum Zwecke der Kundenbetreuung und der Verwaltung von Nutzeranfragen mittels eines Ticketsystems, um Nutzeranfragen effizient und sicher bearbeiten zu können (Art. 6 Abs. 1 lit. f DSGVO). Freshworks verarbeitet Ihre Daten nur insoweit, wie dies zur Erfüllung der Leistungspflichten erforderlich ist und hat unsere Weisungen in Bezug auf diese Daten zu befolgen. Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter geschlossen. Freshworks hat Compliance-Maßnahmen für internationale Datenübermittlungen umgesetzt. Diese gelten für alle weltweiten Aktivitäten, bei denen Freshworks personenbezogene Daten von natürlichen Personen in der EU verarbeitet. Diese Maßnahmen basieren auf den EU-Standardvertragsklauseln (SCCs). Freshworks ist nach dem EU-U.S. Data Privacy Framework zertifiziert.`,
    de: `Sofern Sie uns eine Anfrage per E-Mail senden oder gesendet haben, wird der Inhalt Ihrer Nachricht sowie Ihre E-Mail-Adresse an unsere Ticketing-Plattform Freshdesk des Dienstleisters Freshworks Inc. (2950 S. Delaware Street, Suite 201, San Mateo, CA 94403) übermittelt. Der Einsatz der Ticketing-Plattform erfolgt zum Zwecke der Kundenbetreuung und der Verwaltung von Nutzeranfragen mittels eines Ticketsystems, um Nutzeranfragen effizient und sicher bearbeiten zu können (Art. 6 Abs. 1 lit. f DSGVO). Freshworks verarbeitet Ihre Daten nur insoweit, wie dies zur Erfüllung der Leistungspflichten erforderlich ist und hat unsere Weisungen in Bezug auf diese Daten zu befolgen. Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter geschlossen. Freshworks hat Compliance-Maßnahmen für internationale Datenübermittlungen umgesetzt. Diese gelten für alle weltweiten Aktivitäten, bei denen Freshworks personenbezogene Daten von natürlichen Personen in der EU verarbeitet. Diese Maßnahmen basieren auf den EU-Standardvertragsklauseln (SCCs). Freshworks ist nach dem EU-U.S. Data Privacy Framework zertifiziert.`,
  },
  PP_Paragraph_6_6: {
    en: `Die personenbezogenen Daten in Form Ihrer Adresse werden im Rahmen des Angebots von Google Maps auf unserer Webseite an Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland übermittelt. Dies geschieht ausschließlich, wenn ein Nutzer unserer Webseite diese Funktion aktiviert. Die Datenübermittlung erfolgt zum Zwecke der kartografischen Anzeige und einer nutzerfreundlichen und effektiven Anzeige des Standortes der Kanzlei (Art. 6 Abs. 1 lit. f DSGVO). Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter geschlossen.`,
    de: `Die personenbezogenen Daten in Form Ihrer Adresse werden im Rahmen des Angebots von Google Maps auf unserer Webseite an Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland übermittelt. Dies geschieht ausschließlich, wenn ein Nutzer unserer Webseite diese Funktion aktiviert. Die Datenübermittlung erfolgt zum Zwecke der kartografischen Anzeige und einer nutzerfreundlichen und effektiven Anzeige des Standortes der Kanzlei (Art. 6 Abs. 1 lit. f DSGVO). Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter geschlossen.`,
  },
  PP_Paragraph_6_7: {
    en: `Eine Übermittlung Ihrer oben genannten personenbezogenen Daten in Drittstaaten erfolgt im Rahmen des Einsatzes Content Delivery Network (CDN) von Cloudflare Inc., 665 3rd St. 200, San Francisco, CA 94107, USA. Das CDN beinhaltet Server in Drittstaaten, wobei die o. g. Daten kurzzeitig auf den Servern von Cloudflare gespeichert werden können. Hinsichtlich des Zwecks und der Rechtsgrundlage für den Einsatz von Cloudflare wird auf die o.g. Ausführungen verwiesen. Cloudflare hat Compliance-Maßnahmen für internationale Datenübermittlungen umgesetzt. Diese gelten für alle weltweiten Aktivitäten, bei denen Cloudflare personenbezogene Daten von natürlichen Personen in der EU verarbeitet. Diese Maßnahmen basieren auf den EU-Standardvertragsklauseln (SCCs). Cloudflare ist nach dem EU-U.S. Data Privacy Framework zertifiziert, hat sich dem EU Cloud Code of Conduct verpflichtet und ist unter anderem geprüft durch Auditierungen nach ISO/IEC 27018:2019, SO/IEC 27701:2019 und ISO 27001:2013.`,
    de: `Eine Übermittlung Ihrer oben genannten personenbezogenen Daten in Drittstaaten erfolgt im Rahmen des Einsatzes Content Delivery Network (CDN) von Cloudflare Inc., 665 3rd St. 200, San Francisco, CA 94107, USA. Das CDN beinhaltet Server in Drittstaaten, wobei die o. g. Daten kurzzeitig auf den Servern von Cloudflare gespeichert werden können. Hinsichtlich des Zwecks und der Rechtsgrundlage für den Einsatz von Cloudflare wird auf die o.g. Ausführungen verwiesen. Cloudflare hat Compliance-Maßnahmen für internationale Datenübermittlungen umgesetzt. Diese gelten für alle weltweiten Aktivitäten, bei denen Cloudflare personenbezogene Daten von natürlichen Personen in der EU verarbeitet. Diese Maßnahmen basieren auf den EU-Standardvertragsklauseln (SCCs). Cloudflare ist nach dem EU-U.S. Data Privacy Framework zertifiziert, hat sich dem EU Cloud Code of Conduct verpflichtet und ist unter anderem geprüft durch Auditierungen nach ISO/IEC 27018:2019, SO/IEC 27701:2019 und ISO 27001:2013.`,
  },
  PP_Paragraph_6_8: {
    en: `Eine Übermittlung Ihrer personenbezogenen Daten in Form Ihrer E-Mail-Adresse und des Inhalts Ihrer persönlichen Mail-Anfrage bei uns in Drittstaaten kann im Rahmen des Einsatzes der Ticketing-Plattform Freshdesk des Dienstleisters Freshworks Inc. (2950 S. Delaware Street, Suite 201, San Mateo, CA 94403) erfolgen. Freshworks unterhält auch Server außerhalb der Europäischen Union, insbesondere den USA, was zu einer Datenübermittlung in die USA führen kann. Hinsichtlich des Zwecks und der Rechtsgrundlage für den Einsatz von Freshdesk wird auf die o.g. Ausführungen verwiesen. Freshworks hat Compliance-Maßnahmen für internationale Datenübermittlungen umgesetzt. Diese gelten für alle weltweiten Aktivitäten, bei denen Freshworks personenbezogene Daten von natürlichen Personen in der EU verarbeitet. Diese Maßnahmen basieren auf den EU-Standardvertragsklauseln (SCCs). Freshworks ist nach dem EU-U.S. Data Privacy Framework zertifiziert.`,
    de: `Eine Übermittlung Ihrer personenbezogenen Daten in Form Ihrer E-Mail-Adresse und des Inhalts Ihrer persönlichen Mail-Anfrage bei uns in Drittstaaten kann im Rahmen des Einsatzes der Ticketing-Plattform Freshdesk des Dienstleisters Freshworks Inc. (2950 S. Delaware Street, Suite 201, San Mateo, CA 94403) erfolgen. Freshworks unterhält auch Server außerhalb der Europäischen Union, insbesondere den USA, was zu einer Datenübermittlung in die USA führen kann. Hinsichtlich des Zwecks und der Rechtsgrundlage für den Einsatz von Freshdesk wird auf die o.g. Ausführungen verwiesen. Freshworks hat Compliance-Maßnahmen für internationale Datenübermittlungen umgesetzt. Diese gelten für alle weltweiten Aktivitäten, bei denen Freshworks personenbezogene Daten von natürlichen Personen in der EU verarbeitet. Diese Maßnahmen basieren auf den EU-Standardvertragsklauseln (SCCs). Freshworks ist nach dem EU-U.S. Data Privacy Framework zertifiziert.`,
  },
  PP_Paragraph_6_9: {
    en: `Eine weitere Übermittlung der Ihrer o. g. Daten erfolgt nicht.`,
    de: `Eine weitere Übermittlung der Ihrer o. g. Daten erfolgt nicht.`,
  },

  PP_Paragraph_7_Title: {
    en: 'Ihre Rechte als betroffene Person',
    de: 'Ihre Rechte als betroffene Person',
    ro: 'Drepturile dumneavoastră în calitate de persoană vizată',
  },
  PP_Paragraph_7: {
    en: `Auf Anforderung teilen wir Ihnen mit, ob und welche Daten wir über Sie gespeichert haben. Soweit die gesetzlichen Voraussetzungen vorliegen, haben Sie ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Sie haben weiter das Recht, die Sie betreffenden Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format von uns zu erhalten; Sie können diese Daten an andere Stellen übermitteln oder übermitteln lassen. Sie haben außerdem ein Beschwerderecht bei der zuständigen Aufsichtsbehörde für den Datenschutz.`,
    de: `Auf Anforderung teilen wir Ihnen mit, ob und welche Daten wir über Sie gespeichert haben. Soweit die gesetzlichen Voraussetzungen vorliegen, haben Sie ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Sie haben weiter das Recht, die Sie betreffenden Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format von uns zu erhalten; Sie können diese Daten an andere Stellen übermitteln oder übermitteln lassen. Sie haben außerdem ein Beschwerderecht bei der zuständigen Aufsichtsbehörde für den Datenschutz.`,
  },
  PP_Paragraph_8_Title: {
    en: 'Dauer der Speicherung',
    de: 'Dauer der Speicherung',
    ro: 'Durata de depozitare',
  },
  PP_Paragraph_8: {
    en: `Von uns verarbeiteten Daten im Sinne von Ziffer 3.1-3.7 werden spätestens nach dem Ablauf der Vertragsbeziehung und Ablauf eventueller handels- und steuerrechtlicher Aufbewahrungsfristen nach 6 Jahren gelöscht. Automatisch erfasste Daten im Sinne von Ziffer 3.7 werden spätestens nach Ablauf von 90 Tagen gelöscht.`,
    de: `Von uns verarbeiteten Daten im Sinne von Ziffer 3.1-3.7 werden spätestens nach dem Ablauf der Vertragsbeziehung und Ablauf eventueller handels- und steuerrechtlicher Aufbewahrungsfristen nach 6 Jahren gelöscht. Automatisch erfasste Daten im Sinne von Ziffer 3.7 werden spätestens nach Ablauf von 90 Tagen gelöscht.`,
  },
  PP_Paragraph_9_Title: {
    en: 'Widerspruchsrecht',
    de: 'Widerspruchsrecht',
    ro: 'Dreptul de opoziție',
  },
  PP_Paragraph_9: {
    en: `Soweit die Anwado GmbH Ihre Daten gemäß der vorstehenden Ziffern auf Basis der Interessenabwägung verarbeitet, steht Ihnen bei Vorliegen der gesetzlichen Voraussetzungen ein Widerspruchsrecht zu.`,
    de: `Soweit die Anwado GmbH Ihre Daten gemäß der vorstehenden Ziffern auf Basis der Interessenabwägung verarbeitet, steht Ihnen bei Vorliegen der gesetzlichen Voraussetzungen ein Widerspruchsrecht zu.`,
  },
  // Become a part of the unique Anwado Community
  BPAC_Title: {
    en: 'Become a part of the unique Anwado community',
    de: 'Werden Sie Teil der einzigartigen Anwado Community',
    ro: 'Fă parte din comunitatea unică Anwado',
  },
  BPAC_Description: {
    en: 'Register free of charge - contact the right lawyer - submit reviews',
    de: 'Kostenlos registrieren - Kontakt mit dem passenden Anwalt aufnehmen - Bewertungen abgeben',
    ro: 'Înregistrați-vă gratuit - Contactați avocatul potrivit - Trimiteți recenzii',
  },
  BPAC_Action_Button_Client: {
    en: 'For Clients',
    de: 'Für Mandanten',
    ro: 'Pentru clienți',
  },
  BPAC_Action_Button_Lawyer: {
    en: 'For Laywer',
    de: 'Für Anwälte',
    ro: 'Pentru avocați',
  },
  BPAC_Lawyer_1_Description_1: {
    en: 'wants an appointment!',
    de: 'will einen Termin!',
    ro: 'vrea o programare!',
  },
  BPAC_Lawyer_2_Description_1: {
    en: 'and 2 more',
    de: 'und 2 weitere',
    ro: 'și încă 2',
  },
  BPAC_Lawyer_2_Description_2: {
    en: 'Requests pending',
    de: 'Anfragen stehen aus',
    ro: 'Cereri în așteptare',
  },
  BPAC_Lawyer_3_Description_1: {
    en: 'has made you his',
    de: 'hat dich zu seinen',
    ro: 'te-a făcut al său',
  },
  BPAC_Lawyer_3_Description_2: {
    en: 'Favorites added',
    de: 'Favoriten hinzugefügt',
    ro: 'Favoritele adăugate',
  },
  BPAC_Lawyer_4_Description_1: {
    en: 'has a',
    de: 'hat einen',
    ro: 'are un',
  },
  BPAC_Lawyer_4_Description_2: {
    en: 'Date requested',
    de: 'Termin angefragt',
    ro: 'Programare solicitată',
  },
  // Haven't found a lawyer yet?
  HFLY_Info_4_Title: {
    en: 'Not convinced yet?',
    de: 'Noch nicht überzeugt?',
    ro: 'Nu sunteți încă convins?',
  },
  HFLY_Info_4_Description: {
    en: 'If you have any further questions or need assistance in creating your online presence on Anwado, please feel free to contact us. The Anwado team is looking forward to answering your questions and supporting you.',
    de: 'Wenn Sie noch weitere Fragen haben oder Unterstützung beim Erstellen Ihrer Online-Präsenz auf Anwado benötigen, kontaktieren Sie uns gerne. Das Anwado Team freut sich darauf, Ihre Fragen zu beantworten und Sie tatkräftig zu unterstützen.',
    ro: 'Dacă aveți întrebări suplimentare sau dacă aveți nevoie de sprijin pentru a vă crea prezența online pe Anwado, nu ezitați să ne contactați. Echipa Anwado așteaptă cu nerăbdare să vă răspundă la întrebări și să vă ofere sprijin activ.',
  },
  HFLY_Info_4_Action_Button: {
    en: 'Find lawyer now',
    de: 'Jetzt Anwalt finden',
    ro: 'Găsiți un avocat acum',
  },
  // General Terms and Conditions Client
  DTAC_Meta_Title_1: {
    en: 'Anwado - General Terms and Conditions (AGB)',
    de: 'Anwado - Allgemeine Geschäftsbedingungen (AGB)',
    ro: 'Anwado - Termeni și condiții generale',
  },
  DTAC_Meta_Description_1: {
    en: 'Find out more about our terms and conditions to better understand and safely use our services.',
    de: 'Informieren Sie sich über die AGB von Anwado, um unsere Dienstleistungen besser zu verstehen und sicher zu nutzen.',
    ro: 'Citiți despre Termenii și condițiile de utilizare a Anwado pentru a înțelege mai bine și a utiliza în siguranță serviciile noastre.',
  },
  DTAC_For_Lawyers_Switch: {
    en: 'Für Anwälte',
    de: 'Für Anwälte',
    ro: 'Pentru avocați',
  },
  DTAC_For_Clients_Switch: {
    en: 'Für Mandaten',
    de: 'Für Mandaten',
    ro: 'Pentru mandate',
  },
  DTAC_Pre_Title: {
    en: 'Stand Dezember 2023',
    de: 'Stand Dezember 2023',
    ro: 'Stand Dezember 2023',
  },
  GTCC_Title: {
    en: 'Allgemeine Geschäftsbedingungen für registrierte Nutzer',
    de: 'Allgemeine Geschäftsbedingungen für registrierte Nutzer',
    ro: 'Termeni și condiții generale pentru utilizatorii înregistrați',
  },
  GTCC_Post_Title_1: {
    en: `Allgemeine Geschäftsbedingungen für registrierte Nutzer* (nachfolgend Nutzer) der Anwado GmbH, (nachfolgend „Anwado“), für das Internet-Angebot `,
    de: `Allgemeine Geschäftsbedingungen für registrierte Nutzer* (nachfolgend Nutzer) der Anwado GmbH, (nachfolgend „Anwado“), für das Internet-Angebot `,
  },
  GTCC_Post_Title_2: {
    en: `www.anwado.de`,
    de: `www.anwado.de`,
  },
  GTCC_Post_Title_3: {
    en: ` 
(* Im Folgenden wird aus Gründen der besseren Lesbarkeit ausschließlich die männliche Form verwendet. Sie bezieht sich auf Personen jeglichen Geschlechts.)`,
    de: ` 
(* Im Folgenden wird aus Gründen der besseren Lesbarkeit ausschließlich die männliche Form verwendet. Sie bezieht sich auf Personen jeglichen Geschlechts.)`,
  },
  GTCC_Description_Title_1: {
    en: 'Vorbemerkung',
    de: 'Vorbemerkung',
    ro: 'Observație preliminară',
  },
  GTCC_Description_1_1: {
    en: `Auf der Plattform Anwado registrierte Nutzer haben die Möglichkeit, kostenlos Anwälte auf `,
    de: `Auf der Plattform Anwado registrierte Nutzer haben die Möglichkeit, kostenlos Anwälte auf `,
  },
  GTCC_Description_1_2: {
    en: `www.anwado.de`,
    de: `www.anwado.de`,
  },
  GTCC_Description_1_3: {
    en: ` zu suchen, Bewertungen über einzelne Anwälte abzugeben, Online-Termine zu buchen, einen Anwalt auf eine Online-Favoritenliste zu setzen und weitere personalisierte Dienste zu nutzen. Hierfür gelten die vorliegenden Allgemeinen Geschäftsbedingungen (nachfolgend AGB) für registrierte Nutzer in Verbindung mit den Bewertungsrichtlinien von Anwado, die unter Bewertungsrichtlinien abgerufen werden können.
Für die Bereitstellung von Leistungen durch Anwado für Rechtsanwälte mit einer Silber-, Gold- oder Platin-Mitgliedschaft gelten ausschließlich die Allgemeinen Geschäftsbedingungen für Rechtsanwälte, die unter `,
    de: ` zu suchen, Bewertungen über einzelne Anwälte abzugeben, Online-Termine zu buchen, einen Anwalt auf eine Online-Favoritenliste zu setzen und weitere personalisierte Dienste zu nutzen. Hierfür gelten die vorliegenden Allgemeinen Geschäftsbedingungen (nachfolgend AGB) für registrierte Nutzer in Verbindung mit den Bewertungsrichtlinien von Anwado, die unter Bewertungsrichtlinien abgerufen werden können.
Für die Bereitstellung von Leistungen durch Anwado für Rechtsanwälte mit einer Silber-, Gold- oder Platin-Mitgliedschaft gelten ausschließlich die Allgemeinen Geschäftsbedingungen für Rechtsanwälte, die unter `,
  },
  GTCC_Description_1_4: {
    en: `AGB`,
    de: `AGB`,
  },
  GTCC_Description_1_5: {
    en: ` Anwälte abgerufen werden können.`,
    de: ` Anwälte abgerufen werden können.`,
  },
  GTCC_Paragraph_1_Title: {
    en: 'Geltungsbereich',
    de: 'Geltungsbereich',
    ro: 'Domeniul de aplicare',
  },
  GTCC_Paragraph_1_1_Description: {
    en: 'Die vorliegenden AGB gelten für alle gegenwärtig und zukünftig unter ',
    de: 'Die vorliegenden AGB gelten für alle gegenwärtig und zukünftig unter ',
    ro: 'Prezentele CCG se aplică tuturor activităților curente și viitoare desfășurate în cadrul',
  },
  GTCC_Paragraph_1_2_Description: {
    en: 'www.anwado.de',
    de: 'www.anwado.de',
    ro: 'www.anwado.de',
  },
  GTCC_Paragraph_1_3_Description: {
    en: ' angebotenen kostenlosen und registrierungspflichtigen Dienste für Nutzer, bis sie durch Anwado geändert oder ergänzt werden.',
    de: ' angebotenen kostenlosen und registrierungspflichtigen Dienste für Nutzer, bis sie durch Anwado geändert oder ergänzt werden.',
    ro: ' oferite utilizatorilor în mod gratuit și sub rezerva înregistrării, până când sunt modificate sau completate de Anwado.',
  },
  GTCC_Paragraph_2_Title: {
    en: 'Nutzungsberechtigung',
    de: 'Nutzungsberechtigung',
    ro: 'Dreptul de utilizare',
  },
  GTCC_Paragraph_2_Description: {
    en: 'Für die Anmeldung und Registrierung bei Anwado ist Voraussetzung, dass der Nutzer, wenn er Verbraucher ist, eine unbeschränkt geschäftsfähige natürliche Person über 18 Jahre ist und, wenn er sich für ein Unternehmen, gleich welcher Rechtsform, anmeldet, vertretungsberechtigt ist. Ein Anspruch auf Nutzung des Dienstes besteht nicht.',
    de: 'Für die Anmeldung und Registrierung bei Anwado ist Voraussetzung, dass der Nutzer, wenn er Verbraucher ist, eine unbeschränkt geschäftsfähige natürliche Person über 18 Jahre ist und, wenn er sich für ein Unternehmen, gleich welcher Rechtsform, anmeldet, vertretungsberechtigt ist. Ein Anspruch auf Nutzung des Dienstes besteht nicht.',
    ro: 'Pentru a se conecta și a se înregistra la Anwado, este o condiție prealabilă ca utilizatorul, dacă este consumator, să fie o persoană fizică cu vârsta de peste 18 ani cu capacitate juridică nelimitată și, dacă se înregistrează pentru o companie, indiferent de forma juridică a acesteia, să fie autorizat să o reprezinte. Nu există niciun drept de utilizare a serviciului.',
  },
  GTCC_Paragraph_3_Title: {
    en: 'Nutzer-Profil',
    de: 'Nutzer-Profil',
    ro: 'Profilul utilizatorului',
  },
  GTCC_Paragraph_3_Description_1: {
    en: 'Um die registrierungspflichtigen Dienste von Anwado nutzen zu können, muss der Nutzer sich bei Anwado registrieren und ein kostenloses Profil anlegen. Mit der Registrierung auf Anwado bestätigt der Nutzer, dass er gem. Ziffer 2 zur Nutzung des Dienstes berechtigt ist.',
    de: 'Um die registrierungspflichtigen Dienste von Anwado nutzen zu können, muss der Nutzer sich bei Anwado registrieren und ein kostenloses Profil anlegen. Mit der Registrierung auf Anwado bestätigt der Nutzer, dass er gem. Ziffer 2 zur Nutzung des Dienstes berechtigt ist.',
    ro: 'Pentru a utiliza serviciile Anwado care necesită înregistrare, utilizatorul trebuie să se înregistreze la Anwado și să-și creeze un profil gratuit. Prin înregistrarea pe Anwado, utilizatorul confirmă că este autorizat să utilizeze serviciul în conformitate cu clauza 2.',
  },
  GTCC_Paragraph_3_Description_2: {
    en: 'Der Nutzer verpflichtet sich, bei der Registrierung wahrheitsgemäße und vollständige Angaben zu machen und insbesondere keine Rechte Dritter zu verletzen. Eine Verletzung von Rechten Dritter liegt insbesondere dann vor, wenn personenbezogene Informationen oder sonstige Daten Dritter ohne deren Zustimmung verwendet werden, z.B. Name, Anschrift, Telefonnummer oder E-Mail-Adresse. Anwado ist berechtigt, jederzeit einen Nachweis darüber zu verlangen, dass die Voraussetzungen vorliegen und die Angaben richtig sind.',
    de: 'Der Nutzer verpflichtet sich, bei der Registrierung wahrheitsgemäße und vollständige Angaben zu machen und insbesondere keine Rechte Dritter zu verletzen. Eine Verletzung von Rechten Dritter liegt insbesondere dann vor, wenn personenbezogene Informationen oder sonstige Daten Dritter ohne deren Zustimmung verwendet werden, z.B. Name, Anschrift, Telefonnummer oder E-Mail-Adresse. Anwado ist berechtigt, jederzeit einen Nachweis darüber zu verlangen, dass die Voraussetzungen vorliegen und die Angaben richtig sind.',
    ro: 'Utilizatorul se angajează să furnizeze informații veridice și complete la înregistrare și, în special, să nu încalce drepturile terților. Se consideră că a avut loc o încălcare a drepturilor terților, în special în cazul în care informațiile personale sau alte date ale terților sunt utilizate fără consimțământul acestora, de exemplu, numele, adresa, numărul de telefon sau adresa de e-mail. Anwado are dreptul de a solicita în orice moment dovada că cerințele au fost îndeplinite și că informațiile furnizate sunt corecte.',
  },
  GTCC_Paragraph_3_Description_3: {
    en: 'Der Nutzer verpflichtet sich weiter, sein Passwort nicht an Dritte weiterzugeben und vor dem Zugriff Dritter geschützt aufzubewahren. Anwado kann den Zugang zu den registrierungspflichtigen Diensten sperren, wenn der begründete Verdacht besteht, dass das Passwort von unberechtigten Dritten genutzt wird. Der Nutzer wird in diesem Fall informiert und hat ein neues Passwort zu erstellen.',
    de: 'Der Nutzer verpflichtet sich weiter, sein Passwort nicht an Dritte weiterzugeben und vor dem Zugriff Dritter geschützt aufzubewahren. Anwado kann den Zugang zu den registrierungspflichtigen Diensten sperren, wenn der begründete Verdacht besteht, dass das Passwort von unberechtigten Dritten genutzt wird. Der Nutzer wird in diesem Fall informiert und hat ein neues Passwort zu erstellen.',
    ro: 'De asemenea, utilizatorul se angajează să nu divulge parola sa unor terțe părți și să o păstreze protejată împotriva accesului unor terțe părți. Anwado poate bloca accesul la serviciile care necesită înregistrare în cazul în care există o suspiciune rezonabilă că parola este folosită de terți neautorizați. În acest caz, utilizatorul va fi informat și va trebui să creeze o nouă parolă.',
  },
  GTCC_Paragraph_4_Title: {
    en: 'Leistungsumfang',
    de: 'Leistungsumfang',
    ro: 'Domeniul de aplicare a serviciilor',
  },
  GTCC_Paragraph_4_Description_1: {
    en: 'Anwado selbst erbringt keine Rechtsdienstleistungen. Anwado beschränkt sich auf die Rolle eines Vermittlers und technischen Dienstleisters zwischen dem Nutzer und dem Rechtsanwalt. Die Vergütung für die zu erbringende Rechtsdienstleistung durch den Anwalt muss zwischen dem Anwalt und dem Nutzer direkt vereinbart werden.',
    de: 'Anwado selbst erbringt keine Rechtsdienstleistungen. Anwado beschränkt sich auf die Rolle eines Vermittlers und technischen Dienstleisters zwischen dem Nutzer und dem Rechtsanwalt. Die Vergütung für die zu erbringende Rechtsdienstleistung durch den Anwalt muss zwischen dem Anwalt und dem Nutzer direkt vereinbart werden.',
    ro: 'Anwado nu oferă servicii juridice. Anwado se limitează la rolul de intermediar și furnizor de servicii tehnice între utilizator și avocat. Remunerația pentru serviciul juridic care urmează să fie furnizat de avocat trebuie să fie convenită direct între avocat și utilizator.',
  },
  GTCC_Paragraph_4_Description_2_1: {
    en: 'Die unter ',
    de: 'Die unter ',
    ro: 'Sub',
  },
  GTCC_Paragraph_4_Description_2_2: {
    en: 'www.anwado.de',
    de: 'www.anwado.de',
    ro: 'www.anwado.de',
  },
  GTCC_Paragraph_4_Description_2_3: {
    en: ' angebotenen Dienste sind für den Nutzer kostenlos und stehen nur im Rahmen der technischen und betrieblichen Möglichkeiten zur Verfügung. Anwado ist nicht verantwortlich für Unterbrechungen oder Verzögerungen der Dienste, die außerhalb der Kontrolle von Anwado liegen. Der Nutzer erkennt an, dass Anwado für derartige Ereignisse nicht haftbar gemacht werden kann und dass die Bereitstellung der Dienste von der Zuverlässigkeit, Verfügbarkeit und Bereitstellung dauerhafter Verbindungen durch Dritte (Betreiber des Telekommunikationsnetzes, des öffentlichen Internets, der Ausrüstung des Nutzers usw.) sowie von der Richtigkeit und Vollständigkeit der Nutzerdaten abhängig ist.',
    de: ' angebotenen Dienste sind für den Nutzer kostenlos und stehen nur im Rahmen der technischen und betrieblichen Möglichkeiten zur Verfügung. Anwado ist nicht verantwortlich für Unterbrechungen oder Verzögerungen der Dienste, die außerhalb der Kontrolle von Anwado liegen. Der Nutzer erkennt an, dass Anwado für derartige Ereignisse nicht haftbar gemacht werden kann und dass die Bereitstellung der Dienste von der Zuverlässigkeit, Verfügbarkeit und Bereitstellung dauerhafter Verbindungen durch Dritte (Betreiber des Telekommunikationsnetzes, des öffentlichen Internets, der Ausrüstung des Nutzers usw.) sowie von der Richtigkeit und Vollständigkeit der Nutzerdaten abhängig ist.',
    ro: ' serviciile oferite sunt gratuite pentru utilizator și sunt disponibile numai în limita posibilităților tehnice și operaționale. Anwado nu este responsabilă pentru întreruperile sau întârzierile serviciilor care sunt în afara controlului său. Utilizatorul recunoaște că Anwado nu poate fi trasă la răspundere pentru astfel de evenimente și că furnizarea serviciilor depinde de fiabilitatea, disponibilitatea și furnizarea de conexiuni permanente de către terți (operatorii rețelei de telecomunicații, internetul public, echipamentul utilizatorului etc.), precum și de acuratețea și caracterul complet al datelor utilizatorului.',
  },
  GTCC_Paragraph_4_Description_3: {
    en: 'Anwado ist berechtigt, die Dienste zur planmäßigen Wartung der Anwado-Website durch Anwado oder einen seiner Subunternehmer oder im Falle technischer Notwendigkeit (Notfallwartung) auszusetzen.',
    de: 'Anwado ist berechtigt, die Dienste zur planmäßigen Wartung der Anwado-Website durch Anwado oder einen seiner Subunternehmer oder im Falle technischer Notwendigkeit (Notfallwartung) auszusetzen.',
    ro: 'Anwado are dreptul de a suspenda serviciile pentru întreținerea programată a site-ului Anwado de către Anwado sau unul dintre subcontractanții săi sau în caz de necesitate tehnică (întreținere de urgență).',
  },
  GTCC_Paragraph_5_Title: {
    en: 'Allgemeine Pflichten des Nutzers',
    de: 'Allgemeine Pflichten des Nutzers',
    ro: 'Obligațiile generale ale utilizatorului',
  },
  GTCC_Paragraph_5_Description_1: {
    en: 'Der Nutzer verpflichtet sich, die Plattform und die Dienste von Anwado nicht zu gewerblichen, geschäftlichen oder gewinnorientierten Zwecken und nicht zu anderen Zwecken als der bestimmungsgemäßen Nutzung des Angebots zu nutzen. Die Nutzung der Registrierung zur Auslesung, Speicherung oder Weitergabe personenbezogener Daten anderer Nutzer zu anderen Zwecken als der bestimmungsgemäßen Nutzung der Dienste, ist untersagt.',
    de: 'Der Nutzer verpflichtet sich, die Plattform und die Dienste von Anwado nicht zu gewerblichen, geschäftlichen oder gewinnorientierten Zwecken und nicht zu anderen Zwecken als der bestimmungsgemäßen Nutzung des Angebots zu nutzen. Die Nutzung der Registrierung zur Auslesung, Speicherung oder Weitergabe personenbezogener Daten anderer Nutzer zu anderen Zwecken als der bestimmungsgemäßen Nutzung der Dienste, ist untersagt.',
    ro: 'Utilizatorul se obligă să nu utilizeze platforma și serviciile Anwado în scopuri comerciale, de afaceri sau în scopuri lucrative și nici în alte scopuri decât cele prevăzute în ofertă. Este interzisă utilizarea înregistrării pentru a citi, stoca sau transmite datele personale ale altor utilizatori în alte scopuri decât utilizarea prevăzută a serviciilor.',
  },
  GTCC_Paragraph_5_Description_2: {
    en: 'Bei der Nutzung der Dienste von Anwado ist es untersagt, Daten zu versenden oder auf einem Datenträger von Anwado zu speichern, die nach ihrer Art oder Beschaffenheit, ihrem Umfang oder ihrer Anzahl geeignet sind, die Funktionsfähigkeit der Computersysteme von Anwado oder Dritter zu beeinträchtigen oder Rechte Dritter zu verletzen (z.B. Viren, Spam-E-Mails, etc.).',
    de: 'Bei der Nutzung der Dienste von Anwado ist es untersagt, Daten zu versenden oder auf einem Datenträger von Anwado zu speichern, die nach ihrer Art oder Beschaffenheit, ihrem Umfang oder ihrer Anzahl geeignet sind, die Funktionsfähigkeit der Computersysteme von Anwado oder Dritter zu beeinträchtigen oder Rechte Dritter zu verletzen (z.B. Viren, Spam-E-Mails, etc.).',
    ro: 'În cazul utilizării serviciilor Anwado, este interzisă trimiterea de date sau stocarea de date pe oricare dintre suporturile de date ale Anwado care, prin natura sau caracteristicile lor, prin volumul sau numărul lor, ar putea afecta funcționarea sistemelor informatice ale Anwado sau ale unor terțe părți sau ar putea încălca drepturile unor terțe părți (de exemplu, viruși, e-mailuri spam etc.).',
  },
  GTCC_Paragraph_6_Title: {
    en: 'Bewertungen',
    de: 'Bewertungen',
    ro: 'Recenzii',
  },
  GTCC_Paragraph_6_Description_1: {
    en: 'Der Nutzer kann Bewertungen auf Anwado veröffentlichen und damit anderen zugänglich machen. Die Verantwortung für die Bewertungen liegt ausschließlich beim Nutzer. Der Nutzer verpflichtet sich, bei der Abgabe von Bewertungen nicht gegen geltendes Recht zu verstoßen und insbesondere sicherzustellen, dass durch die Bewertungen keine Rechte Dritter (insbesondere Persönlichkeitsrechte) verletzt werden. Der Autor einer Bewertung wird auf Anwado anonymisiert nur mit seinen Initialen genannt (Bsp.: „A.B.)“.',
    de: 'Der Nutzer kann Bewertungen auf Anwado veröffentlichen und damit anderen zugänglich machen. Die Verantwortung für die Bewertungen liegt ausschließlich beim Nutzer. Der Nutzer verpflichtet sich, bei der Abgabe von Bewertungen nicht gegen geltendes Recht zu verstoßen und insbesondere sicherzustellen, dass durch die Bewertungen keine Rechte Dritter (insbesondere Persönlichkeitsrechte) verletzt werden. Der Autor einer Bewertung wird auf Anwado anonymisiert nur mit seinen Initialen genannt (Bsp.: „A.B.)“.',
    ro: 'Utilizatorul poate publica recenzii pe Anwado și astfel le poate face accesibile și altora. Responsabilitatea pentru recenzii revine exclusiv utilizatorului. Utilizatorul se angajează să nu încalce legislația aplicabilă atunci când trimite recenzii și, în special, să se asigure că recenziile nu încalcă drepturile unor terțe părți (în special drepturile personale). Autorul unei recenzii este numit în mod anonim pe Anwado folosind doar inițialele sale (de exemplu: "A.B.)".',
  },
  GTCC_Paragraph_6_Description_2_1: {
    en: 'Der Nutzer verpflichtet sich, die Bewertungsrichtlinien von Anwado, die unter ',
    de: 'Der Nutzer verpflichtet sich, die Bewertungsrichtlinien von Anwado, die unter ',
    ro: 'Utilizatorul este de acord să respecte liniile directoare de evaluare ale Anwado, care sunt disponibile la adresa',
  },
  GTCC_Paragraph_6_Description_2_2: {
    en: 'Bewertungsrichtlinien',
    de: 'Bewertungsrichtlinien',
    ro: 'Orientări de evaluare',
  },
  GTCC_Paragraph_6_Description_2_3: {
    en: ' abgerufen werden können, zu beachten. Sie sind Bestandteil dieser AGB.',
    de: ' abgerufen werden können, zu beachten. Sie sind Bestandteil dieser AGB.',
    ro: ' poate fi invocată, trebuie respectată. Acestea fac parte integrantă din prezentele CCG.',
  },
  GTCC_Paragraph_7_Title: {
    en: 'Online-Terminvereinbarung',
    de: 'Online-Terminvereinbarung',
    ro: 'Programare online',
  },
  GTCC_Paragraph_7_Description_1: {
    en: 'Der Nutzer kann für sich selbst online einen Termin mit einem Rechtsanwalt vereinbaren, der eine Online-Terminverfügbarkeit eingestellt hat.  Der Rechtsanwalt wird über den Termin informiert und kann diesen gegebenenfalls verschieben oder aus seinem Terminkalender löschen. In diesem Fall wird der Benutzer unverzüglich per E-Mail benachrichtigt. Die Vergütung für die zu erbringende Rechtsdienstleistung muss zwischen dem Anwalt und dem Nutzer direkt vereinbart werden.',
    de: 'Der Nutzer kann für sich selbst online einen Termin mit einem Rechtsanwalt vereinbaren, der eine Online-Terminverfügbarkeit eingestellt hat.  Der Rechtsanwalt wird über den Termin informiert und kann diesen gegebenenfalls verschieben oder aus seinem Terminkalender löschen. In diesem Fall wird der Benutzer unverzüglich per E-Mail benachrichtigt. Die Vergütung für die zu erbringende Rechtsdienstleistung muss zwischen dem Anwalt und dem Nutzer direkt vereinbart werden.',
    ro: 'Utilizatorul își poate face o programare online cu un avocat care a stabilit o disponibilitate de programare online.  Avocatul va fi informat cu privire la programare și, dacă este necesar, o poate reprograma sau o poate șterge din agenda sa. În acest caz, utilizatorul va fi notificat imediat prin e-mail. Remunerația pentru serviciul juridic care urmează să fie prestat trebuie să fie convenită direct între avocat și utilizator.',
  },
  GTCC_Paragraph_7_Description_2: {
    en: 'Der Nutzer verpflichtet sich, die im Rahmen der Online-Terminvereinbarung abgefragten Daten wahrheitsgemäß und vollständig anzugeben.',
    de: 'Der Nutzer verpflichtet sich, die im Rahmen der Online-Terminvereinbarung abgefragten Daten wahrheitsgemäß und vollständig anzugeben.',
    ro: 'Utilizatorul se angajează să furnizeze datele solicitate în contextul aranjamentului online de programare în mod sincer și complet.',
  },
  GTCC_Paragraph_7_Description_3: {
    en: 'Die im Rahmen der Online-Terminvereinbarung gebuchten Termine sind verbindlich und einzuhalten. Der Nutzer verpflichtet sich, den Rechtsanwalt mindestens 24 Stunden im Voraus zu informieren, wenn er einen vereinbarten Termin nicht wahrnehmen kann. Diese Information kann entweder über die Anwado-Plattform oder über jede andere Form der Kontaktaufnahme mit dem Rechtsanwalt erfolgen. Bei gehäuften Terminbuchungen ohne Erscheinen zum vereinbarten Termin behält sich Anwado vor, den entsprechenden Nutzer zukünftig von der Online-Terminbuchung auszuschließen.',
    de: 'Die im Rahmen der Online-Terminvereinbarung gebuchten Termine sind verbindlich und einzuhalten. Der Nutzer verpflichtet sich, den Rechtsanwalt mindestens 24 Stunden im Voraus zu informieren, wenn er einen vereinbarten Termin nicht wahrnehmen kann. Diese Information kann entweder über die Anwado-Plattform oder über jede andere Form der Kontaktaufnahme mit dem Rechtsanwalt erfolgen. Bei gehäuften Terminbuchungen ohne Erscheinen zum vereinbarten Termin behält sich Anwado vor, den entsprechenden Nutzer zukünftig von der Online-Terminbuchung auszuschließen.',
    ro: 'Întâlnirile rezervate în cadrul programării online sunt obligatorii și trebuie respectate. Utilizatorul se angajează să informeze avocatul cu cel puțin 24 de ore înainte dacă nu poate respecta o întâlnire convenită. Această informare poate fi furnizată fie prin intermediul platformei Anwado, fie prin orice altă formă de contact cu avocatul. În cazul unor programări frecvente de întâlniri fără a se prezenta la data convenită, Anwado își rezervă dreptul de a exclude pe viitor utilizatorul în cauză de la programările online de întâlniri.',
  },
  GTCC_Paragraph_7_Description_4: {
    en: 'Jede Terminvereinbarung in diesem Rahmen erfolgt unter der alleinigen Verantwortung des jeweiligen Nutzers mit dem Rechtsanwalt. Anwado übernimmt keine Garantie für die Durchführung der online vereinbarten Termine.',
    de: 'Jede Terminvereinbarung in diesem Rahmen erfolgt unter der alleinigen Verantwortung des jeweiligen Nutzers mit dem Rechtsanwalt. Anwado übernimmt keine Garantie für die Durchführung der online vereinbarten Termine.',
    ro: 'Orice programare făcută în acest cadru este responsabilitatea exclusivă a utilizatorului respectiv cu avocatul. Anwado nu garantează că întâlnirile făcute online vor fi efectuate.',
  },
  GTCC_Paragraph_8_Title: {
    en: 'Nutzungsrechte',
    de: 'Nutzungsrechte',
    ro: 'Drepturi de utilizare',
  },
  GTCC_Paragraph_8_Description_1: {
    en: 'Mit dem Einstellen von Bewertungen und etwaigen anderen Inhalten erklärt sich der Nutzer damit einverstanden, dass Anwado diese Inhalte speichert, veröffentlicht und/oder öffentlich zugänglich macht. Anwado übernimmt für diese Inhalte keine Haftung. Für die Inhalte ist ausschließlich der Nutzer verantwortlich. Anwado stellt insoweit lediglich den Speicherplatz zur Verfügung.',
    de: 'Mit dem Einstellen von Bewertungen und etwaigen anderen Inhalten erklärt sich der Nutzer damit einverstanden, dass Anwado diese Inhalte speichert, veröffentlicht und/oder öffentlich zugänglich macht. Anwado übernimmt für diese Inhalte keine Haftung. Für die Inhalte ist ausschließlich der Nutzer verantwortlich. Anwado stellt insoweit lediglich den Speicherplatz zur Verfügung.',
    ro: 'Prin postarea recenziilor și a oricărui alt conținut, utilizatorul este de acord ca Anwado să stocheze, să publice și/sau să facă public acest conținut. Anwado nu își asumă nicio răspundere pentru acest conținut. Utilizatorul este singurul responsabil pentru acest conținut. În acest sens, Anwado oferă doar spațiul de stocare.',
  },
  GTCC_Paragraph_8_Description_2: {
    en: 'Die Einwilligung nach Ziff. 8.1 gilt zeitlich unbegrenzt, solange der Nutzer gegenüber Anwado keine Umstände nachweist, die einen weiteren Abruf seiner Inhalte für die Zukunft unzumutbar erscheinen lassen (z.B. wegen schwerwiegender Nachteile). Der Nutzer kann selbst eingestellte Inhalte in seinem Profil (z.B. Profildaten oder Bewertungen) löschen oder ändern.',
    de: 'Die Einwilligung nach Ziff. 8.1 gilt zeitlich unbegrenzt, solange der Nutzer gegenüber Anwado keine Umstände nachweist, die einen weiteren Abruf seiner Inhalte für die Zukunft unzumutbar erscheinen lassen (z.B. wegen schwerwiegender Nachteile). Der Nutzer kann selbst eingestellte Inhalte in seinem Profil (z.B. Profildaten oder Bewertungen) löschen oder ändern.',
    ro: 'Consimțământul în conformitate cu punctul 8.1 este valabil pentru o perioadă nelimitată de timp, atâta timp cât utilizatorul nu dovedește Anwado existența unor circumstanțe care fac ca recuperarea ulterioară a conținutului său să nu fie rezonabilă în viitor (de exemplu, din cauza unor dezavantaje grave). Utilizatorul poate șterge sau modifica conținutul pe care l-a postat în profilul său (de exemplu, date de profil sau evaluări).',
  },
  GTCC_Paragraph_8_Description_3: {
    en: 'Der Nutzer ist dafür verantwortlich, dass er über die erforderlichen Rechte zur Einstellung von Inhalten verfügt. In der Regel verfügt der Nutzer über die erforderlichen Rechte, wenn er die betreffenden Inhalte selbst erstellt hat.',
    de: 'Der Nutzer ist dafür verantwortlich, dass er über die erforderlichen Rechte zur Einstellung von Inhalten verfügt. In der Regel verfügt der Nutzer über die erforderlichen Rechte, wenn er die betreffenden Inhalte selbst erstellt hat.',
    ro: 'Utilizatorul este responsabil pentru a se asigura că are drepturile necesare pentru a posta conținut. De regulă, utilizatorul are drepturile necesare dacă a creat el însuși conținutul în cauză.',
  },
  GTCC_Paragraph_9_Title: {
    en: 'Rechte von Anwado',
    de: 'Rechte von Anwado',
    ro: 'Drepturi Anwado',
  },
  GTCC_Paragraph_9_Description_1_1: {
    en: 'Die Rechte (insbesondere Urheber-, Marken- und Kennzeichenrechte) für veröffentlichte, von Anwado bzw. deren Mitarbeitern selbst erstellte oder auf ',
    de: 'Die Rechte (insbesondere Urheber-, Marken- und Kennzeichenrechte) für veröffentlichte, von Anwado bzw. deren Mitarbeitern selbst erstellte oder auf ',
    ro: 'Drepturile (în special drepturile de autor, drepturile de marcă comercială și drepturile de etichetare) pentru materialele publicate create de Anwado sau de angajații săi, în mod direct sau pe site-ul',
  },
  GTCC_Paragraph_9_Description_1_2: {
    en: 'www.anwado.de',
    de: 'www.anwado.de',
    ro: 'www.anwado.de',
  },
  GTCC_Paragraph_9_Description_1_3: {
    en: ' oder in mobilen Angeboten (Apps) von Anwado selbst eingestellten Objekte bzw. für Inhalte, die im Eigentum von Anwado stehen, bleiben allein bei Anwado. Eine Vervielfältigung und/oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung von Anwado nicht gestattet. Insbesondere dürfen das Anwado-Anwaltsverzeichnis und die auf Anwado veröffentlichten Rechtsinformationen nicht kopiert werden.',
    de: ' oder in mobilen Angeboten (Apps) von Anwado selbst eingestellten Objekte bzw. für Inhalte, die im Eigentum von Anwado stehen, bleiben allein bei Anwado. Eine Vervielfältigung und/oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung von Anwado nicht gestattet. Insbesondere dürfen das Anwado-Anwaltsverzeichnis und die auf Anwado veröffentlichten Rechtsinformationen nicht kopiert werden.',
    ro: ' sau obiectele plasate în ofertele mobile (aplicații) de către Anwado însăși sau pentru conținutul care este proprietatea Anwado rămân proprietatea exclusivă a Anwado. Orice reproducere și/sau utilizare a unor astfel de grafice, documente sonore, secvențe video și texte în alte publicații electronice sau tipărite nu este permisă fără acordul expres al Anwado. În special, directorul juridic Anwado și informațiile juridice publicate pe Anwado nu pot fi copiate.',
  },
  GTCC_Paragraph_9_Description_2: {
    en: 'Der Nutzer verpflichtet sich, nicht zu versuchen, auf die Quellcodes der Anwado-Plattform zuzugreifen oder diese zu kopieren; und die Anwado-Plattform nicht für andere Zwecke als die Nutzung der Anwado-Plattform zu verwenden; keine Kopien der Anwado-Plattform anzufertigen; die Anwado-Plattform weder zu vervielfältigen, noch die Anwado-Plattform zu korrigieren, zu extrahieren, zu modifizieren, in eine oder mehrere Sprachen zu übersetzen oder die Anwado-Plattform in andere Software zu integrieren oder auf Grundlage der Anwado-Plattform abgeleitete Werke zu erstellen, die auf der Anwado-Plattform basieren sowie die Anwado-Plattform nicht weiterzuverkaufen, zu vermieten oder kommerziell zu nutzen und die Anwado-Plattform nicht an Dritte zu übertragen.',
    de: 'Der Nutzer verpflichtet sich, nicht zu versuchen, auf die Quellcodes der Anwado-Plattform zuzugreifen oder diese zu kopieren; und die Anwado-Plattform nicht für andere Zwecke als die Nutzung der Anwado-Plattform zu verwenden; keine Kopien der Anwado-Plattform anzufertigen; die Anwado-Plattform weder zu vervielfältigen, noch die Anwado-Plattform zu korrigieren, zu extrahieren, zu modifizieren, in eine oder mehrere Sprachen zu übersetzen oder die Anwado-Plattform in andere Software zu integrieren oder auf Grundlage der Anwado-Plattform abgeleitete Werke zu erstellen, die auf der Anwado-Plattform basieren sowie die Anwado-Plattform nicht weiterzuverkaufen, zu vermieten oder kommerziell zu nutzen und die Anwado-Plattform nicht an Dritte zu übertragen.',
    ro: 'Utilizatorul este de acord să nu încerce să acceseze sau să copieze codurile sursă ale platformei Anwado; și să nu utilizeze platforma Anwado în alt scop decât utilizarea platformei Anwado; să nu facă copii ale platformei Anwado; să nu reproducă platforma Anwado, să nu corecteze, să nu extragă, să nu modifice, să nu traducă platforma Anwado în una sau mai multe limbi, să nu integreze platforma Anwado în alt software sau să nu creeze lucrări derivate bazate pe platforma Anwado și să nu revândă, să nu închirieze sau să nu exploateze comercial platforma Anwado și să nu transfere platforma Anwado către o terță parte.',
  },
  GTCC_Paragraph_10_Title: {
    en: 'Folgen von Pflichtverstößen, Freistellungsanspruch',
    de: 'Folgen von Pflichtverstößen, Freistellungsanspruch',
    ro: 'Consecințele încălcărilor obligațiilor, dreptul la scutire',
  },
  GTCC_Paragraph_10_Description_1: {
    en: 'Bei Verstößen des Nutzers gegen Pflichten aus diesen AGB ist Anwado berechtigt, den Zugang des Nutzers zu Anwado zu sperren, seine Inhalte auf Anwado zu löschen, sein Profil zu deaktivieren oder bei offensichtlich missbräuchlicher Anmeldung oder Nutzung sofort zu löschen sowie sonstige geeignete Maßnahmen zum Schutz vor solchen Verstößen zu ergreifen. Gleiches gilt, wenn ein hinreichender Verdacht auf solche Verstöße besteht.',
    de: 'Bei Verstößen des Nutzers gegen Pflichten aus diesen AGB ist Anwado berechtigt, den Zugang des Nutzers zu Anwado zu sperren, seine Inhalte auf Anwado zu löschen, sein Profil zu deaktivieren oder bei offensichtlich missbräuchlicher Anmeldung oder Nutzung sofort zu löschen sowie sonstige geeignete Maßnahmen zum Schutz vor solchen Verstößen zu ergreifen. Gleiches gilt, wenn ein hinreichender Verdacht auf solche Verstöße besteht.',
    ro: 'În cazul încălcării obligațiilor utilizatorului în temeiul prezentelor CGC, Anwado are dreptul de a bloca accesul utilizatorului la Anwado, de a șterge conținutul utilizatorului pe Anwado, de a dezactiva profilul utilizatorului sau, în cazul unei utilizări abuzive evidente, de a șterge imediat profilul utilizatorului și de a lua alte măsuri adecvate pentru a se proteja împotriva unor astfel de încălcări. Același lucru este valabil și în cazul în care există suficiente suspiciuni cu privire la astfel de încălcări.',
  },
  GTCC_Paragraph_10_Description_2: {
    en: 'Im Falle eines schuldhaften Verstoßes haftet der Nutzer gegenüber Anwado auf Ersatz aller hieraus entstehenden unmittelbaren und mittelbaren Schäden, einschließlich Vermögensschäden. Im Falle eines Verstoßes gegen die Bestimmungen in Ziffer 3.2 und Ziffer 6 stellt der Nutzer Anwado von sämtlichen Ansprüchen Dritter sowie den daraus resultierenden Kosten frei, die wegen eines solchen Verstoßes gegen Anwado, deren gesetzliche Vertreter und/oder Erfüllungsgehilfen geltend gemacht werden. Weitergehende Ansprüche bleiben vorbehalten.',
    de: 'Im Falle eines schuldhaften Verstoßes haftet der Nutzer gegenüber Anwado auf Ersatz aller hieraus entstehenden unmittelbaren und mittelbaren Schäden, einschließlich Vermögensschäden. Im Falle eines Verstoßes gegen die Bestimmungen in Ziffer 3.2 und Ziffer 6 stellt der Nutzer Anwado von sämtlichen Ansprüchen Dritter sowie den daraus resultierenden Kosten frei, die wegen eines solchen Verstoßes gegen Anwado, deren gesetzliche Vertreter und/oder Erfüllungsgehilfen geltend gemacht werden. Weitergehende Ansprüche bleiben vorbehalten.',
    ro: 'În cazul unei încălcări culpabile, utilizatorul va fi răspunzător față de Anwado pentru compensarea tuturor daunelor directe și indirecte, inclusiv a pierderilor financiare, care decurg din această încălcare. În cazul unei încălcări a dispozițiilor din clauza 3.2 și clauza 6, utilizatorul va despăgubi Anwado pentru toate pretențiile formulate de terți și costurile rezultate care sunt revendicate împotriva Anwado, a reprezentanților săi legali și/sau a agenților săi indirecți din cauza unei astfel de încălcări. Anwado își rezervă dreptul de a înainta pretenții suplimentare.',
  },
  GTCC_Paragraph_11_Title: {
    en: 'Haftung',
    de: 'Haftung',
    ro: 'Răspundere',
  },
  GTCC_Paragraph_11_Description_1: {
    en: 'Die Haftung von Anwado, gleich aus welchem Rechtsgrund, für Schäden, die durch den Nutzer, seine gesetzlichen Vertreter oder seine jeweiligen Erfüllungsgehilfen verursacht werden, ist - vorbehaltlich Ziffer 11.2 - wie folgt beschränkt: Bei leicht fahrlässiger Verletzung wesentlicher Vertragspflichten haftet Anwado der Höhe nach begrenzt auf die bei Vertragsschluss vorhersehbaren, vertragstypischen Schäden. Wesentliche Vertragspflichten sind solche, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf. Anwado haftet nicht bei leicht fahrlässiger Verletzung nicht wesentlicher Pflichten aus dem Schuldverhältnis.',
    de: 'Die Haftung von Anwado, gleich aus welchem Rechtsgrund, für Schäden, die durch den Nutzer, seine gesetzlichen Vertreter oder seine jeweiligen Erfüllungsgehilfen verursacht werden, ist - vorbehaltlich Ziffer 11.2 - wie folgt beschränkt: Bei leicht fahrlässiger Verletzung wesentlicher Vertragspflichten haftet Anwado der Höhe nach begrenzt auf die bei Vertragsschluss vorhersehbaren, vertragstypischen Schäden. Wesentliche Vertragspflichten sind solche, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf. Anwado haftet nicht bei leicht fahrlässiger Verletzung nicht wesentlicher Pflichten aus dem Schuldverhältnis.',
    ro: 'Răspunderea Anwado, indiferent de temeiurile legale, pentru daunele cauzate de utilizator, de reprezentanții săi legali sau de agenții săi indirecți respectivi este - sub rezerva clauzei 11.2 - limitată după cum urmează: În cazul încălcării din neglijență ușoară a obligațiilor contractuale materiale, răspunderea Anwado este limitată la valoarea prejudiciului previzibil la momentul încheierii contractului și tipic pentru acest tip de contract. Obligațiile contractuale materiale sunt obligații a căror îndeplinire este esențială pentru executarea corespunzătoare a contractului și pe a căror îndeplinire cealaltă parte se poate baza în mod regulat. Anwado nu este răspunzătoare în cazul unei încălcări ușor neglijente a obligațiilor neesențiale care decurg din obligația contractuală.',
  },
  GTCC_Paragraph_11_Description_2: {
    en: 'Die vorstehenden Haftungsbeschränkungen gelten nicht bei Vorsatz oder grober Fahrlässigkeit sowie in Fällen zwingender gesetzlicher Haftung, insbesondere bei der Übernahme einer Garantie oder der Verletzung des Lebens, des Körpers oder der Gesundheit.',
    de: 'Die vorstehenden Haftungsbeschränkungen gelten nicht bei Vorsatz oder grober Fahrlässigkeit sowie in Fällen zwingender gesetzlicher Haftung, insbesondere bei der Übernahme einer Garantie oder der Verletzung des Lebens, des Körpers oder der Gesundheit.',
    ro: 'Limitările de răspundere de mai sus nu se aplică în caz de intenție sau de neglijență gravă sau în cazurile de răspundere legală obligatorie, în special în cazul asumării unei garanții sau al vătămării vieții, integrității corporale sau sănătății.',
  },
  GTCC_Paragraph_11_Description_3: {
    en: 'Soweit eine Haftung von Anwado beschränkt oder ausgeschlossen ist, so gilt dies auch für die Mitarbeiter, die gesetzlichen Vertreter, die Dienstleister und die sonstigen Erfüllungsgehilfen.',
    de: 'Soweit eine Haftung von Anwado beschränkt oder ausgeschlossen ist, so gilt dies auch für die Mitarbeiter, die gesetzlichen Vertreter, die Dienstleister und die sonstigen Erfüllungsgehilfen.',
    ro: 'În măsura în care răspunderea Anwado este limitată sau exclusă, acest lucru se aplică și angajaților, reprezentanților legali, furnizorilor de servicii și altor agenți indirecti ai acesteia.',
  },
  GTCC_Paragraph_12_Title: {
    en: 'Kündigung',
    de: 'Kündigung',
    ro: 'Anulare',
  },
  GTCC_Paragraph_12_Description_1: {
    en: 'Der Nutzer kann jederzeit ohne Einhaltung einer Frist durch Löschen des Profils im „persönlichen Bereich“ das Nutzungsverhältnis mit Anwado beenden.',
    de: 'Der Nutzer kann jederzeit ohne Einhaltung einer Frist durch Löschen des Profils im „persönlichen Bereich“ das Nutzungsverhältnis mit Anwado beenden.',
    ro: 'Utilizatorul poate înceta relația de utilizator cu Anwado în orice moment și fără notificare prealabilă prin ștergerea profilului din "zona personală".',
  },
  GTCC_Paragraph_12_Description_2: {
    en: 'Anwado kann das Nutzungsverhältnis mit einem Nutzer mit einer Frist von vier Wochen ohne Angaben von Gründen kündigen. Das Recht zur außerordentlichen Kündigung aus wichtigem Grund bleibt hiervon unberührt. Ein außerordentlicher Kündigungsgrund kann insbesondere im Falle diffamierender, bedrohlicher, oder beleidigender Äußerungen vorliegen.',
    de: 'Anwado kann das Nutzungsverhältnis mit einem Nutzer mit einer Frist von vier Wochen ohne Angaben von Gründen kündigen. Das Recht zur außerordentlichen Kündigung aus wichtigem Grund bleibt hiervon unberührt. Ein außerordentlicher Kündigungsgrund kann insbesondere im Falle diffamierender, bedrohlicher, oder beleidigender Äußerungen vorliegen.',
    ro: 'Anwado poate înceta relația cu un utilizator cu un preaviz de patru săptămâni, fără a oferi motive. Dreptul de reziliere extraordinară pentru motive întemeiate rămâne neafectat. Un motiv extraordinar de reziliere poate exista în special în cazul unor declarații defăimătoare, amenințătoare sau insultătoare.',
  },
  GTCC_Paragraph_12_Description_3: {
    en: 'Im Falle einer Kündigung hat Anwado das Recht zur Sperrung und Löschung des jeweiligen Profils sowie aller Inhalte des Nutzers.',
    de: 'Im Falle einer Kündigung hat Anwado das Recht zur Sperrung und Löschung des jeweiligen Profils sowie aller Inhalte des Nutzers.',
    ro: 'În caz de reziliere, Anwado are dreptul de a bloca și șterge profilul respectiv și tot conținutul utilizatorului.',
  },
  GTCC_Paragraph_13_Title: {
    en: 'Schlussbestimmungen',
    de: 'Schlussbestimmungen',
    ro: 'Dispoziții finale',
  },
  GTCC_Paragraph_13_Description_1: {
    en: 'Es gelten jeweils die zum Zeitpunkt der Nutzung auf der Website hinterlegten und einbezogenen AGB. Anwado steht es frei, diese AGB jederzeit zu ändern, insbesondere um rechtlichen und/oder technischen Entwicklungen oder Einschränkungen Rechnung zu tragen. Der Nutzer wird über die Änderung der AGB rechtzeitig mit einer angemessenen Frist vor deren Inkrafttreten per Email oder beim Einloggen informiert. Innerhalb dieser Frist kann der Nutzer der Änderung widersprechen. Nach Ablauf der Frist wird die Änderung wirksam. Widerspricht der Nutzer, kann Anwado das Nutzungsverhältnis außerordentlich kündigen.',
    de: 'Es gelten jeweils die zum Zeitpunkt der Nutzung auf der Website hinterlegten und einbezogenen AGB. Anwado steht es frei, diese AGB jederzeit zu ändern, insbesondere um rechtlichen und/oder technischen Entwicklungen oder Einschränkungen Rechnung zu tragen. Der Nutzer wird über die Änderung der AGB rechtzeitig mit einer angemessenen Frist vor deren Inkrafttreten per Email oder beim Einloggen informiert. Innerhalb dieser Frist kann der Nutzer der Änderung widersprechen. Nach Ablauf der Frist wird die Änderung wirksam. Widerspricht der Nutzer, kann Anwado das Nutzungsverhältnis außerordentlich kündigen.',
    ro: 'În fiecare caz, se aplică CGC afișate pe site-ul web și incluse la momentul utilizării. Anwado are libertatea de a modifica în orice moment aceste CGC, în special pentru a ține cont de evoluțiile sau restricțiile juridice și/sau tehnice. Utilizatorul va fi informat în timp util și cu o perioadă rezonabilă de preaviz înainte de intrarea în vigoare a modificărilor aduse CGC, fie prin e-mail, fie la conectare. În această perioadă, utilizatorul poate obiecta la modificare. După expirarea termenului, modificarea intră în vigoare. În cazul în care utilizatorul se opune, Anwado poate rezilia relația cu utilizatorul fără preaviz.',
  },
  GTCC_Paragraph_13_Description_2: {
    en: 'Sollte eine der Bestimmungen dieser Vereinbarung unwirksam sein oder werden, so berührt dies die Wirksamkeit dieser Vereinbarung im Übrigen nicht.  Änderungen, Ergänzungen und Aufhebung dieser Vereinbarung bedürfen der Schriftform, was auch für die Abbedingung des Schriftformerfordernisses gilt. Soweit einzelne Bestimmungen dieser AGB ganz oder teilweise unwirksam sind, wird die Wirksamkeit dieser Vereinbarung sowie der übrigen Bestimmungen hiervon nicht berührt. Anstelle der unwirksamen Bestimmung ist eine Bestimmung zu vereinbaren, die dem Sinn und Zweck der weggefallenen Bestimmung am nächsten kommt.',
    de: 'Sollte eine der Bestimmungen dieser Vereinbarung unwirksam sein oder werden, so berührt dies die Wirksamkeit dieser Vereinbarung im Übrigen nicht.  Änderungen, Ergänzungen und Aufhebung dieser Vereinbarung bedürfen der Schriftform, was auch für die Abbedingung des Schriftformerfordernisses gilt. Soweit einzelne Bestimmungen dieser AGB ganz oder teilweise unwirksam sind, wird die Wirksamkeit dieser Vereinbarung sowie der übrigen Bestimmungen hiervon nicht berührt. Anstelle der unwirksamen Bestimmung ist eine Bestimmung zu vereinbaren, die dem Sinn und Zweck der weggefallenen Bestimmung am nächsten kommt.',
    ro: 'În cazul în care una dintre dispozițiile prezentului acord este sau devine nulă, acest lucru nu va afecta validitatea celorlalte dispoziții ale acordului.  Modificările, completările și anularea prezentului acord trebuie să fie făcute în scris, ceea ce se aplică și în cazul renunțării la cerința de formă scrisă. În măsura în care anumite prevederi individuale ale acestor CGC sunt invalide în totalitate sau parțial, acest lucru nu va afecta validitatea prezentului acord sau a celorlalte prevederi. În locul dispoziției nevalabile, se va conveni o dispoziție care se apropie cât mai mult posibil de sensul și scopul dispoziției nevalabile.',
  },
  GTCC_Paragraph_13_Description_3: {
    en: 'Ausschließlicher Gerichtsstand für alle Streitigkeiten aus dieser Vereinbarung ist München, sofern der Nutzer Kaufmann ist oder keinen allgemeinen Gerichtsstand im Inland hat; dies gilt auch für den Fall, dass der Nutzer nach Vertragsschluss seinen Wohnsitz oder gewöhnlichen Aufenthalt aus Deutschland heraus verlegt oder sein Wohnsitz oder gewöhnlicher Aufenthalt im Zeitpunkt der Klageerhebung nicht bekannt ist.',
    de: 'Ausschließlicher Gerichtsstand für alle Streitigkeiten aus dieser Vereinbarung ist München, sofern der Nutzer Kaufmann ist oder keinen allgemeinen Gerichtsstand im Inland hat; dies gilt auch für den Fall, dass der Nutzer nach Vertragsschluss seinen Wohnsitz oder gewöhnlichen Aufenthalt aus Deutschland heraus verlegt oder sein Wohnsitz oder gewöhnlicher Aufenthalt im Zeitpunkt der Klageerhebung nicht bekannt ist.',
    ro: 'Jurisdicția exclusivă pentru toate litigiile care decurg din prezentul contract este München, cu condiția ca utilizatorul să fie un comerciant sau să nu aibă un loc de jurisdicție generală în Germania; acest lucru este valabil și în cazul în care utilizatorul își mută reședința sau domiciliul obișnuit în afara Germaniei după încheierea contractului sau dacă reședința sau domiciliul său obișnuit nu este cunoscut la momentul introducerii acțiunii.',
  },
  GTCC_Paragraph_13_Description_4: {
    en: 'Diese Vereinbarung unterliegt dem Recht der Bundesrepublik Deutschland mit Ausnahme des Internationalen Privatrechts und des UN-Kaufrechts.',
    de: 'Diese Vereinbarung unterliegt dem Recht der Bundesrepublik Deutschland mit Ausnahme des Internationalen Privatrechts und des UN-Kaufrechts.',
    ro: 'Prezentul acord este guvernat de legislația Republicii Federale Germania, cu excepția dreptului internațional privat și a Convenției ONU privind contractele de vânzare internațională de bunuri.',
  },
  // General Terms and Conditions Lawyer
  GTCL_Title: {
    en: 'GTC for lawyers with a silver, gold or platinum membership',
    de: 'AGB für Rechtsanwälte mit einer Silber-, Gold- oder Platin-Mitgliedschaft ',
    ro: 'GTC pentru avocații care au un statut de membru argint, aur sau platină',
  },
  GTCL_Post_Title: {
    en: `‍Allgemeine Geschäftsbedingungen für Rechtsanwälte* mit einer Silber-, Gold- oder Platin-Mitgliedschaft (nachfolgend „Kunden“) der Anwado GmbH, (nachfolgend „Anwado“)
(* Im Folgenden wird aus Gründen der besseren Lesbarkeit ausschließlich die männliche Form verwendet. Sie bezieht sich auf Personen jeglichen Geschlechts.)`,
    de: `‍Allgemeine Geschäftsbedingungen für Rechtsanwälte* mit einer Silber-, Gold- oder Platin-Mitgliedschaft (nachfolgend „Kunden“) der Anwado GmbH, (nachfolgend „Anwado“)
(* Im Folgenden wird aus Gründen der besseren Lesbarkeit ausschließlich die männliche Form verwendet. Sie bezieht sich auf Personen jeglichen Geschlechts.)`,
  },
  GTCL_Description_Title_1: {
    en: 'Vorbemerkung',
    de: 'Vorbemerkung',
    ro: 'Observație preliminară',
  },
  GTCL_Description_1_1: {
    en: `Gegenstand der vorliegenden Allgemeinen Geschäftsbedingungen (nachfolgend „AGB“ genannt) ist die Festlegung der Nutzungsbedingungen für die Leistungen, Rechte und Pflichten von Anwado und den Rechtsanwälten im Rahmen der von den Rechtsanwälten abgeschlossenen Mitgliedschaft. Rechtsanwälte, die bei Anwado eine Silber-, Gold- oder Platin-Mitgliedschaft abgeschlossen haben, werden nachfolgend „Kunden“ genannt. 
Kunden können abhängig von der Art der der gewählten Silber-, Gold- oder Platin-Mitgliedschaft verschiedene Dienstleistungen von Anwado in Anspruch nehmen. Die Leistungsbeschreibung für die jeweilige Mitgliedschaft unter `,
    de: `Gegenstand der vorliegenden Allgemeinen Geschäftsbedingungen (nachfolgend „AGB“ genannt) ist die Festlegung der Nutzungsbedingungen für die Leistungen, Rechte und Pflichten von Anwado und den Rechtsanwälten im Rahmen der von den Rechtsanwälten abgeschlossenen Mitgliedschaft. Rechtsanwälte, die bei Anwado eine Silber-, Gold- oder Platin-Mitgliedschaft abgeschlossen haben, werden nachfolgend „Kunden“ genannt. 
Kunden können abhängig von der Art der der gewählten Silber-, Gold- oder Platin-Mitgliedschaft verschiedene Dienstleistungen von Anwado in Anspruch nehmen. Die Leistungsbeschreibung für die jeweilige Mitgliedschaft unter `,
  },
  GTCL_Description_1_2: {
    en: `Preise`,
    de: `Preise`,
  },
  GTCL_Description_1_3: {
    en: ` ist Bestandteil dieser AGB.
Für die Bereitstellung kostenloser Dienste für registrierte Nutzer gelten ausschließlich die Allgemeinen Geschäftsbedingungen für registrierte Nutzer, die unter `,
    de: ` ist Bestandteil dieser AGB.
Für die Bereitstellung kostenloser Dienste für registrierte Nutzer gelten ausschließlich die Allgemeinen Geschäftsbedingungen für registrierte Nutzer, die unter `,
  },
  GTCL_Description_1_4: {
    en: `AGB`,
    de: `AGB`,
  },
  GTCL_Description_1_5: {
    en: ` Nutzer abgerufen werden können.`,
    de: ` Nutzer abgerufen werden können.`,
  },
  GTCL_Paragraph_1_Title: {
    en: 'Geltungsbereich',
    de: 'Geltungsbereich',
    ro: 'Domeniul de aplicare',
  },
  GTCL_Paragraph_1_Description: {
    en: 'Die vorliegenden AGB regeln die Beziehung zwischen Anwado und dem Kunden für alle gegenwärtig und zukünftig angebotenen Dienstleistungen. Entgegenstehende AGB des Kunden sind unwirksam, auch wenn Anwado ihrer Geltung nicht ausdrücklich widerspricht und den Vertrag durchführt. Individualvereinbarungen zwischen Anwado und dem Kunden gehen diesen AGB im Bereich der jeweils individuell vereinbarten Vertragsbedingung vor (vgl. § 305 BGB) und werden sodann durch diese AGB ergänzt.',
    de: 'Die vorliegenden AGB regeln die Beziehung zwischen Anwado und dem Kunden für alle gegenwärtig und zukünftig angebotenen Dienstleistungen. Entgegenstehende AGB des Kunden sind unwirksam, auch wenn Anwado ihrer Geltung nicht ausdrücklich widerspricht und den Vertrag durchführt. Individualvereinbarungen zwischen Anwado und dem Kunden gehen diesen AGB im Bereich der jeweils individuell vereinbarten Vertragsbedingung vor (vgl. § 305 BGB) und werden sodann durch diese AGB ergänzt.',
    ro: 'Aceste CGC reglementează relația dintre Anwado și client pentru toate serviciile oferite în prezent și în viitor. Orice termeni și condiții contradictorii ale clientului sunt invalide, chiar dacă Anwado nu se opune în mod expres aplicării acestora și execută contractul. Acordurile individuale dintre Anwado și client au prioritate față de prezentele CGC în domeniul respectivei condiții contractuale convenite individual (cf. § 305 BGB) și sunt apoi completate de prezentele CGC.',
  },
  GTCL_Paragraph_2_Title: {
    en: 'Nutzungsberechtigung, Verantwortlichkeit',
    de: 'Nutzungsberechtigung, Verantwortlichkeit',
    ro: 'Dreptul de utilizare, responsabilitate',
  },
  GTCL_Paragraph_2_Description_1: {
    en: 'Voraussetzung für die Veröffentlichung eines Profils durch den Kunden ist eine Zulassung als Rechtsanwalt. Zugelassene Rechtsanwälte können eine Silber-, Gold- oder Platin-Mitgliedschaft abschließen. Andere Personen oder Dienstleister sind von der Registrierung für eine Silber-, Gold- oder Platin- Mitgliedschaft ausgeschlossen. Mit seiner Registrierung bei Anwado bestätigt der Kunde, dem nutzungsberechtigten Personenkreis nach diesen AGB anzugehören. Anwado behält sich vor, Nachweise über die Zulassung als Rechtsanwalt anzufordern.',
    de: 'Voraussetzung für die Veröffentlichung eines Profils durch den Kunden ist eine Zulassung als Rechtsanwalt. Zugelassene Rechtsanwälte können eine Silber-, Gold- oder Platin-Mitgliedschaft abschließen. Andere Personen oder Dienstleister sind von der Registrierung für eine Silber-, Gold- oder Platin- Mitgliedschaft ausgeschlossen. Mit seiner Registrierung bei Anwado bestätigt der Kunde, dem nutzungsberechtigten Personenkreis nach diesen AGB anzugehören. Anwado behält sich vor, Nachweise über die Zulassung als Rechtsanwalt anzufordern.',
    ro: 'O condiție prealabilă pentru publicarea unui profil de către client este admiterea ca avocat. Avocații admiși pot deveni membri de argint, de aur sau de platină. Alte persoane sau furnizori de servicii sunt excluși de la înscrierea pentru un membru argint, aur sau platină. Prin înregistrarea la Anwado, clientul confirmă că face parte din grupul de persoane care au dreptul de a utiliza serviciul în conformitate cu prezentele CGV. Anwado își rezervă dreptul de a solicita dovada admiterii în calitate de avocat.',
  },
  GTCL_Paragraph_2_Description_2: {
    en: 'Anwado nimmt grundsätzlich keine rechtliche Prüfung des Profils des Kunden vor. Allein verantwortlich für den Inhalt des Profils ist der Kunde. Der Kunde ist verpflichtet, geltende Gesetze und berufsständische Pflichten hinsichtlich der Berufsausübung, Niederlassung und Berufsbezeichnung selbstständig zu beachten und zu überwachen. Für Verstöße ist allein der Kunde verantwortlich, eine diesbezügliche Haftung von Anwado ist ausgeschlossen.',
    de: 'Anwado nimmt grundsätzlich keine rechtliche Prüfung des Profils des Kunden vor. Allein verantwortlich für den Inhalt des Profils ist der Kunde. Der Kunde ist verpflichtet, geltende Gesetze und berufsständische Pflichten hinsichtlich der Berufsausübung, Niederlassung und Berufsbezeichnung selbstständig zu beachten und zu überwachen. Für Verstöße ist allein der Kunde verantwortlich, eine diesbezügliche Haftung von Anwado ist ausgeschlossen.',
    ro: 'Ca o chestiune de principiu, Anwado nu efectuează o analiză juridică a profilului clientului. Clientul este singurul responsabil pentru conținutul profilului. Clientul este obligat să respecte și să monitorizeze în mod independent legile și obligațiile profesionale aplicabile în ceea ce privește practica profesională, stabilirea și titlul profesional. Clientul este singurul responsabil pentru orice încălcări, iar Anwado nu este răspunzător în acest sens.',
  },
  GTCL_Paragraph_3_Title: {
    en: 'Vertragsschluss',
    de: 'Vertragsschluss',
    ro: 'Încheierea contractului',
  },
  GTCL_Paragraph_3_Description_1: {
    en: 'Der Vertrag über eine Mitgliedschaft kommt zustande, indem der Kunde die von ihm gewählte Leistung über die Webseite ',
    de: 'Der Vertrag über eine Mitgliedschaft kommt zustande, indem der Kunde die von ihm gewählte Leistung über die Webseite ',
    ro: 'Contractul de afiliere se încheie în momentul în care clientul selectează serviciul prin intermediul site-ului web.',
  },
  GTCL_Paragraph_3_Description_2: {
    en: 'www.anwado.de',
    de: 'www.anwado.de',
    ro: 'www.anwado.de',
  },
  GTCL_Paragraph_3_Description_3: {
    en: ' bestellt und Anwado dem Kunden eine Bestätigung per E-Mail zusendet. Das Profil ist in der Regel anschließend sofort online verfügbar und kann vom Kunden bearbeitet werden.',
    de: ' bestellt und Anwado dem Kunden eine Bestätigung per E-Mail zusendet. Das Profil ist in der Regel anschließend sofort online verfügbar und kann vom Kunden bearbeitet werden.',
    ro: ' comandate, iar Anwado trimite clientului o confirmare prin e-mail. De obicei, profilul este apoi imediat disponibil online și poate fi editat de către client.',
  },
  GTCL_Paragraph_4_Title: {
    en: 'Vertragsgegenstand - Silber-, Gold- oder Platin-Mitgliedschaft („Kunden-Profil“)',
    de: 'Vertragsgegenstand - Silber-, Gold- oder Platin-Mitgliedschaft („Kunden-Profil“)',
    ro: 'Obiectul contractului - calitatea de membru Silver, Gold sau Platinum ("Profilul clientului")',
  },
  GTCL_Paragraph_4_Description_1_1: {
    en: 'Gegenstand eines Vertrages ist eine Silber-, Gold- oder Platin-Mitgliedschaft bei Anwado. Je nach gewähltem Leistungsumfang stehen unterschiedliche Leistungen und Funktionen zur Verfügung, insbesondere die Möglichkeit, eine Kanzleidarstellung, Bilder oder Kanzleivideos in das Kunden-Profil einzubinden, die Verknüpfung des Kunden-Profils mit der eigenen Homepage des Kunden, eine Funktion zur Freigabe von Online-Terminvereinbarungen mit den Nutzern und die Möglichkeit, eigene Fachbeiträge („Rechtsinfos“) zu veröffentlichen. Der Umfang, der im Rahmen der verschiedenen Mitgliedschaften angebotenen Dienstleistungen bestimmt sich nach der Leistungsbeschreibung zum Zeitpunkt des Vertragsschlusses, die Bestandteil dieses Vertrages ist. Die Leistungsbeschreibung zu den verschiedenen Mitgliedschaften kann unter ',
    de: 'Gegenstand eines Vertrages ist eine Silber-, Gold- oder Platin-Mitgliedschaft bei Anwado. Je nach gewähltem Leistungsumfang stehen unterschiedliche Leistungen und Funktionen zur Verfügung, insbesondere die Möglichkeit, eine Kanzleidarstellung, Bilder oder Kanzleivideos in das Kunden-Profil einzubinden, die Verknüpfung des Kunden-Profils mit der eigenen Homepage des Kunden, eine Funktion zur Freigabe von Online-Terminvereinbarungen mit den Nutzern und die Möglichkeit, eigene Fachbeiträge („Rechtsinfos“) zu veröffentlichen. Der Umfang, der im Rahmen der verschiedenen Mitgliedschaften angebotenen Dienstleistungen bestimmt sich nach der Leistungsbeschreibung zum Zeitpunkt des Vertragsschlusses, die Bestandteil dieses Vertrages ist. Die Leistungsbeschreibung zu den verschiedenen Mitgliedschaften kann unter ',
    ro: 'Obiectul unui contract este un abonament de argint, de aur sau de platină la Anwado. În funcție de sfera de servicii selectată, sunt disponibile diferite servicii și funcții, în special posibilitatea de a include în profilul clientului o prezentare a cabinetului de avocatură, imagini sau videoclipuri ale cabinetului de avocatură, conectarea profilului clientului cu propria pagină de pornire, o funcție pentru eliberarea de întâlniri online cu utilizatorii și posibilitatea de a publica propriile articole profesionale ("Informații juridice"). Domeniul de aplicare al serviciilor oferite în cadrul diferitelor abonamente este determinat de descrierea serviciilor la momentul încheierii contractului, care face parte integrantă din prezentul contract. Descrierea serviciilor pentru diferitele abonamente poate fi găsită la adresa',
  },
  GTCL_Paragraph_4_Description_1_2: {
    en: 'Preise',
    de: 'Preise',
    ro: 'Prețuri',
  },
  GTCL_Paragraph_4_Description_1_3: {
    en: ' abgerufen werden.',
    de: ' abgerufen werden.',
    ro: ' pot fi recuperate.',
  },
  GTCL_Paragraph_4_Description_2: {
    en: 'Anwado selbst erbringt keine Rechtsdienstleistungen. Anwado beschränkt sich auf die Rolle eines Vermittlers und technischen Dienstleisters zwischen dem Nutzer und dem Rechtsanwalt. Die Vergütung für die zu erbringende Rechtsdienstleistung muss zwischen dem Kunden und dem Nutzer direkt vereinbart werden.',
    de: 'Anwado selbst erbringt keine Rechtsdienstleistungen. Anwado beschränkt sich auf die Rolle eines Vermittlers und technischen Dienstleisters zwischen dem Nutzer und dem Rechtsanwalt. Die Vergütung für die zu erbringende Rechtsdienstleistung muss zwischen dem Kunden und dem Nutzer direkt vereinbart werden.',
    ro: 'Anwado nu oferă servicii juridice. Anwado se limitează la rolul de intermediar și furnizor de servicii tehnice între utilizator și avocat. Remunerația pentru serviciul juridic care urmează să fie furnizat trebuie să fie convenită direct între client și utilizator.',
  },
  GTCL_Paragraph_4_Description_3: {
    en: 'Der Kunde verpflichtet sich, die im Rahmen des Registrierungsprozesses abgefragten Daten wahrheitsgemäß und vollständig anzugeben und insbesondere keine Rechte Dritter zu verletzen. Eine Verletzung von Rechten Dritter ist u.a. dann gegeben, wenn personenbezogene Informationen oder sonstige Daten Dritter ohne deren Einwilligung verwendet werden, z.B. der Name oder die E-Mail-Adresse. Der Kunde verpflichtet sich zudem, im Falle einer Änderung der Daten diese unverzüglich im Kunden-Profil entsprechend zu bearbeiten.',
    de: 'Der Kunde verpflichtet sich, die im Rahmen des Registrierungsprozesses abgefragten Daten wahrheitsgemäß und vollständig anzugeben und insbesondere keine Rechte Dritter zu verletzen. Eine Verletzung von Rechten Dritter ist u.a. dann gegeben, wenn personenbezogene Informationen oder sonstige Daten Dritter ohne deren Einwilligung verwendet werden, z.B. der Name oder die E-Mail-Adresse. Der Kunde verpflichtet sich zudem, im Falle einer Änderung der Daten diese unverzüglich im Kunden-Profil entsprechend zu bearbeiten.',
    ro: 'Clientul se angajează să furnizeze datele solicitate în cadrul procesului de înregistrare în mod sincer și complet și, în special, să nu încalce drepturile terților. O încălcare a drepturilor terților este dată, printre altele, în cazul în care se utilizează informații personale sau alte date ale terților fără consimțământul acestora, de exemplu numele sau adresa de e-mail. De asemenea, clientul se obligă, în cazul unei modificări a datelor, să le editeze în mod corespunzător și fără întârziere în profilul de client.',
  },
  GTCL_Paragraph_4_Description_4: {
    en: 'Anwado vergibt für jedes Kunden-Profil eine individuelle Internetadresse ("Shortlink") in der Form www.anwado.de/[Name Anwalt]. Shortlinks können auch nachträglich ohne Zustimmung des Kunden angepasst werden. Der Kunde wird über eine Anpassung des Shortlinks informiert.',
    de: 'Anwado vergibt für jedes Kunden-Profil eine individuelle Internetadresse ("Shortlink") in der Form www.anwado.de/[Name Anwalt]. Shortlinks können auch nachträglich ohne Zustimmung des Kunden angepasst werden. Der Kunde wird über eine Anpassung des Shortlinks informiert.',
    ro: 'Anwado atribuie o adresă de internet individuală ("shortlink") pentru fiecare profil de client sub forma www.anwado.de/[nume avocat]. De asemenea, shortlink-urile pot fi modificate ulterior, fără acordul clientului. Clientul va fi informat cu privire la orice ajustare a shortlink-ului.',
  },
  GTCL_Paragraph_4_Description_5: {
    en: 'Dem Kunden ist es untersagt, Inhalte, die gegen gesetzliche Vorschriften, berufsständische Regelungen oder behördliche Verbote verstoßen oder die Rechte Dritter, insbesondere Persönlichkeitsrechte, Urheberrechte oder Markenrechte Dritter verletzen, zu veröffentlichen oder auf solche Inhalte zu verlinken.',
    de: 'Dem Kunden ist es untersagt, Inhalte, die gegen gesetzliche Vorschriften, berufsständische Regelungen oder behördliche Verbote verstoßen oder die Rechte Dritter, insbesondere Persönlichkeitsrechte, Urheberrechte oder Markenrechte Dritter verletzen, zu veröffentlichen oder auf solche Inhalte zu verlinken.',
    ro: 'Clientului îi este interzis să publice sau să creeze link-uri către conținuturi care încalcă dispozițiile legale, reglementările profesionale sau interdicțiile oficiale sau care încalcă drepturile terților, în special drepturile personale, drepturile de autor sau drepturile de marcă ale terților.',
  },
  GTCL_Paragraph_4_Description_6: {
    en: 'Die Nutzung einer Mitgliedschaft zur Auslesung, Speicherung oder Weitergabe personenbezogener Daten anderer Nutzer zu anderen Zwecken als der bestimmungsgemäßen Nutzung des Angebots ist untersagt. Kunden haben ihnen bekanntwerdende Informationen über andere Nutzer sowie Kommunikationsinhalte vertraulich zu behandeln.',
    de: 'Die Nutzung einer Mitgliedschaft zur Auslesung, Speicherung oder Weitergabe personenbezogener Daten anderer Nutzer zu anderen Zwecken als der bestimmungsgemäßen Nutzung des Angebots ist untersagt. Kunden haben ihnen bekanntwerdende Informationen über andere Nutzer sowie Kommunikationsinhalte vertraulich zu behandeln.',
    ro: 'Este interzisă utilizarea unui abonament pentru a citi, stoca sau transmite date cu caracter personal ale altor utilizatori în alte scopuri decât utilizarea prevăzută a ofertei. Clienții trebuie să trateze ca fiind confidențiale informațiile despre alți utilizatori și conținutul comunicării de care iau cunoștință.',
  },
  GTCL_Paragraph_4_Description_7: {
    en: 'Bei der Nutzung der Dienste von Anwado ist es untersagt, Daten zu versenden oder auf einem Datenträger von Anwado zu speichern, die nach ihrer Art oder Beschaffenheit, ihrem Umfang oder ihrer Anzahl geeignet sind, die Funktionsfähigkeit der Computersysteme von Anwado oder Dritter zu beeinträchtigen oder Rechte Dritter zu verletzen (z.B. Viren, Spam-E-Mails, etc.).',
    de: 'Bei der Nutzung der Dienste von Anwado ist es untersagt, Daten zu versenden oder auf einem Datenträger von Anwado zu speichern, die nach ihrer Art oder Beschaffenheit, ihrem Umfang oder ihrer Anzahl geeignet sind, die Funktionsfähigkeit der Computersysteme von Anwado oder Dritter zu beeinträchtigen oder Rechte Dritter zu verletzen (z.B. Viren, Spam-E-Mails, etc.).',
    ro: 'În cazul utilizării serviciilor Anwado, este interzisă trimiterea de date sau stocarea de date pe oricare dintre suporturile de date ale Anwado care, prin natura sau caracteristicile lor, prin volumul sau numărul lor, ar putea afecta funcționarea sistemelor informatice ale Anwado sau ale unor terțe părți sau ar putea încălca drepturile unor terțe părți (de exemplu, viruși, e-mailuri spam etc.).',
  },
  GTCL_Paragraph_4_Description_8: {
    en: 'Es wird darauf hingewiesen, dass jegliche Handlungen von Anwado im Hinblick auf Bewertungen der Nutzer, insbesondere deren Veröffentlichung, Verbreitung, Überprüfung und/oder Löschung, nicht Gegenstand des Vertragsverhältnisses mit dem Kunden sind. Mit der Buchung einer Silber-, Gold- oder Platin-Mitgliedschaften sind keine besonderen Rechte in Bezug auf Bewertungen verbunden. Der Kunde kann auch keine Verletzung vertraglicher Nebenpflichten seitens Anwado geltend machen, insbesondere wenn er mit der Veröffentlichung, Verbreitung, Überprüfung und/oder Löschung von Bewertungen oder bei einem Manipulationsverdacht nicht einverstanden ist.‍',
    de: 'Es wird darauf hingewiesen, dass jegliche Handlungen von Anwado im Hinblick auf Bewertungen der Nutzer, insbesondere deren Veröffentlichung, Verbreitung, Überprüfung und/oder Löschung, nicht Gegenstand des Vertragsverhältnisses mit dem Kunden sind. Mit der Buchung einer Silber-, Gold- oder Platin-Mitgliedschaften sind keine besonderen Rechte in Bezug auf Bewertungen verbunden. Der Kunde kann auch keine Verletzung vertraglicher Nebenpflichten seitens Anwado geltend machen, insbesondere wenn er mit der Veröffentlichung, Verbreitung, Überprüfung und/oder Löschung von Bewertungen oder bei einem Manipulationsverdacht nicht einverstanden ist.‍',
    ro: 'Se subliniază faptul că orice acțiune a Anwado cu privire la evaluările utilizatorilor, în special publicarea, difuzarea, revizuirea și/sau ștergerea acestora, nu face obiectul relației contractuale cu clientul. Rezervarea unui abonament de argint, de aur sau de platină nu implică niciun drept special în ceea ce privește evaluările. De asemenea, clientul nu poate invoca nicio încălcare a obligațiilor contractuale auxiliare din partea Anwado, în special în cazul în care nu este de acord cu publicarea, difuzarea, revizuirea și/sau ștergerea ratingurilor sau în caz de suspiciune de manipulare.‍',
  },
  GTCL_Paragraph_5_Title: {
    en: 'Online-Terminvereinbarung',
    de: 'Online-Terminvereinbarung',
    ro: 'Programare online',
  },
  GTCL_Paragraph_5_Description_1: {
    en: 'Kunden die eine Gold- oder Platin-Mitgliedschaft abgeschlossen haben, können die Funktion „Online-Terminvereinbarung“ auf Anwado nutzen. Die Online-Terminvereinbarung bietet Nutzern die Möglichkeit direkt über Anwado Termine beim Kunden online buchen zu können. Hierbei bestimmt der Kunde, welche Termine über die Anwado- Kalenderfunktion angezeigt werden und buchbar sind. Die Vergütung für die zu erbringende Rechtsdienstleistung muss zwischen dem Kunden und dem Nutzer direkt vereinbart werden.',
    de: 'Kunden die eine Gold- oder Platin-Mitgliedschaft abgeschlossen haben, können die Funktion „Online-Terminvereinbarung“ auf Anwado nutzen. Die Online-Terminvereinbarung bietet Nutzern die Möglichkeit direkt über Anwado Termine beim Kunden online buchen zu können. Hierbei bestimmt der Kunde, welche Termine über die Anwado- Kalenderfunktion angezeigt werden und buchbar sind. Die Vergütung für die zu erbringende Rechtsdienstleistung muss zwischen dem Kunden und dem Nutzer direkt vereinbart werden.',
    ro: 'Clienții care au contractat un abonament Gold sau Platinum pot utiliza funcția "Programare online" de pe Anwado. Funcția de programare online permite utilizatorilor să facă programări cu clienții direct online prin intermediul Anwado. Clientul stabilește ce programări sunt afișate și pot fi rezervate prin intermediul funcției de calendar Anwado. Onorariul pentru serviciul juridic care urmează să fie prestat trebuie să fie convenit direct între client și utilizator.',
  },
  GTCL_Paragraph_5_Description_2: {
    en: 'Bei jeder Online-Terminvereinbarung erhält der Kunde die Information über die gebuchten Termine (Tag, Uhrzeit) und der vom Nutzer angegebenen Daten per E-Mail. Die über Anwado gebuchten Termine sind verbindlich. Ein Kunde soll darauf achten, nur Termine anzubieten, die er auch einhalten kann. Bei Nichtverfügbarkeit hat der Kunde die Möglichkeit, einen gebuchten Termin zu verschieben oder zu stornieren.',
    de: 'Bei jeder Online-Terminvereinbarung erhält der Kunde die Information über die gebuchten Termine (Tag, Uhrzeit) und der vom Nutzer angegebenen Daten per E-Mail. Die über Anwado gebuchten Termine sind verbindlich. Ein Kunde soll darauf achten, nur Termine anzubieten, die er auch einhalten kann. Bei Nichtverfügbarkeit hat der Kunde die Möglichkeit, einen gebuchten Termin zu verschieben oder zu stornieren.',
    ro: 'Ori de câte ori se face o programare online, clientul primește prin e-mail informații despre programările rezervate (ziua, ora) și datele furnizate de utilizator. Întâlnirile rezervate prin intermediul Anwado sunt obligatorii. Un client trebuie să aibă grijă să ofere doar întâlniri la care poate să se țină. În caz de indisponibilitate, clientul are posibilitatea de a amâna sau de a anula o întâlnire rezervată.',
  },
  GTCL_Paragraph_5_Description_3: {
    en: 'Der Kunde erhält über die in Ziffer 5.2 genannten Daten hinaus kein Zugang zu personenbezogenen oder sonstigen Daten, die der Nutzer für die Nutzung der Online-Terminvereinbarung zur Verfügung stellt oder die im Zuge der Bereitstellung dieses Dienstes generiert werden. Dies umfasst technische Daten, die bei der Bereitstellung des Dienstes generiert werden und nur Anwado vorliegen. An Dritte werden diese Daten nicht weitergegeben.',
    de: 'Der Kunde erhält über die in Ziffer 5.2 genannten Daten hinaus kein Zugang zu personenbezogenen oder sonstigen Daten, die der Nutzer für die Nutzung der Online-Terminvereinbarung zur Verfügung stellt oder die im Zuge der Bereitstellung dieses Dienstes generiert werden. Dies umfasst technische Daten, die bei der Bereitstellung des Dienstes generiert werden und nur Anwado vorliegen. An Dritte werden diese Daten nicht weitergegeben.',
    ro: 'Clientul nu va avea acces la alte date cu caracter personal sau de altă natură în afara datelor menționate la clauza 5.2 pe care utilizatorul le furnizează pentru utilizarea sistemului de programare online sau care sunt generate în cursul furnizării acestui serviciu. Aceasta include datele tehnice generate în cursul furnizării serviciului și disponibile doar pentru Anwado. Aceste date nu vor fi transmise unor terțe părți.',
  },
  GTCL_Paragraph_6_Title: {
    en: 'Fachbeiträge des Kunden',
    de: 'Fachbeiträge des Kunden',
    ro: 'Contribuții tehnice din partea clientului',
  },
  GTCL_Paragraph_6_Description_1: {
    en: 'Kunden, die eine Gold- oder Platin-Mitgliedschaft abgeschlossen haben, dürfen auf Anwado Fachbeiträge („Rechtsinfos“) veröffentlichen. Fachbeiträge müssen in deutscher Sprache veröffentlicht werden. Ein Anspruch auf Veröffentlichung oder dauerhafte Veröffentlichung eines Fachbeitrags besteht nicht. Nur selbstverfasste Fachbeiträge zu juristischen und rechtspolitischen Themen, die ein hohes Maß an Einzigartigkeit aufweisen und keine reine Eigenwerbung sind, dürfen auf Anwado veröffentlicht werden. Fachbeiträge dürfen keine Computercode o. Ä. enthalten. Anwado ist nicht für die rechtliche und/oder inhaltliche Prüfung von Fachbeiträgen verantwortlich.',
    de: 'Kunden, die eine Gold- oder Platin-Mitgliedschaft abgeschlossen haben, dürfen auf Anwado Fachbeiträge („Rechtsinfos“) veröffentlichen. Fachbeiträge müssen in deutscher Sprache veröffentlicht werden. Ein Anspruch auf Veröffentlichung oder dauerhafte Veröffentlichung eines Fachbeitrags besteht nicht. Nur selbstverfasste Fachbeiträge zu juristischen und rechtspolitischen Themen, die ein hohes Maß an Einzigartigkeit aufweisen und keine reine Eigenwerbung sind, dürfen auf Anwado veröffentlicht werden. Fachbeiträge dürfen keine Computercode o. Ä. enthalten. Anwado ist nicht für die rechtliche und/oder inhaltliche Prüfung von Fachbeiträgen verantwortlich.',
    ro: 'Clienții care au contractat un abonament Gold sau Platinum pot publica articole de specialitate ("Rechtsinfos") pe Anwado. Articolele juridice trebuie să fie publicate în limba germană. Nu există niciun drept de publicare sau de publicare permanentă a unui articol. Pe Anwado pot fi publicate numai articole scrise de autorul propriu pe teme juridice și de politică juridică, care sunt extrem de unice și nu sunt pur autopromoționale. Articolele nu pot conține coduri de calculator sau similare. Anwado nu este responsabil pentru revizuirea juridică și/sau de conținut a contribuțiilor profesionale.',
  },
  GTCL_Paragraph_6_Description_2: {
    en: 'Anwado kann Fachbeiträge des Kunden im Falle einer missbräuchlichen Nutzung sperren oder löschen. Eine solche missbräuchliche Nutzung liegt insbesondere dann vor, wenn ein Fachbeitrag in einer fremden Sprache veröffentlich wird oder mehrere Beiträge eines Kunden den gleichen Sachverhalt behandeln und sich fachlich oder sachlich nicht wesentlich voneinander unterscheiden („Duplicate Content“).',
    de: 'Anwado kann Fachbeiträge des Kunden im Falle einer missbräuchlichen Nutzung sperren oder löschen. Eine solche missbräuchliche Nutzung liegt insbesondere dann vor, wenn ein Fachbeitrag in einer fremden Sprache veröffentlich wird oder mehrere Beiträge eines Kunden den gleichen Sachverhalt behandeln und sich fachlich oder sachlich nicht wesentlich voneinander unterscheiden („Duplicate Content“).',
    ro: 'Anwado poate bloca sau șterge contribuțiile tehnice ale clientului în cazul utilizării necorespunzătoare. O astfel de utilizare necorespunzătoare se consideră că a avut loc în special în cazul în care un articol tehnic este publicat într-o limbă străină sau dacă mai multe articole ale unui client tratează același subiect și nu diferă în mod semnificativ unul de celălalt în ceea ce privește conținutul tehnic sau faptic ("conținut duplicat").',
  },
  GTCL_Paragraph_7_Title: {
    en: 'Urheber- und Nutzungsrechte',
    de: 'Urheber- und Nutzungsrechte',
    ro: 'Drepturi de autor și drepturi de utilizare',
  },
  GTCL_Paragraph_7_Description_1: {
    en: 'Der Kunde darf durch die Veröffentlichung von Texten, Bildern und Videodateien nicht gegen geltendes Recht, die guten Sitten und/oder diese Bedingungen verstoßen. Insbesondere muss er die Rechte Dritter (Namens-, Urheber-, Datenschutzrecht, etc.) beachten. Der Kunde ist verpflichtet, Anwado unverzüglich von etwaigen Rechtsverletzungen zu unterrichten, sobald Dritte diese gegenüber dem Kunden geltend machen. Anwado behält sich das Recht vor, im Falle einer entsprechenden Inanspruchnahme während der Prüfung der Berechtigung dieser geltend gemachten Rechtsverletzungen, den betreffenden Inhalt oder das betreffende Profil vorübergehend zu sperren. Verstöße gegen gesetzliche Bestimmungen oder diese AGB berechtigen Anwado zur sofortigen vorübergehenden oder dauerhaften Löschung der entsprechenden Beiträge oder in besonders schwerwiegenden Fällen zur sofortigen vorübergehenden oder dauerhaften Sperrung des gesamten Kunden-Profils.',
    de: 'Der Kunde darf durch die Veröffentlichung von Texten, Bildern und Videodateien nicht gegen geltendes Recht, die guten Sitten und/oder diese Bedingungen verstoßen. Insbesondere muss er die Rechte Dritter (Namens-, Urheber-, Datenschutzrecht, etc.) beachten. Der Kunde ist verpflichtet, Anwado unverzüglich von etwaigen Rechtsverletzungen zu unterrichten, sobald Dritte diese gegenüber dem Kunden geltend machen. Anwado behält sich das Recht vor, im Falle einer entsprechenden Inanspruchnahme während der Prüfung der Berechtigung dieser geltend gemachten Rechtsverletzungen, den betreffenden Inhalt oder das betreffende Profil vorübergehend zu sperren. Verstöße gegen gesetzliche Bestimmungen oder diese AGB berechtigen Anwado zur sofortigen vorübergehenden oder dauerhaften Löschung der entsprechenden Beiträge oder in besonders schwerwiegenden Fällen zur sofortigen vorübergehenden oder dauerhaften Sperrung des gesamten Kunden-Profils.',
    ro: 'Clientul nu are voie să încalce legislația aplicabilă, moralitatea și/sau acești termeni și condiții prin publicarea de texte, imagini și fișiere video. În special, acesta trebuie să respecte drepturile terților (nume, drepturi de autor, legea privind protecția datelor etc.). Clientul este obligat să informeze imediat Anwado cu privire la orice încălcare a drepturilor, de îndată ce terții fac valabilă o astfel de pretenție față de client. Anwado își rezervă dreptul, în cazul unei astfel de reclamații, de a bloca temporar conținutul sau profilul în cauză până la examinarea legitimității acestor încălcări afirmate. Încălcările dispozițiilor legale sau ale prezentelor CGC dau dreptul Anwado la ștergerea imediată, temporară sau permanentă, a postărilor respective sau, în cazuri deosebit de grave, la blocarea imediată, temporară sau permanentă, a întregului profil al clientului.',
  },
  GTCL_Paragraph_7_Description_2: {
    en: 'Der Kunde sichert zu, dass er berechtigt ist, die Texte und sonstigen Materialien gemäß den vorstehenden Ziffern, die er für die Gestaltung des Kunden-Profils und/oder sonstigen Nutzung (Rechtsinfos, etc.) an Anwado übermittelt hat oder zu deren Nutzung er Anwado berechtigt, zu verwenden und keine Rechte Dritter entgegenstehen.',
    de: 'Der Kunde sichert zu, dass er berechtigt ist, die Texte und sonstigen Materialien gemäß den vorstehenden Ziffern, die er für die Gestaltung des Kunden-Profils und/oder sonstigen Nutzung (Rechtsinfos, etc.) an Anwado übermittelt hat oder zu deren Nutzung er Anwado berechtigt, zu verwenden und keine Rechte Dritter entgegenstehen.',
    ro: 'Clientul garantează că are dreptul de a utiliza textele și alte materiale în conformitate cu clauzele de mai sus, pe care le-a transmis către Anwado pentru crearea profilului de client și/sau pentru alte utilizări (informații juridice etc.) sau pe care autorizează Anwado să le utilizeze, și că nu există drepturi ale unor terțe părți care să intre în conflict cu acestea.',
  },
  GTCL_Paragraph_7_Description_3_1: {
    en: 'Die von Kunden unter ',
    de: 'Die von Kunden unter ',
    ro: 'Serviciile prestate de clienți în cadrul',
  },
  GTCL_Paragraph_7_Description_3_2: {
    en: 'www.anwado.de',
    de: 'www.anwado.de',
    ro: 'www.anwado.de',
  },
  GTCL_Paragraph_7_Description_3_3: {
    en: ' eingestellten Inhalte sind nach Maßgabe der gesetzlichen Bestimmungen urheberrechtlich geschützt. Eine Verwendung solcher Inhalte (z.B. Profilbilder) in anderen elektronischen oder gedruckten Publikationen sowie im Rahmen öffentlicher Wiedergaben durch andere Kunden ist ohne ausdrückliche Zustimmung des jeweiligen Kunden nicht gestattet.',
    de: ' eingestellten Inhalte sind nach Maßgabe der gesetzlichen Bestimmungen urheberrechtlich geschützt. Eine Verwendung solcher Inhalte (z.B. Profilbilder) in anderen elektronischen oder gedruckten Publikationen sowie im Rahmen öffentlicher Wiedergaben durch andere Kunden ist ohne ausdrückliche Zustimmung des jeweiligen Kunden nicht gestattet.',
    ro: ' Conținutul postat pe site-ul web este protejat prin drepturi de autor în conformitate cu prevederile legale. Nu este permisă utilizarea acestor conținuturi (de exemplu, imagini de profil) în alte publicații electronice sau tipărite, precum și în contextul reproducerilor publice de către alți clienți, fără acordul expres al clientului respectiv.',
  },
  GTCL_Paragraph_7_Description_4: {
    en: 'Der Kunde räumt Anwado ab Vertragsschluss an den von ihm veröffentlichten Inhalten (z.B. eigene Beiträge, Marken, Logos, eigene Bilder) die für den Betrieb von Anwado erforderlichen urheberrechtlichen Nutzungsrechte zu gewerblichen Zwecken unentgeltlich ein. Das Nutzungsrecht umfasst insbesondere das nicht ausschließliche, unwiderrufliche, räumlich und inhaltlich unbeschränkte Recht, die Inhalte ganz oder teilweise unter Verwendung sämtlicher digitaler oder analoger Übertragungs- und Abruftechniken sowie aller fester und mobiler Empfangsvorrichtungen und –mittel online auf Anwado.de und auf Internet-Seiten Dritter (z.B. Instagramm, Facebook) zur Verfügung zu stellen. Anwado ist berechtigt, Texte, Bilder oder Videodateien, die der Kunde zur Gestaltung seines Profils oder als Rechtsinfos verwendet, öffentlich zugänglich zu machen, zu vervielfältigen, zu verbreiten und sie zu diesem Zweck in den Grenzen des Urheberrechts zu bearbeiten. Eine Übertragung der Nutzungsrechte auf Partnerseiten zum Zwecke der Reichweitensteigerung ist Anwado uneingeschränkt gestattet.',
    de: 'Der Kunde räumt Anwado ab Vertragsschluss an den von ihm veröffentlichten Inhalten (z.B. eigene Beiträge, Marken, Logos, eigene Bilder) die für den Betrieb von Anwado erforderlichen urheberrechtlichen Nutzungsrechte zu gewerblichen Zwecken unentgeltlich ein. Das Nutzungsrecht umfasst insbesondere das nicht ausschließliche, unwiderrufliche, räumlich und inhaltlich unbeschränkte Recht, die Inhalte ganz oder teilweise unter Verwendung sämtlicher digitaler oder analoger Übertragungs- und Abruftechniken sowie aller fester und mobiler Empfangsvorrichtungen und –mittel online auf Anwado.de und auf Internet-Seiten Dritter (z.B. Instagramm, Facebook) zur Verfügung zu stellen. Anwado ist berechtigt, Texte, Bilder oder Videodateien, die der Kunde zur Gestaltung seines Profils oder als Rechtsinfos verwendet, öffentlich zugänglich zu machen, zu vervielfältigen, zu verbreiten und sie zu diesem Zweck in den Grenzen des Urheberrechts zu bearbeiten. Eine Übertragung der Nutzungsrechte auf Partnerseiten zum Zwecke der Reichweitensteigerung ist Anwado uneingeschränkt gestattet.',
    ro: 'Din momentul încheierii contractului, clientul acordă în mod gratuit Anwado drepturile de utilizare în scopuri comerciale a conținutului publicat de el (de exemplu, articole proprii, mărci, logo-uri, imagini proprii) care sunt necesare pentru funcționarea Anwado. Dreptul de utilizare include, în special, dreptul neexclusiv și irevocabil de a pune la dispoziție conținutul online pe Anwado.de și pe site-urile web ale unor terți (de exemplu, Instagram, Facebook), integral sau parțial, utilizând toate tehnologiile digitale sau analogice de transmisie și recuperare și toate dispozitivele și mijloacele de recepție fixe și mobile. Anwado are dreptul de a pune la dispoziția publicului textele, imaginile sau fișierele video pe care clientul le folosește pentru a-și concepe profilul sau ca informații juridice, de a le reproduce, de a le distribui și de a le edita în acest scop în limitele legii drepturilor de autor. Anwado este autorizată fără restricții să transfere drepturile de utilizare către site-uri partenere în scopul creșterii acoperirii.',
  },
  GTCL_Paragraph_7_Description_5_1: {
    en: 'Die Rechte (insbesondere Urheber-, Marken- und Kennzeichenrechte) für veröffentlichte, von Anwado bzw. deren Mitarbeitern selbst erstellte oder auf ',
    de: 'Die Rechte (insbesondere Urheber-, Marken- und Kennzeichenrechte) für veröffentlichte, von Anwado bzw. deren Mitarbeitern selbst erstellte oder auf ',
    ro: 'Drepturile (în special drepturile de autor, drepturile de marcă comercială și drepturile de etichetare) pentru materialele publicate create de Anwado sau de angajații săi, în mod direct sau pe site-ul',
  },
  GTCL_Paragraph_7_Description_5_2: {
    en: 'www.anwado.de',
    de: 'www.anwado.de',
    ro: 'www.anwado.de',
  },
  GTCL_Paragraph_7_Description_5_3: {
    en: ' oder in mobilen Angeboten (Apps) von Anwado selbst eingestellten Objekte bzw. für Inhalte, die im Eigentum von Anwado stehen, bleiben allein bei Anwado. Eine Vervielfältigung und/oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung von Anwado nicht gestattet. Insbesondere dürfen das Anwado-Anwaltsverzeichnis und die auf Anwado veröffentlichten Rechtsinformationen nicht kopiert werden.',
    de: ' oder in mobilen Angeboten (Apps) von Anwado selbst eingestellten Objekte bzw. für Inhalte, die im Eigentum von Anwado stehen, bleiben allein bei Anwado. Eine Vervielfältigung und/oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung von Anwado nicht gestattet. Insbesondere dürfen das Anwado-Anwaltsverzeichnis und die auf Anwado veröffentlichten Rechtsinformationen nicht kopiert werden.',
    ro: ' sau obiectele plasate în ofertele mobile (aplicații) de către Anwado însăși sau pentru conținutul deținut de Anwado rămân proprietatea exclusivă a Anwado. Orice reproducere și/sau utilizare a unor astfel de grafice, documente sonore, secvențe video și texte în alte publicații electronice sau tipărite nu este permisă fără acordul expres al Anwado. În special, directorul de avocați Anwado și informațiile juridice publicate pe Anwado nu pot fi copiate.',
  },
  GTCL_Paragraph_7_Description_6: {
    en: 'Der Kunde verpflichtet sich, nicht zu versuchen, auf die Quellcodes der Anwado-Plattform zuzugreifen oder diese zu kopieren; und die Anwado-Plattform nicht für andere Zwecke als die Nutzung der Anwado-Plattform zu verwenden; keine Kopien der Anwado-Plattform anzufertigen; die Anwado-Plattform weder zu vervielfältigen, noch die Anwado-Plattform zu korrigieren, zu extrahieren, zu modifizieren, in eine oder mehrere Sprachen zu übersetzen oder die Anwado-Plattform in andere Software zu integrieren oder auf Grundlage der Anwado-Plattform abgeleitete Werke zu erstellen, die auf der Anwado-Plattform basieren sowie die Anwado-Plattform nicht weiterzuverkaufen, zu vermieten oder kommerziell zu nutzen und die Anwado-Plattform nicht an Dritte zu übertragen.',
    de: 'Der Kunde verpflichtet sich, nicht zu versuchen, auf die Quellcodes der Anwado-Plattform zuzugreifen oder diese zu kopieren; und die Anwado-Plattform nicht für andere Zwecke als die Nutzung der Anwado-Plattform zu verwenden; keine Kopien der Anwado-Plattform anzufertigen; die Anwado-Plattform weder zu vervielfältigen, noch die Anwado-Plattform zu korrigieren, zu extrahieren, zu modifizieren, in eine oder mehrere Sprachen zu übersetzen oder die Anwado-Plattform in andere Software zu integrieren oder auf Grundlage der Anwado-Plattform abgeleitete Werke zu erstellen, die auf der Anwado-Plattform basieren sowie die Anwado-Plattform nicht weiterzuverkaufen, zu vermieten oder kommerziell zu nutzen und die Anwado-Plattform nicht an Dritte zu übertragen.',
    ro: 'Clientul este de acord să nu încerce să acceseze sau să copieze codurile sursă ale platformei Anwado; și să nu utilizeze platforma Anwado în alt scop decât utilizarea platformei Anwado; să nu facă copii ale platformei Anwado; să nu reproducă platforma Anwado, să nu corecteze, să nu extragă, să nu modifice, să nu traducă platforma Anwado în una sau mai multe limbi, să nu integreze platforma Anwado în alt software sau să nu creeze lucrări derivate bazate pe platforma Anwado și să nu revândă, să nu închirieze sau să nu exploateze comercial platforma Anwado și să nu transfere platforma Anwado către o terță parte.',
  },
  GTCL_Paragraph_8_Title: {
    en: 'Ranking der Anwalts-Suche',
    de: 'Ranking der Anwalts-Suche',
    ro: 'Avocat de căutare clasament',
  },
  GTCL_Paragraph_8_Description: {
    en: `Anwado ist so konzipiert, dass es Nutzern die Möglichkeit gibt, ihre Entscheidungen selbständig und nach ihren Bedürfnissen zu treffen. Aus diesem Grund sind die Suchergebnisse und das Ranking der Anwälte für jede Anfrage individuell erzeugt.
    Anwado berücksichtigt dabei eine Vielzahl von Faktoren, die sich an der Gesamt-Relevanz für Nutzer orientieren. Deshalb basiert die Sortierlogik auf dem Anwado Relevance Score, einer Kombination aus verschiedenen Faktoren, die die Relevanz und Attraktivität eines Anwaltsprofils für die Nutzer bestimmen. Dazu gehören insbesondere die Anzahl und der Durchschnitt der Bewertungen und beispielsweise Profilinformationen wie das Vorhandensein eines Profilbilds, eine individuelle Kurzbeschreibung, Informationen über den Anwalt und veröffentlichte Artikel sowie die Verfügbarkeit von Online-Terminen.
    `,
    de: `Anwado ist so konzipiert, dass es Nutzern die Möglichkeit gibt, ihre Entscheidungen selbständig und nach ihren Bedürfnissen zu treffen. Aus diesem Grund sind die Suchergebnisse und das Ranking der Anwälte für jede Anfrage individuell erzeugt.
    Anwado berücksichtigt dabei eine Vielzahl von Faktoren, die sich an der Gesamt-Relevanz für Nutzer orientieren. Deshalb basiert die Sortierlogik auf dem Anwado Relevance Score, einer Kombination aus verschiedenen Faktoren, die die Relevanz und Attraktivität eines Anwaltsprofils für die Nutzer bestimmen. Dazu gehören insbesondere die Anzahl und der Durchschnitt der Bewertungen und beispielsweise Profilinformationen wie das Vorhandensein eines Profilbilds, eine individuelle Kurzbeschreibung, Informationen über den Anwalt und veröffentlichte Artikel sowie die Verfügbarkeit von Online-Terminen.
    `,
  },
  GTCL_Paragraph_9_Title: {
    en: 'Änderungen der Leistungen',
    de: 'Änderungen der Leistungen',
    ro: 'Modificări ale prestațiilor',
  },
  GTCL_Paragraph_9_Description: {
    en: 'Anwado behält sich vor, Änderungen und Anpassung der inhaltlichen Gestaltung der Silber-, Gold- oder Platin-Mitgliedschaft vorzunehmen, solange der Umfang und der Inhalt der jeweiligen Mitgliedschaft seinem Charakter nach erhalten bleibt. Darüber hinaus behält sich Anwado vor, den Inhalt einzelner Pakete oder Optionen abzuändern bzw. anzupassen, soweit dies aus technischen Gründen, wie z.B. bei einer von Google geänderten Funktion seiner Suchmaschine, oder aus unternehmerischen Gründen, erforderlich ist.',
    de: 'Anwado behält sich vor, Änderungen und Anpassung der inhaltlichen Gestaltung der Silber-, Gold- oder Platin-Mitgliedschaft vorzunehmen, solange der Umfang und der Inhalt der jeweiligen Mitgliedschaft seinem Charakter nach erhalten bleibt. Darüber hinaus behält sich Anwado vor, den Inhalt einzelner Pakete oder Optionen abzuändern bzw. anzupassen, soweit dies aus technischen Gründen, wie z.B. bei einer von Google geänderten Funktion seiner Suchmaschine, oder aus unternehmerischen Gründen, erforderlich ist.',
    ro: 'Anwado își rezervă dreptul de a face modificări și adaptări la conținutul abonamentului de argint, aur sau platină, atât timp cât domeniul de aplicare și conținutul abonamentului respectiv rămân aceleași. În plus, Anwado își rezervă dreptul de a modifica sau de a adapta conținutul pachetelor sau opțiunilor individuale dacă acest lucru este necesar din motive tehnice, cum ar fi o modificare a funcției motorului său de căutare de către Google, sau din motive comerciale.',
  },
  GTCL_Paragraph_10_Title: {
    en: 'Preise, Zahlung und Tarifänderungen',
    de: 'Preise, Zahlung und Tarifänderungen',
    ro: 'Modificări de prețuri, plăți și tarife',
  },
  GTCL_Paragraph_10_Description_1: {
    en: 'Anwado berechnet für eine Gold- oder Platin-Mitgliedschaft als Entgelt für die erbrachten Leistungen die beim Absenden des Vertragsangebotes mitgeteilten Preise. Wird der Vertrag verlängert, gelten zwischen den Parteien die zum Zeitpunkt der Vertragsverlängerung aktuellen Preise für die jeweilige Mitgliedschaft. Alle Preise verstehen sich zuzüglich der Mehrwertsteuer in der jeweils gültigen gesetzlichen Höhe. Die Leistungserbringung durch Anwado erfolgt während dieser Vertragslaufzeit jeweils monatlich anteilig. Dem Kunden stehen als Zahlungsmethoden eine Zahlung mit Kreditkarte oder die Erteilung eines SEPA-Lastschriftmandats zur Verfügung.',
    de: 'Anwado berechnet für eine Gold- oder Platin-Mitgliedschaft als Entgelt für die erbrachten Leistungen die beim Absenden des Vertragsangebotes mitgeteilten Preise. Wird der Vertrag verlängert, gelten zwischen den Parteien die zum Zeitpunkt der Vertragsverlängerung aktuellen Preise für die jeweilige Mitgliedschaft. Alle Preise verstehen sich zuzüglich der Mehrwertsteuer in der jeweils gültigen gesetzlichen Höhe. Die Leistungserbringung durch Anwado erfolgt während dieser Vertragslaufzeit jeweils monatlich anteilig. Dem Kunden stehen als Zahlungsmethoden eine Zahlung mit Kreditkarte oder die Erteilung eines SEPA-Lastschriftmandats zur Verfügung.',
    ro: 'Pentru un abonament gold sau platinum, Anwado percepe ca taxă pentru serviciile prestate prețurile comunicate în momentul depunerii ofertei de contract. În cazul în care contractul este prelungit, între părți se vor aplica prețurile curente pentru respectivul membru la momentul prelungirii contractului. Toate prețurile sunt supuse taxei pe valoarea adăugată la cota legală aplicabilă la momentul respectiv. Pe durata contractului, Anwado va furniza serviciile pe o bază pro rata lunară. Clientul poate plăti prin card de credit sau prin mandat de debitare directă SEPA.',
  },
  GTCL_Paragraph_10_Description_2: {
    en: 'Der Kunde kann bei Vertragsabschluss eine monatliche Zahlung oder eine jährliche Zahlung wählen. Bei monatlich kündbaren Verträgen erfolgt eine monatliche Abbuchung zum Anfang der Vertragslaufzeit, bei jährlich kündbaren Verträgen wird der Jahresbetrag am Anfang der Vertragslaufzeit einmalig abgebucht. Der Rechnungsbetrag ist jeweils nach Abschluss der Bestellung bzw. Erteilung eines SEPA-Lastschriftmandats zur Zahlung fällig.',
    de: 'Der Kunde kann bei Vertragsabschluss eine monatliche Zahlung oder eine jährliche Zahlung wählen. Bei monatlich kündbaren Verträgen erfolgt eine monatliche Abbuchung zum Anfang der Vertragslaufzeit, bei jährlich kündbaren Verträgen wird der Jahresbetrag am Anfang der Vertragslaufzeit einmalig abgebucht. Der Rechnungsbetrag ist jeweils nach Abschluss der Bestellung bzw. Erteilung eines SEPA-Lastschriftmandats zur Zahlung fällig.',
    ro: 'La încheierea contractului, clientul poate alege o plată lunară sau anuală. În cazul contractelor care pot fi reziliate lunar, suma lunară este debitată la începutul duratei contractului; în cazul contractelor care pot fi reziliate anual, suma anuală este debitată o dată la începutul duratei contractului. Suma facturată trebuie plătită după finalizarea comenzii sau după emiterea unui mandat de debitare directă SEPA.',
  },
  GTCL_Paragraph_10_Description_3: {
    en: 'Wird bei einem Vertrag mit monatlicher Kündigungsfrist in zwei aufeinander folgenden Monaten die Zahlung mangels ausreichender Kontodeckung oder aufgrund der Angabe einer falschen Bankverbindung nicht eingelöst oder widerspricht der Kunde jeweils der Abbuchung, obwohl er hierzu nicht berechtigt ist, ist Anwado berechtigt, den Vertrag mit dem Kunden außerordentlich zu kündigen.',
    de: 'Wird bei einem Vertrag mit monatlicher Kündigungsfrist in zwei aufeinander folgenden Monaten die Zahlung mangels ausreichender Kontodeckung oder aufgrund der Angabe einer falschen Bankverbindung nicht eingelöst oder widerspricht der Kunde jeweils der Abbuchung, obwohl er hierzu nicht berechtigt ist, ist Anwado berechtigt, den Vertrag mit dem Kunden außerordentlich zu kündigen.',
    ro: 'În cazul în care, în cazul unui contract cu preaviz lunar, plata nu este onorată în două luni consecutive din cauza lipsei de fonduri suficiente în cont sau din cauza furnizării de date bancare incorecte sau dacă clientul se opune debitării directe în fiecare caz, deși nu are dreptul să facă acest lucru, Anwado are dreptul de a rezilia în mod extraordinar contractul cu clientul.',
  },
  GTCL_Paragraph_10_Description_4: {
    en: 'Gerät der Kunde mit der Zahlung kostenpflichtiger Dienstleistungen von Anwado in Verzug, hat Anwado das Recht, die Erfüllung fälliger Dienstleistungen gegenüber dem Kunden zu verweigern – insbesondere die bereitgestellten Informationen und die Verlinkung auf Websites des Kunden vorübergehend zu sperren – bis der Verzug beseitigt ist. Anwado wird dem Kunden in diesen Fällen die Sperrung mit regelmäßig vierzehn Werktagen umfassender Vorfrist zur Beseitigung des Verzugs schriftlich ankündigen. Die sonstigen gesetzlichen und vertraglichen Rechte von Anwado wegen Zahlungsverzugs des Kunden bleiben durch die Leistungsverweigerung unberührt.',
    de: 'Gerät der Kunde mit der Zahlung kostenpflichtiger Dienstleistungen von Anwado in Verzug, hat Anwado das Recht, die Erfüllung fälliger Dienstleistungen gegenüber dem Kunden zu verweigern – insbesondere die bereitgestellten Informationen und die Verlinkung auf Websites des Kunden vorübergehend zu sperren – bis der Verzug beseitigt ist. Anwado wird dem Kunden in diesen Fällen die Sperrung mit regelmäßig vierzehn Werktagen umfassender Vorfrist zur Beseitigung des Verzugs schriftlich ankündigen. Die sonstigen gesetzlichen und vertraglichen Rechte von Anwado wegen Zahlungsverzugs des Kunden bleiben durch die Leistungsverweigerung unberührt.',
    ro: 'În cazul în care clientul este în întârziere de plată pentru serviciile furnizate de Anwado pentru care se percepe o taxă, Anwado are dreptul de a refuza să furnizeze clientului serviciile datorate - în special să blocheze temporar informațiile furnizate și linkul către site-urile web ale clientului - până la remedierea întârzierii. În astfel de cazuri, Anwado va notifica clientul în scris cu privire la blocare, cu un termen de preaviz de paisprezece zile lucrătoare pentru remedierea nerespectării obligațiilor. Celelalte drepturi legale și contractuale ale Anwado datorate neîndeplinirii obligațiilor de plată ale clientului nu vor fi afectate de refuzul de a executa.',
  },
  GTCL_Paragraph_11_Title: {
    en: 'Vertragslaufzeit / Kündigung',
    de: 'Vertragslaufzeit / Kündigung',
    ro: 'Perioada contractuală / reziliere',
  },
  GTCL_Paragraph_11_Description: {
    en: 'Die Vertragslaufzeit beträgt je nach gebuchter Mitgliedschaft ab dem Tag der Bestellung einen Monat oder 12 Monate. Der Vertrag verlängert sich automatisch bei einer monatlichen Mitgliedschaft um jeweils einen weiteren Monat oder bei einer jährlichen Mitgliedschaft jeweils um weitere 12 Monate, wenn nicht der Kunde vor Ablauf der Vertragslaufzeit im Kunden-Profil im Bereich Abonnement auf eine kostenfreie Silber-Mitgliedschaft umstellt. Diese Änderung auf ein Silber-Profil wird zum nächstmöglichen Ablauf der jeweiligen Vertragslaufzeit einer kostenpflichtigen Mitgliedschaft wirksam. Eine Kündigung des Silber-Profils kann der Kunde jederzeit im Kunden-Profil im Bereich „Konto“ durch Löschung des „Kontos“ durchführen. In diesem Fall bleiben die Basis-Daten auf Anwado in einem kostenfreien Basis-Profil gespeichert. Das Recht zur fristlosen Kündigung aus wichtigem Grund bleibt für beide Parteien unberührt.',
    de: 'Die Vertragslaufzeit beträgt je nach gebuchter Mitgliedschaft ab dem Tag der Bestellung einen Monat oder 12 Monate. Der Vertrag verlängert sich automatisch bei einer monatlichen Mitgliedschaft um jeweils einen weiteren Monat oder bei einer jährlichen Mitgliedschaft jeweils um weitere 12 Monate, wenn nicht der Kunde vor Ablauf der Vertragslaufzeit im Kunden-Profil im Bereich Abonnement auf eine kostenfreie Silber-Mitgliedschaft umstellt. Diese Änderung auf ein Silber-Profil wird zum nächstmöglichen Ablauf der jeweiligen Vertragslaufzeit einer kostenpflichtigen Mitgliedschaft wirksam. Eine Kündigung des Silber-Profils kann der Kunde jederzeit im Kunden-Profil im Bereich „Konto“ durch Löschung des „Kontos“ durchführen. In diesem Fall bleiben die Basis-Daten auf Anwado in einem kostenfreien Basis-Profil gespeichert. Das Recht zur fristlosen Kündigung aus wichtigem Grund bleibt für beide Parteien unberührt.',
    ro: 'Perioada contractuală este de o lună sau 12 luni de la data comenzii, în funcție de abonamentul rezervat. Contractul se prelungește automat cu încă o lună, în cazul unui abonament lunar, sau cu încă 12 luni, în cazul unui abonament anual, cu excepția cazului în care clientul trece la un abonament Silver gratuit în profilul său de client din secțiunea abonament înainte de sfârșitul perioadei contractuale. Această trecere la un profil silver intră în vigoare la următoarea expirare posibilă a termenului contractual respectiv al unui abonament plătit. Clientul poate anula profilul silver în orice moment în profilul de client din zona "Cont" prin ștergerea "Contului". În acest caz, datele de bază vor rămâne stocate pe Anwado într-un profil de bază gratuit. Dreptul de reziliere fără preaviz pentru motive întemeiate rămâne neafectat pentru ambele părți.',
  },
  GTCL_Paragraph_12_Title: {
    en: 'Datenschutz',
    de: 'Datenschutz',
    ro: 'Protecția datelor',
  },
  GTCL_Paragraph_12_Description_1: {
    en: 'Soweit Kunden Inhalte (z.B. Profilbilder) über die von Anwado angebotenen Dienstleistungen einstellen, erklären sie sich mit der Speicherung, der Veröffentlichung bzw. dem öffentlichen Zugänglichmachen ihrer Inhalte im Rahmen des Angebots unter Anwado und in mobilen Angeboten (Apps) von Anwado einverstanden.',
    de: 'Soweit Kunden Inhalte (z.B. Profilbilder) über die von Anwado angebotenen Dienstleistungen einstellen, erklären sie sich mit der Speicherung, der Veröffentlichung bzw. dem öffentlichen Zugänglichmachen ihrer Inhalte im Rahmen des Angebots unter Anwado und in mobilen Angeboten (Apps) von Anwado einverstanden.',
    ro: 'În măsura în care clienții postează conținut (de exemplu, fotografii de profil) prin intermediul serviciilor oferite de Anwado, aceștia sunt de acord cu stocarea, publicarea sau punerea la dispoziția publicului a conținutului lor în cadrul ofertei de la Anwado și în ofertele mobile (aplicații) ale Anwado.',
  },
  GTCL_Paragraph_12_Description_2: {
    en: 'Ein Anspruch auf Löschung der Inhalte durch Anwado besteht grundsätzlich nicht. Die Einwilligung nach Absatz 1 gilt zeitlich unbeschränkt, soweit der Kunde Anwado keine Umstände gegenüber nachweist, die eine weitere Abrufbarkeit eines Inhalts als für die Zukunft unzumutbar erscheinen lassen (etwa wegen gravierender beruflicher Nachteile). Die von Kunden eingestellten Inhalte können teilweise auch von diesem selbst gelöscht oder geändert werden (etwa die Daten des kostenpflichtigen Profils).',
    de: 'Ein Anspruch auf Löschung der Inhalte durch Anwado besteht grundsätzlich nicht. Die Einwilligung nach Absatz 1 gilt zeitlich unbeschränkt, soweit der Kunde Anwado keine Umstände gegenüber nachweist, die eine weitere Abrufbarkeit eines Inhalts als für die Zukunft unzumutbar erscheinen lassen (etwa wegen gravierender beruflicher Nachteile). Die von Kunden eingestellten Inhalte können teilweise auch von diesem selbst gelöscht oder geändert werden (etwa die Daten des kostenpflichtigen Profils).',
    ro: 'În principiu, nu există niciun drept la ștergerea conținutului de către Anwado. Consimțământul în conformitate cu alineatul (1) este valabil pentru o perioadă nelimitată de timp, cu condiția ca clientul să nu dovedească Anwado existența unor circumstanțe care fac ca recuperarea continuă a conținutului să nu fie rezonabilă în viitor (de exemplu, din cauza unor dezavantaje profesionale grave). O parte din conținutul postat de client poate fi, de asemenea, șters sau modificat de către clientul însuși (de exemplu, datele profilului plătit).',
  },
  GTCL_Paragraph_13_Title: {
    en: 'Haftung des Kunden und Freistellung',
    de: 'Haftung des Kunden und Freistellung',
    ro: 'Răspunderea clientului și despăgubirea',
  },
  GTCL_Paragraph_13_Description_1: {
    en: 'Der Kunde stellt Anwado und dessen Erfüllungsgehilfen von allen Ansprüchen Dritter frei, die diese aufgrund eines im Zusammenhang mit Verstößen gegen in diesen AGB festgelegten Pflichten des Kunden gegenüber Anwado und dessen Erfüllungsgehilfen geltend machen. Dies umfasst auch den Ersatz der hieraus resultierenden Schäden, einschließlich der Kosten für eine angemessene Rechtsverteidigung. Der Kunde ist auch verpflichtet, Anwado bei der Verteidigung gegen vorgenannte Ansprüche durch die Abgabe von Erklärungen, insbesondere eidesstattliche Versicherungen, sowie durch sonstige Informationen zu unterstützen.',
    de: 'Der Kunde stellt Anwado und dessen Erfüllungsgehilfen von allen Ansprüchen Dritter frei, die diese aufgrund eines im Zusammenhang mit Verstößen gegen in diesen AGB festgelegten Pflichten des Kunden gegenüber Anwado und dessen Erfüllungsgehilfen geltend machen. Dies umfasst auch den Ersatz der hieraus resultierenden Schäden, einschließlich der Kosten für eine angemessene Rechtsverteidigung. Der Kunde ist auch verpflichtet, Anwado bei der Verteidigung gegen vorgenannte Ansprüche durch die Abgabe von Erklärungen, insbesondere eidesstattliche Versicherungen, sowie durch sonstige Informationen zu unterstützen.',
    ro: 'Clientul va despăgubi Anwado și agenții săi indirecți împotriva tuturor pretențiilor formulate de terți împotriva Anwado și a agenților săi indirecți în legătură cu încălcarea obligațiilor clientului prevăzute în prezentele CGC. Aceasta include, de asemenea, despăgubiri pentru orice daune rezultate, inclusiv costurile unei apărări juridice adecvate. De asemenea, clientul este obligat să sprijine Anwado în apărarea sa împotriva pretențiilor menționate mai sus prin prezentarea de declarații, în special declarații sub jurământ, și prin furnizarea de alte informații.',
  },
  GTCL_Paragraph_13_Description_2: {
    en: 'Mandatsverhältnisse über Rechtsdienstleistungen kommen unmittelbar zwischen dem Kunden als Rechtsanwalt und dem Nutzer als Mandanten zustande, auch wenn sie über Anwado vermittelt wurden. Für die anwaltliche Leistung haftet allein der Kunde. Der Kunde stellt Anwado auch von sämtlichen Ansprüchen Dritter frei, die diese gegen Anwado wegen Verletzung von Urheber-, Wettbewerbs-, Berufs-, Schadensersatz- oder sonstigen Rechten in Zusammenhang mit dem individuellen Kunden-Profil oder durch den Kunden bereitgestellte Rechtsinfos geltend machen. Dies umfasst auch die Kosten einer erforderlichen Rechtsverteidigung, die direkt oder indirekt durch die Inanspruchnahme von Anwado entstehen.',
    de: 'Mandatsverhältnisse über Rechtsdienstleistungen kommen unmittelbar zwischen dem Kunden als Rechtsanwalt und dem Nutzer als Mandanten zustande, auch wenn sie über Anwado vermittelt wurden. Für die anwaltliche Leistung haftet allein der Kunde. Der Kunde stellt Anwado auch von sämtlichen Ansprüchen Dritter frei, die diese gegen Anwado wegen Verletzung von Urheber-, Wettbewerbs-, Berufs-, Schadensersatz- oder sonstigen Rechten in Zusammenhang mit dem individuellen Kunden-Profil oder durch den Kunden bereitgestellte Rechtsinfos geltend machen. Dies umfasst auch die Kosten einer erforderlichen Rechtsverteidigung, die direkt oder indirekt durch die Inanspruchnahme von Anwado entstehen.',
    ro: 'Relațiile de clientelă privind serviciile juridice se stabilesc direct între client în calitate de avocat și utilizator în calitate de client, chiar dacă acestea au fost aranjate prin intermediul Anwado. Clientul este singurul răspunzător pentru serviciile juridice furnizate. De asemenea, clientul despăgubește Anwado de toate pretențiile formulate de terți împotriva Anwado pentru încălcarea drepturilor de autor, a drepturilor de concurență, a drepturilor profesionale, a drepturilor de despăgubire sau a altor drepturi în legătură cu profilul individual al clientului sau cu informațiile juridice furnizate de client. Aceasta include, de asemenea, costurile oricărei apărări juridice necesare, suportate direct sau indirect ca urmare a pretențiilor formulate împotriva Anwado.',
  },
  GTCL_Paragraph_14_Title: {
    en: 'Haftung und Gewährleistung',
    de: 'Haftung und Gewährleistung',
    ro: 'Răspundere și garanție',
  },
  GTCL_Paragraph_14_Description_1: {
    en: `Die Haftung von Anwado wird, gleich aus welchem Rechtsgrund, für durch den Kunden, seine gesetzlichen Vertreter oder seine jeweiligen Erfüllungsgehilfen verursachte Schäden – vorbehaltlich Absatz 2 – wie folgt beschränkt:
‍
Bei leicht fahrlässiger Verletzung wesentlicher Vertragspflichten haftet Anwado der Höhe nach begrenzt auf die bei Vertragsschluss vorhersehbaren, vertragstypischen Schäden. Wesentliche Vertragspflichten sind solche, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf. Anwado haftet nicht bei leicht fahrlässiger Verletzung nicht wesentlicher Pflichten aus dem Schuldverhältnis.

    `,
    de: `Die Haftung von Anwado wird, gleich aus welchem Rechtsgrund, für durch den Kunden, seine gesetzlichen Vertreter oder seine jeweiligen Erfüllungsgehilfen verursachte Schäden – vorbehaltlich Absatz 2 – wie folgt beschränkt:
‍
Bei leicht fahrlässiger Verletzung wesentlicher Vertragspflichten haftet Anwado der Höhe nach begrenzt auf die bei Vertragsschluss vorhersehbaren, vertragstypischen Schäden. Wesentliche Vertragspflichten sind solche, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf. Anwado haftet nicht bei leicht fahrlässiger Verletzung nicht wesentlicher Pflichten aus dem Schuldverhältnis.

    `,
  },
  GTCL_Paragraph_14_Description_2: {
    en: 'Die vorstehenden Haftungsbeschränkungen gelten nicht bei Vorsatz oder grober Fahrlässigkeit sowie in Fällen zwingender gesetzlicher Haftung, insbesondere bei der Übernahme einer Garantie oder der Verletzung des Lebens, des Körpers oder der Gesundheit.',
    de: 'Die vorstehenden Haftungsbeschränkungen gelten nicht bei Vorsatz oder grober Fahrlässigkeit sowie in Fällen zwingender gesetzlicher Haftung, insbesondere bei der Übernahme einer Garantie oder der Verletzung des Lebens, des Körpers oder der Gesundheit.',
    ro: 'Limitările de răspundere de mai sus nu se aplică în caz de intenție sau de neglijență gravă sau în cazurile de răspundere legală obligatorie, în special în cazul asumării unei garanții sau al vătămării vieții, integrității corporale sau sănătății.',
  },
  GTCL_Paragraph_14_Description_3: {
    en: 'Soweit eine Haftung von Anwado beschränkt oder ausgeschlossen ist, so gilt dies auch für die Mitarbeiter, die gesetzlichen Vertreter, die Dienstleister und die sonstigen Erfüllungsgehilfen.',
    de: 'Soweit eine Haftung von Anwado beschränkt oder ausgeschlossen ist, so gilt dies auch für die Mitarbeiter, die gesetzlichen Vertreter, die Dienstleister und die sonstigen Erfüllungsgehilfen.',
    ro: 'În măsura în care răspunderea Anwado este limitată sau exclusă, acest lucru se aplică și angajaților, reprezentanților legali, furnizorilor de servicii și altor agenți indirecti ai acesteia.',
  },
  GTCL_Paragraph_14_Description_4: {
    en: 'Soweit Anwado nach Abs. 1 und 2 für Datenverluste haftet, ist diese Haftung – außer im Falle des Vorsatzes und der groben Fahrlässigkeit - auf den Verlust solcher Daten beschränkt, die der Kunde in verkehrsüblicher Weise (mindestens einmal täglich) so gesichert hat, dass er sie mit vertretbarem Aufwand reproduzieren kann. Der Einwand des Mitverschuldens bleibt stets zulässig.',
    de: 'Soweit Anwado nach Abs. 1 und 2 für Datenverluste haftet, ist diese Haftung – außer im Falle des Vorsatzes und der groben Fahrlässigkeit - auf den Verlust solcher Daten beschränkt, die der Kunde in verkehrsüblicher Weise (mindestens einmal täglich) so gesichert hat, dass er sie mit vertretbarem Aufwand reproduzieren kann. Der Einwand des Mitverschuldens bleibt stets zulässig.',
    ro: 'În măsura în care Anwado este răspunzătoare pentru pierderea de date în conformitate cu alineatele (1) și (2), această răspundere este limitată - cu excepția cazurilor de intenție și neglijență gravă - la pierderea datelor pe care clientul le-a salvat în mod obișnuit (cel puțin o dată pe zi), astfel încât să le poată reproduce cu un efort rezonabil. Obiecția de neglijență indirectă rămâne întotdeauna admisibilă.',
  },
  GTCL_Paragraph_14_Description_5: {
    en: 'Anwado steht nicht für die Nichterfüllung vertraglicher Pflichten ein, soweit diese auf einem außerhalb des Einflussbereichs von Anwado liegenden Grund (Naturkatastrophen, Krieg, Ein- und Ausfuhrsperren, Hindernisse im Verantwortungsbereich des Kunden etc.) beruht.',
    de: 'Anwado steht nicht für die Nichterfüllung vertraglicher Pflichten ein, soweit diese auf einem außerhalb des Einflussbereichs von Anwado liegenden Grund (Naturkatastrophen, Krieg, Ein- und Ausfuhrsperren, Hindernisse im Verantwortungsbereich des Kunden etc.) beruht.',
    ro: 'Anwado nu este răspunzătoare pentru neîndeplinirea obligațiilor contractuale în măsura în care aceasta se datorează unei cauze independente de voința Anwado (dezastre naturale, război, embargouri la import și export, obstacole în sfera de responsabilitate a clientului etc.).',
  },
  GTCL_Paragraph_14_Description_6_1: {
    en: 'Anwado trifft alle zumutbaren technischen und personellen Vorkehrungen, die Überlastung oder Ausfall der Server oder des Systems weitestgehend ausschließen. Eine 100%ige Erreichbarkeit kann jedoch nicht gewährleistet werden. Im Fall einer vorübergehenden Nichterreichbarkeit der Webseite ',
    de: 'Anwado trifft alle zumutbaren technischen und personellen Vorkehrungen, die Überlastung oder Ausfall der Server oder des Systems weitestgehend ausschließen. Eine 100%ige Erreichbarkeit kann jedoch nicht gewährleistet werden. Im Fall einer vorübergehenden Nichterreichbarkeit der Webseite ',
    ro: 'Anwado va lua toate măsurile de precauție rezonabile din punct de vedere tehnic și al personalului pentru a preveni, pe cât posibil, supraîncărcarea sau defectarea serverelor sau a sistemului. Cu toate acestea, nu se poate garanta o disponibilitate de 100%. În cazul unei inaccesibilități temporare a site-ului web',
  },
  GTCL_Paragraph_14_Description_6_2: {
    en: 'www.anwado.de',
    de: 'www.anwado.de',
    ro: 'www.anwado.de',
  },
  GTCL_Paragraph_14_Description_6_3: {
    en: ' und/oder des Kunden-Profils bemüht sich Anwado um unverzügliche Problembeseitigung. Gewährleistungs- und/oder Schadensersatzansprüche aufgrund solcher Ausfälle sind ausgeschlossen.',
    de: ' und/oder des Kunden-Profils bemüht sich Anwado um unverzügliche Problembeseitigung. Gewährleistungs- und/oder Schadensersatzansprüche aufgrund solcher Ausfälle sind ausgeschlossen.',
    ro: ' și/sau profilul clientului, Anwado va încerca să remedieze problema fără întârziere. Cererile de garanție și/sau cererile de despăgubiri datorate unor astfel de defecțiuni sunt excluse.',
  },
  GTCL_Paragraph_15_Title: {
    en: 'Beweis und Beweislast',
    de: 'Beweis und Beweislast',
    ro: 'Dovada și sarcina probei',
  },
  GTCL_Paragraph_15_Description_1: {
    en: `Daten, die in elektronischen Registern oder anderweitig in elektronischer Form bei Anwado gespeichert sind, gelten als zulässiges Beweismittel für den Nachweis von Datenübertragungen, Verträgen und ausgeführten Zahlungen zwischen den Parteien.`,
    de: `Daten, die in elektronischen Registern oder anderweitig in elektronischer Form bei Anwado gespeichert sind, gelten als zulässiges Beweismittel für den Nachweis von Datenübertragungen, Verträgen und ausgeführten Zahlungen zwischen den Parteien.`,
  },
  GTCL_Paragraph_15_Description_2: {
    en: 'Beruft sich der Kunde im Rahmen des Vertragsschlusses auf einen Missbrauch seiner Identität, so hat er Anwado unverzüglich alle ihm bekannten Tatsachen und Indizien hierfür mitzuteilen. Im Falle eines Verstoßes gegen diese Obliegenheit trägt der Kunde die Beweislast für das Vorliegen eines Identitätsmissbrauchs, sofern hinreichende Anhaltspunkte dafür vorliegen, dass der Kunde und nicht ein Dritter gehandelt hat.‍',
    de: 'Beruft sich der Kunde im Rahmen des Vertragsschlusses auf einen Missbrauch seiner Identität, so hat er Anwado unverzüglich alle ihm bekannten Tatsachen und Indizien hierfür mitzuteilen. Im Falle eines Verstoßes gegen diese Obliegenheit trägt der Kunde die Beweislast für das Vorliegen eines Identitätsmissbrauchs, sofern hinreichende Anhaltspunkte dafür vorliegen, dass der Kunde und nicht ein Dritter gehandelt hat.‍',
    ro: 'În cazul în care, în cursul încheierii contractului, clientul pretinde că identitatea sa a fost folosită în mod abuziv, acesta trebuie să informeze imediat Anwado despre toate faptele și indiciile de care are cunoștință în acest sens. În cazul unei încălcări a acestei obligații, clientul poartă sarcina probei pentru existența unui abuz de identitate, cu condiția să existe suficiente indicii că a acționat clientul și nu o terță parte.‍',
  },
  GTCL_Paragraph_16_Title: {
    en: 'Schlussvorschriften',
    de: 'Schlussvorschriften',
    ro: 'Dispoziții finale',
  },
  GTCL_Paragraph_16_Description_1: {
    en: `Diese Vereinbarung unterliegt dem Recht der Bundesrepublik Deutschland mit Ausnahme des Internationalen Privatrechts und des UN-Kaufrechts.`,
    de: `Diese Vereinbarung unterliegt dem Recht der Bundesrepublik Deutschland mit Ausnahme des Internationalen Privatrechts und des UN-Kaufrechts.`,
  },
  GTCL_Paragraph_16_Description_2: {
    en: 'Gerichtsstand für sämtliche Ansprüche sowie Streitigkeiten aus und im Zusammenhang mit diesem Vertrag ist München.',
    de: 'Gerichtsstand für sämtliche Ansprüche sowie Streitigkeiten aus und im Zusammenhang mit diesem Vertrag ist München.',
    ro: 'Locul de jurisdicție pentru toate cererile și litigiile care decurg din și în legătură cu acest contract este München.',
  },
  GTCL_Paragraph_16_Description_3: {
    en: 'Kunden dürfen gegenüber Forderungen von Anwado weder aufrechnen noch Zurückbehaltungsrechte geltend machen, es sei denn, es handelt sich um unbestrittene oder rechtskräftig festgestellte Forderungen des Kunden.',
    de: 'Kunden dürfen gegenüber Forderungen von Anwado weder aufrechnen noch Zurückbehaltungsrechte geltend machen, es sei denn, es handelt sich um unbestrittene oder rechtskräftig festgestellte Forderungen des Kunden.',
    ro: 'Clienții nu pot nici să compenseze, nici să exercite drepturi de retenție împotriva creanțelor Anwado, cu excepția cazului în care creanțele clientului sunt necontestate sau au fost stabilite de o instanță de judecată.',
  },
  GTCL_Paragraph_16_Description_4: {
    en: 'Sollte eine der Bestimmungen dieses Vertrages unwirksam sein oder werden, so berührt dies die Wirksamkeit des Vertrages im Übrigen nicht. Änderungen, Ergänzungen und Aufhebung des Vertrages bedürfen der Schriftform, was auch für die Abbedingung des Schriftformerfordernisses gilt. Soweit einzelne Bestimmungen dieser AGB ganz oder teilweise unwirksam sind, wird die Wirksamkeit des Vertrages sowie der übrigen Bestimmungen hiervon nicht berührt. Anstelle der unwirksamen Bestimmung ist eine Bestimmung zu vereinbaren, die dem Sinn und Zweck der weggefallenen Bestimmung am nächsten kommt.',
    de: 'Sollte eine der Bestimmungen dieses Vertrages unwirksam sein oder werden, so berührt dies die Wirksamkeit des Vertrages im Übrigen nicht. Änderungen, Ergänzungen und Aufhebung des Vertrages bedürfen der Schriftform, was auch für die Abbedingung des Schriftformerfordernisses gilt. Soweit einzelne Bestimmungen dieser AGB ganz oder teilweise unwirksam sind, wird die Wirksamkeit des Vertrages sowie der übrigen Bestimmungen hiervon nicht berührt. Anstelle der unwirksamen Bestimmung ist eine Bestimmung zu vereinbaren, die dem Sinn und Zweck der weggefallenen Bestimmung am nächsten kommt.',
    ro: 'În cazul în care una dintre dispozițiile prezentului contract este sau devine nulă, acest lucru nu va afecta valabilitatea celorlalte dispoziții ale contractului. Modificările, completările și rezilierea contractului trebuie să se facă în scris, ceea ce se aplică și în cazul renunțării la cerința formei scrise. În măsura în care anumite dispoziții individuale din prezentele CGC sunt invalide în totalitate sau parțial, acest lucru nu afectează valabilitatea contractului sau a celorlalte dispoziții. În locul dispoziției nevalabile, se va conveni o dispoziție care se apropie cât mai mult posibil de sensul și scopul dispoziției nevalabile.',
  },
  // Anwado Search Page
  ASP_Title: {
    en: 'Die Anwado-Suche',
    de: 'Die Anwado-Suche',
    ro: 'Căutarea Anwado',
  },
  ASP_MetaDecorator_Title: {
    en: 'Find a Lawyer | Comprehensive Legal Professional Search',
    de: 'Finden Sie einen Anwalt | Umfassende Suche nach juristischen Fachkräften',
    ro: 'Găsiți un avocat | Căutare profesională cuprinzătoare în domeniul juridic',
  },
  ASP_MetaDecorator_Description: {
    en: 'Explore our extensive database of legal professionals and find the perfect lawyer to meet your needs. Search by location, expertise, and more.',
    de: 'Erkunden Sie unsere umfangreiche Datenbank mit Rechtsexperten und finden Sie den perfekten Anwalt, der Ihren Anforderungen entspricht. Suchen Sie nach Standort, Fachwissen und mehr.',
    ro: 'Explorați baza noastră extinsă de profesioniști din domeniul juridic și găsiți avocatul perfect pentru a vă satisface nevoile. Căutați după locație, expertiză și multe altele.',
  },
  ASP_Description_1: {
    en: 'Anwado ist eine benutzerfreundliche Plattform, die Nutzern hilft, den besten Anwalt für ihre Bedürfnisse zu finden. Um den Nutzern die bestmöglichen Ergebnisse zu präsentieren, haben wir eine spezielle Sortierlogik entwickelt, die bei der Entscheidungsfindung unterstützt. Die Suchanfragen können nach Namen des Anwalts/Kanzlei, Rechtsgebieten oder Ort erfolgen. Bei dem Ort können die Nutzer wählen, ob eine Suche nach der Entfernung zum Nutzer ausgeführt werden soll.',
    de: 'Anwado ist eine benutzerfreundliche Plattform, die Nutzern hilft, den besten Anwalt für ihre Bedürfnisse zu finden. Um den Nutzern die bestmöglichen Ergebnisse zu präsentieren, haben wir eine spezielle Sortierlogik entwickelt, die bei der Entscheidungsfindung unterstützt. Die Suchanfragen können nach Namen des Anwalts/Kanzlei, Rechtsgebieten oder Ort erfolgen. Bei dem Ort können die Nutzer wählen, ob eine Suche nach der Entfernung zum Nutzer ausgeführt werden soll.',
    ro: 'Anwado este o platformă ușor de utilizat care îi ajută pe utilizatori să găsească cel mai bun avocat pentru nevoile lor. Pentru a le prezenta utilizatorilor cele mai bune rezultate posibile, am dezvoltat o logică specială de sortare pentru a-i ajuta în procesul de luare a deciziilor. Căutările pot fi făcute după numele avocatului/societății de avocatură, domeniile de drept sau locație. Pentru locație, utilizatorii pot alege să caute în funcție de distanța față de utilizator.',
  },
  ASP_Description_2: {
    en: 'Nachdem die Suchanfrage durchgeführt wurde, können Nutzer diese mit Hilfe von Filtern, die seitlich auf der Ergebnisseite angezeigt werden, verfeinern, wie z. B. verfügbare Online-Terminvereinbarung, Vorhandensein einer Fachanwaltschaft, Höhe des Bewertungsdurchschnitts und Anzahl der Bewertungen.',
    de: 'Nachdem die Suchanfrage durchgeführt wurde, können Nutzer diese mit Hilfe von Filtern, die seitlich auf der Ergebnisseite angezeigt werden, verfeinern, wie z. B. verfügbare Online-Terminvereinbarung, Vorhandensein einer Fachanwaltschaft, Höhe des Bewertungsdurchschnitts und Anzahl der Bewertungen.',
    ro: 'Odată ce a fost efectuată interogarea de căutare, utilizatorii o pot rafina cu ajutorul filtrelor afișate pe partea laterală a paginii de rezultate, cum ar fi programarea online disponibilă, prezența unui bar de specialitate, nivelul mediei de evaluare și numărul de evaluări.',
  },
  ASP_Description_3: {
    en: 'Anwado ist so konzipiert, dass es Nutzern die Möglichkeit gibt, ihre Entscheidungen selbständig und nach ihren Bedürfnissen zu treffen. Aus diesem Grund sind die Suchergebnisse und das Ranking der Anwälte für jede Anfrage individuell erzeugt.',
    de: 'Anwado ist so konzipiert, dass es Nutzern die Möglichkeit gibt, ihre Entscheidungen selbständig und nach ihren Bedürfnissen zu treffen. Aus diesem Grund sind die Suchergebnisse und das Ranking der Anwälte für jede Anfrage individuell erzeugt.',
    ro: 'Anwado este conceput pentru a le permite utilizatorilor să ia decizii în mod independent și în funcție de nevoile lor. Din acest motiv, rezultatele căutării și clasamentul avocaților sunt generate individual pentru fiecare interogare.',
  },
  ASP_Description_4: {
    en: 'Anwado berücksichtigt dabei eine Vielzahl von Faktoren, die sich an der Gesamt-Relevanz für Nutzer orientieren. Deshalb basiert unsere Sortierlogik auf dem Anwado Relevance Score, einer Kombination aus verschiedenen Faktoren, die die Relevanz und Attraktivität eines Anwaltsprofils für die Nutzer bestimmen. Dazu gehören insbesondere die Anzahl und der Durchschnitt der Bewertungen und beispielsweise Profilinformationen wie das Vorhandensein eines Profilbilds, eine individuelle Kurzbeschreibung, Informationen über den Anwalt und veröffentlichte Artikel sowie die Verfügbarkeit von Online-Terminen.',
    de: 'Anwado berücksichtigt dabei eine Vielzahl von Faktoren, die sich an der Gesamt-Relevanz für Nutzer orientieren. Deshalb basiert unsere Sortierlogik auf dem Anwado Relevance Score, einer Kombination aus verschiedenen Faktoren, die die Relevanz und Attraktivität eines Anwaltsprofils für die Nutzer bestimmen. Dazu gehören insbesondere die Anzahl und der Durchschnitt der Bewertungen und beispielsweise Profilinformationen wie das Vorhandensein eines Profilbilds, eine individuelle Kurzbeschreibung, Informationen über den Anwalt und veröffentlichte Artikel sowie die Verfügbarkeit von Online-Terminen.',
    ro: 'Anwado ia în considerare o varietate de factori în funcție de relevanța generală pentru utilizatori. Prin urmare, logica noastră de sortare se bazează pe Anwado Relevance Score, o combinație de diverși factori care determină relevanța și atractivitatea unui profil de avocat pentru utilizatori. Aceștia includ, în special, numărul și media evaluărilor și, de exemplu, informații despre profil, cum ar fi prezența unei fotografii de profil, o scurtă descriere individuală, informații despre avocat și articole publicate, precum și disponibilitatea de programări online.',
  },
  ASP_Description_5: {
    en: 'Um das Ranking Ihres Anwaltsprofils auf Anwado zu verbessern, empfehlen wir folgende allgemeine Tipps: Sammeln Sie positive Bewertungen von zufriedenen Mandanten. Gute Bewertungen zeigen anderen Nutzern, dass Sie vertrauenswürdig und kompetent sind.',
    de: 'Um das Ranking Ihres Anwaltsprofils auf Anwado zu verbessern, empfehlen wir folgende allgemeine Tipps: Sammeln Sie positive Bewertungen von zufriedenen Mandanten. Gute Bewertungen zeigen anderen Nutzern, dass Sie vertrauenswürdig und kompetent sind.',
    ro: 'Pentru a îmbunătăți clasamentul profilului dvs. de avocat pe Anwado, vă recomandăm următoarele sfaturi generale: Colectați recenzii pozitive de la clienți mulțumiți. Recenziile bune arată celorlalți utilizatori că sunteți de încredere și competent.',
  },
  ASP_Description_6: {
    en: 'Stellen Sie sicher, dass Ihr Profilbild professionell und ansprechend ist. Ein gutes Profilbild hilft dabei, das Vertrauen der Nutzer zu gewinnen und Ihr Profil hervorzuheben.',
    de: 'Stellen Sie sicher, dass Ihr Profilbild professionell und ansprechend ist. Ein gutes Profilbild hilft dabei, das Vertrauen der Nutzer zu gewinnen und Ihr Profil hervorzuheben.',
    ro: 'Asigurați-vă că fotografia de profil este profesională și atrăgătoare. O imagine de profil bună ajută la câștigarea încrederii utilizatorilor și face ca profilul dvs. să iasă în evidență.',
  },
  ASP_Description_7: {
    en: 'Achten Sie darauf, eine aussagekräftige und präzise individuelle Kurzbeschreibung zu erstellen. Dies ermöglicht es den Nutzern, schnell zu erkennen, welche Dienstleistungen Sie anbieten und welche Erfahrungen Sie mitbringen.',
    de: 'Achten Sie darauf, eine aussagekräftige und präzise individuelle Kurzbeschreibung zu erstellen. Dies ermöglicht es den Nutzern, schnell zu erkennen, welche Dienstleistungen Sie anbieten und welche Erfahrungen Sie mitbringen.',
    ro: 'Asigurați-vă că creați o scurtă descriere individuală semnificativă și precisă. Acest lucru le permite utilizatorilor să identifice rapid ce servicii oferiți și ce experiență puneți la dispoziție.',
  },
  ASP_Description_8: {
    en: 'Füllen Sie die Informationen über sich und Ihre Kanzlei vollständig aus. Dies gibt den Nutzern einen umfassenden Einblick in Ihre Expertise, Ihre Arbeitsweise und den Umfang Ihrer Leistungen.',
    de: 'Füllen Sie die Informationen über sich und Ihre Kanzlei vollständig aus. Dies gibt den Nutzern einen umfassenden Einblick in Ihre Expertise, Ihre Arbeitsweise und den Umfang Ihrer Leistungen.',
    ro: 'Completați complet informațiile despre dvs. și despre cabinetul dvs. de avocatură. Astfel, utilizatorii vor avea o imagine cuprinzătoare a expertizei dumneavoastră, a metodelor de lucru și a domeniului de aplicare a serviciilor dumneavoastră.',
  },
  ASP_Description_9: {
    en: 'Veröffentlichen Sie regelmäßig Artikel, um Ihre Fachkenntnisse zu demonstrieren und Ihren Anwado Relevance Score zu erhöhen.',
    de: 'Veröffentlichen Sie regelmäßig Artikel, um Ihre Fachkenntnisse zu demonstrieren und Ihren Anwado Relevance Score zu erhöhen.',
    ro: 'Publicați articole în mod regulat pentru a vă demonstra expertiza și pentru a vă crește scorul de relevanță Anwado.',
  },
  ASP_Description_10: {
    en: 'Stellen Sie sicher, dass Sie immer verfügbare Termine haben. Eine hohe Verfügbarkeit erleichtert es den Nutzern, mit Ihnen in Kontakt zu treten und Ihre Dienstleistungen in Anspruch zu nehmen.',
    de: 'Stellen Sie sicher, dass Sie immer verfügbare Termine haben. Eine hohe Verfügbarkeit erleichtert es den Nutzern, mit Ihnen in Kontakt zu treten und Ihre Dienstleistungen in Anspruch zu nehmen.',
    ro: 'Asigurați-vă că aveți întotdeauna întâlniri disponibile. O disponibilitate ridicată face ca utilizatorii să vă contacteze mai ușor și să vă folosească serviciile.',
  },
  ASP_Description_11: {
    en: 'Unsere Kunden können zwischen den folgenden Profilen wählen:',
    de: 'Unsere Kunden können zwischen den folgenden Profilen wählen:',
    ro: 'Clienții noștri pot alege între următoarele profiluri:',
  },
  ASP_Paragraph_1_Title: {
    en: 'Nicht-Kundenprofil: ',
    de: 'Nicht-Kundenprofil: ',
    ro: 'Profilul non-client:',
  },
  ASP_Paragraph_1_Description: {
    en: 'Diese Art von Profil wird mit den Daten eines Adressdatenanbieters erstellt. Es handelt sich um öffentlich verfügbare Daten. Wir erstellen diese Profile ohne die aktive Mitwirkung von Anwälten. Die jeweilige Person kann es für sich beanspruchen und daraus ein verifiziertes kostenfreies Silber-Profil erstellen. Das Nicht-Kundenprofil ist fester Bestandteil des rechtlichen Konstrukts von Anwado als neutrale Informationsplattform in Deutschland. Anwälte können dieses Profil nicht löschen lassen.',
    de: 'Diese Art von Profil wird mit den Daten eines Adressdatenanbieters erstellt. Es handelt sich um öffentlich verfügbare Daten. Wir erstellen diese Profile ohne die aktive Mitwirkung von Anwälten. Die jeweilige Person kann es für sich beanspruchen und daraus ein verifiziertes kostenfreies Silber-Profil erstellen. Das Nicht-Kundenprofil ist fester Bestandteil des rechtlichen Konstrukts von Anwado als neutrale Informationsplattform in Deutschland. Anwälte können dieses Profil nicht löschen lassen.',
    ro: 'Acest tip de profil este creat cu date de la un furnizor de date de adrese. Este vorba de date disponibile publicului. Noi creăm aceste profiluri fără implicarea activă a avocaților. Persoana respectivă îl poate revendica pentru sine și poate crea un profil de argint gratuit verificat din acesta. Profilul non-client este parte integrantă a construcției juridice a Anwado ca platformă de informare neutră în Germania. Avocaților nu li se poate șterge acest profil.',
  },
  ASP_Paragraph_2_Title: {
    en: 'Silber-Profil: ',
    de: 'Silber-Profil: ',
    ro: 'Profil de argint:',
  },
  ASP_Paragraph_2_Description: {
    en: 'Dies ist ein kostenloses Konto, mit dem Anwälte ihr eigenes Profil erstellen und grundlegende Angaben in ihrem Profil korrigieren können. Um ein Silber-Profil zu erhalten, können Anwälte sich kostenfrei auf Anwado registrieren.',
    de: 'Dies ist ein kostenloses Konto, mit dem Anwälte ihr eigenes Profil erstellen und grundlegende Angaben in ihrem Profil korrigieren können. Um ein Silber-Profil zu erhalten, können Anwälte sich kostenfrei auf Anwado registrieren.',
    ro: 'Acesta este un cont gratuit care permite avocaților să își creeze propriul profil și să corecteze informațiile de bază din profilul lor. Pentru a obține un profil argintiu, avocații se pot înregistra gratuit pe Anwado.',
  },
  ASP_Paragraph_3_Title: {
    en: 'Gold-Profil: ',
    de: 'Gold-Profil: ',
    ro: 'Profilul aurului:',
  },
  ASP_Paragraph_3_Description: {
    en: 'Das kostenpflichtige Gold-Profil bietet Zugang zu mehr Dienstleistungen und Funktionen, die es den Anwälten ermöglichen, sichtbarer zu werden, indem sie ihr Profil mit Fotos, Videos und anreichern können sowie Online-Termine für die Nutzer anbieten können.',
    de: 'Das kostenpflichtige Gold-Profil bietet Zugang zu mehr Dienstleistungen und Funktionen, die es den Anwälten ermöglichen, sichtbarer zu werden, indem sie ihr Profil mit Fotos, Videos und anreichern können sowie Online-Termine für die Nutzer anbieten können.',
    ro: 'Profilul Gold cu plată oferă acces la mai multe servicii și caracteristici care permit avocaților să devină mai vizibili, putând să-și îmbogățească profilul cu fotografii, videoclipuri și să ofere întâlniri online pentru utilizatori.',
  },
  ASP_Paragraph_4_Title: {
    en: 'Platin-Profil: ',
    de: 'Platin-Profil: ',
    ro: 'Profil Platinum:',
  },
  ASP_Paragraph_4_Description: {
    en: 'Das kostenpflichtige Platin-Profils ermöglicht eine höhere Listung in den Suchergebnis, die deutlich als bezahltes Ergebnis („Anzeige“) gekennzeichnet ist.',
    de: 'Das kostenpflichtige Platin-Profils ermöglicht eine höhere Listung in den Suchergebnis, die deutlich als bezahltes Ergebnis („Anzeige“) gekennzeichnet ist.',
    ro: 'Profilul platinum plătit permite o listare mai mare în rezultatele căutării, care este marcată în mod clar ca fiind un rezultat plătit ("anunț").',
  },
  // Legal Notice
  LN_Meta_Title: {
    en: 'Anwado - Legal Notice',
    de: 'Anwado - Impressum',
    ro: 'Anwado - amprentă',
  },
  LN_Meta_Description: {
    en: `Find out about our legal information according to § 5 TMG in Anwado's Legal Notice.`,
    de: 'Informieren Sie sich über unsere gesetzlichen Angaben gemäß § 5 TMG im Impressum von Anwado.',
    ro: 'Aflați despre informațiile noastre juridice în conformitate cu § 5 TMG, în cadrul Anwado.',
  },
  LN_Date: {
    en: 'Stand Dezember 2023',
    de: 'Stand Dezember 2023',
    ro: 'Stand Dezember 2023',
  },
  LN_Title: {
    en: 'Imprint',
    de: 'Impressum',
    ro: 'Imprimare',
  },
  LN_Description: {
    en: 'Angaben Gemäss § 5 TMG',
    de: 'Angaben Gemäss § 5 TMG',
    ro: 'Informații în conformitate cu § 5 TMG',
  },
  LN_Description_1: {
    en: `Anwado GmbH
Gartenstadtstrasse 10 
81825 München
    
E-Mail: support@Anwado.de
Tel: 089-452426920
Sitz: München
Registergericht: Amtsgericht München
HRB 276438
USt-IdNr.: DE354112063
    
Geschäftsführer: Claudia Schlet, Dr. Maximilian Pühler
    
    
Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV
Claudia Schlet, Dr. Maximilian Pühler
Gartenstadtstrasse 10
81825 München
    `,
    de: `Anwado GmbH
Gartenstadtstrasse 10 
81825 München

E-Mail: support@Anwado.de
Tel: 089-452426920
Sitz: München
Registergericht: Amtsgericht München
HRB 276438
USt-IdNr.: DE354112063

Geschäftsführer: Claudia Schlet, Dr. Maximilian Pühler


Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV
Claudia Schlet, Dr. Maximilian Pühler
Gartenstadtstrasse 10
81825 München
    `,
  },
  LN_Title_2: {
    en: 'Online dispute resolution',
    de: 'Online-Streitbeilegung',
    ro: 'Soluționarea disputelor online',
  },
  LN_Description_2_1: {
    en: `Die Europäische Kommission stellt eine Internet-Plattform zur Online-Streitbeilegung (OS) bereit: `,
    de: `Die Europäische Kommission stellt eine Internet-Plattform zur Online-Streitbeilegung (OS) bereit: `,
  },
  LN_Description_2_2: {
    en: `Die Anwado GmbH ist nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
    
Die vorliegenden Angaben sind auch im Zusammenhang mit öffentlichen Auftritten der Anwado GmbH in sozialen Medien (z.B. Facebook, Twitter, etc.) anwendbar.`,
    de: `Die Anwado GmbH ist nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
    
Die vorliegenden Angaben sind auch im Zusammenhang mit öffentlichen Auftritten der Anwado GmbH in sozialen Medien (z.B. Facebook, Twitter, etc.) anwendbar.`,
  },
  LN_Title_3: {
    en: 'Rechtshinweis',
    de: 'Rechtshinweis',
    ro: 'Aviz juridic',
  },
  LN_Description_3: {
    en: `Die Anwado GmbH prüft und aktualisiert die Informationen auf ihren Webseiten ständig. Trotz aller Sorgfalt können sich die Daten inzwischen verändert haben. Eine Haftung oder Garantie für die Aktualität, Richtigkeit und Vollständigkeit der zur Verfügung gestellten Informationen kann daher nicht übernommen werden. Gleiches gilt auch für alle anderen Webseiten, auf die mittels Hyperlink verwiesen wird. Die Anwado GmbH ist für den Inhalt der Webseiten, die aufgrund einer solchen Verbindung erreicht werden, nicht verantwortlich. Zudem behält sich die Anwado GmbH das Recht vor, Änderungen oder Ergänzungen der bereitgestellten Informationen vorzunehmen.`,
    de: 'Die Anwado GmbH prüft und aktualisiert die Informationen auf ihren Webseiten ständig. Trotz aller Sorgfalt können sich die Daten inzwischen verändert haben. Eine Haftung oder Garantie für die Aktualität, Richtigkeit und Vollständigkeit der zur Verfügung gestellten Informationen kann daher nicht übernommen werden. Gleiches gilt auch für alle anderen Webseiten, auf die mittels Hyperlink verwiesen wird. Die Anwado GmbH ist für den Inhalt der Webseiten, die aufgrund einer solchen Verbindung erreicht werden, nicht verantwortlich. Zudem behält sich die Anwado GmbH das Recht vor, Änderungen oder Ergänzungen der bereitgestellten Informationen vorzunehmen.',
    ro: 'Anwado GmbH verifică și actualizează în mod constant informațiile de pe site-urile sale. În ciuda grijii cuvenite, este posibil ca datele să se fi modificat între timp. Prin urmare, nu poate fi asumată nicio răspundere sau garanție pentru actualitatea, corectitudinea și caracterul complet al informațiilor furnizate. Același lucru este valabil și pentru toate celelalte site-uri web la care se face trimitere prin hyperlink. Anwado GmbH nu este responsabilă pentru conținutul site-urilor web care pot fi accesate prin intermediul unor astfel de link-uri. În plus, Anwado GmbH își rezervă dreptul de a aduce modificări sau completări la informațiile furnizate.',
  },
  LN_Title_4: {
    en: 'Anwaltsverzeichnis und verlinkte Webseiten:',
    de: 'Anwaltsverzeichnis und verlinkte Webseiten:',
    ro: 'Anuar de avocați și site-uri legate:',
  },
  LN_Description_4: {
    en: `Das Anwaltsverzeichnis wird aus öffentlichen Quellen sowie aus Selbstauskünften der Anwälte zusammengestellt. Für die Richtigkeit der Fremddaten kann trotz sorgfältiger Prüfung keine Gewähr übernommen werden. Unsere Website enthält auch Links auf externe Webseiten Dritter (insbesondere von Anwälten und Kanzleien). Auf den Inhalt dieser verlinkten Seiten hat die Anwado GmbH keinen Einfluss. Deshalb kann die Anwado GmbH für diese externen Links keine Gewähr für deren Richtigkeit übernehmen. Für die Inhalte der verlinkten Seiten sind die jeweiligen Anbieter oder Betreiber (Verfasser) der Seiten verantwortlich.`,
    de: `Das Anwaltsverzeichnis wird aus öffentlichen Quellen sowie aus Selbstauskünften der Anwälte zusammengestellt. Für die Richtigkeit der Fremddaten kann trotz sorgfältiger Prüfung keine Gewähr übernommen werden. Unsere Website enthält auch Links auf externe Webseiten Dritter (insbesondere von Anwälten und Kanzleien). Auf den Inhalt dieser verlinkten Seiten hat die Anwado GmbH keinen Einfluss. Deshalb kann die Anwado GmbH für diese externen Links keine Gewähr für deren Richtigkeit übernehmen. Für die Inhalte der verlinkten Seiten sind die jeweiligen Anbieter oder Betreiber (Verfasser) der Seiten verantwortlich.`,
  },
  LN_Title_5: {
    en: 'Urheberrechte',
    de: 'Urheberrechte',
    ro: 'Drepturi de autor',
  },
  LN_Description_5: {
    en: `Alle Inhalte (Texte, Videos, Bilder sowie deren Anordnung, Layout, Schrift- und Farbgestaltung) auf den Webseiten der Anwado GmbH unterliegen dem Schutz des Urheberrechts und anderer Schutzgesetze. Der Inhalt dieser Websites darf nicht zu kommerziellen Zwecken kopiert, verbreitet, verändert oder Dritten zugänglich gemacht werden. Wir weisen darauf hin, dass auf den Websites enthaltene Bilder teilweise dem Urheberrecht Dritter unterliegen. Es ist nicht erlaubt zu versuchen, auf die Quellcodes der Anwado-Plattform zuzugreifen oder diese zu kopieren und die Anwado-Plattform für andere Zwecke als die Nutzung der Anwado-Plattform zu verwenden, Kopien der Anwado-Plattform anzufertigen,  die Anwado-Plattform zu vervielfältigen, oder die Anwado-Plattform zu extrahieren, zu modifizieren, in eine oder mehrere Sprachen zu übersetzen oder die Anwado-Plattform in andere Software zu integrieren oder auf Grundlage der Anwado-Plattform abgeleitete Arbeiten zu erstellen, die auf der Anwado-Plattform basieren, die Anwado-Plattform weiterzuverkaufen, noch zu vermieten oder kommerziell zu nutzen und die Anwado-Plattform an Dritte zu übertragen.`,
    de: `Alle Inhalte (Texte, Videos, Bilder sowie deren Anordnung, Layout, Schrift- und Farbgestaltung) auf den Webseiten der Anwado GmbH unterliegen dem Schutz des Urheberrechts und anderer Schutzgesetze. Der Inhalt dieser Websites darf nicht zu kommerziellen Zwecken kopiert, verbreitet, verändert oder Dritten zugänglich gemacht werden. Wir weisen darauf hin, dass auf den Websites enthaltene Bilder teilweise dem Urheberrecht Dritter unterliegen. Es ist nicht erlaubt zu versuchen, auf die Quellcodes der Anwado-Plattform zuzugreifen oder diese zu kopieren und die Anwado-Plattform für andere Zwecke als die Nutzung der Anwado-Plattform zu verwenden, Kopien der Anwado-Plattform anzufertigen,  die Anwado-Plattform zu vervielfältigen, oder die Anwado-Plattform zu extrahieren, zu modifizieren, in eine oder mehrere Sprachen zu übersetzen oder die Anwado-Plattform in andere Software zu integrieren oder auf Grundlage der Anwado-Plattform abgeleitete Arbeiten zu erstellen, die auf der Anwado-Plattform basieren, die Anwado-Plattform weiterzuverkaufen, noch zu vermieten oder kommerziell zu nutzen und die Anwado-Plattform an Dritte zu übertragen.`,
  },
  LN_Title_6: {
    en: 'Consumer dispute resolution/universal arbitration board',
    de: 'Verbraucherstreitbeilegung/Universalschlichtungsstelle',
    ro: 'Soluționarea litigiilor în materie de consum/Consiliul de arbitraj universal',
  },
  LN_Description_6: {
    en: `We are not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration board.`,
    de: `Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.`,
  },
  LN_Title_7: {
    en: 'Information according to P2B regulation',
    de: 'Informationen gemäß P2B-Verordnung',
    ro: 'Informații în conformitate cu Regulamentul P2B',
  },
  LN_Description_7: {
    en: `Evaluation guidelines Danteschutz`,
    de: `BewertungsrichtlinienDanteschutz`,
  },
  LN_Title_8: {
    en: `Mandatory information`,
    de: 'Pflichtangaben',
    ro: 'Informații obligatorii',
  },
  LN_Description_8: {
    en: `HRB 276438, Munich Local Court 
VAT ID No.: DE354112063`,
    de: `HRB 276438, Amtsgericht München 
USt-IdNr.: DE354112063`,
  },
  //Scoring Policies
  Scoring_Policies_Meta_Title_1: {
    en: 'Anwado - Evaluation Guidelines',
    de: 'Anwado - Bewertungsrichtlinien',
    ro: 'Anwado - Ghid de evaluare',
  },
  Scoring_Policies_Meta_Description_1: {
    en: 'Anwado offers a reliable lawyer search with real user reviews. Find your lawyer and book appointments online.',
    de: 'Anwado bietet eine verlässliche Anwaltssuche mit echten Nutzerbewertungen. Finden Sie Ihren Anwalt und buchen Sie Termine online.',
    ro: 'Anwado oferă o căutare fiabilă de avocați cu recenzii reale ale utilizatorilor. Găsește-ți avocatul și programează întâlniri online.',
  },
  Scoring_Policies_Title: {
    en: 'Bewertungsrichtlinien',
    de: 'Bewertungsrichtlinien',
    ro: 'Orientări de evaluare',
  },
  Scoring_Policies_Description_1: {
    en: 'Anwado verfolgt das Ziel, Nutzer (Im Folgenden wird aus Gründen der besseren Lesbarkeit ausschließlich die männliche Form verwendet. Sie bezieht sich auf Personen jeglichen Geschlechts.) schnell und unkompliziert mit den relevantesten Informationen über geeignete Anwälte zu versorgen. Die Anwado-Anwaltssuche samt Online-Terminvereinbarung ermöglicht es Nutzern, passende Anwälte zu finden, wobei authentische Bewertungen anderer Nutzer dabei unterstützen können. Authentische Nutzerbewertungen sind daher für Anwado von großer Bedeutung. Wir legen selbst hohe Standards fest, um sicherzustellen, dass auf Anwado veröffentlichte Bewertungen echt, authentisch und nicht manipuliert sind. Nur auf Anwado registrierte Nutzer können Bewertungen veröffentlichen. Bewertungen werden im Anwaltsprofil mit einer Gesamtsternzahl zwischen 1 und 5 angezeigt und können die Position des Anwalts im Anwado-Ranking beeinflussen.',
    de: 'Anwado verfolgt das Ziel, Nutzer (Im Folgenden wird aus Gründen der besseren Lesbarkeit ausschließlich die männliche Form verwendet. Sie bezieht sich auf Personen jeglichen Geschlechts.) schnell und unkompliziert mit den relevantesten Informationen über geeignete Anwälte zu versorgen. Die Anwado-Anwaltssuche samt Online-Terminvereinbarung ermöglicht es Nutzern, passende Anwälte zu finden, wobei authentische Bewertungen anderer Nutzer dabei unterstützen können. Authentische Nutzerbewertungen sind daher für Anwado von großer Bedeutung. Wir legen selbst hohe Standards fest, um sicherzustellen, dass auf Anwado veröffentlichte Bewertungen echt, authentisch und nicht manipuliert sind. Nur auf Anwado registrierte Nutzer können Bewertungen veröffentlichen. Bewertungen werden im Anwaltsprofil mit einer Gesamtsternzahl zwischen 1 und 5 angezeigt und können die Position des Anwalts im Anwado-Ranking beeinflussen.',
    ro: 'Anwado urmărește obiectivul de a oferi utilizatorilor (În cele ce urmează, forma masculină este utilizată exclusiv din motive de mai bună lizibilitate. Se referă la persoane de orice sex) cu cele mai relevante informații despre avocații potriviți, rapid și ușor. Căutarea de avocați Anwado, inclusiv programările online, le permite utilizatorilor să găsească avocați potriviți, iar recenziile autentice ale utilizatorilor îi pot sprijini în acest sens. Prin urmare, recenziile autentice ale utilizatorilor sunt de mare importanță pentru Anwado. Noi înșine stabilim standarde înalte pentru a ne asigura că recenziile publicate pe Anwado sunt autentice, veritabile și nu sunt manipulate. Numai utilizatorii înregistrați pe Anwado pot publica recenzii. Recenzile sunt afișate în profilul avocatului cu un rating general de stele între 1 și 5 și pot influența poziția avocatului în clasamentul Anwado.',
  },
  Scoring_Policies_Description_2: {
    en: 'Unsere Anwado-Bewertungsrichtlinien:',
    de: 'Unsere Anwado-Bewertungsrichtlinien:',
    ro: 'Ghidul nostru de evaluare Anwado:',
  },
  Scoring_Policies_Paragraph_1_Title: {
    en: 'Erfahrungen aus erster Hand',
    de: 'Erfahrungen aus erster Hand',
    ro: 'Experiență directă',
  },
  Scoring_Policies_Paragraph_1_Description: {
    en: 'Anwado-Bewertungen sollen die persönlichen Erfahrungen von Nutzern mit auf unserer Website gelisteten Anwälten widerspiegeln. Sie sollen relevante und hilfreiche Informationen für andere Nutzer der Anwado-Community bereitstellen und ausschließlich auf eigenen Erfahrungen der Nutzer (Erste-Hand-Erfahrungen) basieren. Wir achten auf die Echtheit von Bewertungen und veröffentlichen nur solche, von deren Authentizität wir überzeugt sind. Manipulierte Bewertungen und die dafür Verantwortlichen werden konsequent verfolgt, da sie dem Vertrauen in unsere Plattform schaden und Nutzer täuschen können.',
    de: 'Anwado-Bewertungen sollen die persönlichen Erfahrungen von Nutzern mit auf unserer Website gelisteten Anwälten widerspiegeln. Sie sollen relevante und hilfreiche Informationen für andere Nutzer der Anwado-Community bereitstellen und ausschließlich auf eigenen Erfahrungen der Nutzer (Erste-Hand-Erfahrungen) basieren. Wir achten auf die Echtheit von Bewertungen und veröffentlichen nur solche, von deren Authentizität wir überzeugt sind. Manipulierte Bewertungen und die dafür Verantwortlichen werden konsequent verfolgt, da sie dem Vertrauen in unsere Plattform schaden und Nutzer täuschen können.',
    ro: 'Recenzile Anwado au scopul de a reflecta experiențele personale ale utilizatorilor cu avocații listați pe site-ul nostru. Acestea au scopul de a oferi informații relevante și utile pentru alți utilizatori ai comunității Anwado și se bazează exclusiv pe experiențele proprii ale utilizatorilor (experiențe de primă mână). Suntem atenți la autenticitatea recenziilor și le publicăm doar pe cele de care suntem convinși. Recenziile manipulate și cei responsabili de acestea vor fi urmăriți în mod consecvent, deoarece acestea pot afecta încrederea în platforma noastră și pot înșela utilizatorii.',
  },
  Scoring_Policies_Paragraph_2_Title: {
    en: 'Faire Bewertungen',
    de: 'Faire Bewertungen',
    ro: 'Evaluări juste',
  },
  Scoring_Policies_Paragraph_2_Description: {
    en: 'Anwado ermöglicht es Nutzern, Anwälte online zu bewerten. Wir überprüfen Bewertungsinhalte auf Einhaltung unserer Fairness-Anforderungen, einschließlich Meinungsfreiheit, wertschätzender und konstruktiver Kritik. Respektvolle und konstruktive Bewertungen sind uns wichtig, und wir setzen uns aktiv gegen Beleidigungen, Hassrede, Hetze, Diffamierung, Diskriminierung, Rassismus und Respektlosigkeiten ein.',
    de: 'Anwado ermöglicht es Nutzern, Anwälte online zu bewerten. Wir überprüfen Bewertungsinhalte auf Einhaltung unserer Fairness-Anforderungen, einschließlich Meinungsfreiheit, wertschätzender und konstruktiver Kritik. Respektvolle und konstruktive Bewertungen sind uns wichtig, und wir setzen uns aktiv gegen Beleidigungen, Hassrede, Hetze, Diffamierung, Diskriminierung, Rassismus und Respektlosigkeiten ein.',
    ro: 'Anwado permite utilizatorilor să evalueze online avocații. Examinăm conținutul recenziilor pentru a verifica dacă respectă cerințele noastre de corectitudine, inclusiv libertatea de exprimare, apreciere și critică constructivă. Recenzile respectuoase și constructive sunt importante pentru noi și lucrăm activ împotriva insultelor, discursului instigator la ură, agitației, defăimării, discriminării, rasismului și lipsei de respect.',
  },
  Scoring_Policies_Paragraph_3_Title: {
    en: 'Keine gekauften Bewertungen',
    de: 'Keine gekauften Bewertungen',
    ro: 'Nu există evaluări cumpărate',
  },
  Scoring_Policies_Paragraph_3_Description: {
    en: 'Anwado legt Wert darauf, dass Nutzer nur echte Erfahrungen teilen. Kopierte oder plagiierte Inhalte sowie Bewertungen, die im Austausch für Gegenleistungen abgegeben wurden, werden entfernt.',
    de: 'Anwado legt Wert darauf, dass Nutzer nur echte Erfahrungen teilen. Kopierte oder plagiierte Inhalte sowie Bewertungen, die im Austausch für Gegenleistungen abgegeben wurden, werden entfernt.',
    ro: 'Anwado se asigură că utilizatorii împărtășesc doar experiențe autentice. Conținutul copiat sau plagiat, precum și recenziile oferite în schimbul unei contraprestații, vor fi eliminate.',
  },
  Scoring_Policies_Paragraph_4_Title: {
    en: 'Nützlichkeit',
    de: 'Nützlichkeit',
    ro: 'Utilitate',
  },
  Scoring_Policies_Paragraph_4_Description: {
    en: 'Aussagekräftige und relevante Bewertungen unterstützen Nutzer bei der Suche nach dem passenden Anwalt. Daher sind konkrete Beschreibungen von Nutzererfahrungen sowie angemessene Sternebewertungen erwünscht. Auch wenn nicht alle Bewertungen diesen Ansprüchen gerecht werden, lassen wir sie in der Regel zu, da sie dennoch relevante Informationen enthalten können.',
    de: 'Aussagekräftige und relevante Bewertungen unterstützen Nutzer bei der Suche nach dem passenden Anwalt. Daher sind konkrete Beschreibungen von Nutzererfahrungen sowie angemessene Sternebewertungen erwünscht. Auch wenn nicht alle Bewertungen diesen Ansprüchen gerecht werden, lassen wir sie in der Regel zu, da sie dennoch relevante Informationen enthalten können.',
    ro: 'Evaluările semnificative și relevante îi sprijină pe utilizatori în căutarea avocatului potrivit. Prin urmare, sunt de dorit descrieri concrete ale experiențelor utilizatorilor și clasificări adecvate cu stele. Chiar dacă nu toate recenziile îndeplinesc aceste cerințe, de obicei le permitem, deoarece ele pot conține totuși informații relevante.',
  },
  Scoring_Policies_Paragraph_5_Title: {
    en: 'Änderung oder Löschung von Bewertungen',
    de: 'Änderung oder Löschung von Bewertungen',
    ro: 'Modificarea sau ștergerea ratingurilor',
  },
  Scoring_Policies_Paragraph_5_Description: {
    en: 'Nutzer haben die Möglichkeit, ihre abgegebenen Bewertungen eigenständig zu ändern oder zu löschen, falls sie ihre Meinung ändern oder Fehler in ihrer ursprünglichen Bewertung korrigieren möchten. Um dies zu tun, können sie sich in ihr Anwado-Konto einloggen, die betreffende Bewertung aufrufen und die gewünschten Änderungen vornehmen oder die Löschung der Bewertung bestätigen.',
    de: 'Nutzer haben die Möglichkeit, ihre abgegebenen Bewertungen eigenständig zu ändern oder zu löschen, falls sie ihre Meinung ändern oder Fehler in ihrer ursprünglichen Bewertung korrigieren möchten. Um dies zu tun, können sie sich in ihr Anwado-Konto einloggen, die betreffende Bewertung aufrufen und die gewünschten Änderungen vornehmen oder die Löschung der Bewertung bestätigen.',
    ro: 'Utilizatorii au opțiunea de a-și modifica sau șterge în mod independent recenziile trimise, dacă doresc să își schimbe opinia sau să corecteze erorile din recenzia lor inițială. Pentru a face acest lucru, aceștia se pot autentifica în contul lor Anwado, pot accesa recenzia în cauză și pot face modificările dorite sau pot confirma ștergerea recenziei.',
  },
  Scoring_Policies_Paragraph_6_Title: {
    en: 'Recht auf Gegendarstellung',
    de: 'Recht auf Gegendarstellung',
    ro: 'Dreptul la replică',
  },
  Scoring_Policies_Paragraph_6_Description: {
    en: 'Anwälte haben das Recht, auf Bewertungen zu reagieren oder ihre Sichtweise darzulegen, um eine ausgewogene und faire Darstellung der Zusammenarbeit zu gewährleisten. Diese Antworten werden im Bewertungsbereich des Anwaltsprofils direkt unter der Bewertung des Nutzers veröffentlicht, um potenziellen Mandanten ein umfassendes Bild der Erfahrungen mit dem Anwalt zu vermitteln.',
    de: 'Anwälte haben das Recht, auf Bewertungen zu reagieren oder ihre Sichtweise darzulegen, um eine ausgewogene und faire Darstellung der Zusammenarbeit zu gewährleisten. Diese Antworten werden im Bewertungsbereich des Anwaltsprofils direkt unter der Bewertung des Nutzers veröffentlicht, um potenziellen Mandanten ein umfassendes Bild der Erfahrungen mit dem Anwalt zu vermitteln.',
    ro: 'Avocații au dreptul de a răspunde la recenzii sau de a-și prezenta punctul de vedere pentru a asigura o reprezentare echilibrată și corectă a cooperării. Aceste răspunsuri sunt publicate în secțiunea de evaluare a profilului avocatului, direct sub evaluarea utilizatorului, pentru a oferi potențialilor clienți o imagine completă a experienței cu avocatul.',
  },
  Scoring_Policies_Paragraph_7_Title: {
    en: 'Berichterstattung und Moderation',
    de: 'Berichterstattung und Moderation',
    ro: 'Raportare și moderare',
  },
  Scoring_Policies_Paragraph_7_Description: {
    en: 'Anwälte können Bewertungen, die möglicherweise gegen die Richtlinien verstoßen oder inhaltliche Bedenken aufwerfen, an den Service von Anwado zur Überprüfung melden. Nachdem eine Bewertung gemeldet wurde, wird das Anwado-Team den gemeldeten Inhalt sorgfältig prüfen und entscheiden, wie weiter verfahren wird. Dies kann die Bearbeitung oder Entfernung der Bewertung, die Rückgabe der Bewertung zur Korrektur an den Nutzer oder die Bestätigung der Bewertung als zulässig beinhalten.',
    de: 'Anwälte können Bewertungen, die möglicherweise gegen die Richtlinien verstoßen oder inhaltliche Bedenken aufwerfen, an den Service von Anwado zur Überprüfung melden. Nachdem eine Bewertung gemeldet wurde, wird das Anwado-Team den gemeldeten Inhalt sorgfältig prüfen und entscheiden, wie weiter verfahren wird. Dies kann die Bearbeitung oder Entfernung der Bewertung, die Rückgabe der Bewertung zur Korrektur an den Nutzer oder die Bestätigung der Bewertung als zulässig beinhalten.',
    ro: 'Avocații pot raporta recenziile care ar putea încălca liniile directoare sau pot ridica probleme de conținut serviciului Anwado pentru a fi analizate. După ce o recenzie a fost raportată, echipa Anwado va analiza cu atenție conținutul raportat și va decide cum să procedeze. Aceasta poate include editarea sau eliminarea recenziei, returnarea recenziei către utilizator pentru corectare sau confirmarea recenziei ca fiind admisibilă.',
  },
  Scoring_Policies_Description_3: {
    en: `Was geschieht mit Bewertungen, die diesen Richtlinien nicht entsprechen? 
Bewertungen, die offensichtlich gegen geltendes Recht verstoßen, werden gelöscht. In unklaren Fällen oder bei weniger schwerwiegenden Verstößen gegen unsere Richtlinien behalten wir uns vor, Bewertungen zur Korrektur an die Verfasser zurückzugeben. Bei schwerwiegenden Vorwürfen kann die Veröffentlichung von der Vorlage von Beweisen abhängig gemacht werden. 
Wir sind überzeugt, dass unsere Richtlinien die Grundlage für eine Plattform der Fairness und Transparenz schaffen, auf der Nutzer echte Hilfe bei der Suche nach dem passenden Anwalt finden. Der Profilstatus eines Anwalts hat bei Anwado keinen Einfluss auf Entscheidungen zur Veröffentlichung von Bewertungen. Bitte unterstützen Sie uns, indem Sie Bewertungen melden, die gegen diese Richtlinien verstoßen.`,
    de: `Was geschieht mit Bewertungen, die diesen Richtlinien nicht entsprechen? 
Bewertungen, die offensichtlich gegen geltendes Recht verstoßen, werden gelöscht. In unklaren Fällen oder bei weniger schwerwiegenden Verstößen gegen unsere Richtlinien behalten wir uns vor, Bewertungen zur Korrektur an die Verfasser zurückzugeben. Bei schwerwiegenden Vorwürfen kann die Veröffentlichung von der Vorlage von Beweisen abhängig gemacht werden. 
Wir sind überzeugt, dass unsere Richtlinien die Grundlage für eine Plattform der Fairness und Transparenz schaffen, auf der Nutzer echte Hilfe bei der Suche nach dem passenden Anwalt finden. Der Profilstatus eines Anwalts hat bei Anwado keinen Einfluss auf Entscheidungen zur Veröffentlichung von Bewertungen. Bitte unterstützen Sie uns, indem Sie Bewertungen melden, die gegen diese Richtlinien verstoßen.`,
  },
  // Cookies
  Cookies_Banner_Text: {
    en: 'We use cookies to ensure the proper functioning of our website ("cookies"). The legal basis is Art. 6 para. 1 p. 1 lit. f DSGVO (legitimate interests). By clicking "Allow", you consent to the use of non-essential cookies and other technologies.  By clicking on "Decline", cookies and similar technologies will not be used. If you consent, the consent also includes the related processing of personal data (e.g. IP address) and the transfer of the same to third countries outside the European Economic Area (EEA).',
    de: 'Wir verwenden Cookies um das ordnungsgemäße Funktionieren unserer Webseite zu gewährleisten („Cookies“). Die Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO (berechtigte Interessen). Durch Klicken auf „Zulassen” willigen Sie in die Verwendung von nicht essentiellen Cookies und anderen Technologien ein.  Durch Klicken auf „Ablehnen”, werden keine Cookies und ähnliche Technologien genutzt. Soweit Sie einwilligen, umfasst die Einwilligung auch die damit zusammenhängende Verarbeitung personenbezogener Daten (z.B. IP-Adresse) sowie die Übermittlung derselben in Drittländer außerhalb des Europäischen Wirtschaftsraums (EWR).',
    ro: 'Utilizăm module cookie pentru a asigura buna funcționare a site-ului nostru web ("module cookie"). Temeiul juridic este art. 6 alin. 1 p. 1 lit. f DSGVO (interese legitime). Făcând clic pe "Permiteți", sunteți de acord cu utilizarea cookie-urilor neesențiale și a altor tehnologii.  Făcând clic pe "Refuz", nu vor fi utilizate cookie-uri și tehnologii similare. În cazul în care vă dați consimțământul, consimțământul include, de asemenea, prelucrarea conexă a datelor cu caracter personal (de exemplu, adresa IP), precum și transferul acestora către țări terțe din afara Spațiului Economic European (SEE).',
  },
  Cookies_Banner_Accept_Text: {
    en: 'I accept',
    de: 'Ich akzeptiere',
    ro: 'Accept',
  },
  Cookies_Banner_Decline_Text: {
    en: 'I decline',
    de: 'Ich lehne ab',
    ro: 'Refuz.',
  },
  Cookies_Banner_Learn_More: {
    en: `Learn more about Anwado's use of cookies.`,
    de: 'Erfahren Sie mehr über die Verwendung von Cookies bei Anwado.',
    ro: 'Aflați mai multe despre utilizarea cookie-urilor de către Anwado.',
  },
  Cookies_Pre_Title: {
    en: 'Stand Dezember 2023',
    de: 'Stand Dezember 2023',
    ro: 'Stand Dezember 2023',
  },
  Cookies_Title: {
    en: 'Cookies',
    de: 'Cookies',
    ro: 'Cookie-uri',
  },
  Cookies_Description: {
    en: 'Unsere Bewertungsrichtlinien stellen sicher, dass Bewertungen auf Anwado fair, echt und ohne unangemessene Inhalte sind und anderen Rechtssuchenden von Nutzen sind.',
    de: 'Unsere Bewertungsrichtlinien stellen sicher, dass Bewertungen auf Anwado fair, echt und ohne unangemessene Inhalte sind und anderen Rechtssuchenden von Nutzen sind.',
    ro: 'Politica noastră privind recenziile se asigură că recenziile de pe Anwado sunt corecte, autentice, lipsite de conținut inadecvat și utile pentru alte persoane care caută drepturi.',
  },
  Cookies_Paragraph_1_Title: {
    en: 'Bewertungsrichtlinien',
    de: 'Bewertungsrichtlinien',
    ro: 'Orientări de evaluare',
  },
  Cookies_Paragraph_1_Description_1: {
    en: `Anwado verfolgt das Ziel, Nutzer (Im Folgenden wird aus Gründen der besseren Lesbarkeit ausschließlich die männliche Form verwendet. Sie bezieht sich auf Personen jeglichen Geschlechts.) schnell und unkompliziert mit den relevantesten Informationen über geeignete Anwälte zu versorgen, da wir an eine transparente und zugängliche Rechtsberatung glauben, bei der Mandanten die besten Anwälte finden und Anwälte ihr Fachwissen effektiv teilen können – das ist Rechtsberatung neu gedacht. Durch die Bereitstellung einer benutzerfreundlichen Plattform ermöglicht Anwado Mandanten, gezielt nach Anwälten zu suchen, Bewertungen einzusehen und online Termine zu vereinbaren. Anwälte können ihre Präsenz stärken, indem sie ihr Fachwissen präsentieren und von zufriedenen Mandanten empfohlen werden.

Wir legen selbst hohe Standards fest, um sicherzustellen, dass auf Anwado veröffentlichte Bewertungen echt, authentisch und nicht manipuliert sind. Nur auf Anwado registrierte Nutzer können Bewertungen veröffentlichen. Bewertungen werden im AnwaltsKonto mit einer Gesamtsternzahl zwischen 1 und 5 angezeigt und können die Position des Anwalts im Anwado-Ranking beeinflussen.

Unsere Anwado-Bewertungsrichtlinien:`,
    de: `Anwado verfolgt das Ziel, Nutzer (Im Folgenden wird aus Gründen der besseren Lesbarkeit ausschließlich die männliche Form verwendet. Sie bezieht sich auf Personen jeglichen Geschlechts.) schnell und unkompliziert mit den relevantesten Informationen über geeignete Anwälte zu versorgen, da wir an eine transparente und zugängliche Rechtsberatung glauben, bei der Mandanten die besten Anwälte finden und Anwälte ihr Fachwissen effektiv teilen können – das ist Rechtsberatung neu gedacht. Durch die Bereitstellung einer benutzerfreundlichen Plattform ermöglicht Anwado Mandanten, gezielt nach Anwälten zu suchen, Bewertungen einzusehen und online Termine zu vereinbaren. Anwälte können auf der anderen Seite ihre Präsenz stärken, indem sie ihr Fachwissen präsentieren und von zufriedenen Mandanten empfohlen werden.

Wir legen selbst hohe Standards fest, um sicherzustellen, dass auf Anwado veröffentlichte Bewertungen echt, authentisch und nicht manipuliert sind. Nur auf Anwado registrierte Nutzer können Bewertungen veröffentlichen. Bewertungen werden im AnwaltsKonto mit einer Gesamtsternzahl zwischen 1 und 5 angezeigt und können die Position des Anwalts im Anwado-Ranking beeinflussen.

    Unsere Anwado-Bewertungsrichtlinien:`,
  },

  Cookies_Paragraph_2_Title: {
    en: 'Wie verwenden wir Ihre Informationen?',
    de: 'Wie verwenden wir Ihre Informationen?',
    ro: 'Cum folosim informațiile dumneavoastră?',
  },
  Cookies_Paragraph_2_Description_1: {
    en: 'Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.',
    de: 'Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.',
    ro: 'Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.',
  },
  Cookies_Paragraph_2_Description_2: {
    en: 'Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat auctor aliquam. Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.',
    de: 'Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat auctor aliquam. Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.',
    ro: 'Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat auctor aliquam. Risus, volutpat vulputate vulputate posuere purus sit congue convallis aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.',
  },
  Cookies_Paragraph_2_Description_3: {
    en: 'Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.',
    de: 'Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.',
    ro: 'Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.',
  },
  Cookies_Paragraph_3_Title: {
    en: 'Verwenden wir Cookies und andere Tracking-Technologien?',
    de: 'Verwenden wir Cookies und andere Tracking-Technologien?',
    ro: 'Utilizăm cookie-uri și alte tehnologii de urmărire?',
  },
  Cookies_Paragraph_3_Description_1: {
    en: 'Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque ac.',
    de: 'Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque ac.',
    ro: 'Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis aliquam aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque ac.',
  },
  Cookies_Paragraph_4_Title: {
    en: 'Wie lange bewahren wir Ihre Daten auf?',
    de: 'Wie lange bewahren wir Ihre Daten auf?',
    ro: 'Cât timp păstrăm datele dumneavoastră?',
  },
  Cookies_Paragraph_4_Description_1: {
    en: 'Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque ac.',
    de: 'Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque ac.',
    ro: 'Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis aliquam aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque ac.',
  },
  Cookies_Paragraph_5_Title: {
    en: 'Wie bewahren wir Ihre Daten sicher auf?',
    de: 'Wie bewahren wir Ihre Daten sicher auf?',
    ro: 'Cum păstrăm datele dumneavoastră în siguranță?',
  },
  Cookies_Paragraph_5_Description_1: {
    en: 'Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque ac.',
    de: 'Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque ac.',
    ro: 'Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis aliquam aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque ac.',
  },
  Cookies_Paragraph_6_Title: {
    en: 'What are your privacy rights?',
    de: 'Welche Rechte haben Sie in Bezug auf Ihre Privatsphäre?',
    ro: 'Care sunt drepturile dumneavoastră la confidențialitate?',
  },
  Cookies_Paragraph_6_Description_1: {
    en: 'Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque ac.',
    de: 'Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque ac.',
    ro: 'Pharetra morbi libero id aliquam elit massa integer tellus. Quis felis aliquam aliquam ullamcorper porttitor. Pulvinar ullamcorper sit dictumst ut eget a, elementum eu. Maecenas est morbi mattis id in ac pellentesque ac.',
  },
  Cookies_Paragraph_7_Title: {
    en: 'How can you contact us?',
    de: 'Wie können Sie mit uns Kontakt aufnehmen?',
    ro: 'Cum ne puteți contacta?',
  },
  Cookies_Paragraph_7_Description_1: {
    en: 'Sagittis et eu at elementum, quis in. Proin praesent volutpat egestas sociis sit lorem nunc nunc sit. Eget diam curabitur mi ac. Auctor rutrum lacus malesuada massa ornare et. Vulputate consectetur ac ultrices at diam dui eget fringilla tincidunt. Arcu sit dignissim massa erat cursus vulputate gravida id. Sed quis auctor vulputate hac elementum gravida cursus dis.',
    de: 'Sagittis et eu at elementum, quis in. Proin praesent volutpat egestas sociis sit lorem nunc nunc sit. Eget diam curabitur mi ac. Auctor rutrum lacus malesuada massa ornare et. Vulputate consectetur ac ultrices at diam dui eget fringilla tincidunt. Arcu sit dignissim massa erat cursus vulputate gravida id. Sed quis auctor vulputate hac elementum gravida cursus dis.',
    ro: 'Sagittis et eu at elementum, quis in. Proin praesent volutpat egestas sociis sit lorem nunc nunc sit. Eget diam curabitur mi ac. Auctor rutrum lacus malesuada massa ornare et. Vulputate consectetur ac ultrices at diam dui eget fringilla tincidunt. Arcu sit dignissim massa erat cursus vulputate gravida id. Sed quis auctor vulputate hac elementum gravida cursus dis.',
  },
  Cookies_Paragraph_7_Description_2_1: {
    en: 'Lectus id duis vitae porttitor enim',
    de: 'Lectus id duis vitae porttitor enim',
    ro: 'Lectus id duis vitae porttitor enim',
  },
  Cookies_Paragraph_7_Description_2_2: {
    en: 'gravida morbi',
    de: 'gravida morbi',
    ro: 'gravida morbi',
  },
  Cookies_Paragraph_7_Description_3_1: {
    en: 'Eu turpis',
    de: 'Eu turpis',
    ro: 'Eu turpis',
  },
  Cookies_Paragraph_7_Description_3_2: {
    en: 'posuere semper feugiat',
    de: 'posuere semper feugiat',
    ro: 'posuere semper feugiat',
  },
  Cookies_Paragraph_7_Description_3_3: {
    en: 'volutpat elit, ultrices suspendisse. Auctor vel in vitae placerat.',
    de: 'volutpat elit, ultrices suspendisse. Auctor vel in vitae placerat.',
    ro: 'volutpat elit, ultrices suspendisse. Auctor vel in vitae placerat.',
  },
  Cookies_Paragraph_7_Description_4_1: {
    en: 'Suspendisse maecenas ac',
    de: 'Suspendisse maecenas ac',
    ro: 'Suspendisse maecenas ac',
  },
  Cookies_Paragraph_7_Description_4_2: {
    en: 'donec scelerisque',
    de: 'donec scelerisque',
    ro: 'donec scelerisque',
  },
  Cookies_Paragraph_7_Description_4_3: {
    en: 'diam sed est duis purus.',
    de: 'diam sed est duis purus.',
    ro: 'diam sed est duis purus.',
  },
  // About Us
  About_Us_Meta_Title_1: {
    en: 'Anwado - About us, Legal advice rethought',
    de: 'Anwado - Über uns, Rechtsberatung neu gedacht',
    ro: 'Anwado - Despre noi, Consultanță juridică regândită',
  },
  About_Us_Meta_Description_1: {
    en: 'Anwado stands for transparent and accessible legal advice. Discover how we are reshaping legal advice - simply, quickly and online.',
    de: 'Anwado steht für eine transparente und zugängliche Rechtsberatung. Entdecken Sie, wie wir die Rechtsberatung neu gestalten - einfach, schnell und online.',
    ro: 'Anwado înseamnă consultanță juridică transparentă și accesibilă. Descoperiți modul în care remodelăm consilierea juridică - simplu, rapid și online.',
  },
  About_Us_Title_1: {
    en: 'About Us',
    de: 'Über uns',
    ro: 'Despre noi',
  },
  About_Us_Title_2: {
    en: 'We do things things differently...',
    de: 'Wir machen die Dinge anders...',
    ro: 'Noi facem lucrurile diferit...',
  },
  About_Us_Title_3: {
    en: 'Simple - fast - online!',
    de: 'Einfach - schnell - online!',
    ro: 'Simplu - rapid - online!',
  },
  About_Us_Achievement_1_Title: {
    en: '',
    de: '',
  },
  About_Us_Achievement_1_Subtitle: {
    en: 'Lawyers',
    de: 'Anwälte',
    ro: 'Avocați',
  },
  About_Us_Achievement_1_Description: {
    en: '',
    de: '',
  },
  About_Us_Achievement_2_Title: {
    en: '',
    de: '',
  },
  About_Us_Achievement_2_Subtitle: {
    en: 'Specialist lawyers',
    de: 'Fachanwälte',
    ro: 'Avocați specializați',
  },
  About_Us_Achievement_2_Description: {
    en: 'Find the right specialist lawyer on Anwado.',
    de: 'Finden Sie auf Anwado den passenden Fachanwalt.',
    ro: 'Găsiți avocatul specializat potrivit pe Anwado.',
  },
  About_Us_Achievement_3_Title: {
    en: '',
    de: '',
  },
  About_Us_Achievement_3_Subtitle: {
    en: 'Law firms',
    de: 'Kanzleien',
    ro: 'Firmele de avocatură',
  },
  About_Us_Achievement_3_Description: {
    en: '',
    de: '',
  },
  About_Us_Achievement_4_Title: {
    en: '160',
    de: '160',
    ro: '160',
  },
  About_Us_Achievement_4_Subtitle: {
    en: 'Legal fields',
    de: 'Rechtsgebiete',
    ro: 'Domenii de drept',
  },
  About_Us_Achievement_4_Description: {
    en: 'Choose from 160 areas of law, the right one for your concern. From labor law to civil law, you will find all relevant areas of law on our platform.',
    de: 'Wählen Sie aus 160 Rechtsgebiete, das passende für Ihr Anliegen. Von Arbeitsrecht bis Zivilrecht finden Sie alle relevanten Rechtsgebiete auf unserer Plattform.',
    ro: 'Alegeți din 160 de domenii de drept, cel mai potrivit pentru cazul dumneavoastră. De la dreptul muncii până la dreptul civil, veți găsi toate domeniile de drept relevante pe platforma noastră.',
  },
  About_Us_Our: {
    en: 'Our',
    de: 'Unser',
    ro: 'Site-ul nostru',
  },
  About_Us_Goal: {
    en: 'Goal',
    de: 'Ziel',
    ro: 'Destinație',
  },
  About_Us_Our_Goal_Title: {
    en: 'An all-in-one solution for Clients and lawyers',
    de: 'Eine all-in-one Lösung für Mandanten und Anwälte',
    ro: 'O soluție all-in-one pentru clienți și avocați',
  },
  About_Us_Our_Goal_Description: {
    en: 'Our goal is to make it as easy as possible for clients to find the right lawyer and to keep the inhibition threshold as low as possible. Is as low as possible. We ensure this by offering the option of booking an appointment with Gold and Platinum lawyers directly online. online. The way to the lawyer should be pleasant, and we help with that. Always and everywhere.',
    de: 'Unser Ziel ist es, dass Mandanten auf möglichst einfache Weise den passenden Anwalt finden und die Hemmschwelle für eine Kontaktaufnahme möglichst niedrig ist. Das stellen wir durch die Möglichkeit sicher, bei vielen Gold- und Platin Anwälten direkt online einen Termin buchen zu können. Der Weg zum Anwalt sollte angenehm sein, und wir helfen dabei. Immer und überall.',
    ro: 'Scopul nostru este de a face cât mai ușor posibil pentru clienți să găsească avocatul potrivit și de a menține cât mai scăzut pragul de inhibiție pentru a intra în contact. Asigurăm acest lucru oferind posibilitatea de a rezerva o întâlnire direct online cu mulți avocați Gold și Platinum. Drumul către avocat ar trebui să fie plăcut, iar noi vă ajutăm în acest sens. Întotdeauna și peste tot.',
  },
  About_Us_Values_Title_1: {
    en: 'For clients',
    de: 'Für Mandanten',
    ro: 'Pentru clienți',
  },
  About_Us_Values_Text_1: {
    en: 'Find a lawyer, make an online appointment and clarify all legal issues.',
    de: 'Finden Sie einen Anwalt, vereinbaren Sie einen Termin online und klären Sie alle Rechtsfragen.',
    ro: 'Găsiți un avocat, faceți o programare online și clarificați toate problemele juridice.',
  },
  About_Us_Values_Title_2: {
    en: 'For lawyers',
    de: 'Für Anwälte',
    ro: 'Pentru avocați',
  },
  About_Us_Values_Text_2: {
    en: 'Save time managing client requests and benefit from more growth through modern online marketing.',
    de: 'Sparen Sie Zeit bei der Verwaltung der Mandantenanfragen und profitieren Sie von mehr Wachstum durch zeitgemäßes Online-Marketing.',
    ro: 'Economisiți timp în gestionarea cererilor de informații ale clienților și beneficiați de o creștere mai mare prin marketing online în timp util.',
  },
  About_Us_Values_Title_3: {
    en: 'Everything on one platform',
    de: 'Alles auf einer Plattform',
    ro: 'Totul pe o singură platformă',
  },
  About_Us_Values_Text_3: {
    en: 'The all-in-one solution for clients and lawyers.',
    de: 'Die all-in-one Lösung für Mandanten und Anwälte.',
    ro: 'Soluția all-in-one pentru clienți și avocați.',
  },
  About_Us_Values_Title_4: {
    en: 'Independent',
    de: 'Unabhängig',
    ro: 'Independent',
  },
  About_Us_Values_Text_4: {
    en: 'Evaluations take place completely independently of the package, without us as a provider having any provider have any influence on have any influence on the ratings. Lawyers decide about client structure without being restricted by the be restricted by the platform.',
    de: 'Bewertungen finden komplett unabhängig vom Rechtsanwaltspaket statt, ohne das wir als Anbieter Einfluss auf die Bewertungen haben. Anwälte entscheiden über Ihre Mandantenstruktur, ohne durch die Plattform eingeschränkt zu werden.',
    ro: 'Evaluările au loc în mod complet independent de pachetul de asistență juridică, fără ca noi, în calitate de furnizor, să avem vreo influență asupra evaluărilor. Avocații decid cu privire la structura lor de clienți fără a fi restricționați de platformă.',
  },
  About_Us_Values_Title_5: {
    en: 'Responsible',
    de: 'Verantwortungsvoll',
    ro: 'Responsabil',
  },
  About_Us_Values_Text_5: {
    en: 'At Anwado, we make no compromises when it comes to the the security of your data and fully implement the Data Protection Regulation (DSGVO) implemented. We understand the importance of protecting personal data and have taken extensive measures in place to ensure that all data data stored on our platform is protected is protected and secure.',
    de: 'Bei Anwado machen wir keine Abstriche, wenn es um die Sicherheit Ihrer Daten geht und setzen die Datenschutzgrundverordnung (DSGVO) vollständig um. Wir verstehen die Wichtigkeit des Schutzes persönlicher Daten und haben umfangreiche Maßnahmen ergriffen, um sicherzustellen, dass alle Daten, die auf unserer Plattform gespeichert werden, geschützt und sicher sind.',
    ro: 'La Anwado, nu facem rabat de la nimic atunci când vine vorba de securitatea datelor dumneavoastră și implementăm pe deplin Regulamentul general privind protecția datelor (GDPR). Înțelegem importanța protejării datelor cu caracter personal și am luat măsuri extinse pentru a ne asigura că toate datele stocate pe platforma noastră sunt protejate și sigure.',
  },
  About_Us_Values_Title_6: {
    en: 'Sustainable',
    de: 'Nachhaltig',
    ro: 'Durabil',
  },
  About_Us_Values_Text_6: {
    en: 'For our IT infrastructure, we work with the infrastructure provider Scaleway. This enables us to use climate-neutral data centers that are powered 100% by renewable energies.',
    de: 'Für unsere IT-Infrastruktur arbeiten wir mit dem Infrastrukturanbieter Scaleway zusammen. Dies ermöglicht uns die Nutzung klimaneutraler Rechenzentren, die zu 100 % mit erneuerbaren Energien betrieben werden.',
    ro: 'Pentru infrastructura noastră IT, lucrăm cu furnizorul de infrastructură Scaleway. Acest lucru ne permite să folosim centre de date neutre din punct de vedere climatic, care sunt alimentate 100% cu energie regenerabilă.',
  },
  About_Us_Lawyer_Cards_Title: {
    en: 'The best lawyers are only one click away...',
    de: 'Die besten Anwälte sind nur einen Klick entfernt...',
    ro: 'Cei mai buni avocați sunt la un click distanță...',
  },
  About_Us_Lawyer_Cards_Description: {
    en: 'With our user-friendly platform, you can quickly and easily find a lawyer that fits your needs. Whether it is a divorce, an employment law problem, or a real estate Real estate matter, we have a wide range of experienced attorneys ready to help you. Search our extensive database, read reviews and legal info to Find the best attorney for your case.',
    de: 'Mit unserer benutzerfreundlichen Plattform können Sie schnell und einfach einen Anwalt finden, der Ihren Bedürfnissen entspricht. Ob es sich um einen Nachbarschaftsstreit, ein Arbeitsrechtsproblem oder eine Immobilienangelegenheit handelt, wir haben eine breite Palette an erfahrenen Anwälten, die bereit sind, Ihnen zu helfen. Durchsuchen Sie unsere umfangreiche Datenbank, lesen Sie Bewertungen und Rechtsinfos, um den besten Anwalt für Ihren Fall zu finden.',
    ro: 'Cu ajutorul platformei noastre ușor de utilizat, puteți găsi rapid și ușor un avocat care să se potrivească nevoilor dumneavoastră. Fie că este vorba de o dispută între vecini, o problemă de angajare sau o problemă imobiliară, avem o gamă largă de avocați cu experiență gata să vă ajute. Căutați în baza noastră de date extinsă, citiți recenzii și informații juridice pentru a găsi cel mai bun avocat pentru cazul dumneavoastră.',
  },
  About_Us_Lawyer_Review: {
    en: '“In no time at all a lawyer who immediately took care my matter, mega!”',
    de: '“Im Handumdrehen einen Anwalt der sich sofort um mein Anliegen gekümmert hat, mega!”',
    ro: '"În cel mai scurt timp un avocat care s-a ocupat imediat de cererea mea, mega!"',
  },
  About_Us_Lawyer_Description: {
    en: 'Lorem ipsum dolor sit',
    de: 'Lorem ipsum dolor sit',
    ro: 'Lorem ipsum dolor sit',
  },
  About_Us_Any_Questions: {
    en: `Haven't found a lawyer yet?`,
    de: 'Noch keinen Anwalt gefunden?',
    ro: 'Nu ați găsit încă un avocat?',
  },
  About_Us_Any_Questions_Description: {
    en: 'Simply use the Anwado search to find the right lawyer for you and make an online appointment right away.',
    de: 'Nutzen Sie einfach die Anwado-Suche, um den für Sie passenden Anwalt zu finden und gleich online einen Termin zu vereinbaren.',
    ro: 'Pur și simplu folosiți căutarea Anwado pentru a găsi avocatul potrivit pentru dumneavoastră și faceți o programare online imediat.',
  },
  Anwado_Join_And_Offer_Services: {
    en: 'Join the Anwado community now and benefit from effective law firm marketing.',
    de: 'Schließen Sie Sich jetzt der Anwado Community an und profitieren Sie von einem effektiven Kanzleimarketing.',
    ro: 'Alăturați-vă acum comunității Anwado și beneficiați de un marketing eficient pentru firme de avocatură.',
  },
  // Article Blog Details
  Article_Blog_Details_To_Lawyer: {
    en: 'To the lawyer profile',
    de: 'Zum Anwaltprofil',
    ro: 'La profilul de avocat',
  },
  Articles_Blog_Details_Updated_at: {
    en: 'Updated at',
    de: 'Aktualisiert am',
    ro: 'Actualizat pe',
  },
  Articles_Blog_Details_Published_at: {
    en: 'Published at',
    de: 'Veröffentlicht bei',
    ro: 'Publicat la',
  },
  Article_Blog_Page_Details_Gray_Title: {
    en: 'How do I get in touch with ',
    de: 'Wie trete ich in Kontakt mit ',
    ro: 'Cum pot lua legătura cu',
  },
  Article_Blog_Page_Details_Gray_Description_1: {
    en: 'On Anwado you can find the right lawyer from a large number of lawyers and law firms in Germany. The recommendations of other clients, the information provided by the lawyers, numerous filter options and much more will help you. To contact ',
    de: 'Auf Anwado finden Sie den passenden Anwalt aus einer Vielzahl niedergelassener Anwälte und Kanzleien in Deutschland. Dabei helfen ihnen die Empfehlungen anderer Mandanten, die von den Anwälten bereitgestellten Informationen, zahlreiche Filtermöglichkeiten und vieles mehr. Um mit ',
    ro: 'Pe Anwado puteți găsi avocatul potrivit dintr-un număr mare de avocați și firme de avocatură stabilite în Germania. Vă vor ajuta recomandările altor clienți, informațiile furnizate de avocați, numeroasele opțiuni de filtrare și multe altele. Pentru a lucra cu',
  },
  Article_Blog_Page_Details_Gray_Description_2: {
    en: ', you can make an appointment online directly in Anwado, for an on-site meeting or for a telephone appointment. This saves you the time-consuming process of looking for an appointment by telephone. To do so, simply go to the lawyer profile of ',
    de: ' in Kontakt zu treten, können Sie direkt in Anwado online einen Termin vereinbaren, für eine Besprechung vor Ort oder für einen Telefontermin. So sparen Sie sich die zeitaufwendige telefonische Terminsuche. Gehen Sie hierzu einfach auf das Anwaltsprofil von ',
    ro: ' puteți face o programare online direct în Anwado, pentru o întâlnire la fața locului sau pentru o programare telefonică. Acest lucru vă scutește de procesul consumator de timp de a căuta o programare prin telefon. Accesați pur și simplu profilul de avocat al',
  },
  Article_Blog_Page_Details_Gray_Description_3: {
    en: ' and click on "Make an appointment".',
    de: ' und klicken Sie auf "Termin vereinbaren".',
    ro: ' și faceți clic pe "Make an appointment".',
  },
  Article_Blog_Page_Details_Gray_Button_Lawyer_Page: {
    en: `Click here for the lawyer profile!`,
    de: `Hier geht's zum Anwaltsprofil!`,
    ro: `Click aici pentru profilul avocatului!`,
  },
  Article_Blog_Page_Details_Gray_Button_Find_Lawyer: {
    en: `Find the right lawyer now!`,
    de: `Jetzt den richtigen Anwalt finden!`,
    ro: `Găsiți acum avocatul potrivit!`,
  },
  Article_Blog_Page_Details_Gray_Button_Copy_Link: {
    en: `Copy link`,
    de: `Link kopieren`,
    ro: `Copiază link-ul`,
  },
  Article_Blog_Page_Details_Gray_Button_Lawyer_Profile: {
    en: `To lawyer profile`,
    de: `Anwaltsprofil`,
    ro: `La profilul avocatului`,
  },
  Article_Blog_Page_Details_Gray_Button_Homepage: {
    en: `Find a lawyer`,
    de: `Anwalt finden`,
    ro: `Găsiți un avocat`,
  },

  // Support
  Support_Meta_Title_1: {
    en: 'Anwado - Frequently Asked Questions (FAQs)',
    de: 'Anwado - Häufig gestellte Fragen (FAQs)',
    ro: 'Anwado - Întrebări frecvente (FAQ)',
  },
  Support_Meta_Description_1: {
    en: 'Find answers and helpful tips on using Anwado in our FAQs. If you have any further questions, please do not hesitate to contact us.',
    de: 'Finden Sie Antworten und hilfreiche Tipps zur Nutzung von Anwado in unseren FAQs. Für weitere Fragen stehen wir Ihnen gerne zur Verfügung.',
    ro: 'Găsiți răspunsuri și sfaturi utile despre utilizarea Anwado în secțiunea Întrebări frecvente. Dacă aveți întrebări suplimentare, nu ezitați să ne contactați.',
  },
  Support_Title: {
    en: 'We have the answers...',
    de: 'Wir haben die Anworten...',
    ro: 'Noi avem răspunsurile...',
  },
  Support_Description: {
    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    de: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    ro: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  Support_Second_Title: {
    en: 'Support',
    de: 'Unterstützung',
    ro: 'Suport',
  },
  Support_FAQ_Description: {
    en: 'You can find useful tips for using Anwado here. If you have any further questions, please feel free to use our contact form.',
    de: 'Nützliche Tipps für die Nutzung von Anwado finden Sie hier. Bei weiteren Fragen, nutzen Sie gerne unser Kontaktformular.',
    ro: 'Puteți găsi sfaturi utile pentru utilizarea Anwado aici. Dacă aveți întrebări suplimentare, vă rugăm să folosiți formularul nostru de contact.',
  },
  Support_FAQ_Lawyer_Question_1: {
    en: 'What does Anwado offer?',
    de: 'Was bietet Anwado?',
    ro: 'Ce oferă Anwado?',
  },
  Support_FAQ_Lawyer_Answer_1: {
    en: 'On Anwado, clients can find the right lawyer from a large number of established lawyers and law firms in Germany. The recommendations of other clients, the information provided by the lawyers, numerous filter options and much more help. With a profile on Anwado you will optimize your online presence, increase your visibility and find new clients.',
    de: 'Auf Anwado finden Mandanten den passenden Anwalt aus einer Vielzahl von niedergelassenen Anwälten und Kanzleien in Deutschland. Dabei helfen die Empfehlungen anderer Mandanten, die von den Anwälten bereitgestellten Informationen, zahlreiche Filtermöglichkeiten und vieles mehr. Mit einem Profil auf Anwado optimieren Sie Ihre Onlinepräsenz, erhöhen Ihre Sichtbarkeit und finden neue Mandanten.',
    ro: 'Pe Anwado, clienții pot găsi avocatul potrivit dintr-un număr mare de avocați și firme de avocatură stabilite în Germania. Recomandările altor clienți, informațiile furnizate de avocați, numeroasele opțiuni de filtrare și multe altele îi ajută. Cu un profil pe Anwado, vă optimizați prezența online, vă creșteți vizibilitatea și găsiți noi clienți.',
  },
  Support_FAQ_Lawyer_Question_2: {
    en: 'What criteria are used to sort the search results of the Anwado lawyer search?',
    de: 'Nach welchen Kriterien wird das Suchergebnis der Anwado Anwaltssuche sortiert?',
    ro: 'Ce criterii sunt folosite pentru a sorta rezultatele căutării de avocați în Anwado?',
  },
  Support_FAQ_Lawyer_Answer_2: {
    en: "Our search results are listed according to how relevant and complete they are to the client's search, this includes in particular the rating and completeness of the profile. The more complete a profile is, including testimonials, availability of an online calendar, adding photos and services, the higher it will appear in your search results list. This information can only be offered by lawyers who have registered with Anwado and booked a paid entry. Clients have numerous filtering and sorting options to customize the results to their individual needs. Lawyer profiles that appear above this ranking are registered as platinum lawyers and marked as advertisements.",
    de: 'Unsere Suchergebnisse werden danach aufgelistet, wie relevant und vollständig sie für die Suche des Mandanten sind, dazu gehört insbesondere die Bewertung und die Vollständigkeit des Profils. Je vollständiger ein Profil ist, dazu gehören auch Erfahrungsberichte, die Verfügbarkeit eines Onlinekalenders, das Hinzufügen von Fotos und Dienstleistungen, desto weiter oben wird es in der Suchergebnisliste angezeigt. Diese Informationen können nur von Anwälten angeboten werden, die sich bei Anwado registriert haben und einen kostenpflichtigen Eintrag gebucht haben. Den Mandanten stehen zahlreiche Filter- und Sortieroptionen zur Verfügung, um die Ergebnisse an ihre individuellen Bedürfnisse anzupassen. Anwaltsprofile, die über diesem Ranking auftauchen, sind als Platin-Anwälte registriert und als Anzeige gekennzeichnet.',
    ro: 'Rezultatele căutării noastre sunt listate în funcție de cât de relevante și complete sunt pentru căutarea clientului, ceea ce include în special ratingul și caracterul complet al profilului. Cu cât un profil este mai complet, incluzând mărturii, disponibilitatea unui calendar online, adăugarea de fotografii și servicii, cu atât va apărea mai sus în lista de rezultate ale căutării. Aceste informații pot fi oferite doar de avocații care s-au înregistrat la Anwado și au rezervat o intrare plătită. Clienții au la dispoziție numeroase opțiuni de filtrare și sortare pentru a adapta rezultatele la nevoile lor individuale. Profilurile avocaților care apar peste acest clasament sunt înregistrate ca avocați de platină și marcate ca anunț publicitar.',
  },
  Support_FAQ_Lawyer_Question_3: {
    en: 'How can I reset my password?',
    de: 'Wie kann ich mein Passwort zurücksetzen?',
    ro: 'Cum îmi pot reseta parola?',
  },
  Support_FAQ_Lawyer_Answer_3: {
    en: 'You can conveniently reset your password via the "Reset password" link. To do this, simply go to " Login". There you will find the link. Follow the instructions to reset your password.',
    de: 'Sie können Ihr Passwort bequem über den Link "Passwort zurücksetzen" zurücksetzen. Gehen Sie dazu einfach auf " Login". Dort finden Sie den Link. Folgen Sie den Anweisungen, um Ihr Passwort zurückzusetzen.',
    ro: 'Vă puteți reseta în mod convenabil parola prin intermediul linkului "Resetare parolă". Pentru a face acest lucru, mergeți la "Login". Acolo veți găsi linkul. Urmați instrucțiunile pentru a vă reseta parola.',
  },
  Support_FAQ_Lawyer_Question_4: {
    en: 'What does my profile look like to people seeking legal advice?',
    de: 'Wie sieht mein Profil für Rechtssuchende aus?',
    ro: 'Cum arată profilul meu pentru persoanele care caută consultanță juridică?',
  },
  Support_FAQ_Lawyer_Answer_4: {
    en: 'Your profile contains all relevant information about your offer and your law firm (name, photo, address, law firm focus, specialist lawyer title, etc.) in the overview at the legal searcher. If there are reviews, these will also be displayed. ',
    de: 'Ihr Profil enthält alle relevanten Informationen zu Ihrem Angebot und Ihrer Kanzlei (Name, Foto, Adresse, Kanzleischwerpunkt, Fachanwaltstitel etc.) in der Übersicht beim Rechtssuchenden. Falls es Bewertungen gibt, werden diese ebenfalls angezeigt.',
    ro: 'Profilul dvs. conține toate informațiile relevante despre oferta dvs. și despre cabinetul dvs. de avocatură (nume, fotografie, adresă, domeniul de activitate al cabinetului de avocatură, titlul de avocat specializat etc.) în prezentarea generală pentru căutătorul juridic. În cazul în care există recenzii, acestea vor fi, de asemenea, afișate.',
  },
  Support_FAQ_Lawyer_Question_5: {
    en: 'How do I create my profile?',
    de: 'Wie erstelle ich mein Profil?',
    ro: 'Cum îmi creez profilul?',
  },
  Support_FAQ_Lawyer_Answer_5: {
    en: 'We are glad that you also want to become part of the Anwado community. To create your own entry, please register first. Afterwards you can easily adjust your profile or publish legal tips for your clients. Please also take the time to set up your calendar. This way clients can contact you quickly and easily. ',
    de: 'Es freut uns, dass auch Sie Teil der Anwado Community werden wollen. Um einen eigenen Eintrag anzulegen, registrieren Sie Sich bitte zunächst. Im Anschluss können Sie dann bequem Ihr Profil anpassen oder Rechtsinfos für Ihre Mandaten veröffentlichen. Bitte nehmen Sie sich auch die Zeit Ihren Kalender einzurichten. So können Mandanten schnell und einfach Kontakt zu Ihnen aufnehmen. ',
    ro: 'Ne bucurăm că și dumneavoastră doriți să faceți parte din comunitatea Anwado. Pentru a vă crea propria intrare, vă rugăm să vă înregistrați mai întâi. Apoi vă puteți ajusta cu ușurință profilul sau puteți publica informații juridice pentru clienții dumneavoastră. De asemenea, vă rugăm să vă faceți timp să vă configurați calendarul. În acest fel, clienții vă pot contacta rapid și ușor.',
  },
  Support_FAQ_Lawyer_Question_6: {
    en: 'I already have an entry on Anwado, but I did not create it myself?',
    de: 'Ich habe bereits einen Eintrag auf Anwado, habe diesen aber nicht selbst angelegt?',
    ro: 'Am deja o intrare pe Anwado, dar nu am creat-o eu însumi?',
  },
  Support_FAQ_Lawyer_Answer_6: {
    en: 'On Anwado we try to offer our clients the most comprehensive selection of suitable lawyers. If you already find an entry for you on Anwado, you can adopt it in the registration process. Simply start the registration process as a new lawyer and we will automatically suggest a suitable entry for you to take over. You can easily take over this entry by confirming the email address you have entered. Please contact the Anwado support if you need assistance.',
    de: 'Auf Anwado versuchen wir für Mandanten, eine möglichst umfassende Auswahl passender Rechtsanwälte anzubieten. Sollten Sie bereits einen Eintrag für Sich auf Anwado vorfinden, können Sie diesen im Registrierungsprozess übernehmen. Starten Sie einfach die Registrierung als neuer Anwalt und wir schlagen Ihnen automatisch den passenden Eintrag zur Übernahme vor. Sie können diesen bequem über die Bestätigung der hinterlegten Emailadresse übernehmen. Bitte wenden Sie sich an den Anwado Support falls Sie hierbei Unterstützung benötigen.',
    ro: 'Pe Anwado, încercăm să oferim clienților noștri o selecție cât mai cuprinzătoare de avocați potriviți. Dacă găsiți deja o intrare pentru dvs. pe Anwado, o puteți adopta în procesul de înregistrare. Pur și simplu începeți procesul de înregistrare ca avocat nou și vă vom sugera automat o intrare potrivită pentru a o prelua. Puteți transfera cu ușurință această intrare prin confirmarea adresei de e-mail pe care ați introdus-o. Vă rugăm să contactați serviciul de asistență Anwado dacă aveți nevoie de asistență.',
  },
  Support_FAQ_Lawyer_Question_7: {
    en: 'Why have only some lawyers deposited pictures and additional information?',
    de: 'Warum haben nur einige Anwälte Bilder und zusätzliche Informationen hinterlegt?',
    ro: 'De ce doar unii avocați au la dosar fotografii și informații suplimentare?',
  },
  Support_FAQ_Lawyer_Answer_7: {
    en: 'Through a paid Gold and Platinum entry on Anwado, attorneys have the opportunity to provide clients with additional information and images. This provides them with additional, specific information in advance, which, together with the ratings, expand and supplement the first impression.',
    de: 'Durch einen kostenpflichtigen Gold- und Platin-Eintrag auf Anwado haben Anwälte die Möglichkeit, den Mandanten weitere Informationen und Bilder zur Verfügung zu stellen. Sie erhalten dadurch bereits im Vorfeld zusätzliche, spezifische Informationen, die zusammen mit den Bewertungen den ersten Eindruck erweitern und ergänzen.',
    ro: 'Cu o intrare plătită de aur și platină pe Anwado, avocații au posibilitatea de a oferi clienților informații suplimentare și imagini. Astfel, aceștia primesc în avans informații suplimentare și specifice, care, împreună cu evaluările, îmbunătățesc și completează prima impresie.',
  },
  Support_FAQ_Lawyer_Question_8: {
    en: 'How do the different profiles differ?',
    de: 'Wie unterscheiden sich die verschiedenen Profile?',
    ro: 'Cum diferă diferitele profiluri?',
  },
  Support_FAQ_Lawyer_Answer_8_1: {
    en: `The basis of Anwado is a comprehensive database of lawyers admitted to practice in Germany.
    As soon as you register as a new lawyer or take over an existing entry about yourself, we welcome you as part of the Anwado community. You can now choose from three Anwado memberships:
        
        - Silver membership is free with Anwado. Here you have the opportunity to publish basic information about yourself and create your basic profile. 

        - The Gold membership extends your profile with the possibility to publish an individual description of your person, services and focus on Anwado. In addition, Gold members have the opportunity to publish legal information and conveniently offer appointments via Anwado.
      
        - Platinum members are particularly present on Anwado. Here are so optimally placed in the Anwado search and found before all other lawyers. Reach more clients comfortably.
        
        Detailed information about the available packages can also be found in our`,
    de: `Grundlage von Anwado ist eine umfangreiche Datenbank von in Deutschland zugelassenen Rechtsanwälten.
    Sobald Sie sich als neuer Anwalt registrieren oder einen bestehenden Eintrag über sich übernehmen, heißen wir Sie herzlich als Teil der Anwado Community willkommen. Sie haben nun die Auswahl aus drei Anwado Mitgliedschaften:
        
        • Die Silber Mitgliedschaft ist bei Anwado kostenlos. Sie haben hier die Möglichkeit, grundlegende Informationen über sich zu veröffentlichen und Ihr Profil zu erstellen. 
       
        • Die Gold Mitgliedschaft erweitert Ihr Profil um die Möglichkeit, eine individuelle Beschreibung Ihrer Person, Leistungen und Schwerpunkte sowie Profil- und Kanzleifotos auf Anwado zu veröffentlichen. Zudem haben Gold Mitglieder die Möglichkeit, Rechtsinfos zu veröffentlichen und Termine bequem über Anwado anzubieten.
       
        • Platin Mitlieder sind auf Anwado besonders präsent. Hier werden Sie optimal in der Anwado Suche platziert und vor allen anderen Anwälten gefunden. Erreichen Sie bequem mehr Mandanten.
       
        Detaillierte Informationen über die verfügbaren Pakete finden Sie auch in unserer`,
  },
  Support_FAQ_Lawyer_Answer_8_2: {
    en: 'price list.',
    de: 'Preisliste.',
    ro: 'Lista de prețuri.',
  },
  Support_FAQ_Lawyer_Question_9: {
    en: 'How can I publish a professional article?',
    de: 'Wie kann ich einen Fachartikel veröffentlichen?',
    ro: 'Cum pot publica un articol profesional?',
  },
  Support_FAQ_Lawyer_Answer_9: {
    en: 'Anwado offers all Gold and Platinum members the possibility to publish legal information on Anwado. To do so, go to the Legal Info tab in your personal area. Here you can view and manage your legal information and create new legal information. ',
    de: 'Anwado bietet allen Gold und Platin Mitgliedern die Möglichkeit, Rechtsinfos auf Anwado zu veröffentlichen. Gehen Sie in Ihrem persönlichen Bereich auf den Reiter Rechtsinfos. Hier können Sie Ihre Rechtsinfos einsehen, verwalten sowie neue Rechtsinfos verfassen.',
    ro: 'Anwado oferă tuturor membrilor Gold și Platinum posibilitatea de a publica informații juridice pe Anwado. Accesați fila Informații juridice din zona dvs. personală. Aici vă puteți vizualiza și gestiona informațiile juridice și puteți crea noi informații juridice.',
  },
  Support_FAQ_Lawyer_Question_10: {
    en: 'How does Anwado ensure the security of my data?',
    de: 'Wie sorgt Anwado für die Sicherheit meiner Daten?',
    ro: 'Cum asigură Anwado securitatea datelor mele?',
  },
  Support_FAQ_Lawyer_Answer_10_1: {
    en: 'Security is particularly important to us. Data protection is a top priority for us. You can find more information in our',
    de: 'Sicherheit ist uns besonders wichtig. Datenschutz hat für uns höchste Priorität. Weitere Informationen finden Sie in unserer',
    ro: 'Securitatea este deosebit de importantă pentru noi. Protecția datelor este o prioritate absolută pentru noi. Puteți găsi mai multe informații în pagina noastră',
  },
  Support_FAQ_Lawyer_Answer_10_2: {
    en: 'privacy policy.',
    de: 'Datenschutzerklärung.',
    ro: 'Politica de confidențialitate.',
  },
  Support_FAQ_Lawyer_Question_11: {
    en: 'How does Anwado ensure that ratings are handled seriously?',
    de: 'Wie stellt Anwado einen seriösen Umgang mit Bewertungen sicher?',
    ro: 'Cum se asigură Anwado că evaluările sunt tratate cu seriozitate?',
  },
  Support_FAQ_Lawyer_Answer_11_1: {
    en: 'Real lawyer ratings are our capital. This is the only way to find the right lawyer for you on Anwado. For this, we apply strict quality assurance measures. See also our',
    de: 'Echte Anwaltsbewertungen sind unser Kapital. Nur so finden Mandanten auf Anwado den richtigen Anwalt. Dafür gelten bei uns strenge Maßnahmen der Qualitätssicherung. Siehe auch unsere',
    ro: 'Ratingurile reale ale avocaților sunt capitalul nostru. Numai în acest fel clienții pot găsi avocatul potrivit pe Anwado. În acest scop, aplicăm măsuri stricte de asigurare a calității. Vedeți și pagina noastră',
  },
  Support_FAQ_Lawyer_Answer_11_2: {
    en: 'rating guidelines.',
    de: 'Bewertungsrichtlinien.',
    ro: 'Orientări de evaluare.',
  },
  Support_FAQ_Question_1: {
    en: 'What does Anwado offer?',
    de: 'Was bietet Anwado?',
    ro: 'Ce oferă Anwado?',
  },
  Support_FAQ_Answer_1: {
    en: 'On Anwado you can find the right lawyer from a large number of established lawyers and law firms in Germany. The recommendations of other clients, the information provided by the lawyers, numerous filter options and much more will help you.',
    de: 'Auf Anwado finden Sie den passenden Anwalt aus einer Vielzahl von niedergelassenen Anwälten und Kanzleien in Deutschland. Dabei helfen Ihnen die Empfehlungen anderer Mandanten, die von den Anwälten bereitgestellten Informationen, zahlreiche Filtermöglichkeiten und vieles mehr.',
    ro: 'Pe Anwado puteți găsi avocatul potrivit dintr-un număr mare de avocați și firme de avocatură stabilite în Germania. Vă vor ajuta recomandările altor clienți, informațiile furnizate de avocați, numeroasele opțiuni de filtrare și multe altele.',
  },
  Support_FAQ_Question_2: {
    en: 'Why make an online appointment through Anwado?',
    de: 'Warum über Anwado einen Online-Termin vereinbaren?',
    ro: 'De ce să faceți o programare online prin intermediul Anwado?',
  },
  Support_FAQ_Answer_2: {
    en: 'Via Anwado you can make an appointment directly online for an on-site meeting or a telephone appointment. This saves you the time-consuming search for an appointment by telephone.',
    de: 'Über Anwado können Sie direkt online einen Termin vereinbaren für eine Besprechung vor Ort oder einen Telefontermin. So sparen Sie sich die zeitaufwendige telefonische Terminsuche.',
    ro: 'Prin intermediul Anwado puteți face o programare direct online pentru o întâlnire la fața locului sau o programare telefonică. Acest lucru vă scutește de căutarea unei întâlniri prin telefon, care necesită mult timp.',
  },
  Support_FAQ_Question_3: {
    en: 'What criteria are used to sort the search results of the Anwado lawyer search?',
    de: 'Nach welchen Kriterien wird das Suchergebnis der Anwado Anwaltssuche sortiert?',
    ro: 'Ce criterii sunt folosite pentru a sorta rezultatele căutării de avocați în Anwado?',
  },
  Support_FAQ_Answer_3: {
    en: 'Our search results are listed according to how relevant and complete they are to your search, this includes in particular the rating and completeness of the profile. The more complete a profile is, including testimonials, availability of an online calendar, adding photos and services, the higher it will appear in your search results list. As a user, you have numerous filtering and sorting options to customize the results to your individual needs. Lawyer profiles that appear above this ranking are registered as platinum lawyers and marked as advertisements. ',
    de: 'Unsere Suchergebnisse werden danach aufgelistet, wie relevant und vollständig sie für Ihre Suche sind, dazu gehört insbesondere die Bewertung und die Vollständigkeit des Profils. Je vollständiger ein Profil ist, dazu gehören auch Erfahrungsberichte, die Verfügbarkeit eines Onlinekalenders, das Hinzufügen von Fotos und Dienstleistungen, desto weiter oben wird es in Ihrer Suchergebnisliste angezeigt. Als Nutzer stehen Ihnen zahlreiche Filter- und Sortieroptionen zur Verfügung, um die Ergebnisse an Ihre individuellen Bedürfnisse anzupassen. Anwaltsprofile, die über diesem Ranking auftauchen, sind als Platin-Anwälte registriert und als Anzeige gekennzeichnet. ',
    ro: 'Rezultatele căutării noastre sunt listate în funcție de cât de relevante și complete sunt pentru căutarea dvs., ceea ce include în special evaluarea și caracterul complet al profilului. Cu cât un profil este mai complet, incluzând mărturii, disponibilitatea unui calendar online, adăugarea de fotografii și servicii, cu atât va apărea mai sus în lista de rezultate ale căutării. În calitate de utilizator, aveți la dispoziție numeroase opțiuni de filtrare și sortare pentru a adapta rezultatele la nevoile dumneavoastră individuale. Profilurile de avocați care apar peste acest clasament sunt înregistrate ca avocați de platină și sunt marcate ca fiind o reclamă.',
  },
  Support_FAQ_Question_4: {
    en: 'How does Anwado ensure that ratings are handled seriously?',
    de: 'Wie stellt Anwado einen seriösen Umgang mit Bewertungen sicher?',
    ro: 'Cum se asigură Anwado că evaluările sunt tratate cu seriozitate?',
  },
  Support_FAQ_Answer_4: {
    en: 'Real reviews are our capital. This is the only way to find the right lawyer for you on Anwado. For this, we apply strict quality assurance measures. See also our rating guidelines.',
    de: 'Echte Bewertungen sind unser Kapital. Nur so finden Sie auf Anwado den richtigen Anwalt für sich. Dafür gelten bei uns strenge Maßnahmen der Qualitätssicherung. Siehe auch unsere Bewertungsrichtlinien.',
    ro: 'Comentariile reale sunt capitalul nostru. Acesta este singurul mod de a găsi avocatul potrivit pentru dumneavoastră pe Anwado. În acest scop, aplicăm măsuri stricte de asigurare a calității. Consultați și liniile directoare ale ratingului nostru.',
  },
  Support_FAQ_Question_5: {
    en: 'Why have only some lawyers deposited pictures and additional information?',
    de: 'Warum haben nur einige Anwälte Bilder und zusätzliche Informationen hinterlegt?',
    ro: 'De ce doar unii avocați au la dosar fotografii și informații suplimentare?',
  },
  Support_FAQ_Answer_5: {
    en: 'Through a paid Gold and Platinum entry on Anwado, attorneys have the opportunity to provide clients with additional information and images. This provides them with additional, specific information in advance, which, together with the ratings, expand and supplement the first impression.',
    de: 'Durch einen kostenpflichtigen Gold- und Platin-Eintrag auf Anwado haben Anwälte die Möglichkeit, den Mandanten weitere Informationen und Bilder zur Verfügung zu stellen. Sie erhalten dadurch bereits im Vorfeld zusätzliche, spezifische Informationen, die zusammen mit den Bewertungen den ersten Eindruck erweitern und ergänzen.',
    ro: 'Cu o intrare plătită de aur și platină pe Anwado, avocații au posibilitatea de a oferi clienților informații suplimentare și imagini. Astfel, aceștia primesc în avans informații suplimentare și specifice, care, împreună cu evaluările, îmbunătățesc și completează prima impresie.',
  },
  Support_FAQ_Question_6: {
    en: 'How does Anwado ensure the security of my data?',
    de: 'Wie sorgt Anwado für die Sicherheit meiner Daten?',
    ro: 'Cum asigură Anwado securitatea datelor mele?',
  },
  Support_FAQ_Answer_6: {
    en: 'Security is particularly important to us. Data protection is a top priority for us. You can find more information in our privacy policy.',
    de: 'Sicherheit ist uns besonders wichtig. Datenschutz hat für uns höchste Priorität. Weitere Informationen finden Sie in unserer Datenschutzerklärung.',
    ro: 'Securitatea este deosebit de importantă pentru noi. Protecția datelor este o prioritate absolută pentru noi. Puteți găsi mai multe informații în politica noastră de confidențialitate.',
  },
  Support_FAQ_Question_7: {
    en: 'How is the lawyer paid?',
    de: 'Wie wird der Anwalt bezahlt?',
    ro: 'Cum este plătit avocatul?',
  },
  Support_FAQ_Answer_7: {
    en: 'How will the lawyer be paid? At the beginning of the conversation with the lawyer, please also agree on the cost of the consultation or assumption of the mandate.',
    de: 'Bitte vereinbaren Sie zu Beginn des Gesprächs mit dem Anwalt die Kosten der Beratung.',
    ro: 'Vă rugăm să conveniți asupra costurilor consultării la începutul conversației cu avocatul.',
  },
  Support_FAQ_Question_8: {
    en: 'How can I search for a lawyer?',
    de: 'Wie kann ich nach einem Anwalt suchen?',
    ro: 'Cum pot să caut un avocat?',
  },
  Support_FAQ_Answer_8: {
    en: `Anwado offers you various options to search specifically for a lawyer. Simply use the intuitive search on our homepage. 

Here you have the possibility to search for a specific lawyer, lawyers in a specific location or in your vicinity as well as on the basis of suitable legal fields. Of course, you can also use various criteria to further narrow down your search.
        
Tip: Save the lawyers you have already found as favorites so that you can easily find them again later.`,
    de: `Anwado bietet Ihnen verschiedene Möglichkeiten, um gezielt nach einem Anwalt zu suchen. Nutzen Sie einfach die intuitive Anwado Suche. 
  
Hier haben Sie die Möglichkeit nach einem konkreten Anwalt, Anwälten an einem bestimmten Ort oder in Ihrer Nähe sowie anhand von passenden Rechtsgebieten zu suchen. Natürlich könne Sie auch verschiedene Kriterien nutzen, um die Suche weiter einzugrenzen.
    
Tipp: Speichern Sie sich bereits gefundene Anwälte als Favoriten, so können Sie diese später bequem wieder finden.`,
  },
  Support_FAQ_Question_9: {
    en: 'How can I reset my password?',
    de: 'Wie kann ich mein Passwort zurücksetzen?',
    ro: 'Cum îmi pot reseta parola?',
  },
  Support_FAQ_Answer_9: {
    en: 'You can conveniently reset your password via the "Reset password" link. To do this, simply go to "Login". There you will find the link. Follow the instructions to reset your password.',
    de: 'Sie können Ihr Passwort bequem über den Link "Passwort zurücksetzen" zurücksetzen. Gehen Sie dazu einfach auf "Login". Dort finden Sie den Link. Folgen Sie den Anweisungen, um Ihr Passwort zurückzusetzen.',
    ro: 'Vă puteți reseta în mod convenabil parola prin intermediul linkului "Resetare parolă". Pentru a face acest lucru, mergeți la "Login". Acolo veți găsi linkul. Urmați instrucțiunile pentru a vă reseta parola.',
  },
  Support_FAQ_Question_10: {
    en: 'Who writes the legal info?',
    de: 'Wer schreibt die Rechtsinfos?',
    ro: 'Cine scrie informațiile juridice?',
  },
  Support_FAQ_Answer_10: {
    en: 'Legal info is written by our lawyers as well as by the Anwado editorial team, who provide you with comprehensive information on current case law as well as other legally interesting topics. This will give you an insight into the specific areas of law covered by our lawyers.',
    de: 'Rechtsinfos werden sowohl von unseren Anwälten als auch von der Anwado Redaktion verfasst. Die Rechtsinfos informieren Sie über die aktuelle Rechtsprechung sowie weitere rechtlich interessante Themen. So erhalten Sie einen Einblick in die spezifischen Rechtsgebiete unserer Anwälte.',
    ro: 'Informațiile juridice sunt redactate de către avocații noștri, precum și de către echipa editorială Anwado. Informațiile juridice vă informează despre jurisprudența actuală, precum și despre alte subiecte interesante din punct de vedere juridic. Acest lucru vă oferă o perspectivă asupra domeniilor specifice de drept acoperite de avocații noștri.',
  },
  Support_FAQ_Question_11: {
    en: 'How up-to-date is the legal information?',
    de: 'Wie aktuell sind die Rechtsinfos?',
    ro: 'Cât de actualizate sunt informațiile juridice?',
  },
  Support_FAQ_Answer_11: {
    en: 'Both the Anwado editorial team and our lawyers are constantly writing new legal tips, which you can easily find via our legal info search or directly in the profile of the respective lawyer. You can tell by the date of the article when it was written or last updated.',
    de: 'Sowohl die Anwado Redaktion als auch unsere Anwälte verfassen laufend neue Rechtsinfos, die Sie bequem über unsere Rechtsinfosuche oder direkt im Profil des jeweiligen Anwalts finden können. Sie können am Datum des Artikels erkennen, wann dieser verfasst oder zuletzt aktualisiert wurde.',
    ro: 'Atât echipa editorială Anwado, cât și avocații noștri scriu în mod constant noi informații juridice, pe care le puteți găsi cu ușurință prin intermediul căutării noastre de informații juridice sau direct în profilul avocatului respectiv. Puteți vedea din data articolului când a fost scris sau actualizat ultima dată.',
  },
  Support_FAQ_Question_12: {
    en: 'Can I contact Anwado with legal questions?',
    de: 'Kann ich mich mit juristischen Fragen an Anwado wenden?',
    ro: 'Pot contacta Anwado pentru întrebări de natură juridică?',
  },
  Support_FAQ_Answer_12: {
    en: `In the Federal Republic of Germany, only lawyers, patent attorneys, tax advisors and notaries are permitted to provide legal advice. In addition, institutions may also provide legal advice if they have a corresponding official permit to do so. Therefore, Anwado is not allowed to answer legal questions or to give information that is in the direction of legal advice according to the Legal Services Act of July 1, 2008 - as amended!

However, we will help you to find a competent legal advisor who will support you in solving your legal problem. Through our portal, we simplify your contact and access to competent lawyers.`,
    de: `In der Bundesrepublik Deutschland ist die Rechtsberatung nur Rechtsanwälten, Patentanwälten, Steuerberatern und Notaren erlaubt. Darüber hinaus dürfen auch Institutionen Rechtsberatung erteilen, wenn sie eine entsprechende behördliche Erlaubnis dazu haben. Anwado darf daher keine Rechtsfragen beantworten oder Auskünfte erteilen, die in die Richtung einer Rechtsberatung nach dem Rechtsdienstleistungsgesetz vom 1. Juli 2008 - in seiner jeweiligen Fassung - gehen!

Wir helfen Ihnen jedoch dabei, einen kompetenten Rechtsberater zu finden, der Sie bei der Lösung Ihres Rechtsproblems unterstützt. Über unser Portal vereinfachen wir Ihnen den Kontakt und den Zugang zu kompetenten Anwälten.`,
  },
  Support_Things_To_Know: {
    en: 'Things to know',
    de: 'Wissenswertes',
    ro: 'Lucruri de știut',
  },
  Support_Features_Title: {
    en: 'Anwado is legal advice rethought',
    de: 'Anwado ist Rechtsberatung neu gedacht',
    ro: 'Anwado este un sfat juridic regândit',
  },
  Support_Features_Description: {
    en: 'Here, clients can find the right lawyer for them and make an appointment directly online with the lawyers registered on Anwado. This saves clients the time-consuming process of contacting many lawyers by phone until the right lawyer is found.',
    de: 'Hier finden Mandanten den für sie richtigen Anwalt und können bei den auf Anwado registrierten Anwälten direkt online einen Termin vereinbaren. Dabei sparen sich Mandanten die zeitaufwendige telefonische Kontaktaufnahme mit vielen Anwälten, bis der richtige Anwalt gefunden ist.',
    ro: 'Aici, clienții pot găsi avocatul potrivit pentru ei și pot face o programare direct online cu avocații înregistrați pe Anwado. Astfel, clienții scapă de procesul consumator de timp de a contacta telefonic mai mulți avocați până când găsesc avocatul potrivit.',
  },
  // Abous Us
  AU_: {
    en: '',
    de: '',
  },
  AU_Info_1_Box_1_Title: {
    en: 'Increase online reputation through reviews',
    de: 'Online Reputation durch Bewertungen steigern',
    ro: 'Creșterea reputației online prin recenzii',
  },
  AU_Info_1_Box_1_Description: {
    en: 'With more ratings you reach more clients',
    de: 'Mit mehr Bewertungen erreichen Sie mehr Mandanten',
    ro: 'Cu mai multe ratinguri ajungeți la mai mulți clienți',
  },
  AU_Info_1_Box_2_Title: {
    en: 'Personal lawyer profile',
    de: 'Persönliches Anwalts-Profil',
    ro: 'Profilul avocatului personal',
  },
  AU_Info_1_Box_2_Description: {
    en: 'Your window for online client acquisition',
    de: 'Ihr Fenster für die online Mandantenaquise',
    ro: 'Fereastra ta pentru achiziția de clienți online',
  },
  AU_Info_1_Box_3_Title: {
    en: 'Law firms',
    de: 'Online-Terminvereinbarung',
    ro: 'Programare online',
  },
  AU_Info_1_Box_3_Description: {
    en: 'Potential clients can make an appointment with you directly',
    de: 'Potentielle Mandanten können mit Ihnen direkt einen Termin',
    ro: 'Clienții potențiali pot face o programare direct la dumneavoastră.',
  },
  AU_Info_1_Box_4_Title: {
    en: 'Publish legal information directly',
    de: 'Rechtsinfos direkt veröffentlichen',
    ro: 'Publicarea directă a informațiilor juridice',
  },
  AU_Info_1_Box_4_Description: {
    en: 'Show your professional expertise and increase your visibility',
    de: 'Zeigen Sie Ihre fachliche Expertise und steigern Sie Ihre Sichtbarkeit',
    ro: 'Arată-ți expertiza profesională și crește-ți vizibilitatea',
  },
  // Contact Us
  CU_Form_Meta_Title_1: {
    en: 'Anwado - Contact Us',
    de: 'Anwado - Kontaktieren Sie uns',
    ro: 'Anwado - Contactați-ne',
  },
  CU_Form_Meta_Description_1: {
    en: 'Have questions or concerns? Reach out to Anwado via our contact form and we will get back to you as soon as possible.',
    de: 'Haben Sie Fragen oder Anliegen? Kontaktieren Sie Anwado über unser Kontaktformular und wir werden uns schnellstmöglich bei Ihnen melden.',
    ro: 'Aveți întrebări sau nelămuriri? Contactați Anwado prin intermediul formularului nostru de contact și vă vom răspunde cât mai curând posibil.',
  },
  CU_Form_Bug_Input_Title: {
    en: 'How can we help you?',
    de: 'Wie können wir Ihnen helfen?',
    ro: 'Cum vă putem ajuta?',
  },
  CU_Form_Bug_Input_Placeholder: {
    en: 'Tell us something about the problem....',
    de: 'Erzählen Sie uns etwas über das Problem...',
    ro: 'Spuneți-ne despre problemă...',
  },
  CU_Form_Action_Button: {
    en: 'Send',
    de: 'Abschicken',
    ro: 'Trimiteți',
  },
  CU_Image_Title: {
    en: 'We would love to hear from you!',
    de: 'Wir würden uns freuen von Ihnen zu hören!',
    ro: 'Ne-ar plăcea să ne contactați!',
  },
  CU_Form_Checkbox_Title_1: {
    en: 'I have read and accepted ',
    de: 'Ich habe ',
    ro: 'Eu am',
  },
  CU_Form_Checkbox_Title_2: {
    en: ' the privacy policy.',
    de: ' die Datenschutzerklärung ',
    ro: ' politica de confidențialitate',
  },
  CU_Form_Checkbox_Title_3: {
    en: '',
    de: ' gelesen und akzeptiert.',
    ro: ' citit și acceptat.',
  },
  CU_Image_Description: {
    en: 'Use our contact form and we will get back to you as soon as possible.',
    de: 'Nutzen Sie unser Kontaktformular und wir werden uns umgehend bei Ihnen melden.',
    ro: 'Folosiți formularul nostru de contact și vă vom răspunde cât mai curând posibil.',
  },
  // Fair use Policy
  FUP_Meta_Title_1: {
    en: 'Anwado - Our Quality Promise',
    de: 'Anwado - Unser Qualitätsversprechen',
    ro: 'Anwado - Promisiunea noastră de calitate',
  },
  FUP_Meta_Description_1: {
    en: 'Anwado offers a unique experience through high-quality services. Learn about our values and commitment to providing excellent service.',
    de: 'Anwado bietet ein einzigartiges Erlebnis durch hochwertige Dienstleistungen. Erfahren Sie mehr über unsere Werte und unser Engagement für exzellenten Service.',
    ro: 'Anwado oferă o experiență unică prin servicii de calitate. Aflați mai multe despre valorile noastre și angajamentul nostru față de excelența serviciilor.',
  },
  FUP_Header_Title: {
    en: 'Quality promise',
    de: 'Qualitätsversprechen',
    ro: 'Promisiunea de calitate',
  },
  FUP_Header_Description: {
    en: 'Our values, our claim',
    de: 'Unsere Werte, unser Anspruch',
    ro: 'Valorile noastre, revendicarea noastră',
  },
  FUP_Header_Action_Button_1: {
    en: 'Anwado',
    de: 'Anwado',
    ro: 'Anwado',
  },
  FUP_Header_Action_button_2: {
    en: 'Contact Anwado',
    de: 'Anwado kontaktieren',
    ro: 'Contact Anwado',
  },
  FUP_Header_Action_button_3: {
    en: 'Register',
    de: 'Registrieren',
    ro: 'Înregistrare',
  },
  FUP_Info_1_Title: {
    en: 'We are a special platform',
    de: 'Wir sind eine besondere Plattform',
    ro: 'Suntem o platformă specială',
  },
  FUP_Info_1_Description: {
    en: 'We are proud to be a special platform. Our offer and services provide our customers with a unique experience. We are committed to maintaining and developing this uniqueness on a daily basis, in order to always be able to offer our customers the best possible service.',
    de: 'Wir sind stolz darauf, eine besondere Plattform zu sein. Unser Angebot und unsere Dienstleistungen bieten unseren Nutzern ein einzigartiges Erlebnis. Wir setzen uns täglich dafür ein, diese Einzigartigkeit beizubehalten und weiterzuentwickeln, um unseren Nutzern stets den bestmöglichen Service anbieten zu können.',
    ro: 'Suntem mândri că suntem o platformă specială. Oferta și serviciile noastre oferă o experiență unică pentru utilizatorii noștri. Ne angajăm să menținem și să dezvoltăm această unicitate în fiecare zi, astfel încât să putem oferi întotdeauna utilizatorilor noștri cele mai bune servicii posibile.',
  },
  FUP_Info_1_Box_1_Title_1: {
    en: 'Innovation',
    de: 'Innovation',
    ro: 'Inovație',
  },
  FUP_Info_1_Box_1_Description_1: {
    en: 'We are constantly on the lookout for new technologies and ways to improve our platform and offerings and to keep them keep them up to date.',
    de: 'Wir sind ständig auf der Suche nach neuen Technologien und Wegen, um unsere Plattform und Angebote zu verbessern und auf dem neuesten Stand zu halten.    ',
    ro: 'Căutăm în mod constant noi tehnologii și modalități de a ne îmbunătăți și de a ne menține platforma și ofertele la zi.    ',
  },
  FUP_Info_1_Box_1_Title_2: {
    en: 'Sustainability',
    de: 'Nachhaltigkeit',
    ro: 'Sustenabilitate',
  },
  FUP_Info_1_Box_1_Description_2: {
    en: 'We pay attention to the sustainable use of with resources and give the information and information and data of our users the highest highest priority',
    de: 'Wir achten auf einen nachhaltigen Umgang mit Ressourcen und und räumen den Daten unserer Nutzer den höchsten Stellenwert ein',
    ro: 'Acordăm atenție utilizării durabile a resurselor și acordăm cea mai mare valoare datelor utilizatorilor noștri.',
  },
  FUP_Info_1_Box_1_Title_3: {
    en: 'Transparency',
    de: 'Transparenz',
    ro: 'Transparență',
  },
  FUP_Info_1_Box_1_Description_3: {
    en: 'We are transparent about our terms and conditions and privacy policies and give our customers full control over their data',
    de: 'Wir sind transparent in Bezug auf unsere Geschäftsbedingungen und Datenschutzrichtlinien und geben unseren Kunden die volle Kontrolle über ihre Daten',
    ro: 'Suntem transparenți în ceea ce privește termenii și condițiile noastre și politicile de confidențialitate și oferim clienților noștri controlul deplin asupra datelor lor.',
  },
  FUP_Info_CheckList_1_1: {
    en: 'Technological innovations',
    de: 'Technologische Innovationen',
    ro: 'Inovații tehnologice',
  },
  FUP_Info_CheckList_1_2: {
    en: 'Process innovation',
    de: 'Prozessinnovation',
    ro: 'Inovarea proceselor',
  },
  FUP_Info_CheckList_1_3: {
    en: 'User-centered innovation',
    de: 'Nutzerzentrierte Innovation',
    ro: 'Inovarea centrată pe utilizator',
  },
  FUP_Info_CheckList_2_1: {
    en: 'Climate-neutral data centers',
    de: 'Klimaneutrale Rechenzentren',
    ro: 'Centre de date neutre din punct de vedere climatic',
  },
  FUP_Info_CheckList_2_2: {
    en: '100% with renewable energies',
    de: '100% mit erneuerbaren Energien',
    ro: '100% cu energii regenerabile',
  },
  FUP_Info_CheckList_2_3: {
    en: 'Data Protection Regulation (DSGVO) compliant',
    de: 'Datenschutzgrundverordnung (DSGVO) konform',
    ro: 'Conform cu Regulamentul privind protecția datelor (DSGVO)',
  },
  FUP_Info_CheckList_3_1: {
    en: 'Fair business conditions',
    de: 'Faire Geschäftsbedingungen',
    ro: 'Termeni și condiții echitabile',
  },
  FUP_Info_CheckList_3_2: {
    en: 'Clear data protection conditions',
    de: 'Klare Datenschutzbedingungen',
    ro: 'Condiții clare de protecție a datelor',
  },
  FUP_Info_CheckList_3_3: {
    en: 'Transparent evaluation guidelines',
    de: 'Transparente Bewertungsrichtlinien',
    ro: 'Linii directoare de evaluare transparente',
  },
  FUP_Info_2_Title: {
    en: 'Our goal is to make it as easy as possible for clients to find the find the right lawyer and contact him directly!',
    de: 'Unser Ziel ist es, dass Mandanten auf möglichst einfache Weise den passenden Anwalt finden und direkt kontaktieren können!',
    ro: 'Scopul nostru este de a face cât mai ușor posibil pentru clienți să găsească avocatul potrivit și să-l contacteze direct!',
  },
  FUP_Info_2_Description: {
    en: 'On Anwado you can find the right lawyer among most established lawyers and law firms in Germany. Arrange directly an appointment online for an on-site meeting or for a telephone consultation. This saves you the time-consuming search for an appointment by telephone.',
    de: 'Auf Anwado finden Sie den passenden Anwalt unter vielen niedergelassenen Anwälten und Kanzleien in Deutschland. Vereinbaren Sie direkt online einen Termin für eine Besprechung vor Ort oder für eine Telefonberatung. So sparen Sie sich die zeitaufwendige telefonische Terminsuche.',
    ro: 'Pe Anwado puteți găsi avocatul potrivit printre numeroși avocați și firme de avocatură din Germania. Faceți o programare direct online pentru o întâlnire la fața locului sau pentru o consultație telefonică. Astfel, economisiți căutarea unei întâlniri prin telefon, care necesită mult timp.',
  },
  FUP_Info_2_Box_1_Title: {
    en: 'For clients',
    de: 'Für Mandanten',
    ro: 'Pentru clienți',
  },
  FUP_Info_2_Box_1_Description: {
    en: 'Find a lawyer, make an online appointment and clarify all legal issues.',
    de: 'Finden Sie einen Anwalt, vereinbaren Sie einen online Termin und klären Sie alle Rechtsfragen.',
    ro: 'Găsiți un avocat, faceți o programare online și clarificați toate problemele juridice.',
  },
  FUP_Info_2_Box_2_Title: {
    en: 'For lawyers',
    de: 'Für Anwälte',
    ro: 'Pentru avocați',
  },
  FUP_Info_2_Box_2_Description: {
    en: 'Save time managing client requests and benefit from more growth through modern online marketing.',
    de: 'Sparen Sie Zeit bei der Verwaltung der Mandantenanfragen und profitieren Sie von mehr Wachstum durch modernes Online-Marketing.',
    ro: 'Economisiți timp în gestionarea cererilor de informații ale clienților și beneficiați de o creștere mai mare prin intermediul marketingului online modern.',
  },
  FUP_Info_2_Box_3_Title: {
    en: 'Everything on one platform',
    de: 'Alles auf einer Plattform',
    ro: 'Totul pe o singură platformă',
  },
  FUP_Info_2_Box_3_Description: {
    en: 'The all-in-one solution for clients and lawyers.',
    de: 'Die all-in-one Lösung für Mandanten und Anwälte.',
    ro: 'Soluția all-in-one pentru clienți și avocați.',
  },
  FUP_Info_2_Box_4_Title: {
    en: 'Independent',
    de: 'Unabhängig',
    ro: 'Independent',
  },
  FUP_Info_2_Box_4_Description: {
    en: 'Evaluations take place completely independently of the package, without us as a provider having any provider have any influence on have any influence on the ratings. Lawyers decide about client structure without being restricted by the be restricted by the platform.',
    de: 'Bewertungen finden komplett unabhängig vom Rechtsanwaltspaket statt, ohne das wir als Anbieter Einfluss auf die Bewertungen haben.',
    ro: 'Evaluările au loc în mod complet independent de pachetul de asistență juridică, fără ca noi, în calitate de furnizor, să avem vreo influență asupra evaluărilor.',
  },
  FUP_Info_2_Box_5_Title: {
    en: 'Responsible',
    de: 'Verantwortungsvoll',
    ro: 'Responsabil',
  },
  FUP_Info_2_Box_5_Description: {
    en: 'At Anwado, we make no compromises when it comes to the the security of your data and fully implement the Data Protection Regulation (DSGVO) implemented. We understand the importance of protecting personal data and have taken extensive measures in place to ensure that all data data stored on our platform is protected is protected and secure.',
    de: 'Bei Anwado machen wir keine Abstriche, wenn es um die Sicherheit Ihrer Daten geht und setzen die Datenschutzgrundverordnung (DSGVO) vollständig um. Wir verstehen die Wichtigkeit des Schutzes persönlicher Daten und haben umfangreiche Maßnahmen ergriffen, um sicherzustellen, dass alle Daten, die auf unserer Plattform gespeichert werden, geschützt und sicher sind.',
    ro: 'La Anwado, nu facem rabat de la nimic atunci când vine vorba de securitatea datelor dumneavoastră și implementăm pe deplin Regulamentul general privind protecția datelor (GDPR). Înțelegem importanța protejării datelor cu caracter personal și am luat măsuri extinse pentru a ne asigura că toate datele stocate pe platforma noastră sunt protejate și sigure.',
  },
  FUP_Info_2_Box_6_Title: {
    en: 'Sustainable',
    de: 'Nachhaltig',
    ro: 'Durabil',
  },
  FUP_Info_2_Box_6_Description: {
    en: 'For our IT infrastructure, we work with the infrastructure provider Scaleway. This enables us to use climate-neutral data centers that are powered 100% by renewable energies.',
    de: 'Für unsere IT-Infrastruktur arbeiten wir mit dem Infrastrukturanbieter Scaleway zusammen. Dies ermöglicht uns die Nutzung klimaneutraler Rechenzentren, die zu 100 % mit erneuerbaren Energien betrieben werden.',
    ro: 'Pentru infrastructura noastră IT, lucrăm cu furnizorul de infrastructură Scaleway. Acest lucru ne permite să folosim centre de date neutre din punct de vedere climatic, care sunt alimentate cu energie 100% regenerabilă.',
  },
  FUP_Info_3_Action_Button_1: {
    en: 'Lawyers near you',
    de: 'Rechtsanwälte in Ihrer Nähe',
    ro: 'Avocații din zona dumneavoastră',
  },
  FUP_Info_1_Box_1_Title: {
    en: 'Legal advice rethought',
    de: 'Rechtsberatung neu gedacht',
    ro: 'Consultanță juridică regândită',
  },
  FUP_Info_3_Action_Button_2: {
    en: 'Go to FAQ',
    de: 'Zu den FAQ',
    ro: 'La FAQ',
  },
  FUP_Info_3_Action_Button_3: {
    en: 'Find a lawyer',
    de: 'Anwalt finden',
    ro: 'Găsiți un avocat',
  },
  // Landing Page Bottom
  LPB_Functions_Title_1: {
    en: 'Find a lawyer in Germany',
    de: 'Rechtsanwalt in Deutschland finden',
    ro: 'Găsiți un avocat în Germania',
  },
  LPB_Functions_Description: {
    en: 'By providing a user-friendly platform, Anwado enables clients to search specifically for lawyers, view reviews and make appointments online. Lawyers, on the other hand, can strengthen their presence by showcasing their expertise and being recommended by satisfied clients. Anwado provides an online platform where clients can search for lawyers based on specialty, location and ratings. Our platform also makes it easy to set up appointments between clients and lawyers. Lawyers can create their profiles, showcase their expertise and improve their marketing to attract more clients.',
    de: 'Durch die Bereitstellung einer benutzerfreundlichen Plattform ermöglicht Anwado Mandanten, gezielt nach Anwälten zu suchen, Bewertungen einzusehen und online Termine zu vereinbaren. Anwälte können auf der anderen Seite ihre Präsenz stärken, indem sie ihr Fachwissen präsentieren und von zufriedenen Mandanten empfohlen werden. Anwado bietet eine Online-Plattform, auf der Mandanten nach Anwälten suchen können, basierend auf Rechtsgebiet, Standort und Bewertungen. Die Anwado Plattform ermöglicht zudem die einfache Vereinbarung von Terminen zwischen Mandanten und Anwälten. Anwälte können ihre Profile erstellen, ihr Fachwissen präsentieren und ihr Marketing verbessern, um mehr Mandanten zu gewinnen.',
    ro: 'Oferind o platformă ușor de utilizat, Anwado permite clienților să caute în mod special avocați, să vadă recenzii și să facă programări online. Avocații, pe de altă parte, își pot consolida prezența prin prezentarea expertizei lor și prin faptul că sunt recomandați de clienți mulțumiți. Anwado oferă o platformă online în care clienții pot căuta avocați în funcție de domeniul juridic, locație și evaluări. Platforma Anwado facilitează, de asemenea, organizarea de întâlniri între clienți și avocați. Avocații își pot crea profiluri, își pot prezenta expertiza și își pot îmbunătăți marketingul pentru a atrage mai mulți clienți.',
  },
  LPB_Functions_Action_Button_1: {
    en: 'Anwado',
    de: 'Anwado',
    ro: 'Anwado',
  },
  LPB_Info_1_Title: {
    en: 'Are you a lawyer? Create profile now',
    de: 'Sie sind Anwalt? Jetzt Profil erstellen',
    ro: 'Sunteți avocat? Creați un profil acum',
  },
  LPB_Info_1_Description: {
    en: 'Increase online presence and generate more mandates through digital visibility',
    de: 'Online-Präsenz erhöhen und durch digitale Sichtbarkeit mehr Mandate generieren',
    ro: 'Creșterea prezenței online și generarea mai multor mandate prin vizibilitate digitală',
  },
  LPB_Info_1_Action_Button_1: {
    en: 'To the lawyer portal',
    de: 'Mehr erfahren',
    ro: 'Aflați mai multe',
  },
  LPB_Features_Tag: {
    en: 'What we offer',
    de: 'Was wir bieten',
    ro: 'Ce oferim',
  },
  LPB_Features_Title: {
    en: 'We take care of you',
    de: 'Wir kümmern uns um Sie',
    ro: 'Noi avem grijă de tine',
  },
  LPB_Features_Description: {
    en: `Every problem needs a suitable solution. Every person seeking advice needs the right lawyer. 

With Anwado you can find the right lawyer quickly, easily and online. Book a consultation appointment on the spot or a telephone consultation right away.`,
    de: `Jedes Problem braucht eine passende Lösung. Jeder Ratsuchende braucht den passenden Anwalt. 

Mit Anwado finden Sie den passenden Anwalt schnell, einfach und online. Buchen Sie sich gleich einen Beratungstermin vor Ort, oder eine Telefonberatung.`,
  },
  LPB_Features_Box_1_Title: {
    en: 'Incredibly fast',
    de: 'Unglaublich schnell',
    ro: 'Incredibil de rapid',
  },
  LPB_Features_Box_2_Title: {
    en: 'No cost',
    de: 'Keine Kosten',
    ro: 'Fără costuri',
  },
  LPB_Features_Box_2_Description: {
    en: 'For those seeking advice, the Anwado service is free of charge',
    de: 'Für Ratsuchende ist der Anwado-Service kostenfrei',
    ro: 'Serviciul Anwado este gratuit pentru cei care solicită consiliere.',
  },
  LPB_Features_Box_3_Title: {
    en: 'Selection of top lawyers',
    de: 'Auswahl an Top-Anwälten',
    ro: 'Selectarea avocaților de top',
  },
  LPB_Features_Box_3_Description: {
    en: 'With Anwado you will find the top lawyers in your area',
    de: 'Mit Anwado finden Sie die Top-Anwälte in Ihrer Umgebung',
    ro: 'Cu Anwado puteți găsi cei mai buni avocați din zona dvs.',
  },
  LPB_Features_Box_Action_Button_1: {
    en: 'Start search now',
    de: 'Jetzt Suche starten',
    ro: 'Începeți căutarea acum',
  },
  LPB_Top_Lawyers_Title: {
    en: 'No contracts. Talk to top lawyers without obligation',
    de: 'Keine Verträge. Unverbindlich mit Top-Anwälten sprechen',
    ro: 'Fără contracte. Vorbiți cu avocați de top fără obligații',
  },
  LPB_Top_Lawyers_Description: {
    en: 'This is how legal advice works today',
    de: 'So geht Rechtsberatung heute',
    ro: 'Iată cum funcționează astăzi consilierea juridică',
  },
  LPB_Top_Lawyers_Action_Button_1: {
    en: 'FAQ',
    de: 'FAQ',
    ro: 'ÎNTREBĂRI FRECVENTE',
  },
  LPB_Top_Lawyers_Action_Button_2: {
    en: 'Start search',
    de: 'Suche starten',
    ro: 'Începeți căutarea',
  },
  LPB_Lawyer_List_Title: {
    en: 'An expert for every concern',
    de: 'Für jedes Anliegen ein Experte',
    ro: 'Un expert pentru fiecare problemă',
  },
  LPB_Lawyer_List_Description: {
    en: 'Real reviews help',
    de: 'Echte Bewertungen helfen dabei',
    ro: 'Recenzile reale ajută',
  },
  LPB_Functions_Title_3: {
    en: 'Anwado suggested a specialist lawyer for my problem within seconds and I was able to make an online appointment immediately. I described my problem and the lawyer was very good and helped me.',
    de: 'Anwado hat innerhalb von Sekunden einen Fachanwalt für mein Problem vorgeschlagen und ich konnte sofort einen Termin online vereinbaren. Ich habe mein Problem geschildert, der Anwalt war super und konnte mir weiterhelfen.',
    ro: 'Anwado mi-a sugerat în câteva secunde un avocat specializat pentru problema mea și am putut face imediat o programare online. Am descris problema mea, avocatul a fost minunat și a reușit să mă ajute.',
  },
  // Article Section
  AS_Latest_Info: {
    en: 'Latest legal information and articles',
    de: 'Neueste Rechtsinfos und Fachbeiträge',
    ro: 'Cele mai recente informații și articole juridice',
  },
  AS_Latest_From: {
    en: 'Latest posts from',
    de: 'Neuste Posts von',
    ro: 'Ultimele postări din',
  },
  AS_Legal_Info: {
    en: 'Legal info',
    de: 'Rechtsinfos',
    ro: 'Informații juridice',
  },
  AS_Legal_Info_Description: {
    en: 'With legal info from Anwado lawyers you can find a solution to your problem',
    de: 'Mit den Rechtsinfos von Anwado-Anwälten können Sie eine Lösung für Ihr Problem finden',
    ro: 'Cu informații juridice de la avocații Anwado puteți găsi o soluție la problema dvs.',
  },
  // Subscription Badges
  SB_Silver: {
    en: 'Verified access on Anwado.',
    de: 'Verifizierter Zugang auf Anwado.',
    ro: 'Acces verificat pe Anwado.',
  },
  SB_Gold: {
    en: 'Paying customer of Anwado. This has no influence on the ratings or on the place in the Anwado ranking.',
    de: 'Zahlender Kunde von Anwado. Dies hat keinen Einfluss auf die Bewertungen oder auf den Platz im Anwado-Ranking.',
    ro: 'Client plătitor al Anwado. Acest lucru nu are nicio influență asupra evaluărilor sau asupra locului în clasamentul Anwado.',
  },
  SB_Platinum: {
    en: 'Paying customer of Anwado. This ad is not related to user ratings and does not affect the place in Anwado ranking.',
    de: 'Zahlender Kunde von Anwado. Diese Anzeige steht in keinem Zusammenhang mit Bewertungen von Nutzern und hat Einfluss auf den Platz im Anwado-Ranking.',
    ro: 'Client plătitor al Anwado. Acest anunț nu are legătură cu evaluările utilizatorilor și nu influențează poziția în clasamentul Anwado.',
  },
  // Lawyer Landing Page
  LLP_Info_1_Badge_Title: {
    en: 'New',
    de: 'Neu',
    ro: 'Nou',
  },
  LLP_No_Lawyers_Found: {
    en: 'No lawyer found... Please try again.',
    de: 'Kein Anwalt gefunden... Bitte versuchen Sie es nocheinmal.',
    ro: 'Nici un avocat nu a fost găsit... Vă rugăm să încercați din nou.',
  },
  LLP_Info_1_Badge_Description: {
    en: 'Now Anwado online appointment possible',
    de: 'Jetzt Online-Terminvereinbarung möglich',
    ro: 'Programările online sunt acum posibile',
  },
  LLP_Info_1_Meta_Title_1: {
    en: 'Anwado - The perfect appearance for lawyers',
    de: 'Anwado - Der perfekte Auftritt für Anwälte',
    ro: 'Anwado - Aspectul perfect pentru avocați',
  },
  LLP_Info_1_Meta_Description_1: {
    en: 'With your profile on Anwado you will be found easier and faster by potential clients',
    de: 'Mit Ihrem Profil auf Anwado werden Sie von potentiellen Mandanten einfacher und schneller gefunden.',
    ro: 'Cu profilul dvs. pe Anwado, potențialii clienți vă vor găsi mai ușor și mai rapid.',
  },
  LLP_Info_1_Title_1: {
    en: 'The perfect appearance for lawyers',
    de: 'Der perfekte Auftritt für Anwälte',
    ro: 'Aspectul perfect pentru avocați',
  },
  // LLP_Info_1_Title_2: {
  //   en: 'Solution for lawyers',
  //   de: 'Lösung für Anwälte',
  //   ro: 'Soluție pentru avocați',
  // },
  LLP_Info_1_Description: {
    en: 'Digital marketing, online appointments and new clients',
    de: 'Digitales Marketing, Online-Terminvereinbarung und neue Mandanten',
    ro: 'Marketing digital, programări online și clienți noi',
  },
  LLP_Info_2_Tag: {
    en: 'Advantages',
    de: 'Vorteile',
    ro: 'Avantaje',
  },
  LLP_Info_2_Title: {
    en: 'Everything on one platform',
    de: 'Alles auf einer Plattform',
    ro: 'Totul pe o singură platformă',
  },
  LLP_Info_2_Description: {
    en: 'Win more clients, more success!',
    de: 'Mehr Mandanten gewinnen, mehr Erfolg!',
    ro: 'Câștigă mai mulți clienți, mai mult succes!',
  },
  LLP_Info_2_Box_1_Title: {
    en: 'Online diary',
    de: 'Online Terminkalender',
    ro: 'Jurnal online',
  },
  LLP_Info_2_Box_1_Description: {
    en: 'Fast and direct first contact with clients in your field of expertise',
    de: 'Schneller und direkter Erstkontakt mit Mandanten in Ihrem Fachbereich',
    ro: 'Primul contact rapid și direct cu clienții din domeniul dumneavoastră de expertiză',
  },
  LLP_Info_2_Box_2_Title: {
    en: 'Publish legal info',
    de: 'Rechtsinfos veröffentlichen',
    ro: 'Publicarea informațiilor juridice',
  },
  LLP_Info_2_Box_2_Description: {
    en: 'More visibility in your field through targeted publications',
    de: 'Mehr Sichtbarkeit in Ihrem Fachbereich durch gezielte Veröffentlichungen',
    ro: 'Mai multă vizibilitate în domeniul dumneavoastră prin publicații specifice',
  },
  LLP_Info_2_Box_3_Title: {
    en: 'Modern marketing',
    de: 'Modernes Marketing',
    ro: 'Marketing modern',
  },
  LLP_Info_2_Box_3_Description: {
    en: 'Quickly build your online reputation',
    de: 'Schneller Aufbau Ihrer Online-Reputation',
    ro: 'Construiți-vă rapid reputația online',
  },
  LLP_Info_2_Box_4_Title: {
    en: 'More clients',
    de: 'Mehr Mandanten',
    ro: 'Mai mulți clienți',
  },
  LLP_Info_2_Box_4_Description: {
    en: 'More growth: most clients search for their lawyer online',
    de: 'Mehr Wachstum: Die meisten Mandanten suchen online nach einem Anwalt',
    ro: 'Mai multă creștere: Majoritatea clienților caută un avocat online',
  },
  LLP_Info_3_Title: {
    en: 'Secure client requests at the touch of a button',
    de: 'Sichern Sie sich Mandantenanfragen auf Knopfdruck',
    ro: 'Solicitări de informații sigure pentru clienți prin simpla apăsare a unui buton',
  },
  LLP_Info_3_Description: {
    en: 'Offer your appointment booking where clients are looking for it: online!',
    de: 'Bieten Sie dort Ihre Terminbuchung an, wo Mandanten sie suchen: online!',
    ro: 'Oferiți rezervarea de întâlniri acolo unde clienții o caută: online!',
  },
  LLP_Info_4_Tag_Price: {
    en: 'Prices',
    de: 'Preise',
    ro: 'Prețuri',
  },
  LLP_Info_4_Title: {
    en: 'Which package suits you?',
    de: 'Welches Paket passt zu Ihnen?',
    ro: 'Ce pachet vi se potrivește?',
  },
  LLP_Info_4_Description: {
    en: 'Our Anwado packages offer you unique advantages',
    de: 'Unsere Anwado Pakete bieten Ihnen einzigartige Vorteile',
    ro: 'Pachetele noastre Anwado vă oferă avantaje unice',
  },
  LLP_Info_4_Tag: {
    en: 'Free forever!',
    de: 'Für immer Gratis!',
    ro: 'Gratuit pentru totdeauna!',
  },
  LLP_Info_5_Tag: {
    en: 'Why Anwado?',
    de: 'Warum Anwado?',
    ro: 'De ce Anwado?',
  },
  LLP_Info_5_Title: {
    en: 'With your Anwado profile you will be found',
    de: 'Mit Ihrem Anwado-Profil werden Sie gefunden',
    ro: 'Cu profilul tău Anwado vei fi găsit',
  },
  LLP_Info_5_Description: {
    en: 'Higher findability - With your profile on Anwado you will be found easier and faster by potential clients - this is how your digital presence works',
    de: 'Höhere Auffindbarkeit - Mit Ihrem Profil auf Anwado werden Sie von potentiellen Mandanten einfacher und schneller gefunden - so funktioniert ihr digitaler Auftritt',
    ro: 'Găsire mai mare - Cu profilul tău pe Anwado vei fi găsit mai ușor și mai rapid de către potențialii clienți - așa funcționează prezența ta digitală',
  },
  LLP_Info_6_Title: {
    en: 'Frequently asked questions',
    de: 'Häufig gestellte Fragen',
    ro: 'Întrebări frecvente',
  },
  LLP_Info_6_Description: {
    en: 'You can find useful tips and answers about your Anwado online presence here. If you have any further questions, please feel free to send us an e-mail.',
    de: 'Nützliche Tipps und Antworten rund um Ihre Anwado online Präsenz finden Sie hier. Bei weiteren Fragen, können Sie uns gerne eine E-Mail senden.',
    ro: 'Aici puteți găsi sfaturi și răspunsuri utile despre prezența online a Anwado. Dacă aveți întrebări suplimentare, nu ezitați să ne trimiteți un e-mail.',
  },
  LLP_Info_7_Action_Button: {
    en: 'Contact Anwado',
    de: 'Anwado kontaktieren',
    ro: 'Contact Anwado',
  },
  LLP_Info_8_Title: {
    en: 'Legal advice rethought',
    de: 'Rechtsberatung neu gedacht',
    ro: 'Consultanță juridică regândită',
  },
  LLP_Info_8_Description: {
    en: 'Register today on Anwado and ensure your optimal presence in our online community. ',
    de: 'Registrieren Sie sich noch heute auf Anwado und sichern sie sich den optimalen Auftritt in unserer Online Community. ',
    ro: 'Înregistrează-te astăzi pe Anwado și asigură-te că ai cea mai bună prezență posibilă în comunitatea noastră online.',
  },

  // Not used but maybe we will need them later?
  LLP_English: {
    en: 'English',
    de: 'Englisch',
    ro: 'Engleză',
  },
  LLP_German: {
    en: 'German',
    de: 'Deutsch',
    ro: 'Germană',
  },
  LLP_Afar: {
    en: 'Remote',
    de: 'Fern',
    ro: 'La distanță',
  },
  LLP_Abkhazian: {
    en: 'Abkhaz',
    de: 'Abchasisch',
    ro: 'Abhazia',
  },
  LLP_Afrikaans: {
    en: 'Afrikaans',
    de: 'Afrikaans',
    ro: 'Afrikaans',
  },
  LLP_Amharic: {
    en: 'Amharic',
    de: 'Amharisch',
    ro: 'Amharic',
  },
  LLP_Arabic: {
    en: 'Arabic',
    de: 'Arabisch',
    ro: 'Arabă',
  },
  LLP_Assamese: {
    en: 'Assamese',
    de: 'Assamesisch',
    ro: 'Assamese',
  },
  LLP_Aymara: {
    en: 'Aymara',
    de: 'Aymara',
    ro: 'Aymara',
  },
  LLP_Azerbaijani: {
    en: 'Azerbaijani',
    de: 'Aserbaidschanisch',
    ro: 'Azerbaidjan',
  },
  LLP_Bashkir: {
    en: 'Bashkir',
    de: 'Baschkirisch',
    ro: 'Bashkir',
  },
  LLP_Belarusian: {
    en: 'Belarusian',
    de: 'Belarussisch',
    ro: 'Bielorusă',
  },
  LLP_Bulgarian: {
    en: 'bulgarian',
    de: 'bulgarisch',
    ro: 'Bulgară',
  },
  LLP_Bihari: {
    en: 'Bihari',
    de: 'Bihari',
    ro: 'Bihari',
  },
  LLP_Bislama: {
    en: 'Bislama',
    de: 'Bislama',
    ro: 'Bislama',
  },
  LLP_Bengali_Bangla: {
    en: 'Bengali/Bangla',
    de: 'Bengalisch/Bangla',
    ro: 'Bengali/Bangla',
  },
  LLP_Tibetan: {
    en: 'Tibetan',
    de: 'Tibetisch',
    ro: 'Tibetan',
  },
  LLP_Breton: {
    en: 'Breton',
    de: 'Bretonisch',
    ro: 'Breton',
  },
  LLP_Catalan: {
    en: 'catalan',
    de: 'katalanisch',
    ro: 'Catalan',
  },
  LLP_Corsican: {
    en: 'Corsican',
    de: 'Korsisch',
    ro: 'Corsican',
  },
  LLP_Czech: {
    en: 'Czech',
    de: 'Tschechisch',
    ro: 'Cehia',
  },
  LLP_Welsh: {
    en: 'Welsh',
    de: 'Walisisch',
    ro: 'Țara Galilor',
  },
  LLP_Danish: {
    en: 'danish',
    de: 'dänisch',
    ro: 'daneză',
  },
  LLP_Bhutani: {
    en: 'Bhutani',
    de: 'Bhutani',
    ro: 'Bhutani',
  },
  LLP_Greek: {
    en: 'Greek',
    de: 'griechisch',
    ro: 'Greacă',
  },
  LLP_Esperanto: {
    en: 'Esperanto',
    de: 'Esperanto',
    ro: 'Esperanto',
  },
  LLP_Spanish: {
    en: 'Spanish',
    de: 'Spanisch',
    ro: 'Spaniolă',
  },
  LLP_Estonian: {
    en: 'estonian',
    de: 'estnisch',
    ro: 'estoniană',
  },
  LLP_Basque: {
    en: 'basque',
    de: 'baskisch',
    ro: 'bască',
  },
  LLP_Persian: {
    en: 'persian',
    de: 'persisch',
    ro: 'persian',
  },
  LLP_Finnish: {
    en: 'finnish',
    de: 'finnisch',
    ro: 'finlandeză',
  },
  LLP_Fiji: {
    en: 'Fiji',
    de: 'Fidschi',
    ro: 'Fiji',
  },
  LLP_Faroese: {
    en: 'Faroese',
    de: 'Färöisch',
    ro: 'Feroe',
  },
  LLP_French: {
    en: 'French',
    de: 'Französisch',
    ro: 'Franceză',
  },
  LLP_Frisian: {
    en: 'Frisian',
    de: 'Friesisch',
    ro: 'Frisonul',
  },
  LLP_Irish: {
    en: 'irish',
    de: 'irisch',
    ro: 'irlandeză',
  },
  LLP_Scots_Gaelic: {
    en: 'Scottish/Gaelic',
    de: 'Schottisch/Gälisch',
    ro: 'Scoțian/Gaelic',
  },
  LLP_Galician: {
    en: 'galician',
    de: 'galizisch',
    ro: 'galiciană',
  },
  LLP_Guarani: {
    en: 'Guarani',
    de: 'Guarani',
    ro: 'Guarani',
  },
  LLP_Gujarati: {
    en: 'Gujarati',
    de: 'Gujarati',
    ro: 'Gujarati',
  },
  LLP_Hausa: {
    en: 'Hausa',
    de: 'Hausa',
    ro: 'Hausa',
  },
  LLP_Hindi: {
    en: 'Hindi',
    de: 'Hindi',
    ro: 'Hindi',
  },
  LLP_Croatian: {
    en: 'croatian',
    de: 'kroatisch',
    ro: 'croat',
  },
  LLP_Hungarian: {
    en: 'hungarian',
    de: 'ungarisch',
    ro: 'maghiară',
  },
  LLP_Armenian: {
    en: 'Armenian',
    de: 'Armenisch',
    ro: 'Armenească',
  },
  LLP_Interlingua: {
    en: 'Interlingua',
    de: 'Interlingua',
    ro: 'Interlingua',
  },
  LLP_Interlingue: {
    en: 'Interlingue',
    de: 'Interlingue',
    ro: 'Interlingue',
  },
  LLP_Inupiak: {
    en: 'Inupiak',
    de: 'Inupiak',
    ro: 'Inupiak',
  },
  LLP_Indonesian: {
    en: 'indonesian',
    de: 'indonesisch',
    ro: 'indoneziană',
  },
  LLP_Icelandic: {
    en: 'icelandic',
    de: 'isländisch',
    ro: 'icelandă',
  },
  LLP_Italian: {
    en: 'Italian',
    de: 'Italienisch',
    ro: 'Italian',
  },
  LLP_Hebrew: {
    en: 'Hebrew',
    de: 'Hebräisch',
    ro: 'Ebraică',
  },
  LLP_Japanese: {
    en: 'Japanese',
    de: 'Japanisch',
    ro: 'Japoneză',
  },
  LLP_Yiddish: {
    en: 'Yiddish',
    de: 'Jiddisch',
    ro: 'Yiddish',
  },
  LLP_Javanese: {
    en: 'Javanese',
    de: 'Javanisch',
    ro: 'Javaneză',
  },
  LLP_Georgian: {
    en: 'Georgian',
    de: 'Georgisch',
    ro: 'Georgian',
  },
  LLP_Kazakh: {
    en: 'Kazakh',
    de: 'Kasachisch',
    ro: 'Kazahstan',
  },
  LLP_Greenlandic: {
    en: 'Greenlandic',
    de: 'Grönlandisch',
    ro: 'Groenlandeză',
  },
  LLP_Cambodian: {
    en: 'Cambodian',
    de: 'Kambodschanisch',
    ro: 'Cambodgiană',
  },
  LLP_Kannada: {
    en: 'Kannada',
    de: 'Kannada',
    ro: 'Kannada',
  },
  LLP_Korean: {
    en: 'Korean',
    de: 'Koreanisch',
    ro: 'Coreeană',
  },
  LLP_Kashmiri: {
    en: 'Kashmiri',
    de: 'Kaschmiri',
    ro: 'Kashmiri',
  },
  LLP_Kurdish: {
    en: 'Kurdish',
    de: 'Kurdisch',
    ro: 'Kurdish',
  },
  LLP_Kirghiz: {
    en: 'Kyrgyz',
    de: 'Kirgisisch',
    ro: 'Kârgâzstan',
  },
  LLP_Latin: {
    en: 'Latin',
    de: 'Latein',
    ro: 'Latină',
  },
  LLP_Lingala: {
    en: 'Lingala',
    de: 'Lingala',
    ro: 'Lingala',
  },
  LLP_Laothian: {
    en: 'Lao',
    de: 'Laotisch',
    ro: 'Lao',
  },
  LLP_Lithuanian: {
    en: 'lithuanian',
    de: 'litauisch',
    ro: 'lituanian',
  },
  LLP_Latvian_Lettish: {
    en: 'Latvian/Latvian',
    de: 'Lettisch/Lettisch',
    ro: 'Letonă/Letonă',
  },
  LLP_Malagasy: {
    en: 'Malagasy',
    de: 'Madagassisch',
    ro: 'Malagasy',
  },
  LLP_Maori: {
    en: 'Maori',
    de: 'Maori',
    ro: 'Maori',
  },
  LLP_Macedonian: {
    en: 'macedonian',
    de: 'mazedonisch',
    ro: 'Macedoneană',
  },
  LLP_Malayalam: {
    en: 'Malayalam',
    de: 'Malayalam',
    ro: 'Malayalam',
  },
  LLP_Mongolian: {
    en: 'mongolian',
    de: 'mongolisch',
    ro: 'mongolă',
  },
  LLP_Moldavian: {
    en: 'Moldovan',
    de: 'Moldauisch',
    ro: 'Moldovenească',
  },
  LLP_Marathi: {
    en: 'Marathi',
    de: 'Marathi',
    ro: 'Marathi',
  },
  LLP_Malay: {
    en: 'Malay',
    de: 'Malaiisch',
    ro: 'Malay',
  },
  LLP_Maltese: {
    en: 'Maltese',
    de: 'Maltesisch',
    ro: 'Malteză',
  },
  LLP_Burmese: {
    en: 'burmese',
    de: 'burmesisch',
    ro: 'birmaneză',
  },
  LLP_Nauru: {
    en: 'Nauru',
    de: 'Nauru',
    ro: 'Nauru',
  },
  LLP_Nepali: {
    en: 'Nepali',
    de: 'Nepali',
    ro: 'Nepali',
  },
  LLP_Dutch: {
    en: 'Dutch',
    de: 'Niederländisch',
    ro: 'Olandeză',
  },
  LLP_Norwegian: {
    en: 'Norwegian',
    de: 'Norwegisch',
    ro: 'Norvegiană',
  },
  LLP_Nyanja: {
    en: 'Nyanja',
    de: 'Nyanja',
    ro: 'Nyanja',
  },
  LLP_Occitan: {
    en: 'Occitan',
    de: 'Okzitanisch',
    ro: 'Occitan',
  },
  LLP_Afan_Oromo: {
    en: 'Afan/Oromo',
    de: 'Afan/Oromo',
    ro: 'Afan/Oromo',
  },
  LLP_Punjabi: {
    en: 'Punjabi',
    de: 'Punjabi',
    ro: 'Punjabi',
  },
  LLP_Polish: {
    en: 'Polish',
    de: 'Polnisch',
    ro: 'Poloneză',
  },
  LLP_Pashto_Pushto: {
    en: 'Pashto/Pashto',
    de: 'Paschtu/Paschtu',
    ro: 'Pashto/Pashto',
  },
  LLP_Portuguese: {
    en: 'Portuguese',
    de: 'Portugiesisch',
    ro: 'Portugheză',
  },
  LLP_Quechua: {
    en: 'Quechua',
    de: 'Quechua',
    ro: 'Quechua',
  },
  LLP_Rhaeto_Romance: {
    en: 'Rhaeto-Romanic',
    de: 'Rätoromanisch',
    ro: 'Rhaeto-românesc',
  },
  LLP_Kirundi: {
    en: 'Kirundi',
    de: 'Kirundi',
    ro: 'Kirundi',
  },
  LLP_Romanian: {
    en: 'romanian',
    de: 'rumänisch',
    ro: 'Românesc',
  },
  LLP_Russian: {
    en: 'Russian',
    de: 'Russisch',
    ro: 'Rusă',
  },
  LLP_Kinyarwanda: {
    en: 'Kinyarwanda',
    de: 'Kinyarwanda',
    ro: 'Kinyarwanda',
  },
  LLP_Sanskrit: {
    en: 'Sanskrit',
    de: 'Sanskrit',
    ro: 'Sanscrită',
  },
  LLP_Sindhi: {
    en: 'Sindhi',
    de: 'Sindhi',
    ro: 'Sindhi',
  },
  LLP_Sangro: {
    en: 'Sangro',
    de: 'Sangro',
    ro: 'Sangro',
  },
  LLP_Serbo_Croatian: {
    en: 'Serbo-Croatian',
    de: 'Serbo-Kroatisch',
    ro: 'Sârbo-croată',
  },
  LLP_Singhalese: {
    en: 'Sinhala',
    de: 'Singhalesisch',
    ro: 'Sinhala',
  },
  LLP_Slovak: {
    en: 'Slovak',
    de: 'Slowakisch',
    ro: 'Slovacia',
  },
  LLP_Slovenian: {
    en: 'Slovenian',
    de: 'Slowenisch',
    ro: 'Slovenă',
  },
  LLP_Samoan: {
    en: 'Samoan',
    de: 'Samoisch',
    ro: 'Samoan',
  },
  LLP_Somali: {
    en: 'Somali',
    de: 'Somali',
    ro: 'Somaleză',
  },
  LLP_Albanian: {
    en: 'Albanian',
    de: 'Albanisch',
    ro: 'Albaneză',
  },
  LLP_Serbian: {
    en: 'Serbian',
    de: 'Serbisch',
    ro: 'Sârbă',
  },
  LLP_Siswati: {
    en: 'Siswati',
    de: 'Siswati',
    ro: 'Siswati',
  },
  LLP_Sesotho: {
    en: 'Sesotho',
    de: 'Sesotho',
    ro: 'Sesotho',
  },
  LLP_Sundanese: {
    en: 'Sundanese',
    de: 'Sundanesisch',
    ro: 'Sundaneză',
  },
  LLP_Swedish: {
    en: 'Swedish',
    de: 'Schwedisch',
    ro: 'Suedeză',
  },
  LLP_Swahili: {
    en: 'Swahili',
    de: 'Swahili',
    ro: 'Swahili',
  },
  LLP_Tamil: {
    en: 'Tamil',
    de: 'Tamil',
    ro: 'Tamil',
  },
  LLP_Telugu: {
    en: 'Telugu',
    de: 'Telugu',
    ro: 'Telugu',
  },
  LLP_Tajik: {
    en: 'Tajik',
    de: 'Tadschikisch',
    ro: 'Tajik',
  },
  LLP_Thai: {
    en: 'Thai',
    de: 'Thailändisch',
    ro: 'Thai',
  },
  LLP_Tigrinya: {
    en: 'Tigrinja',
    de: 'Tigrinja',
    ro: 'Tigrinja',
  },
  LLP_Turkmen: {
    en: 'Turkmen',
    de: 'Turkmenisch',
    ro: 'Turkmeni',
  },
  LLP_Tagalog: {
    en: 'Tagalog',
    de: 'Tagalog',
    ro: 'Tagalog',
  },
  LLP_Setswana: {
    en: 'Setswana',
    de: 'Setswana',
    ro: 'Setswana',
  },
  LLP_Tonga: {
    en: 'Tonga',
    de: 'Tonga',
    ro: 'Tonga',
  },
  LLP_Turkish: {
    en: 'Turkish',
    de: 'Türkisch',
    ro: 'Turcă',
  },
  LLP_Tsonga: {
    en: 'Tsonga',
    de: 'Tsonga',
    ro: 'Tsonga',
  },
  LLP_Tatar: {
    en: 'Tatar',
    de: 'Tatarisch',
    ro: 'Tatar',
  },
  LLP_Twi: {
    en: 'Twi',
    de: 'Twi',
    ro: 'Twi',
  },
  LLP_Ukrainian: {
    en: 'Ukrainian',
    de: 'Ukrainisch',
    ro: 'Ucraineană',
  },
  LLP_Urdu: {
    en: 'Urdu',
    de: 'Urdu',
    ro: 'Urdu',
  },
  LLP_Uzbek: {
    en: 'Uzbek',
    de: 'Usbekisch',
    ro: 'Uzbecă',
  },
  LLP_Vietnamese: {
    en: 'Vietnamese',
    de: 'Vietnamesisch',
    ro: 'Vietnameză',
  },
  LLP_Volapuk: {
    en: 'Volapuk',
    de: 'Volapuk',
    ro: 'Volapuk',
  },
  LLP_Wolof: {
    en: 'Wolof',
    de: 'Wolof',
    ro: 'Wolof',
  },
  LLP_Xhosa: {
    en: 'Xhosa',
    de: 'Xhosa',
    ro: 'Xhosa',
  },
  LLP_Yoruba: {
    en: 'Yoruba',
    de: 'Yoruba',
    ro: 'Yoruba',
  },
  LLP_Chinese: {
    en: 'Chinese',
    de: 'Chinesisch',
    ro: 'Chineză',
  },
  LLP_Zulu: {
    en: 'Zulu',
    de: 'Zulu',
    ro: 'Zulu',
  },

  //Lawyer Settings Subscription Card
  LSSC_Card_Arrow: {
    en: 'Your Advantages!',
    de: 'Ihre Vorteile!',
    ro: 'Avantajele tale!',
  },
  LSSC_Card_Title: {
    en: 'Attract new clients:',
    de: 'Neue Mandanten gewinnen:',
    ro: 'Câștigarea de noi clienți:',
  },
  LSSC_Card_Gold_Text: {
    en: 'Gold Membership',
    de: 'Gold Mitgliedschaft',
    ro: 'Membru Gold',
  },
  LSSC_Card_Unlock_Features: {
    en: 'To unlock these and other features, update your Membership',
    de: 'Um diese und weitere Funktionen freizuschalten, aktualisieren Sie Ihre Mitgliedschaft',
    ro: 'Pentru a debloca aceste și alte caracteristici, actualizați-vă abonamentul',
  },
  LSSC_Card_Unlock_Features_2: {
    en: 'Secure the perfect appearance for lawyers through digital marketing and online appointment booking. ',
    de: 'Sichern Sie sich den perfekten Auftritt für Anwälte durch digitales Marketing und Online-Terminvereinbarung.',
    ro: 'Asigurați prezența perfectă pentru avocați prin marketing digital și programări online.',
  },
  LSSC_Card_Upgrade_Button: {
    en: 'Unlock upgrade',
    de: 'Upgrade freischalten',
    ro: 'Deblocare upgrade',
  },
  PRICE_PAGE_Meta_Title_1: {
    en: 'Anwado - Subscription and Pricing',
    de: 'Anwado - Abonnement und Preise',
    ro: 'Anwado - Abonament și prețuri',
  },
  PRICE_PAGE_Meta_Description_1: {
    en: 'Explore Anwado`s subscription options and pricing. Benefit from our services and reach more clients.',
    de: 'Entdecken Sie Anwado`s Abonnementoptionen und Preise. Profitieren Sie von unseren Leistungen und erreichen Sie mehr Mandanten.',
    ro: 'Descoperiți opțiunile și prețurile abonamentelor Anwado. Beneficiați de serviciile noastre și ajungeți la mai mulți clienți.',
  },
  LP_Info_1_Meta_Title_1: {
    en: 'Anwado - The perfect appearance for lawyers',
    de: 'Anwado - Der perfekte Auftritt für Anwälte',
    ro: 'Anwado - Aspectul perfect pentru avocați',
  },
  LP_Info_1_Meta_Description_1: {
    en: 'With your profile on Anwado you will be found easier and faster by potential clients',
    de: 'Mit Ihrem Profil auf Anwado werden Sie von potentiellen Mandanten einfacher und schneller gefunden.',
    ro: 'Cu profilul dvs. pe Anwado, potențialii clienți vă vor găsi mai ușor și mai rapid.',
  },

  // areas of law/speciality and city pages:
  AOLS_Lawyer_Area_of_law: {
    de: 'Rechtsanwälte für',
    ro: 'Avocați pentru',
    en: 'Lawyers for',
  },
  AOLS_Lawyer_Speciality: {
    de: 'Fachanwälte für',
    ro: 'Avocați specializați pentru',
    en: 'Specialist lawyers in',
  },
  AOLS_Lawyer_City: {
    de: 'Rechtsanwälte in',
    ro: 'Avocați în',
    en: 'Lawyers in',
  },
};

export default translations;
