import React from 'react';

import 'Assets/styles/common/LawyerPageFaq.css';

const FaqLawyerComponent = (props) => {
  let { text, title, picture, centeredContent, alignCenter } = props;

  return (
    <div
      className="componentcard"
      style={{ alignItems: centeredContent ? 'center' : 'flex-start' }}
    >
      <div className="pictureDesign">
        <div className="innerPicture">
          <img
            style={{ width: '20px', height: '20px' }}
            src={picture}
            alt="img"
          />
        </div>
      </div>
      <div
        className="componentTextCard"
        style={{
          textAlign: centeredContent ? 'center' : 'left',
          alignItems: alignCenter ? 'center' : 'flex-start',
        }}
      >
        <h2 className="componentCardOneTitle">{`${title}`}</h2>

        <h3 className="componentCardOneTextField">{`${text}`}</h3>
      </div>
    </div>
  );
};
export default FaqLawyerComponent;
