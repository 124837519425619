import React from 'react';

import Button from '@mui/material/Button';

import purpleCheckIcon from 'Assets/pictures/purpleCheckIcon.png';
import styles from 'Assets/styles/lawyer/FilterCheckBox.module.scss';

export default function Checkbox(props) {
  return (
    <div
      className={
        props.available === false
          ? styles.checkboxContainerDisabled
          : props.checked
          ? styles.checkboxContainerSelected
          : styles.checkboxContainer
      }
    >
      <Button
        data-cy={props.data_cy}
        disabled={props.available === false}
        className={styles.checkButton}
        onClick={props.onChange ? () => props.onChange() : null}
        // data-cy="checkbox-#{props.name}"
      >
        {/* {<CheckRoundedIcon className={props.checked ? styles.checkStyleSelected : styles.checkStyle} />} */}
        <img
          src={purpleCheckIcon}
          className={
            props.checked ? styles.checkStyleSelectedImg : styles.checkStyleImg
          }
          alt="check"
        />
      </Button>
    </div>
  );
}
