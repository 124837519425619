import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: {
    locations: '',
    areasOfLaws: [],
    search: '',
    sortLawyers: 0,
    minScore: 0,
  },
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState: initialState,
  reducers: {
    addLocation: (state, action) => {
      if (action.payload) {
        state.value = {
          ...state.value,
          locations: action.payload,
        };
      }
    },
    addAreasOfLaws: (state, action) => {
      if (action.payload) {
        state.value = {
          ...state.value,
          areasOfLaws: [...state.value.areasOfLaws, action.payload],
        };
      }
    },
    removeLocation: (state, action) => {
      state.value = { ...state.value, locations: '' };
    },
    removeAreaOfLaw: (state, action) => {
      state.value.areasOfLaws = state.value.areasOfLaws.filter(
        (element) => element !== action.payload
      );
    },
    resetLocations: (state, action) => {
      state.value.locations = [];
    },
    resetAreasOfLaws: (state, action) => {
      state.value.areasOfLaws = [];
    },
    addSearch: (state, action) => {
      if (action.payload) {
        state.value = { ...state.value, search: action.payload };
      }
    },
    addSortLawyer: (state, action) => {
      if (action.payload) {
        state.value = { ...state.value, sortLawyers: action.payload };
      }
    },
    addMinScore: (state, action) => {
      if (action.payload) {
        state.value = { ...state.value, minScore: action.payload };
      }
    },
    resetFilters: (state) => {
      state.value = {
        locations: '',
        areasOfLaws: [],
        search: '',
        sortLawyers: 0,
        minScore: 0,
      };
    },
  },
});
export const {
  addLocation,
  removeLocation,
  addAreasOfLaws,
  removeAreaOfLaw,
  addSearch,
  addSortLawyer,
  addMinScore,
  resetFilters,
  resetLocations,
  resetAreasOfLaws,
} = filtersSlice.actions;
export default filtersSlice.reducer;
