import React from 'react';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import twoLawyers from 'Assets/pictures/2layers.svg';
import arrowUpRight from 'Assets/pictures/arrow-up-right.svg';

import styles from 'Assets/styles/subscription/actualPlan.module.scss';

import { actualSubscriptionTexts } from 'Services/subscriptionTexts';

const ActualPlan = (props) => {
  const { subscriptionInformation, handleOpen } = props;

  return (
    <div className={styles['actual-plan-container']}>
      <div className={styles['actual-plan-header']}>
        <div className={styles['actual-plan-header-content']}>
          <div className={styles['actual-plan-header-content-img-container']}>
            <img
              alt="actual plan"
              src={twoLawyers}
              className={styles['actual-plan-header-content-img']}
            />
          </div>

          <div className={styles['actual-plan-header-content-text']}>
            {LPTranslate('LSS_Actual_Plan_Title')}
          </div>
        </div>
      </div>
      <div className={styles['actual-plan-content']}>
        <div className={styles['actual-plan-content-information']}>
          <div className={styles['details-and-price']}>
            <div className={styles['details']}>
              <div className={styles['title']}>
                {actualSubscriptionTexts(subscriptionInformation)}
              </div>
              {/* <div className={styles['text']}>Lorem ipsum</div> */}
            </div>
            <div className={styles['price-container']}>
              <div className={styles['wrap']}>€</div>
              <div className={styles['price']}>
                {subscriptionInformation.subscriptionPrice}
              </div>
              <div className={styles['period-wrap']}>
                {subscriptionInformation.subscriptionRecurringInterval ===
                'Month'
                  ? LPTranslate('Common_Per_Month')
                  : LPTranslate('Common_Per_Year')}
              </div>
            </div>
          </div>
        </div>
        <div className={styles['actual-plan-content-action']}>
          <div className={styles['divider']}></div>
          <div className={styles['action-button-container']}>
            <div
              data-cy="action-button"
              className={styles['action-button']}
              onClick={handleOpen}
            >
              <div className={styles['button-text']}>
                {LPTranslate('LSS_Plan_Upgrade')}
              </div>
              <div className={styles['button-icon']}>
                <img
                  src={arrowUpRight}
                  className={styles['header-img']}
                  alt="arrow up right"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActualPlan;
