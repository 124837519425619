import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    isSwitchChecked: false,
  },
};

const switchCheckSlice = createSlice({
  name: "switchCheck",
  initialState: initialState,
  reducers: {
    setSwitchChecked: (state, action) => {
      if (action.payload) {
        state.value = { ...state.value, isSwitchChecked: action.payload };
      }
    },
    resetSwitchCheck: (state) => {
      state.value = {
        isSwitchChecked: false,
      };
    },
  },
});
export const { setSwitchChecked, resetSwitchCheck } = switchCheckSlice.actions;
export default switchCheckSlice.reducer;
