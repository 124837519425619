import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useQuery } from '@apollo/client';

import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import SettingsMenu from 'Components/CommonComponents/SettingsMenu';
import VacationModal from 'Components/Modals/VacationModal';
import TimeIntervalView from 'Components/Lawyer/TimeIntervalView';
import EditLawyerSectionInfo from 'Components/Lawyer/Edit/EditLawyerSectionInfo';
import EditLawyerAvailabilitySection from 'Components/Lawyer/Edit/EditLawyerAvailabilitySection';

import {
  GET_LAWYER_AVAILABILITY,
  GET_LAWYER_HOLIDAYS,
} from 'Services/Queries/lawyer';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import { parseJwt } from 'Services/Utils/token';

import styles from 'Assets/styles/lawyer/LawyerCalendarSettingsPage.module.scss';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LawyerCalendarSettingsPage = () => {
  const [saveButtonPressed, setSaveButtonPressed] = useState(false);
  const [revertButtonPressed, setRevertButtonPressed] = useState(false);
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const [isPageLoadedFirstTime, setIsPageLoadedFirstTime] = useState(true);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [showAvailability, setShowAvailability] = useState(false);
  const [personally, setPersonally] = useState(false);
  const [byPhone, setByPhone] = useState(false);

  const [holidaysArr, setHolidaysArr] = useState([]);

  const getLawyerAvailability = useQuery(GET_LAWYER_AVAILABILITY, {
    variables: { id: parseInt(clientInfoRedux.role_id) },
  });

  const getLawyerHolidays = useQuery(GET_LAWYER_HOLIDAYS, {
    variables: { id: parseInt(clientInfoRedux.role_id) },
  });

  useEffect(() => {
    if (getLawyerHolidays && getLawyerHolidays.data) {
      setHolidaysArr(
        getLawyerHolidays.data.getLawyerHolidaysForSettings.holidays
      );
    }
  }, [getLawyerHolidays]);

  useEffect(() => {
    if (
      getLawyerAvailability &&
      getLawyerAvailability.data &&
      getLawyerAvailability.data.getLawyerScheduleAvailability &&
      isPageLoadedFirstTime
    ) {
      setShowAvailability(
        getLawyerAvailability.data.getLawyerScheduleAvailability
          .show_availability
      );
      setPersonally(
        getLawyerAvailability.data.getLawyerScheduleAvailability.personally
      );
      setByPhone(
        getLawyerAvailability.data.getLawyerScheduleAvailability.by_phone
      );

      setIsPageLoadedFirstTime(false);
    }
  }, [getLawyerAvailability, isPageLoadedFirstTime]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const updateLawyerAvailabilityBasedOnDaysSelected = (activateSliders) => {
    if (activateSliders) {
      setPersonally(true);
      setByPhone(true);
      setShowAvailability(true);
    } else {
      setPersonally(false);
      setByPhone(false);
      setShowAvailability(false);
    }
  };

  return (
    <>
      {parseJwt(clientInfoRedux.token).subscription > 1 ? (
        <div className={styles['container']}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            autoHideDuration={2000}
            onClose={handleCloseSnackbar}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity="success"
              className={styles['snackbar']}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
          <div className={styles['edit-lawyer-top-menu']}>
            <SettingsMenu
              setUpdateComponent={'/calendarSettings'}
              role={clientInfoRedux.role}
              subscription={parseJwt(clientInfoRedux.token).subscription}
            />
            <div className={styles['Divider']} />
          </div>

          <div
            className={styles['time-interval-revert-save-buttons-container']}
          >
            <Button
              data-cy="cancel-button"
              className={styles['time-interval-revert-button']}
              onClick={() => setRevertButtonPressed(true)}
            >
              {LPTranslate('Button_Cancel')}
            </Button>
            <Button
              data-cy="save-button"
              className={styles['time-interval-save-button']}
              onClick={() => setSaveButtonPressed(true)}
            >
              {LPTranslate('Button_Save')}
            </Button>
          </div>

          <div className={styles['time-interval-content-container']}>
            <div className={styles['time-interval-description-container']}>
              <p
                className={
                  styles['time-interval-description-container-title-text']
                }
              >
                {LPTranslate('LSC_Title')}
              </p>

              <p
                className={
                  styles['time-interval-description-container-subtitle-text']
                }
              >
                {LPTranslate('LSC_Description')}
              </p>

              <div
                className={styles['time-interval-vacation-container']}
                style={{ display: 'block' }}
              >
                <VacationModal holidaysArr={holidaysArr} />
              </div>
            </div>

            <div className={styles['time-interval-intervals-container']}>
              <TimeIntervalView
                lawyerAvailability={{
                  personally: personally,
                  by_phone: byPhone,
                  show_availability: showAvailability,
                }}
                ifSaveButtonPressed={saveButtonPressed}
                ifRevertButtonPressed={revertButtonPressed}
                onChangeSnackbar={() => setSnackbarOpen(true)}
                setSnackbarMessage={setSnackbarMessage}
                onChangeSaveButtonPressed={() => setSaveButtonPressed(false)}
                onChangeRevertButtonPressed={() =>
                  setRevertButtonPressed(false)
                }
                updateLawyerAvailabilityBasedOnDaysSelected={
                  updateLawyerAvailabilityBasedOnDaysSelected
                }
              />
            </div>
          </div>

          <div className={styles.Divider} />

          <div className={styles.infoContainer}>
            <div className={styles.Section}>
              <div className={styles.SectionInformation}>
                <div className={styles.SectionInformationContainer}>
                  <EditLawyerSectionInfo
                    subscription={2}
                    title={LPTranslate('LPP_Bio_Availability')}
                    text={LPTranslate('LPP_Bio_Availability_Description')}
                  />
                </div>
              </div>

              <div className={styles.SectionContent}>
                <div className={styles.SectionContentContainer}>
                  <EditLawyerAvailabilitySection
                    lawyerAvailability={{
                      personally: personally,
                      by_phone: byPhone,
                      show_availability: showAvailability,
                    }}
                    onChangeAvailability={(value) => setShowAvailability(value)}
                    onChangeByPhone={(value) => setByPhone(value)}
                    onChangePersonally={(value) => setPersonally(value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.permissionText}>
          {LPTranslate('LSC_PermissionText')}
        </div>
      )}
    </>
  );
};

export default LawyerCalendarSettingsPage;
