import moment from 'moment';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

const months = [
  LPTranslate('Anwado_January'),
  LPTranslate('Anwado_February'),
  LPTranslate('Anwado_March'),
  LPTranslate('Anwado_April'),
  LPTranslate('Anwado_May'),
  LPTranslate('Anwado_June'),
  LPTranslate('Anwado_July'),
  LPTranslate('Anwado_August'),
  LPTranslate('Anwado_September'),
  LPTranslate('Anwado_October'),
  LPTranslate('Anwado_November'),
  LPTranslate('Anwado_December'),
];

const milisecondsToDDMMYYYY = (miliseconds) => {
  return (
    moment(Number(miliseconds)).format('DD') +
    ' ' +
    months[moment(Number(miliseconds)).format('M') - 1] +
    ' ' +
    moment(Number(miliseconds)).format('YYYY')
  );
};

const milisecondsToMMMDDYYYY = (miliseconds) => {
  return (
    months[moment(Number(miliseconds)).format('M') - 1] +
    ' ' +
    moment(Number(miliseconds)).format('DD') +
    ', ' +
    moment(Number(miliseconds)).format('YYYY')
  );
};

const millisecondsToTimeAgo = (milliseconds) => {
  return moment(Number(milliseconds)).fromNow();
};

export { milisecondsToDDMMYYYY, milisecondsToMMMDDYYYY, millisecondsToTimeAgo };
