import React from 'react';

import styles from 'Assets/styles/calendar/Calendar.module.scss';

const CalendarDays = (props) => {
  let firstDayOfMonth = new Date(
    props.day.getFullYear(),
    props.day.getMonth(),
    1
  );
  let weekdayOfFirstDay = firstDayOfMonth.getDay();
  let currentDays = [];
  const currentDay = new Date();

  for (let day = 0; day < 42; day++) {
    if (day === 0 && weekdayOfFirstDay === 0) {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 6);
    } else if (day === 0) {
      firstDayOfMonth.setDate(
        firstDayOfMonth.getDate() + (day - weekdayOfFirstDay + 1)
      );
    } else {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
    }

    let calendarDay = {
      currentMonth: firstDayOfMonth.getMonth() === props.day.getMonth(),
      date: new Date(firstDayOfMonth),
      month: firstDayOfMonth.getMonth(),
      number: firstDayOfMonth.getDate(),
      startDateSelected:
        props.startDate !== undefined &&
        firstDayOfMonth.toDateString() === props.startDate.toDateString(),
      endDateSelected:
        props.endDate !== undefined &&
        firstDayOfMonth.toDateString() === props.endDate.toDateString(),
      year: firstDayOfMonth.getFullYear(),
    };

    currentDays.push(calendarDay);
  }

  const isPreviousMonth = (element) => {
    let nodeIterator = element;
    while (nodeIterator.nextSibling) {
      if (nodeIterator.classList.contains('current')) {
        return true;
      }
      nodeIterator = nodeIterator.nextSibling;
    }
    return false;
  };

  const startDateFirstTableHoverFirstTable = (e) => {
    let currentNode = e.target;

    if (e.target.classList.contains('current')) {
      while (
        parseInt(currentNode.firstChild.innerText) !==
        parseInt(props.startDate.getDate())
      ) {
        if (
          currentNode.classList.contains('day-of-month') &&
          currentNode.classList.contains('current')
        )
          currentNode.className = 'calendar-day current';
        currentNode.style.background = currentNode.classList.contains('current')
          ? '#F9F5FF'
          : currentNode.style.background;
        currentNode = currentNode.classList.contains('current')
          ? parseInt(currentNode.firstChild.innerText) >
            parseInt(props.startDate.getDate())
            ? currentNode.previousSibling
            : currentNode.nextSibling
          : currentNode.previousSibling;
      }
    } else {
      if (isPreviousMonth(e.target)) {
        while (
          parseInt(currentNode.firstChild.innerText) !==
          parseInt(props.startDate.getDate())
        ) {
          currentNode.style.background = currentNode.classList.contains(
            'current'
          )
            ? '#F9F5FF'
            : currentNode.style.background;
          currentNode = currentNode.nextSibling;
        }
      } else {
        currentNode =
          document.getElementsByClassName('calendar-body')[0].lastChild
            .lastChild;
        const element = document.querySelectorAll('*[aria-label]');

        for (var i = 0; i < element.length; i++) {
          if (
            parseInt(element[i].getAttribute('aria-label').split(' ')[1]) ===
              parseInt(props.startDate.getMonth() + 1) &&
            element[i].classList.contains('current')
          ) {
            currentNode = element[i];
          }
        }
        while (
          parseInt(currentNode.firstChild.innerText) !==
          parseInt(props.startDate.getDate())
        ) {
          if (
            currentNode.classList.contains('day-of-month') &&
            currentNode.classList.contains('current')
          )
            currentNode.className = 'calendar-day current';
          currentNode.style.background = currentNode.classList.contains(
            'current'
          )
            ? '#F9F5FF'
            : currentNode.style.background;
          currentNode = currentNode.previousSibling;
        }
      }
    }
  };

  const startDateFirstTableHoverSecondTable = (e) => {
    if (props.startDate !== undefined && props.endDate === undefined) {
      // coloreaza elementele din primul calendar (cel din stanga)

      let currentNode =
        document.getElementsByClassName('calendar-body')[0].lastChild.lastChild;
      const element = document.querySelectorAll('*[aria-label]');

      for (var i = 0; i < element.length; i++) {
        if (
          parseInt(element[i].getAttribute('aria-label').split(' ')[1]) ===
            parseInt(props.startDate.getMonth() + 1) &&
          element[i].classList.contains('current')
        ) {
          currentNode = element[i];
        }
      }

      while (
        parseInt(currentNode.firstChild.innerText) !==
        parseInt(props.startDate.getDate())
      ) {
        if (
          currentNode.classList.contains('day-of-month') &&
          currentNode.classList.contains('current')
        )
          currentNode.className = 'calendar-day current';
        currentNode.style.background = currentNode.classList.contains('current')
          ? '#F9F5FF'
          : currentNode.style.background;
        currentNode = currentNode.previousSibling;
      }

      // coloreaza elementele din al doilea calendar (cel din dreapta

      if (e.target.classList.contains('current')) {
        currentNode = e.target;
        while (currentNode && currentNode.classList.contains('current')) {
          if (
            currentNode.classList.contains('day-of-month') &&
            currentNode.classList.contains('current')
          )
            currentNode.className = 'calendar-day current';
          currentNode.style.background = currentNode.classList.contains(
            'current'
          )
            ? '#F9F5FF'
            : currentNode.style.background;
          currentNode = currentNode.previousSibling;
        }
      } else {
        if (!isPreviousMonth(e.target)) {
          let secondTableNode =
            document.getElementsByClassName('calendar-body')[0].lastChild
              .firstChild;
          while (secondTableNode.nextSibling) {
            secondTableNode.style.background =
              secondTableNode.classList.contains('current')
                ? '#F9F5FF'
                : secondTableNode.style.background;
            secondTableNode = secondTableNode.nextSibling;
          }
        }
      }
    }
  };

  const handleMouseOver = (e) => {
    if (
      e.target.tagName === 'SPAN' &&
      props.startDate !== undefined &&
      props.endDate === undefined &&
      new Date(
        e.target.getAttribute('aria-label').split(' ')[2] +
          '-' +
          e.target.getAttribute('aria-label').split(' ')[1] +
          '-' +
          e.target.getAttribute('aria-label').split(' ')[0]
      ).getTime() /
        1000 >
        new Date().getTime() / 1000
    ) {
      if (
        props.startDate.getMonth() === props.day.getMonth() &&
        document.getElementById('calendar1').contains(e.target)
      ) {
        startDateFirstTableHoverFirstTable(e);
      }

      if (
        (props.startDate.getMonth() + 1 === props.day.getMonth() ||
          props.startDate.getMonth() + 1 === 12 ||
          props.day.getMonth() === 1) &&
        document.getElementById('calendar2').contains(e.target)
      ) {
        startDateFirstTableHoverSecondTable(e);
      }
    }
  };

  const handleMouseLeave = (e) => {
    if (
      e.target.tagName === 'SPAN' &&
      props.startDate !== undefined &&
      props.endDate === undefined &&
      new Date(
        e.target.getAttribute('aria-label').split(' ')[2] +
          '-' +
          e.target.getAttribute('aria-label').split(' ')[1] +
          '-' +
          e.target.getAttribute('aria-label').split(' ')[0]
      ).getTime() /
        1000 >
        new Date().getTime() / 1000
    ) {
      const element = document.querySelectorAll('*[aria-label]');

      for (var i = 0; i < element.length; i++) {
        if (
          element[i].classList.contains('current') &&
          element[i].className !==
            'calendar-day current day-of-month selected' &&
          parseInt(element[i].getAttribute('aria-label').split(' ')[0]) ===
            parseInt(currentDay.getDate()) &&
          parseInt(element[i].getAttribute('aria-label').split(' ')[1]) ===
            parseInt(currentDay.getMonth() + 1)
        ) {
          element[i].className = 'calendar-day current day-of-month';
        }
        if (element[i].style.background !== '')
          element[i].style.background = '#FFFFFF';
      }
    }
  };

  return (
    <div className={styles['table-content']}>
      {currentDays.map((day, id) => {
        return (
          <span
            data-cy="calendar-day-button"
            key={id}
            className={
              'calendar-day' +
              (day.currentMonth ? ' current' : '') +
              (new Date(
                parseInt(day.year) +
                  '-' +
                  parseInt(day.month + 1) +
                  '-' +
                  parseInt(day.number)
              ).getTime() <= new Date().getTime()
                ? ' disabled'
                : '') +
              (day.startDateSelected || day.endDateSelected
                ? ' selected'
                : '') +
              (day.number === currentDay.getDate() &&
              day.month === currentDay.getMonth() &&
              day.currentMonth
                ? ' day-of-month'
                : '')
            }
            onClick={(e) => props.changeCurrentDay(day, e)}
            aria-label={
              day.number + ' ' + parseInt(day.month + 1) + ' ' + day.year
            }
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
          >
            <p className="day-text">{day.number}</p>
          </span>
        );
      })}
    </div>
  );
};

export default CalendarDays;
