import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';

import SettingsMenu from 'Components/CommonComponents/SettingsMenu';
import EditSaveChangesSection from 'Components/Lawyer/Edit/EditSaveChangesSection';
import EditClientProfileInformation from 'Components/Client/Settings/Edit/EditClientProfileInformation';
import EditLawyerSectionInfo from 'Components/Lawyer/Edit/EditLawyerSectionInfo';
import ImageDropzone from 'Components/Lawyer/Edit/ImageDropzone';
import LawyerImage from 'Components/Lawyer/Edit/EditLawyerImage';

import { addAll } from 'Services/Redux/reducers/clientInfo';
import { GET_CLIENT, UPDATE_CLIENT } from 'Services/Queries/client';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import useAlert from 'Services/Utils/hooks/useAlert';

import styles from 'Assets/styles/client/settings/ClientProfileSettings.module.scss';

const ClientProfileSettings = () => {
  // scos prefilled-ul si in cazul de eroare, sa apara pop up
  const { setAlert } = useAlert();

  const dispatch = useDispatch();
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);
  const logged_client_id = clientInfoRedux.role_id;

  let clientId = Number(logged_client_id);
  const [salutation, setSalutation] = useState('');
  const [title, setTitle] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [location, setLocation] = useState('');
  const [house_number, setHouseNumber] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [city, setCity] = useState('');
  const [telephone, setTelephone] = useState('');
  const [profileImage, setProfileImage] = useState('');

  const [clientInformationErrorMessages, setClientInformationErrorMessages] =
    useState({
      salutation: '',
      firstName: '',
      lastName: '',
      home_address: '',
      house_number: '',
      zipcode: '',
      location: '',
    });

  const currentClient = useQuery(GET_CLIENT, {
    variables: { id: clientId },
    fetchPolicy: 'no-cache',
  });

  const [updateClient, { loading: updateClientLoading }] =
    useMutation(UPDATE_CLIENT);

  const onSaveChanges = async () => {
    try {
      let newErrors = {};

      if (salutation.trim() === '')
        newErrors.salutation = LPTranslate('Error_Required_Field');

      if (firstName.trim() === '')
        newErrors.firstName = LPTranslate('Error_Required_Field');

      if (lastName.trim() === '')
        newErrors.lastName = LPTranslate('Error_Required_Field');

      if (location.trim() === '')
        newErrors.home_address = LPTranslate('Error_Required_Field');

      if (house_number.trim() === '')
        newErrors.house_number = LPTranslate('Error_Required_Field');

      if (!zipcode && zipcode.trim() === '')
        newErrors.zipcode = LPTranslate('Error_Required_Field');

      if (!city && city.trim() === '')
        newErrors.location = LPTranslate('Error_Required_Field');

      if (telephone.trim() === '')
        newErrors.telephone = LPTranslate('Error_Required_Field');

      if (Object.keys(newErrors).length > 0) {
        setClientInformationErrorMessages(newErrors);
        return;
      }

      let clientSaveData = {
        id: clientId,
        salutation: salutation.trim(),
        title: title.trim(),
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        name: firstName.trim() + ' ' + lastName.trim(),
        home_address: location.trim(),
        house_number: house_number.trim(),
        zipcode: zipcode.trim(),
        location: city.trim(),
        telephone: telephone.trim(),
        profileImage: profileImage,
        user_id: clientInfoRedux.user_id,
      };
      await updateClient({ variables: { input: clientSaveData } });

      setClientInformationErrorMessages({});

      const event = new Event('UpdateUserInformationNavbar');
      document.dispatchEvent(event);

      setAlert(LPTranslate('SIS_Update_Successfully_Message'));
    } catch (e) {
      console.log(e);
    }
  };

  const fillFields = (clientDTO) => {
    // de avut grija la null values
    setSalutation(clientDTO.salutation ? clientDTO.salutation : '');
    setTitle(clientDTO.title ? clientDTO.title : '');
    setFirstName(clientDTO.first_name);
    setLastName(clientDTO.last_name);
    setLocation(clientDTO.home_address ? clientDTO.home_address : '');
    setHouseNumber(clientDTO.house_number ? clientDTO.house_number : '');
    setZipcode(clientDTO.zipcode ? clientDTO.zipcode : '');
    setCity(clientDTO.location ? clientDTO.location : '');
    setTelephone(clientDTO.telephone ? clientDTO.telephone : '');

    setProfileImage(clientDTO.profileImage);
  };
  useEffect(() => {
    if (!updateClient.loading && updateClient.data !== undefined) {
      dispatch(addAll(updateClient.data.updateClient));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateClient.loading]);

  useEffect(() => {
    if (!currentClient.loading && currentClient.data !== undefined) {
      let clientDTO = { ...currentClient.data.client, id: 1 };

      delete clientDTO.__typename;

      fillFields(clientDTO);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient.loading]);

  if (updateClient.loading || currentClient.loading) {
    return (
      <>
        <div className="loading-overlay">
          <CircularProgress color="secondary" />
        </div>
      </>
    );
  }

  if (updateClient.error || currentClient.error) {
    return <p>Error :</p>;
  }

  return (
    <div className={styles['client-profile-settings-page-container']}>
      <div className={styles['client-profile-settings-top-menu']}>
        <SettingsMenu
          setUpdateComponent={'/updateClientProfile'}
          role={clientInfoRedux.role}
        />
      </div>
      <div
        className={styles['client-profile-settings-edit-save-changes-section']}
      >
        <EditSaveChangesSection
          onSaveChanges={onSaveChanges}
          pageTitle={LPTranslate('Common_Public_Profile')}
        />
      </div>
      <div className={styles['client-profile-settings-divider']} />
      <div className={styles['client-profile-settings-section']}>
        <div className={styles['client-profile-settings-section-information']}>
          <div
            className={
              styles['client-profile-settings-section-information-container']
            }
          >
            <EditLawyerSectionInfo
              subscription={1}
              title={LPTranslate('SIS_Contact_Information_Title')}
              text={LPTranslate('SIS_Contact_Information_Description')}
            />
          </div>
        </div>

        <div className={styles['client-profile-settings-section-content']}>
          <div
            className={
              styles['client-profile-settings-section-content-container']
            }
          >
            <p className={styles['client-profile-settings-section-title']}>
              {LPTranslate('Input_Profile_Image')}
            </p>
            {profileImage !== '' ? (
              <LawyerImage
                delete_image_data_cy="delete-profile-image-button"
                update_image_data_cy="update-profile-image-button"
                imageFile={profileImage}
                onChange={(image) => {
                  setProfileImage(image);
                }}
                onDelete={() => setProfileImage('')}
                roundImage={true}
              />
            ) : (
              <div
                className={
                  styles['client-profile-settings-image-dropzone-container']
                }
              >
                <ImageDropzone
                  data_cy="profile-image-input"
                  onChange={(image) => setProfileImage(image)}
                  singleImage
                  compressorOptions={{
                    maxWidth: 768,
                    maxHeight: 768,
                  }}
                />
              </div>
            )}
            <EditClientProfileInformation
              salutation={salutation}
              title={title}
              firstName={firstName}
              lastName={lastName}
              location={location}
              house_number={house_number}
              zipcode={zipcode}
              city={city}
              telephone={telephone}
              clientInformationErrorMessages={clientInformationErrorMessages}
              onChangeSalutation={(value) => setSalutation(value)}
              onChangeTitle={(value) => setTitle(value)}
              onChangeFirstName={(value) => setFirstName(value)}
              onChangeLastName={(value) => setLastName(value)}
              onChangeAddressValue={(value) => setLocation(value)}
              onChangeHouseNumber={(value) => setHouseNumber(value)}
              onChangeZipCode={(value) => setZipcode(value)}
              onChangeCity={(value) => setCity(value)}
              onChangeTelephone={(value) => setTelephone(value)}
            />
          </div>
        </div>
      </div>
      <div className={styles['client-profile-settings-divider']} />

      <div
        className={
          styles['client-profile-settings-edit-save-changes-section-bottom']
        }
      >
        <EditSaveChangesSection
          onSaveChanges={onSaveChanges}
          bottomChanges
          isLoading={updateClientLoading}
        />
      </div>
    </div>
  );
};

export default ClientProfileSettings;
