import React, { useEffect, useCallback } from 'react';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { GET_GOOGLE_AUTO_COMPLETE } from 'Services/Queries/google';
import { useLazyQuery } from '@apollo/client';

import 'Assets/styles/textfields/GoogleAutocompleteCustomTextfield.scss';

let lastInputSearched = '';
let nextInputToSearch = '';

export default function FreeSolo(props) {
  const [autoCompleteOptions, setAutoCompleteOptions] = React.useState([
    {
      locality: props.value,
      country: '',
      postalCode: '',
      fullName: props.value,
      placeId: '',
    },
  ]);

  const [getGoogleAutoComplete, { loading, data }] = useLazyQuery(
    GET_GOOGLE_AUTO_COMPLETE
  );

  const fetchGoogleAutoComplete = useCallback(
    async (inputValue) => {
      if (!loading) {
        if (inputValue.length >= 3 && inputValue.length % 2 === 1) {
          lastInputSearched = inputValue;
          nextInputToSearch = inputValue;
          await getGoogleAutoComplete({
            variables: {
              input: inputValue,
            },
          });
        }
      } else {
        nextInputToSearch = inputValue;
      }
    },
    [getGoogleAutoComplete, loading]
  );

  useEffect(() => {
    if (data && data.length !== 0) {
      const filteredData = data.googleAutoComplete.filter(
        (element) => element.locality !== ''
      );

      let options = [];
      for (let i = 0; i < filteredData.length; i++) {
        options.push({
          locality: filteredData[i].locality,
          country: filteredData[i].country,
          postalCode: filteredData[i].postalCode,
          fullName:
            filteredData[i].locality +
            ', ' +
            filteredData[i].country +
            ', ' +
            filteredData[i].postalCode,
          placeId: filteredData[i].placeId,
        });
      }

      //sometimes the google api sends duplicates, this removes them
      options = options.filter(
        (item, index) =>
          options.findIndex((element) => element.fullName === item.fullName) ===
          index
      );

      setAutoCompleteOptions(options);
    }
  }, [data]);

  useEffect(() => {
    if (!loading) {
      if (lastInputSearched !== nextInputToSearch) {
        fetchGoogleAutoComplete(nextInputToSearch);
      }
    }
  }, [loading, fetchGoogleAutoComplete]);

  const onTextFieldChange = (event, value) => {
    props.onTextfieldChange(value);
  };

  const onDropdownSelect = (event, value) => {
    const locality = value.locality;
    let country = '';
    if (value.country !== undefined) {
      country = value.country.split(', ');
      country = country[country.length - 1];
    }

    props.onDropdownSelect(locality, country, '');
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      {props.title && (
        <p className="lawp-google-autocomplete-custom-textfield-title">
          {' '}
          {props.title}{' '}
        </p>
      )}
      <Autocomplete
        data-cy="city-input"
        className={`lawp-google-autocomplete-custom-textfield-autocomplete ${
          props.errorMessage !== ''
            ? 'lawp-google-autocomplete-custom-textfield-error'
            : ''
        }`}
        freeSolo
        disableClearable
        options={autoCompleteOptions}
        value={{
          locality: props.value,
          country: '',
          postalCode: '',
          fullName: props.value,
        }}
        getOptionLabel={(option) => option.locality}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <LocationOnIcon className="lawp-google-autocomplete-custom-textfield-icon" />
            <span className="lawp-google-autocomplete-custom-textfield-city">
              {option.locality}
            </span>
            <span className="lawp-google-autocomplete-custom-textfield-country">
              {option.country}
            </span>
          </Box>
        )}
        onChange={(event, newValue) => {
          onDropdownSelect(event, newValue);
        }}
        renderInput={(params) => (
          <TextField
            className="lawp-google-autocomplete-custom-textfield-textfield"
            {...params}
            onChange={(event) => {
              fetchGoogleAutoComplete(event.target.value);
              onTextFieldChange(event, event.target.value);
            }}
          />
        )}
      />

      {props.errorMessage && (
        <p className="lawp-google-autocomplete-custom-textfield-error-message">
          {props.errorMessage}
        </p>
      )}
    </Stack>
  );
}
