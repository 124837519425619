import React, { useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

import { createUrlSlug } from 'Services/Utils/metaData';

import { GET_LAWYERS_FOR_LAWYER_SECTION } from 'Services/Queries/lawyer';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/lawyer/LawyerSection.scss';

import LawyerSectionCard from 'Components/Lawyer/LawyerSectionCard';

const ReviewsSection = (props) => {
  const scrollView = useRef(null);

  const navigate = useNavigate();

  let scrollDistance = 396;
  let lastPoint = 0;
  let onDrag = 0;

  let cardRef = useRef(null);

  const [pressed, setPressed] = useState(false);
  const [dragMovement, setDragMovement] = useState(0);

  const [getLawyers, { data }] = useLazyQuery(GET_LAWYERS_FOR_LAWYER_SECTION);

  useEffect(() => {
    const fetch = () => {
      if (data === undefined) {
        getLawyers();
      }
    };

    fetch();
  }, [data, getLawyers]);

  const handleMouseDown = (e) => {
    let sliderContainer = document.querySelector(
      '.lawp-lawyer-section-horizontal-scroll'
    );

    setPressed(true);
    sliderContainer.style.cursor = 'grabbing';
    setDragMovement(0);
    onDrag = 0;
  };

  const handleMouseEnter = (e) => {
    let sliderContainer = document.querySelector(
      '.lawp-lawyer-section-horizontal-scroll'
    );
    sliderContainer.style.cursor = 'grab';
  };

  const handleMouseUp = (e) => {
    let sliderContainer = document.querySelector(
      '.lawp-lawyer-section-horizontal-scroll'
    );
    sliderContainer.style.cursor = 'grab';
    setPressed(false);
  };

  const handleMouseMove = (e) => {
    if (!pressed) return;

    let currentX = e.type === 'touchmove' ? e.touches[0].clientX : e.clientX;

    const count = Math.ceil(scrollView.current.scrollLeft / scrollDistance);

    if (currentX > lastPoint && lastPoint !== 0)
      scrollView.current.scrollLeft = count * scrollDistance - scrollDistance;
    else if (currentX < lastPoint && lastPoint !== 0)
      scrollView.current.scrollLeft = count * scrollDistance + scrollDistance;

    lastPoint = currentX;

    onDrag++;
    setDragMovement(onDrag);
  };

  const handleMouseLeave = () => {
    let sliderContainer = document.querySelector(
      '.lawp-lawyer-section-horizontal-scroll'
    );
    let innerSlider = sliderContainer.firstChild;

    setPressed(false);
    innerSlider.classList.remove('active');
  };

  const handleClickOnCard = (lawyer) => {
    if (dragMovement === 0) {
      let slug = createUrlSlug(
        '',
        lawyer.first_name,
        lawyer.last_name,
        ''
      );

      slug += `-${lawyer.id}`;
      navigate(`/anwaelte/${slug}`);
    }
  };

  return (
    <div className="lawp-lawyer-section-container">
      <div
        id="reviewsSection"
        className="lawp-lawyer-section-public-top-container"
      >
        <div className="lawp-lawyer-section-public-title-container">
          <h2 className="lawp-lawyer-section-review-title">
            {LPTranslate('LPB_Lawyer_List_Title')}
          </h2>

          <div className="lawp-lawyer-section-lawyer-description">
            <h3 className="lawp-lawyer-section-lawyer-description-text">
              {LPTranslate('LPB_Lawyer_List_Description')}
            </h3>
          </div>
        </div>
        <div className="lawp-lawyer-section-view-all-button-container">
          <Button
            className="lawp-lawyer-section-view-all-button"
            data-cy="lawyer-section-start-search"
            onClick={() => {
              props.changeContent('reviews');
            }}
          >
            {LPTranslate('Common_Start_Search')}
          </Button>
        </div>
      </div>

      <div
        ref={scrollView}
        className="lawp-lawyer-section-horizontal-scroll"
        onMouseDown={handleMouseDown}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onTouchStart={handleMouseDown}
        onTouchMove={handleMouseMove}
      >
        <div className="lawp-lawyer-inner-section-horizontal-scroll">
          {data &&
            data.getLawyersForLawyerSection &&
            data.getLawyersForLawyerSection.map((lawyer, index) => {
              return (
                <LawyerSectionCard
                  handleClickOnCard={() => handleClickOnCard(lawyer)}
                  cardRef={cardRef}
                  key={index}
                  image={lawyer.lawyer_image}
                  rating={lawyer.rating}
                  name={`${lawyer.first_name} ${lawyer.last_name}`}
                  specialist={lawyer.specialist_attourneys}
                  lawFirm={lawyer.lawfirm_name}
                />
              );
            })}
        </div>
      </div>

      <div className="lawp-lawyer-section-arrow-buttons-container">
        <IconButton
          className="lawp-lawyer-section-arrow-buttons"
          data-cy="lawyer-section-left"
          onClick={() => {
            const count = Math.ceil(
              scrollView.current.scrollLeft / scrollDistance
            );
            scrollView.current.scrollLeft =
              count * scrollDistance - scrollDistance;
          }}
        >
          <ArrowBackRoundedIcon />
        </IconButton>

        <IconButton
          className="lawp-lawyer-section-arrow-buttons"
          data-cy="lawyer-section-right"
          onClick={() => {
            const count = Math.ceil(
              scrollView.current.scrollLeft / scrollDistance
            );
            scrollView.current.scrollLeft =
              count * scrollDistance + scrollDistance;
          }}
        >
          <ArrowForwardRoundedIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default ReviewsSection;
