import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

import { useLazyQuery } from '@apollo/client';
import { GET_ALL_ARTICLES } from 'Services/Queries/lawyer';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import useAlert from 'Services/Utils/hooks/useAlert';

import ArticleCard from 'Components/Articles/ArticleCard';
import ArticleSearchTextfield from 'Components/Lawyer/Article/ArticleSearchTextfield';
import AutocompleteSelectAOL from 'Components/Inputs/AutocompleteSelectAOL';
import MetaDecorator from 'Layouts/MetaDecorator';

import leftArrow from 'Assets/pictures/leftArrow.svg';
import rightArrow from 'Assets/pictures/rightArrow.svg';

import 'Assets/styles/articles/ArticlesBlogPage.scss';

const NUMBER_OF_ARTICLES_PER_PAGE = 9;

const ArticlesBlogPage = () => {
  const { setAlert } = useAlert();

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const dispatch = useDispatch();

  const [articles, { loading, error, data }] = useLazyQuery(GET_ALL_ARTICLES);

  const [areasOfLawBoxes, setAreasOfLawBoxes] = useState([]);

  const areaOfLawChangeHandler = (areaOfLawID) => {
    let newAreasOfLaw = [...areasOfLawBoxes];

    if (newAreasOfLaw.includes(areaOfLawID)) {
      newAreasOfLaw = newAreasOfLaw.filter((item) => item !== areaOfLawID);
    } else {
      newAreasOfLaw.push(areaOfLawID);
    }

    setAreasOfLawBoxes(newAreasOfLaw);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);
  }, [dispatch]);

  const handlePageChange = (value) => {
    if (value >= 1 && value <= pageCount) {
      setPage(value);
    }
  };

  const refetchArticles = () => {
    articles({
      variables: {
        input: {
          page: 1,
          limit: NUMBER_OF_ARTICLES_PER_PAGE,
          search: searchValue,
          area_of_law: areasOfLawBoxes,
        },
      },
    });
    setPage(1);
  };

  useEffect(() => {
    articles({
      variables: {
        input: {
          page: page,
          limit: NUMBER_OF_ARTICLES_PER_PAGE,
          search: searchValue,
          area_of_law: areasOfLawBoxes,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, articles]);

  useEffect(() => {
    if (data !== undefined) {
      setPageCount(data.articles.number_of_pages);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setAlert(LPTranslate('Error_Server_Down'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (loading === true || data === undefined) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '200px',
            marginBottom: '200px',
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      </>
    );
  }
  if (error) {
    return <p>error...</p>;
  }

  return (
    <>
      <MetaDecorator
        title={LPTranslate('AP_Meta_Title_1')}
        description={LPTranslate('AP_Meta_Description_1')}
        canonical={'canonical'}
        link={`${process.env.REACT_APP_DNS_URI}/rechtsinfos`}
      />

      <div className="lawp-article-blog-page-articles-blog-container">
        <div className="lawp-article-blog-page-header-section">
          <h4 className="lawp-article-blog-page-subheader">
            {' '}
            {LPTranslate('AP_Info')}{' '}
          </h4>

          <h1 className="lawp-article-blog-page-header">
            {' '}
            {LPTranslate('AP_Title')}{' '}
          </h1>

          <h3 className="lawp-article-blog-page-description">
            {LPTranslate('AP_Description')}{' '}
          </h3>
        </div>

        <div className="lawp-article-blog-page-search-section">
          <form
            style={{ display: 'flex' }}
            onSubmit={(e) => {
              e.preventDefault();
              refetchArticles();
            }}
          >
            <div className="lawp-article-blog-page-search-inputs">
              <ArticleSearchTextfield
                value={searchValue}
                data_cy={'articles-search'}
                onChange={(e) => setSearchValue(e.target.value)}
              />

              {/* <ArticleSearchDropdown
                value={searchAreaOfLaw}
                onChange={(e) => setSearchAreaOfLaw(e.target.value)}
                areasOfLaw={areasOfLawQuery}
              /> */}
              <div className="lawp-article-blog-page-aol-container">
                <AutocompleteSelectAOL
                  boxes={areasOfLawBoxes}
                  onChange={areaOfLawChangeHandler}
                  data-cy="areas-dropdown"
                />
              </div>
            </div>

            <Button
              className="lawp-article-blog-page-search-button"
              data-cy={'articles-search-button'}
              onClick={() => refetchArticles()}
              type="submit"
            >
              {windowSize.innerWidth > 1060 ? (
                LPTranslate('Button_Search')
              ) : (
                <SearchRoundedIcon className="icon-search-button" />
              )}
            </Button>
          </form>
        </div>

        <div className="lawp-article-blog-page-articles-container">
          {data.articles && data.articles.articles.map((article, index) => {
            return (
              <div
                className="lawp-article-blog-page-article-spacing"
                key={article.id}
              >
                <ArticleCard
                  article={article}
                  previousPage={'ArticleBlogPage'}
                />
              </div>
            );
          })}

          {data.articles && data.articles.articles.length === 0 && (
            <p
              className="lawp-article-blog-page-no-article-text"
              data-cy="no-results"
            >
              {LPTranslate('AP_No_Articles_Found')}
            </p>
          )}
        </div>

        {pageCount > 1 && (
          <>
            <div className="lawp-article-blog-page-article-pagination-divider" />

            <div className="lawp-article-blog-page-articles-pagination-container">
              <Button
                disableRipple
                disabled={page === 1}
                className="lawp-article-blog-page-left-arrow-button"
                data-cy={'pagination-previous-button'}
                startIcon={
                  page === 1 ? null : <img src={leftArrow} alt="leftArrow" />
                }
                onClick={() => {
                  handlePageChange(page - 1);
                }}
              >
                {page === 1 ? '' : LPTranslate('Button_Back')}
              </Button>

              <Stack>
                <Pagination
                  data-cy={'pagination'}
                  className="lawp-article-blog-page-pagination"
                  count={pageCount}
                  page={page}
                  onChange={(event, value) => {
                    handlePageChange(value);
                  }}
                />
              </Stack>

              <Button
                disableRipple
                disabled={page === pageCount}
                className="lawp-article-blog-page-right-arrow-button"
                data-cy={'pagination-next-button'}
                endIcon={
                  page === pageCount ? null : (
                    <img src={rightArrow} alt="leftArrow" />
                  )
                }
                onClick={() => handlePageChange(page + 1)}
              >
                {page === pageCount ? '' : LPTranslate('Button_Next')}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export default ArticlesBlogPage;
