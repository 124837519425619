import React from 'react';

import { useNavigate } from 'react-router-dom';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import check from 'Assets/pictures/checkCircle.svg';

import styles from 'Assets/styles/lawyer/ConfirmSendingMessageToLawyer.module.scss';

const ConfirmSendingMessageToLawyerModal = (props) => {
  let {
    lawyer_name,
    client,
    goToRegister,
    client_firstname,
    client_lastname,
    client_email,
  } = props;

  const navigate = useNavigate();

  const handleChange = () => {
    if (goToRegister) {
      navigate('/registerClient', {
        state: {
          firstname: client_firstname,
          lastname: client_lastname,
          email: client_email,
          registerFromOtherComponent: true,
        },
      });
    } else {
      props.handleClose();
    }
  };

  return (
    <div className={styles['confirm-message-to-lawyer-modal-container']}>
      <div className={styles['confirm-message-to-lawyer-modal-featured-icon']}>
        <img src={check} alt="key" />
      </div>
      <div
        className={
          styles['confirm-message-to-lawyer-modal-text-and-supporting-text']
        }
      >
        <h2 className={styles['confirm-message-to-lawyer-modal-text']}>
          {LPTranslate('USM_Confirm_Title')}
        </h2>
        <h3
          className={styles['confirm-message-to-lawyer-modal-supporting-text']}
        >
          {lawyer_name} {LPTranslate('USM_Confirm_Description_1')}
          {client.role.length === 0 && (
            <>
              <br /> <br />
            </>
          )}
          {client.role.length === 0 && LPTranslate('USM_Confirm_Description_2')}
        </h3>
      </div>

      <div className={styles['confirm-message-to-lawyer-modal-actions']}>
        <button
          data-cy="submit-button"
          className={styles['confirm-message-to-lawyer-modal-success-button']}
          onClick={handleChange}
        >
          {props.goToRegister
            ? LPTranslate('FUP_Header_Action_button_3')
            : LPTranslate('USM_Confirm_Button')}
        </button>
      </div>
    </div>
  );
};
export default ConfirmSendingMessageToLawyerModal;
