import React from 'react';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/help/CookiesPage.scss';

const TermsOfServiceLawyer = () => {
  return (
    <div className="lawp-cookies-page-container">
      <div className="lawp-cookies-page-header-section">
        <h4 className="lawp-cookies-page-subheader">
          {LPTranslate('DTAC_Pre_Title')}
        </h4>

        <h1 className="lawp-cookies-page-header">
          {LPTranslate('GTCL_Title')}
        </h1>

        <h3 className="lawp-cookies-page-description">
          {LPTranslate('GTCL_Post_Title')}
        </h3>
      </div>

      <h2 className="lawp-cookies-page-faq-section-title">
        {LPTranslate('GTCL_Description_Title_1')}
      </h2>

      <h3 className="lawp-cookies-page-text-block">
        {LPTranslate('GTCL_Description_1_1')}
        <a
          className={'lawp-cookies-page-link'}
          href={`${process.env.REACT_APP_DNS_URI}/preise`}
        >
          {LPTranslate('GTCL_Description_1_2')}
        </a>
        {LPTranslate('GTCL_Description_1_3')}
        <a
          className={'lawp-cookies-page-link'}
          href={`${process.env.REACT_APP_DNS_URI}/agb`}
        >
          {LPTranslate('GTCL_Description_1_4')}
        </a>
        {LPTranslate('GTCL_Description_1_5')}
      </h3>

      <ol>
        <li className="lawp-cookies-page-faq-section-title">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCL_Paragraph_1_Title')}
          </h2>

          <h3 className="lawp-cookies-page-text-block">
            {LPTranslate('GTCL_Paragraph_1_Description')}
          </h3>
        </li>

        <li className="lawp-cookies-page-faq-list">
          {/* <div className="lawp-cookies-page-faq-section"> */}
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCL_Paragraph_2_Title')}
          </h2>

          <ol>
            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_2_Description_1')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_2_Description_2')}
              </h3>
            </li>
          </ol>
        </li>

        {/* </div> */}

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCL_Paragraph_3_Title')}
          </h2>
          <h3 className="lawp-cookies-page-text-block">
            {LPTranslate('GTCL_Paragraph_3_Description_1')}
            <a
              className={'lawp-cookies-page-link'}
              href={`${process.env.REACT_APP_DNS_URI}`}
            >
              {LPTranslate('GTCL_Paragraph_3_Description_2')}
            </a>
            {LPTranslate('GTCL_Paragraph_3_Description_3')}
          </h3>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCL_Paragraph_4_Title')}
          </h2>

          <ol>
            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_4_Description_1_1')}
                <a
                  className={'lawp-cookies-page-link'}
                  href={`${process.env.REACT_APP_DNS_URI}/preise`}
                >
                  {LPTranslate('GTCL_Paragraph_4_Description_1_2')}
                </a>
                {LPTranslate('GTCL_Paragraph_4_Description_1_3')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_4_Description_2')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_4_Description_3')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_4_Description_4')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_4_Description_5')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_4_Description_6')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_4_Description_7')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_4_Description_8')}
              </h3>
            </li>
          </ol>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCL_Paragraph_5_Title')}
          </h2>

          <ol>
            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_5_Description_1')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_5_Description_2')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_5_Description_3')}
              </h3>
            </li>
          </ol>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCL_Paragraph_6_Title')}
          </h2>

          <ol>
            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_6_Description_1')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_6_Description_2')}
              </h3>
            </li>
          </ol>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCL_Paragraph_7_Title')}
          </h2>

          <ol>
            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_7_Description_1')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_7_Description_2')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_7_Description_3_1')}
                <a
                  className={'lawp-cookies-page-link'}
                  href={`${process.env.REACT_APP_DNS_URI}`}
                >
                  {LPTranslate('GTCL_Paragraph_7_Description_3_2')}
                </a>
                {LPTranslate('GTCL_Paragraph_7_Description_3_3')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_7_Description_4')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_7_Description_5_1')}
                <a
                  className={'lawp-cookies-page-link'}
                  href={`${process.env.REACT_APP_DNS_URI}`}
                >
                  {LPTranslate('GTCL_Paragraph_7_Description_5_2')}
                </a>
                {LPTranslate('GTCL_Paragraph_7_Description_5_3')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_7_Description_6')}
              </h3>
            </li>
          </ol>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCL_Paragraph_8_Title')}
          </h2>

          <h3 className="lawp-cookies-page-text-block">
            {LPTranslate('GTCL_Paragraph_8_Description')}
          </h3>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCL_Paragraph_9_Title')}
          </h2>

          <h3 className="lawp-cookies-page-text-block">
            {LPTranslate('GTCL_Paragraph_9_Description')}
          </h3>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCL_Paragraph_10_Title')}
          </h2>

          <ol>
            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_10_Description_1')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_10_Description_2')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_10_Description_3')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_10_Description_4')}
              </h3>
            </li>
          </ol>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCL_Paragraph_11_Title')}
          </h2>

          <h3 className="lawp-cookies-page-text-block">
            {LPTranslate('GTCL_Paragraph_11_Description')}
          </h3>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCL_Paragraph_12_Title')}
          </h2>

          <ol>
            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_12_Description_1')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_12_Description_2')}
              </h3>
            </li>
          </ol>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCL_Paragraph_13_Title')}
          </h2>

          <ol>
            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_13_Description_1')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_13_Description_2')}
              </h3>
            </li>
          </ol>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCL_Paragraph_14_Title')}
          </h2>

          <ol>
            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_14_Description_1')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_14_Description_2')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_14_Description_3')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_14_Description_4')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_14_Description_5')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_14_Description_6_1')}
                <a
                  className={'lawp-cookies-page-link'}
                  href={`${process.env.REACT_APP_DNS_URI}`}
                >
                  {LPTranslate('GTCL_Paragraph_14_Description_6_2')}
                </a>
                {LPTranslate('GTCL_Paragraph_14_Description_6_3')}
              </h3>
            </li>
          </ol>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCL_Paragraph_15_Title')}
          </h2>

          <ol>
            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_15_Description_1')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_15_Description_2')}
              </h3>
            </li>
          </ol>
        </li>

        <li className="lawp-cookies-page-faq-list">
          <h2 className="lawp-cookies-page-faq-section-title">
            {LPTranslate('GTCL_Paragraph_16_Title')}
          </h2>

          <ol>
            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_16_Description_1')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_16_Description_2')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_16_Description_3')}
              </h3>
            </li>

            <li className="lawp-cookies-page-text-block">
              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('GTCL_Paragraph_16_Description_4')}
              </h3>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

export default TermsOfServiceLawyer;
