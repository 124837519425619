import React from 'react';

import Button from '@mui/material/Button';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/lawyer/EditReloadPageSection.module.scss';

const EditReloadPageSectionInfo = (props) => {
  const { noText, pageTitle } = props;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftSection}>
          {noText !== true && (
            <div className={styles.titleContainer}>
              <span className={styles.titleText}>{pageTitle}</span>
            </div>
          )}
        </div>

        <div className={styles.rightSection}>
          <Button
            data-cy="refresh-button"
            className={styles.saveButton}
            onClick={props.onReloadChanges}
            form="update-form"
          >
            {LPTranslate('Button_Refresh')}
          </Button>
        </div>
      </div>

      {!noText && <div className={styles['Divider']} />}
    </>
  );
};

export default EditReloadPageSectionInfo;
