import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';

import TokenMiddleware from './Middleware/TokenMiddleware';
import Router from './Routes/Router';
import LoggedInMiddleware from 'Middleware/LoggedInMiddleware';
import ScrollToTop from 'Components/ScrollToTop';

import CookiesAcceptBar from 'Components/help/CookiesAcceptBar';

import 'Assets/styles/App.scss';

import history from './Routes/history';

import { AlertProvider } from 'Components/CommonComponents/SnackbarContext';
import SnackbarComponent from 'Components/CommonComponents/SnackbarComponent';

function App() {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_URI,
  });

  const authLink = TokenMiddleware();

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return (
    <>
      <div className="lawp-app-container-background">
        <div className="lawp-app-container">
          <BrowserRouter history={history}>
            <ScrollToTop />
            <ApolloProvider client={client}>
              <LoggedInMiddleware />

              <AlertProvider>
                <SnackbarComponent />
                <Router />
              </AlertProvider>

              <CookiesAcceptBar />
            </ApolloProvider>
          </BrowserRouter>
        </div>
      </div>
    </>
  );
}

export default App;
