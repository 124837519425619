import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

const subscriptionPageTextVariables = {
  subscriptionModalSubheading: [LPTranslate('Common_Subscription_For_Lawyers')],
  subscriptionModalHeading: [LPTranslate('LSUS_Title')],
  subscriptionModalHeadingSupportText: [LPTranslate('LSUS_Description')],
  Silver: {
    cardTitle: LPTranslate('Anwado_Basic_Plan'),
    priceDescription_1: LPTranslate('LSUS_Card_Silver_Description'),
    priceDescription_2: "",
    benefietsTitle: LPTranslate('LSUS_Card_Silver_Info_Title'),
    benefietsUnderTitle: LPTranslate('LSUS_Card_Silver_Info_Description'),
  },
  Gold: {
    cardTitle: LPTranslate('Anwado_Gold_Plan'),
    priceDescription_1: LPTranslate('LSUS_Card_Price_Description_1'),
    priceDescription_2: LPTranslate('LSUS_Card_Price_Description_2'),
    benefietsTitle: LPTranslate('LSUS_Card_Gold_Info_Title'),
    benefietsUnderTitle: LPTranslate('LSUS_Card_Gold_Info_Description'),
  },
  Platin: {
    cardTitle: LPTranslate('Anwado_Platin_Plan'),
    priceDescription_1: LPTranslate('LSUS_Card_Price_Description_1'),
    priceDescription_2: LPTranslate('LSUS_Card_Price_Description_2'),
    benefietsTitle: LPTranslate('LSUS_Card_Platin_Info_Title'),
    benefietsUnderTitle: LPTranslate('LSUS_Card_Platin_Info_Description'),
  },
  commonText: {
    basicBenefietsTitle: LPTranslate('LSUS_Card_Silver_Info_Title'),
    goldBenefietsTitle: LPTranslate('LSUS_Card_Gold_Info_Title'),
    platinumBenefietsTitle: LPTranslate('LSUS_Card_Platin_Info_Title'),
    basicUnderTitle: LPTranslate('LSUS_Card_Silver_Info_Description'),
    goldUnderTitle: LPTranslate('LSUS_Card_Gold_Info_Description'),
    platUnderTitle: LPTranslate('LSUS_Card_Platin_Info_Description'),
  },
  month: {
    SubscriptionCardPriceTime: LPTranslate('LSS_Plan_Month'),
    Basicplan: LPTranslate('Anwado_Basic_Plan'),
    Goldplan: LPTranslate('Anwado_Gold_Plan'),
    Platinplan: LPTranslate('Anwado_Platin_Plan'),
    BasicUnderPriceText: LPTranslate('LSUS_Card_Silver_Description'),
    GoldUnderPriceText: LPTranslate('LSUS_Card_Gold_Description'),
    PlatinUnderPriceText: LPTranslate('LSUS_Card_Platin_Description'),
  },
  year: {
    SubscriptionCardPriceTime: LPTranslate('LSS_Plan_Year'),
    Basicplan: LPTranslate('Anwado_Basic_Plan'),
    Goldplan: LPTranslate('Anwado_Gold_Plan'),
    Platinplan: LPTranslate('Anwado_Platin_Plan'),
    BasicUnderPriceText: LPTranslate('LSUS_Card_Silver_Description'),
    GoldUnderPriceText: LPTranslate('LSUS_Card_Gold_Description'),
    PlatinUnderPriceText: LPTranslate('LSUS_Card_Platin_Description'),
    mostPopular: LPTranslate('LSUS_Most_Popular'),
  },
  mostPopular: LPTranslate('LSUS_Most_Popular'),
  perMonth: LPTranslate('LSS_Plan_Month'),
  perYear: LPTranslate('LSS_Plan_Year'),
  monthly: [LPTranslate('LSUS_Switch_Month')],
  yearly: [LPTranslate('LSUS_Switch_Year')],
  persentage: ['10%'],
};

export default subscriptionPageTextVariables;
