import React from 'react';

import { useNavigate } from 'react-router';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import arrowLeft from 'Assets/pictures/arrow-left.svg';
import mailIcon from 'Assets/pictures/mailIcon.svg';
import styles from 'Assets/styles/forgotPassword/EmailPasswordSent.module.scss';
import commonStyles from 'Assets/styles/forgotPassword/ForgotPasswordCommon.module.scss';

const EmailPasswordSent = (props) => {
  const [resentPasswordTimeout, setResentPasswordTimeout] =
    React.useState(false);

  const navigate = useNavigate(-1);
  const handleSendAgain = () => {
    if (resentPasswordTimeout) return;

    setResentPasswordTimeout(true);
    setTimeout(() => {
      setResentPasswordTimeout(false);
    }, 10000);

    props.resendEmail(props.email);
  };
  return (
    <div className={commonStyles['forgot-password-page-container']}>
      <div
        className={`${commonStyles['forgot-password-box-container']}`}
        // ${styles['email-password-sent-box-container']}`}
      >
        <div
          className={`${commonStyles['forgot-password-header']}`}
          // ${styles['email-password-sent-header']}`}
        >
          <div className={commonStyles['forgot-password-header-icon']}>
            <img src={mailIcon} alt="Mail Icon" />
          </div>
          <div
            className={`${commonStyles['forgot-password-header-text-support-container']}`}
            // ${styles['email-password-sent-header-text-support-container']}`}
          >
            <div className={commonStyles['forgot-password-header-text']}>
              {LPTranslate('Common_Check_Your_Email')}
            </div>
            <div className={commonStyles['forgot-password-header-support']}>
              {`Wir haben Ihnen eine Email zur Verifikation an die 
              ${props.email} gesendet. Bitte überprüfen Sie auch Ihren Spam- oder Junk-Ordner, falls Sie die E-Mail nicht in Ihrem Posteingang finden können.`}
            </div>
          </div>
        </div>
        <div className={styles['email-password-sent-content-text-and-button']}>
          <div className={styles['email-password-sent-content-text']}>
            {LPTranslate('Common_No_Email_Received')}
          </div>
          <div
            data-cy="send-again-button"
            className={styles['email-password-sent-content-button']}
            style={{
              color: resentPasswordTimeout ? 'gray' : '',
              cursor: resentPasswordTimeout ? 'auto' : '',
            }}
            onClick={handleSendAgain}
          >
            {LPTranslate('Common_Resend_Email')}
          </div>
        </div>
        <div
          className={
            styles['email-password-sent-content-button-timeout-container']
          }
        >
          {resentPasswordTimeout && (
            <div
              className={styles['email-password-sent-content-button-timeout']}
            >
              {LPTranslate('Common_Resend_Email_Message')}
            </div>
          )}
        </div>
        <div className={styles['email-password-sent-back-button']}></div>
        <div
          data-cy="back-button"
          className={styles['email-password-sent-back-button-text-container']}
          onClick={() => navigate(-1)}
        >
          <div className={styles['email-password-sent-back-button']}>
            <img src={arrowLeft} alt="arrow left" />
          </div>
          <div
            className={styles['email-password-sent-back-text']}
            // onClick={(e) => console.log("email sent")}
          >
            {LPTranslate('Common_Back_To_Login')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailPasswordSent;
