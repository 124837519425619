import React, { useState, useEffect, useCallback } from 'react';

import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import { useMutation } from '@apollo/client';

import EmailPasswordSent from 'Components/ForgotPassword/EmailPasswordSent';
import EmailPasswordRequest from 'Components/ForgotPassword/EmailPasswordRequest';
import NewPassword from 'Components/ForgotPassword/NewPassword';
import PasswordResetComplete from 'Components/ForgotPassword/PasswordResetComplete';

import {
  FORGOT_PASSWORD,
  VERIFY_PARAM_TOKEN_MUTATION,
} from 'Services/Queries/user';

const getSteps = [
  {
    label: 'Email Request',
    isChecked: false,
  },
  {
    label: 'Email Sent',
    isChecked: false,
  },
  {
    label: 'New Password',
    isChecked: false,
  },
  {
    label: 'Password reset complete',
    isChecked: false,
  },
];

const ResetForgotPaswwordPage = () => {
  let { forgotPasswordToken } = useParams();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');

  const [currentStep, updateCurrentStep] = useState(0);

  const [forgotPassword] = useMutation(FORGOT_PASSWORD);
  const [verifyParamToken, { data, loading, error }] = useMutation(
    VERIFY_PARAM_TOKEN_MUTATION
  );

  const handleSendEmail = async (email) => {
    try {
      forgotPassword({
        variables: { input: { email: email } },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmail = (email) => {
    setEmail(email);
  };

  const handleNext = useCallback((step) => {
    getSteps[step].isChecked = true;
    updateCurrentStep(step + 1);
  }, []);

  useEffect(() => {
    if (forgotPasswordToken) {
      try {
        const verifyParam = async () => {
          await verifyParamToken({
            variables: { input: { token: forgotPasswordToken, type: 'reset' } },
          });
        };

        verifyParam();
      } catch (error) {
        console.log(error);
      }
    }
  }, [forgotPasswordToken, verifyParamToken]);

  useEffect(() => {
    if (data && data.verifyParamTokenMutation) {
      if (data.verifyParamTokenMutation.message === 'error') {
        alert(data.verifyParamTokenMutation.error);
        navigate('/resetForgotPassword');
      } else if (data.verifyParamTokenMutation.message === 'Token accepted') {
        handleNext(1);
      }
    }
  }, [data, handleNext, loading, navigate]);

  if (error) return `Submission error! ${error.message}`;

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <EmailPasswordRequest
              handleNext={handleNext}
              handleEmail={handleEmail}
              sendEmail={handleSendEmail}
            />
          </>
        );

      case 1:
        return (
          <>
            <EmailPasswordSent
              handleNext={handleNext}
              email={email}
              resendEmail={handleSendEmail}
            />
          </>
        );
      case 2:
        return (
          <>
            <NewPassword
              handleNext={handleNext}
              forgotPasswordToken={forgotPasswordToken}
            />
          </>
        );
      case 3:
        return (
          <>
            <PasswordResetComplete handleNext={handleNext} />
          </>
        );

      default:
        return 'unknown step';
    }
  };

  return <>{getStepContent(currentStep)}</>;
};

export default ResetForgotPaswwordPage;
