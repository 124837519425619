import React from 'react';

import ReactPlayer from 'react-player';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import AvatarRowGroup from 'Components/AvatarRowGroup';
import RatingStars from 'Components/RatingStars/RatingStars';

import HomePageIntro720p from 'Assets/videos/HomePageIntro720p.mp4';
import HomePageIntro1080 from 'Assets/videos/HomePageIntro1080p.mp4';
import HomepageIntroThumbnail from 'Assets/videos/HomePageIntroThumbnail.jpg';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/LandingPageTopView.module.scss';

const LandingPageTopView = (props) => {
  const [fullScreen, setFullScreen] = React.useState(false);
  const [videoTime, setVideoTime] = React.useState(0);
  const [isReady, setIsReady] = React.useState(true);

  const playerRef = React.useRef(null);

  React.useEffect(() => {
    const handleFullScreenChange = () => {
      try {
        if (document.fullscreenElement) {
          setFullScreen(true);
          setIsReady(true);
        } else {
          setFullScreen(false);
          setIsReady(true);
        }
      } catch (e) {
        console.log(e);
      }
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  return (
    <div className={styles.container} id="landing-page-top-view">
      <div className={styles.centerContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.titleContainer}>
            <h1 className={styles.title}>{LPTranslate('LP_Content_Title')}</h1>
          </div>

          <div className={styles.subTitleContainer}>
            <h2 className={styles.subTitle}>
              {LPTranslate('LP_Content_Description_Heading')} <br />
              {LPTranslate('Common_Find_Rate_Make_Appointment')} <br />
            </h2>
          </div>

          <div className={styles.lawyersContainer}>
            <div className={styles.avatarGroup}>
              <AvatarRowGroup
                lawyerLoading={props.lawyerLoading}
                lawyerError={props.lawyerError}
              />
            </div>
            <div className={styles.ratingContainer}>
              <div className={styles.starsContainer}>
                {props.lawyerLoading || props.lawyerError ? (
                  <Box
                    sx={{ width: 200, display: 'flex', flexDirection: 'row' }}
                  >
                    <Skeleton
                      animation="wave"
                      sx={{ width: '120px', mr: '20px' }}
                    />
                    <Skeleton animation="wave" sx={{ width: '60px' }} />
                  </Box>
                ) : (
                  <>
                    <div className={styles['rating-container']}>
                      <RatingStars
                        value={5}
                        width={20}
                        height={20}
                        readonly={true}
                        colorFilter={
                          'invert(91%) sepia(69%) saturate(1377%) hue-rotate(317deg) brightness(99%) contrast(102%)'
                        }
                      />
                      <h3 className={styles['ratingText']}>5.0</h3>
                    </div>
                    <h4 className={styles['rating-subTitle']}>
                      {LPTranslate('LP_Content_Description_Under_Rating')}
                    </h4>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.imageContainer}>
          <div className={styles.video}>
            <ReactPlayer
              ref={playerRef}
              data-cy="react-player"
              className={styles.reactPlayer}
              url={fullScreen ? HomePageIntro1080 : HomePageIntro720p}
              light={HomepageIntroThumbnail}
              progressInterval={100}
              onProgress={() => {
                setVideoTime(playerRef.current.getCurrentTime());
              }}
              onReady={() => {
                if (isReady) {
                  const timeToStart = videoTime;
                  playerRef.current.seekTo(timeToStart, 'seconds');
                  setIsReady(false);
                }
              }}
              width="100%"
              height="100%"
              controls={true}
              muted={false}
              playing={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPageTopView;
