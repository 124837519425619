import React from 'react';

import imgPlaceholder1 from 'Assets/pictures/AvatarImage1.png';
import imgPlaceholder2 from 'Assets/pictures/AvatarImage2.png';
import imgPlaceholder3 from 'Assets/pictures/AvatarImage3.png';
import imgPlaceholder4 from 'Assets/pictures/AvatarImage4.png';

import 'Assets/styles/help/LawyerNotificationMockup.scss';

const LawyerNotificationMockup = (props) => {
  const { authorName, displayData, transparencyValue } = props;

  const placeholderImageSelector = (index) => {
    switch (index) {
      case 1:
        return imgPlaceholder1;
      case 2:
        return imgPlaceholder2;
      case 3:
        return imgPlaceholder3;
      case 4:
        return imgPlaceholder4;
      default:
        return imgPlaceholder1;
    }
  };

  return (
    <div>
      <div
        className="lawp-lawyer-notification-mockup-article-author"
        style={{
          opacity: transparencyValue,
        }}
      >
        <img
          src={placeholderImageSelector(props.placeholderImageIndex)}
          alt="placeholder"
          className="lawp-lawyer-notification-mockup-article-author-image"
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <h3 className="lawp-lawyer-notification-mockup-author-name">
              {authorName}
            </h3>
            &nbsp;
            <h3
              style={{
                color: '#344054',
                fontWeight: 400,
                fontSize: 14,
                display: 'inline-block',
              }}
            >
              {props.nameFollowup}
            </h3>
          </div>
          <h3 className="lawp-lawyer-notification-mockup-article-date">
            {displayData}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default LawyerNotificationMockup;
