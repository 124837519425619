import React, { useEffect, useRef, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

import ReviewCardPublic from 'Components/Lawyer/ReviewCardPublic';

import { GET_REVIEWS_OF_LAWYER } from 'Services/Queries/lawyer';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/lawyer/ReviewsSection.scss';

const ReviewsSection = (props) => {
  const { lawyer } = props;

  const scrollView = useRef(null);

  let scrollDistance = 396;
  let lastPoint = 0;
  let onDrag = 0;

  const [pressed, setPressed] = useState(false);
  const [dragMovement, setDragMovement] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const [reviewsLawyerQuery, { data }] = useLazyQuery(GET_REVIEWS_OF_LAWYER);

  const refetchLawyerReviews = async () => {
    reviewsLawyerQuery({
      variables: {
        input: {
          lawyer_id: lawyer.id,
          page: 1,
          limit: 8,
        },
      },
    });
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {
    refetchLawyerReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ratingData]);

  if (!data?.reviewsOfLawyer?.reviews?.length && props.hideIfNoReviews) {
    return null;
  }

  const handleMouseDown = (e) => {
    if (!isModalOpened) {
      let sliderContainer = document.querySelector(
        '.lawp-reviews-section-horizontal-scroll'
      );

      setPressed(true);
      sliderContainer.style.cursor = 'grabbing';
      setDragMovement(0);
      onDrag = 0;
    }
  };

  const handleMouseEnter = (e) => {
    if (!isModalOpened) {
      let sliderContainer = document.querySelector(
        '.lawp-reviews-section-horizontal-scroll'
      );
      sliderContainer.style.cursor = 'grab';
    }
  };

  const handleMouseMove = (e) => {
    if (!isModalOpened) {
      if (!pressed) return;

      let currentX = e.type === 'touchmove' ? e.touches[0].clientX : e.clientX;

      const count = Math.ceil(scrollView.current.scrollLeft / scrollDistance);

      if (currentX > lastPoint && lastPoint !== 0)
        scrollView.current.scrollLeft = count * scrollDistance - scrollDistance;
      else if (currentX < lastPoint && lastPoint !== 0)
        scrollView.current.scrollLeft = count * scrollDistance + scrollDistance;
      lastPoint = currentX;

      onDrag++;
      setDragMovement(onDrag);
    }
  };

  const handleMouseUp = (e) => {
    if (!isModalOpened) {
      let sliderContainer = document.querySelector(
        '.lawp-reviews-section-horizontal-scroll'
      );
      sliderContainer.style.cursor = 'grab';
      setPressed(false);
    }
  };

  const handleMouseLeave = () => {
    if (!isModalOpened) {
      setPressed(false);
    }
  };

  return (
    <div className="lawp-reviews-section-container">
      <div
        id="reviewsSection"
        className="lawp-reviews-section-public-top-container"
      >
        <div className="lawp-reviews-section-public-title-container">
          <h4 className="lawp-reviews-section-client-name">
            {LPTranslate('RCLP_Pre_Title')}
          </h4>

          <h2 className="lawp-reviews-section-review-title">
            {LPTranslate('RCLP_Title')} {lawyer.title && `${lawyer.title} `}
            {lawyer.first_name} {lawyer.last_name}
          </h2>

          {/* <div className="lawp-reviews-section-lawyer-description">
            <h3 className="lawp-reviews-section-lawyer-description-text">
              {LPTranslate('RCLP_Description')}
            </h3>
          </div> */}
        </div>

        {data && data.reviewsOfLawyer.number_of_pages > 1 && (
          <div className="lawp-reviews-section-view-all-button-container">
            <Button
              data-cy={'send-reviews-button'}
              className="lawp-reviews-section-view-all-button"
              onClick={() => {
                props.changeContent('reviews');
              }}
            >
              {LPTranslate('RCLP_Button')}
            </Button>
          </div>
        )}
      </div>

      <div
        ref={scrollView}
        className="lawp-reviews-section-horizontal-scroll"
        onMouseDown={handleMouseDown}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onTouchStart={handleMouseDown}
        onTouchMove={handleMouseMove}
      >
        {data?.reviewsOfLawyer &&
          data.reviewsOfLawyer.reviews &&
          data.reviewsOfLawyer.reviews.map((review, index) => {
            return (
              <ReviewCardPublic
                key={index}
                dragMovement={dragMovement}
                handleIsModalOpened={(val) => setIsModalOpened(val)}
                lawyerImage={lawyer.profileImage}
                review={review}
                onRefetch={() => refetchLawyerReviews()}
              />
            );
          })}
      </div>

      {windowWidth < 1060 || data?.reviewsOfLawyer.reviews.length > 3 ? (
        <div className="lawp-reviews-section-arrow-buttons-container">
          <IconButton
            data-cy="left-arrow"
            className="lawp-reviews-section-arrow-buttons"
            onClick={() => {
              const count = Math.ceil(scrollView.current.scrollLeft / 400);
              scrollView.current.scrollLeft = count * 400 - 400;
            }}
          >
            <ArrowBackRoundedIcon />
          </IconButton>

          <IconButton
            data-cy="right-arrow"
            className="lawp-reviews-section-arrow-buttons"
            onClick={() => {
              const count = Math.ceil(scrollView.current.scrollLeft / 400);
              scrollView.current.scrollLeft = count * 400 + 400;
            }}
          >
            <ArrowForwardRoundedIcon />
          </IconButton>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ReviewsSection;
