import React from 'react';

import RatingStars from 'Components/RatingStars/RatingStars';
import AvatarRowGroup from 'Components/AvatarRowGroup';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import styles from 'Assets/styles/registerClient/registerCarousel.module.scss';

const RegisterCarousel = () => {
  return (
    <div className={styles.RightSection}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.textAndSupportingText}>
            <h2 className={styles.text}>
              {LPTranslate('RC_Photo_Content_title')}
            </h2>

            <h2 className={styles.supportingText}>
              <br /> {LPTranslate('RL_Photo_Content_Description_1')}
            </h2>
          </div>

          <div className={styles.lawyersContainer}>
            <div className={styles.avatarGroup}>
              <AvatarRowGroup />
            </div>
            <div className={styles.ratingContainer}>
              <div className={styles.starsContainer}>
                <RatingStars
                  value={5}
                  width={20}
                  height={20}
                  readonly={true}
                  colorFilter={
                    'invert(91%) sepia(69%) saturate(1377%) hue-rotate(317deg) brightness(99%) contrast(102%)'
                  }
                />
              </div>
              <div className={styles.reviewsContainer}>
                <h3 className={styles.reviewsText}>
                  {LPTranslate('RL_Photo_Content_Clients_Description')}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.backgroundOverlay} />
    </div>
  );
};

export default RegisterCarousel;
