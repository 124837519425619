import React from 'react';

import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { parseJwt } from 'Services/Utils/token';

export const AuthenticationRoute = () => {
  const jwtToken = useSelector((state) => state.clientInfo.value.token);

  return jwtToken ? <Outlet /> : <Navigate to="/login" />;
};

export const LawyerAuthenticationRoute = () => {
  const jwtToken = useSelector((state) => state.clientInfo.value.token);
  const parseToken = parseJwt(jwtToken);

  const params = new URLSearchParams(window.location.search);

  if (parseToken && parseToken.role === 'lawyer') return <Outlet />;

  if (params.get('email') === null) return <Navigate to="/login" />;

  return <Navigate to={`/login?email=${params.get('email')}`} />;
};

export const OperatorAuthenticationRoute = () => {
  const jwtToken = useSelector((state) => state.clientInfo.value.token);
  const parseToken = parseJwt(jwtToken);

  if (parseToken && parseToken.role === 'operator') return <Outlet />;

  return <Navigate to="/login" />;
};

export const ClientAuthenticationRoute = () => {
  const jwtToken = useSelector((state) => state.clientInfo.value.token);
  const parseToken = parseJwt(jwtToken);

  if (parseToken && parseToken.role === 'client') return <Outlet />;

  return <Navigate to="/login" />;
};
