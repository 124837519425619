import React from 'react';

import * as ReactQuill from 'react-quill';
import Quill from 'quill';
import { useState, useEffect, useMemo } from 'react';

import 'quill/dist/quill.snow.css';

import 'Assets/styles/lawyer/UpdateLawyerQuill.scss';

import DropPicture from 'Assets/pictures/DropDownArrowIcon.svg';

const QuillTextFields = (props) => {
  let { title, id, value, onChange } = props;

  var icons = ReactQuill.Quill.import('ui/icons');

  icons['link'] = `<svg width="20" height="11" viewBox="0 0 20 11">
      <path
      
        d="M1.9 5.2019C1.9 3.4919 3.29 2.1019 5 2.1019H9V0.201904H5C2.24 0.201904 0 2.4419 0 5.2019C0 7.9619 2.24 10.2019 5 10.2019H9V8.3019H5C3.29 8.3019 1.9 6.9119 1.9 5.2019ZM6 6.2019H14V4.2019H6V6.2019ZM15 0.201904H11V2.1019H15C16.71 2.1019 18.1 3.4919 18.1 5.2019C18.1 6.9119 16.71 8.3019 15 8.3019H11V10.2019H15C17.76 10.2019 20 7.9619 20 5.2019C20 2.4419 17.76 0.201904 15 0.201904Z"
      />
    </svg>`;

  icons['bold'] = `<svg width="11" height="15" viewBox="0 0 11 15">
  <path
   d="M8.6 6.9919C9.57 6.3219 10.25 5.2219 10.25 4.2019C10.25 1.9419 8.5 0.201904 6.25 0.201904H0V14.2019H7.04C9.13 14.2019 10.75 12.5019 10.75 10.4119C10.75 8.8919 9.89 7.5919 8.6 6.9919ZM3 2.7019H6C6.83 2.7019 7.5 3.3719 7.5 4.2019C7.5 5.0319 6.83 5.7019 6 5.7019H3V2.7019ZM6.5 11.7019H3V8.7019H6.5C7.33 8.7019 8 9.3719 8 10.2019C8 11.0319 7.33 11.7019 6.5 11.7019Z" 
   />
  </svg>
  `;

  icons['italic'] = `<svg width="12" height="15" viewBox="0 0 12 15" >
  <path d="M4 0.201904V3.2019H6.21L2.79 11.2019H0V14.2019H8V11.2019H5.79L9.21 3.2019H12V0.201904H4Z" />
  </svg>
  `;

  icons.list.ordered = `<svg width="19" height="17" viewBox="0 0 19 17"  >
  <path d="M0 13.2019H2V13.7019H1V14.7019H2V15.2019H0V16.2019H3V12.2019H0V13.2019ZM1 4.2019H2V0.201904H0V1.2019H1V4.2019ZM0 7.2019H1.8L0 9.3019V10.2019H3V9.2019H1.2L3 7.1019V6.2019H0V7.2019ZM5 1.2019V3.2019H19V1.2019H5ZM5 15.2019H19V13.2019H5V15.2019ZM5 9.2019H19V7.2019H5V9.2019Z"/>
  </svg>
  `;
  icons.list.bullet = `<svg width="20" height="16" viewBox="0 0 20 16" ">
  <path d="M2.25 6.7019C1.42 6.7019 0.75 7.3719 0.75 8.2019C0.75 9.0319 1.42 9.7019 2.25 9.7019C3.08 9.7019 3.75 9.0319 3.75 8.2019C3.75 7.3719 3.08 6.7019 2.25 6.7019ZM2.25 0.701904C1.42 0.701904 0.75 1.3719 0.75 2.2019C0.75 3.0319 1.42 3.7019 2.25 3.7019C3.08 3.7019 3.75 3.0319 3.75 2.2019C3.75 1.3719 3.08 0.701904 2.25 0.701904ZM2.25 12.7019C1.42 12.7019 0.75 13.3819 0.75 14.2019C0.75 15.0219 1.43 15.7019 2.25 15.7019C3.07 15.7019 3.75 15.0219 3.75 14.2019C3.75 13.3819 3.08 12.7019 2.25 12.7019ZM5.25 15.2019H19.25V13.2019H5.25V15.2019ZM5.25 9.2019H19.25V7.2019H5.25V9.2019ZM5.25 1.2019V3.2019H19.25V1.2019H5.25Z" />
  </svg>
  `;

  //automatically adds http:// to links:
  const Link = Quill.import('formats/link');
  // Override the existing property on the Quill global object and add custom protocols
  Link.PROTOCOL_WHITELIST = [
    'http',
    'https',
    'mailto',
    'tel',
    'radar',
    'rdar',
    'smb',
    'sms',
  ];

  class CustomLinkSanitizer extends Link {
    static sanitize(url) {
      // Run default sanitize method from Quill
      const sanitizedUrl = super.sanitize(url);

      // Not whitelisted URL based on protocol so, let's return `blank`
      if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl;

      // Verify if the URL already have a whitelisted protocol
      const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST.some(function (
        protocol
      ) {
        return sanitizedUrl.startsWith(protocol);
      });

      if (hasWhitelistedProtocol) return sanitizedUrl;

      // if not, then append only 'http' to not to be a relative URL
      return `http://${sanitizedUrl}`;
    }
  }

  Quill.register(CustomLinkSanitizer, true);

  //automatically adds http:// to links//

  useEffect(() => {
    const toolbarList = document.querySelectorAll('.toolbar-container');

    toolbarList.forEach((toolbar) => {
      const img = document.createElement('img');
      img.setAttribute('alt', 'downArrow');
      img.setAttribute('src', DropPicture);
      img.setAttribute('class', 'dropPicture');

      const child = toolbar.querySelector('.ql-size.ql-picker');
      const childTwo = child.querySelector('.ql-picker-label');

      const svg = childTwo.lastElementChild;

      childTwo.removeChild(svg);
      childTwo.append(img);
    });
  }, []);

  const quillRef = React.useRef();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewContent, setPreviewContent] = useState('');

  // Font sizes:
  //!!! Change default font size from scss file, .ql-container !!!

  var fontSizeStyle = Quill.import('attributors/style/size');
  fontSizeStyle.whitelist = ['14px', '18px', '22px', '26px'];

  Quill.register(fontSizeStyle, true);
  // Font sizes//

  const [fontSize, setFontSize] = useState('18px');
  const CustomToolbar = () => (
    <div className="toolbar-container toolbar-test" id={`toolbar-${id}`}>
      <select
        data-cy={
          props.quill_buttons_data_cy ? 'aam-ql-size-button' : 'ql-size-button'
        }
        className="ql-size"
        value={fontSize}
        onChange={(e) => {
          setFontSize(e.target.value);
        }}
      >
        <option value="14px">Small</option>
        <option value="18px">Medium</option>
        <option value="22px">Large</option>
        <option value="26px">Huge</option>
      </select>
      <div className=".ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) ">
        <div className=".dropImg"></div>
      </div>
      <button
        data-cy={
          props.quill_buttons_data_cy ? 'aam-ql-bold-button' : 'ql-bold-button'
        }
        className="ql-bold"
      />
      <button
        data-cy={
          props.quill_buttons_data_cy
            ? 'aam-ql-italic-button'
            : 'ql-italic-button'
        }
        className="ql-italic"
      />
      <button
        data-cy={
          props.quill_buttons_data_cy ? 'aam-ql-link-button' : 'ql-link-button'
        }
        className="ql-link"
      />

      <button
        data-cy={
          props.quill_buttons_data_cy
            ? 'aam-ql-bullet-button'
            : 'ql-bullet-button'
        }
        className="ql-list"
        value="bullet"
      ></button>
      <button
        data-cy={
          props.quill_buttons_data_cy ? 'aam-ql-list-button' : 'ql-list-button'
        }
        className="ql-list"
        value="ordered"
      ></button>
    </div>
  );

  const handleChange = (val, delta, source, editor) => {
    if (val === value) return;

    if (val === '<p><br></p>') val = '';

    onChange(val);

    if (previewOpen) {
      setPreviewContent(editor.getText());
    }
  };

  const quillModules = useMemo(() => {
    return {
      toolbar: {
        container: `#toolbar-${id}`,
        handlers: {
          preview: function (value) {
            const html = this.quill.root.innerHTML;
            setPreviewContent(html);
            setPreviewOpen(!previewOpen);
          },
        },
      },
    };
  }, [id, previewOpen]);

  const quillFormats = ['bold', 'italic', 'size', 'list', 'link'];

  return (
    <>
      <div className="quillTab">
        <div className="text-editor">
          {props.titleAbove ? (
            <>
              <div className="titleElementAbove">{title}</div>
              <div className="toolBarBelow">{CustomToolbar(id)}</div>
            </>
          ) : (
            <>
              {CustomToolbar(id)}

              <div className="titleElement">{title}</div>
            </>
          )}

          <div
            className="quill-container"
            data-text-editor={`name${id}`}
            data-cy={props.data_cy}
          >
            <ReactQuill
              ref={quillRef}
              value={value}
              onChange={(e, delta, source, editor) => {
                handleChange(e, delta, source, editor);
              }}
              bounds={`[data-text-editor="name${id}"]`}
              modules={quillModules}
              formats={quillFormats}
            />
          </div>
          {previewOpen ? (
            <div dangerouslySetInnerHTML={{ __html: previewContent }} />
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default QuillTextFields;
