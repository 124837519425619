import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import EmailChecker from 'Components/RegisterLawyer/NewRegister/steps/EmailChecker';
import VerificationCode from 'Components/RegisterLawyer/NewRegister/steps/VerificationCode';
import VerifyLawyerOnRegister from 'Components/RegisterLawyer/NewRegister/steps/VerifyLawyerOnRegister';
import SubscriptionStep from 'Components/RegisterLawyer/NewRegister/steps/SubscriptionStep';
import SetLawyerPasswordOnRegister from 'Components/RegisterLawyer/NewRegister/steps/SetLawyerPasswordOnRegister';
import NewLawyerEmail from 'Components/RegisterLawyer/NewRegister/steps/NewLawyerEmail';
import SetLawyerAdressDetailsOnRegister from '../SetLawyerAdressDetailsOnRegister';

import LawyerProfileChecker from './steps/LawyerProfileChecker';
import CreateLawyerAccount from 'Components/RegisterLawyer/CreateLawyerAccount';
import StepNavigation from 'Components/Stepper/stepNavigation';
import { DELETE_LAWYER_FROM_REGISTER } from 'Services/Queries/lawyer';

import {
  setLawyerStepper,
  addFirstname,
  addLastname,
  addUserEmail,
  addRoleEmail,
} from 'Services/Redux/reducers/RegisterReducer';

import registerSteps from 'Services/registerSteps';

import logo from 'Assets/pictures/anwado-logo2.svg';
import styles from 'Assets/styles/registerLawyer/VerifyNewLawyerPage.module.scss';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// noua functie de generate otp code care sa si trimita email(RESEND_OTP_CODE) care sa creeze si sa trimita catre lawyer_id
const RegisterLawyerProcess = (props) => {
  const {
    availableLawyers,
    formValues,
    handleRegisterLawyer,
    registerLawyerData,
  } = props;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const snackbarSeverity = 'success';
  const snackbarMessage = false;
  const temporaryEmail = formValues.email;

  const lawyerRegisterRedux = useSelector(
    (state) => state.registerStepper.value
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [deleteUser] = useMutation(DELETE_LAWYER_FROM_REGISTER);

  const [currentStep, updateCurrentStep] = useState(0);
  const [passwordOnRegister, setPasswordOnRegister] = useState('');

  const [lawyerSelected, setLawyerSelected] = useState(null);

  const checkVar = (variable) => {
    return variable && variable.length > 0 ? variable : '';
  };

  const title = checkVar(formValues.title);
  const salutation = checkVar(formValues.salutation);
  const firstName = checkVar(formValues.firstName);
  const lastName = checkVar(formValues.lastName);
  const [email, setEmail] = useState(checkVar(formValues.email));
  const [hashedEmail, setHashedEmail] = useState('');

  const handleChangePassword = (password) => {
    setPasswordOnRegister(password);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  useEffect(() => {
    // Push the current state into the history stack
    window.history.pushState(null, document.title, window.location.href);

    // Handle popstate events
    const handlePopState = (event) => {
      window.history.pushState(null, document.title, window.location.href);
    };

    // Add the event listener
    window.addEventListener('popstate', handlePopState);

    // Cleanup: Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []); // Empty dependency array ensures this effect runs once when the component mounts and cleans up when it unmounts

  useEffect(() => {
    if (registerLawyerData) {
      if (
        registerLawyerData.registerLawyer &&
        registerLawyerData.registerLawyer.user_email
      ) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'lawyer-register-custom-event',
        });
        dispatch(addFirstname(firstName));
        dispatch(addLastname(lastName));
        dispatch(addRoleEmail(email));
        dispatch(addUserEmail(registerLawyerData.registerLawyer.user_email));

        setEmail(registerLawyerData.registerLawyer.user_email);
        hashEmail(email);
        handleNext(1);
      } else {
        console.log('eroare');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerLawyerData]);

  useEffect(() => {
    updateCurrentStep(lawyerRegisterRedux.lawyerStepper);
  }, [lawyerRegisterRedux]);

  useEffect(() => {
    if (availableLawyers.length === 1) {
      const singleLawyer = availableLawyers[0];
      handleRegisterLawyer(
        singleLawyer.id,
        title,
        salutation,
        email,
        firstName,
        lastName,
        true
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableLawyers]);

  const hashEmail = (email) => {
    const emailTobeHashed = email ? email : temporaryEmail;
    let index1 = emailTobeHashed.indexOf('@') - 1;

    var arr1 = emailTobeHashed.split('');

    for (let i = 1; i < 4; i++) {
      arr1.splice(index1 - i, 1, '*');
    }

    const after = emailTobeHashed.substring(emailTobeHashed.indexOf('@') + 1);
    let index2 = after.indexOf('.');

    var arr2 = after.split('');

    for (let i = 1; i < 3; i++) {
      arr2.splice(index2 - i, 1, '*');
    }

    var result1 = arr1.join('').substring(0, emailTobeHashed.indexOf('@'));

    var result2 = arr2.join('');

    setHashedEmail(result1 + '@' + result2);
  };

  const handleNext = (step) => {
    dispatch(setLawyerStepper(step + 1));
  };

  const returnToRegister = async () => {
    //delete user and get back 1 step
    try {
      let deleteUserFromRegister = await deleteUser({
        variables: {
          email: lawyerRegisterRedux.temporaryEmail,
        },
      });
      if (
        deleteUserFromRegister.data.deleteUserFromRegister.message ===
        'User has been deleted!'
      )
        dispatch(addFirstname(''));
      dispatch(addLastname(''));
      dispatch(setLawyerStepper(0));
      navigate('/registerLawyer', {
        state: { isSentFromLawyerPage: false },
      });
    } catch (error) {
      // show error to user
    }
  };

  const handleBack = (step) => {
    dispatch(setLawyerStepper(step + 1));
  };

  function updateStep(step) {
    updateCurrentStep(step);
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        if (availableLawyers.length === 1) {
          const singleLawyer = availableLawyers[0];

          localStorage.setItem('ClaimedLawyerId', singleLawyer.id);

          return getStepContent(step + 1); // Skip to the next step
        }
        return (
          <React.Fragment>
            <LawyerProfileChecker
              title={title}
              salutation={salutation}
              firstName={firstName}
              lastName={lastName}
              email={email}
              availableLawyers={availableLawyers}
              lawyerSelected={lawyerSelected}
              setLawyerSelected={setLawyerSelected}
              handleRegisterLawyer={handleRegisterLawyer}
              registerLawyerData={registerLawyerData}
              returnToRegister={returnToRegister}
            />
          </React.Fragment>
        );

      case 1:
        return (
          <>
            <EmailChecker
              step={step}
              handleNext={handleNext}
              hashedEmail={hashedEmail}
              handleBack={handleBack}
              returnToRegister={returnToRegister}
            />
          </>
        );
      case 2:
        return (
          <>
            <VerificationCode
              handleNext={handleNext}
              firstName={lawyerRegisterRedux.firstName}
              lastName={lawyerRegisterRedux.lastName}
              userEmail={lawyerRegisterRedux.userEmail}
              roleEmail={lawyerRegisterRedux.roleEmail}
              hashedEmail={hashedEmail}
              handleBack={handleBack}
              returnToRegister={returnToRegister}
            />
          </>
        );
      case 3:
        return (
          <>
            <VerifyLawyerOnRegister
              handleNext={handleNext}
              handleBack={handleBack}
              returnToRegister={returnToRegister}
              email={lawyerRegisterRedux.email}
              temporaryEmail={temporaryEmail}
            />
          </>
        );

      case 4:
        return (
          <>
            <NewLawyerEmail handleNext={handleNext} hashEmail={hashEmail} />
          </>
        );

      case 5:
        return (
          <>
            <SetLawyerAdressDetailsOnRegister
              handleNext={() => handleNext(6)}
              lawyerRegisterRedux={lawyerRegisterRedux}
            />
          </>
        );

      case 6:
        return (
          <>
            <SubscriptionStep
              currentStep={currentStep}
              handleNext={handleNext}
              firstName={lawyerRegisterRedux.firsName}
              lastName={lawyerRegisterRedux.lastName}
              roleEmail={lawyerRegisterRedux.roleEmail}
              userEmail={lawyerRegisterRedux.userEmail}
            />
          </>
        );
      case 7:
        return (
          <>
            <SetLawyerPasswordOnRegister
              lawyerRegisterRedux={lawyerRegisterRedux}
              handleNext={handleNext}
              firstName={lawyerRegisterRedux.firstName}
              lastName={lawyerRegisterRedux.lastName}
              roleEmail={lawyerRegisterRedux.roleEmail}
              userEmail={lawyerRegisterRedux.userEmail}
              handleBack={() => handleBack(4)}
              handleBackOneStep={() => handleBack(6)}
              handleChangePassword={(pass) => handleChangePassword(pass)}
            />
          </>
        );
      case 8:
        return (
          <>
            <CreateLawyerAccount
              lawyerRegisterRedux={lawyerRegisterRedux}
              name={
                lawyerRegisterRedux.firstName +
                ' ' +
                lawyerRegisterRedux.lastName
              }
              email={lawyerRegisterRedux.userEmail}
              passwordOnRegister={passwordOnRegister}
              handleNext={handleNext}
            />
          </>
        );
      default:
        return 'unknown step';
    }
  };

  return (
    <>
      <div
        style={
          currentStep === 7
            ? {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }
            : {}
        }
        className={styles.verifyLawyerContainer}
      >
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={
              snackbarSeverity === 'error' && {
                backgroundColor: '#7f56d9 !important',
              }
            }
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <div
          style={
            currentStep === 7
              ? {
                flexDirection: 'column',
              }
              : {}
          }
          className={styles.verifyLawyerSection}
        >
          <div
            className={
              currentStep === 7
                ? styles.topSectionSubscription
                : styles.topSection
            }
          >
            <StepNavigation
              role="lawyer"
              labelArray={registerSteps}
              currentStep={currentStep}
              updateStep={updateStep}
            />
          </div>
          <div
            style={currentStep === 7 ? { display: 'none' } : {}}
            className={styles.verifyLeftSection}
          >
            <div className={styles.verifyLeftSectionContainer}>
              <img className={styles.verifyLogo} src={logo} alt="logo" />

              <div className={styles.content}>
                <StepNavigation
                  role="lawyer"
                  labelArray={registerSteps}
                  currentStep={currentStep}
                  updateStep={updateStep}
                />
              </div>
            </div>

            <div className={styles.verifyFooter}></div>
          </div>

          {currentStep === registerSteps.length + 1 ? (
            <CreateLawyerAccount
              name={firstName + ' ' + lastName}
              email={email}
              passwordOnRegister={passwordOnRegister}
            />
          ) : (
            <>{getStepContent(currentStep - 1)}</>
          )}
        </div>
      </div>
    </>
  );
};

export default RegisterLawyerProcess;
