import React, { useState, useEffect } from 'react';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useMutation, useQuery } from '@apollo/client';

import AddLawyerReviewBox from 'Components/Lawyer/AddLawyerReviewBox';
import AddLawyerReviewNotLoggedNameBox from 'Components/Modals/AddLawyerReviewNotLoggedNameBox';
import { useSelector } from 'react-redux';
import {
  ADD_REVIEW,
  GET_REVIEW_OF_CLIENT_FOR_LAWYER,
} from 'Services/Queries/client';
import { AreasOfLaw_Color } from 'Services/Utils/areaOfLaws';

import 'Assets/styles/lawyer/AddLawyerReviewModal.scss';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

export default function AddLawyerReviewModal(props) {
  const [reviewTitle, setReviewTitle] = useState('');
  const [reviewText, setReviewText] = useState('');
  const [rating, setRating] = useState(0);
  const [averageRating, setAverageRating] = useState(props.averageNr);
  const [reviewsNumber, setReviewsNumber] = useState(props.reviewsNr);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const [articleImage, setArticleImage] = useState(null);
  const [shownModal, setShownModal] = useState(0);

  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const clientReview = useQuery(GET_REVIEW_OF_CLIENT_FOR_LAWYER, {
    variables: {
      client_id: parseInt(clientInfoRedux.role_id),
      lawyer_id: parseInt(props.lawyer.id),
    },
  });

  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const handleInputChange = (inputName, value) => {
    switch (inputName) {
      case 'firstName':
        setFormValues({ ...formValues, firstName: value });
        break;
      case 'lastName':
        setFormValues({ ...formValues, lastName: value });
        break;
      case 'email':
        setFormValues({ ...formValues, email: value });
        break;

      default:
        break;
    }
  };

  const resetState = () => {
    setReviewTitle('');
    setReviewText('');
    setRating(0);
    setTermsAccepted(false);
  };

  const handleClose = () => {
    resetState();
    setShownModal(0);
  };

  const changeModal = () => {
    setShownModal(1);
  };

  const handleCloseModal = (modalNr) => {
    setShownModal(modalNr);
  };

  const handleRating = (rating) => {
    setRating(rating);
  };

  useEffect(() => {
    if (props.lawyer.profileImage) {
      setArticleImage(props.lawyer.profileImage);
    } else {
      setArticleImage(null);
    }
    return () => {
      setArticleImage(null);
    };
  }, [props.lawyer]);

  useEffect(() => {
    if (clientReview.data !== undefined && clientReview.data !== null) {
      const reviewData = clientReview.data.getReviewOfClientForLawyer;
      setReviewTitle(reviewData.title);
      setReviewText(reviewData.content);
      setRating(Number(reviewData.rating));
    }
    return () => {
      setReviewTitle('');
      setReviewText('');
      setRating(0);
    };
  }, [clientReview.data]);
  const [addReview, { data: addReviewData }] = useMutation(ADD_REVIEW);

  const AreaOfLawTagJSX = (value, key) => {
    return (
      <div
        className="lawp-add-lawyer-review-modal-area-of-law-tag-container"
        style={{
          color: AreasOfLaw_Color[value]?.color || 'black',
          backgroundColor: AreasOfLaw_Color[value]?.background || 'white',
        }}
        key={key}
      >
        <h3 className="lawp-add-lawyer-review-modal-area-of-law-tag-text">
          {value}
        </h3>
      </div>
    );
  };

  const submitHandler = async () => {
    if (termsAccepted) {
      if (!clientInfoRedux.role) {
        changeModal();
      } else {
        await addReview({
          variables: {
            input: {
              client_id: parseInt(clientInfoRedux.role_id),
              lawyer_id: parseInt(props.lawyer.id),
              title: reviewTitle.trim(),
              content: reviewText.trim(),
              rating: rating,
              client_name: clientInfoRedux.name,
              lawyer_name: `${props.lawyer.first_name} ${props.lawyer.last_name}`,
              lawyer_email: props.lawyer.email,
            },
          },
        });

        if (props.onComplete) props.onComplete();
      }
    } else {
      setErrorMessage(LPTranslate('Common_Terms_And_Conditions_Must_Accept'));
    }
  };

  useEffect(() => {
    if (addReviewData && addReviewData.addReview !== null) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'client-add-review-custom-event',
      });
      props.setReviewsNr(addReviewData.addReview.count);
      props.setAverageNr(addReviewData.addReview.averageRatingValue);

      //used to update average rating and reviews number from left container of the modal
      setAverageRating(addReviewData.addReview.averageRatingValue);
      setReviewsNumber(addReviewData.addReview.count);

      handleCloseModal(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addReviewData]);

  return (
    <div>
      <Modal open={props.open} onClose={handleClose}>
        <Box>
          {shownModal === 0 ? (
            <AddLawyerReviewBox
              clientId={clientInfoRedux.role_id}
              resetState={resetState}
              handleClose={props.handleClose}
              articleImage={articleImage}
              lawyerNameTitle={props.lawyer.title}
              firstname={props.lawyer.first_name}
              lastname={props.lawyer.last_name}
              lawyerNameSuffixTitle={props.lawyer.title_suffix}
              rating={props.averageNr}
              newRating={rating}
              reviewsNr={props.reviewsNr}
              short_description={props.lawyer.short_description}
              specialities={props.lawyer.specialities}
              areasOfLaw={props.lawyer.areasOfLaw}
              AreaOfLawTagJSX={AreaOfLawTagJSX}
              setReviewTitle={setReviewTitle}
              reviewTitle={reviewTitle}
              handleRating={handleRating}
              setReviewText={setReviewText}
              reviewText={reviewText}
              termsAccepted={termsAccepted}
              submitHandler={submitHandler}
              setTermsAccepted={setTermsAccepted}
              setReviewsNr={props.setReviewsNr}
              setAverageNr={props.setAverageNr}
              errorMessage={errorMessage}
            />
          ) : (
            <AddLawyerReviewNotLoggedNameBox
              resetState={resetState}
              handleClose={props.handleClose}
              articleImage={articleImage}
              lawyerNameTitle={props.lawyer.title}
              firstname={props.lawyer.first_name}
              lastname={props.lawyer.last_name}
              lawyer_email={props.lawyer.email}
              lawyerNameSuffixTitle={props.lawyer.title_suffix}
              rating={props.lawyer.rating}
              newRating={rating}
              averageRating={averageRating}
              reviewsNumber={reviewsNumber}
              specialities={props.lawyer.specialities}
              reviewsNr={props.reviewsNr}
              short_description={props.lawyer.short_description}
              areasOfLaw={props.lawyer.areasOfLaw}
              AreaOfLawTagJSX={AreaOfLawTagJSX}
              handleCloseModal={handleCloseModal}
              setReviewTitle={setReviewTitle}
              setReviewText={setReviewText}
              setTermsAccepted={setTermsAccepted}
              setRating={setRating}
              setShownModal={setShownModal}
              reviewTitle={reviewTitle}
              reviewText={reviewText}
              termsAccepted={termsAccepted}
              submitHandler={submitHandler}
              lawyer_id={props.lawyer.id}
              shownModal={shownModal}
              onReloadChanges={props.onReloadChanges}
              formValues={formValues}
              formErrors={formErrors}
              handleInputChange={handleInputChange}
              setFormValues={setFormValues}
              setFormErrors={setFormErrors}
              ratingData={props.ratingData}
              setRatingData={props.setRatingData}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
}
