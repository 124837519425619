import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';

import SimpleTextField from 'Components/Inputs/SimpleTextField';

import { UPDATE_USER_PASSWORD } from 'Services/Queries/common';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { validatePassword } from 'Services/Utils/validations';

import check from 'Assets/pictures/stepperCheck.svg';
import styles from 'Assets/styles/updateLawyerDetails/SetLawyerNewPasswordModal.module.scss';

import UpdatePasswordConfirmationModal from './UpdatePasswordConfirmationModal';

const SetLawyerNewPasswordModal = (props) => {
  const { shownModal, errorMessage } = props;
  const { handleClose, handleChangeModal } = props;

  const [updateUserPassword, { data: updateUserPasswordData }] =
    useMutation(UPDATE_USER_PASSWORD);
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const [formValues, setFormValues] = useState({
    password: '',
    repeatPassword: '',
  });
  const [formErrors, setFormErrors] = useState({
    password: '',
    repeatPassword: '',
  });

  const resetState = () => {
    setFormValues({
      password: '',
      repeatPassword: '',
    });
    setFormErrors({
      password: '',
      repeatPassword: '',
    });
  };

  const handleInputChange = (inputName, value) => {
    switch (inputName) {
      case 'password':
        setFormValues({ ...formValues, password: value });
        break;
      case 'repeatPassword':
        setFormValues({ ...formValues, repeatPassword: value });
        break;

      default:
        break;
    }
  };

  const updatePassword = async (e) => {
    e.preventDefault();

    let isError = false;
    let newInputErrors = { password: '', repeatPassword: '' };

    if (formValues.password.length === 0) {
      isError = true;
      newInputErrors.password = LPTranslate('Error_Password_Required');
    } else if (formValues.password.length < 8) {
      isError = true;
      newInputErrors.password = LPTranslate('Error_Password_Min_Length');
    }

    if (!validatePassword(formValues.password)) {
      isError = true;
      newInputErrors.password = LPTranslate(
        'Error_Password_Invalid_Requirements'
      );
    }

    if (formValues.repeatPassword.length === 0) {
      isError = true;
      newInputErrors.repeatPassword = LPTranslate(
        'Error_Confirmation_Password_Required'
      );
    } else if (formValues.password !== formValues.repeatPassword) {
      isError = true;
      newInputErrors.repeatPassword = LPTranslate('Error_Password_Must_Match');
    }

    setFormErrors(newInputErrors);

    if (isError) return;

    try {
      await updateUserPassword({
        variables: {
          email: clientInfoRedux.email,
          password: formValues.password,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (
      updateUserPasswordData &&
      updateUserPasswordData.changeUserPassword.error.length > 0
    ) {
    } else if (
      updateUserPasswordData &&
      updateUserPasswordData.changeUserPassword.message.length > 0
    ) {
      resetState();
      handleChangeModal(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, updateUserPasswordData]);

  return (
    <>
      {shownModal === 1 ? (
        <div className={styles['update-password-box']}>
          <div className={styles['modal-container']}>
            <div className={styles['stepper-content']}>
              <div className={styles['check-step-icon-base']}>
                <div className={styles['checked-icon-content']}>
                  <img
                    className={styles['check-icon']}
                    src={check}
                    alt="checked"
                  />
                </div>
              </div>
              <div className={styles['unselected-line']}></div>
              <div className={styles['step-icon-base']}>
                <div className={styles['selected-icon-content']}>
                  <div className={styles['selected-dot']} />
                </div>
              </div>
            </div>

            <form
              className={styles['modal-content']}
              onSubmit={updatePassword}
              id="set-new-password-form"
            >
              <div className={styles['modal-text-and-supporting-text']}>
                <p className={styles['modal-text']}>
                  {LPTranslate('CDPM_Step_Title')}
                </p>
                <p className={styles['modal-supporting-text']}>
                  {LPTranslate('CDPM_Step_Description_2')}
                </p>
              </div>

              <div className={styles['frame1030-input-field']}>
                <SimpleTextField
                  data_cy="password-input"
                  title={LPTranslate('CDPM_Step_2_New_Password_Input_Title')}
                  placeholder="••••••••"
                  onChange={(value) => {
                    handleInputChange('password', value);
                    setFormErrors({ ...formErrors, password: '' });
                  }}
                  value={formValues.password}
                  textFieldWidthPercent={100}
                  type="password"
                  errorMessage={formErrors.password}
                />
              </div>

              <div className={styles['frame1030-verify-input-field']}>
                <SimpleTextField
                  data_cy="repeat-password-input"
                  title={LPTranslate(
                    'CDPM_Step_2_Confirm_Password_Input_Title'
                  )}
                  placeholder="••••••••"
                  onChange={(value) => {
                    handleInputChange('repeatPassword', value);
                    setFormErrors({ ...formErrors, repeatPassword: '' });
                  }}
                  value={formValues.repeatPassword}
                  textFieldWidthPercent={100}
                  type="password"
                  errorMessage={formErrors.repeatPassword}
                />

                {errorMessage && (
                  <p className={styles['error-message']}>{errorMessage}</p>
                )}
              </div>
            </form>

            <div className={styles['actions']}>
              <div className={styles['cancel-button']}>
                <button
                  data-cy="cancel-button"
                  className={styles['cancel-button-base']}
                  onClick={handleClose}
                >
                  <p className={styles['cancel-button-text']}>
                    {LPTranslate('Button_Cancel')}
                  </p>
                </button>
              </div>

              <div className={styles['next-button']}>
                <button
                  data-cy="submit-button"
                  className={styles['next-button-base']}
                  type="submit"
                  form="set-new-password-form"
                >
                  <p className={styles['next-button-text']}>
                    {LPTranslate('Button_Next')}
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <UpdatePasswordConfirmationModal
          handleClose={() => handleClose()}
          titleMessage={LPTranslate(
            'CDPM_Step_3_Confirm_Password_Title_Message'
          )}
          hintMessage={LPTranslate('CDPM_Step_3_Confirm_Password_Hint_Message')}
          buttonText={LPTranslate('CDPM_Step_3_Confirm_Password_Button')}
        />
      )}
    </>
  );
};

export default SetLawyerNewPasswordModal;
