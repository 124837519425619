import styles from 'Assets/styles/lawyer/DeleteComponentsStyle/DeleteComponentSecondState.module.scss';
import purpleCheckMark from 'Assets/pictures/purpleCheckMark.svg';

const DeleteAccountSecondCopmonent = (props) => {
  const { deleteTitle, deleteDetails, benefits1, benefits2, benefits3 } = props;
  return (
    <>
      <div className={styles['second-state']}>
        <p className={styles['text']}>{deleteTitle}</p>
        <div className={styles['second-state-title']}>
          <div className={styles['no-delete-title']}>
            <div className={styles['delete-text-subtitle']}>
              {deleteDetails}
            </div>
          </div>
        </div>

        <div className={styles['container-benefits']}>
          <div className={styles['row-details']}>
            <div className={styles['purple-circle']}>
              <div className={styles['check-mark']}>
                <img
                  className="check-mark-image"
                  src={purpleCheckMark}
                  alt="Purple Check Mark"
                />
              </div>
            </div>

            <div className={styles['check-mark-text']}>{benefits1}</div>
          </div>
          <div className={styles['delete-lawyer-row-details']}>
            <div className={styles['purple-circle']}>
              <div className={styles['check-mark']}>
                <img
                  className="check-mark-image"
                  src={purpleCheckMark}
                  alt="Purple Check Mark"
                />
              </div>
            </div>

            <div className={styles['check-mark-text']}>{benefits2}</div>
          </div>
          <div className={styles['row-details']}>
            <div className={styles['purple-circle']}>
              <div className={styles['check-mark']}>
                <img
                  className="check-mark-image"
                  src={purpleCheckMark}
                  alt="Purple Check Mark"
                />
              </div>
            </div>

            <div className={styles['check-mark-text']}>{benefits3}</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DeleteAccountSecondCopmonent;
