import React from 'react';
import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import ArticlesView from 'Components/Lawyer/ArticlesView';
import ReviewsView from 'Components/Lawyer/ReviewsView';
import ReviewsSection from 'Components/Lawyer/ReviewsSection';
import LawyerProfileCard from 'Components/Lawyer/LawyerProfileCard';
import DescriptionLawyer from 'Components/Lawyer/DescriptionLawyer';
import LawyerDetailsCard from 'Components/Lawyer/LawyerDetailsCard';
import LawyerInfoCard from 'Components/Lawyer/LawyerInfoCard';
import LawyerPageArticleSection from 'Components/Lawyer/LawyerPageArticleSection';
import ContactLawyerCommponent from 'Components/Lawyer/ContactLawyerComponent';
import FaqComponent from 'Components/CommonComponents/FaqComponent';
import MetaDecorator from 'Layouts/MetaDecorator';

import backgroundGradient from 'Assets/pictures/backgroundGradient.png';

import { createMetaDataTitle } from 'Services/Utils/metaData';

import { GET_LAWYER } from 'Services/Queries/lawyer';
import { GET_LAWYER_AVAILABILITY } from 'Services/Queries/client';
import { GET_PROFILE_PICTURE } from 'Services/Queries/lawyer';

import 'Assets/styles/lawyer/LawyerPage.scss';

const LawyerPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [content, setContent] = useState('overview');

  const [ratingData, setRatingData] = useState({
    reviewTitle: '',
    reviewText: '',
    rating: '',
  });

  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const params = useParams();
  const slug = params.lawyerSlug.split('-');
  let id = slug[slug.length - 1];

  const parseLawyerId = parseInt(id);
  const parseClientId = parseInt(clientInfoRedux.user_id);
  const LawyerResponse = useQuery(GET_LAWYER, {
    variables: { id: id },
  });

  const [reviewsNr, setReviewsNr] = useState(0);
  const [averageNr, setAverageNr] = useState(0);
  const [isPageLoadedFirstTime, setIsPageLoadedFirstTime] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('LawyerPageOpenArticleView') === 'true') {
      localStorage.setItem('LawyerPageOpenArticleView', 'false');

      let timeBack = localStorage.getItem('LawyerPageOpenArticleViewTimeBack');

      if (timeBack) {
        if (Date.now() - timeBack < 2000) {
          setContent('articles');
        }
      }
    }

    if (location.state && location.state.ArticleViewOpen) {
      setContent('articles');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (LawyerResponse && LawyerResponse.data && isPageLoadedFirstTime) {
      if (LawyerResponse.data.lawyer.status === 'error') {
        navigate('/*');
      } else {
        setReviewsNr(LawyerResponse.data.lawyer.review_count);
        setAverageNr(LawyerResponse.data.lawyer.rating);

        setIsPageLoadedFirstTime(false);
      }
    }
  }, [LawyerResponse, isPageLoadedFirstTime, navigate]);

  const LawyeAvailability = useQuery(GET_LAWYER_AVAILABILITY, {
    variables: { lawyer_id: id },
  });

  let availability = LawyeAvailability.data?.getLawyerAvailability;

  const profilePictureResponse = useQuery(GET_PROFILE_PICTURE, {
    variables: { id: id, image_type: 'profile picture' },
  });

  if (LawyerResponse.loading || profilePictureResponse.loading) {
    return (
      <>
        <div className="loading-overlay">
          <CircularProgress color="secondary" />
        </div>
      </>
    );
  }

  if (
    LawyerResponse.error ||
    profilePictureResponse.error ||
    LawyerResponse.data.lawyer.status === 'error'
  ) {
    return <p>error...</p>;
  }

  let lawyer = LawyerResponse.data?.lawyer;

  let image = profilePictureResponse.data.getProfilePicture?.image_data;

  const changeContent = (newContent) => {
    setContent(newContent);
  };

  const LawyerShortDescriptionJSX = (lawyer) => {
    const language = localStorage.getItem('userLanguage');
    const lawyerNameTitle =
      lawyer.title + ' ' + lawyer.first_name + ' ' + lawyer.last_name;

    let AreasOfLawList = lawyer.areasOfLaw
      .map((value, key) => {
        return value;
      })
      .join(', ');

    if (language === 'en') {
      if (lawyer.areasOfLaw.length > 0) {
        AreasOfLawList = AreasOfLawList.replace(/,(?=[^,]*$)/, ' and');
      }
    } else if (language === 'de') {
      if (lawyer.areasOfLaw.length > 0) {
        AreasOfLawList = AreasOfLawList.replace(/,(?=[^,]*$)/, ' und');
      }
    }

    let SpecialitiesList = lawyer.specialities
      .map((value, key) => {
        return value;
      })
      .join(', ');

    if (language === 'en') {
      if (lawyer.specialities.length > 0) {
        SpecialitiesList = SpecialitiesList.replace(/,(?=[^,]*$)/, ' and');
      }
    } else if (language === 'de') {
      if (lawyer.specialities.length > 0) {
        SpecialitiesList = SpecialitiesList.replace(/,(?=[^,]*$)/, ' und');
      }
    }

    let returnString = '';

    if (!lawyer.short_description) {
      if (lawyer.specialities.length === 0 && lawyer.areasOfLaw.length > 0) {
        if (lawyer.salutation === 'Herr') {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is a lawyer listed on Anwado who practices in the fields ${AreasOfLawList}.`;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist ein auf Anwado gelisteter Rechtsanwalt, der in den Bereichen ${AreasOfLawList} tätig ist.`;
        } else if (lawyer.salutation === 'Frau') {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is a lawyer listed on Anwado practicing in the fields of ${AreasOfLawList}.`;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist eine auf Anwado gelistete Rechtsanwältin, die in den Bereichen ${AreasOfLawList} tätig ist.`;
        } else {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is a lawyer listed on Anwado and practicing in the fields of ${AreasOfLawList}.`;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist als Rechtsanwalt*in auf Anwado gelistete und in den Bereichen ${AreasOfLawList} tätig.`;
        }
      } else if (
        lawyer.specialities.length === 0 &&
        lawyer.areasOfLaw.length === 0
      ) {
        if (lawyer.salutation === 'Herr') {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is a lawyer listed on Anwado.`;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist ein auf Anwado gelisteter Rechtsanwalt.`;
        } else if (lawyer.salutation === 'Frau') {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is a female lawyer listed on Anwado.`;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist eine auf Anwado gelistete Rechtsanwältin`;
        } else {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is a lawyer listed on Anwado.`;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist als Rechtsanwalt*in auf Anwado gelistete.`;
        }
      } else if (
        lawyer.specialities.length > 0 &&
        lawyer.areasOfLaw.length > 0
      ) {
        if (lawyer.salutation === 'Herr') {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is a specialist lawyer listed on Anwado for ${SpecialitiesList} practicing in ${AreasOfLawList}.`;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist ein auf Anwado gelisteter Fachanwalt für ${SpecialitiesList}, der in den Bereichen ${AreasOfLawList} tätig ist.`;
        } else if (lawyer.salutation === 'Frau') {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is a specialist lawyer listed on Anwado for ${SpecialitiesList} practicing in the fields of ${AreasOfLawList}.`;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist eine auf Anwado gelistete Fachanwältin für ${SpecialitiesList}, die in den Bereichen ${AreasOfLawList} tätig ist.`;
        } else {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is a specialist lawyer for ${SpecialitiesList} listed on Anwado and practicing in the fields of ${AreasOfLawList}.`;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist als Fachanwalt*in für ${SpecialitiesList} auf Anwado gelistete und in den Bereichen ${AreasOfLawList} tätig.`;
        }
      } else if (
        lawyer.specialities.length > 0 &&
        lawyer.areasOfLaw.length === 0
      ) {
        if (lawyer.salutation === 'Herr') {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is a specialist lawyer listed on Anwado for ${SpecialitiesList}.`;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist ein auf Anwado gelisteter Fachanwalt für ${SpecialitiesList}.`;
        } else if (lawyer.salutation === 'Frau') {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is a specialist lawyer listed on Anwado for ${SpecialitiesList}. `;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist eine auf Anwado gelistete Fachanwältin für ${SpecialitiesList}.`;
        } else {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is listed on Anwado as a specialist lawyer for ${SpecialitiesList}.`;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist als Fachanwalt*in für ${SpecialitiesList} auf Anwado gelistete.`;
        }
      }
    }

    if (returnString) {
      if (language === 'en') {
        return returnString + ' This entry is provided by anwado.de.';
      } else if (language === 'de') {
        return (
          returnString +
          ' Bei diesem Eintrag handelt es sich um eine von anwado.de bereitgestellte Information.'
        );
      }
    }

    return lawyer.short_description;
  };

  return (
    <>
      <MetaDecorator
        title={createMetaDataTitle(
          lawyer.salutation,
          '',
          lawyer.first_name,
          lawyer.last_name
        )}
        description={
          lawyer.self_description
            ? lawyer.self_description
            : LawyerShortDescriptionJSX(lawyer)
        }
        canonical={'canonical'}
        link={`${process.env.REACT_APP_DNS_URI}/anwaelte/${lawyer.first_name}-${lawyer.last_name}-${lawyer.id}`}
      />
      {/* <Helmet>
        <title>{lawyer.first_name + ' ' + lawyer.last_name}</title>
        <meta
          name="description"
          content={
            lawyer.first_name +
            ' ' +
            lawyer.last_name +
            '-' +
            metadataLawyer('speciality') +
            '-' +
            metadataLawyer('areaOfLaw')
          }
        />
        <link
          rel="canonical"
          href={
            '/anwaelte/' +
            lawyer.first_name.replace(/ /g, '_') +
            '+' +
            lawyer.last_name.replace(/ /g, '_')
          }
        />
      </Helmet> */}

      <div className="lawp-lawyer-page-container">
        <img
          alt="background"
          src={backgroundGradient}
          className="lawp-lawyer-gradient-image"
        />

        <LawyerProfileCard
          id={id}
          profile_picture={image}
          availability={availability}
          averageNr={averageNr}
          lawyer={lawyer}
          clientInfoRedux={clientInfoRedux}
          reviewsNr={reviewsNr}
          user_id={parseClientId}
          lawyer_id={parseLawyerId}
        />
      </div>

      {content === 'overview' && (
        <>
          <LawyerInfoCard
            id={id}
            lawyer={lawyer}
            clientInfoRedux={clientInfoRedux}
            lawyer_id={id}
            profile_picture={image}
            reviewsNr={reviewsNr}
            averageNr={averageNr}
            availability={availability}
            ratingData={ratingData}
            setRatingData={setRatingData}
            setReviewsNr={setReviewsNr}
            setAverageNr={setAverageNr}
          />

          <DescriptionLawyer lawyer={lawyer} profile_picture={image} />

          <LawyerDetailsCard lawyer={lawyer} />

          <ContactLawyerCommponent
            country={lawyer.country}
            street={lawyer.street}
            lawfirm_name={lawyer.lawfirm_name}
            house_number={lawyer.house_number}
            postcode={lawyer.postcode}
            location={lawyer.location}
            website={lawyer.website}
            telephone={lawyer.telephone}
            email={lawyer.email}
            emailalt={lawyer.emailalt}
            lawyer={lawyer}
            client={clientInfoRedux}
          />

          <LawyerPageArticleSection
            lawyer={lawyer}
            changeContent={changeContent}
            hideIfNoArticles={true}
            previousPageForArticleCards={'LawyerPage'}
          />

          <ReviewsSection
            lawyer={lawyer}
            changeContent={changeContent}
            hideIfNoReviews={true}
            ratingData={ratingData}
          />

          <FaqComponent
            lawyer={lawyer}
            clientInfoRedux={clientInfoRedux}
            reviewsNr={reviewsNr}
            averageNr={averageNr}
            availability={availability}
            profile_picture={image}
          />
        </>
      )}

      {content === 'reviews' && (
        <>
          <ReviewsView
            lawyer={lawyer}
            changeContent={changeContent}
            ratingData={ratingData}
          />
        </>
      )}
      {content === 'articles' && (
        <>
          <ArticlesView lawyer={lawyer} changeContent={changeContent} />
        </>
      )}
    </>
  );
};

export default LawyerPage;
