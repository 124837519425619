import * as React from 'react';
import { styled } from '@mui/material/styles';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginBottom: theme.spacing(5),
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      props.expanded === 0 ? (
        <AddCircleOutlineIcon
          sx={{ color: '#7F56D9', fontSize: '1.5rem', marginRight: '15px' }}
        />
      ) : (
        <RemoveCircleOutlineIcon sx={{ fontSize: '1.5rem' }} />
      )
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'white',
  flexDirection: 'row-reverse',

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
    marginRight: '15px',
    color: '#7F56D9',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiTypography-body1': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '28px',
    color: '#101828',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  whiteSpace: 'pre-line',
  marginLeft: '3.0rem',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#667085',
}));

export default function FAQExpandableSection(props) {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {props.questions.map((question, index) => {
        return (
          <Accordion
            key={index}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
          >
            <AccordionSummary
              aria-controls={`panel${index + 1}d-content`}
              id={`panel${index + 1}d-header`}
              expanded={expanded === `panel${index + 1}` ? 1 : 0}
            >
              <h2
                style={{
                  fontSize: 18,
                  fontWeight: 500,
                  lineHeight: '28px',
                  color: '#101828',
                  fontStyle: 'normal',
                }}
              >
                {question.question}
              </h2>
            </AccordionSummary>
            <AccordionDetails sx={{ whiteSpace: 'pre-line' }}>
              <h3
                style={{
                  fontSize: 16,
                  color: '#667085',
                  fontWeight: 400,
                  lineHeight: 1.5,
                  letterSpacing: '0.00938em',
                }}
              >
                {question.answer}
                &nbsp;
                {question.answer_link && (
                  <a
                    href={
                      question.answer_link === 'price list.' ||
                      question.answer_link === 'Preisliste.'
                        ? '/preise'
                        : [
                            question.answer_link === 'privacy policy.' ||
                            question.answer_link === 'Datenschutzerklärung.'
                              ? '/datenschutz-cookies'
                              : '/bewertungsrichtlinien',
                          ]
                    }
                    style={{
                      fontSize: 16,
                      color: '#667085',
                      fontWeight: 400,
                      lineHeight: 1.5,
                      letterSpacing: '0.00938em',
                    }}
                  >
                    {question.answer_link}
                  </a>
                )}
              </h3>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
