import { createContext, useState } from 'react';

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const severity = 'error';

  const setAlert = (text) => {
    setMessage(text);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <AlertContext.Provider
      value={{
        message,
        open,
        severity,
        handleClose,
        setAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
