import translations from './languages/translations_v2';

const setUserLanguageFromLocalStorage = () => {
  if (!localStorage.getItem('userLanguage')) {
    const browserLanguage = navigator.language || navigator.userLanguage;

    if (browserLanguage === 'de-DE' || browserLanguage === 'de') {
      localStorage.setItem('userLanguage', 'de');
    } else {
      let timeZoneCityToCountry = {
        Berlin: 'Germany',
        Busingen: 'Germany',
      };

      // let userRegion;
      let userCity;
      let userCountry;
      let userTimeZone;

      if (Intl) {
        userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var tzArr = userTimeZone.split('/');
        // userRegion = tzArr[0];
        userCity = tzArr[tzArr.length - 1];
        userCountry = timeZoneCityToCountry[userCity];
      }
      localStorage.setItem('userLanguage', 'de');
      // if (userCountry === 'Germany') {
      //   localStorage.setItem('userLanguage', 'de');
      // } else {
      //   localStorage.setItem('userLanguage', 'en');
      // }
    }
  }

  return localStorage.getItem('userLanguage');
};

const LPTranslate = (key) => {
  const language = setUserLanguageFromLocalStorage();

  try {
    if (language !== null) return translations[key][language];

    return translations[key]['de'];
  } catch (e) {
    console.log(e);
  }
};

const LPTranslateWithLanguage = (key, language) => {
  try {
    if (language !== null) return translations[key][language];

    return translations[key]['de'];
  } catch (e) {
    console.log(e);
  }
};

export { LPTranslate, LPTranslateWithLanguage };
