import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/updateLawyerDetails/FinishUpdateLawyerDetails.module.scss';
import check from 'Assets/pictures/checkCircle.svg';

const FinishUpdateLawyerDetails = (props) => {
  return (
    <>
      <div className={styles['container']}>
        <div className={styles['content']}>
          <div className={styles['header']}>
            <div className={styles['header']}>
              <div className={styles['featured-icon']}>
                <div className={styles['check-circle']}>
                  <img src={check} alt="key" />
                </div>
              </div>

              <div className={styles['text-and-supporting-text']}>
                <p className={styles['text']}>{LPTranslate('CUEM_Title')}</p>

                <p className={styles['supporting-text']}>
                  {LPTranslate('CUEM_Description')}
                </p>
              </div>
            </div>
          </div>

          <div className={styles['done-button']}>
            <button
              className={styles['done-button-base']}
              // onClick={() => navigate('/updateClientCredentials')}
              onClick={() =>
                (window.location.href = '/updateClientCredentials')
              }
            >
              <p className={styles['done-button-text']}>
                {LPTranslate('Button_Done')}
              </p>
            </button>
          </div>

          <div className={styles['home-button']}>
            <button
              className={styles['home-button-base']}
              // onClick={() => navigate('/')}
              onClick={() => (window.location.href = '/')}
            >
              <ArrowBackIcon sx={{ color: '#667085' }} />
              <p className={styles['home-button-text']}>
                {LPTranslate('Button_Back_To_Homepage')}
              </p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinishUpdateLawyerDetails;
