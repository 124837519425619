import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: {
    remember: false,
    token: '',
    subscription: 0,
    role: '',
    user_id: 0,
    role_id: 0,
    name: '',
    last_name: '',
    first_name: '',
    title: '',
    email: '',
    dropdownEvent: false,
    isProfileImageUpdated: false,
  },
};

const clientInfoSlice = createSlice({
  name: 'clientInfo',
  initialState: initialState,
  reducers: {
    addAll: (state, action) => {
      if (action.payload) {
        let info = {
          token: action.payload.token,
          role: action.payload.role,
          email: action.payload.email,
          name: action.payload.name,
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          title: action.payload.title,
          user_id: action.payload.user_id,
          role_id: action.payload.role_id,
        };

        state.value = { ...state.value, ...info };
      }
    },
    changeRemember: (state, action) => {
      if (action.payload) {
        state.value = { ...state.value, remember: action.payload };
      }
    },
    addToken: (state, action) => {
      if (action.payload) {
        state.value = { ...state.value, token: action.payload };
      }
    },
    addSubscription: (state, action) => {
      if (action.payload) {
        state.value = { ...state.value, subscription: action.payload };
      }
    },
    addRole: (state, action) => {
      if (action.payload) {
        state.value = { ...state.value, role: action.payload };
      }
    },
    addName: (state, action) => {
      if (action.payload) {
        state.value = { ...state.value, name: action.payload };
      }
    },
    addLastName: (state, action) => {
      if (action.payload) {
        state.value = { ...state.value, last_name: action.payload };
      }
    },
    addFirstName: (state, action) => {
      if (action.payload) {
        state.value = { ...state.value, first_name: action.payload };
      }
    },
    addTitle: (state, action) => {
      if (action.payload) {
        state.value = { ...state.value, title: action.payload };
      }
    },
    addUser_Id: (state, action) => {
      if (action.payload) {
        state.value = { ...state.value, user_id: action.payload };
      }
    },
    addRole_Id: (state, action) => {
      if (action.payload) {
        state.value = { ...state.value, role_id: action.payload };
      }
    },
    addEmail: (state, action) => {
      if (action.payload) {
        state.value = { ...state.value, email: action.payload };
      }
    },
    changeDropdownEvent: (state, action) => {
      if (action.payload) {
        state.value = {
          ...state.value,
          dropdownEvent: !state.value.dropdownEvent,
        };
      }
    },
    isProfileImageUpdated: (state, action) => {
      if (action.payload) {
        state.value = {
          ...state.value,
          isProfileImageUpdated: action.payload,
        };
      }
    },
    resetClientInfo: (state) => {
      state.value = {
        remember: false,
        token: '',
        role: '',
        user_id: 0,
        role_id: 0,
        name: '',
        last_name: '',
        first_name: '',
        dropdownEvent: false,
        isProfileImageUpdated: false,
      };
    },
  },
});
export const {
  addAll,
  changeRemember,
  addToken,
  addSubscription,
  addRole,
  addName,
  addLastName,
  addFirstName,
  addTitle,
  addUser_Id,
  addRole_Id,
  addEmail,
  changeDropdownEvent,
  isProfileImageUpdated,
  resetClientInfo,
} = clientInfoSlice.actions;
export default clientInfoSlice.reducer;
