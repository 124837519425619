import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { resetClientInfo } from 'Services/Redux/reducers/clientInfo';
import { removeTokenInfo } from 'Services/Utils/token';

import check from 'Assets/pictures/checkCircle.svg';

import styles from 'Assets/styles/lawyer/DeleteComponentsStyle/DeleteModal.module.scss';

const DeleteModal = (props) => {
  const {
    userInfo,
    deleteModalTitle,
    deleteModalSubText,
    deleteModalSuccessButtonText,
  } = props;

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [open] = useState(true);

  const handleClick = () => {
    if (!userInfo.remember) {
      localStorage.clear();
      sessionStorage.clear();
    }
    dispatch(resetClientInfo());

    navigate('/');
  };

  useEffect(() => {
    return () => {
      removeTokenInfo();
      sessionStorage.clear();
      dispatch(resetClientInfo());
      navigate('/');
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal
        className={styles['add-comment-success-modal']}
        open={open}
        onClose={() => props.closeModal()}
      >
        <Box className={styles['add-comment-success-box']}>
          <div className={styles['featured-icon']}>
            <div className={styles['check-circle']}>
              <img src={check} alt="key" />
            </div>
          </div>
          <div className={styles['text-and-supporting-text']}>
            <p className={styles['text']}>{deleteModalTitle}</p>
            <p className={styles['delete-modal-subtext']}>
              {deleteModalSubText}
            </p>
          </div>
          <div className={styles['success-modal-actions']}>
            <button
              data-cy="success-button"
              className={styles['success-button']}
              onClick={() => {
                handleClick();
              }}
            >
              <p className={styles['success-button-text']}>
                {deleteModalSuccessButtonText}
              </p>
            </button>
          </div>
        </Box>
      </Modal>

      <div className={styles['delete-modal-parent']}></div>
    </>
  );
};
export default DeleteModal;
