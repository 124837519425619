import React from 'react';

import EmailPasswordChange from 'Components/CommonComponents/EmailPasswordChange';

const UpdateClientDetailsPage = () => {
  return (
    <>
      <EmailPasswordChange />
    </>
  );
};

export default UpdateClientDetailsPage;
