import { setContext } from '@apollo/client/link/context';
import { useSelector } from 'react-redux';

const TokenMiddleware = () => {
  const token = useSelector((state) => state.clientInfo.value.token);

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });
  return authLink;
};
export default TokenMiddleware;
