import React, { useEffect } from 'react';

import Button from '@mui/material/Button';

import { useLazyQuery } from '@apollo/client';

import { GET_ARTICLES_OF_LAWYER } from 'Services/Queries/lawyer';
import ArticleCard from 'Components/Articles/ArticleCard';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/lawyer/LawyerPageArticleSection.scss';

const NUMBER_OF_ARTICLES_PER_PAGE = 2;

const LawyerPageArticleSection = (props) => {
  const [articles, { data }] = useLazyQuery(GET_ARTICLES_OF_LAWYER);

  useEffect(() => {
    articles({
      variables: {
        input: {
          user_id: Number(props.lawyer.user_id),
          limit: NUMBER_OF_ARTICLES_PER_PAGE,
          page: 1,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (data?.articlesOfLawyer?.articles.length === 0 && props.hideIfNoArticles) {
    return null;
  }

  return (
    <div className="lawp-lawyer-page-article-section-center-container">
      <div className="lawp-lawyer-page-article-section-container">
        <div className="lawp-lawyer-page-article-section-description-container">
          <div className="lawp-lawyer-page-article-section-public-title-container">
            {props.lawyer && props.lawyer.first_name && (
              <h4 className="lawp-lawyer-page-article-section-client-name">
                {`${LPTranslate('AS_Latest_From')} ${
                  props.lawyer.title ? props.lawyer.title + '.' : ''
                } ${props.lawyer.first_name} ${props.lawyer.last_name}`}
                {props.lawyer.title_suffix && `, ${props.lawyer.title_suffix}`}
              </h4>
            )}

            {props.lawyer && props.lawyer.first_name === undefined && (
              <h4 className="lawp-lawyer-page-article-section-client-name">
                {LPTranslate('AS_Latest_Info')}
              </h4>
            )}

            <h2 className="lawp-lawyer-page-article-section-review-title">
              {LPTranslate('AS_Legal_Info')}
            </h2>

            <div className="lawp-lawyer-page-article-section-lawyer-description">
              <h3 className="lawp-lawyer-page-article-section-lawyer-description-text">
                {LPTranslate('AS_Legal_Info_Description')}
              </h3>
            </div>

            {data && data.articlesOfLawyer.number_of_pages > 1 && (
              <div className="lawp-lawyer-page-article-section-view-all-articles-button-container">
                <Button
                  data-cy={'show-articles-button'}
                  className="lawp-lawyer-page-article-section-view-all-articles-button"
                  onClick={() => {
                    props.changeContent('articles');
                  }}
                >
                  {LPTranslate('LPP_Articles_Action_Button')}
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className="lawp-lawyer-page-article-section-all-article-container">
          {data &&
            data.articlesOfLawyer.articles.map((article, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="lawp-lawyer-page-article-section-article-container">
                    <ArticleCard
                      keyIndex={index}
                      article={article}
                      previousPage={props.previousPageForArticleCards}
                    />
                  </div>

                  {index === data.articlesOfLawyer.articles.length - 1 &&
                    index === 0 && (
                      <div className="lawp-lawyer-page-article-section-only-one-articles-container">
                        <h3 className="lawp-lawyer-page-article-section-no-articles-container-text">
                          {' '}
                          {LPTranslate('Error_Only_One_Article_Lawyer_Page')}
                        </h3>
                      </div>
                    )}
                </React.Fragment>
              );
            })}

          {data !== undefined && data.articlesOfLawyer.length === 0 && (
            <div className="lawp-lawyer-page-article-section-no-articles-container">
              <h3 className="lawp-lawyer-page-article-section-no-articles-container-text">
                {' '}
                {LPTranslate('Error_No_Articles_Lawyer_Page')}
              </h3>
            </div>
          )}
        </div>

        {/* <div className='lawp-lawyer-page-article-section-article-container'></div> */}
      </div>
    </div>
  );
};

export default LawyerPageArticleSection;
