import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import mailIcon from 'Assets/pictures/mailIcon.svg';
import styles from 'Assets/styles/updateLawyerDetails/ConfirmChangingLawyerEmailModal.module.scss';

const ConfirmChangingLawyerEmailModal = (props) => {
  const [hashedEmail, setHashedEmail] = useState('');

  const hashEmail = (email) => {
    let index1 = email.indexOf('@') - 1;

    var arr1 = email.split('');

    for (let i = 1; i < 4; i++) {
      arr1.splice(index1 - i, 1, '*');
    }

    const after = email.substring(email.indexOf('@') + 1);
    let index2 = after.indexOf('.');

    var arr2 = after.split('');

    for (let i = 1; i < 3; i++) {
      arr2.splice(index2 - i, 1, '*');
    }

    var result1 = arr1.join('').substring(0, email.indexOf('@'));

    var result2 = arr2.join('');

    setHashedEmail(result1 + '@' + result2);
  };

  useEffect(() => {
    hashEmail(props.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box className={styles['box']}>
        <div className={styles['modal-container']}>
          <div className={styles['content']}>
            <div className={styles['header']}>
              <div className={styles['featured-icon']}>
                <div className={styles['mail']}>
                  <img src={mailIcon} alt="key" />
                </div>
              </div>

              <div className={styles['text-and-supporting-text']}>
                <p className={styles['text']}>
                  {LPTranslate('Common_Check_Your_Email')}
                </p>

                <p className={styles['supporting-text']}>
                  {LPTranslate('Common_Check_Your_Email_Description_1')}{' '}
                  {hashedEmail}{' '}
                  {LPTranslate('Common_Check_Your_Email_Description_2')}
                </p>
              </div>
            </div>

            <div className={styles['row']}>
              <p className={styles['row-text']}>
                {LPTranslate('Common_No_Email_Received')}
              </p>

              <div className={styles['row-button']}>
                <button
                  data-cy="resend-button"
                  className={styles['row-button-base']}
                  onClick={props.updateEmail}
                >
                  <p className={styles['row-button-text']}>
                    {LPTranslate('Common_Resend_Email')}
                  </p>
                </button>
              </div>
            </div>
          </div>

          <div className={styles['actions']}>
            <div className={styles['confirm-button']}>
              <button
                data-cy="submit-button"
                className={styles['confirm-button-base']}
                onClick={() => {
                  props.resetState();
                  props.handleClose();
                }}
              >
                <p className={styles['confirm-button-text']}>
                  {LPTranslate('Button_Done')}
                </p>
              </button>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default ConfirmChangingLawyerEmailModal;
