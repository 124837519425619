import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';

import { GET_REVIEW_REPORT } from 'Services/Queries/lawyer';
import RatingStars from 'Components/RatingStars/RatingStars';
import SimpleTextField from 'Components/Inputs/SimpleTextField';
import Checkbox from 'Components/Lawyer/FilterCheckBox';
import reportIcon from 'Assets/pictures/reportIcon.svg';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { milisecondsToMMMDDYYYY } from 'Services/Utils/moment';

import 'Assets/styles/lawyer/ReviewResponseModal.scss';

const reportReasons = [
  'Does not correspond to the truth',
  'No client',
  'Inappropriate language',
];

export default function NestedModal(props) {
  const [report, setReport] = React.useState('');
  const [checkboxes, setCheckboxes] = React.useState([0, 0, 0]);

  const { data } = useQuery(GET_REVIEW_REPORT, {
    variables: {
      id: props.review.id,
    },
  });

  React.useEffect(() => {
    if (data && data.getReviewReport) {
      setReport(data.getReviewReport.report_message);

      let newCheckboxes = [0, 0, 0];
      if (
        data.getReviewReport.report_reason ===
        'Does not correspond to the truth'
      )
        newCheckboxes[0] = 1;
      else if (data.getReviewReport.report_reason === 'No client')
        newCheckboxes[1] = 1;
      else if (data.getReviewReport.report_reason === 'Inappropriate language')
        newCheckboxes[2] = 1;

      setCheckboxes(newCheckboxes);
    }
  }, [data]);

  const handleClose = () => {
    props.handleClose();
  };

  const handleSubmit = () => {
    let reviewIndex = null;

    for (let i = 0; i < checkboxes.length; i++)
      if (checkboxes[i] === 1) reviewIndex = i;

    let reportData = {
      reportMessage: report,
      reportReason:
        reviewIndex === null ? 'Not specified' : reportReasons[reviewIndex],
    };

    props.handleSubmit(reportData);
    handleClose();
  };

  const onCheckboxChange = (index) => {
    let newCheckboxes = [0, 0, 0];
    newCheckboxes[index] = 1;
    setCheckboxes(newCheckboxes);
  };

  return (
    <Modal open={props.reviewModalOpen} onClose={handleClose}>
      <Box className="lawp-report-report-modal-container">
        <h2 className="title-date">
          <img src={reportIcon} alt="report icon" className="report-icon" />
          {LPTranslate('RS_Report_Rating_From')}{' '}
          {milisecondsToMMMDDYYYY(props.review.createdAt)}
        </h2>

        <div className="review-card">
          <RatingStars
            value={props.review.rating}
            width={20}
            height={20}
            readonly={true}
            colorFilter={
              'invert(91%) sepia(69%) saturate(1377%) hue-rotate(317deg) brightness(99%) contrast(102%)'
            }
          />

          <p className="client-name">{props.review.client_name}</p>

          <p className="review-content">"{props.review.content}"</p>
        </div>

        <div className="report-reason-container">
          <p>{LPTranslate('RS_Tick_As_Appropriate')}</p>
          <div className="checkboxes-container">
            <div className="checkbox-container">
              <Checkbox
                data_cy="not-true-checkbox"
                title="Ich habe die Rechtsberatung nicht in Anspruch genommen"
                checked={checkboxes[0]}
                onChange={() => onCheckboxChange(0)}
              />
              <p> {LPTranslate('RS_Report_Reason_1')} </p>
            </div>
            <div className="checkbox-container">
              <Checkbox
                data_cy="no-client-checkbox"
                title="Ich habe die Rechtsberatung nicht in Anspruch genommen"
                checked={checkboxes[1]}
                onChange={() => onCheckboxChange(1)}
              />
              <p> {LPTranslate('RS_Report_Reason_2')} </p>
            </div>
            <div className="checkbox-container">
              <Checkbox
                data_cy="inappropriate-language-checkbox"
                title="Ich habe die Rechtsberatung nicht in Anspruch genommen"
                checked={checkboxes[2]}
                onChange={() => onCheckboxChange(2)}
              />
              <p> {LPTranslate('RS_Report_Reason_3')} </p>
            </div>
          </div>
        </div>

        <SimpleTextField
          data_cy="report-description-input"
          title={LPTranslate('RS_Report_a_Review')}
          value={report}
          onChange={(value) => setReport(value)}
          textFieldWidthPercent={100}
          multiline={true}
          rows={2}
          placeholder={LPTranslate('RS_Report_Input_Placeholder')}
          hasHintText={true}
          hintText={LPTranslate('RS_Report_Input_Hint')}
        />

        <div className="button-container">
          <Button
            data-cy="report-cancel-button"
            className="cancel-button"
            onClick={() => handleClose()}
          >
            {LPTranslate('Button_Close')}
          </Button>
          <Button
            data-cy="report-submit-button"
            className="submit-button"
            onClick={handleSubmit}
          >
            {LPTranslate('RS_Submit_Report')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
