import React from 'react';

import { useSelector } from 'react-redux';

import SettingsMenu from 'Components/CommonComponents/SettingsMenu';

import 'Assets/styles/operator/OperatorSettingsPage.scss';

const OperatorSettingsPage = () => {
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  return (
    <>
      <h1>Operator Account Page</h1>

      <SettingsMenu
        setUpdateComponent={'/OperatorAccount'}
        role={clientInfoRedux.role}
      />
    </>
  );
};

export default OperatorSettingsPage;
