import React, { useState, useEffect, useRef } from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import 'Assets/styles/lawyer/EditAutoComplete.scss';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option) => option.title,
});

/**
 * @param {{dataArray: [{title: String, selected: 0|1}], title: String, textFieldWidth: Number}} props
 * @returns {JSX.Element}
 */
export default function AutoCompleteFixedValuesSingleSelect(props) {
  const { dataArray, title, textFieldWidthPercent, errorMessage, disabled } =
    props;
  const [textFieldValue, setTextFieldValue] = useState({
    title: '',
    selected: 1,
  });

  const widthTextField =
    textFieldWidthPercent !== undefined ? `${textFieldWidthPercent}%` : 300;

  const containerRef = useRef(null);

  const handleChangeMultiple = (value) => {
    for (let entry of dataArray) {
      entry.selected = 0;
    }
    value.selected = 1;
    setTextFieldValue(value);
    props.onChange(value.title);
  };

  useEffect(() => {
    for (let entry of dataArray) {
      if (entry.selected === 1) {
        setTextFieldValue({ title: entry.title, selected: 1 });
        break;
      }
    }
  }, [dataArray]);

  useEffect(() => {
    for (let entry of dataArray) {
      if (entry.title === props.selected) {
        entry.selected = 1;
        setTextFieldValue(entry);
      }
    }
  }, [props.selected, dataArray]);

  return (
    <div
      className="editLawyerContainerAutoComplete"
      style={{
        width: widthTextField,
      }}
    >
      <p className="editLawyerTitleAutoComplete"> {title} </p>

      <Autocomplete
        ref={containerRef}
        className={`editLawyerAutoCompleteStyling ${
          errorMessage ? 'editLawyerAutoCompleteStyling-error' : ''
        }`}
        disablePortal
        clearOnEscape
        id="filter-demo"
        value={textFieldValue}
        options={dataArray}
        getOptionLabel={(option) => option.title}
        filterOptions={filterOptions}
        popupIcon={<KeyboardArrowDownIcon />}
        onChange={(event, value) => {
          if (value !== null) handleChangeMultiple(value);
        }}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            data-cy="salutation-input"
            {...params}
            placeholder={
              props.placeholder ? props.placeholder : 'Bitte auswählen'
            }
            className={` ${
              disabled ? 'editLawyerTextFieldAutoCompleteDisabled' : ''
            } ${
              errorMessage
                ? 'editLawyerAutocompleteTextfield-error'
                : 'editLawyerTextFieldAutoComplete'
            } 
            `}
            disabled={disabled}
          />
        )}
      />

      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
}
