import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

const subscriptionContentText = (data, subscription_period_german) => {
  console.log('props data: ', data);

  if (data.type === 'CancelSchedule')
    return (
      <>
        {LPTranslate('LSUS_Cancel_Schedule_Title_1') +
          ' ' +
          data.newSubscription +
          LPTranslate('LSUS_Cancel_Schedule_Title_2') +
          '?'}
      </>
    );
  else if (data.type === 'downgrade' || data.type === 'update') {
    const currentSubscriptionPeriod =
      subscription_period_german[`title_${data.old_recurring_interval}`];
    const newSubscriptionPeriod =
      subscription_period_german[`title_${data.new_recurring_interval}`];

    return (
      <>
        {LPTranslate('LSUS_Downgrade_Title_1') +
          ' ' +
          data.currentSubscription +
          LPTranslate('LSUS_Update_Title_2_1') +
          ' (' +
          currentSubscriptionPeriod +
          ') ' +
          LPTranslate('LSUS_Update_Title_2_2') +
          ' ' +
          data.newSubscription +
          LPTranslate('LSUS_Update_Title_3_1')}

        {data.newSubscription !== 'Silver' &&
          ' (' + newSubscriptionPeriod + ')'}
        {LPTranslate('LSUS_Update_Title_3_2')}
      </>
    );
  }
};

const subscriptionContentSupport = (data, subscription_period_german) => {
  if (data.type === 'CancelSchedule')
    return (
      <>
        {LPTranslate('LSUS_Cancel_Schedule_Information_1') +
          ' ' +
          data.newSubscription +
          LPTranslate('LSUS_Cancel_Schedule_Information_2')}
      </>
    );
  else if (data.type === 'downgrade' || data.type === 'update')
    return (
      <>
        {LPTranslate('LSUS_Update_Information_1') +
          ' €' +
          data.newPrice +
          LPTranslate('LSUS_Update_Information_2') +
          ' ' +
          data.newSubscription +
          LPTranslate('LSUS_Update_Information_3')}

        {data.newSubscription !== 'Silver'
          ? ' ' +
            subscription_period_german[
              `description_${data.new_recurring_interval}`
            ] +
            ' '
          : '. '}

        {LPTranslate('LSUS_Update_Information_4') +
          subscription_period_german[`end_of_current_subscription`] +
          ' ' +
          data.startDate +
          '.'}
      </>
    );
};

const actualSubscriptionTexts = (subscriptionInformation) => {
  if (subscriptionInformation.subscriptionName === 'Silver')
    return <>{LPTranslate('Anwado_Basic_Plan')}</>;
  else if (
    subscriptionInformation.subscriptionName === 'Gold' &&
    subscriptionInformation.subscriptionRecurringInterval === 'Month'
  )
    return (
      <>
        {LPTranslate('Anwado_Gold_Plan') + ' ' + LPTranslate('Common_Monthly')}
      </>
    );
  else if (
    subscriptionInformation.subscriptionName === 'Gold' &&
    subscriptionInformation.subscriptionRecurringInterval === 'Year'
  )
    return (
      <>
        {LPTranslate('Anwado_Gold_Plan') + ' ' + LPTranslate('Common_Yearly')}
      </>
    );
  else if (
    subscriptionInformation.subscriptionName === 'Platin' &&
    subscriptionInformation.subscriptionRecurringInterval === 'Month'
  )
    return (
      <>
        {LPTranslate('Anwado_Platin_Plan') +
          ' ' +
          LPTranslate('Common_Monthly')}
      </>
    );
  else if (
    subscriptionInformation.subscriptionName === 'Platin' &&
    subscriptionInformation.subscriptionRecurringInterval === 'Year'
  )
    return (
      <>
        {LPTranslate('Anwado_Platin_Plan') + ' ' + LPTranslate('Common_Yearly')}
      </>
    );
};

export {
  subscriptionContentText,
  actualSubscriptionTexts,
  subscriptionContentSupport,
};
