import React, { useEffect, useState, useCallback } from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';

import { SEARCH_L } from 'Services/Queries/lawyer';
import SettingsMenu from 'Components/CommonComponents/SettingsMenu';
import EditReloadPageSection from 'Components/Lawyer/Edit/EditReloadPageSection';
import LawyerCard from 'Components/Lawyer/LawyerCard';
import Filters from 'Components/Lawyer/Filters';
import FiltersDrawer from 'Components/Lawyer/FiltersDrawer';
import CircularProgress from '@mui/material/CircularProgress';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import leftArrow from 'Assets/pictures/leftArrow.svg';
import rightArrow from 'Assets/pictures/rightArrow.svg';

import styles from 'Assets/styles/client/settings/ClientProfileSettings.module.scss';
import CardStyles from 'Assets/styles/user/UserClientPage.module.scss';
import { parseJwt } from 'Services/Utils/token';

const ClientFavoriteLawyer = () => {
  const [filterCheckBoxes, setFilterCheckBoxes] = useState({});
  const clientRedux = useSelector((state) => state.clientInfo.value);
  const userId = parseInt(clientRedux.user_id);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);

  const [lawyerList, setLawyerList] = useState([]);

  const [lawyersDB, { loading, error, data }] = useLazyQuery(SEARCH_L, {
    fetchPolicy: 'no-cache',
  });

  const fetchLawyers = useCallback(async () => {
    await lawyersDB({
      variables: {
        input: {
          areaOfLawFilter: [],
          distanceSearch: 0,
          filterCheckBoxes: filterCheckBoxes,
          searchRelativeTo: 'favorites',
          cityFilter: [''],
          search: '',
          client_id: userId,
          page: page,
          pageLimit: 10,
        },
      },
    });
  }, [lawyersDB, filterCheckBoxes, userId, page]);

  const clearFilters = () => {
    setFilterCheckBoxes({});
  };

  useEffect(() => {
    fetchLawyers();
  }, [fetchLawyers, filterCheckBoxes]);

  useEffect(() => {
    fetchLawyers();
  }, [fetchLawyers, page]);

  useEffect(() => {
    if (data !== undefined) {
      setLawyerList(data.searchL.lawyers);
      setPageCount(data.searchL.number_of_pages);
    }
  }, [data]);

  const handlePageChange = (value) => {
    if (value >= 1 && value <= pageCount) {
      setPage(value);
    }
  };

  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  if (error)
    return (
      <>
        <p>Error : </p>
      </>
    );

  return (
    <>
      <div className={styles['client-profile-settings-page-container']}>
        <div className={styles['client-profile-settings-top-menu']}>
          <SettingsMenu
            setUpdateComponent={'/favoriteLawyer'}
            role={clientInfoRedux.role}
            subscription={parseJwt(clientInfoRedux.token).subscription}
          />
        </div>

        <div
          className={
            styles['client-profile-settings-edit-save-changes-section']
          }
        >
          <EditReloadPageSection
            pageTitle={LPTranslate('Common_Favorite_Lawyers')}
            noText={LPTranslate(
              'Common_Page_Section_Placeholder_For_Favorites_Lawyer'
            )}
            onReloadChanges={fetchLawyers}
          />
        </div>
        <div className={styles['client-profile-settings-divider']} />

        <div className={CardStyles['hamburgerMenu']}>
          <FiltersDrawer
            filters={filterCheckBoxes}
            setFilterCheckBoxes={setFilterCheckBoxes}
            lawyerList={lawyerList}
            clearFilters={clearFilters}
            removeClearFilterButtonIfNoFilters={true}
          />
        </div>
        <div className={CardStyles.lawyerSearchContainer}>
          {lawyerList?.length === 0 ? (
            <div className={CardStyles['emptyListText']}>
              {LPTranslate('Error_Favorites_Empty_List')}
            </div>
          ) : (
            <div className={CardStyles['lawyerCardsContainer']}>
              {loading ? (
                <div className={styles['loading-container']}>
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                lawyerList.map((lawyer, key) => (
                  <LawyerCard key={lawyer.id} lawyer={lawyer} />
                ))
              )}

              {lawyerList.length > 0 && pageCount > 1 && !loading && (
                <div
                  className={
                    CardStyles[
                      'lawp-article-blog-page-articles-pagination-container'
                    ]
                  }
                >
                  <Button
                    disableRipple
                    disabled={page === 1}
                    className={
                      CardStyles['lawp-article-blog-page-left-arrow-button']
                    }
                    startIcon={
                      page === 1 ? null : (
                        <img src={leftArrow} alt="leftArrow" />
                      )
                    }
                    onClick={() => {
                      handlePageChange(page - 1);
                    }}
                  >
                    {page === 1 ? '' : LPTranslate('Button_Back')}
                  </Button>

                  <Stack>
                    <Pagination
                      className="lawp-article-blog-page-pagination"
                      count={pageCount}
                      page={page}
                      onChange={(event, value) => {
                        handlePageChange(value);
                      }}
                    />
                  </Stack>

                  <Button
                    disableRipple
                    disabled={page === pageCount}
                    className={
                      CardStyles['lawp-article-blog-page-left-arrow-button']
                    }
                    endIcon={
                      page === pageCount ? null : (
                        <img src={rightArrow} alt="leftArrow" />
                      )
                    }
                    onClick={() => handlePageChange(page + 1)}
                  >
                    {page === pageCount ? '' : LPTranslate('Button_Next')}
                  </Button>
                </div>
              )}
            </div>
          )}

          <div className={styles['favorite-lawyers-filter']}>
            <Filters
              lawyerList={lawyerList}
              filters={filterCheckBoxes}
              setFilterCheckBoxes={setFilterCheckBoxes}
              clearFilters={clearFilters}
              removeClearFilterButtonIfNoFilters={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ClientFavoriteLawyer;
