import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import EmailChecker from 'Components/RegisterClient/EmailChecker';
import VerificationCode from 'Components/RegisterClient/VerificationCode';
import VerifyClientOnRegister from 'Components/RegisterClient/VerifyClientOnRegister';
import SetPasswordOnRegister from 'Components/RegisterLawyer/SetPasswordOnRegister';
import CreateLawyerAccount from 'Components/RegisterLawyer/CreateLawyerAccount';
import SetClientDetailsOnRegister from 'Components/RegisterClient/SetClientDetailsOnRegister';
import StepNavigation from 'Components/Stepper/stepNavigation';

import { parseJwt } from 'Services/Utils/token';
import registerSteps from 'Services/registerClientSteps';

import {
  VERIFY_OTP_CODE_AND_JWT,
  // VERIFY_ACCOUNT_ON_SETTING_MEETING,
} from 'Services/Queries/client';
import { DELETE_CLIENT_FROM_REGISTER } from 'Services/Queries/client';

import {
  setClientStepper,
  addFirstname,
  addLastname,
  addRegisterEmail,
} from 'Services/Redux/reducers/RegisterReducer';

import logo from 'Assets/pictures/anwado-logo2.svg';
import styles from 'Assets/styles/registerLawyer/VerifyNewLawyerPage.module.scss';

const VerifyNewClientProcess = (props) => {
  const [verifyOtpCode] = useMutation(VERIFY_OTP_CODE_AND_JWT);

  //assigned but never used
  // const [verifyOnSettingMeeting] = useMutation(
  //   VERIFY_ACCOUNT_ON_SETTING_MEETING
  // );

  const [deleteClient] = useMutation(DELETE_CLIENT_FROM_REGISTER);

  const { token, tokenAccepted, registerFromOtherComponent } = props;
  const lawyerRegisterRedux = useSelector(
    (state) => state.registerStepper.value
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentStep, updateCurrentStep] = useState(1);
  const [passwordOnRegister, setPasswordOnRegister] = useState('');

  const [firstname, setFirstname] = useState(
    lawyerRegisterRedux.firstName ? lawyerRegisterRedux.firstName : ''
  );
  const [lastname, setLastname] = useState(
    lawyerRegisterRedux.lastName ? lawyerRegisterRedux.lastName : ''
  );
  const [email, setEmail] = useState('');

  const [completeProfileInformation, setCompleteProfileInformation] = useState({
    address: '',
    nr: '',
    postalCode: '',
    city: '',
    phoneNumber: '',
  });

  useEffect(() => {
    updateCurrentStep(lawyerRegisterRedux.clientStepper);
  }, [lawyerRegisterRedux]);

  useEffect(() => {
    // Push the current state into the history stack
    window.history.pushState(null, document.title, window.location.href);

    // Handle popstate events
    const handlePopState = (event) => {
      window.history.pushState(null, document.title, window.location.href);
    };

    // Add the event listener
    window.addEventListener('popstate', handlePopState);

    // Cleanup: Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []); // Empty dependency array ensures this effect runs once when the component mounts and cleans up when it unmounts

  useEffect(() => {
    if (!token && registerFromOtherComponent) {
      setFirstname(lawyerRegisterRedux.firstname);
      setLastname(lawyerRegisterRedux.lastname);
      setEmail(lawyerRegisterRedux.email);

      handleNext(1);
    }
    if (token && tokenAccepted && !parseJwt(token).otp) {
      checkIfVerificationIsAutomatic(
        '',
        parseJwt(token).email,
        parseJwt(token).registerOnAppointment
      );
      setFirstname(parseJwt(token).firstname);
      setLastname(parseJwt(token).lastname);
      setEmail(parseJwt(token).email);
    } else if (token && tokenAccepted && parseJwt(token).otp) {
      checkIfVerificationIsAutomatic(
        parseJwt(token).otp.toString(),
        parseJwt(token).email,
        true
      );

      setFirstname(parseJwt(token).firstname);
      setLastname(parseJwt(token).lastname);
      setEmail(parseJwt(token).email);
    } else {
      setFirstname(lawyerRegisterRedux.firstname);
      setLastname(lawyerRegisterRedux.lastname);
      setEmail(lawyerRegisterRedux.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerFromOtherComponent, token, tokenAccepted]);

  useEffect(() => {
    console.log('current step: ', currentStep);
  }, [currentStep]);

  const returnToRegister = async () => {
    //delete user and get back 1 step
    try {
      let deleteClientFromRegister = await deleteClient({
        variables: {
          email: email,
        },
      });
      if (
        deleteClientFromRegister.data.deleteClientFromRegister.message ===
        'User has been deleted!'
      ) {
        dispatch(addFirstname(''));
        dispatch(addLastname(''));
        dispatch(addRegisterEmail(''));

        setFirstname('');
        setLastname('');
        setEmail('');

        dispatch(setClientStepper(0));
      }

      // navigate('/registerClient');

      // navigate('/registerClient', {
      //   // state: { isSentFromLawyerPage: false },
      // });
    } catch (error) {
      // show error to user
    }
  };

  const handleChangePassword = (password) => {
    setPasswordOnRegister(password);
  };

  useEffect(() => {
    const event = () => {
      for (let i = 1; i < lawyerRegisterRedux.clientStepper; i++) {
        registerSteps[i - 1].isChecked = true;
      }
    };
    event();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIfVerificationIsAutomatic = async (
    otp,
    email,
    registerOnAppointment
  ) => {
    if (token) {
      if (registerOnAppointment) {
        try {
          let verifyCode = await verifyOtpCode({
            variables: {
              input: {
                jwt: token,
              },
            },
          });

          console.log('verifying otp code from token...');

          if (verifyCode.data.verifyClientOtpCodeAndJwt.message) {
            console.log(
              'verify otp code data:',
              verifyCode.data.verifyClientOtpCodeAndJwt
            );
            handleNext(1);
            handleNext(2);
            console.log('proceeding to 3rd step...');
          } else {
            console.log('error: ', verifyCode.data.verifyClientOtpCodeAndJwt);

            for (let i = 1; i < lawyerRegisterRedux.clientStepper; i++) {
              registerSteps[i - 1].isChecked = true;
            }
            console.log('testing registerSteps: ', registerSteps);
            console.log('current step on error: ', currentStep);

            updateCurrentStep(lawyerRegisterRedux.clientStepper);
            console.log(
              'redux step value on error:',
              lawyerRegisterRedux.clientStepper
            );
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          let verifyCode = await verifyOtpCode({
            variables: {
              input: {
                jwt: token,
              },
            },
          });

          if (verifyCode.data.verifyClientOtpCodeAndJwt.message) {
            handleNext(1);
            handleNext(2);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const handleNext = (step) => {
    dispatch(setClientStepper(step + 1));
    registerSteps[step - 1].isChecked = true;
    updateCurrentStep(step + 1);
  };

  const handleBack = (step) => {
    dispatch(setClientStepper(step + 1));
    registerSteps[step].isChecked = false;
    updateCurrentStep(step + 1);
  };

  function updateStep(step) {
    updateCurrentStep(step);
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <EmailChecker
              labelArray={registerSteps}
              currentStep={currentStep}
              handleNext={handleNext}
              email={email}
              returnToRegister={returnToRegister}
            />
          </>
        );
      case 1:
        return (
          <>
            <VerificationCode
              handleNext={handleNext}
              handleBack={handleBack}
              firstName={firstname}
              lastName={lastname}
              email={email}
              returnToRegister={returnToRegister}
            />
          </>
        );
      case 2:
        return (
          <>
            <VerifyClientOnRegister
              handleNext={handleNext}
              handleBack={handleBack}
              email={email}
              token={token}
              returnToRegister={returnToRegister}
            />
          </>
        );
      case 3:
        return (
          <>
            <SetClientDetailsOnRegister
              email={email}
              handleNext={handleNext}
              completeProfileInformation={completeProfileInformation}
              setCompleteProfileInformation={setCompleteProfileInformation}
            />
          </>
        );
      case 4:
        return (
          <>
            <SetPasswordOnRegister
              handleNext={handleNext}
              handleBack={handleBack}
              email={email}
              handleChangePassword={(pass) => handleChangePassword(pass)}
            />
          </>
        );
      case 5:
        return (
          <>
            <CreateLawyerAccount
              name={firstname + ' ' + lastname}
              email={email}
              passwordOnRegister={passwordOnRegister}
            />
          </>
        );
      default:
        return 'unknown step';
    }
  };

  const handleButton = (link) => {
    navigate(link);
  };

  return (
    <>
      <div className={styles.verifyLawyerContainer}>
        <div className={styles.verifyClientSection}>
          <div className={styles.topSection}>
            <StepNavigation
              role="client"
              labelArray={registerSteps}
              currentStep={currentStep}
              updateStep={updateStep}
            />
          </div>
          <div className={styles.verifyLeftSection}>
            <div className={styles.verifyLeftSectionContainer}>
              <img
                className={styles.verifyLogo}
                src={logo}
                alt="logo"
                onClick={() => handleButton('/')}
              />

              <div className={styles.content}>
                <StepNavigation
                  role="client"
                  labelArray={registerSteps}
                  currentStep={currentStep}
                  updateStep={updateStep}
                />
              </div>
            </div>

            <div className={styles.verifyFooter}></div>
          </div>

          {currentStep === registerSteps.length + 1 ? (
            <CreateLawyerAccount
              name={firstname + ' ' + lastname}
              email={email}
              passwordOnRegister={passwordOnRegister}
            />
          ) : (
            <>{getStepContent(currentStep - 1)}</>
          )}
        </div>
      </div>
    </>
  );
};

export default VerifyNewClientProcess;
