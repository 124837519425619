import React from 'react';

import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import SimpleTextField from 'Components/Inputs/SimpleTextField';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import avatar from 'Assets/pictures/AvatarProfile.png';

import styles from 'Assets/styles/lawyer/SendMessageToLawyerNotLoggedModal.module.scss';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SendMessageToLawyerNotLoggedBox = (props) => {
  let {
    lawyer,
    articleImage,
    formValues,
    formErrors,
    snackbarSeverity,
    snackbarOpen,
    snackbarMessage,
  } = props;

  return (
    <>
      <Box
        className={styles['send-message-to-lawyer-not-logged-modal-container']}
      >
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={props.handleCloseSnackbar}
        >
          <Alert
            onClose={props.handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={
              snackbarSeverity === 'error' && {
                backgroundColor: '#7f56d9 !important',
              }
            }
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <form
          className={styles['send-message-to-lawyer-not-logged-modal-content']}
          id={'send-lawyer-not-logged-message-form'}
          onSubmit={props.sendMessage}
        >
          <div
            className={
              styles[
                'send-message-to-lawyer-not-logged-modal-avatar-label-group'
              ]
            }
          >
            {articleImage !== null ? (
              <img
                alt="article"
                src={articleImage}
                className={
                  styles[
                    'send-message-to-lawyer-not-logged-modal-profile-image'
                  ]
                }
              />
            ) : (
              <img
                alt="avatar"
                src={avatar}
                className={
                  styles[
                    'send-message-to-lawyer-not-logged-modal-profile-image'
                  ]
                }
              />
            )}
            <div
              className={
                styles['send-message-to-lawyer-not-logged-modal-name-and-email']
              }
            >
              <h3
                className={
                  styles['send-message-to-lawyer-not-logged-modal-name']
                }
              >
                {lawyer.first_name} {lawyer.last_name}
              </h3>

              <h3
                className={
                  styles['send-message-to-lawyer-not-logged-modal-email']
                }
              >
                {lawyer.email}
              </h3>
            </div>
          </div>

          {lawyer.subscription > 0 ? (
            <>
              <div
                className={
                  styles[
                    'send-message-to-lawyer-not-logged-modal-text-and-supporting-text'
                  ]
                }
              >
                <h2
                  className={
                    styles['send-message-to-lawyer-not-logged-modal-text']
                  }
                >
                  {LPTranslate('USM_Title')} {lawyer.first_name}{' '}
                  {lawyer.last_name}
                </h2>
                <h3
                  className={
                    styles[
                      'send-message-to-lawyer-not-logged-modal-supporting-text'
                    ]
                  }
                >
                  {LPTranslate('USM_Description_1')}{' '}
                  {lawyer.first_name + ' ' + lawyer.last_name}
                  {LPTranslate('USM_Description_2')}.
                </h3>
              </div>

              <div
                className={
                  styles[
                    'send-message-to-lawyer-not-logged-modal-textarea-input-field'
                  ]
                }
              >
                <SimpleTextField
                  data_cy="description-input"
                  title={LPTranslate('Common_Message')}
                  value={formValues.description}
                  errorMessage={formErrors.description}
                  onChange={(value) => {
                    props.handleInputChange('description', value);
                    props.setFormErrors({
                      ...formErrors,
                      description: '',
                    });
                  }}
                  textFieldWidthPercent={100}
                  multiline={true}
                  rows={2.5}
                  type="text"
                  placeholder={LPTranslate('CSA_Message_Placeholder')}
                />
              </div>
              <div
                className={
                  styles[
                    'send-message-to-lawyer-not-logged-modal-textarea-input-hint'
                  ]
                }
              >
                {LPTranslate('CSA_Message_Hint')}
              </div>
            </>
          ) : (
            lawyer.subscription === 0 && (
              <div
                className={
                  styles[
                    'send-message-to-lawyer-not-logged-modal-textarea-input-hint'
                  ]
                }
              >
                {LPTranslate('CSA_Message_Subscription_0_1').replace(
                  '<lawyer>',
                  lawyer.first_name + ' ' + lawyer.last_name
                )}
              </div>
            )
          )}
        </form>

        {lawyer.subscription > 0 && (
          <>
            <div
              className={
                styles['send-message-to-lawyer-not-logged-modal-actions']
              }
            >
              <button
                data-cy="cancel-button"
                className={
                  styles[
                    'send-message-to-lawyer-not-logged-modal-cancel-button'
                  ]
                }
                onClick={props.handleClose}
              >
                {LPTranslate('Button_Cancel')}
              </button>

              <button
                data-cy="submit-button"
                className={
                  styles[
                    'send-message-to-lawyer-not-logged-modal-success-button'
                  ]
                }
                type="submit"
                form="send-lawyer-not-logged-message-form"
              >
                {LPTranslate('AP_Next_Page')}
              </button>
            </div>

            <div className={styles['stepper-content']}>
              <div className={styles['step-icon-base']}>
                <div className={styles['selected-icon-content']}>
                  <div className={styles['selected-dot']} />
                </div>
              </div>

              <div className={styles['unselected-step-icon-base']}>
                <div className={styles['unselected-icon-content']}>
                  <div className={styles['unselected-dot']} />
                </div>
              </div>
            </div>
          </>
        )}

        {lawyer.subscription === 0 && (
          <div
            className={
              styles['send-message-to-lawyer-not-logged-modal-actions']
            }
          >
            <button
              data-cy="cancel-button"
              className={
                styles['send-message-to-lawyer-not-logged-modal-success-button']
              }
              style={{ width: '100%' }}
              onClick={props.handleClose}
            >
              Ok
            </button>
          </div>
        )}
      </Box>
    </>
  );
};

export default SendMessageToLawyerNotLoggedBox;
