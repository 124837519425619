import React from 'react';

import SimpleTextField from 'Components/Inputs/SimpleTextField';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import check from 'Assets/pictures/stepperCheck.svg';
import styles from 'Assets/styles/common/ConfirmationDeleteClientBox.module.scss';

const ConfirmationDeleteClientBox = (props) => {
  const { deleteText } = props;

  const { handleDeleteText, handleDeleteTextError } = props;

  return (
    <div className={styles['lawp-confirmation-delete-account-modal-container']}>
      {/* Stepper container */}
      <div
        className={
          styles['lawp-confirmation-delete-account-modal-stepper-content']
        }
      >
        <div
          className={
            styles[
              'lawp-confirmation-delete-account-modal-check-step-icon-base'
            ]
          }
        >
          <div
            className={
              styles[
                'lawp-confirmation-delete-account-modal-checked-icon-content'
              ]
            }
          >
            <img
              className={
                styles['lawp-confirmation-delete-account-modal-check-icon']
              }
              src={check}
              alt="checked"
            />
          </div>
        </div>

        <div
          className={
            styles['lawp-confirmation-delete-account-modal-unselected-line']
          }
        ></div>

        <div
          className={
            styles['lawp-confirmation-delete-account-modal-step-icon-base']
          }
        >
          <div
            className={
              styles[
                'lawp-confirmation-delete-account-modal-selected-icon-content'
              ]
            }
          >
            <div
              className={
                styles['lawp-confirmation-delete-account-modal-selected-dot']
              }
            />
          </div>
        </div>
      </div>
      <div className={styles['lawp-confirmation-delete-account-modal-content']}>
        {/* Text container */}
        <div
          className={
            styles[
              'lawp-confirmation-delete-account-modal-content-text-and-support-container'
            ]
          }
        >
          <div
            className={
              styles[
                'lawp-confirmation-delete-account-modal-content-text-container'
              ]
            }
          >
            {LPTranslate('CDAM_Important_Title')}
          </div>
          <div
            className={
              styles[
                'lawp-confirmation-delete-account-modal-content-support-container'
              ]
            }
          >
            {LPTranslate('CDAM_Important_Label')}
          </div>
        </div>
        {/* Input container */}
        <div
          className={
            styles['lawp-confirmation-delete-account-modal-input-container']
          }
        >
          <div
            className={
              styles['lawp-confirmation-delete-account-modal-input-label']
            }
          >
            <SimpleTextField
              data_cy="description-input"
              title={LPTranslate('Input_Specify_Reason')}
              placeholder={LPTranslate(
                'Common_Reason_For_Deletion_Placeholder'
              )}
              onChange={(value) => {
                handleDeleteText(value);
                handleDeleteTextError('');
              }}
              value={deleteText}
              textFieldWidthPercent={100}
              type="text"
              multiline={true}
              rows={2.5}
            />
          </div>

          {/* {deleteTextError.length === 0 && (
            <div
              className={styles['lawp-confirmation-delete-account-modal-hint']}
            >
              {LPTranslate('RSD_Input_Hint')}
            </div>
          )} */}
        </div>
        {/* Actions container */}
        <div
          className={
            styles[
              'lawp-confirmation-delete-account-modal-content-actions-container'
            ]
          }
        >
          <div
            data-cy="cancel-button"
            onClick={props.handleClose}
            className={
              styles[
                'lawp-confirmation-delete-account-modal-content-actions-cancel-button-container'
              ]
            }
          >
            <div
              className={
                styles[
                  'lawp-confirmation-delete-account-modal-content-actions-cancel-button'
                ]
              }
            >
              {LPTranslate('Button_Cancel')}
            </div>
          </div>
          <div
            data-cy="submit-button"
            onClick={() => props.handleDelete()}
            className={
              styles[
                'lawp-confirmation-delete-account-modal-content-actions-accept-button-container'
              ]
            }
          >
            <div
              className={
                styles[
                  'lawp-confirmation-delete-account-modal-content-actions-accept-button'
                ]
              }
            >
              {LPTranslate('CDAM_Step_2_Action_Button_Accept')}
            </div>
          </div>
        </div>
        {/* end */}
      </div>
    </div>
  );
};

export default ConfirmationDeleteClientBox;
