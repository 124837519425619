import React, { useEffect } from 'react';

import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from 'react-cookie-consent';
import { useNavigate } from 'react-router-dom';

import { tagManagerArgs } from 'Services/Utils/gtm-utils';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/help/CookiesAcceptBar.scss';

const CookiesAcceptBar = () => {
  const navigate = useNavigate();

  const handleAcceptCookie = () => {
    tagManagerArgs(process.env.REACT_APP_GTM_ID);

    // window.dataLayer = window.dataLayer || [];
    // function gtag() {
    //   window.dataLayer.push(arguments);
    // }
    // gtag('js', new Date());
    // gtag('config', 'G-MS9F0K441K', { anonymize_ip: true });

    // var s = document.createElement('script');
    // s.type = 'text/javascript';
    // s.async = 'true';
    // s.src = `https://www.googletagmanager.com/gtag/js?id=G-MS9F0K441K`;
    // var x = document.getElementsByTagName('script')[0];
    // x.parentNode.insertBefore(s, x);
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();

    if (isConsent === 'true') handleAcceptCookie();

    Cookies.set('anwado_form_data', true);

    Cookies.set('anwado_public_information', true);

    Cookies.set('anwado_token', true);

    Cookies.set('anwado_remember_me', true);

    Cookies.set('anwado_subscription_preference', true);

    // else {
    // var cookies = document.cookie.split('=')[0];
    // for (var i = 0; i < cookies.length; i++) {
    //   Cookies.remove(cookies);
    // }
    // window['ga-disable-<G-MS9F0K441K>'] = true;
    // }

    // console.log(window.dataLayer);
  }, []);

  return (
    <>
      <CookieConsent
        buttonText={LPTranslate('Cookies_Banner_Accept_Text')}
        declineButtonText={LPTranslate('Cookies_Banner_Decline_Text')}
        enableDeclineButton
        style={{ background: '#2B373B' }}
        contentClasses="lawp-cookies-accept-bar-text"
        buttonWrapperClasses="lawp-cookies-accept-bar-button-wrapper"
        containerClasses="lawp-cookies-accept-bar-container-class"
        buttonClasses="lawp-cookies-accept-bar-accept-button"
        declineButtonClasses="lawp-cookies-accept-bar-decline-button"
        onAccept={handleAcceptCookie}
      >
        {/* We use cookies on our websites for a number of purposes, including analytics and performance, functionality and advertising.{" "} */}
        {LPTranslate('Cookies_Banner_Text')}
        &nbsp;
        <span
          className="lawp-cookies-accept-bar-learn-more-text"
          onClick={() => {
            navigate('/datenschutz-cookies');
          }}
        >
          {LPTranslate('Cookies_Banner_Learn_More')}
        </span>
      </CookieConsent>
    </>
  );
};

export default CookiesAcceptBar;
