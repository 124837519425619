import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import EditLawyerArticleSectionDotsMenu from 'Components/Lawyer/Edit/EditLawyerArticleSectionDotsMenu';

import { milisecondsToDDMMYYYY } from 'Services/Utils/moment';
import { readingTime } from 'Services/Utils/readingTime';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { createUrlSlug } from 'Services/Utils/metaData';

import AvatarProfilePlaceholder from 'Assets/pictures/AvatarProfile.png';
import styles from 'Assets/styles/lawyer/EditLawyerArticleSection.module.scss';

const EditArticleCard = (props) => {
  const navigate = useNavigate();
  const { article, authorProfileImage } = props;

  const [articleID, setArticleID] = React.useState(null);
  const [profileImage, setProfileImage] = React.useState(null);
  const [articleImage, setArticleImage] = React.useState(null);

  useEffect(() => {
    const updateImages = async () => {
      if (article.coverImage) {
        setArticleImage(article.coverImage);
      }

      if (authorProfileImage) {
        setProfileImage(authorProfileImage);
      }

      setArticleID(article.id);
    };

    updateImages();
  }, [article, authorProfileImage, articleID]);

  const handleEditArticleButton = () => {
    props.handleEdit(article.id);
  };

  const deleteArticleHandler = async () => {
    props.handleDelete(article.id);
  };

  return (
    <div
      data-cy="article-card"
      className={styles.ArticleContainer}
      onClick={() => {
        let slug = createUrlSlug('', article.title, article.author_name, '');

        slug += `-${article.id}`;

        navigate(`/rechtsinfos/${slug}`, {
          state: { previousPage: props.previousPage },
        });

        if (props.previousPage) {
          localStorage.setItem(
            'ArticleBlogPageDetailsPreviousPage',
            props.previousPage
          );
        } else {
          localStorage.setItem('ArticleBlogPageDetailsPreviousPage', null);
        }
      }}
    >
      <img
        src={articleImage}
        alt="placeholder"
        className={styles.ArticleImage}
      />

      <div className={styles.ArticleInformation}>
        <div className={styles.ArticleTags}>
          <div className={styles.ContainerTag}>
            <div className={styles.ArticleTypeTag}>
              <span>
                {LPTranslate('AAM_Form_1_Input_Description_Reading_Time_1')}
              </span>
            </div>
            <span className={styles.ArticleReadTime}>
              {readingTime(article.content)}
              {LPTranslate('AAM_Form_1_Input_Description_Reading_Time_2')}
            </span>
          </div>

          <div>
            <EditLawyerArticleSectionDotsMenu
              editArticle={handleEditArticleButton}
              deleteArticle={deleteArticleHandler}
              article={article}
              link={article.link}
            />
          </div>
        </div>

        <div className={styles.ArticleTitle}>
          <span>{article.title}</span>
        </div>

        <div className={styles.ArticleDescription}>
          <span>{article.description}</span>
        </div>

        <div className={styles.ArticleAuthor}>
          <img
            src={profileImage ? profileImage : AvatarProfilePlaceholder}
            alt="placeholder"
            className={styles.ArticleAuthorImage}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className={styles.AuthorName}>{article.author_name}</span>
            <span className={styles.ArticleDate}>
              {`${milisecondsToDDMMYYYY(article.createdAt)}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditArticleCard;
