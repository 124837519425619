import React, { useState } from 'react';

import Calendar from 'Components/Calendar/Calendar';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/lawyer/LawyerCalendarSettingsPage.module.scss';

const CalendarComponent = (props) => {
  const [currentDay, setCurrentDay] = useState(
    props.startDate !== undefined ? props.startDate : new Date()
  );
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  const [hoverFirstInput, setHoverFirstInput] = useState(true);
  const [hoverSecondInput, setHoverSecondInput] = useState(true);
  const [nextMonth, setnextMonth] = useState(
    new Date(currentDay.getFullYear(), currentDay.getMonth() + 1, 1)
  );

  const months = [
    LPTranslate('Anwado_January'),
    LPTranslate('Anwado_February'),
    LPTranslate('Anwado_March'),
    LPTranslate('Anwado_April'),
    LPTranslate('Anwado_May'),
    LPTranslate('Anwado_June'),
    LPTranslate('Anwado_July'),
    LPTranslate('Anwado_August'),
    LPTranslate('Anwado_September'),
    LPTranslate('Anwado_October'),
    LPTranslate('Anwado_November'),
    LPTranslate('Anwado_December'),
  ];

  const handleChangeDate = async (whichDate) => {
    let startDateAux = startDate;
    let endDateAux = endDate;

    if (whichDate === 'firstDate') {
      endDateAux = endDate;
      setStartDate(endDate);
      setEndDate();
    } else if (whichDate === 'lastDate') {
      setStartDate(startDate);
      setEndDate();
    }

    var selectedElements = document.getElementsByClassName(
      'calendar-day current'
    );

    for (let i = 0; i < selectedElements.length; i++) {
      if (
        selectedElements[i].style.background !== '' ||
        selectedElements[i].classList.contains('selected')
      ) {
        selectedElements[i].style.background = '#FFFFFF';
      }

      if (
        whichDate === 'firstDate' &&
        startDateAux !== undefined &&
        parseInt(selectedElements[i].innerText) ===
          parseInt(startDateAux.getDate())
      ) {
        selectedElements[i].classList.remove('selected');

        let navTabs =
          document.getElementsByClassName('calendar-body')[1].lastChild;
        for (let i = 0; i < navTabs.children.length; i++) {
          if (
            parseInt(
              navTabs.children[i].getAttribute('aria-label').split(' ')[1]
            ) === parseInt(startDateAux.getMonth() + 1) &&
            parseInt(startDate.getMonth() + 1) !==
              parseInt(startDateAux.getMonth() + 1)
          ) {
            await handleNextByStartDate(startDate);

            selectedElements = document.getElementsByClassName(
              'calendar-day current'
            );
            for (let i = 0; i < selectedElements.length; i++) {
              if (
                parseInt(selectedElements[i].innerText) ===
                  parseInt(startDateAux.getDate()) &&
                parseInt(
                  selectedElements[i].getAttribute('aria-label').split(' ')[1]
                ) === parseInt(startDateAux.getMonth() + 1)
              ) {
                selectedElements[i].classList.add('selected');
              } else {
                selectedElements[i].classList.remove('selected');
              }
            }
          }
        }
      } else if (
        whichDate === 'lastDate' &&
        endDateAux !== undefined &&
        parseInt(selectedElements[i].innerText) ===
          parseInt(endDateAux.getDate())
      )
        selectedElements[i].classList.remove('selected');
    }
  };

  const handleChanges = () => {
    props.handleVacation(startDate, endDate, props.index);
    props.handleStartDate(startDate, props.index);
    props.handleEndDate(endDate, props.index);
    props.handleState(0);
    // props.handleIndex(props.index);
  };

  const handleNextByStartDate = (day) => {
    const previousMonth = new Date(day.getFullYear(), day.getMonth() + 1, 1);
    const nextMonth = new Date(
      day.getFullYear(),
      previousMonth.getMonth() + 1,
      1
    );
    setCurrentDay(previousMonth);
    setnextMonth(nextMonth);
  };

  return (
    <div className={styles['tables']}>
      <div className={styles['date-pickers']}>
        <Calendar
          calendarArr={props.calendarArr}
          months={months}
          calendarNumber={1}
          currentDay={currentDay}
          nextMonth={nextMonth}
          setCurrentDay={setCurrentDay}
          setnextMonth={setnextMonth}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </div>
      <div className={styles['horizontal-divider']} />

      <div className={styles['bottom-panel']}>
        <div className={styles['input-fields']}>
          <input
            data-cy="start-date-input"
            className={styles['start-date-input']}
            style={{
              outline:
                hoverFirstInput && startDate === undefined
                  ? '1px solid red'
                  : '1px solid #7f56d9',
            }}
            type="text"
            value={
              startDate !== undefined
                ? months[startDate.getMonth()].substring(0, 3) +
                  ' ' +
                  startDate.getDate() +
                  ', ' +
                  startDate.getFullYear()
                : 'MMM dd, yyyy'
            }
            placeholder=""
            readOnly={true}
            onClick={() => {
              handleChangeDate('firstDate');
              setHoverFirstInput(true);
            }}
          />

          <p className={styles['separator-line']}>–</p>

          <input
            data-cy="end-date-input"
            className={styles['end-date-input']}
            style={{
              outline:
                hoverSecondInput && endDate === undefined
                  ? '1px solid red'
                  : '1px solid #7f56d9',
            }}
            type="text"
            value={
              endDate !== undefined
                ? months[endDate.getMonth()].substring(0, 3) +
                  ' ' +
                  endDate.getDate() +
                  ', ' +
                  endDate.getFullYear()
                : 'MMM dd, yyyy'
            }
            placeholder=""
            readOnly={true}
            onClick={() => {
              handleChangeDate('lastDate');
              setHoverSecondInput(true);
            }}
          />
        </div>

        <div className={styles['actions']}>
          <button
            data-cy="calendar-cancel-button"
            className={styles['cancel-button']}
            onClick={() => props.handleState(0)}
          >
            <p className={styles['cancel-text']}>
              {LPTranslate('Button_Cancel')}
            </p>
          </button>

          {(startDate === undefined && endDate === undefined) ||
          (startDate !== undefined && endDate === undefined) ? (
            <button
              data-cy="calendar-submit-button"
              className={styles['send-data-button']}
              disabled
              style={{ background: 'gray', border: 'none' }}
            >
              <p className={styles['send-data-text']}>
                {LPTranslate('Button_Save')}
              </p>
            </button>
          ) : (
            <button
              data-cy="calendar-submit-button"
              className={styles['send-data-button']}
              onClick={handleChanges}
            >
              <p className={styles['send-data-text']}>
                {LPTranslate('Button_Save')}
              </p>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CalendarComponent;
