import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import AddLawyerReviewTextfield from 'Components/Lawyer/AddLawyerReviewTextfield';
import RatingStars from 'Components/RatingStars/RatingStars';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/lawyer/DeleteReviewModal.scss';

export default function DeleteReviewModal(props) {
  const { review } = props;

  const [deleteText, setDeleteText] = useState('');

  return (
    <div>
      <Modal open={props.open} onClose={props.handleClose}>
        <Box className="lawp-delete-review-modal-container">
          <p className="lawp-delete-review-modal-title">
            {LPTranslate('RSD_Title')}
          </p>

          <div className="lawp-delete-review-modal-rating-container">
            <RatingStars
              value={Number(review.lawyer.rating)}
              width={20}
              height={20}
              readonly={true}
              colorFilter={
                'invert(91%) sepia(69%) saturate(1377%) hue-rotate(317deg) brightness(99%) contrast(102%)'
              }
            />
            <Box className="lawp-delete-review-modal-rating-text">
              {Number(review.lawyer.rating)}
            </Box>

            <Box className="lawp-delete-review-modal-rating-reviews">
              {review.lawyer.review_count === 1 &&
              review.lawyer.review_count !== 0
                ? `${LPTranslate('LPP_Header_From')} ${
                    review.lawyer.review_count
                  } ${LPTranslate('Common_Review')}`
                : `${LPTranslate('LPP_Header_From')} ${
                    review.lawyer.review_count
                  } ${LPTranslate('Common_Reviews')}`}
            </Box>
          </div>

          <div className="lawp-delete-review-modal-lawyer-description">
            <p className="lawp-delete-review-modal-lawyer-description-text">
              {review.content}
            </p>
          </div>

          <div className="lawp-delete-review-modal-textfield-container">
            <AddLawyerReviewTextfield
              data_cy="description-input"
              title={LPTranslate('Input_Specify_Reason')}
              onChange={(value) => setDeleteText(value)}
              value={deleteText}
              textFieldWidthPercent={100}
              placeholder={LPTranslate('ARM_Input_2_Placeholder')}
              multiline
              rows={4}
            />
          </div>

          <div className="lawp-delete-review-modal-button-container">
            <Button
              data-cy="cancel-button"
              className="lawp-delete-review-modal-cancel-button"
              onClick={() => {
                props.handleClose();
              }}
            >
              {LPTranslate('Button_Cancel')}
            </Button>
            <Button
              data-cy="submit-button"
              className="lawp-delete-review-modal-submit-button"
              onClick={() => {
                props.onDelete(review.id, deleteText);
              }}
              form="update-form"
            >
              {LPTranslate('Button_Delete')}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
