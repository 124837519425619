import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';

import { GET_REVIEW_RESPONSE } from 'Services/Queries/lawyer';
import RatingStars from 'Components/RatingStars/RatingStars';
import SimpleTextField from 'Components/Inputs/SimpleTextField';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { milisecondsToMMMDDYYYY } from 'Services/Utils/moment';

import 'Assets/styles/lawyer/ReviewReportModal.scss';

export default function ReviewResponseModal(props) {
  const [response, setResponse] = React.useState('');

  const { data } = useQuery(GET_REVIEW_RESPONSE, {
    variables: {
      id: props.review.id,
    },
  });

  React.useEffect(() => {
    if (data) setResponse(data.getReviewResponse.response_message);
  }, [data]);

  const handleClose = () => {
    props.handleClose();
  };

  const handleSubmit = () => {
    props.handleSubmit(response);
    handleClose();
  };

  return (
    <Modal open={props.reviewModalOpen} onClose={handleClose}>
      <Box className="lawp-report-report-modal-container">
        <h2 className="title-date">
          {LPTranslate('RS_Rating_From')}{' '}
          {milisecondsToMMMDDYYYY(props.review.createdAt)}
        </h2>

        <div className="review-card">
          <RatingStars
            value={props.review.rating}
            width={20}
            height={20}
            readonly={true}
            colorFilter={
              'invert(91%) sepia(69%) saturate(1377%) hue-rotate(317deg) brightness(99%) contrast(102%)'
            }
          />

          <p className="client-name">{props.review.client_name}</p>

          <p className="review-content">"{props.review.content}"</p>
        </div>

        <SimpleTextField
          data_cy="response-description-input"
          title={LPTranslate('RS_Report_a_Review')}
          value={response}
          onChange={(value) => setResponse(value)}
          placeholder={LPTranslate('RS_Response_Input_Placeholder')}
          textFieldWidthPercent={100}
          multiline={true}
          rows={2}
          hasHintText={true}
          hintText={LPTranslate('RS_Response_Input_Hint')}
        />

        <div className="button-container">
          <Button
            data-cy="response-cancel-button"
            className="cancel-button"
            onClick={() => handleClose()}
          >
            {LPTranslate('Button_Close')}
          </Button>
          <Button
            data-cy="response-submit-button"
            className="submit-button"
            onClick={handleSubmit}
          >
            {LPTranslate('RS_Submit_Report')}
          </Button>
        </div>

        <p className="bottom-text">
          {LPTranslate('RS_Rating_Not_True')}{' '}
          <span
            data-cy="response-report-button"
            className="link-text"
            onClick={() => props.handleReportNowButton()}
          >
            {LPTranslate('RS_Report_Now')}
          </span>
        </p>
      </Box>
    </Modal>
  );
}
