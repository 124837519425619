import styles from 'Assets/styles/lawyer/SubscriptionModal/SubscriptionModalCard.module.scss';

import purpleCheckMark from 'Assets/pictures/purpleCheckMark.svg';
const SubscriptionModalCardBenefiets = (props) => {
  const { checkDetails } = props;
  return (
    <div className={styles['card-content-check-items-text']}>
      <div className={styles['card-content-check-items-text-check']}>
        <div className={styles['card-content-check-items-text-checkIcon']}>
          <img src={purpleCheckMark} alt="purple check" />
        </div>
      </div>
      <div className={styles['card-content-check-items-text-wrap']}>
        <div className={styles['card-content-check-items-text-wrapText']}>
          {checkDetails}
        </div>
      </div>
    </div>
  );
};
export default SubscriptionModalCardBenefiets;
