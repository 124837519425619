import React, { useState, useEffect } from 'react';

import { useParams, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import SettingsMenu from 'Components/CommonComponents/SettingsMenu';
import EditSaveChangesSection from 'Components/Lawyer/Edit/EditSaveChangesSection';
import UpdateLawyerDetailsSection from 'Components/Lawyer/UpdateDetails/UpdateLawyerDetailsSection';

import { VERIFY_PARAM_TOKEN_MUTATION } from 'Services/Queries/user';
import { UPDATE_LAWYER_EMAIL_ADDRESS } from 'Services/Queries/lawyer';
import { VERIFY_USER_PASSWORD } from 'Services/Queries/common';
import { REFRESH_TOKEN } from 'Services/Queries/user';
import { parseJwt } from 'Services/Utils/token';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { getTokenInfo, removeTokenInfo } from 'Services/Utils/token';
import { addAll } from 'Services/Redux/reducers/clientInfo';

import FinishUpdateLawyerDetails from 'Components/Lawyer/UpdateDetails/FinishUpdateLawyerDetails';

import styles from 'Assets/styles/updateLawyerDetails/UpdateLawyerDetailsPage.module.scss';

const EmailPasswordChange = () => {
  const [verifyUserPassword] = useMutation(VERIFY_USER_PASSWORD);
  const [updateLawyerEmailAddress] = useMutation(UPDATE_LAWYER_EMAIL_ADDRESS);
  const [verifyParamToken, { data, loading }] = useMutation(
    VERIFY_PARAM_TOKEN_MUTATION
  );
  const [refreshToken] = useMutation(REFRESH_TOKEN, {
    fetchPolicy: 'network-only',
  });
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);
  const { token } = useParams();
  const navigate = useNavigate();
  const [tokenAccepted, setTokenAccepted] = useState(false);
  const [stepState, setStepState] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [check, setCheck] = useState(false);
  const [openModals, setOpenModals] = useState(false);
  const [error] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const verify = async () => {
      if (token) {
        try {
          await verifyParamToken({
            variables: { input: { token: token, type: 'update details' } },
          });
        } catch (error) {
          console.log(error);
        }
      }
    };

    verify();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    const verify = async () => {
      if (data && data.verifyParamTokenMutation) {
        if (data.verifyParamTokenMutation.status === 'error') {
          alert(data.verifyParamTokenMutation.error);
          navigate('/updateLawyerDetails');
        } else if (data.verifyParamTokenMutation.message === 'Token accepted') {
          try {
            let lawyer = await updateLawyerEmailAddress({
              variables: {
                firstName: parseJwt(token).firstname,
                lastName: parseJwt(token).lastname,
                actualEmail: parseJwt(token).actualEmail,
                newEmail: parseJwt(token).newEmail,
              },
            });

            if (lawyer.data.updateLawyerEmailAddress.message) {
              setTokenAccepted(true);
              setStepState(1);
            } else if (lawyer.data.updateLawyerEmailAddress.error) {
              alert(lawyer.data.updateLawyerEmailAddress.error);
            }

            //aici trebuie sa facem update la token instant?

            const tokenInfo = getTokenInfo();
            const tokenInfoParsed = JSON.parse(tokenInfo);

            if (tokenInfoParsed) {
              let token = tokenInfoParsed.token;
              let refresh_token = tokenInfoParsed.refresh_token;

              if (!token || !refresh_token) {
                navigate('/login');
              }

              const response = await refreshToken({
                variables: {
                  input: {
                    token: token,
                    refresh_token: refresh_token,
                  },
                },
              });

              if (
                response.data.refreshToken &&
                response.data.refreshToken.status === '200'
              ) {
                let jwtToken = response.data.refreshToken.jwtToken;
                let refresh_token = response.data.refreshToken.refresh_token;

                const token_decoded = parseJwt(jwtToken);

                let tokenInfo = {
                  token: jwtToken,
                  refresh_token: refresh_token,
                  role: token_decoded.role,
                  email: token_decoded.email,
                  name: token_decoded.username,
                  first_name: token_decoded.first_name,
                  title: token_decoded.title,
                  last_name: token_decoded.last_name,
                  user_id: parseInt(token_decoded.user_id),
                  role_id: parseInt(token_decoded.role_id),
                };

                removeTokenInfo();
                localStorage.setItem(
                  'anwadoTokenInfo',
                  JSON.stringify(tokenInfo)
                );

                dispatch(addAll(tokenInfo));
              } else {
                removeTokenInfo();

                window.location.href = '/login';
              }
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    };
    verify();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const onSaveChanges = async () => {
    try {
      let lawyer = await verifyUserPassword({
        variables: {
          firstName: clientInfoRedux.first_name,
          lastName: clientInfoRedux.last_name,
          email: clientInfoRedux.email,
          password: password,
        },
      });

      if (lawyer.data.verifyUserPassword.message) {
        setOpenModals(true);
      } else if (lawyer.data.verifyUserPassword.error) {
        setPasswordError(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {stepState === 0 && (
        <div className={styles['container']}>
          <div className={styles['edit-lawyer-top-menu']}>
            <SettingsMenu
              setUpdateComponent={'/updateClientCredentials'}
              role={clientInfoRedux.role}
            />
            <div className={styles['Divider']} />
          </div>

          <div className={styles.EditSaveChangesSection}>
            <EditSaveChangesSection
              pageTitle={LPTranslate('SIS_Account_Title')}
              email={email}
              openModals={openModals}
              setOpenModals={setOpenModals}
              onSaveChanges={onSaveChanges}
              setCheck={setCheck}
            />
          </div>

          <div className={styles['Divider']} />

          <UpdateLawyerDetailsSection
            setEmail={setEmail}
            setPassword={setPassword}
            check={check}
            error={error}
            passwordError={passwordError}
          />
        </div>
      )}

      {stepState === 1 && (
        <FinishUpdateLawyerDetails
          tokenAccepted={tokenAccepted}
          token={token}
        />
      )}
    </>
  );
};

export default EmailPasswordChange;
