import * as React from 'react';

import { useQuery } from '@apollo/client';

import CircularProgress from '@mui/material/CircularProgress';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { GET_LAWYERS_TOP_CATEGORIES } from 'Services/Queries/lawyer';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/lawyer/LawyerCategories.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const LawyerCategories = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const { data, loading, error } = useQuery(GET_LAWYERS_TOP_CATEGORIES);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className="lawp-lawyer-categories-container">
      <div className='content-container'>

      <h2>{LPTranslate('Common_Categories')}</h2>

      <Box sx={{ width: '100%' }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            className="lawp-lawyer-categories-tabs"
          >
            <Tab label={LPTranslate('Common_Locations')} {...a11yProps(0)} />
            <Tab label={LPTranslate('Common_Specialities')} {...a11yProps(1)} />
            <Tab label={LPTranslate('Common_Areas_Of_Law')} {...a11yProps(2)} />
          </Tabs>
        </AppBar>

        {data !== undefined && data.getLawyersTopCategories !== undefined && (
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <ul className="multi-column-list">
                {data.getLawyersTopCategories.cities.map((city, index) => (
                  <li key={index}>
                    <a href={`${process.env.REACT_APP_DNS_URI}/ort/${city}`}>
                      {city}
                    </a>
                  </li>
                ))}
              </ul>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <ul className="multi-column-list">
                {data.getLawyersTopCategories.areasOfLaw.map(
                  (areaOfLaw, index) => (
                    <li key={index}>
                      <a
                        href={`${
                          process.env.REACT_APP_DNS_URI
                        }/aol/${areaOfLaw.replace(/\s+/g, '-')}`}
                      >
                        {areaOfLaw}
                      </a>
                    </li>
                  )
                )}
              </ul>
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <ul className="multi-column-list">
                {data.getLawyersTopCategories.specialities.map(
                  (speciality, index) => (
                    <li key={index}>
                      <a
                        href={`${
                          process.env.REACT_APP_DNS_URI
                        }/aol/${speciality.replace(/\s+/g, '-')}`}
                      >
                        {speciality}
                      </a>
                    </li>
                  )
                )}
              </ul>
            </TabPanel>
          </SwipeableViews>
        )}

        {loading && <div className="loading-container"><CircularProgress color="secondary" /></div>}
      </Box>
      </div>
    </div>
  );
};

export default LawyerCategories;
