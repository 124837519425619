import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';

import useAlert from 'Services/Utils/hooks/useAlert';
import Button from '@mui/material/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextField } from '@material-ui/core';
import fileDownload from 'js-file-download';

import SettingsMenu from 'Components/CommonComponents/SettingsMenu';
import { DOWNLOAD_INVOICES_TIME_PERIOD } from 'Services/Queries/operator';

import 'Assets/styles/operator/OperatorStripeInvoicesPage.scss';

const OperatorStripeInvoicesPage = () => {
  const { setAlert } = useAlert();
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const [downloadInvoicesTimePeriod, { data, loading }] = useLazyQuery(
    DOWNLOAD_INVOICES_TIME_PERIOD
  );

  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  const handleStartDateChange = (date) => {
    setStart(date.getTime()); //Milliseconds since 1970
  };

  const handleEndDateChange = (date) => {
    setEnd(date.getTime());
  };

  const handleDownloadInvoices = () => {
    console.log(start, end);

    if (!start || !end || isNaN(start) || isNaN(end)) {
      setAlert('Please select a start and end date');
      return;
    }

    if (start > end) {
      setAlert('Start date must be before end date');
      return;
    }

    downloadInvoicesTimePeriod({
      variables: {
        input: {
          date_from: start.toString(),
          date_to: end.toString(),
        },
      },
    });
  };

  useEffect(() => {
    console.log(data);
    if (data && data.downloadInvoicesTimePeriod) {
      let bufferTest = Buffer.from(data.downloadInvoicesTimePeriod, 'base64');
      fileDownload(bufferTest, 'invoices.zip');
    }
  }, [data]);

  return (
    <>
      <h1>Operator Invoice Page</h1>

      <SettingsMenu
        setUpdateComponent={'/OperatorArticle'}
        role={clientInfoRedux.role}
      />

      <div className="lawp-operator-stripe-invoices-page-container">
        {/* Date Pickers */}
        <div className="date-pickers">
          <TextField
            label="Start Date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => handleStartDateChange(new Date(e.target.value))}
          />
          <TextField
            label="End Date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => handleEndDateChange(new Date(e.target.value))}
          />

          <Button
            className={'save-button'}
            onClick={handleDownloadInvoices}
            form="update-form"
            disabled={loading}
          >
            <CircularProgress
              size={24}
              color="inherit"
              style={{
                color: 'white',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
                visibility: loading ? 'visible' : 'hidden',
              }}
            />
            <span style={{ visibility: loading ? 'hidden' : 'visible' }}>
              Download Invoices
            </span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default OperatorStripeInvoicesPage;
