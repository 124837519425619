import React from 'react';

import styles from 'Assets/styles/lawyer/EditLawyerSectionInfo.module.scss';

const EditLawyerSectionInfo = (props) => {
  const {
    // subscription,
    title,
    text,
    titleFontSize,
    contentFontSize,
    titleLineHeight,
    contentLineHeight,
  } = props;

  // let avatarTagSubscriptionJSX = () => {
  //   let text;
  //   switch (subscription) {
  //     case 0:
  //       text = 'Unregistered';
  //       break;
  //     case 1:
  //       text = 'Basic';
  //       break;
  //     case 2:
  //       text = 'Gold';
  //       break;
  //     case 3:
  //       text = 'Platinum';
  //       break;
  //     default:
  //       text = 'Unregistered';
  //       break;
  //   }

  //   if (subscription === 2 || subscription === 3) {
  //     return (
  //       <Box className={styles.avatarTag}>
  //         <img className={styles.avatarTagIcon} src={badge} alt="badge" />
  //         <Box className={styles.avatarTagText}>{text}</Box>
  //       </Box>
  //     );
  //   }
  // };

  let titleTextJSX = () => {
    // let member = '';
    // switch (subscription) {
    //   case 2:
    //     member = 'Gold Member';
    //     break;
    //   case 3:
    //     member = 'Platinum Member';
    //     break;
    //   default:
    //     break;
    // }

    return (
      <>
        <span
          className={styles.titleText}
          style={{
            fontSize: titleFontSize !== undefined ? `${titleFontSize}px` : {},
            lineHeight:
              titleLineHeight !== undefined ? `${titleLineHeight}px` : {},
          }}
        >
          {title}
        </span>
        {/* {member !== '' && (
          <span className={styles.titleTextSubscription}>{`(${member})`}</span>
        )} */}
      </>
    );
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.titleContainer}>{titleTextJSX()}</div>
        <div className={styles.contentContainer}>
          <span
            className={styles.contentText}
            style={{
              fontSize:
                contentFontSize !== undefined ? `${contentFontSize}px` : {},
              lineHeight:
                contentLineHeight !== undefined ? `${contentLineHeight}px` : {},
            }}
          >
            {text}
          </span>
        </div>
      </div>
    </>
  );
};

export default EditLawyerSectionInfo;
