import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

import AskLawyerToSetScheduleModal from 'Components/Modals/AskLawyerToSetScheduleModal';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/common/LawyerPageFaqFooter.scss';

const FaqFooterComponent = (props) => {
  let {
    faqsTitle,
    faqText,
    faqTextNotRegistered,
    clientInfoRedux,
    lawyer,
    reviewsNr,
    averageNr,
  } = props;

  const navigate = useNavigate();

  const [articleImage, setArticleImage] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [disabledDaysIndex, setDisabledDaysIndex] = useState(0);

  useEffect(() => {
    if (props.availability && props.availability.length !== 0) {
      let index = 0;

      for (let i = 0; i < props.availability.length; i++) {
        if (!props.availability[i].enabled) index++;
      }
      setDisabledDaysIndex(index);
    }
  }, [props.availability]);

  useEffect(() => {
    if (lawyer.profileImage) {
      setArticleImage(lawyer.profileImage);
    } else {
      setArticleImage(null);
    }
    return () => {
      setArticleImage(null);
    };
  }, [lawyer.profileImage]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  let enabledAppointmentButton = (
    availability,
    disabledDaysIndex,
    LawyerIsavailable,
    lawyerSubscription
  ) => {
    return (
      <button
        data-cy="faq-appointment-button"
        // className="faqsButton"
        className={
          props.availability &&
          props.availability.length !== 0 &&
          disabledDaysIndex < 7 &&
          lawyer.lawyerAvailability.show_availability &&
          lawyer.subscription > 1
            ? 'appointmentButton'
            : 'requestAppointmentButton'
        }
        onClick={() => {
          if (props.availability && props.availability.length !== 0) {
            if (
              disabledDaysIndex < 7 &&
              lawyer.lawyerAvailability.show_availability &&
              lawyer.subscription > 1
            ) {
              navigate('/setAppointment', {
                state: {
                  id: lawyer.id,
                  lawyer: lawyer,
                  reviewsNr: reviewsNr,
                  averageNr: averageNr,
                  profileImage: articleImage,
                  specialities: lawyer.specialities,
                  backButtonText: 'to Profil Overview',
                  availability: lawyer.lawyerAvailability,
                },
              });
            } else setOpenModal(true);
          } else {
            setOpenModal(true);
          }
        }}
      >
        {disabledDaysIndex < 7 &&
        lawyer.lawyerAvailability.show_availability &&
        lawyer.subscription > 1
          ? LPTranslate('LPP_FAQ_Action_button')
          : LPTranslate('Button_Make_Appointment_Request')}
      </button>
    );
  };

  let disabledAppointmentButton = (
    disabledDaysIndex,
    LawyerIsavailable,
    lawyerSubscription
  ) => {
    return (
      <Tooltip
        className="tooltip-medium-size"
        PopperProps={{
          disablePortal: true,
        }}
        title={LPTranslate('Error_Lawyer_Cannot_Create_Appointment')}
        placement="top"
        TransitionComponent={Zoom}
      >
        <button
          data-cy="faq-appointment-button"
          className="faqsButton"
          style={{
            background: 'gray',
            border: '1px solid gray',
            padding: '10px 18px',
            boxSizing: 'border-box',
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            borderRadius: '8px',
            margin: '0px 12px',
            color: '#FFF',
            fontSize: '14px',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            textTransform: 'none',
            width: '230px',
          }}
          variant="text"
        >
          {disabledDaysIndex < 7 && LawyerIsavailable && lawyerSubscription > 1
            ? LPTranslate('LPP_FAQ_Action_button')
            : LPTranslate('Button_Make_Appointment_Request')}
        </button>
      </Tooltip>
    );
  };

  var appointmentButton = () => {
    let LawyerIsavailable;

    if (clientInfoRedux.role === 'lawyer') {
      if (
        lawyer.lawyerAvailability.personally ||
        (lawyer.lawyerAvailability.by_phone && lawyer.telephone)
      )
        LawyerIsavailable = true;
      else LawyerIsavailable = false;

      return disabledAppointmentButton(
        disabledDaysIndex,
        LawyerIsavailable,
        lawyer.subscription
      );
    } else if (
      clientInfoRedux.role === 'client' ||
      clientInfoRedux.role === ''
    ) {
      if (
        lawyer.lawyerAvailability.personally ||
        (lawyer.lawyerAvailability.by_phone && lawyer.telephone)
      )
        LawyerIsavailable = true;
      else LawyerIsavailable = false;

      return enabledAppointmentButton(
        props.availability,
        disabledDaysIndex,
        LawyerIsavailable,
        lawyer.subscription
      );
    }
  };

  return (
    <div className="faqCardFooter">
      <div className="componentTextCardFooter">
        <h2 className="faqsFooterTitle">
          <>{`${faqsTitle}`}</>
        </h2>

        <h3 className="faqsFooterText">
          {lawyer.subscription > 0 ? (
            <>{`${faqText}`}</>
          ) : (
            <>
              {`${faqTextNotRegistered}`.replace(
                '<lawyer>',
                lawyer.first_name + ' ' + lawyer.last_name
              )}
            </>
          )}
        </h3>
      </div>
      {appointmentButton()}

      <AskLawyerToSetScheduleModal
        openModal={openModal}
        handleOpenModal={() => setOpenModal(false)}
        handleCloseSnackbar={handleCloseSnackbar}
        handleSnackbarMessage={(message) => setSnackbarMessage(message)}
        handleSnackbarSeverity={(type) => setSnackbarSeverity(type)}
        handleSnackbarOpen={() => setSnackbarOpen(true)}
        snackbarSeverity={snackbarSeverity}
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        profileImage={articleImage}
        title={lawyer.title}
        first_name={lawyer.first_name}
        last_name={lawyer.last_name}
        email={lawyer.email}
        subscription={lawyer.subscription}
      />
    </div>
  );
};

export default FaqFooterComponent;
