import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import ReviewReportModal from 'Components/Modals/ReviewReportModal';
import ReviewResponseModal from 'Components/Modals/ReviewResponseModal';
import ReviewCardPublicFullInfoModal from 'Components/Modals/ReviewCardPublicFullInfoModal';

import { createUrlSlug } from 'Services/Utils/metaData';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import showIcon from 'Assets/pictures/showIcon.svg';
import deleteIcon from 'Assets/pictures/deleteIcon.svg';
import updateRatingIcon from 'Assets/pictures/updateRatingIcon.svg';
import OutlinedFlagRoundedIcon from '@mui/icons-material/OutlinedFlagRounded';

import styles from 'Assets/styles/lawyer/EditLawyerArticleSectionDotsMenu.module.scss';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 220,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function ReviewCardMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [reviewFullInfoModalOpen, setReviewFullInfoModalOpen] = useState(false);
  const [reviewReportModalOpen, setReviewReportModalOpen] = useState(false);
  const [reviewResponseModalOpen, setReviewResponseModalOpen] = useState(false);

  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (index, data) => {
    setAnchorEl(null);

    switch (index) {
      case 'view':
        setReviewFullInfoModalOpen(true);
        break;
      case 'profile':
        if (props.CardType === 'client') return;

        let slug = createUrlSlug(
          '',
          props.review.lawyer.first_name,
          props.review.lawyer.last_name
        );

        slug += `-${props.review.lawyer_id}`;
        navigate(`/anwaelte/${slug}`);

        break;
      case 'update':
        if (props.CardType === 'client') return;
        props.onUpdatePress();
        break;
      case 'delete':
        if (props.CardType === 'client') return;
        props.onDeletePress();
        break;
      case 'report':
        if (props.CardType !== 'client') return;
        props.onReportPress(data);
        break;
      case 'response':
        if (props.CardType !== 'client') return;
        props.onResponsePress(data);
        break;
      default:
        break;
    }
  };

  const handleReportNowResponseModalPress = () => {
    setReviewReportModalOpen(true);
    setReviewResponseModalOpen(false);
  };

  return (
    <div>
      <IconButton
        data-cy="three-dots-button"
        aria-label="delete"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          data-cy="view-button"
          onClick={() => handleClose('view')}
          disableRipple
        >
          <VisibilityOutlinedIcon className={styles.icon} />
          <span className={styles.text}>{LPTranslate('Common_View')}</span>
        </MenuItem>

        {props.CardType !== 'client' && (
          <MenuItem
            data-cy="lawyer-profile-button"
            onClick={() => handleClose('profile')}
            disableRipple
          >
            <img src={showIcon} alt="editIcon" className={styles.icon} />
            <span className={styles.text}>
              {LPTranslate('Common_To_Profile')}
            </span>
          </MenuItem>
        )}
        {props.CardType !== 'client' && (
          <MenuItem
            data-cy="update-button"
            onClick={() => handleClose('update')}
            disableRipple
          >
            <img
              src={updateRatingIcon}
              alt="editIcon"
              className={styles.icon}
            />
            <span className={styles.text}>{LPTranslate('RS_Update')}</span>
          </MenuItem>
        )}

        {props.CardType === 'client' && (
          <MenuItem
            data-cy="respond-button"
            onClick={() => setReviewResponseModalOpen(true)}
            disableRipple
          >
            <MailOutlineRoundedIcon className={styles.icon} />

            <span className={styles.text}>{LPTranslate('RS_Respond')}</span>
          </MenuItem>
        )}

        {props.CardType === 'client' && (
          <MenuItem
            data-cy="report-button"
            onClick={() => setReviewReportModalOpen(true)}
            disableRipple
          >
            <OutlinedFlagRoundedIcon className={styles.icon} />

            <span className={styles.text}>{LPTranslate('RS_Report')}</span>
          </MenuItem>
        )}

        <div className={styles.divider} />

        {props.CardType !== 'client' && (
          <MenuItem
            data-cy="delete-button"
            onClick={() => handleClose('delete')}
            disableRipple
          >
            <img src={deleteIcon} alt="copy" className={styles.icon} />
            <span className={styles.text}>{LPTranslate('RS_Delete')}</span>
          </MenuItem>
        )}
      </StyledMenu>

      {/* TODO: add modal view all */}
      <ReviewCardPublicFullInfoModal
        review={props.review}
        lawyerImage={props.lawyerImage}
        clientName={props.review.client_name}
        show={reviewFullInfoModalOpen}
        onClose={() => setReviewFullInfoModalOpen(false)}
      />

      <ReviewResponseModal
        review={props.review}
        reviewModalOpen={reviewResponseModalOpen}
        handleClose={() => setReviewResponseModalOpen(false)}
        handleSubmit={(response) => handleClose('response', response)}
        handleReportNowButton={() => handleReportNowResponseModalPress()}
      />

      <ReviewReportModal
        review={props.review}
        reviewModalOpen={reviewReportModalOpen}
        handleClose={() => setReviewReportModalOpen(false)}
        handleSubmit={(report) => handleClose('report', report)}
      />
    </div>
  );
}
