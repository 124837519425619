import * as React from 'react';

import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { AreasOfLaw_Color } from 'Services/Utils/areaOfLaws';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 300,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const AreasOfLawDropdown = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const AreaOfLawTagJSX = (value, key) => {
    return (
      <div
        className="lawp-add-lawyer-review-modal-area-of-law-tag-container"
        style={{
          color: AreasOfLaw_Color[value]?.color || 'black',
          backgroundColor: AreasOfLaw_Color[value]?.background || 'white',
          margin: 0,
        }}
        key={key}
      >
        <span className="lawp-add-lawyer-review-modal-area-of-law-tag-text">
          {value}
        </span>
      </div>
    );
  };

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        onClick={handleClick}
        data-cy={'expand-list-button'}
        sx={{
          margin: '0px 0px',
          backgroundColor: '#f9f5ff',
          padding: '0px',
          boxShadow: 'none',
          borderRadius: '50%',
          maxWidth: '35px',
          maxHeight: '35px',
          minWidth: '35px',
          minHeight: '35px',
          color: '#6941c6',
          fontFamily: 'Inter',
          '&:hover': { backgroundColor: 'transparent' },
        }}
      >
        +{props.areasOfLaw.length - props.areasOfLawOverflowIndexStart}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        PaperProps={{
          style: {
            maxHeight: 400,
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {props.areasOfLaw.map((areaOfLaw, key) => {
          if (key >= props.areasOfLawOverflowIndexStart) {
            return (
              <MenuItem
                key={key}
                disableRipple
                onClick={handleClose}
                sx={{
                  fontSize: '16px',
                  fontFamily: 'Inter',
                  fontWeight: '500',
                }}
              >
                {AreaOfLawTagJSX(areaOfLaw)}
              </MenuItem>
            );
          } else {
            return null;
          }
        })}
      </StyledMenu>
    </>
  );
};

export default AreasOfLawDropdown;
