import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { CircularProgress } from '@mui/material';

import NewSubscriptionModalCard from 'Components/Subscription/NewSubscriptionModalCard';
import FAQExpandableSection from 'Components/help/FAQExpandableSection';
import SubscriptionFooterComponent from 'Components/Subscription/SubscriptionFooterComponent.js';
import MetaDecorator from 'Layouts/MetaDecorator';

import {
  CREATE_PAYMENT_SESSION,
  GET_SUBSCRIPTION_INFORMATION,
} from 'Services/Queries/stripe';
import subscriptionPageTextVariables from 'Services/TextVariables/subscriptionPageTextVariables.js';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { requireTokenUpdate } from 'Services/Utils/token';

import styles from 'Assets/styles/lawyer/SubscriptionModal/SubscriptionModal.module.scss';

let faqsDummyData = [
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_1'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_1'),
  },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_2'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_2'),
  },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_3'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_3'),
  },
  // {
  //   question: LPTranslate('Support_FAQ_Lawyer_Question_4'),
  //   answer: LPTranslate('Support_FAQ_Lawyer_Answer_4'),
  // },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_7'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_7'),
  },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_8'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_8_1'),
    answer_link: LPTranslate('Support_FAQ_Lawyer_Answer_8_2'),
  },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_9'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_9'),
  },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_10'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_10_1'),
    answer_link: LPTranslate('Support_FAQ_Lawyer_Answer_10_2'),
  },
  {
    question: LPTranslate('Support_FAQ_Lawyer_Question_11'),
    answer: LPTranslate('Support_FAQ_Lawyer_Answer_11_1'),
    answer_link: LPTranslate('Support_FAQ_Lawyer_Answer_11_2'),
  },
];

const NewSubscriptionProcessModal = (props) => {
  //TODO get lawyer subscription information
  // products; subscription type, recurring_interval, schedule
  const navigate = useNavigate();

  // prices, lawyer subscription(type, reccuringInterval), reccuringInterval,

  const [recurringInterval, setRecurringInterval] = useState('year');
  const [lawyerSubscription, setLawyerSubscription] = useState({
    tier: 'silver',
    recurringInterval: 'month',
  });

  const [processInformation, setProcesInformation] = useState({
    schedule: {
      type: '',
      recurringInterval: '',
    },
  });
  const [DbPrices, setDbPrices] = useState([]);

  const percentage = subscriptionPageTextVariables.persentage;

  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const subscriptionInformation = useQuery(GET_SUBSCRIPTION_INFORMATION, {
    variables: { lawyer_id: clientInfoRedux.role_id },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [createPaymentSession] = useMutation(CREATE_PAYMENT_SESSION);

  const loading = subscriptionInformation.loading;

  useEffect(() => {
    if (subscriptionInformation.data) {
      setProcesInformation({
        schedule: {
          type: subscriptionInformation.data.stripeSubscriptionInformation
            .schedule.type,
          recurringInterval:
            subscriptionInformation.data.stripeSubscriptionInformation.schedule
              .recurring_interval,
        },
      });
      setDbPrices(
        subscriptionInformation.data.stripeSubscriptionInformation.products
      );
      setLawyerSubscription({
        tier: subscriptionInformation.data.stripeSubscriptionInformation
          .subscriptionType,
        recurringInterval:
          subscriptionInformation.data.stripeSubscriptionInformation
            .recurring_interval,
      });
      if (
        subscriptionInformation.data.stripeSubscriptionInformation
          .recurring_interval?.length > 0
      ) {
        setRecurringInterval(
          subscriptionInformation.data.stripeSubscriptionInformation
            .recurring_interval
        );
      }
    }
  }, [subscriptionInformation.data]);

  const handleCreatePaymentSession = async (payment_id) => {
    try {
      if (isLoading) return;

      setIsLoading(true);
      const CreatePaymentSessionData = await createPaymentSession({
        variables: {
          input: {
            payment_id: payment_id,
            email: clientInfoRedux.email,
            type: 'normal',
          },
        },
      });

      requireTokenUpdate();
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'lawyer-buy-subscription-custom-event',
      });

      window.location.href =
        CreatePaymentSessionData.data.createPaymentSession.session_url;

      setIsLoading(false);
    } catch (error) {
      console.log(error);

      setIsLoading(false);
    }
  };

  const handleRecurringInterval = (e, buttonPressed) => {
    switch (buttonPressed) {
      case 'month':
        const yearly = document.getElementById(
          'lawp-subscription-process-modal-year-button'
        );
        const badge = document.getElementById(
          'lawp-subscription-process-modal-year-button-badge'
        );
        if (yearly.classList.contains(styles['horizontal-tabs-year-active'])) {
          yearly.classList.remove(styles['horizontal-tabs-year-active']);
          badge.classList.remove(styles['horizontal-tabs-year-badge-active']);
        }
        if (
          !e.currentTarget.classList.contains(
            styles['horizontal-tabs-month-active']
          )
        ) {
          e.currentTarget.classList.add(styles['horizontal-tabs-month-active']);
          setRecurringInterval('month');
        }
        break;
      case 'year':
        const monthly = document.getElementById(
          'lawp-subscription-process-modal-month-button'
        );
        const badge2 = document.getElementById(
          'lawp-subscription-process-modal-year-button-badge'
        );
        if (
          !e.currentTarget.classList.contains(
            styles['horizontal-tabs-year-active']
          )
        ) {
          if (
            monthly.classList.contains(styles['horizontal-tabs-month-active'])
          ) {
            monthly.classList.remove(styles['horizontal-tabs-month-active']);
          }
          e.currentTarget.classList.add(styles['horizontal-tabs-year-active']);

          badge2.classList.add(styles['horizontal-tabs-year-badge-active']);
          setRecurringInterval('year');
        }
        break;

      default:
        break;
    }
  };

  const handleOpen = () => (window.location.href = '/paymentSettings');

  const switchButton = (type) => {
    var classes = '';
    switch (type) {
      case 'month':
        classes = `${styles['horizontal-tabs-month']}`;
        if (recurringInterval === type) {
          classes += ` ${styles['horizontal-tabs-month-active']}`;
        }
        return (
          <button
            id="lawp-subscription-process-modal-month-button"
            className={classes}
            onClick={(e) => handleRecurringInterval(e, 'month')}
          >
            {LPTranslate('LSUS_Switch_Month')}
          </button>
        );
      case 'year':
        classes = ` ${styles['horizontal-tabs-year']}`;
        var badgeClasses = ` ${styles['horizontal-tabs-year-badge']}`;

        if (recurringInterval === type) {
          classes += ` ${styles['horizontal-tabs-year-active']}`;
          badgeClasses += ` ${styles['horizontal-tabs-year-badge-active']}`;
        }
        return (
          <button
            id="lawp-subscription-process-modal-year-button"
            className={classes}
            onClick={(e) => handleRecurringInterval(e, 'year')}
          >
            <div className={styles['horizontal-tabs-year-text']}>
              {LPTranslate('LSUS_Switch_Year')}
            </div>
            <div
              id="lawp-subscription-process-modal-year-button-badge"
              className={badgeClasses}
            >
              -{percentage}
            </div>
          </button>
        );
      default:
        break;
    }
  };

  if (loading) {
    return (
      <>
        <div className="loading-overlay">
          <CircularProgress color="secondary" />
        </div>
      </>
    );
  }

  return (
    <>
      <MetaDecorator
        title={LPTranslate('LSUS_MetaDecorator_Title')}
        description={LPTranslate('LSUS_MetaDecorator_Description')}
        canonical={'canonical'}
        link={`${process.env.REACT_APP_DNS_URI}/subscriptionPage`}
      />

      <div className={styles['modal-body']}>
        <div className={styles['subscription-overlay']}>
          <div className={styles['subscription-modal-title-component']}>
            <div className={styles['subscription-modal-back-button']}>
              <Button
                className="lawp-article-blog-page-details-to-lawyer-profile-button"
                data-cy="back-button"
                onClick={(e) => {
                  navigate(-1);
                }}
                startIcon={<ArrowBackRoundedIcon />}
              >
                {LPTranslate('Button_Back')}
              </Button>
            </div>
            <div
              className={styles['subscription-modal-title-compoent-container']}
            >
              {' '}
              <div
                className={
                  styles['subscription-modal-title-compoent-container-content']
                }
              >
                <div className={styles['heading-and-supporting-text']}>
                  <div className={styles['heading-and-subheading']}>
                    <button
                      className={styles['subheading']}
                      onClick={handleOpen}
                    >
                      {LPTranslate('Common_Subscription_For_Lawyers')}
                    </button>
                    <div className={styles['heading']}>
                      {LPTranslate('LSS_Title')}
                    </div>
                  </div>
                </div>
                {/* recurring interval select buttons */}
                <div className={styles['header-horizontal-tabs']}>
                  {/* month button */}

                  {switchButton('month')}
                  {/* yearly button */}
                  {switchButton('year')}
                </div>
              </div>
            </div>
          </div>
          <div className={styles['subscription-card-section']}>
            <div className={styles['subscription-card-container']}>
              <div className={styles['subscription-card-content']}>
                {/* de afisat un nou subscription modal */}
                {DbPrices?.length > 0 ? (
                  <>
                    {DbPrices.map(
                      (price, index) =>
                        price.recurring_interval === recurringInterval && (
                          <NewSubscriptionModalCard
                            key={index}
                            price={price}
                            lawyerSubscription={lawyerSubscription}
                            recurringInterval={recurringInterval}
                            schedule={processInformation.schedule}
                            handlePayButton={handleCreatePaymentSession}
                          />
                        )
                    )}
                  </>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
          <div className="faq">
            <FAQExpandableSection questions={faqsDummyData} />
            <SubscriptionFooterComponent />
          </div>
        </div>
      </div>
    </>
  );
};
export default NewSubscriptionProcessModal;
