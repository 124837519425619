import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { createUrlSlug } from 'Services/Utils/metaData';

import editIcon from 'Assets/pictures/editIcon.svg';
import showIcon from 'Assets/pictures/showIcon.svg';
import copyLinkIcon from 'Assets/pictures/copyLinkIcon.svg';
import deleteIcon from 'Assets/pictures/deleteIcon.svg';

import styles from 'Assets/styles/lawyer/EditLawyerArticleSectionDotsMenu.module.scss';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 220,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function CustomizedMenus(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (index, event) => {
    event.stopPropagation();
    setAnchorEl(null);
    let slug = createUrlSlug(
      '',
      props.article.title,
      props.article.author_name,
      ''
    );

    slug += `-${props.article.id}`;

    switch (index) {
      case 'edit':
        props.editArticle();
        break;
      case 'delete':
        props.deleteArticle();
        break;
      case 'copy':
        navigator.clipboard.writeText(
          `${process.env.REACT_APP_DNS_URI}/rechtsinfos/${slug}`
        );
        break;
      case 'show':
        window.location.pathname = `rechtsinfos/${slug}`;
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <IconButton
        data-cy="three-dots-button"
        className={styles.menuIcon}
        aria-label="delete"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => handleClose('close', e)}
      >
        <MenuItem
          data-cy="edit-button"
          onClick={(e) => handleClose('edit', e)}
          disableRipple
        >
          <img src={editIcon} alt="editIcon" className={styles.icon} />
          <span className={styles.text}>{LPTranslate('Common_Edit')}</span>
        </MenuItem>
        <MenuItem
          data-cy="show-button"
          onClick={(e) => handleClose('show', e)}
          disableRipple
        >
          <img src={showIcon} alt="editIcon" className={styles.icon} />
          <span className={styles.text}>{LPTranslate('Common_Show')}</span>
        </MenuItem>

        <div className={styles.divider} />

        <MenuItem
          data-cy="copy-button"
          onClick={(e) => handleClose('copy', e)}
          disableRipple
        >
          <img src={copyLinkIcon} alt="copy" className={styles.icon} />
          <span className={styles.text}>{LPTranslate('Common_Copy_Link')}</span>
        </MenuItem>
        {/* <MenuItem onClick={handleClose} disableRipple>
          <img src={duplicateIcon} alt="editIcon" className={styles.icon} />
          <span className={styles.text}>Duplicate</span>
        </MenuItem> */}

        <div className={styles.divider} />

        <MenuItem
          data-cy="delete-button"
          onClick={(e) => handleClose('delete', e)}
          disableRipple
        >
          <img src={deleteIcon} alt="editIcon" className={styles.icon} />
          <span className={styles.text}>{LPTranslate('Common_Delete')}</span>
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
