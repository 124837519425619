import React from 'react';

import SimpleTextField from 'Components/Inputs/SimpleTextField';
import AvatarRowGroup from 'Components/AvatarRowGroup';
import RatingStars from 'Components/RatingStars/RatingStars';
import AutoCompleteTextFieldSingleSelected from 'Components/Lawyer/Edit/AutoCompleteFixedValuesSingleSelect';
import RegisterCheckboxComponent from 'Components/CommonComponents/RegisterCheckboxComponent';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import logo from 'Assets/pictures/registerLogo.svg';
import purpleLogo from 'Assets/pictures/registerPurpleLogo.svg';

import styles from 'Assets/styles/lawyer/RegisterLawyer.module.scss';

const RegisterLawyerForm = (props) => {
  const {
    handleSubmit,
    handleInputChange,
    setFormErrors,
    setTermsAccepted,
    salutationData,
    formValues,
    formErrors,
    termsAccepted,
    navigate,
    errorMessage,
    isSentFromLawyerPage,
    marketingAccepted,
    setMarketingAccepted,
  } = props;

  return (
    <div className={styles.signUp}>
      <div className={styles.leftSection}>
        <div className={styles.container}>
          <div className={styles.content}>
            <img className={styles.registerLawyerLogo} src={logo} alt="logo" />

            <div className={styles.textAndSupportingText}>
              <h1 className={styles.text}>
                {LPTranslate('RL_Photo_Content_title')}
              </h1>

              <h2 className={styles.supportingText}>
                {LPTranslate('RL_Photo_Content_Description_1')}
              </h2>
            </div>

            <div className={styles.lawyersContainer}>
              <div className={styles.avatarGroup}>
                <AvatarRowGroup />
              </div>
              <div className={styles.ratingContainer}>
                <div className={styles.starsContainer}>
                  <RatingStars
                    value={5}
                    width={20}
                    height={20}
                    readonly={true}
                    colorFilter={
                      'invert(91%) sepia(69%) saturate(1377%) hue-rotate(317deg) brightness(99%) contrast(102%)'
                    }
                  />
                </div>
                <div className={styles.reviewsContainer}>
                  <h3 className={styles.reviewsText}>
                    {LPTranslate('RL_Photo_Content_Lawyers_Description')}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.backgroundOverlay} />
      </div>

      <div className={styles.rightSection}>
        <div className={styles.rightSectionContainer}>
          <div className={styles.rightSectionContent}>
            <div className={styles.rightSectionTextAndSupportingText}>
              <img
                className={styles.registerLawyerPurpleLogo}
                src={purpleLogo}
                alt="purple logo"
              />
              <h2 className={styles.rightSectionText}>
                {LPTranslate('RL_Form_Title')}
              </h2>
              <h3 className={styles.rightSectionSupportingText}>
                {LPTranslate('RL_Form_Description')}
              </h3>
            </div>

            <div className={styles.rightSectionInputs}>
              <form
                className={styles.rightSectionForm}
                id="register-form"
                onSubmit={handleSubmit}
              >
                <div className={styles.registerLawyerOneThirdSection}>
                  <div style={{ width: '145px', marginRight: '20px' }}>
                    <AutoCompleteTextFieldSingleSelected
                      title={LPTranslate('Input_Salutation')}
                      onChange={(value) => {
                        handleInputChange('salutation', value);
                        setFormErrors({
                          ...formErrors,
                          salutation: '',
                        });
                      }}
                      placeholder={LPTranslate('Input_Salutation')}
                      selected={formValues.salutation}
                      dataArray={salutationData}
                      textFieldWidthPercent={100}
                      errorMessage={formErrors.salutation}
                      handleInputChange={handleInputChange}
                      disabled={isSentFromLawyerPage}
                    />
                  </div>

                  <div
                    style={
                      formErrors.salutation
                        ? {
                            width: '96px',
                            marginRight: '20px',
                            marginBottom: '25px',
                          }
                        : { width: '96px', marginRight: '20px' }
                    }
                  >
                    <SimpleTextField
                      data_cy="prefix-title-input"
                      title={LPTranslate('Input_Title')}
                      onChange={(value) => handleInputChange('title', value)}
                      value={formValues.title}
                      textFieldWidthPercent={100}
                      disabled={isSentFromLawyerPage}
                    />
                  </div>
                </div>

                <div className={styles.firstNameInputField}>
                  <div className={styles.inputWithLabel}>
                    <SimpleTextField
                      data_cy="first-name-input"
                      title={LPTranslate('Input_First_Name')}
                      placeholder={LPTranslate('Input_First_Name_Placeholder')}
                      onChange={(value) => {
                        handleInputChange('firstName', value);
                        setFormErrors({
                          ...formErrors,
                          firstName: '',
                        });
                      }}
                      value={formValues.firstName}
                      type="text"
                      errorMessage={formErrors.firstName}
                      disabled={isSentFromLawyerPage}
                    />
                  </div>
                </div>

                <div className={styles.lastNameInputField}>
                  <div className={styles.inputWithLabel}>
                    <SimpleTextField
                      data_cy="last-name-input"
                      title={LPTranslate('Input_Last_Name')}
                      placeholder={LPTranslate('Input_Last_Name_Placeholder')}
                      onChange={(value) => {
                        handleInputChange('lastName', value);
                        setFormErrors({
                          ...formErrors,
                          lastName: '',
                        });
                      }}
                      value={formValues.lastName}
                      type="text"
                      errorMessage={formErrors.lastName}
                      disabled={isSentFromLawyerPage}
                    />
                  </div>
                </div>

                <div className={styles.emailInputField}>
                  <div className={styles.inputWithLabel}>
                    <SimpleTextField
                      data_cy="email-address-input"
                      title="Email*"
                      placeholder={LPTranslate('Common_Email_Placeholder')}
                      onChange={(value) => {
                        handleInputChange('email', value);
                        setFormErrors({
                          ...formErrors,
                          email: '',
                        });
                      }}
                      value={formValues.email}
                      type="text"
                      errorMessage={formErrors.email}
                      disabled={isSentFromLawyerPage}
                    />
                  </div>
                </div>
              </form>

              <div className={styles.row}>
                <h3 className={styles.loginText}>
                  {LPTranslate('Common_Already_Have_Account_Question')}
                </h3>

                <div className={styles.loginButton}>
                  <button
                    data-cy="login-button"
                    className={styles.loginButtonBase}
                    onClick={() => navigate('/login')}
                  >
                    <h3 className={styles.loginButtonText}>
                      {LPTranslate('Common_Login_Now')}
                    </h3>
                  </button>
                </div>
              </div>

              <RegisterCheckboxComponent
                termsAccepted={termsAccepted}
                setTermsAccepted={setTermsAccepted}
                marketingAccepted={marketingAccepted}
                setMarketingAccepted={setMarketingAccepted}
              />

              <div className={styles.actions}>
                {errorMessage && (
                  <p className={styles['error-message']}>{errorMessage}</p>
                )}
                {termsAccepted ? (
                  <button
                    data-cy="submit-button"
                    className={styles.registerButton}
                    type="submit"
                    form="register-form"
                  >
                    <h3 className={styles.buttonText}>
                      {LPTranslate('Button_Create_Account')}
                    </h3>
                  </button>
                ) : (
                  <button
                    disabled
                    className={styles.disabledButton}
                    data-cy="submit-button"
                  >
                    <h3 className={styles.buttonText}>
                      {LPTranslate('Button_Create_Account')}
                    </h3>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterLawyerForm;
