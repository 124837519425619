import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

const registerSteps = [
  {
    label: LPTranslate('RL_Stepper_1_Title'),
    description: LPTranslate('RL_Stepper_1_Description'),
    isChecked: false,
  },
  {
    label: LPTranslate('RL_Stepper_2_Title'),
    description: LPTranslate('RL_Stepper_2_Description'),
    isChecked: false,
  },
  {
    label: LPTranslate('RL_Stepper_3_Title'),
    description: LPTranslate('RL_Stepper_3_Description'),
    isChecked: false,
  },
  {
    label: LPTranslate('RL_Stepper_4_Title'),
    description: LPTranslate('RL_Stepper_4_Description'),
    isChecked: false,
  },
  {
    label: LPTranslate('RL_Stepper_5_Title'),
    description: LPTranslate('RL_Stepper_5_Description'),
    isChecked: false,
  },
  {
    label: LPTranslate('RL_Stepper_6_Title'),
    description: LPTranslate('RL_Stepper_6_Description'),
    isChecked: false,
  },
  {
    label: LPTranslate('RL_Stepper_7_Title'),
    description: LPTranslate('RL_Stepper_7_Description'),
    isChecked: false,
  },
  {
    label: LPTranslate('RL_Stepper_8_Title'),
    description: LPTranslate('RL_Stepper_8_Description'),
    isChecked: false,
  },
];

export default registerSteps;
