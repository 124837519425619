import React, { useEffect } from 'react';

import { useNavigate } from 'react-router';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import jwt from 'jwt-decode';

import SimpleTextField from 'Components/Inputs/SimpleTextField';

import { SET_APPOINTMENT } from 'Services/Queries/client';
import { MINI_REGISTER } from 'Services/Queries/user';
import { validateEmail } from 'Services/Utils/validations';
import { tranformToCalendarTimestamp } from 'Services/Utils/calendar/calendar';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import arrowLeft from 'Assets/pictures/purple-left-arrow.svg';

import 'Assets/styles/lawyer/SetMeetingNameBetweenClientAndLawyerModal.scss';
import RegisterCheckboxComponent from 'Components/CommonComponents/RegisterCheckboxComponent';

const SetMeetingNameBetweenClientAndLawyerModal = (props) => {
  const {
    nextStepDate,
    nextStepHour,
    detailsFormValues,
    meetingVal,
    formValues,
    formErrors,
  } = props;

  const [setAppointment, { data: setAppointmentData }] =
    useMutation(SET_APPOINTMENT);
  const [
    miniRegister,
    { data: miniRegisterData, loading: miniRegisterLoading },
  ] = useMutation(MINI_REGISTER);

  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const [marketingAccepted, setMarketingAccepted] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState('');

  const navigate = useNavigate();

  const handleInputChange = (inputName, value) => {
    switch (inputName) {
      case 'firstName':
        props.setFormValues({ ...formValues, firstName: value });
        break;
      case 'lastName':
        props.setFormValues({ ...formValues, lastName: value });
        break;
      case 'email':
        props.setFormValues({ ...formValues, email: value });
        break;

      default:
        break;
    }
  };

  const callSetAppointment = async (token, token_decoded) => {
    const descriptionArr = detailsFormValues.description.split(/\n/g);
    let gmailDescription,
      outlookDescription,
      yahooDescription,
      icsDescription = '';
    for (let i = 0; i < descriptionArr.length; i++) {
      if (i === 0) {
        gmailDescription = '<div>' + descriptionArr[i] + '</div>\n';
        outlookDescription = '<div>' + descriptionArr[i] + '</div>\n';
        yahooDescription = descriptionArr[i];
        icsDescription = descriptionArr[i];
      } else {
        if (descriptionArr[i] === '') {
          gmailDescription += '<p></p>\n';
          outlookDescription += '<br>';
        } else gmailDescription += '<div>' + descriptionArr[i] + '</div>\n';
        outlookDescription += '<div>' + descriptionArr[i] + '</div>\n';
        yahooDescription += '%0d%0a' + descriptionArr[i];
        icsDescription += '\n' + descriptionArr[i];
      }
    }
    const hour = nextStepHour.split(':')[0];
    const minute = nextStepHour.split(':')[1];
    // const weekday = nextStepDate.split(',')[0];
    const dateString = nextStepDate.split(',')[1].split(' ')[1].split('.');
    const day = dateString[0];
    const month = dateString[1];
    const year = dateString[2];
    const dateMoment = moment(`${day} ${month} ${year}`, 'DD MM YYYY');
    let appointmentDateTimestamp = moment(dateMoment).format('X');
    let startAppointment = moment(dateMoment).set({
      hour: hour,
      minute: minute,
    });
    let endAppointment = moment(dateMoment)
      .set({
        hour: hour,
        minute: minute,
      })
      .add('30', 'minutes');
    let startAppointmentTimestamp =
      tranformToCalendarTimestamp(startAppointment);
    let endAppointmentTimestamp = tranformToCalendarTimestamp(endAppointment);
    let phoneNumber = '';
    if (detailsFormValues.phoneNumber)
      phoneNumber = detailsFormValues.phoneNumber;
    if (detailsFormValues.phoneNumber === null) phoneNumber = '';
    let meetingValString = '';
    if (meetingVal === 0)
      meetingValString = LPTranslate('LSA_Update_Appointment_Call');
    else if (meetingVal === 1)
      meetingValString = LPTranslate('LSA_Update_Appointment_Site_Meeting');

    let appointmentLocation = '';

    if (detailsFormValues && detailsFormValues.addressValue) {
      appointmentLocation =
        detailsFormValues.addressValue.street +
        ', ' +
        detailsFormValues.addressValue.city +
        ', ' +
        detailsFormValues.addressValue.zipcode +
        ', ' +
        detailsFormValues.addressValue.country;
    }

    try {
      await setAppointment({
        variables: {
          input: {
            token: token,
            lawyer_id: props.lawyer_id,
            client_id: token_decoded.role_id,
            title: detailsFormValues.title,
            appointmentLocation: appointmentLocation,
            gmailDescription: gmailDescription,
            outlookDescription: outlookDescription,
            yahooDescription: yahooDescription,
            icsDescription: JSON.stringify(icsDescription).replace(/"/g, ''),
            meetingVal: meetingValString,
            phoneNumber: phoneNumber,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            email: formValues.email,
            appointmentHour: nextStepHour,
            nextStepDate: nextStepDate,
            role: token_decoded.role,
            dayNumber: day,
            month: month,
            year: year,
            appointmentDateTimestamp: appointmentDateTimestamp,
            startAppointmentTimestamp: startAppointmentTimestamp,
            endAppointmentTimestamp: endAppointmentTimestamp,
            updateAppointment: false,
            description: detailsFormValues.description,
          },
        },
      });
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isError = false;
    let newInputErrors = {
      firstName: '',
      lastName: '',
      email: '',
    };

    if (formValues.email.length === 0) {
      isError = true;
      newInputErrors.email = LPTranslate('Error_Email_Required');
    } else if (!validateEmail(formValues.email)) {
      isError = true;
      newInputErrors.email = LPTranslate('Error_Valid_Email');
    }

    if (formValues.firstName.length === 0) {
      isError = true;
      newInputErrors.firstName = LPTranslate('Error_First_Name_Required');
    }
    if (formValues.lastName.length === 0) {
      isError = true;
      newInputErrors.lastName = LPTranslate('Error_Last_Name_Required');
    }

    props.setFormErrors(newInputErrors);

    if (isError) return;

    // navigate(-1);

    // return;

    try {
      await miniRegister({
        variables: {
          input: {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            email: formValues.email,
            role: 'client',
            type: 'mini_register_appointment',
            marketingAccepted: marketingAccepted,
          },
        },
      });
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    if (miniRegisterData && miniRegisterData.miniRegister) {
      if (miniRegisterData.miniRegister.status === '204') {
        setErrorMessage(miniRegisterData.miniRegister.message);
      } else if (miniRegisterData.miniRegister.jwtToken) {
        // mutatie set appointment
        const token_decoded = jwt(miniRegisterData.miniRegister.jwtToken);
        callSetAppointment(
          `Bearer ${miniRegisterData.miniRegister.jwtToken}`,
          token_decoded
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [miniRegisterData]);

  useEffect(() => {
    if (
      setAppointmentData &&
      setAppointmentData.setAppointment &&
      setAppointmentData.setAppointment.error
    ) {
      setErrorMessage(setAppointmentData.setAppointment.message);
      return;
    }

    if (setAppointmentData && setAppointmentData.setAppointment.message) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'client-mini-register-set-appointment-custom-event',
      });
      props.setGoToRegister(true);
      props.setState(3);
    } else if (
      setAppointmentData &&
      setAppointmentData.setAppointment.error ===
        'User already exists in database...'
    ) {
      setErrorMessage('User already exists in database...');

      let isError = false;
      let newInputErrors = {
        firstName: '',
        lastName: '',
        email: '',
      };

      isError = true;
      newInputErrors.email = LPTranslate(
        'Error_Set_Meeting_User_Already_Exists'
      );

      props.setFormErrors(newInputErrors);

      if (isError) return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAppointmentData]);

  return (
    <div className="lawp-add-meeting-modal-container-right-content-form">
      <div className="lawp-add-meeting-modal-container-right-frame1038">
        <button
          className={
            'lawp-add-meeting-modal-container-right-row-arrow-button-back'
          }
          onClick={() => props.setState(1)}
        >
          <img src={arrowLeft} alt="arrow" />
        </button>
      </div>

      <div className="lawp-add-meeting-modal-container-right-heading-and-supporting-text-large">
        <h1 className="lawp-add-meeting-modal-container-right-heading-large">
          {LPTranslate('CSA_Register_Title')}
        </h1>
        <h3 className="lawp-add-meeting-modal-container-right-supporting-text">
          {LPTranslate('CSA_Register_Description')}
        </h3>
      </div>

      <h3 className="lawp-add-meeting-modal-container-right-text">
        {`${nextStepDate} um ${nextStepHour} Uhr`}
      </h3>

      <form
        onSubmit={(e) => handleSubmit(e)}
        className={
          'lawp-add-meeting-modal-container-right-heading-form-content'
        }
      >
        <div
          className={
            'lawp-add-meeting-modal-container-right-heading-firstname-input '
          }
        >
          <div
            className={
              'lawp-add-meeting-modal-container-right-heading-input-with-label'
            }
          >
            <SimpleTextField
              data_cy="first-name-input"
              title={LPTranslate('Input_First_Name')}
              placeholder={LPTranslate('Input_First_Name_Placeholder')}
              onChange={(value) => {
                handleInputChange('firstName', value);
                props.setFormErrors({ ...formErrors, firstName: '' });
              }}
              value={formValues.firstName}
              textFieldWidthPercent={100}
              type="text"
              errorMessage={formErrors.firstName}
            />
          </div>
        </div>

        <div
          className={
            'lawp-add-meeting-modal-container-right-heading-lastname-input '
          }
        >
          <div
            className={
              'lawp-add-meeting-modal-container-right-heading-input-with-label'
            }
          >
            <SimpleTextField
              data_cy="last-name-input"
              title={LPTranslate('Input_Last_Name')}
              placeholder={LPTranslate('Input_Last_Name_Placeholder')}
              onChange={(value) => {
                handleInputChange('lastName', value);
                props.setFormErrors({ ...formErrors, lastName: '' });
              }}
              value={formValues.lastName}
              textFieldWidthPercent={100}
              type="text"
              errorMessage={formErrors.lastName}
            />
          </div>
        </div>

        <div
          className={
            'lawp-add-meeting-modal-container-right-heading-email-input '
          }
        >
          <div
            className={
              'lawp-add-meeting-modal-container-right-heading-input-with-label '
            }
          >
            <SimpleTextField
              data_cy="email-address-input"
              title="Email*"
              placeholder={LPTranslate('LC_Email_Placeholder')}
              onChange={(value) => {
                handleInputChange('email', value);
                props.setFormErrors({ ...formErrors, email: '' });
              }}
              value={formValues.email}
              textFieldWidthPercent={100}
              type="text"
              errorMessage={formErrors.email}
            />
          </div>
        </div>

        {errorMessage && (
          <div className="lawp-set-meeting-error-container">
            <p className="lawp-set-meeting-error-message">{errorMessage}</p>
          </div>
        )}

        <div
          className={'lawp-add-meeting-modal-container-right-heading-actions'}
        >
          <div
            style={{
              marginTop: '-20px',
            }}
          >
            <RegisterCheckboxComponent
              termsAccepted={termsAccepted}
              setTermsAccepted={setTermsAccepted}
              marketingAccepted={marketingAccepted}
              setMarketingAccepted={setMarketingAccepted}
            />
          </div>

          {termsAccepted && !miniRegisterLoading ? (
            <div
              className={
                'lawp-add-meeting-modal-container-right-heading-button'
              }
            >
              <button
                data-cy="submit-button"
                className="lawp-add-meeting-modal-container-right-submit-button"
                type="submit"
              >
                {/* {LPTranslate('Button_Make_Appointment')} */}
                OK
              </button>
            </div>
          ) : (
            <div
              className={
                'lawp-add-meeting-modal-container-right-heading-button'
              }
            >
              <button
                data-cy="submit-button"
                className="lawp-add-meeting-modal-container-right-submit-button-disabled"
                disabled
              >
                {/* {LPTranslate('Button_Make_Appointment')} */}
                OK
              </button>
            </div>
          )}
          <div className="lawp-add-meeting-modal-container-right-row">
            <h3 className="lawp-add-meeting-modal-container-right-login-text">
              {LPTranslate('Common_Already_Have_Account_Question')}
            </h3>

            <div className="lawp-add-meeting-modal-container-right-login-button">
              <button
                className="lawp-add-meeting-modal-container-right-login-button-base"
                onClick={() => {
                  navigate('/login');
                }}
              >
                <h3 className="lawp-add-meeting-modal-container-right-login-button-text">
                  {LPTranslate('Common_Login_Now')}
                </h3>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SetMeetingNameBetweenClientAndLawyerModal;
