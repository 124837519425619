import React, { useEffect, useState } from 'react';
import CalendarDays from 'Components/Calendar/CalendarDays';

import arrowLeft from 'Assets/pictures/chevron-left.svg';
import arrowRight from 'Assets/pictures/chevron-right.svg';

import 'Assets/styles/calendar/calendar.css';

const Calendar = (props) => {
  const weekdays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
  const [isNextMonthPressed, setIsNextMonthPressed] = useState(false);

  const changeCalendarFromSecond = (element, target) => {
    let nodeIterator = element;
    target.classList.add('selected');
    for (let i = 0; i < nodeIterator.children.length; i++) {
      if (nodeIterator.children[i].classList.contains('selected')) {
        if (
          parseInt(nodeIterator.children[i].innerText) ===
          parseInt(target.innerText)
        ) {
          nodeIterator.children[i].className = 'calendar-day current';
        }
        return true;
      }
    }
    return false;
  };

  const isPreviousMonth = (element) => {
    let nodeIterator = element;

    if (element.classList.contains('current')) {
      return false;
    }

    while (nodeIterator.nextSibling) {
      if (nodeIterator.classList.contains('current')) {
        return true;
      }
      nodeIterator = nodeIterator.nextSibling;
    }
  };

  const isNextMonth = (element) => {
    let nodeIterator = element;

    setIsNextMonthPressed(true);

    if (nodeIterator.classList.contains('current')) {
      return false;
    }

    while (nodeIterator.nextSibling || nodeIterator.lastChild) {
      if (nodeIterator.classList.contains('current')) {
        return true;
      }
      nodeIterator = nodeIterator.previousSibling;
    }
  };

  useEffect(() => {
    const preventToChangeBackgroundColor = () => {
      const element = document.querySelectorAll('*[aria-label]');
      for (let i = 0; i < element.length; i++) {
        if (!element[i].classList.contains('current')) {
          element[i].style.background = '#FFFFFF';
          element[i].classList.remove('selected');
        }
      }

      setIsNextMonthPressed(false);
    };

    preventToChangeBackgroundColor();
  }, [props.startDate, props.endDate, isNextMonthPressed]);

  useEffect(() => {
    if (props.startDate !== undefined && props.endDate !== undefined) {
      const element = document.querySelectorAll('*[aria-label]');

      for (let i = 0; i < element.length; i++) {
        if (!element[i].classList.contains('current')) {
          element[i].style.background = '#FFFFFF';
          element[i].classList.remove('selected');
        }
        if (
          element[i].classList.contains('current') &&
          parseInt(new Date(Number(props.startDate)).getMonth() + 1) ===
            parseInt(new Date(Number(props.endDate)).getMonth() + 1) &&
          parseInt(element[i].getAttribute('aria-label').split(' ')[0]) >
            parseInt(new Date(Number(props.startDate)).getDate()) &&
          parseInt(element[i].getAttribute('aria-label').split(' ')[1]) ===
            parseInt(new Date(Number(props.startDate)).getMonth() + 1) &&
          parseInt(element[i].getAttribute('aria-label').split(' ')[0]) <
            parseInt(new Date(Number(props.endDate)).getDate()) &&
          parseInt(element[i].getAttribute('aria-label').split(' ')[1]) ===
            parseInt(new Date(Number(props.endDate)).getMonth() + 1)
        ) {
          element[i].style.background = '#F9F5FF';
        } else if (
          parseInt(new Date(Number(props.startDate)).getMonth() + 1) !==
          parseInt(new Date(Number(props.endDate)).getMonth() + 1)
        ) {
          if (
            element[i].classList.contains('current') &&
            parseInt(element[i].getAttribute('aria-label').split(' ')[0]) >
              parseInt(props.startDate.getDate()) &&
            parseInt(element[i].getAttribute('aria-label').split(' ')[1]) ===
              parseInt(props.startDate.getMonth() + 1)
          )
            element[i].style.background = '#F9F5FF';

          if (
            element[i].classList.contains('current') &&
            parseInt(element[i].getAttribute('aria-label').split(' ')[0]) <
              parseInt(props.endDate.getDate()) &&
            parseInt(element[i].getAttribute('aria-label').split(' ')[1]) ===
              parseInt(props.endDate.getMonth() + 1)
          )
            element[i].style.background = '#F9F5FF';
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeCurrentDay = async (day, e) => {
    let target = e.target.tagName === 'P' ? e.target.parentElement : e.target;

    // verificam daca ziua selectata este una curenta lunii respective
    // + verificam daca ziua selectata este din trecut

    if (
      !target.classList.contains('current') ||
      new Date(
        target.getAttribute('aria-label').split(' ')[2] +
          '-' +
          target.getAttribute('aria-label').split(' ')[1] +
          '-' +
          target.getAttribute('aria-label').split(' ')[0]
      ).getTime() /
        1000 <
        new Date().getTime() / 1000
    )
      return;

    // cand apasam pe o zi a treia oara, vom reseta start date si end date

    if (props.startDate !== undefined && props.endDate !== undefined) {
      var selectedElements = document.getElementsByClassName(
        'calendar-day current'
      );

      for (let i = 0; i < selectedElements.length; i++) {
        if (
          selectedElements[i].style.background !== '' ||
          selectedElements[i].className === 'calendar-day current selected'
        )
          selectedElements[i].style.background = '#FFFFFF';
        selectedElements[i].className = 'calendar-day current';
      }
      props.setStartDate(new Date(day.year, day.month, day.number));
      props.setEndDate(undefined);

      let dateStr = target.getAttribute('aria-label');
      let monthStr = dateStr.slice(
        dateStr.indexOf(' ') + 1,
        dateStr.lastIndexOf(' ')
      );
      let dayStr = dateStr.slice(0, dateStr.indexOf(' '));
      let yearStr = dateStr.slice(dateStr.lastIndexOf(' ') + 1);

      let date = new Date(
        parseInt(yearStr),
        parseInt(monthStr - 2),
        parseInt(dayStr)
      );

      let secondCalendar =
        document.getElementsByClassName('calendar-body')[1].lastChild;

      if (isPreviousMonth(target)) {
        let dayNumber = target.children[0].outerText;
        await handlePreviousByStartDate();

        let navTabs =
          document.getElementsByClassName('calendar-body')[0].lastChild;

        for (let i = 0; i < navTabs.children.length; i++) {
          if (!navTabs.children[i].classList.contains('current')) {
            navTabs.children[i].style.background = '#FFFFFF';
            navTabs.children[i].classList.remove('selected');
          }
          if (
            parseInt(navTabs.children[i].innerText) === parseInt(dayNumber) &&
            navTabs.children[i].classList.contains('current')
          ) {
            navTabs.children[i].className = 'calendar-day current selected';
          }
        }
      } else if (
        isNextMonth(target) ||
        changeCalendarFromSecond(secondCalendar, target)
      ) {
        let dayNumber = target.children[0].outerText;
        await handleNextByStartDate(props.currentDay);

        if (parseInt(monthStr) === parseInt(props.currentDay.getMonth() + 3)) {
          handleNextByStartDate(date);
        }

        let navTabs =
          document.getElementsByClassName('calendar-body')[0].lastChild;

        for (let i = 0; i < navTabs.children.length; i++) {
          if (!navTabs.children[i].classList.contains('current')) {
            navTabs.children[i].style.background = '#FFFFFF';
            navTabs.children[i].classList.remove('selected');
          }
          if (
            parseInt(navTabs.children[i].innerText) === parseInt(dayNumber) &&
            navTabs.children[i].classList.contains('current')
          ) {
            navTabs.children[i].className = 'calendar-day current selected';
          }
        }
      }
    }
    // cand apasam pe o zi a doua oara, aici se va seta end date
    else if (props.startDate !== undefined) {
      let target = e.target.tagName === 'P' ? e.target.parentElement : e.target;
      let dateStr = target.getAttribute('aria-label');
      let monthStr = dateStr.slice(
        dateStr.indexOf(' ') + 1,
        dateStr.lastIndexOf(' ')
      );
      let endDateAux = new Date(day.year, day.month, day.number);

      if (
        parseInt(endDateAux.getMonth() + 1) !==
          parseInt(props.startDate.getMonth() + 3) &&
        parseInt(endDateAux.getMonth() + 11) !==
          parseInt(props.startDate.getMonth() + 1)
      ) {
        props.setEndDate(endDateAux);

        const element = document.querySelectorAll('*[aria-label]');

        let start,
          end = undefined;

        if (props.startDate.getTime() > endDateAux.getTime()) {
          const aux = endDateAux;
          end = props.startDate;
          start = aux;
        } else {
          start = props.startDate;
          end = endDateAux;
        }

        for (let i = 0; i < element.length; i++) {
          if (
            parseInt(element[i].getAttribute('aria-label').split(' ')[1]) ===
              parseInt(start.getMonth() + 1) &&
            parseInt(end.getMonth() + 1) === parseInt(start.getMonth() + 1) &&
            !target.classList.contains('current')
          ) {
            element[i].style.background = '#FFFFFF';
            element[i].classList.remove('selected');
          }

          if (
            element[i].classList.contains('current') &&
            parseInt(element[i].getAttribute('aria-label').split(' ')[1]) ===
              parseInt(start.getMonth() + 1) &&
            parseInt(element[i].getAttribute('aria-label').split(' ')[0]) ===
              parseInt(start.getDate())
          )
            element[i].className = 'calendar-day current selected';

          if (parseInt(end.getMonth() + 1) === parseInt(start.getMonth() + 1)) {
            if (
              element[i].classList.contains('current') &&
              parseInt(element[i].getAttribute('aria-label').split(' ')[1]) ===
                parseInt(props.startDate.getMonth() + 1) &&
              element[i].innerText > parseInt(start.getDate()) &&
              element[i].innerText < parseInt(end.getDate())
            )
              element[i].style.background = '#F9F5FF';
          } else if (
            parseInt(end.getMonth() + 1) > parseInt(start.getMonth() + 1)
          ) {
            if (
              (element[i].classList.contains('current') &&
                parseInt(
                  element[i].getAttribute('aria-label').split(' ')[1]
                ) === parseInt(start.getMonth() + 1) &&
                element[i].innerText > parseInt(start.getDate())) ||
              (element[i].classList.contains('current') &&
                parseInt(
                  element[i].getAttribute('aria-label').split(' ')[1]
                ) === parseInt(end.getMonth() + 1) &&
                element[i].innerText < parseInt(end.getDate()))
            )
              element[i].style.background = '#F9F5FF';
          } else if (
            parseInt(end.getMonth() + 1) < parseInt(start.getMonth() + 1)
          ) {
            if (
              (element[i].classList.contains('current') &&
                parseInt(
                  element[i].getAttribute('aria-label').split(' ')[1]
                ) === parseInt(start.getMonth() + 1) &&
                element[i].innerText > parseInt(start.getDate())) ||
              (element[i].classList.contains('current') &&
                parseInt(
                  element[i].getAttribute('aria-label').split(' ')[1]
                ) === parseInt(end.getMonth() + 1) &&
                element[i].innerText < parseInt(end.getDate()))
            )
              element[i].style.background = '#F9F5FF';
          }
        }

        if (props.startDate.getTime() > endDateAux.getTime()) {
          var aux = endDateAux;
          props.setEndDate(props.startDate);
          props.setStartDate(aux);
        }

        if (
          isPreviousMonth(target) &&
          parseInt(monthStr) !== parseInt(props.startDate.getMonth() + 1)
        ) {
          await handlePreviousByStartDate();

          const element = document.querySelectorAll('*[aria-label]');

          if (props.startDate !== undefined && endDateAux !== undefined) {
            for (let i = 0; i < element.length; i++) {
              if (
                parseInt(
                  element[i].getAttribute('aria-label').split(' ')[1]
                ) !== parseInt(props.startDate.getMonth() + 1)
              ) {
                element[i].style.background = '#FFFFFF';
                element[i].classList.remove('selected');
              }

              if (
                element[i].classList.contains('current') &&
                parseInt(element[i].getAttribute('aria-label').split(' ')[0]) >
                  parseInt(endDateAux.getDate()) &&
                parseInt(
                  element[i].getAttribute('aria-label').split(' ')[1]
                ) === parseInt(endDateAux.getMonth() + 1)
              )
                element[i].style.background = '#F9F5FF';

              if (
                element[i].classList.contains('current') &&
                parseInt(
                  element[i].getAttribute('aria-label').split(' ')[1]
                ) === parseInt(endDateAux.getMonth() + 1) &&
                parseInt(
                  element[i].getAttribute('aria-label').split(' ')[0]
                ) === parseInt(endDateAux.getDate())
              )
                element[i].className = 'calendar-day current selected';

              if (
                element[i].classList.contains('current') &&
                parseInt(element[i].getAttribute('aria-label').split(' ')[0]) <
                  parseInt(props.startDate.getDate()) &&
                parseInt(
                  element[i].getAttribute('aria-label').split(' ')[1]
                ) !== parseInt(props.startDate.getMonth())
              )
                element[i].style.background = '#F9F5FF';

              if (
                element[i].classList.contains('current') &&
                parseInt(
                  element[i].getAttribute('aria-label').split(' ')[1]
                ) === parseInt(props.startDate.getMonth() + 1) &&
                parseInt(
                  element[i].getAttribute('aria-label').split(' ')[0]
                ) === parseInt(props.startDate.getDate())
              )
                element[i].className = 'calendar-day current selected';
            }
          }
        } else {
          const element = document.querySelectorAll('*[aria-label]');
          if (props.startDate !== undefined && endDateAux !== undefined) {
            for (let i = 0; i < element.length; i++) {
              if (
                parseInt(
                  element[i].getAttribute('aria-label').split(' ')[1]
                ) !== parseInt(props.startDate.getMonth() + 1) &&
                parseInt(
                  element[i].getAttribute('aria-label').split(' ')[0]
                ) === parseInt(endDateAux.getDate())
              )
                element[i].classList.remove('selected');
              if (
                element[i].classList.contains('current') &&
                parseInt(
                  element[i].getAttribute('aria-label').split(' ')[1]
                ) === parseInt(endDateAux.getMonth() + 1) &&
                parseInt(
                  element[i].getAttribute('aria-label').split(' ')[0]
                ) === parseInt(endDateAux.getDate())
              )
                element[i].className = 'calendar-day current selected';
            }
          }
        }
      }
    }
    // cand apasam pe o zi a prima oara, aici se va seta start date
    else {
      let target = e.target.tagName === 'P' ? e.target.parentElement : e.target;
      if (target.classList.contains('current')) {
        props.setStartDate(new Date(day.year, day.month, day.number));

        let secondCalendar =
          document.getElementsByClassName('calendar-body')[1].lastChild;

        let dateStr = target.getAttribute('aria-label');
        let monthStr = dateStr.slice(
          dateStr.indexOf(' ') + 1,
          dateStr.lastIndexOf(' ')
        );
        let dayStr = dateStr.slice(0, dateStr.indexOf(' '));
        let yearStr = dateStr.slice(dateStr.lastIndexOf(' ') + 1);

        let date = new Date(
          parseInt(yearStr),
          parseInt(monthStr - 2),
          parseInt(dayStr)
        );

        if (isPreviousMonth(target)) {
          let dayNumber = target.children[0].outerText;
          await handlePreviousByStartDate();

          let navTabs =
            document.getElementsByClassName('calendar-body')[0].lastChild;

          for (let i = 0; i < navTabs.children.length; i++) {
            if (
              parseInt(navTabs.children[i].innerText) === parseInt(dayNumber) &&
              navTabs.children[i].classList.contains('current')
            ) {
              navTabs.children[i].className = 'calendar-day current selected';
            }
          }
        } else if (
          isNextMonth(target) ||
          changeCalendarFromSecond(secondCalendar, target)
        ) {
          let dayNumber = target.children[0].outerText;
          await handleNextByStartDate(props.currentDay);

          if (
            parseInt(monthStr) === parseInt(props.currentDay.getMonth() + 3)
          ) {
            handleNextByStartDate(date);
          }

          let navTabs =
            document.getElementsByClassName('calendar-body')[0].lastChild;

          for (let i = 0; i < navTabs.children.length; i++) {
            if (
              parseInt(navTabs.children[i].innerText) === parseInt(dayNumber) &&
              navTabs.children[i].classList.contains('current')
            ) {
              navTabs.children[i].className = 'calendar-day current selected';
            }
          }
        }
      }
    }
  };

  const handlePreviousByStartDate = async () => {
    const previousMonth = new Date(
      props.currentDay.getFullYear(),
      props.currentDay.getMonth() - 1,
      1
    );
    const nextMonth = new Date(
      props.currentDay.getFullYear(),
      previousMonth.getMonth() + 1,
      1
    );
    await props.setCurrentDay(previousMonth);
    await props.setnextMonth(nextMonth);
  };

  const handleNextByStartDate = async (day) => {
    let tableContent1 =
      document.getElementsByClassName('calendar-content')[0].lastChild
        .firstChild.lastChild.innerText;
    let tableContent2 =
      document.getElementsByClassName('calendar-content')[1].lastChild
        .firstChild.firstChild.innerText;

    const previousMonth = new Date(day.getFullYear(), day.getMonth() + 1, 1);

    if (
      parseInt(tableContent1.split(' ')[1]) ===
      parseInt(tableContent2.split(' ')[1])
    ) {
      const nextMonth = new Date(
        day.getFullYear(),
        previousMonth.getMonth() + 1,
        1
      );

      await props.setCurrentDay(previousMonth);
      await props.setnextMonth(nextMonth);
    } else if (
      parseInt(tableContent1.split(' ')[1]) <
      parseInt(tableContent2.split(' ')[1])
    ) {
      const nextMonth = new Date(
        parseInt(tableContent2.split(' ')[1]),
        previousMonth.getMonth() + 1,
        1
      );

      await props.setCurrentDay(previousMonth);
      await props.setnextMonth(nextMonth);
    }
  };

  const handleCalendars = async (type) => {
    let previousMonth, nextMonth;

    if (type === 'previous') {
      previousMonth = new Date(
        props.currentDay.getFullYear(),
        props.currentDay.getMonth() - 1,
        1
      );
      nextMonth = new Date(
        previousMonth.getFullYear(),
        previousMonth.getMonth() + 1,
        1
      );
    } else if (type === 'next') {
      previousMonth = new Date(
        props.currentDay.getFullYear(),
        props.currentDay.getMonth() + 1,
        1
      );

      nextMonth = new Date(
        previousMonth.getFullYear(),
        previousMonth.getMonth() + 1,
        1
      );
    }

    await props.setCurrentDay(previousMonth);
    await props.setnextMonth(nextMonth);

    const element = document.querySelectorAll('*[aria-label]');

    if (props.endDate === undefined) {
      await props.setStartDate(undefined);

      let navTabs =
        document.getElementsByClassName('calendar-body')[0].lastChild;

      for (let i = 0; i < navTabs.children.length; i++) {
        if (
          !navTabs.children[i].classList.contains('current') ||
          navTabs.children[i].classList.contains('selected')
        )
          navTabs.children[i].classList.remove('selected');
      }
    }

    if (props.startDate !== undefined && props.endDate !== undefined) {
      if (props.startDate.getMonth() === props.endDate.getMonth()) {
        for (let i = 0; i < element.length; i++) {
          if (
            !element[i].classList.contains('current') ||
            parseInt(element[i].getAttribute('aria-label').split(' ')[1]) !==
              parseInt(props.startDate.getMonth() + 1)
          ) {
            element[i].style.background = '#FFFFFF';
            element[i].classList.remove('selected');
          } else if (
            element[i].classList.contains('current') &&
            parseInt(element[i].getAttribute('aria-label').split(' ')[0]) >
              parseInt(props.startDate.getDate()) &&
            parseInt(element[i].getAttribute('aria-label').split(' ')[0]) <
              parseInt(props.endDate.getDate())
          )
            element[i].style.background = '#F9F5FF';
          else if (
            element[i].classList.contains('current') &&
            parseInt(element[i].getAttribute('aria-label').split(' ')[1]) ===
              parseInt(props.startDate.getMonth() + 1) &&
            (parseInt(element[i].getAttribute('aria-label').split(' ')[0]) ===
              parseInt(props.startDate.getDate()) ||
              parseInt(element[i].getAttribute('aria-label').split(' ')[0]) ===
                parseInt(props.endDate.getDate()))
          )
            element[i].className = 'calendar-day current selected';
        }
      } else {
        for (let i = 0; i < element.length; i++) {
          if (
            !element[i].classList.contains('current') ||
            parseInt(element[i].getAttribute('aria-label').split(' ')[1]) !==
              parseInt(props.startDate.getMonth() + 1)
          ) {
            element[i].style.background = '#FFFFFF';
            element[i].classList.remove('selected');
          }

          if (
            element[i].classList.contains('current') &&
            parseInt(element[i].getAttribute('aria-label').split(' ')[0]) >
              parseInt(props.startDate.getDate()) &&
            parseInt(element[i].getAttribute('aria-label').split(' ')[1]) ===
              parseInt(props.startDate.getMonth() + 1)
          )
            element[i].style.background = '#F9F5FF';
          else element[i].style.background = '#FFFFFF';

          if (
            element[i].classList.contains('current') &&
            parseInt(element[i].getAttribute('aria-label').split(' ')[1]) ===
              parseInt(props.startDate.getMonth() + 1) &&
            parseInt(element[i].getAttribute('aria-label').split(' ')[0]) ===
              parseInt(props.startDate.getDate())
          )
            element[i].className = 'calendar-day current selected';

          if (
            element[i].classList.contains('current') &&
            parseInt(element[i].getAttribute('aria-label').split(' ')[0]) <
              parseInt(props.endDate.getDate()) &&
            parseInt(element[i].getAttribute('aria-label').split(' ')[1]) ===
              parseInt(props.endDate.getMonth() + 1)
          )
            element[i].style.background = '#F9F5FF';

          if (
            element[i].classList.contains('current') &&
            parseInt(element[i].getAttribute('aria-label').split(' ')[1]) ===
              parseInt(props.endDate.getMonth() + 1) &&
            parseInt(element[i].getAttribute('aria-label').split(' ')[0]) ===
              parseInt(props.endDate.getDate())
          )
            element[i].className = 'calendar-day current selected';

          if (element[i].classList.contains('day-of-month'))
            element[i].classList.remove('day-of-month');
        }
      }
    }
  };

  return (
    <>
      <div className="calendar-content" id="calendar1">
        <div className="calendar">
          <div className="calendar-header">
            <button
              data-cy="holiday-calendar-previous-month-button"
              className="button-left"
              onClick={() => handleCalendars('previous')}
            >
              <img src={arrowLeft} alt={'left arrow'} />
            </button>
            <p className="month-year-text">
              {props.months[props.currentDay.getMonth()]}{' '}
              {props.currentDay.getFullYear()}
            </p>
          </div>
          <div className="calendar-body">
            <div className="table-header">
              {weekdays.map((weekday, id) => {
                return (
                  <div key={id} className="weekday">
                    <p className="weekday-names">{weekday}</p>
                  </div>
                );
              })}
            </div>
            <CalendarDays
              day={props.currentDay}
              startDate={props.startDate}
              endDate={props.endDate}
              changeCurrentDay={changeCurrentDay}
              calendarArr={props.calendarArr}
            />
          </div>
        </div>
      </div>

      <div className="divider" />

      <div className="calendar-content" id="calendar2">
        <div className="calendar">
          <div className="calendar-header">
            {/* <button className="button-left" onClick={handlePrevious}><img src={arrowLeft} /></button> */}
            <p className="month-year-text">
              {props.months[props.nextMonth.getMonth()]}{' '}
              {props.nextMonth.getFullYear()}
            </p>
            <button
              data-cy="holiday-calendar-next-month-button"
              className="button-right"
              onClick={() => handleCalendars('next')}
            >
              <img src={arrowRight} alt={'right arrow'} />
            </button>
          </div>
          <div className="calendar-body">
            <div className="table-header">
              {weekdays.map((weekday, id) => {
                return (
                  <div key={id} className="weekday">
                    <p className="weekday-names">{weekday}</p>
                  </div>
                );
              })}
            </div>
            <CalendarDays
              day={props.nextMonth}
              startDate={props.startDate}
              endDate={props.endDate}
              changeCurrentDay={changeCurrentDay}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendar;
