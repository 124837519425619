import React, { useEffect, useCallback } from 'react';

import Button from '@mui/material/Button';

import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

// import AboutUsAchievement from 'Components/help/AboutUsAchievement';
import FaqLawyerComponent from 'Components/CommonComponents/FaqLawyerComponent';
import LawyerCardPreview from 'Components/help/LawyerCardPreview';
import RatingStars from 'Components/RatingStars/RatingStars';
import MetaDecorator from 'Layouts/MetaDecorator';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { GET_LAWYERS_BY_SCORE } from 'Services/Queries/lawyer';

import heartIcon from 'Assets/pictures/heartIcon.svg';
import lightIcon from 'Assets/pictures/lightIcon.svg';
import smileIcon from 'Assets/pictures/smileIcon.svg';
import friendIcon from 'Assets/pictures/friendIcon.svg';
import flagIcon from 'Assets/pictures/flagIcon.svg';
import chartArrowIcon from 'Assets/pictures/chartArrowIcon.svg';

import avatarImage1 from 'Assets/pictures/AvatarImage1.png';
import avatarImage2 from 'Assets/pictures/AvatarImage2.png';
import avatarImage3 from 'Assets/pictures/AvatarImage3.png';
import imagesGroup1 from 'Assets/pictures/imagesGroup1.png';
import 'Assets/styles/help/AboutUsPage.scss';

// const Achievemnts = [
//   {
//     title: LPTranslate('About_Us_Achievement_1_Title'),
//     subtitle: LPTranslate('About_Us_Achievement_1_Subtitle'),
//     description: LPTranslate('About_Us_Achievement_1_Description'),
//   },
//   {
//     title: LPTranslate('About_Us_Achievement_2_Title'),
//     subtitle: LPTranslate('About_Us_Achievement_2_Subtitle'),
//     description: LPTranslate('About_Us_Achievement_2_Description'),
//   },
//   {
//     title: LPTranslate('About_Us_Achievement_3_Title'),
//     subtitle: LPTranslate('About_Us_Achievement_3_Subtitle'),
//     description: LPTranslate('About_Us_Achievement_3_Description'),
//   },
//   {
//     title: LPTranslate('About_Us_Achievement_4_Title'),
//     subtitle: LPTranslate('About_Us_Achievement_4_Subtitle'),
//     description: LPTranslate('About_Us_Achievement_4_Description'),
//   },
// ];

const ValuesData = [
  {
    cardText: LPTranslate('About_Us_Values_Text_1'),
    cardTitle: LPTranslate('About_Us_Values_Title_1'),
    picture: friendIcon,
  },
  {
    cardText: LPTranslate('About_Us_Values_Text_2'),
    cardTitle: LPTranslate('About_Us_Values_Title_2'),
    picture: heartIcon,
  },
  {
    cardText: LPTranslate('About_Us_Values_Text_3'),
    cardTitle: LPTranslate('About_Us_Values_Title_3'),
    picture: chartArrowIcon,
  },
  {
    cardText: LPTranslate('About_Us_Values_Text_4'),
    cardTitle: LPTranslate('About_Us_Values_Title_4'),
    picture: smileIcon,
  },
  {
    cardText: LPTranslate('About_Us_Values_Text_5'),
    cardTitle: LPTranslate('About_Us_Values_Title_5'),
    picture: flagIcon,
  },
  {
    cardText: LPTranslate('About_Us_Values_Text_6'),
    cardTitle: LPTranslate('About_Us_Values_Title_6'),
    picture: lightIcon,
  },
];

const reviewData = {
  avatar: avatarImage1,
  rating: 5,
  // name: 'Katherine Moss',
  name: 'S.M.',
  description: LPTranslate('About_Us_Lawyer_Description'),
  review: LPTranslate('About_Us_Lawyer_Review'),
};

const AboutUsPage = () => {
  const navigate = useNavigate();

  const [getlawyersByScoreAPI, { data }] = useLazyQuery(GET_LAWYERS_BY_SCORE);

  const fetchLawyers = useCallback(async () => {
    await getlawyersByScoreAPI({
      variables: {
        input: {
          numberOfLawyers: 8,
        },
      },
    });
  }, [getlawyersByScoreAPI]);

  useEffect(() => {
    fetchLawyers();
  }, [fetchLawyers]);

  return (
    <div className="lawp-about-us-page-container">
      <MetaDecorator
        title={LPTranslate('About_Us_Meta_Title_1')}
        description={LPTranslate('About_Us_Meta_Description_1')}
        canonical={'canonical'}
        link={`${process.env.REACT_APP_DNS_URI}/anwado`}
      />
      <div className="lawp-about-us-page-top-container">
        <div className="lawp-about-us-page-top-container-content">
          <div className="lawp-about-us-page-top-container-content-left">
            <h3> {LPTranslate('About_Us_Title_1')} </h3>
            <br />
            <h1>{LPTranslate('About_Us_Title_2')}</h1>
          </div>

          <div className="lawp-about-us-page-top-container-content-right">
            <h2>{LPTranslate('About_Us_Title_3')}</h2>
          </div>
        </div>
      </div>

      <div className="lawp-about-us-page-bottom-container">
        <div className="lawp-about-us-page-bottom-container-content">
          {/* <div className="lawp-about-us-page-achievements-container">
            {Achievemnts.map((achievement, index) => {
              return (
                <AboutUsAchievement
                  key={index}
                  title={achievement.title}
                  subtitle={achievement.subtitle}
                  description={achievement.description}
                />
              );
            })}
          </div> */}

          <div className="lawp-about-us-page-review-section-only-text">
            <div className="content">
              <h2 className="review-text">
                "{LPTranslate('LP_Founders_Citation')}"
              </h2>

              <h3 className="name">{LPTranslate('LP_Founders_Name')}</h3>
            </div>
          </div>

          <div className="lawp-about-us-page-our-values-container">
            <div className="lawp-about-us-page-our-values-container-info">
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <h4>{LPTranslate('About_Us_Our')}</h4>
                &nbsp;
                <h4
                  style={{
                    // fontWeight: '800',
                    fontSize: 16,
                    display: 'inline-block',
                  }}
                >
                  {LPTranslate('About_Us_Goal')}
                </h4>
              </div>
              <h2> {LPTranslate('About_Us_Our_Goal_Title')}</h2>
              <h3>{LPTranslate('About_Us_Our_Goal_Description')}</h3>
            </div>
          </div>

          <div className="lawp-about-us-page-values-container">
            <div className="content">
              {ValuesData.map((item, index) => {
                return (
                  <div key={index}>
                    <FaqLawyerComponent
                      text={item.cardText}
                      picture={item.picture}
                      title={item.cardTitle}
                      centeredContent={true}
                      alignCenter={true}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="lawp-about-us-page-favorite-lawyers-container">
          <div className="lawp-about-us-page-our-values-container">
            <div className="lawp-about-us-page-our-values-container-info">
              <h4>Anwado</h4>
              <h2>{LPTranslate('About_Us_Lawyer_Cards_Title')}</h2>
              <h3>{LPTranslate('About_Us_Lawyer_Cards_Description')}</h3>
            </div>
          </div>

          <div className="lawyer-cards-container">
            <div className="lawyer-cards">
              {data &&
                data.lawyersByScore &&
                data.lawyersByScore.map((lawyer, index) => {
                  return (
                    <React.Fragment key={index}>
                      <LawyerCardPreview lawyer={lawyer} index={index} />
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="lawp-about-us-page-review-section">
          <div className="reviews-container">
            <RatingStars
              value={Number(reviewData.rating)}
              width={20}
              height={20}
              readonly={true}
              colorFilter={
                'invert(91%) sepia(69%) saturate(1377%) hue-rotate(317deg) brightness(99%) contrast(102%)'
              }
            />
            <h2 className="review-title">{reviewData.review}</h2>
            <h2 className="name">{reviewData.name}</h2>
            <div className="bottom-container"></div>
          </div>

          <div className="img-container">
            <img
              src={imagesGroup1}
              alt="placeholder"
              className="images-group"
            />
          </div>
        </div>

        <div className="lawp-about-us-page-no-lawyer-yet">
          <div className="avatar-group">
            <img
              src={avatarImage1}
              alt="placeholder"
              className="avatar-side avatar"
            />
            <img
              src={avatarImage2}
              alt="placeholder"
              className="avatar-middle avatar"
            />
            <img
              src={avatarImage3}
              alt="placeholder"
              className="avatar-side avatar"
            />
          </div>
          <h2 className="text1">{LPTranslate('About_Us_Any_Questions')}</h2>
          <h3 className="text2">
            {LPTranslate('About_Us_Any_Questions_Description')}
          </h3>
          <Button
            className="button-style"
            onClick={() => navigate('/')}
            data-cy="find-experts"
          >
            <a
              href={`${process.env.REACT_APP_DNS_URI}/`}
              style={{ color: 'white' }}
              onClick={(e) => e.stopPropagation()}
            >
              {LPTranslate('HFLY_Info_4_Action_Button')}
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
