import React, { useState, useEffect } from 'react';

import moment from 'moment';
import jwt from 'jwt-decode';

import { useNavigate } from 'react-router';
import { useMutation } from '@apollo/client';

import Box from '@mui/material/Box';

import SimpleTextField from 'Components/Inputs/SimpleTextField';
import ConfirmSendingMessageToLawyerModal from 'Components/Modals/ConfirmSendingMessageToLawyerModal';
import { validateEmail } from 'Services/Utils/validations';

import { MINI_REGISTER } from 'Services/Queries/user';
// adaugat token in input si schimbat logica pe back-end
import { SEND_MESSAGE_TO_LAWYER_VIA_EMAIL } from 'Services/Queries/user';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import avatar from 'Assets/pictures/AvatarProfile.png';
import check from 'Assets/pictures/whiteStepperCheck.svg';
import arrowLeft from 'Assets/pictures/black-arrow-left.svg';

import styles from 'Assets/styles/lawyer/SendMessageToLawyerNotLoggedNameModal.module.scss';
import RegisterCheckboxComponent from 'Components/CommonComponents/RegisterCheckboxComponent';

//navigate(-1)

// miniregister
const SendMessageToLawyerNotLoggedNameModal = (props) => {
  let { lawyer, client, description, shownModal, formValues, formErrors } =
    props;

  const [sendMessageToLawyer, { data: sendMessageToLawyerData }] = useMutation(
    SEND_MESSAGE_TO_LAWYER_VIA_EMAIL
  );
  const [miniRegister, { data: miniRegisterData }] = useMutation(MINI_REGISTER);

  const [errorMessage, setErrorMessage] = useState('');

  const [goToRegister, setGoToRegister] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [marketingAccepted, setMarketingAccepted] = useState(false);

  const navigate = useNavigate();

  const indexWeekday = {
    1: 'Montag',
    2: 'Dienstag',
    3: 'Mittwoch',
    4: 'Donnerstag',
    5: 'Freitag',
    6: 'Samstag',
    7: 'Sonntag',
  };

  const [articleImage, setArticleImage] = useState(null);

  useEffect(() => {
    if (lawyer.profileImage) {
      setArticleImage(lawyer.profileImage);
    } else {
      setArticleImage(null);
    }
  }, [lawyer.profileImage]);

  const handleArrowClose = (e) => {
    e.preventDefault();
    props.handleCloseModal(0);
  };

  const callSendMessage = async (token, token_decoded) => {
    let date = moment();
    try {
      await sendMessageToLawyer({
        variables: {
          input: {
            token: token,
            lawyer_name: lawyer.first_name + ' ' + lawyer.last_name,
            lawyer_email: lawyer.email,
            client_firstname: formValues.firstName,
            client_lastname: formValues.lastName,
            client_email: formValues.email,
            description: description,
            messageDate:
              indexWeekday[date.weekday()] +
              ', ' +
              date.format('DD.MM.YYYY, hh:mm') +
              ' Uhr',
            userRole: token_decoded.role,
          },
        },
      });
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isError = false;
    let newInputErrors = {};

    if (formValues.email.length === 0) {
      isError = true;
      newInputErrors.email = LPTranslate('Error_Email_Required');
    } else if (!validateEmail(formValues.email)) {
      isError = true;
      newInputErrors.email = LPTranslate('Error_Valid_Email');
    }

    if (formValues.firstName.length === 0) {
      isError = true;
      newInputErrors.firstName = LPTranslate('Error_First_Name_Required');
    } else if (formValues.firstName.length > 128) {
      isError = true;
      newInputErrors.firstName = LPTranslate('Error_First_Name_Too_Long');
    }
    if (formValues.lastName.length === 0) {
      isError = true;
      newInputErrors.lastName = LPTranslate('Error_Last_Name_Required');
    } else if (formValues.lastName.length > 128) {
      isError = true;
      newInputErrors.lastName = LPTranslate('Error_Last_Name_Too_Long');
    }

    props.setFormErrors(newInputErrors);

    if (isError) return;

    try {
      let response = await miniRegister({
        variables: {
          input: {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            email: formValues.email,
            role: 'client',
            type: 'mini_register_message',
            marketingAccepted: marketingAccepted,
          },
        },
      });

      if (response.data.miniRegister.status === '204') {
        setErrorMessage(response.data.miniRegister.message);
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    if (miniRegisterData && miniRegisterData.miniRegister) {
      if (miniRegisterData.miniRegister.status === '204') {
        setErrorMessage(miniRegisterData.miniRegister.message);
      } else if (miniRegisterData.miniRegister.jwtToken) {
        // mutatie set appointment
        const token_decoded = jwt(miniRegisterData.miniRegister.jwtToken);
        callSendMessage(
          `Bearer ${miniRegisterData.miniRegister.jwtToken}`,
          token_decoded
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [miniRegisterData]);

  useEffect(() => {
    const fetch = () => {
      if (
        sendMessageToLawyerData &&
        sendMessageToLawyerData.sendMessageToLawyer &&
        sendMessageToLawyerData.sendMessageToLawyer.error
      ) {
        setErrorMessage(sendMessageToLawyerData.sendMessageToLawyer.error);
        return;
      }

      if (
        sendMessageToLawyerData &&
        sendMessageToLawyerData.sendMessageToLawyer.message ===
          'message arrived!'
      ) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'client-send-message-custom-event',
        });
        props.changeModal(2);
      } else if (
        sendMessageToLawyerData &&
        sendMessageToLawyerData.sendMessageToLawyer.error ===
          'You have to be logged in!'
      ) {
        // TODO: add translation
        setErrorMessage('You have to be logged in!');

        let isError = false;
        let newInputErrors = {
          firstName: '',
          lastName: '',
          email: '',
        };

        isError = true;
        newInputErrors.email =
          'Bitte loggen Sie sich zuerst ein und senden Sie dann Ihre Nachricht!';

        props.setFormErrors(newInputErrors);

        if (isError) return;
      } else if (
        sendMessageToLawyerData &&
        sendMessageToLawyerData.sendMessageToLawyer.message ===
          'message arrived but user does not exist in database!'
      ) {
        setGoToRegister(true);
        props.changeModal(2);
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMessageToLawyerData]);

  return (
    <>
      {shownModal === 1 ? (
        <Box
          className={
            styles['send-message-to-lawyer-not-logged-name-modal-container']
          }
        >
          <Box
            className={
              styles['send-message-to-lawyer-not-logged-scroll-container']
            }
          >
            <form
              className={
                styles['send-message-to-lawyer-not-logged-name-modal-content']
              }
              id={'send-lawyer-not-logged-name-message-form'}
              onSubmit={handleSubmit}
            >
              <div
                className={
                  styles[
                    'send-message-to-lawyer-not-logged-name-modal-text-and-supporting-text'
                  ]
                }
              >
                <img
                  alt="arrow left"
                  data-cy="arrow-left"
                  src={arrowLeft}
                  onClick={(e) => handleArrowClose(e)}
                  className={
                    styles[
                      'send-message-to-lawyer-not-logged-name-modal-arrow-left'
                    ]
                  }
                />

                <h2
                  className={
                    styles['send-message-to-lawyer-not-logged-name-modal-text']
                  }
                >
                  {LPTranslate('USM_Title_1_Step_2')} {lawyer.first_name}{' '}
                  {lawyer.last_name} {LPTranslate('USM_Title_2_Step_2')}
                </h2>
                <h3
                  className={
                    styles[
                      'send-message-to-lawyer-not-logged-name-modal-supporting-text'
                    ]
                  }
                >
                  {LPTranslate('USM_Description_1')}{' '}
                  {lawyer.first_name + ' ' + lawyer.last_name}
                  {LPTranslate('USM_Description_2')}.
                </h3>
              </div>

              <div
                className={
                  styles[
                    'send-message-to-lawyer-not-logged-name-modal-avatar-label-group'
                  ]
                }
              >
                {articleImage !== null ? (
                  <img
                    alt="article"
                    src={articleImage}
                    className={
                      styles[
                        'send-message-to-lawyer-not-logged-name-modal-profile-image'
                      ]
                    }
                  />
                ) : (
                  <img
                    alt="avatar"
                    className={
                      styles[
                        'send-message-to-lawyer-not-logged-name-modal-profile-image'
                      ]
                    }
                    src={avatar}
                  />
                )}
                <div
                  className={
                    styles[
                      'send-message-to-lawyer-not-logged-name-modal-name-and-email'
                    ]
                  }
                >
                  <h3
                    className={
                      styles[
                        'send-message-to-lawyer-not-logged-name-modal-name'
                      ]
                    }
                  >
                    {lawyer.first_name} {lawyer.last_name}
                  </h3>

                  <h3
                    className={
                      styles[
                        'send-message-to-lawyer-not-logged-name-modal-email'
                      ]
                    }
                  >
                    {lawyer.email}
                  </h3>
                </div>
              </div>

              <h3
                className={
                  styles['send-message-to-lawyer-not-logged-name-modal-frame19']
                }
              >
                “{description}”
              </h3>

              <div
                className={
                  styles[
                    'send-message-to-lawyer-not-logged-name-modal-first-name-input-field'
                  ]
                }
              >
                <SimpleTextField
                  data_cy="first-name-input"
                  title={LPTranslate('Input_First_Name')}
                  placeholder={LPTranslate('Input_First_Name_Placeholder')}
                  onChange={(value) => {
                    props.handleInputChange('firstName', value);
                    props.setFormErrors({
                      ...formErrors,
                      firstName: '',
                    });
                  }}
                  value={formValues.firstName}
                  type="text"
                  errorMessage={formErrors.firstName}
                />
              </div>

              <div
                className={
                  styles[
                    'send-message-to-lawyer-not-logged-name-modal-last-name-input-field'
                  ]
                }
              >
                <SimpleTextField
                  data_cy="last-name-input"
                  title={LPTranslate('Input_Last_Name')}
                  placeholder={LPTranslate('Input_Last_Name_Placeholder')}
                  onChange={(value) => {
                    props.handleInputChange('lastName', value);
                    props.setFormErrors({
                      ...formErrors,
                      lastName: '',
                    });
                  }}
                  value={formValues.lastName}
                  type="text"
                  errorMessage={formErrors.lastName}
                />
              </div>

              <div
                className={
                  styles[
                    'send-message-to-lawyer-not-logged-name-modal-email-input-field'
                  ]
                }
              >
                <SimpleTextField
                  data_cy="email-input"
                  title="Email*"
                  placeholder={LPTranslate('Common_Email_Placeholder')}
                  onChange={(value) => {
                    props.handleInputChange('email', value);
                    props.setFormErrors({
                      ...formErrors,
                      email: '',
                    });
                  }}
                  value={formValues.email}
                  type="text"
                  errorMessage={formErrors.email}
                />
              </div>

              <RegisterCheckboxComponent
                termsAccepted={termsAccepted}
                setTermsAccepted={setTermsAccepted}
                marketingAccepted={marketingAccepted}
                setMarketingAccepted={setMarketingAccepted}
              />
            </form>
            {errorMessage && (
              <p
                className={
                  styles[
                    'send-message-to-lawyer-not-logged-modal-error-message'
                  ]
                }
              >
                {errorMessage}
              </p>
            )}

            <div
              className={
                styles['send-message-to-lawyer-not-logged-name-modal-actions']
              }
            >
              <button
                data-cy="cancel-button"
                className={
                  styles[
                    'send-message-to-lawyer-not-logged-name-modal-cancel-button'
                  ]
                }
                onClick={props.handleClose}
              >
                {LPTranslate('Button_Cancel')}
              </button>

              {termsAccepted ? (
                <button
                  data-cy="submit-button"
                  className={
                    styles[
                      'send-message-to-lawyer-not-logged-modal-success-button'
                    ]
                  }
                  type="submit"
                  form="send-lawyer-not-logged-name-message-form"
                >
                  {/* {LPTranslate('LPP_Header_Send_Message')} */}
                  OK
                </button>
              ) : (
                <button
                  data-cy="submit-button"
                  disabled
                  className={
                    styles[
                      'send-message-to-lawyer-not-logged-modal-success-button-disabled'
                    ]
                  }
                  type="submit"
                  form="send-lawyer-not-logged-name-message-form"
                >
                  {/* {LPTranslate('LPP_Header_Send_Message')} */}
                  OK
                </button>
              )}
            </div>

            <div className={styles['row']}>
              <h3 className={styles['loginText']}>
                {LPTranslate('Common_Already_Have_Account_Question')}
              </h3>

              <div className={styles['loginButton']}>
                <button
                  className={styles['loginButtonBase']}
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  <h3 className={styles['loginButtonText']}>
                    {LPTranslate('Common_Login_Now')}
                  </h3>
                </button>
              </div>
            </div>

            <div className={styles['stepper-content']}>
              <div className={styles['check-step-icon-base']}>
                <div className={styles['checked-icon-content']}>
                  <img
                    className={styles['check-icon']}
                    src={check}
                    alt="checked"
                  />
                </div>
              </div>

              <div className={styles['step-icon-base']}>
                <div className={styles['selected-icon-content']}>
                  <div className={styles['selected-dot']} />
                </div>
              </div>
            </div>
          </Box>
        </Box>
      ) : (
        <ConfirmSendingMessageToLawyerModal
          lawyer_name={lawyer.first_name + ' ' + lawyer.last_name}
          client={client}
          goToRegister={goToRegister}
          handleClose={props.handleClose}
          client_firstname={formValues.firstName}
          client_lastname={formValues.lastName}
          client_email={formValues.email}
        />
      )}
    </>
  );
};
export default SendMessageToLawyerNotLoggedNameModal;
