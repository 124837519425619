import React, { useState } from 'react';

import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

import SimpleTextField from 'Components/Inputs/SimpleTextField';

import {
  setLawyerStepper,
  setClientStepper,
  addFirstname,
  addLastname,
  addRegisterEmail,
} from 'Services/Redux/reducers/RegisterReducer';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import anwadoLogo from 'Assets/pictures/logomark.svg';
import styles from 'Assets/styles/registerClient/registerFormComponent.module.scss';
import RegisterCheckboxComponent from 'Components/CommonComponents/RegisterCheckboxComponent';

const RegisterFormComponent = (props) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [termsAccepted, setTermsAccepted] = useState(false);

  return (
    <div className={styles['register-container']}>
      <div className={styles['full-width-header-navigation']}>
        <div className={styles['full-width-header']}>
          <div className={styles['full-width-header-container']}>
            <button
              data-cy="register-lawyer-button"
              className={styles['full-width-header-lawyerButton']}
              onClick={() => {
                dispatch(addFirstname(''));
                dispatch(addLastname(''));
                dispatch(addRegisterEmail(''));
                dispatch(setLawyerStepper(0));
                dispatch(setClientStepper(0));

                navigate('/registerLawyer', {
                  state: {
                    isSentFromLawyerPage: false,
                  },
                });
              }}
            >
              {LPTranslate('RC_Form_Badge')}
            </button>
          </div>
        </div>
      </div>
      <div className={styles['main-container']}>
        <div className={styles['content-container']}>
          <div className={styles['header-and-form']}>
            <div className={styles['text-supporting-text']}>
              <img src={anwadoLogo} alt="anwadoLogo"></img>
              <h1 className={styles['header-text']}>
                {LPTranslate('RC_Form_Title')}
              </h1>
              <h2 className={styles['header-supporting-text']}>
                {LPTranslate('RC_Form_Description')}
              </h2>
            </div>
            <div className={styles.rightSectionInputs}>
              <form
                className={styles.rightSectionForm}
                id="register-form"
                onSubmit={props.handleSubmit}
              >
                <div className={styles.firstNameInputField}>
                  <div className={styles.inputWithLabel}>
                    <SimpleTextField
                      data_cy="first-name-input"
                      title={LPTranslate('Input_First_Name')}
                      placeholder={LPTranslate('Input_First_Name_Placeholder')}
                      onChange={(value) => {
                        props.handleInputChange('firstName', value);
                        props.setFormErrors({
                          ...props.formErrors,
                          firstName: '',
                        });
                      }}
                      value={props.formValues.firstName}
                      type="text"
                      errorMessage={props.formErrors.firstName}
                    />
                  </div>
                </div>

                <div className={styles.lastNameInputField}>
                  <div className={styles.inputWithLabel}>
                    <SimpleTextField
                      data_cy="last-name-input"
                      title={LPTranslate('Input_Last_Name')}
                      placeholder={LPTranslate('Input_Last_Name_Placeholder')}
                      onChange={(value) => {
                        props.handleInputChange('lastName', value);
                        props.setFormErrors({
                          ...props.formErrors,
                          lastName: '',
                        });
                      }}
                      value={props.formValues.lastName}
                      type="text"
                      errorMessage={props.formErrors.lastName}
                    />
                  </div>
                </div>

                <div className={styles.emailInputField}>
                  <div className={styles.inputWithLabel}>
                    <SimpleTextField
                      data_cy="email-address-input"
                      title="Email*"
                      placeholder={LPTranslate('Common_Email_Placeholder')}
                      onChange={(value) => {
                        props.handleInputChange('email', value);
                        props.setFormErrors({
                          ...props.formErrors,
                          email: '',
                        });
                      }}
                      value={props.formValues.email}
                      type="text"
                      errorMessage={props.formErrors.email}
                    />
                  </div>
                </div>

                <RegisterCheckboxComponent
                  termsAccepted={termsAccepted}
                  setTermsAccepted={setTermsAccepted}
                  marketingAccepted={props.marketingAccepted}
                  setMarketingAccepted={props.setMarketingAccepted}
                />
              </form>

              <div className={styles.actions}>
                {props.errorMessage && (
                  <p className={styles.errorMessage}>{props.errorMessage}</p>
                )}

                {termsAccepted && !props.checkIfClientExistsLoading ? (
                  <button
                    className={styles.registerButton}
                    type="submit"
                    form="register-form"
                    data-cy="submit-button"
                  >
                    <h3 className={styles.buttonText}>
                      {LPTranslate('Button_Create_Account')}
                    </h3>
                  </button>
                ) : (
                  <button
                    disabled
                    className={styles.disabledButton}
                    data-cy="submit-button"
                  >
                    <h3 className={styles.buttonText}>
                      {LPTranslate('Button_Create_Account')}
                    </h3>
                  </button>
                )}
              </div>
            </div>

            <div className={styles.row}>
              <h3 className={styles.loginText}>
                {LPTranslate('Common_Already_Have_Account_Question')}
              </h3>

              <div className={styles.loginButton}>
                <button
                  className={styles.loginButtonBase}
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  <h3 className={styles.loginButtonText}>
                    {LPTranslate('Common_Login_Now')}
                  </h3>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterFormComponent;
