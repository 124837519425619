import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import NewSubscriptionUpdateModal from 'Components/Modals/NewSubscriptionUpdateModal';
import SubscriptionModalCardBenefiets from 'Components/Subscription/SubscriptionModalCardBenefiets.js';

import subscriptionPageTextVariables from 'Services/TextVariables/subscriptionPageTextVariables.js';
import subscriptionTextVariables from 'Services/TextVariables/subscriptionCardTextVariables.js';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/lawyer/SubscriptionModal/SubscriptionModalCard.module.scss';

const NewSubscriptionModalCard = (props) => {
  const {
    price,
    lawyerSubscription,
    recurringInterval,
    buttonRedirect,
    role,
    schedule,
    handleUpdate,
    handlePayButton,
  } = props;
  const navigate = useNavigate();

  // const [priceReccuringInterval, setPriceReccuringInterval] = useState(
  //   price.recurring_interval
  // );
  const [priceId] = useState(price.price_id);
  const [cardTier] = useState(price.stripe_product.tier);
  const [subscriptionCardPrice] = useState(
    price.unit_amount / 100
  );

  const ButtonShown = () => {
    if (buttonRedirect) {
      switch (role) {
        case 'lawyer':
          return (
            <button
              className={styles['update-subscription-button']}
              onClick={() => {
                navigate('/subscriptionPage', {
                  state: {
                    isSentFromLawyerPage: false,
                  },
                });
              }}
            >
              {LPTranslate('LSS_Get_Started_Plan_Title')}
            </button>
          );

        case '':
          return (
            <button
              className={styles['update-subscription-button']}
              data-cy="subscription-button"
              onClick={() => {
                localStorage.setItem('recurringInterval', recurringInterval);
                localStorage.setItem('cardTier', cardTier);
                navigate('/registerLawyer', {
                  state: {
                    isSentFromLawyerPage: false,
                  },
                });
              }}
            >
              {LPTranslate('LSS_Get_Started_Plan_Title')}
            </button>
          );

        default:
          break;
      }
    }
    var type = 'update';
    switch (lawyerSubscription.tier) {
      case 'Platin':
        if (recurringInterval === 'year') {
          if (lawyerSubscription.recurringInterval === 'year') {
            if (cardTier === 'Silver' || cardTier === 'Gold') {
              type = 'downgrade';
            } else if (cardTier === 'Platin') {
              return (
                <button className={styles['actual-subscription-button']}>
                  {LPTranslate('LSS_Actual_Plan_Title')}
                </button>
              );
            }
          } else if (lawyerSubscription.recurringInterval === 'month') {
            if (cardTier === 'Silver') {
              type = 'downgrade';
            } else {
              type = 'update';
            }
          }
        } else if (recurringInterval === 'month') {
          if (lawyerSubscription.recurringInterval === 'year') {
            type = 'downgrade';
          } else if (lawyerSubscription.recurringInterval === 'month') {
            if (cardTier === 'Silver' || cardTier === 'Gold') {
              type = 'downgrade';
            } else if (cardTier === 'Platin') {
              return (
                <button className={styles['actual-subscription-button']}>
                  {LPTranslate('LSS_Actual_Plan_Title')}
                </button>
              );
            }
          }
        }
        if (
          schedule.recurringInterval === recurringInterval &&
          schedule.type === cardTier
        ) {
          type = 'CancelSchedule';
        }
        if (schedule.type === cardTier && schedule.type === 'Silver') {
          type = 'CancelSchedule';
        }
        // avem type-ul corect
        // trebuie sa trimitem noul interval, noul tier de subscription, id-ul pretului, si tier-ul curent cu recurring interval-ul curent
        // subscripion update modal trebuie sa fie element stripe, primeste de la backend pretul si face un intent, daca se apasa pe update, trebuie sa foloseasca payment method-ul avocatului
        // de creat functia de update in acest modal
        return (
          // <p>new update modal</p>
          // <Elements stripe={stripePromise}>
          <NewSubscriptionUpdateModal
            type={type}
            new_recurring_interval={lawyerSubscription.recurringInterval}
            old_recurring_interval={recurringInterval}
            price_id={priceId}
            currentSubscription={lawyerSubscription.tier}
            newSubscription={cardTier}
            handleUpdate={handleUpdate}
          />
          // </Elements>
        );
      case 'Gold':
        if (recurringInterval === 'year') {
          if (lawyerSubscription.recurringInterval === 'year') {
            if (cardTier === 'Silver') {
              type = 'downgrade';
            } else if (cardTier === 'Platin') {
              type = 'update';
            } else if (cardTier === 'Gold') {
              return (
                <button className={styles['actual-subscription-button']}>
                  {LPTranslate('LSS_Actual_Plan_Title')}
                </button>
              );
            }
          } else if (lawyerSubscription.recurringInterval === 'month') {
            if (cardTier === 'Silver') {
              type = 'downgrade';
            } else {
              type = 'update';
            }
          }
        } else if (recurringInterval === 'month') {
          if (lawyerSubscription.recurringInterval === 'year') {
            type = 'downgrade';
          } else if (lawyerSubscription.recurringInterval === 'month') {
            if (cardTier === 'Silver') {
              type = 'downgrade';
            } else if (cardTier === 'Platin') {
              type = 'update';
            } else if (cardTier === 'Gold') {
              return (
                <button className={styles['actual-subscription-button']}>
                  {LPTranslate('LSS_Actual_Plan_Title')}
                </button>
              );
            }
          }
        }
        if (
          schedule.recurringInterval === recurringInterval &&
          schedule.type === cardTier
        ) {
          type = 'CancelSchedule';
        }
        return (
          // <Elements stripe={stripePromise}>
          <NewSubscriptionUpdateModal
            type={type}
            new_recurring_interval={recurringInterval}
            old_recurring_interval={lawyerSubscription.recurringInterval}
            price_id={priceId}
            currentSubscription={lawyerSubscription.tier}
            newSubscription={cardTier}
            handleUpdate={handleUpdate}
          />
          // </Elements>
          // <SubscriptionUpdateModal
          //   type={type}
          //   new_recurring_interval={currentRecurringInterval}
          //   old_recurring_interval={currentSubscription}
          //   price_id={priceId}
          //   currentSubscription={currentSubscription}
          //   newSubscription={cardTier}
          //   handleUpdate={handleUpdate}
          // />
        );
      case 'Silver':
        if (cardTier === 'Silver') {
          return (
            <button className={styles['actual-subscription-button']}>
              {LPTranslate('LSS_Actual_Plan_Title')}
            </button>
          );
        } else if (cardTier === 'Platin' || cardTier === 'Gold') {
          return (
            <button
              className={styles['update-subscription-button']}
              onClick={() => {
                handlePayButton(priceId);
              }}
            >
              {LPTranslate('LSS_Get_Started_Plan_Title')}
            </button>
          );
        }
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className={styles['card-component']}>
        <div className={styles['card-header']}>
          <div className={styles['card-header-and-price']}>
            <div className={styles['card-header-and-badge']}>
              <div className={styles['heading-text']}>
                {subscriptionPageTextVariables[cardTier].cardTitle}
              </div>
              {cardTier === 'Gold' && (
                <div className={styles['badge']}>
                  <div className={styles['badge-color']}>
                    <div className={styles['badge-text']}>
                      {LPTranslate('LSUS_Most_Popular')}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles['price-container']}>
              {recurringInterval === 'month' ? (
                <div className={styles['price-text']}>
                  €{subscriptionCardPrice}
                </div>
              ) : (
                <div className={styles['price-text']}>
                  €{subscriptionCardPrice / 12}
                </div>
              )}

              <div className={styles['price-per-time']}>
                <div className={styles['price-per-time-text']}>
                  {subscriptionPageTextVariables.perMonth}
                </div>
              </div>
            </div>
            <div className={styles['under-price-text']}>
              {cardTier === 'Silver'
                ? `${subscriptionPageTextVariables[cardTier].priceDescription_1}`
                : `${subscriptionPageTextVariables[cardTier].priceDescription_1}${subscriptionCardPrice}€${subscriptionPageTextVariables[cardTier].priceDescription_2}`}
            </div>
          </div>

          <div className={styles['button-container']}>{ButtonShown()}</div>
        </div>
        <div className={styles['divider']} />
        <div className={styles['card-content']}>
          <div className={styles['card-content-header']}>
            <div className={styles['card-content-header-title']}>
              {subscriptionPageTextVariables[cardTier].benefietsTitle}
            </div>
            <div className={styles['card-content-header-undertitle']}>
              {subscriptionPageTextVariables[cardTier].benefietsUnderTitle}
            </div>
          </div>
        </div>
        <div className={styles['card-content-container']}>
          {cardTier === 'Silver' &&
            subscriptionTextVariables.Benefietsbasic.map((row, index) => (
              <SubscriptionModalCardBenefiets checkDetails={row} key={index} />
            ))}

          {cardTier === 'Gold' &&
            subscriptionTextVariables.Benefietsgold.map((row, index) => (
              <SubscriptionModalCardBenefiets checkDetails={row} key={index} />
            ))}

          {cardTier === 'Platin' &&
            subscriptionTextVariables.Benefietsplat.map((row, index) => (
              <SubscriptionModalCardBenefiets checkDetails={row} key={index} />
            ))}
        </div>
      </div>
    </>
  );
};

export default NewSubscriptionModalCard;
