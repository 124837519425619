import React from 'react';

import styles from 'Assets/styles/step.module.scss';
import check from 'Assets/pictures/stepperCheck.svg';

const step = (props) => {
  return (
    <div className={styles.stepBlock}>
      {/* <div className={styles.circleWrapper} onClick={props.updateStep(props.index)}> */}

      {props.selected && !props.isChecked && (
        <>
          <div className={styles.conectorWrap}>
            <div className={styles.stepIconBase}>
              <div className={styles.selectedIconContent}>
                <div className={styles.selectedDot} />
              </div>
            </div>
            {props.index !== props.last && (
              <>
                <div className={styles.unselectedConnector} />
              </>
            )}
          </div>

          <div className={styles.stepTextAndSupportingText}>
            <p className={styles.selectedStepText}>{props.label}</p>
            <span className={styles.selectedStepSupportingText}>
              {props.description}
            </span>
          </div>
        </>
      )}

      {!props.selected && !props.isChecked && (
        <>
          <div className={styles.conectorWrap}>
            <div className={styles.unselectedStepIconBase}>
              <div className={styles.unSelectedIconContent}>
                <div className={styles.unSelectedDot} />
              </div>
            </div>
            {props.index !== props.last && (
              <>
                <div className={styles.unselectedConnector} />
              </>
            )}
          </div>

          <div className={styles.stepTextAndSupportingText}>
            <p className={styles.stepText}>{props.label}</p>
            <span className={styles.stepSupportingText}>
              {props.description}
            </span>
          </div>
        </>
      )}

      {props.isChecked && !props.selected && (
        <>
          <div className={styles.conectorWrap}>
            <div className={styles.checkStepIconBase}>
              <div className={styles.CheckedIconContent}>
                <img className={styles.checkIcon} src={check} alt="checked" />
              </div>
            </div>
            {props.index !== props.last && (
              <div className={styles.selectedConnector} />
            )}
          </div>

          <div className={styles.stepTextAndSupportingText}>
            <p className={styles.stepText}>{props.label}</p>
            <span className={styles.stepSupportingText}>
              {props.description}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default step;
