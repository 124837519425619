import React, { useState } from 'react';

import SubscriptionModalCardBenefiets from 'Components/Subscription/SubscriptionModalCardBenefiets.js';

import subscriptionTextVariables from 'Services/TextVariables/subscriptionCardTextVariables.js';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/lawyer/SubscriptionModal/SubscriptionModalCard.module.scss';

const SubscriptionCard = (props) => {
  const {
    subscriptionPageTextVariables,
    recurring_interval,
    cardTier,
    SubscriptionCardPrice,
    handleChangePlan,
    plans,
    isDisabled
  } = props;

  const ButtonShown = () => {
    if (plans[recurring_interval][cardTier] === 1) {
      return (
        <button
          className={styles['actual-subscription-button']}
          data-cy="selected-plan"
        >
          {LPTranslate('Common_Selected')}
        </button>
      );
    } else {
      return (
        <button
          data-cy="select-plan-button"
          className={styles['update-subscription-button']}
          onClick={() => handleChangePlan(recurring_interval, cardTier)}
          disabled={isDisabled}
        >
          {LPTranslate('Common_Select_Plan')}
        </button>
      );
    }
  };

  const [SubscriptionCardHeadingBadge] = useState('');

  return (
    <div className={styles['card-component']}>
      <div className={styles['card-header']}>
        <div className={styles['card-header-and-price']}>
          <div className={styles['card-header-and-badge']}>
            <div className={styles['heading-text']}>
              {subscriptionPageTextVariables[cardTier].cardTitle}
            </div>
            {SubscriptionCardHeadingBadge?.length > 0 && (
              <div className={styles['badge']}>
                <div className={styles['badge-color']}>
                  <div className={styles['badge-text']}>
                    {subscriptionPageTextVariables.mostPopular}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={styles['price-container']}>
            {recurring_interval === 'month' ? (
              <div className={styles['price-text']}>
                €{SubscriptionCardPrice}
              </div>
            ) : (
              <div className={styles['price-text']}>
                €{SubscriptionCardPrice / 12}
              </div>
            )}

            <div className={styles['price-per-time']}>
              <div className={styles['price-per-time-text']}>
                {subscriptionPageTextVariables.perMonth}
              </div>
            </div>
          </div>
          <div className={styles['under-price-text']}>
            {cardTier === 'Silver'
              ? `${subscriptionPageTextVariables[cardTier].priceDescription_1}`
              : `${subscriptionPageTextVariables[cardTier].priceDescription_1}${SubscriptionCardPrice}€${subscriptionPageTextVariables[cardTier].priceDescription_2}`}
          </div>
        </div>

        <div className={styles['button-container']}>{ButtonShown()}</div>
      </div>
      <div className={styles['divider']} />
      <div className={styles['card-content']}>
        <div className={styles['card-content-header']}>
          <div className={styles['card-content-header-title']}>
            {subscriptionPageTextVariables[cardTier].benefietsTitle}
          </div>
          <div className={styles['card-content-header-undertitle']}>
            {subscriptionPageTextVariables[cardTier].benefietsUnderTitle}
          </div>
        </div>
      </div>
      <div className={styles['card-content-container']}>
        {cardTier === 'Silver' &&
          subscriptionTextVariables.Benefietsbasic.map((row, index) => (
            <SubscriptionModalCardBenefiets checkDetails={row} key={index} />
          ))}

        {cardTier === 'Gold' &&
          subscriptionTextVariables.Benefietsgold.map((row, index) => (
            <SubscriptionModalCardBenefiets checkDetails={row} key={index} />
          ))}

        {cardTier === 'Platin' &&
          subscriptionTextVariables.Benefietsplat.map((row, index) => (
            <SubscriptionModalCardBenefiets checkDetails={row} key={index} />
          ))}
      </div>
    </div>
  );
};

export default SubscriptionCard;
