import { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import {
  GET_FAVORITELAWYER,
  ADD_FAVORITELAWYER,
  DELETE_FAVORITELAWYER,
} from 'Services/Queries/client';

import FavoriteLawyerNotSelected from 'Assets/pictures/FavoriteLawyerNotSelected.svg';
import FavoriteLawyerSelected from 'Assets/pictures/FavoriteLawyerSelected.svg';

// import style from 'Assets/styles/common/FavoriteLawyerIcons.module.scss';
import 'Assets/styles/common/FavoriteLawyerIcons.css';

const FavoriteLawyer = (props) => {
  const { user_id, lawyer_id, role_id, role } = props;

  const [favVer, setFavVer] = useState('');
  const [curentLawyer, setCurentLawyer] = useState(false);
  const [createFavoriteLawyer, favObject] = useMutation(ADD_FAVORITELAWYER);
  const [deleteFavoriteLawyer] = useMutation(DELETE_FAVORITELAWYER);

  //TO DO: move this in the above component and get all favorite lawyers at once
  const FavoriteLawyerResponse = useQuery(GET_FAVORITELAWYER, {
    variables: {
      input: {
        user_id: user_id,
        lawyer_id: lawyer_id,
      },
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (
      FavoriteLawyerResponse.loading === false &&
      FavoriteLawyerResponse.data.favoriteLawyer.message ===
        "FavoriteLawyer doesn't exists"
    ) {
      setFavVer(false);
    } else {
      setFavVer(true);
    }

    if (role_id === lawyer_id && role === 'lawyer') {
      setCurentLawyer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FavoriteLawyerResponse.data]);

  const showFavoriteButtonWhenLogged = () => {
    if (role !== '') {
      return (
        <button
          data-cy={'favorite-button'}
          className={'not-favorite-lawyer'}
          onClick={handleFavoriteLawyer}
        >
          <img
            className={'favorite-lawyer-img'}
            src={FavoriteLawyerNotSelected}
            alt="FavoriteLawyerNotSelected"
          />
        </button>
      );
    } else if (role === '') {
      return (
        <button
          data-cy={'favorite-button'}
          className={'not-favorite-lawyer'}
          style={{ display: 'none' }}
          onClick={handleFavoriteLawyer}
        >
          <img
            className={'favorite-lawyer-img'}
            src={FavoriteLawyerNotSelected}
            alt="FavoriteLawyerNotSelected"
          />
        </button>
      );
    }
  };

  const handleFavoriteLawyer = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (user_id) {
      if (favVer === false) {
        setFavVer(true);

        await createFavoriteLawyer({
          variables: {
            input: {
              user_id: user_id,
              lawyer_id: lawyer_id,
            },
          },
        });
      } else {
        setFavVer(false);

        await deleteFavoriteLawyer({
          variables: {
            input: {
              user_id: user_id,
              lawyer_id: lawyer_id,
            },
          },
        });
      }
    }
  };

  if (favObject.loading)
    return <img src={FavoriteLawyerSelected} alt="FavoriteLawyerSelected" />;
  if (favObject.error) return <p>Error :</p>;

  return (
    <div>
      {FavoriteLawyerResponse.loading === false && (
        <div>
          {curentLawyer === false && (
            <div>
              {favVer === true && (
                <button
                  data-cy={'favorite-button'}
                  className={'favorite-lawyer'}
                  onClick={handleFavoriteLawyer}
                >
                  <img
                    className={'favorite-lawyer-img'}
                    src={FavoriteLawyerSelected}
                    alt="FavoriteLawyerSelected"
                  />
                </button>
              )}

              {favVer === false && showFavoriteButtonWhenLogged()}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default FavoriteLawyer;
