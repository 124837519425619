import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';

import { GET_AREAS_OF_LAW } from 'Services/Queries/lawyer';
import SettingsMenu from 'Components/CommonComponents/SettingsMenu';
import EditLawyerArticleSection from 'Components/Lawyer/Edit/EditLawyerArticleSection';

import 'Assets/styles/operator/OperatorSettingsPage.scss';

const OperatorSettingsPage = () => {
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);
  const logged_user_id = clientInfoRedux.user_id;

  const [areasOfLawDB, setAreaOfLawDB] = useState([]);

  const areasOfLawQuery = useQuery(GET_AREAS_OF_LAW);

  useEffect(() => {
    if (
      !areasOfLawQuery.loading &&
      areasOfLawQuery.data !== undefined &&
      areasOfLawDB.length === 0
    ) {
      let dataAreas = [];

      for (let i = 0; i < areasOfLawQuery.data.getAreasOfLaw.length; i++) {
        dataAreas.push(areasOfLawQuery.data.getAreasOfLaw[i].name);
      }

      setAreaOfLawDB(dataAreas);
    }
  }, [areasOfLawQuery, areasOfLawDB]);

  return (
    <>
      <h1>Operator Article Page</h1>

      <SettingsMenu
        setUpdateComponent={'/OperatorArticle'}
        role={clientInfoRedux.role}
      />

      <EditLawyerArticleSection
        loading={false}
        error={false}
        areasOfLawDB={areasOfLawDB}
        userId={logged_user_id}
        profileImage={null}
      />
    </>
  );
};

export default OperatorSettingsPage;
