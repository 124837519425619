const AreasOfLaw_Color = {
  Abstammungsrecht: { background: '#F9F5FF', color: '#8440c9' },
  Agrarrecht: { background: '#EEF4FF', color: '#3a5ec7' },
  'Allgemeines Vertragsrecht': { background: '#FDF2FA', color: '#a65d8b' },
  Anwaltshaftung: { background: '#f7d5a6', color: '#7c6535' },
  Arbeitsrecht: { background: '#89f2fd', color: '#246676' },
  Architektenrecht: { background: '#f2e3a7', color: '#716c3c' },
  Archivrecht: { background: '#ecd1fc', color: '#873fa2' },
  Arzthaftungsrecht: { background: '#e1fec4', color: '#60a432' },
  Asylrecht: { background: '#f6cef7', color: '#8b4e87' },
  Auftragsrecht: { background: '#c0f3c2', color: '#4c7c52' },
  Ausländerrecht: { background: '#ffd2f3', color: '#b33284' },
  Bankrecht: { background: '#baffd9', color: '#2da06c' },
  'Bank- und Kapitalmarktrecht': { background: '#f9d7e1', color: '#a35a5f' },
  Baurecht: { background: '#fbdeff', color: '#ba35bd' },
  'Bau- und Architektenrecht': { background: '#f7f2b5', color: '#7f7a3f' },
  Beamtenrecht: { background: '#b6e8b7', color: '#526054' },
  Bergrecht: { background: '#ffdbf7', color: '#ba348f' },
  Berufsrecht: { background: '#aef3cd', color: '#3f765d' },
  Betäubungsmittelrecht: { background: '#ffdaef', color: '#b93472' },
  Betreuungsrecht: { background: '#a6f9de', color: '#33816f' },
  'Betriebliche Altersversorgung': { background: '#ffd4e1', color: '#b5334d' },
  Bibliotheksrecht: { background: '#9efae8', color: '#2e7e77' },
  Börsenrecht: { background: '#f1d1e7', color: '#75616d' },
  Bürgschaftsrecht: { background: '#cdf7c2', color: '#4c8945' },
  'Crypto Crime': { background: '#ded6f9', color: '#664f94' },
  Datenschutzrecht: { background: '#e5fcc1', color: '#6a9a37' },
  Deliktsrecht: { background: '#eaddff', color: '#7635bc' },
  Denkmalrecht: { background: '#d9f4ba', color: '#5e7d45' },
  Designrecht: { background: '#d5ddff', color: '#333fb6' },
  Domainrecht: { background: '#f7ecaf', color: '#817d3a' },
  'EDV-Recht': { background: '#a5e3fe', color: '#2a658d' },
  'Ehe- und Familienrecht': { background: '#f3f2b5', color: '#747944' },
  Enteignungsrecht: { background: '#93f1ff', color: '#246d82' },
  Erbrecht: { background: '#edd9a5', color: '#665f40' },
  Europarecht: { background: '#8cf5fc', color: '#266976' },
  Familienrecht: { background: '#fbd3b4', color: '#8f6535' },
  'Forderungseinzug ': { background: '#8ef6f9', color: '#2a6872' },
  'Fracht-/Speditionsrecht': { background: '#ffddbb', color: '#a1732d' },
  Gesellschaftsrecht: { background: '#86ebe6', color: '#305355' },
  Gesundheitsrecht: { background: '#fad1cb', color: '#975643' },
  Gewerberaummietrecht: { background: '#8df1eb', color: '#306062' },
  Gewerberecht: { background: '#ffd6d7', color: '#b63d33' },
  'Gewerblicher Rechtsschutz': { background: '#96f7ec', color: '#2f7372' },
  Grundbuchrecht: { background: '#fee4ff', color: '#c136b8' },
  Grundrechte: { background: '#cae9b1', color: '#54624d' },
  Grundstücksrecht: { background: '#fde8ff', color: '#c437c3' },
  Handelsrecht: { background: '#d6fac3', color: '#53933e' },
  'Handels- und Gesellschaftsrecht': {
    background: '#6af7ac',
    color: '#027337',
  },
  Haushaltsrecht: { background: '#e1d6ed', color: '#6c6c6c' },
  Immobilienrecht: { background: '#e8f4b7', color: '#6b7c43' },
  Individualarbeitsrecht: { background: '#dee6ff', color: '#3548bd' },
  Informationsrecht: { background: '#dcdea1', color: '#4c4d47' },
  Informationstechnologierecht: { background: '#a5e3fe', color: '#2a658d' },
  Inkassorecht: { background: '#c3e8ff', color: '#2f6da8' },
  'Insolvenz- und Sanierungsrecht': { background: '#e2dca4', color: '#535347' },
  'Internationale Gerichtsbarkeit': { background: '#a1fdff', color: '#27818d' },
  'Internationales Privatrecht': { background: '#f2d6af', color: '#746441' },
  'Internationales Recht': { background: '#a3fff9', color: '#288b8f' },
  'Internationales Strafrecht': { background: '#efd8ac', color: '#6d6243' },
  'Internationales Wirtschaftsrecht': {
    background: '#a7f7ff',
    color: '#297e92',
  },
  Internetrecht: { background: '#ffebb9', color: '#a08a2d' },
  'IT-Recht': { background: '#aff2ff', color: '#2a7b98' },
  Jagdrecht: { background: '#fffdc7', color: '#a3ab30' },
  Jugendstrafrecht: { background: '#b4e1f4', color: '#41647b' },
  Kapitalmarktrecht: { background: '#f0ffc5', color: '#7da92f' },
  Kartellrecht: { background: '#cddbf2', color: '#5a6379' },
  Kaufrecht: { background: '#d7ffce', color: '#3cb031' },
  Kindschaftsrecht: { background: '#f2d2df', color: '#786067' },
  Kirchenrecht: { background: '#9bedd2', color: '#3a6259' },
  'Kollektives Arbeitsrecht': { background: '#f5d2d7', color: '#83585a' },
  Kommunalrecht: { background: '#8dede1', color: '#335c5b' },
  Kreditsicherungsrecht: { background: '#f5d4be', color: '#816446' },
  Kriegsrecht: { background: '#93eae2', color: '#385a5a' },
  Landesrecht: { background: '#f3d5b7', color: '#796445' },
  Landwirtschaftsrecht: { background: '#affffd', color: '#2a9098' },
  Luftrecht: { background: '#ffddd0', color: '#b26232' },
  Maklerrecht: { background: '#97ead6', color: '#3a5c57' },
  'Marken- und Kennzeichnungsrecht': {
    background: '#ffe1e9',
    color: '#bf354c',
  },
  Markenrecht: { background: '#b3ffe1', color: '#2b9b7a' },
  Mediation: { background: '#ffebfa', color: '#c63895' },
  Medienrecht: { background: '#a9e8c4', color: '#475e53' },
  Medizinrecht: { background: '#fcedff', color: '#bd39c7' },
  Menschenrechte: { background: '#bee5b5', color: '#545b54' },
  'Miet- und Pachtrecht': { background: '#ebeaff', color: '#4c37c6' },
  'Miet- und Wohnungseigentumsrecht': {
    background: '#ebeaaf',
    color: '#6b5500',
  },
  Mietrecht: { background: '#cbffd2', color: '#31ae4e' },
  Migrationsrecht: { background: '#e8d5e1', color: '#696969' },
  Namensrecht: { background: '#b0ffe9', color: '#2b9985' },
  Naturschutzrecht: { background: '#ffe0cc', color: '#af6f31' },
  'Öffentliches Baurecht': { background: '#97e9dd', color: '#3a5a58' },
  'Öffentliches Dienstrecht': { background: '#f0d6bf', color: '#736450' },
  'Öffentliches Recht': { background: '#99e8ec', color: '#3a5a60' },
  Opferhilfe: { background: '#ffedc8', color: '#ab8f30' },
  Ordnungswidrigkeitenrecht: { background: '#99e8e6', color: '#3c5759' },
  Pachtrecht: { background: '#e3dbaf', color: '#575650' },
  Patentrecht: { background: '#b9f7ff', color: '#2d87a0' },
  Pferderecht: { background: '#cfe1ae', color: '#525350' },
  Pflegerecht: { background: '#f4edff', color: '#7f39c7' },
  Polizeirecht: { background: '#afe7c0', color: '#4d5e54' },
  Presserecht: { background: '#fdf5ff', color: '#bb40c9' },
  Produkthaftungsrecht: { background: '#c5e3b3', color: '#545753' },
  Prozessrecht: { background: '#c6ddf1', color: '#556377' },
  Raumordnungsrecht: { background: '#e4ffcd', color: '#5faf31' },
  'Recht der internationalen Organisationen': {
    background: '#d9d9e8',
    color: '#6b6b6b',
  },
  'Recht der Neuen Medien': { background: '#f6ffcf', color: '#8cb132' },
  Reiserecht: { background: '#c1f2ff', color: '#2e81a6' },
  Sachenrecht: { background: '#fff6d0', color: '#b2a632' },
  Schadensersatzrecht: { background: '#a4e6e9', color: '#435b5f' },
  Schenkungsrecht: { background: '#feffd9', color: '#a8b934' },
  Schiedsgerichtsbarkeit: { background: '#bce0ec', color: '#536269' },
  Schmerzensgeldrecht: { background: '#edffd0', color: '#74b232' },
  Schuldrecht: { background: '#e4efff', color: '#3661c1' },
  Schulrecht: { background: '#c3e4b7', color: '#575956' },
  Schwerbehindertenrecht: { background: '#e2d7de', color: '#676767' },
  Sexualstrafrecht: { background: '#bdffe3', color: '#2ea37d' },
  Sozialhilferecht: { background: '#ffebe9', color: '#c55237' },
  Sozialrecht: { background: '#a7e8ce', color: '#465e56' },
  Sozialversicherungsrecht: { background: '#ffe8da', color: '#b97434' },
  Speditionsrecht: { background: '#bcffef', color: '#2da292' },
  Sportrecht: { background: '#e8d7ce', color: '#656565' },
  'Sportrecht (seit 1.7.2019)': { background: '#ebabff', color: '#710694' },
  Staatshaftungsrecht: { background: '#a7e7dc', color: '#465c5a' },
  Staatsrecht: { background: '#ffefdc', color: '#bb8b34' },
  Steuerrecht: { background: '#ace6d4', color: '#4b5b58' },
  Stiftungsrecht: { background: '#e8d9bc', color: '#615f58' },
  Strafprozessrecht: { background: '#c5fffe', color: '#2f9fa9' },
  Strafrecht: { background: '#d0e1b3', color: '#545454' },
  Strafverfahrensrecht: { background: '#d1f4ff', color: '#3287b2' },
  Strafvollzugsrecht: { background: '#d3dfb7', color: '#555555' },
  Straßenrecht: { background: '#e9f6ff', color: '#377dc5' },
  Telekommunikationsrecht: { background: '#bee5bd', color: '#5b5b5b' },
  Tierrecht: { background: '#d2dce1', color: '#646464' },
  Transportrecht: { background: '#e3ffd7', color: '#4eb733' },
  Transportversicherungsrecht: { background: '#b0e4e5', color: '#4f595a' },
  'Transport- und Speditionsrecht': { background: '#b3c5ff', color: '#0030c2' },
  Umweltrecht: { background: '#fff4d7', color: '#b7a033' },
  Unterhaltsrecht: { background: '#cefcff', color: '#319cb0' },
  'Unternehmensrecht & Betriebsnachfolge': {
    background: '#e5d9c3',
    color: '#5e5e5e',
  },
  'Urheber- und Medienrecht': { background: '#e3f7ff', color: '#3a8cb2' },
  Urheberrecht: { background: '#cffff6', color: '#32b1a6' },
  Verbandsrecht: { background: '#dbdcc0', color: '#585858' },
  Verbraucherrecht: { background: '#dbf9ff', color: '#3496ba' },
  Vereinsrecht: { background: '#d1e0c0', color: '#5a5a5a' },
  Verfassungsrecht: { background: '#e3ffff', color: '#36b3c0' },
  Vergaberecht: { background: '#d5dec3', color: '#5b5b5b' },
  Verkehrsrecht: { background: '#b7e3e1', color: '#575757' },
  Verkehrsstrafrecht: { background: '#fff8e0', color: '#bead35' },
  Verkehrsunfallrecht: { background: '#bfe2da', color: '#5b5b5b' },
  Versicherungsrecht: { background: '#feffe4', color: '#aec136' },
  Vertragsrecht: { background: '#bee3c8', color: '#5b5b5b' },
  Verwaltungsorganisationsrecht: { background: '#fff9ed', color: '#c7a639' },
  Verwaltungsprozessrecht: { background: '#d5ffe5', color: '#33b672' },
  Verwaltungsrecht: { background: '#dddbce', color: '#606060' },
  Völkerrecht: { background: '#d4ffeb', color: '#33b585' },
  Wahlrecht: { background: '#fefff3', color: '#afc83e' },
  Wehrrecht: { background: '#c3e1d3', color: '#5c5c5c' },
  Werkvertragsrecht: { background: '#eeffdb', color: '#6eba34' },
  Wettbewerbsrecht: { background: '#c7e0d8', color: '#5e5e5e' },
  'Wirtschafts- und Unternehmensrecht': {
    background: '#e5ffdc',
    color: '#4abb34',
  },
  Wirtschaftsrecht: { background: '#dfe6df', color: '#6d6d6d' },
  Wohnraummietrecht: { background: '#e3ffe5', color: '#36c04e' },
  Wohnungseigentumsrecht: { background: '#ceded3', color: '#606060' },
  Zivilprozessrecht: { background: '#f1ffe4', color: '#6bc136' },
  Zivilrecht: { background: '#d4ddcc', color: '#5f5f5f' },
  Zwangsversteigerungsrecht: { background: '#d9fff1', color: '#34b995' },
  Zwangsverwaltung: { background: '#f1fff8', color: '#3dc890' },
  Zwangsvollstreckungsrecht: { background: '#e3fff8', color: '#36c0ac' },

  Abfallrecht: { background: '#e9d2ed', color: '#4a0e53' },
  Abgabenrecht: { background: '#b0f79e', color: '#1d7506' },
  Adoptionsrecht: { background: '#9ca3c0', color: '#05103b' },
  Aktienrecht: { background: '#d4fec6', color: '#3b652d' },
  'Allgemeines Gleichbehandlungsgesetz (AGG)': {
    background: '#a9f5dd',
    color: '#105c44',
  },
  Arbeitsförderungsrecht: { background: '#c2a19e', color: '#3e0c08' },
  Arzneimittelrecht: { background: '#d2cdc5', color: '#2b200d' },
  Arztrecht: { background: '#dce692', color: '#76802c' },

  Atomrecht: { background: '#f2bc6a', color: '#a46404' },
  Ausbildungsförderungsrecht: { background: '#c7b7ea', color: '#23075d' },
  'Ausländisches Recht': { background: '#b4b9e6', color: '#020845' },
  Bauplanungsrecht: { background: '#c0c7e2', color: '#0d152f' },

  'Baurecht (öffentliches)': { background: '#c0c7e2', color: '#0d152f' },
  'Baurecht (privates)': { background: '#b9ccc6', color: '#061914' },
  Bauträgerrecht: { background: '#ebded4', color: '#4b3a2d' },
  Beamtenversorgungsrecht: { background: '#cdfee7', color: '#246446' },
  Behindertenrecht: { background: '#dad2cd', color: '#342a23' },
  'Berg- und Abgrabungsrecht': { background: '#dfeebc', color: '#3b500d' },
  Berufsbildungsrecht: { background: '#c9ecbe', color: '#1e4d10' },

  'Berufsrecht (andere freie Berufe)': {
    background: '#d7fee6',
    color: '#316545',
  },
  'Berufsrecht (anwaltliches)': { background: '#c0fad4', color: '#125f2d' },
  Betäubungsmittelstrafrecht: { background: '#ece8d4', color: '#4d472c' },
  Betriebskostenrecht: { background: '#bec3e3', color: '#0f1640' },
  'Bio- und Gentechnologierecht': { background: '#c0b4cf', color: '#120226' },

  'Buchführung und Bilanzwesen': { background: '#f2f9b5', color: '#555e03' },
  Compliance: { background: '#b9c4f4', color: '#091758' },
  Denkmalschutzrecht: { background: '#c3c7b6', color: '#161c05' },
  Dienstrecht: { background: '#e6fede', color: '#45643a' },
  'Ehescheidung / Scheidungsrecht': { background: '#fedaf2', color: '#643454' },
  'Einbürgerungs- und Staatsangehörigkeitsrecht': {
    background: '#f3d4f1',
    color: '#562d53',
  },
  Einkommensteuerrecht: { background: '#dbebde', color: '#364c3a' },
  'Energie- und Energiewirtschaftsrecht': {
    background: '#f7dec0',
    color: '#5b3a12',
  },
  'Enteignungs- und Umlegungsrecht': {
    background: '#cdc8fe',
    color: '#231d64',
  },

  Erbbaurecht: { background: '#b7cbc6', color: '#06201a' },
  'Erbschaft- und Schenkungsteuerrecht': {
    background: '#fdf8eb',
    color: '#635d4b',
  },
  Erschließungsrecht: { background: '#d0cec9', color: '#28251e' },
  'Europäische Menschenrechte': { background: '#d1b6ed', color: '#28044e' },
  Fahrerlaubnisrecht: { background: '#e6c7ce', color: '#451b24' },
  'Familienrechtliche Mediation': { background: '#bdb6e0', color: '#0e043c' },
  Fischereirecht: { background: '#b8cbe9', color: '#082049' },

  Franchiserecht: { background: '#bbead5', color: '#0b4a2e' },
  'Gebührenrecht (anwaltliches)': { background: '#f7fdc9', color: '#5b631e' },
  Grundsteuerrecht: { background: '#fae3d7', color: '#5f4030' },
  Güterkraftverkehrsrecht: { background: '#cdecd1', color: '#244c28' },
  Güterrecht: { background: '#c0bafb', color: '#120a61' },
  Haftpflichtrecht: { background: '#c4b7b9', color: '#170608' },
  Haftpflichtversicherungsrecht: { background: '#dabbca', color: '#340c1f' },
  Immissionsschutzrecht: { background: '#f0fde4', color: '#526442' },
  Immobilienverkäufe: { background: '#e3f2f1', color: '#415553' },

  Insolvenzverwaltung: { background: '#c2cfdb', color: '#142636' },
  'Internationales Familienrecht': { background: '#d2bdde', color: '#2a0e3a' },
  'Internationales Steuerrecht': { background: '#d3bec6', color: '#2b0f1a' },
  'Internationales/Europäisches Strafrecht': {
    background: '#bec6dd',
    color: '#0f1a39',
  },
  Kapitalanlagerecht: { background: '#c9c1f2', color: '#1e1455' },
  Kapitalstrafrecht: { background: '#f8ecf4', color: '#5c4d57' },
  Kassenarztrecht: { background: '#b7c8bf', color: '#061c11' },
  Kindergeldrecht: { background: '#c3bfee', color: '#16104f' },
  'Kommunal- und Kommunalverfassungsrecht': {
    background: '#c6fab7',
    color: '#1a5f06',
  },

  'Körperschaft- und Gewerbesteuerrecht': {
    background: '#dfedd7',
    color: '#3b4e30',
  },
  Kostenrecht: { background: '#e9e3b8', color: '#484108' },
  Krankenhausrecht: { background: '#d4f3df', color: '#2c563c' },
  Krankenversicherungsrecht: { background: '#c1f0cb', color: '#135221' },
  Kreditversicherungsrecht: { background: '#d7ecc8', color: '#304d1d' },
  Kündigungsschutzrecht: { background: '#e2fcd7', color: '#2f4925' },
  'Landschafts- und Naturschutzrecht': {
    background: '#d9f7fe',
    color: '#335c65',
  },
  Leasingrecht: { background: '#c2ced1', color: '#142528' },
  Lebensmittelrecht: { background: '#e5fbc6', color: '#44601a' },
  Lizenzrecht: { background: '#d5c6de', color: '#2e1a3a' },
  Luftfahrtrecht: { background: '#b6c2e8', color: '#051547' },
  'Mitbestimmungs- und Betriebsverfassungsrecht': {
    background: '#f2fbdd',
    color: '#556039',
  },

  Mutterschutzrecht: { background: '#b8b7e5', color: '#080643' },
  Nachbarrecht: { background: '#e0f5f9', color: '#3c595e' },
  'Nebenklage / Opferrecht': { background: '#ecf5d7', color: '#4c5930' },
  Notarrecht: { background: '#dab9e3', color: '#340940' },
  Oldtimerrecht: { background: '#b3fad9', color: '#016034' },
  Opferrecht: { background: '#e8f0bf', color: '#485211' },
  Passagierflugrecht: { background: '#b5bdef', color: '#040e51' },

  Patientenrecht: { background: '#fdb9b6', color: '#640804' },

  Personenbeförderungsrecht: { background: '#f5e9e3', color: '#594840' },
  Personenversicherungsrecht: { background: '#c6fbf6', color: '#1a605a' },
  Pflegeversicherungsrecht: { background: '#d3e7e8', color: '#2c4647' },
  'Polizei- und Ordnungsrecht': { background: '#e5fce9', color: '#446248' },
  'Presse- und Medienrecht': { background: '#e0b3f8', color: '#3c005d' },
  Prüfungsrecht: { background: '#f8febb', color: '#5d640b' },
  'Recht der Kreditsicherung': { background: '#cbcaf4', color: '#212057' },
  Rentenversicherungsrecht: { background: '#d7f0de', color: '#30523a' },
  'Schul- und Hochschulrecht': { background: '#b5c5c9', color: '#04191e' },

  'See- und Schifffahrtsrecht': { background: '#bcf3f0', color: '#0c5652' },
  Sorgerecht: { background: '#ceffb8', color: '#256608' },
  Sozialabgabenrecht: { background: '#beb3c8', color: '#0f011d' },
  'Speditions- und Frachtrecht': { background: '#f1fad7', color: '#546031' },
  'Staats- und Verfassungsrecht': { background: '#d6b7f2', color: '#2f0655' },
  'Staatshaftungs- und Entschädigungsrecht': {
    background: '#bffed7',
    color: '#116531',
  },
  Steuerstrafrecht: { background: '#ccefe2', color: '#225140' },
  'Straf- und Strafverfahrensrecht': {
    background: '#c0bbd4',
    color: '#120b2c',
  },

  'Straßen- und Wegerecht': { background: '#babfb6', color: '#0a1105' },
  Tarifvertragsrecht: { background: '#b9d2c7', color: '#092a1b' },
  Testamentsvollstreckung: { background: '#bab3dd', color: '#0a0139' },
  Tierschutzrecht: { background: '#e1b3c6', color: '#3e011a' },
  Umsatzsteuerrecht: { background: '#d7b6cf', color: '#300526' },
  Umweltstrafrecht: { background: '#e8dfc3', color: '#473c16' },
  Unfallversicherungsrecht: { background: '#b6cebf', color: '#052411' },
  Unternehmensnachfolgerecht: { background: '#b7c5ed', color: '#06184e' },
  'Urheber- und Verlagsrecht': { background: '#e3c4bc', color: '#41170c' },
  'Vereins- und Verbandsrecht': { background: '#f3efe2', color: '#56513f' },
  'Verkehrsstraf- und OWi-Recht': { background: '#ecf4da', color: '#4d5835' },

  Verkehrsversicherungsrecht: { background: '#bae3f7', color: '#0a415b' },
  Verkehrsverwaltungsrecht: { background: '#d6b4c0', color: '#2f0212' },
  Verkehrszivilrecht: { background: '#c4b5bf', color: '#1d0415' },
  Versorgungsausgleich: { background: '#d5b9f4', color: '#2e0858' },
  Waffenrecht: { background: '#bac7f3', color: '#0a1b56' },
  Wasserrecht: { background: '#e3bcc5', color: '#400d19' },
  'Wechsel- und Scheckrecht': { background: '#dae9f7', color: '#34495b' },
  Wirtschaftsmediation: { background: '#daf1d2', color: '#35532a' },
  Wirtschaftsstrafrecht: { background: '#daf2e5', color: '#355544' },
  Wirtschaftsverwaltungsrecht: { background: '#d6fae3', color: '#2f5f41' },
  Zollrecht: { background: '#e1d4ee', color: '#3e2d4f' },

  Insolvenzrecht: { background: '#e0b3f8', color: '#3c005d' },
};

export { AreasOfLaw_Color };
