import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import PartOfCommunityMockupSection from 'Components/help/PartOfCommunityMockupSection';
import MetaDecorator from 'Layouts/MetaDecorator';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/help/CookiesPage.scss';

const PrivacyPage = () => {
  const rows = [
    {
      name: 'anwado_google_map',
      description: LPTranslate('PP_Paragraph_4_Description_1'),
      type: LPTranslate('PP_Paragraph_4_Type_1'),
      category: LPTranslate('PP_Paragraph_4_Category_1'),
    },
    {
      name: 'anwado_cookie_consent',
      description: LPTranslate('PP_Paragraph_4_Description_2'),
      type: LPTranslate('PP_Paragraph_4_Type_2'),
      category: LPTranslate('PP_Paragraph_4_Category_2'),
    },
    {
      name: 'anwado_subscription_preference',
      description: LPTranslate('PP_Paragraph_4_Description_3'),
      type: LPTranslate('PP_Paragraph_4_Type_3'),
      category: LPTranslate('PP_Paragraph_4_Category_3'),
    },
    {
      name: 'anwado_api_ipify_search',
      description: LPTranslate('PP_Paragraph_4_Description_4'),
      type: LPTranslate('PP_Paragraph_4_Type_4'),
      category: LPTranslate('PP_Paragraph_4_Category_4'),
    },
    {
      name: '_ga',
      description: LPTranslate('PP_Paragraph_4_Description_ga'),
      type: LPTranslate('PP_Paragraph_4_Type_1'),
      category: LPTranslate('PP_Paragraph_4_Category_1'),
    },
    {
      name: '_ga_#',
      description: LPTranslate('PP_Paragraph_4_Description_ga_number'),
      type: LPTranslate('PP_Paragraph_4_Type_1'),
      category: LPTranslate('PP_Paragraph_4_Category_1'),
    },
    {
      name: '_gcl_au',
      description: LPTranslate('PP_Paragraph_4_Description_gcl_au'),
      type: LPTranslate('PP_Paragraph_4_Type_1'),
      category: LPTranslate('PP_Paragraph_4_Category_1'),
    },
    {
      name: 'anwado_location',
      description: LPTranslate('PP_Paragraph_4_Description_5'),
      type: LPTranslate('PP_Paragraph_4_Type_5'),
      category: LPTranslate('PP_Paragraph_4_Category_5'),
    },
    {
      name: 'anwado_remember_me',
      description: LPTranslate('PP_Paragraph_4_Description_6'),
      type: LPTranslate('PP_Paragraph_4_Type_6'),
      category: LPTranslate('PP_Paragraph_4_Category_6'),
    },
    {
      name: 'anwado_form_data',
      description: LPTranslate('PP_Paragraph_4_Description_7'),
      type: LPTranslate('PP_Paragraph_4_Type_7'),
      category: LPTranslate('PP_Paragraph_4_Category_7'),
    },
    {
      name: 'anwado_token',
      description: LPTranslate('PP_Paragraph_4_Description_8'),
      type: LPTranslate('PP_Paragraph_4_Type_8'),
      category: LPTranslate('PP_Paragraph_4_Category_8'),
    },
    {
      name: 'anwado_public_information',
      description: LPTranslate('PP_Paragraph_4_Description_9'),
      type: LPTranslate('PP_Paragraph_4_Type_9'),
      category: LPTranslate('PP_Paragraph_4_Category_9'),
    },
  ];

  return (
    <div className="lawp-cookies-page-container">
      <MetaDecorator
        title={LPTranslate('PP_Description_Meta_Title_1')}
        description={LPTranslate('PP_Description_Meta_Description_1')}
        canonical={'canonical'}
        link={`${process.env.REACT_APP_DNS_URI}/datenschutz-cookies`}
      />
      <div className="lawp-cookies-page-faq-container">
        <div className="lawp-cookies-page-header-section">
          <h3 className="lawp-cookies-page-subheader">
            {' '}
            {LPTranslate('PP_Pre_Title')}{' '}
          </h3>

          <h1 className="lawp-cookies-page-header">
            {' '}
            {LPTranslate('PP_Title')}{' '}
          </h1>
        </div>

        <h3 className="lawp-cookies-page-text-block">
          {LPTranslate('PP_Paragraph')}
        </h3>

        <ol>
          <li className="lawp-cookies-page-faq-list">
            <h2 className="lawp-cookies-page-faq-section-title">
              {LPTranslate('PP_Paragraph_1_Title')}
            </h2>

            <h3 className="lawp-cookies-page-text-block">
              {LPTranslate('PP_Paragraph_1_1')}
              <a
                className={'lawp-cookies-page-link'}
                href={`${process.env.REACT_APP_DNS_URI}`}
              >
                {LPTranslate('PP_Paragraph_1_2')}
              </a>
              {LPTranslate('PP_Paragraph_1_3')}
            </h3>
          </li>

          <li className="lawp-cookies-page-faq-list">
            <h2 className="lawp-cookies-page-faq-section-title">
              {LPTranslate('PP_Paragraph_2_Title')}
            </h2>

            <h3 className="lawp-cookies-page-text-block">
              {LPTranslate('PP_Paragraph_2')}
            </h3>
          </li>

          <li className="lawp-cookies-page-faq-list">
            <h2 className="lawp-cookies-page-faq-section-title">
              {LPTranslate('PP_Paragraph_3_Title')}
            </h2>

            <h3 className="lawp-cookies-page-text-block">
              {LPTranslate('PP_Paragraph_3')}
            </h3>

            <ol>
              <li className="lawp-cookies-page-text-block-privacy">
                {LPTranslate('PP_Paragraph_3_1_1_Title')}
              </li>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('PP_Paragraph_3_1_1')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_1_2_Title')}</b>
                {LPTranslate('PP_Paragraph_3_1_2')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_1_3_Title')}</b>
                {LPTranslate('PP_Paragraph_3_1_3')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_1_4_Title')}</b>
                {LPTranslate('PP_Paragraph_3_1_4')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_1_5_Title')}</b>
                {LPTranslate('PP_Paragraph_3_1_5')}
              </h3>

              <li className="lawp-cookies-page-text-block-privacy">
                {LPTranslate('PP_Paragraph_3_2_1_Title')}
              </li>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('PP_Paragraph_3_2_1')}
              </h3>

              <h3
                className="lawp-cookies-page-text-block"
                style={{ fontWeight: 'bold', textDecoration: 'underline' }}
              >
                {LPTranslate('PP_Paragraph_3_2_2_Pre_Title')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_2_2_Title')}</b>
                {LPTranslate('PP_Paragraph_3_2_2')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_2_3_Title')}</b>
                {LPTranslate('PP_Paragraph_3_2_3')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_2_4_Title')}</b>
                {LPTranslate('PP_Paragraph_3_2_4')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_2_5_Title')}</b>
                {LPTranslate('PP_Paragraph_3_2_5')}
              </h3>

              <h3
                className="lawp-cookies-page-text-block"
                style={{ fontWeight: 'bold', textDecoration: 'underline' }}
              >
                {LPTranslate('PP_Paragraph_3_2_6_Pre_Title')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_2_6_Title')}</b>
                {LPTranslate('PP_Paragraph_3_2_6')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_2_7_Title')}</b>
                {LPTranslate('PP_Paragraph_3_2_7')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_2_8_Title')}</b>
                {LPTranslate('PP_Paragraph_3_2_8')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_2_9_Title')}</b>
                {LPTranslate('PP_Paragraph_3_2_9')}
              </h3>

              <h3
                className="lawp-cookies-page-text-block"
                style={{ fontWeight: 'bold', textDecoration: 'underline' }}
              >
                {LPTranslate('PP_Paragraph_3_2_10_Pre_Title')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_2_11_Title')}</b>
                {LPTranslate('PP_Paragraph_3_2_11')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_2_12_Title')}</b>
                {LPTranslate('PP_Paragraph_3_2_12')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_2_13_Title')}</b>
                {LPTranslate('PP_Paragraph_3_2_13')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_2_14_Title')}</b>
                {LPTranslate('PP_Paragraph_3_2_14')}
              </h3>

              <h3
                className="lawp-cookies-page-text-block"
                style={{ fontWeight: 'bold', textDecoration: 'underline' }}
              >
                {LPTranslate('PP_Paragraph_3_2_15_Pre_Title')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('PP_Paragraph_3_2_15')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_2_16_Title')}</b>
                {LPTranslate('PP_Paragraph_3_2_16')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_2_17_Title')}</b>
                {LPTranslate('PP_Paragraph_3_2_17')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_2_18_Title')}</b>
                {LPTranslate('PP_Paragraph_3_2_18')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_2_19_Title')}</b>
                {LPTranslate('PP_Paragraph_3_2_19')}
              </h3>

              <h3
                className="lawp-cookies-page-text-block"
                style={{ fontWeight: 'bold', textDecoration: 'underline' }}
              >
                {LPTranslate('PP_Paragraph_3_2_20_Pre_Title')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_2_20_Title')}</b>
                {LPTranslate('PP_Paragraph_3_2_20')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_2_21_Title')}</b>
                {LPTranslate('PP_Paragraph_3_2_21')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_2_22_Title')}</b>
                {LPTranslate('PP_Paragraph_3_2_22')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_2_23_Title')}</b>
                {LPTranslate('PP_Paragraph_3_2_23')}
              </h3>

              <li className="lawp-cookies-page-text-block-privacy">
                {LPTranslate('PP_Paragraph_3_3_1_Title')}
              </li>

              <h3
                className="lawp-cookies-page-text-block"
                style={{ fontWeight: 'bold', textDecoration: 'underline' }}
              >
                {LPTranslate('PP_Paragraph_3_3_1_Pre_Title')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('PP_Paragraph_3_3_1')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_3_2_Title')}</b>
                {LPTranslate('PP_Paragraph_3_3_2')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_3_3_Title')}</b>
                {LPTranslate('PP_Paragraph_3_3_3')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_3_4_Title')}</b>
                {LPTranslate('PP_Paragraph_3_3_4')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_3_5_Title')}</b>
                {LPTranslate('PP_Paragraph_3_3_5')}
              </h3>

              <h3
                className="lawp-cookies-page-text-block"
                style={{ fontWeight: 'bold', textDecoration: 'underline' }}
              >
                {LPTranslate('PP_Paragraph_3_3_6_Pre_Title')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('PP_Paragraph_3_3_6')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_3_7_Title')}</b>
                {LPTranslate('PP_Paragraph_3_3_7')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_3_8_Title')}</b>
                {LPTranslate('PP_Paragraph_3_3_8')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_3_9_Title')}</b>
                {LPTranslate('PP_Paragraph_3_3_9')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_3_10_Title')}</b>
                {LPTranslate('PP_Paragraph_3_3_10')}
              </h3>

              <h3
                className="lawp-cookies-page-text-block"
                style={{ fontWeight: 'bold', textDecoration: 'underline' }}
              >
                {LPTranslate('PP_Paragraph_3_3_11_Pre_Title')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('PP_Paragraph_3_3_11')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_3_12_Title')}</b>
                {LPTranslate('PP_Paragraph_3_3_12')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_3_13_Title')}</b>
                {LPTranslate('PP_Paragraph_3_3_13')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_3_14_Title')}</b>
                {LPTranslate('PP_Paragraph_3_3_14')}
              </h3>

              <li className="lawp-cookies-page-text-block-privacy">
                {LPTranslate('PP_Paragraph_3_4_1_Title')}
              </li>

              <h3
                className="lawp-cookies-page-text-block"
                style={{ fontWeight: 'bold', textDecoration: 'underline' }}
              >
                {LPTranslate('PP_Paragraph_3_4_1_Pre_Title')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('PP_Paragraph_3_4_1')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_4_2_Title')}</b>
                {LPTranslate('PP_Paragraph_3_4_2')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_4_3_Title')}</b>
                {LPTranslate('PP_Paragraph_3_4_3')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_4_4_Title')}</b>
                {LPTranslate('PP_Paragraph_3_4_4')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_4_5_Title')}</b>
                {LPTranslate('PP_Paragraph_3_4_5')}
              </h3>

              <h3
                className="lawp-cookies-page-text-block"
                style={{ fontWeight: 'bold', textDecoration: 'underline' }}
              >
                {LPTranslate('PP_Paragraph_3_4_6_Pre_Title')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('PP_Paragraph_3_4_6')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_4_7_Title')}</b>
                {LPTranslate('PP_Paragraph_3_4_7')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_4_8_Title')}</b>
                {LPTranslate('PP_Paragraph_3_4_8')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_4_9_Title')}</b>
                {LPTranslate('PP_Paragraph_3_4_9')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_4_10_Title')}</b>
                {LPTranslate('PP_Paragraph_3_4_10')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_4_11_Title')}</b>
                {LPTranslate('PP_Paragraph_3_4_11')}
              </h3>

              <h3
                className="lawp-cookies-page-text-block"
                style={{ fontWeight: 'bold', textDecoration: 'underline' }}
              >
                {LPTranslate('PP_Paragraph_3_4_12_Pre_Title')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('PP_Paragraph_3_4_12')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_4_13_Title')}</b>
                {LPTranslate('PP_Paragraph_3_4_13')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_4_14_Title')}</b>
                {LPTranslate('PP_Paragraph_3_4_14')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_4_15_Title')}</b>
                {LPTranslate('PP_Paragraph_3_4_15')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_4_16_Title')}</b>
                {LPTranslate('PP_Paragraph_3_4_16')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_4_17_Title')}</b>
                {LPTranslate('PP_Paragraph_3_4_17')}
              </h3>

              <h3
                className="lawp-cookies-page-text-block"
                style={{ fontWeight: 'bold', textDecoration: 'underline' }}
              >
                {LPTranslate('PP_Paragraph_3_4_18_Pre_Title')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('PP_Paragraph_3_4_18')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_4_19_Title')}</b>
                {LPTranslate('PP_Paragraph_3_4_19')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_4_20_Title')}</b>
                {LPTranslate('PP_Paragraph_3_4_20')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_4_21_Title')}</b>
                {LPTranslate('PP_Paragraph_3_4_21')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_4_22_Title')}</b>
                {LPTranslate('PP_Paragraph_3_4_22')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_4_23_Title')}</b>
                {LPTranslate('PP_Paragraph_3_4_23')}
              </h3>

              <li className="lawp-cookies-page-text-block-privacy">
                {LPTranslate('PP_Paragraph_3_5_1_Title')}
              </li>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('PP_Paragraph_3_5_1')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_5_2_Title')}</b>
                {LPTranslate('PP_Paragraph_3_5_2')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_5_3_Title')}</b>
                {LPTranslate('PP_Paragraph_3_5_3')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_5_4_Title')}</b>
                {LPTranslate('PP_Paragraph_3_5_4')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_5_5_Title')}</b>
                {LPTranslate('PP_Paragraph_3_5_5')}
              </h3>

              <li className="lawp-cookies-page-text-block-privacy">
                {LPTranslate('PP_Paragraph_3_6_1_Title')}
              </li>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('PP_Paragraph_3_6_1')}
              </h3>

              <h3
                className="lawp-cookies-page-text-block"
                style={{ fontWeight: 'bold', textDecoration: 'underline' }}
              >
                {LPTranslate('PP_Paragraph_3_6_2_Pre_Title')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_6_2_Title')}</b>
                {LPTranslate('PP_Paragraph_3_6_2')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_6_3_Title')}</b>
                {LPTranslate('PP_Paragraph_3_6_3')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_6_4_Title')}</b>
                {LPTranslate('PP_Paragraph_3_6_4')}
              </h3>

              <h3 className="lawp-cookies-page-text-block">
                <b>{LPTranslate('PP_Paragraph_3_6_5_Title')}</b>
                {LPTranslate('PP_Paragraph_3_6_5')}
              </h3>

              <li className="lawp-cookies-page-text-block-privacy">
                {LPTranslate('PP_Paragraph_3_7_1_Title')}
              </li>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('PP_Paragraph_3_7_1')}
              </h3>

              <ul style={{ marginBottom: '1rem' }}>
                <li className="lawp-cookies-page-text-block">
                  {LPTranslate('PP_Paragraph_3_7_1_1')}
                </li>
                <li className="lawp-cookies-page-text-block">
                  {LPTranslate('PP_Paragraph_3_7_1_2')}
                </li>
                <li className="lawp-cookies-page-text-block">
                  {LPTranslate('PP_Paragraph_3_7_1_3')}
                </li>
                <li className="lawp-cookies-page-text-block">
                  {LPTranslate('PP_Paragraph_3_7_1_4')}
                </li>
                <li className="lawp-cookies-page-text-block">
                  {LPTranslate('PP_Paragraph_3_7_1_5')}
                </li>
                <li className="lawp-cookies-page-text-block">
                  {LPTranslate('PP_Paragraph_3_7_1_6')}
                </li>
                <li className="lawp-cookies-page-text-block">
                  {LPTranslate('PP_Paragraph_3_7_1_7')}
                </li>
                <li className="lawp-cookies-page-text-block">
                  {LPTranslate('PP_Paragraph_3_7_1_8')}
                </li>
              </ul>
            </ol>
          </li>

          <li className="lawp-cookies-page-faq-list">
            <h2 className="lawp-cookies-page-faq-section-title">
              {LPTranslate('PP_Paragraph_4_Title')}
            </h2>

            <ol>
              <li className="lawp-cookies-page-text-block-privacy">
                {LPTranslate('PP_Paragraph_4_1_Title')}
              </li>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('PP_Paragraph_4_1')}
              </h3>

              <li className="lawp-cookies-page-text-block-privacy">
                {LPTranslate('PP_Paragraph_4_2_Title')}
              </li>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('PP_Paragraph_4_2')}
              </h3>

              <li className="lawp-cookies-page-text-block-privacy">
                {LPTranslate('PP_Paragraph_4_3_Title')}
              </li>

              <ol>
                <li className="lawp-cookies-page-text-block-privacy">
                  {LPTranslate('PP_Paragraph_4_3_1_Title')}
                </li>
                <h3 className="lawp-cookies-page-text-block">
                  {LPTranslate('PP_Paragraph_4_3_1')}
                </h3>
                <li className="lawp-cookies-page-text-block-privacy">
                  {LPTranslate('PP_Paragraph_4_3_2_Title')}
                </li>
                <h3 className="lawp-cookies-page-text-block">
                  {LPTranslate('PP_Paragraph_4_3_2')}
                </h3>
              </ol>

              <li className="lawp-cookies-page-text-block-privacy">
                {LPTranslate('PP_Paragraph_4_4_Title')}
              </li>

              <TableContainer
                component={Paper}
                className="lawp-cookies-page-table"
              >
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Beschreibung</TableCell>
                      <TableCell>Speicherdauer</TableCell>
                      <TableCell>Kategorie</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>{row.type}</TableCell>
                        <TableCell>{row.category}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </ol>
          </li>

          <li className="lawp-cookies-page-faq-list">
            <h2 className="lawp-cookies-page-faq-section-title">
              {LPTranslate('PP_Paragraph_5_Title')}
            </h2>

            <h3 className="lawp-cookies-page-text-block">
              {LPTranslate('PP_Paragraph_5')}
            </h3>

            <ol>
              <li className="lawp-cookies-page-text-block-privacy">
                {LPTranslate('PP_Paragraph_5_1_Title')}
              </li>

              <ol>
                <li className="lawp-cookies-page-text-block-privacy">
                  {LPTranslate('PP_Paragraph_5_1_1_Title')}
                </li>

                <h3 className="lawp-cookies-page-text-block">
                  {LPTranslate('PP_Paragraph_5_1_1')}
                </h3>

                <li className="lawp-cookies-page-text-block-privacy">
                  {LPTranslate('PP_Paragraph_5_1_2_Title')}
                </li>

                <h3 className="lawp-cookies-page-text-block">
                  {LPTranslate('PP_Paragraph_5_1_2_1')}
                  <a
                    className="lawp-cookies-page-link"
                    href="http://tools.google.com/dlpage/gaoptout?hl=de"
                  >
                    {LPTranslate('PP_Paragraph_5_1_2_2')}
                  </a>
                  .
                </h3>

                <li className="lawp-cookies-page-text-block-privacy">
                  {LPTranslate('PP_Paragraph_5_1_3_Title')}
                </li>

                <h3 className="lawp-cookies-page-text-block">
                  {LPTranslate('PP_Paragraph_5_1_3_1')}
                  <a
                    className="lawp-cookies-page-link"
                    href="http://google.com/analytics/terms/de.html"
                  >
                    {LPTranslate('PP_Paragraph_5_1_3_2')}
                  </a>
                  {LPTranslate('PP_Paragraph_5_1_3_3')}
                  <a
                    className="lawp-cookies-page-link"
                    href="http://www.google.de/intl/de/policies/privacy"
                  >
                    {LPTranslate('PP_Paragraph_5_1_3_4')}.
                  </a>
                </h3>
              </ol>

              <li className="lawp-cookies-page-text-block-privacy">
                {LPTranslate('PP_Paragraph_5_2_Title')}
              </li>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('PP_Paragraph_5_2_1')}
                <a
                  className="lawp-cookies-page-link"
                  href="https://marketingplatform.google.com/intl/de/about/analytics/tag-manager/use-policy/"
                >
                  {LPTranslate('PP_Paragraph_5_2_2')}
                </a>
                {').'}
              </h3>

              <li className="lawp-cookies-page-text-block-privacy">
                {LPTranslate('PP_Paragraph_5_3_Title')}
              </li>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('PP_Paragraph_5_3_1')}
                <a
                  className="lawp-cookies-page-link"
                  href="https://policies.google.com/privacy?hl=de&gl=de"
                >
                  {LPTranslate('PP_Paragraph_5_3_2')}
                </a>
                .
              </h3>
            </ol>
          </li>

          <li className="lawp-cookies-page-faq-list">
            <h2 className="lawp-cookies-page-faq-section-title">
              {LPTranslate('PP_Paragraph_6_Title')}
            </h2>

            <h3 className="lawp-cookies-page-text-block">
              {LPTranslate('PP_Paragraph_6_1')}
            </h3>
            <h3 className="lawp-cookies-page-text-block">
              {LPTranslate('PP_Paragraph_6_2')}
            </h3>
            <h3 className="lawp-cookies-page-text-block">
              {LPTranslate('PP_Paragraph_6_3')}
            </h3>
            <h3 className="lawp-cookies-page-text-block">
              {LPTranslate('PP_Paragraph_6_4')}
            </h3>
            <h3 className="lawp-cookies-page-text-block">
              {LPTranslate('PP_Paragraph_6_5')}
            </h3>
            <h3 className="lawp-cookies-page-text-block">
              {LPTranslate('PP_Paragraph_6_6')}
            </h3>
            <h3 className="lawp-cookies-page-text-block">
              {LPTranslate('PP_Paragraph_6_7')}
            </h3>
            <h3 className="lawp-cookies-page-text-block">
              {LPTranslate('PP_Paragraph_6_8')}
            </h3>
            <h3 className="lawp-cookies-page-text-block">
              {LPTranslate('PP_Paragraph_6_9')}
            </h3>
          </li>

          <li className="lawp-cookies-page-faq-list">
            <h2 className="lawp-cookies-page-faq-section-title">
              {LPTranslate('PP_Paragraph_7_Title')}
            </h2>

            <h3 className="lawp-cookies-page-text-block">
              {LPTranslate('PP_Paragraph_7')}
            </h3>
          </li>

          <li className="lawp-cookies-page-faq-list">
            <h2 className="lawp-cookies-page-faq-section-title">
              {LPTranslate('PP_Paragraph_8_Title')}
            </h2>

            <h3 className="lawp-cookies-page-text-block">
              {LPTranslate('PP_Paragraph_8')}
            </h3>
          </li>

          <li className="lawp-cookies-page-faq-list">
            <h2 className="lawp-cookies-page-faq-section-title">
              {LPTranslate('PP_Paragraph_9_Title')}
            </h2>

            <h3 className="lawp-cookies-page-text-block">
              {LPTranslate('PP_Paragraph_9')}
            </h3>
          </li>
        </ol>

        {/* <div className="lawp-cookies-page-faq-section">
 
        </div> */}
      </div>

      <PartOfCommunityMockupSection language="de" />
    </div>
  );
};

export default PrivacyPage;
