import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

import SendMessageToLawyerModal from 'Components/Modals/SendMessageToLawyerModal';
import SendMessageToLawyerNotLoggedModal from 'Components/Modals/SendMessageToLawyerNotLoggedModal';
import AskLawyerToSetScheduleModal from 'Components/Modals/AskLawyerToSetScheduleModal';
import AddLawyerReviewModal from 'Components/Lawyer/AddLawyerReviewModal';

import { AreasOfLaw_Color } from 'Services/Utils/areaOfLaws';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { countryToLanguage } from 'Services/Utils/countryToLanguage.js';

import styles from 'Assets/styles/lawyer/LawyerInfoCard.module.scss';

const LawyerInfoCard = (props) => {
  let { lawyer, clientInfoRedux, reviewsNr } = props;
  const navigate = useNavigate();

  const [articleImage, setArticleImage] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [disabledDaysIndex, setDisabledDaysIndex] = useState(0);

  const [AddLawyerReviewModalOpen, setAddLawyerReviewModalOpen] =
    useState(false);

  const getFlag = (country) => {
    let flag;
    try {
      const language = countryToLanguage[country];

      flag = require(`Assets/languageFlags/${language}.svg`).default;
    } catch (e) {
      //without 'default' it will be displayed as an empty flag,
      //will use this until we add all flags or a default flag.
      flag = null;
    }

    return flag;
  };

  useEffect(() => {
    if (props.availability && props.availability.length !== 0) {
      let index = 0;

      for (let i = 0; i < props.availability.length; i++) {
        if (!props.availability[i].enabled) index++;
      }
      setDisabledDaysIndex(index);
    }
  }, [props.availability]);

  useEffect(() => {
    if (lawyer.profileImage) {
      setArticleImage(lawyer.profileImage);
    } else {
      setArticleImage(null);
    }
    return () => {
      setArticleImage(null);
    };
  }, [lawyer.profileImage]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const lawyerAvailability = () => {
    let available = false;

    if (
      lawyer.lawyerAvailability.personally ||
      (lawyer.lawyerAvailability.by_phone && lawyer.telephone)
    ) {
      available = true;
    }

    return available;
  };

  let enabledAppointmentButton = (
    availability,
    disabledDaysIndex,
    LawyerIsavailable,
    lawyerSubscription
  ) => {
    return (
      <button
        data-cy={'appointment-button'}
        className={styles.appointmentLink}
        variant="text"
        onClick={() => {
          if (props.availability && props.availability.length !== 0) {
            if (
              disabledDaysIndex < 7 &&
              LawyerIsavailable &&
              lawyer.lawyerAvailability.show_availability &&
              lawyer.subscription > 1
            ) {
              navigate('/setAppointment', {
                state: {
                  id: lawyer.id,
                  lawyer: lawyer,
                  reviewsNr: reviewsNr,
                  averageNr: props.averageNr,
                  profileImage: articleImage,
                  specialities: lawyer.specialities,
                  backButtonText: 'to Profil Overview',
                  availability: lawyer.lawyerAvailability,
                },
              });
            } else setOpenModal(true);
          } else {
            setOpenModal(true);
          }
        }}
      >
        {disabledDaysIndex < 7 &&
        lawyer.lawyerAvailability.show_availability &&
        lawyer.subscription > 1
          ? LPTranslate('Button_Make_Appointment')
          : LPTranslate('Button_Make_Appointment_Request')}
      </button>
    );
  };

  let disabledAppointmentButton = (
    disabledDaysIndex,
    LawyerIsavailable,
    lawyerSubscription
  ) => {
    return (
      <Tooltip
        className="tooltip-medium-size"
        PopperProps={{
          disablePortal: true,
        }}
        title={LPTranslate('Error_Lawyer_Cannot_Create_Appointment')}
        placement="top"
        TransitionComponent={Zoom}
      >
        <button
          data-cy="faq-appointment-button"
          className={styles.appointmentLink}
          style={{
            boxSizing: 'border-box',
            color: 'gray',
            fontSize: '16px',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            textTransform: 'none',
          }}
          variant="text"
        >
          {disabledDaysIndex < 7 && LawyerIsavailable && lawyerSubscription > 1
            ? LPTranslate('LPP_FAQ_Action_button')
            : LPTranslate('Button_Make_Appointment_Request')}
        </button>
      </Tooltip>
    );
  };

  var AppointmentButton = () => {
    let LawyerIsavailable;

    if (clientInfoRedux.role === 'lawyer') {
      if (
        lawyer.lawyerAvailability.personally ||
        (lawyer.lawyerAvailability.by_phone && lawyer.telephone)
      )
        LawyerIsavailable = true;
      else LawyerIsavailable = false;

      return disabledAppointmentButton(
        disabledDaysIndex,
        LawyerIsavailable,
        lawyer.subscription
      );
    } else if (
      clientInfoRedux.role === 'client' ||
      clientInfoRedux.role === ''
    ) {
      if (
        lawyer.lawyerAvailability.personally ||
        (lawyer.lawyerAvailability.by_phone && lawyer.telephone)
      )
        LawyerIsavailable = true;
      else LawyerIsavailable = false;

      return enabledAppointmentButton(
        props.availability,
        disabledDaysIndex,
        LawyerIsavailable,
        lawyer.subscription
      );
    }
  };

  var claimAccountButton = () => {
    if (clientInfoRedux.role !== 'lawyer' && lawyer.user_id === null) {
      return (
        <button
          className={styles.claimAccountButton}
          target="_blank"
          data-cy={'claim-account-button'}
          onClick={() => {
            const params = new URLSearchParams({
              salutation: lawyer.salutation,
              title: lawyer.title,
              firstName: lawyer.first_name,
              lastName: lawyer.last_name,
              email: lawyer.email,
              isSentFromLawyerPage: true,
            }).toString();

            navigate(`/registerLawyer?${params}`);
          }}
        >
          {LPTranslate('LPP_Bio_Claim_Account_Button')}
          <NorthEastIcon transform="scale(0.7)" />
        </button>
      );
    } else {
      return <></>;
    }
  };

  let enabledAppointmentButtonWithIcon = (
    availability,
    disabledDaysIndex,
    LawyerIsavailable,
    lawyerShowAvailability,
    lawyerSubscription
  ) => {
    return (
      <Button
        data-cy={'appointment-button'}
        className={
          props.availability &&
          props.availability.length !== 0 &&
          disabledDaysIndex < 7 &&
          LawyerIsavailable &&
          lawyerAvailability() &&
          lawyer.subscription > 1
            ? styles.appointmentButton
            : styles.requestAppointmentButton
        }
        variant="text"
        onClick={() => {
          if (props.availability && props.availability.length !== 0) {
            if (
              disabledDaysIndex < 7 &&
              LawyerIsavailable &&
              lawyerAvailability() &&
              lawyer.subscription > 1
            ) {
              navigate('/setAppointment', {
                state: {
                  id: lawyer.id,
                  lawyer: lawyer,
                  reviewsNr: reviewsNr,
                  averageNr: props.averageNr,
                  profileImage: articleImage,
                  specialities: lawyer.specialities,
                  backButtonText: 'to Profil Overview',
                  availability: lawyer.lawyerAvailability,
                },
              });
            } else setOpenModal(true);
          } else {
            setOpenModal(true);
          }
        }}
      >
        <CalendarTodayIcon transform="scale(0.8)" />{' '}
        {disabledDaysIndex < 7 &&
        LawyerIsavailable &&
        lawyerAvailability() &&
        lawyer.subscription > 1
          ? LPTranslate('Button_Make_Appointment')
          : LPTranslate('Button_Make_Appointment_Request')}
      </Button>
    );
  };

  let disabledAppointmentButtonWithIcon = (
    disabledDaysIndex,
    LawyerIsavailable,
    lawyerSubscription
  ) => {
    return (
      <Tooltip
        className="tooltip-medium-size"
        PopperProps={{
          disablePortal: true,
        }}
        title={LPTranslate('Error_Lawyer_Cannot_Create_Appointment')}
        placement="top"
        TransitionComponent={Zoom}
      >
        <button
          data-cy="faq-appointment-button"
          className="faqsButton"
          style={{
            background: 'gray',
            border: '1px solid gray',
            padding: '10px 18px',
            boxSizing: 'border-box',
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            borderRadius: '8px',
            margin: '0px 12px',
            color: '#FFF',
            fontSize: '14px',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            textTransform: 'none',
          }}
          variant="text"
        >
          {disabledDaysIndex < 7 && LawyerIsavailable && lawyerSubscription > 1
            ? LPTranslate('LPP_FAQ_Action_button')
            : LPTranslate('Button_Make_Appointment_Request')}
        </button>
      </Tooltip>
    );
  };

  var appointmentButton = () => {
    let LawyerIsavailable;

    if (clientInfoRedux.role === 'lawyer') {
      if (
        lawyer.lawyerAvailability.personally ||
        (lawyer.lawyerAvailability.by_phone && lawyer.telephone)
      )
        LawyerIsavailable = true;
      else LawyerIsavailable = false;

      return disabledAppointmentButtonWithIcon(
        disabledDaysIndex,
        LawyerIsavailable,
        lawyer.subscription
      );
    } else if (
      clientInfoRedux.role === 'client' ||
      clientInfoRedux.role === ''
    ) {
      if (
        lawyer.lawyerAvailability.personally ||
        (lawyer.lawyerAvailability.by_phone && lawyer.telephone)
      )
        LawyerIsavailable = true;
      else LawyerIsavailable = false;

      return enabledAppointmentButtonWithIcon(
        props.availability,
        disabledDaysIndex,
        LawyerIsavailable,
        lawyer.subscription
      );
    }
  };

  var addReviewButton = () => {
    if (
      clientInfoRedux.email !== lawyer.email &&
      clientInfoRedux.role !== 'lawyer'
    ) {
      return (
        <button
          className={styles.appointmentLink}
          onClick={() => setAddLawyerReviewModalOpen(true)}
          data-cy={'add-review-button'}
        >
          {LPTranslate('LPP_Bio_Submit_Rating_Button')}
          <NorthEastIcon transform="scale(0.7)" />
        </button>
      );
    } else {
      return (
        <Tooltip
          className="tooltip-medium-size"
          PopperProps={{
            disablePortal: true,
          }}
          title={LPTranslate('Error_Lawyer_Cannot_Create_Reviews')}
          placement="top"
          TransitionComponent={Zoom}
        >
          <button
            style={{ color: 'gray' }}
            className={styles.appointmentLink}
            data-cy={'add-review-button'}
          >
            {LPTranslate('LPP_Bio_Submit_Rating_Button')}
            <NorthEastIcon transform="scale(0.7)" />
          </button>
        </Tooltip>
      );
    }
  };

  const AreaOfLawTagJSX = (value, key) => {
    return (
      <div
        className="lawp-add-lawyer-review-modal-area-of-law-tag-container"
        style={{
          color: AreasOfLaw_Color[value]?.color || 'black',
          backgroundColor: AreasOfLaw_Color[value]?.background || 'white',
        }}
        onClick={(e) => {
          e.stopPropagation();

          let aol = value;

          aol = aol.replace(/\s+/g, '-');

          navigate(`/aol/${aol}`);
        }}
        key={key}
      >
        <h3 className="lawp-add-lawyer-review-modal-area-of-law-tag-text">
          {value}
        </h3>
      </div>
    );
  };

  const LawyerShortDescriptionJSX = () => {
    const language = localStorage.getItem('userLanguage');
    const lawyerNameTitle =
      lawyer.title + ' ' + lawyer.first_name + ' ' + lawyer.last_name;

    let AreasOfLawList = lawyer.areasOfLaw
      .map((value, key) => {
        return value;
      })
      .join(', ');

    if (language === 'en') {
      if (lawyer.areasOfLaw.length > 0) {
        AreasOfLawList = AreasOfLawList.replace(/,(?=[^,]*$)/, ' and');
      }
    } else if (language === 'de') {
      if (lawyer.areasOfLaw.length > 0) {
        AreasOfLawList = AreasOfLawList.replace(/,(?=[^,]*$)/, ' und');
      }
    }

    let SpecialitiesList = lawyer.specialities
      .map((value, key) => {
        return value;
      })
      .join(', ');

    if (language === 'en') {
      if (lawyer.specialities.length > 0) {
        SpecialitiesList = SpecialitiesList.replace(/,(?=[^,]*$)/, ' and');
      }
    } else if (language === 'de') {
      if (lawyer.specialities.length > 0) {
        SpecialitiesList = SpecialitiesList.replace(/,(?=[^,]*$)/, ' und');
      }
    }

    let returnString = '';

    if (!lawyer.short_description) {
      if (lawyer.specialities.length === 0 && lawyer.areasOfLaw.length > 0) {
        if (lawyer.salutation === 'Herr') {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is a lawyer listed on Anwado who practices in the fields ${AreasOfLawList}.`;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist ein auf Anwado gelisteter Rechtsanwalt, der in den Bereichen ${AreasOfLawList} tätig ist.`;
        } else if (lawyer.salutation === 'Frau') {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is a lawyer listed on Anwado practicing in the fields of ${AreasOfLawList}.`;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist eine auf Anwado gelistete Rechtsanwältin, die in den Bereichen ${AreasOfLawList} tätig ist.`;
        } else {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is a lawyer listed on Anwado and practicing in the fields of ${AreasOfLawList}.`;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist als Rechtsanwalt*in auf Anwado gelistete und in den Bereichen ${AreasOfLawList} tätig.`;
        }
      } else if (
        lawyer.specialities.length === 0 &&
        lawyer.areasOfLaw.length === 0
      ) {
        if (lawyer.salutation === 'Herr') {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is a lawyer listed on Anwado.`;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist ein auf Anwado gelisteter Rechtsanwalt.`;
        } else if (lawyer.salutation === 'Frau') {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is a female lawyer listed on Anwado.`;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist eine auf Anwado gelistete Rechtsanwältin`;
        } else {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is a lawyer listed on Anwado.`;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist als Rechtsanwalt*in auf Anwado gelistete.`;
        }
      } else if (
        lawyer.specialities.length > 0 &&
        lawyer.areasOfLaw.length > 0
      ) {
        if (lawyer.salutation === 'Herr') {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is a specialist lawyer listed on Anwado for ${SpecialitiesList} practicing in ${AreasOfLawList}.`;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist ein auf Anwado gelisteter Fachanwalt für ${SpecialitiesList}, der in den Bereichen ${AreasOfLawList} tätig ist.`;
        } else if (lawyer.salutation === 'Frau') {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is a specialist lawyer listed on Anwado for ${SpecialitiesList} practicing in the fields of ${AreasOfLawList}.`;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist eine auf Anwado gelistete Fachanwältin für ${SpecialitiesList}, die in den Bereichen ${AreasOfLawList} tätig ist.`;
        } else {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is a specialist lawyer for ${SpecialitiesList} listed on Anwado and practicing in the fields of ${AreasOfLawList}.`;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist als Fachanwalt*in für ${SpecialitiesList} auf Anwado gelistete und in den Bereichen ${AreasOfLawList} tätig.`;
        }
      } else if (
        lawyer.specialities.length > 0 &&
        lawyer.areasOfLaw.length === 0
      ) {
        if (lawyer.salutation === 'Herr') {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is a specialist lawyer listed on Anwado for ${SpecialitiesList}.`;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist ein auf Anwado gelisteter Fachanwalt für ${SpecialitiesList}.`;
        } else if (lawyer.salutation === 'Frau') {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is a specialist lawyer listed on Anwado for ${SpecialitiesList}. `;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist eine auf Anwado gelistete Fachanwältin für ${SpecialitiesList}.`;
        } else {
          if (language === 'en')
            returnString = `${lawyerNameTitle} is listed on Anwado as a specialist lawyer for ${SpecialitiesList}.`;

          if (language === 'de')
            returnString = `${lawyerNameTitle} ist als Fachanwalt*in für ${SpecialitiesList} auf Anwado gelistete.`;
        }
      }
    }

    if (returnString) {
      if (language === 'en') {
        return returnString + ' This entry is provided by anwado.de.';
      } else if (language === 'de') {
        return (
          returnString +
          ' Bei diesem Eintrag handelt es sich um eine von anwado.de bereitgestellte Information.'
        );
      }
    }

    return '';
  };

  return (
    <>
      <div
        className={styles.lawyerInfoCardOuterContainer}
        style={
          lawyer.specialities.length !== 0 &&
          lawyer.short_description !== null &&
          lawyer.areasOfLaw.length !== 0
            ? {}
            : { justifyContent: 'space-evenly' }
        }
      >
        <div className={styles.shortDescriptionContainer}>
          {lawyer.specialities && (
            <h4 className={styles.specialistText}>
              {lawyer.specialities.length > 0 &&
                lawyer.specialities.map((speciality, index) => {
                  if (index === 0) {
                    return (
                      <React.Fragment key={index}>
                        <span>
                          {`${LPTranslate('Input_Specialist_Lawyer')} `}
                        </span>
                        <span
                          key={index}
                          className={styles.specialistTextParagraph}
                          onClick={() => {
                            let spec = speciality;

                            spec = spec.replace(/\s+/g, '-');

                            navigate(`/fachanwalt/${spec}`);
                          }}
                        >{`${speciality}`}</span>
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <span
                        key={index}
                        className={styles.specialistTextParagraph}
                        onClick={() => {
                          let spec = speciality;

                          spec = spec.replace(/\s+/g, '-');

                          navigate(`/fachanwalt/${spec}`);
                        }}
                      >{`, ${speciality}`}</span>
                    );
                  }
                })}
            </h4>
          )}
          <h2 className={styles.lawyerNameText}>
            {lawyer.title && `${lawyer.title} `}
            {lawyer.first_name + ' ' + lawyer.last_name}
            {lawyer.title_suffix && `, ${lawyer.title_suffix}`}
          </h2>

          <h3 className={styles.descriptionText}>
            {lawyer.short_description
              ? lawyer.short_description
              : LawyerShortDescriptionJSX()}
          </h3>

          <div className={styles.areasOfLawContainer}>
            {lawyer.areasOfLaw.length > 0 &&
              lawyer.areasOfLaw.map(AreaOfLawTagJSX)}
          </div>
        </div>

        {/* {!lawyer.short_description && (
          <div className={styles.shortDescriptionContainer}>
            <h3 className={styles.descriptionText}>
              {LawyerShortDescriptionJSX()}
            </h3>
          </div>
        )} */}

        <div className={styles.lawyerDetailsContainer}>
          {(lawyer.location || lawyer.country || lawyer.website) && (
            <div className={styles.lawyerLocationAndWebsiteDetailsContainer}>
              {(lawyer.location || lawyer.country) && (
                <div className={styles.lawyerLocationDetailsContainer}>
                  <h4 className={styles.infoTitle}>
                    {LPTranslate('Common_Location')}
                  </h4>

                  <div className={styles.lawyerLocationContainer}>
                    {getFlag(lawyer.country) && (
                      <img
                        className={styles.flagImage}
                        src={getFlag(lawyer.country)}
                        alt="flag"
                      />
                    )}

                    <h3 className={styles.locationText}>
                      <span
                        className={styles.locationTextLink}
                        onClick={() => {
                          navigate(`/ort/${lawyer.location}`);
                        }}
                      >
                        {lawyer.location}
                      </span>
                      , {lawyer.country}
                    </h3>
                  </div>
                </div>
              )}

              {lawyer.subscription > 1 && lawyer.website && (
                <div className={styles.lawyerWebsiteDetailsContainer}>
                  <h4 className={styles.infoTitle}>
                    {LPTranslate('Common_Website')}
                  </h4>

                  <div className={styles.lawyerWebsiteContainer}>
                    <div className={styles.lawyerWebsiteText}>
                      <a
                        className={styles.infoTextLink}
                        data-cy="website-button"
                        href={`https://${lawyer.website}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {lawyer.website}
                        <NorthEastIcon transform="scale(0.7)" />
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className={styles.lawyerAppointmentAndEmailDetailsContainer}>
            <div className={styles.lawyerAppointmentDetailsContainer}>
              <h4 className={styles.infoTitle}>
                {LPTranslate('LPP_Bio_Availability')}
              </h4>

              <div className={styles.lawyerAppointmentContainer}>
                <div className={styles.lawyerAppointmentText}>
                  {AppointmentButton()}
                </div>
              </div>
            </div>

            <div className={styles.lawyerEmailDetailsContainer}>
              <h4 className={styles.infoTitle}>Email</h4>

              <div className={styles.lawyerEmailText}>
                {clientInfoRedux.role.length !== 0 ? (
                  <SendMessageToLawyerModal
                    lawyer={lawyer}
                    client={clientInfoRedux}
                  />
                ) : (
                  <SendMessageToLawyerNotLoggedModal
                    lawyer={lawyer}
                    client={clientInfoRedux}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.lawyerReviewAndClaimAccountDetailsContainer}>
            <div className={styles.lawyerReviewDetailsContainer}>
              <h4 className={styles.infoTitle}>
                {LPTranslate('SL_Filters_Rating_Name')}
              </h4>

              <div className={styles.lawyerAppointmentContainer}>
                <div className={styles.lawyerAppointmentText}>
                  {addReviewButton()}
                </div>
              </div>

              <AddLawyerReviewModal
                open={AddLawyerReviewModalOpen}
                handleClose={() => setAddLawyerReviewModalOpen(false)}
                lawyer={lawyer}
                averageNr={props.averageNr}
                reviewsNr={reviewsNr}
                onComplete={() => {}}
                ratingData={props.ratingData}
                setRatingData={props.setRatingData}
                setReviewsNr={props.setReviewsNr}
                setAverageNr={props.setAverageNr}
              />
            </div>

            {clientInfoRedux.role !== 'lawyer' && lawyer.user_id === null ? (
              <div className={styles.lawyerClaimAccountDetailsContainer}>
                <h4 className={styles.infoTitle}>
                  {LPTranslate('LPP_Bio_Claim_Account')}
                </h4>

                <div className={styles.lawyerClaimAccountContainer}>
                  {claimAccountButton()}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className={styles.lawyerAppointmentBoxContainer}>
          <div className={styles.lawyerAppointmentTitleAndTextContainer}>
            <h2 className={styles.lawyerAppointmentTitleText}>
              {lawyer.subscription > 1
                ? LPTranslate('Button_Make_Appointment')
                : LPTranslate('Button_Make_Appointment_Request')}
            </h2>

            <h3 className={styles.lawyerAppointmentTextContent}>
              {lawyer.subscription > 0
                ? LPTranslate('LPP_Bio_Appointment_Card_Description')
                : LPTranslate('LPP_FAQ_Answer_Not_Registered').replaceAll(
                    '<lawyer>',
                    lawyer.first_name + ' ' + lawyer.last_name
                  )}
            </h3>
          </div>

          <div className={styles.lawyerAppointmentButtonContent}>
            {appointmentButton()}
          </div>
        </div>
      </div>

      <AskLawyerToSetScheduleModal
        openModal={openModal}
        handleOpenModal={() => setOpenModal(false)}
        handleCloseSnackbar={handleCloseSnackbar}
        handleSnackbarMessage={(message) => setSnackbarMessage(message)}
        handleSnackbarSeverity={(type) => setSnackbarSeverity(type)}
        handleSnackbarOpen={() => setSnackbarOpen(true)}
        snackbarSeverity={snackbarSeverity}
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        profileImage={articleImage}
        title={lawyer.title}
        first_name={lawyer.first_name}
        last_name={lawyer.last_name}
        email={lawyer.email}
        subscription={lawyer.subscription}
      />
    </>
  );
};

export default LawyerInfoCard;
