import React from 'react';

import PartOfCommunityMockupSection from 'Components/help/PartOfCommunityMockupSection';
import MetaDecorator from 'Layouts/MetaDecorator';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/help/CookiesPage.scss';

const CookiesPage = () => {
  return (
    <div className="lawp-cookies-page-container">
      <MetaDecorator
        title={LPTranslate('Scoring_Policies_Meta_Title_1')}
        description={LPTranslate('Scoring_Policies_Meta_Description_1')}
        canonical={'canonical'}
        link={`${process.env.REACT_APP_DNS_URI}/bewertungsrichtlinien`}
      />
      <div className="lawp-cookies-page-faq-container">
        <div className="lawp-cookies-page-header-section">
          <h4 className="lawp-cookies-page-subheader">
            {' '}
            {LPTranslate('Cookies_Pre_Title')}{' '}
          </h4>

          <h1 className="lawp-cookies-page-header">
            {' '}
            {LPTranslate('Scoring_Policies_Title')}{' '}
          </h1>

          <h3 className="lawp-cookies-page-description">
            {LPTranslate('Scoring_Policies_Description_1')}{' '}
          </h3>
        </div>

        <div className="lawp-cookies-page-faq-section">
          <h3 className="lawp-cookies-page-text-block">
            {LPTranslate('Scoring_Policies_Description_2')}{' '}
          </h3>

          <ul>
            <li>
              <h2 className="lawp-cookies-page-faq-section-title">
                {LPTranslate('Scoring_Policies_Paragraph_1_Title')}
              </h2>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('Scoring_Policies_Paragraph_1_Description')}
              </h3>
            </li>

            <li>
              <h2 className="lawp-cookies-page-faq-section-title">
                {LPTranslate('Scoring_Policies_Paragraph_2_Title')}
              </h2>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('Scoring_Policies_Paragraph_2_Description')}
              </h3>
            </li>

            <li>
              <h2 className="lawp-cookies-page-faq-section-title">
                {LPTranslate('Scoring_Policies_Paragraph_3_Title')}
              </h2>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('Scoring_Policies_Paragraph_3_Description')}
              </h3>
            </li>

            <li>
              <h2 className="lawp-cookies-page-faq-section-title">
                {LPTranslate('Scoring_Policies_Paragraph_4_Title')}
              </h2>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('Scoring_Policies_Paragraph_4_Description')}
              </h3>
            </li>

            <li>
              <h2 className="lawp-cookies-page-faq-section-title">
                {LPTranslate('Scoring_Policies_Paragraph_5_Title')}
              </h2>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('Scoring_Policies_Paragraph_5_Description')}
              </h3>
            </li>

            <li>
              <h2 className="lawp-cookies-page-faq-section-title">
                {LPTranslate('Scoring_Policies_Paragraph_6_Title')}
              </h2>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('Scoring_Policies_Paragraph_6_Description')}
              </h3>
            </li>

            <li>
              <h2 className="lawp-cookies-page-faq-section-title">
                {LPTranslate('Scoring_Policies_Paragraph_7_Title')}
              </h2>

              <h3 className="lawp-cookies-page-text-block">
                {LPTranslate('Scoring_Policies_Paragraph_7_Description')}
              </h3>
            </li>
          </ul>

          <h3 className="lawp-cookies-page-text-block">
            {LPTranslate('Scoring_Policies_Description_3')}
          </h3>
        </div>
      </div>

      <div
        style={{
          width: '90%',
        }}
      >
        <PartOfCommunityMockupSection language="de" />
      </div>
    </div>
  );
};

export default CookiesPage;
