import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import UpcomingEventsAccordion from 'Components/Client/EventsComponents/UpcomingEventsAccordion';
import CancelEventModal from 'Components/Client/EventsComponents/CancelEventModal';
import SuggestNewAppointmentModal from 'Components/Client/EventsComponents/SuggestNewAppointmentModal';

import { createUrlSlug } from 'Services/Utils/metaData';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import defaultAvatar from 'Assets/pictures/avatarIcon.svg';
import styles from 'Assets/styles/client/UpcomingEvents.module.scss';

const AppointmentCard = (props) => {
  const {
    handleRefetch,
    cardData,
    role,
    menuButtonSelected,
    appointments,
    formValuesUpdate,
  } = props;

  let appointmentDate = `${moment.unix(cardData.date).format('DD.MM.YYYY')}`;

  let dateTime = moment(
    appointmentDate + ' ' + cardData.time.slice(0, 5),
    'DD.MM.YYYY HH:mm:ss'
  ).toDate();

  let startAppointment = moment(dateTime)
    .set({
      hour: cardData.time.slice(0, 3),
      minute: cardData.time.slice(3, 5),
    })
    .toDate();

  let endAppointment = moment(dateTime)
    .set({
      hour: cardData.time.slice(0, 3),
      minute: cardData.time.slice(3, 5),
    })
    .add('30', 'minutes')
    .toDate();

  const navigate = useNavigate();

  const [profileImage, setProfileImage] = useState(null);

  let convertProfileImage = async (image) => {
    setProfileImage(image);
  };

  useEffect(() => {
    if (cardData.lawyer.profile_image) {
      convertProfileImage(cardData.lawyer.profile_image);
    } else setProfileImage(null);
  }, [cardData.lawyer.profile_image]);

  useEffect(() => {
    if (cardData.client && cardData.client.profileImage) {
      convertProfileImage(cardData.client.profileImage);
    }
  }, [cardData.client]);

  const showDateAndTimeUntilMeeting = () => {
    if (
      menuButtonSelected === 0 &&
      moment(dateTime).format('X') - moment().format('X') < 10800
    ) {
      // afiseaza timpul ramas pana la meeting (maxim 3 ore) cu o culoare rosie
      return (
        <div className={styles['upcoming-events-card-time-supporting-text']}>
          {moment(dateTime).format('X') - moment().format('X') < 3600 &&
          cardData.minutesLeft !== undefined
            ? showTimeInMinutesUntilMeeting()
            : showTimeInHoursUntilMeeting()}
        </div>
      );
    } else {
      return (
        <div
          className={styles['upcoming-events-card-time-supporting-text-gray']}
        >
          {appointmentDate +
            ' / ' +
            startAppointment.getHours() +
            ':' +
            String(startAppointment.getMinutes()).padStart(2, '0') +
            '-' +
            endAppointment.getHours() +
            ':' +
            String(endAppointment.getMinutes()).padStart(2, '0')}
        </div>
      );
    }
  };

  const showTimeInMinutesUntilMeeting = () => {
    return (
      'In ' +
      cardData.minutesLeft +
      [
        moment(dateTime).format('X') - moment().format('X') <= 60
          ? ' ' + LPTranslate('LSA_Last_Minute_Left_Text')
          : LPTranslate('LSA_Minutes_Left_Text'),
      ]
    );
  };

  const showTimeInHoursUntilMeeting = () => {
    return (
      'In ' +
      (
        parseInt(moment(dateTime).format('X') - moment().format('X')) / 3600
      ).toPrecision(1) +
      [
        moment(dateTime).format('X') - moment().format('X') > 7200
          ? LPTranslate('LSA_Hours_Left_Text')
          : ' ' + LPTranslate('LSA_Last_Hour_Left_Text'),
      ]
    );
  };

  return (
    <div className={styles['upcoming-events-card']}>
      <div className={styles['upcoming-events-card-content']}>
        {showDateAndTimeUntilMeeting()}

        <div className={styles['upcoming-events-card-heading-content']}>
          {profileImage !== null ? (
            <img
              style={{ objectFit: 'cover' }}
              alt="profileImage"
              src={profileImage}
              className={styles['upcoming-events-card-check-icon']}
            />
          ) : (
            <img
              alt="defaultAvatar"
              src={defaultAvatar}
              className={styles['default-profile-image']}
            />
          )}
          <div className={styles['upcoming-events-card-heading-and-icon']}>
            <div
              className={
                styles['upcoming-events-card-text-and-supporting-text']
              }
            >
              {role === 'client' &&
                cardData?.specialist_attourneys &&
                cardData?.specialist_attourneys !== 'null' && (
                  <div className={styles['upcoming-events-card-author']}>
                    {cardData?.specialist_attourneys}
                  </div>
                )}
              {role === 'client' ? (
                <div
                  data-cy="lawyer-button"
                  className={styles['upcoming-events-card-name']}
                  onClick={() => {
                    let slug = createUrlSlug(
                      '',
                      cardData.lawyer.first_name,
                      cardData.lawyer.last_name,
                      ''
                    );

                    slug += `-${cardData.lawyer_id}`;
                    navigate(`/anwaelte/${slug}`);
                  }}
                >
                  {cardData?.lawyer_name}
                </div>
              ) : (
                <div
                  className={styles['upcoming-events-card-name']}
                  style={{ cursor: 'unset' }}
                >
                  {cardData?.client_name}
                </div>
              )}

              {role === 'lawyer' && cardData?.client && (
                <>
                  {cardData?.client.telephone && (
                    <div
                      className={styles['upcoming-events-card-supporting-text']}
                    >
                      {cardData?.client.telephone}
                    </div>
                  )}

                  {cardData?.client.email && (
                    <div
                      className={styles['upcoming-events-card-supporting-text']}
                    >
                      {cardData?.client.email}
                    </div>
                  )}
                </>
              )}

              <div className={styles['upcoming-events-card-supporting-text']}>
                {cardData?.appointment_type === 'Anruf' || cardData?.appointment_type === 'Call'
                  ? LPTranslate('LSA_Update_Appointment_Call')
                  : LPTranslate('LSA_Update_Appointment_Site_Meeting')}
              </div>
            </div>

            {cardData.appointment_type === 'Video Meeting' && (
              <button
                className={
                  styles['upcoming-events-card-go-to-conversation-button']
                }
              >
                {LPTranslate('LSA_Action_Button_Go_To_Talk')}
              </button>
            )}
            {/* </div> */}
          </div>
        </div>
      </div>

      <UpcomingEventsAccordion
        title={cardData.appointment_subject}
        description={cardData.appointment_description}
      />
      <div className={styles['upcoming-events-card-actions']}>
        <div className={styles['upcoming-events-card-actions-divider']} />

        <div className={styles['upcoming-events-card-actions-content']}>
          <div className={styles['upcoming-events-card-actions-frame1039']}>
            <CancelEventModal
              handleRefetch={() => handleRefetch()}
              // handleAppointmentID={(id) => {
              //   props.setAppointmentID(id);
              // }}
              data={cardData}
              appointmentDate={appointmentDate}
              startAppointment={startAppointment}
              endAppointment={endAppointment}
              role={role}
              menuButtonSelected={menuButtonSelected}
            />

            <SuggestNewAppointmentModal
              // resetAppointmentsList={() => props.resetAppointmentsList()}
              availability={cardData.lawyer.lawyerAvailability}
              profileImage={profileImage}
              appointmentDate={appointmentDate}
              startAppointment={startAppointment}
              endAppointment={endAppointment}
              propsData={cardData}
              role={role}
              appointments={appointments}
              menuButtonSelected={menuButtonSelected}
              handleRefetch={() => handleRefetch()}
              handleFormValuesUpdate={(titleStr, descriptionStr) => {
                props.setFormValuesUpdate({
                  ...formValuesUpdate,
                  title: titleStr,
                  description: descriptionStr,
                });
              }}
              // handleUpdateData={(cardData) => {
              //   props.setUpdateData(cardData);
              // }}
              // handleUpdateID={(id) => {
              //   props.setUpdateAppointmentID(id);
              // }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentCard;
