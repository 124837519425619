import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

const registerClientSteps = [
  {
    label: LPTranslate('RC_Step_1_Title'),
    description: LPTranslate('RC_Step_1_Description'),
    isChecked: false,
  },
  {
    label: LPTranslate('RC_Step_2_Title'),
    description: LPTranslate('RC_Step_2_Description'),
    isChecked: false,
  },
  {
    label: LPTranslate('RC_Step_3_Title'),
    description: LPTranslate('RC_Step_3_Description'),
    isChecked: false,
  },
  {
    label: LPTranslate('RC_Step_4_Title'),
    description: LPTranslate('RC_Step_4_Description'),
    isChecked: false,
  },
  {
    label: LPTranslate('RC_Step_5_Title'),
    description: LPTranslate('RC_Step_5_Description'),
    isChecked: false,
  },
];

export default registerClientSteps;
