import React from 'react';

import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

import LawyerNotificationMockup from './LawyerNotificationMockup';

import {
  LPTranslate,
  LPTranslateWithLanguage,
} from 'Services/Utils/LPTranslate/translate';

import MacBookMockUp from 'Assets/pictures/macBookMockUp.png';
import SearchPage from 'Assets/pictures/searchPageScreenShot2.png';
import 'Assets/styles/help/PartOfCommunityMockupSection.scss';

const PartOfCommunityMockupSection = (props) => {
  const { language } = props;
  const navigate = useNavigate();

  return (
    <div className="lawp-part-of-community-mockup-section-container">
      <div className="lawp-part-of-community-mockup-section-left-container">
        <div>
          <h2 className="lawp-part-of-community-mockup-section-title">
            {language
              ? LPTranslateWithLanguage('BPAC_Title', language)
              : LPTranslate('BPAC_Title')}
          </h2>
          <h3 className="lawp-part-of-community-mockup-section-description">
            {language
              ? LPTranslateWithLanguage('BPAC_Description', language)
              : LPTranslate('BPAC_Description')}
          </h3>
        </div>

        <div className="lawp-part-of-community-mockup-section-buttons-container">
          <Button
            className="lawp-part-of-community-mockup-section-client-button"
            data-cy="for-clients"
            onClick={() => navigate('/registerClient')}
          >
            <a
              className="lawp-part-of-community-mockup-section-client-button-a-href"
              data-cy="for-clients"
              href={`${process.env.REACT_APP_DNS_URI}/registerClient`}
              onClick={(e) => e.stopPropagation()}
            >
              {language
                ? LPTranslateWithLanguage('BPAC_Action_Button_Client', language)
                : LPTranslate('BPAC_Action_Button_Client')}
            </a>
          </Button>

          <Button
            className="lawp-part-of-community-mockup-section-lawyer-button"
            data-cy="for-lawyer"
            onClick={() => navigate('/registerLawyer')}
          >
            <a
              className="lawp-part-of-community-mockup-section-lawyer-button-a-href"
              data-cy="for-clients"
              href={`${process.env.REACT_APP_DNS_URI}/registerLawyer`}
              onClick={(e) => e.stopPropagation()}
            >
              {language
                ? LPTranslateWithLanguage('BPAC_Action_Button_Lawyer', language)
                : LPTranslate('BPAC_Action_Button_Lawyer')}
            </a>
          </Button>
        </div>
      </div>
      <div className="lawp-part-of-community-mockup-section-right-container">
        <div className="lawp-part-of-community-mockup-section-gray-background">
          <div className="lawp-part-of-community-mockup-notification-group">
            <LawyerNotificationMockup
              authorName="Olivia Rhye"
              nameFollowup={
                language
                  ? LPTranslateWithLanguage(
                      'BPAC_Lawyer_1_Description_1',
                      language
                    )
                  : LPTranslate('BPAC_Lawyer_1_Description_1')
              }
              transparencyValue={0.95}
              placeholderImageIndex={1}
            />
            <LawyerNotificationMockup
              authorName="Candice Wu"
              nameFollowup={
                language
                  ? LPTranslateWithLanguage(
                      'BPAC_Lawyer_2_Description_1',
                      language
                    )
                  : LPTranslate('BPAC_Lawyer_2_Description_1')
              }
              displayData={
                language
                  ? LPTranslateWithLanguage(
                      'BPAC_Lawyer_2_Description_2',
                      language
                    )
                  : LPTranslate('BPAC_Lawyer_2_Description_2')
              }
              transparencyValue={0.95}
              placeholderImageIndex={2}
            />
            <LawyerNotificationMockup
              authorName="Phoenix Baker"
              nameFollowup={
                language
                  ? LPTranslateWithLanguage(
                      'BPAC_Lawyer_3_Description_1',
                      language
                    )
                  : LPTranslate('BPAC_Lawyer_3_Description_1')
              }
              displayData={
                language
                  ? LPTranslateWithLanguage(
                      'BPAC_Lawyer_3_Description_2',
                      language
                    )
                  : LPTranslate('BPAC_Lawyer_3_Description_2')
              }
              transparencyValue={0.75}
              placeholderImageIndex={3}
            />
            <LawyerNotificationMockup
              authorName="Lana Steiner Baker"
              nameFollowup={
                language
                  ? LPTranslateWithLanguage(
                      'BPAC_Lawyer_4_Description_1',
                      language
                    )
                  : LPTranslate('BPAC_Lawyer_4_Description_1')
              }
              displayData={
                language
                  ? LPTranslateWithLanguage(
                      'BPAC_Lawyer_4_Description_2',
                      language
                    )
                  : LPTranslate('BPAC_Lawyer_4_Description_2')
              }
              transparencyValue={0.5}
              placeholderImageIndex={4}
            />
          </div>

          <div className="lawp-part-of-community-mockup-section-iphone-mockup-container">
            <img
              src={MacBookMockUp}
              alt="Iphone Mockup"
              className="lawp-part-of-community-mockup-section-iphone-mockup"
            />
            <img
              src={SearchPage}
              alt="Iphone Mockup"
              className="lawp-part-of-community-mockup-section-iphone-mockup-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartOfCommunityMockupSection;
