import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { GET_USER_PROFILE_IMAGE } from 'Services/Queries/user';

import CloseIcon from '@mui/icons-material/Close';

import DeleteAccountFirstCopmonent from 'Components/Lawyer/DeleteComponents/DeleteComponentFirstState';
import DeleteAccountSecondCopmonent from 'Components/Lawyer/DeleteComponents/DeleteComponentSecondState';
import DeleteAccountThirdCopmonent from 'Components/Lawyer/DeleteComponents/DeleteComponentThirdState';
import DeleteStepper from 'Components/Lawyer/DeleteComponents/DeleteStepper';
import DeleteModalSubscripion from 'Components/Lawyer/DeleteComponents/DeleteModalSubscripion';

import trashIcon from 'Assets/pictures/trashIcon.svg';
import avatar from 'Assets/pictures/avatarIcon.svg';
import styles from 'Assets/styles/lawyer/DeleteComponentsStyle/DeleteAccount.module.scss';

const DeleteAccountCopmonent = (props) => {
  const {
    deleteTitle,
    deleteDetails,
    deleteSubDetails,
    underButtonText,
    DeleteOnClick,
    state,
    benefits1,
    benefits2,
    benefits3,
    keepAccountButton,
    deleteComponentSubTitle,
    deleteAccountButtonState3,
    eligible,
    deleteState,
    user_id,
    handleToken,
    onClick,
    token,
    first_name,
    last_name,
  } = props;

  const [userImage, { data }] = useLazyQuery(GET_USER_PROFILE_IMAGE, {
    fetchPolicy: 'no-cache',
  });

  const [profileImage, setProfileImage] = useState(null);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    async function fetchData() {
      if (user_id) {
        await userImage({
          variables: {
            id: user_id,
          },
        });
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data && data.userInformationNavbar) {
      setProfileImage(data.userInformationNavbar.profileImage);
      setUsername(data.userInformationNavbar.username);
      setEmail(data.userInformationNavbar.email);
    }
  }, [data]);

  return (
    <>
      <div className={styles['parent']}>
        <div className={styles['gray-margin']}>
          <DeleteStepper
            state={state}
            deleteState={deleteState}
            eligible={eligible}
          />
        </div>

        <div className={styles['delete-container']}>
          <div className={styles['delete-inner-container']}>
            <div className={styles['delete-frame']}>
              <div className={styles['delete-content']}>
                {state <= 2 && (
                  <div className={styles['delete-header']}>
                    <div className={styles['delete-parent-featured-icon']}>
                      <img src={trashIcon} alt="trashIcon" />
                    </div>

                    <div className={styles['x-container']}>
                      <CloseIcon />
                    </div>

                    <div className={styles['avatar-label-group']}>
                      {profileImage !== '' && profileImage !== null ? (
                        <img
                          className={styles['avatar']}
                          src={profileImage}
                          alt="avatar"
                        />
                      ) : (
                        <img
                          className={styles['default-avatar']}
                          src={avatar}
                          alt="default-avatar"
                        />
                      )}

                      <div className={styles['lawyer-name-and-email']}>
                        <p className={styles['lawyer-name']}>{username}</p>

                        <p className={styles['lawyer-email']}>{email}</p>
                      </div>
                    </div>
                  </div>
                )}

                {state === 1 && (
                  <DeleteAccountFirstCopmonent
                    deleteTitle={deleteTitle}
                    deleteDetails={deleteDetails}
                    deleteSubDetails={deleteSubDetails}
                    keepAccountButton={keepAccountButton}
                    user_id={user_id}
                    handleToken={handleToken}
                    onClick={onClick}
                    underButtonText={underButtonText}
                    onBackButtonEvent={props.onBackButtonEvent}
                  />
                )}

                {state === 2 && (
                  <DeleteAccountSecondCopmonent
                    benefits1={benefits1}
                    benefits2={benefits2}
                    benefits3={benefits3}
                    deleteTitle={deleteTitle}
                    deleteDetails={deleteDetails}
                  />
                )}
                {state >= 3 && (
                  <DeleteAccountThirdCopmonent
                    deleteTitle={deleteTitle}
                    deleteDetails={deleteDetails}
                    benefits1={benefits1}
                    benefits2={benefits2}
                    benefits3={benefits3}
                    deleteSubDetails={deleteSubDetails}
                    deleteComponentSubTitle={deleteComponentSubTitle}
                    token={token}
                  />
                )}
                {/* home button state 1 and 2 */}
                <div
                  className={styles['delete-buttons-container']}
                  style={{ gap: '15px' }}
                >
                  {state >= 3 && eligible === true && (
                    <div className={styles['no-delete-button']}>
                      <div className={styles['button']}>
                        {state <= 2 && <></>}

                        <DeleteModalSubscripion
                          eligible={eligible}
                          keepAccountButton={keepAccountButton}
                          token={token}
                          first_name={first_name}
                          last_name={last_name}
                          email={props.email}
                        />
                      </div>
                    </div>
                  )}
                  {state === 2 && (
                    <div className={styles['delete-account-button']}>
                      <button
                        data-cy="submit-button"
                        className={styles['delete-account-button-base']}
                        onClick={DeleteOnClick}
                      >
                        <p className={styles['delete-account-button-text']}>
                          {underButtonText}
                        </p>
                      </button>

                      <button
                        data-cy="cancel-button"
                        className={styles['button-base']}
                        onClick={(event) => {
                          event.preventDefault();
                          props.onBackButtonEvent();
                        }}
                      >
                        <p className={styles['button-text']}>
                          {keepAccountButton}
                        </p>
                      </button>
                    </div>
                  )}
                  {state === 3 && (
                    <div className={styles['delete-account-button']}>
                      <button
                        data-cy="delete-anyway-button"
                        className={styles['delete-account-button-base']}
                        onClick={DeleteOnClick}
                      >
                        <p className={styles['delete-account-button-text']}>
                          {deleteAccountButtonState3}
                        </p>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DeleteAccountCopmonent;
