import { gql } from "@apollo/client";

export const GET_GOOGLE_AUTO_COMPLETE = gql`
    query googleAutoComplete($input: String!) {
        googleAutoComplete(input: $input) {
            locality
            country
            postalCode
            placeId
        }
    }
`;


export const GET_GOOGLE_PLACE_DETAILS = gql`
    query googleAutoPlaceDetails($input: String!) {
        googleAutoPlaceDetails(input: $input) {
            locality
            country
            postalCode
        }
    }
`;

