import { gql } from '@apollo/client';

export const ADD_OPERATOR = gql`
  mutation AddClient($input: CreateOperatorInput!) {
    addOperator(input: $input) {
      ... on Operator {
        name
        picture
        professional_title
        home_address
        telephone
        email
      }
      ... on CustomError {
        status
        message
      }
    }
  }
`;

export const GET_ALL_REVIEW_REPORTS = gql`
  query GetAllReviewReports($input: ReportsInput!) {
    reviewReports(input: $input) {
      reports {
        id
        review_id
        client_id
        lawyer_id
        report_reason
        report_message
        createdAt
      }
      number_of_reports
      number_of_pages
      status
    }
  }
`;

export const GET_REVIEW = gql`
  query GetReview($id: ID!) {
    review(id: $id) {
      id
      client_name
      lawyer_name
      title
      content
      rating
      lawyer_id
      client_id
      createdAt
      response
    }
  }
`;

export const DELETE_REVIEW_REPORT = gql`
  mutation deleteReviewReportByOperator($id: ID!) {
    deleteReviewReportByOperator(id: $id) {
      status
      message
    }
  }
`;

export const DELETE_REVIEW = gql`
  mutation deleteReviewByOperator($id: ID!) {
    deleteReviewByOperator(id: $id) {
      status
      message
    }
  }
`;

export const GET_LAWYER = gql`
  query GetLawyer($id: ID!) {
    lawyerByOperator(id: $id) {
      id
      salutation
      title
      title_suffix
      first_name
      last_name
      job_title
      lawfirm_name
      street
      house_number
      postcode
      location
      country
      telephone
      fax_number
      email
      emailalt
      rating
      user_id
      subscription
      profile_image
      review_count
    }
  }
`;

export const GET_CLIENT = gql`
  query GetClient($id: ID!) {
    clientByOperator(id: $id) {
      id
      salutation
      title
      title_suffix
      first_name
      last_name
      name
      location
      emailAlt
      home_address
      house_number
      zipcode
      telephone
      email
      user_id
      profileImage
    }
  }
`;

export const UPDATE_SOCIAL_MEDIA_CODE = gql`
  mutation UpdateSocialMediaCode($input: UpdateSocialMediaCodeInput!) {
    updateSocialMediaCode(input: $input) {
      status
      message
    }
  }
`;

export const DOWNLOAD_INVOICES_TIME_PERIOD = gql`
  query DownloadInvoicesTimePeriod($input: DownloadInvoicesInput!) {
    downloadInvoicesTimePeriod(input: $input)
  }
`;
