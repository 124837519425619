import moment from 'moment';

const indexWeekday = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

const timestampToDate = (timestamp) => {
  let date = null;
  if (timestamp.length === 10) {
    date = moment(timestamp, 'X');
  } else if (timestamp.length === 13) {
    date = moment(timestamp, 'x');
  }
  return date;
};

const transformHolidays = (backendHolidays) => {
  // transform hour holidays from back-end into a list of dates that should be compared with the cells date
  const holidays = [];
  for (let i = 0; i < backendHolidays.length; i++) {
    let startDate = timestampToDate(backendHolidays[i].start_date_timestamp);
    const endDate = timestampToDate(backendHolidays[i].end_date_timestamp);
    while (startDate <= endDate) {
      holidays.push(`${moment(startDate).format('DD MMMM YYYY')}`);
      startDate = moment(startDate).add(1, 'd');
    }
  }

  return holidays;
};

const transformReservedAppointments = (backendReserved) => {
  // create reserved object that hold as a key the date and each key have a list of hours that means the reserved appointments, that should not be rendered on front-end
  const reserved = {};
  for (let i = 0; i < backendReserved.length; i++) {
    const hourMinutesSeconds = backendReserved[i].hour.split(':');
    const date = timestampToDate(backendReserved[i].date).set({
      hour: hourMinutesSeconds[0],
      minute: hourMinutesSeconds[1],
      seconds: '00',
    });
    const dateString = `${moment(date).format('DD MMMM YYYY')}`;
    const hourString = `${moment(date).format('HH:mm')}`;
    if (!reserved[dateString]) {
      reserved[dateString] = [];
    }
    reserved[dateString].push(hourString);
  }
  return reserved;
};

const transformSchedule = (backendSchedule) => {
  // create the lawyer schedule for every day of the week that is enabled by lawyer
  const schedule = {};
  let index = 1;
  for (let i = 0; i < backendSchedule.length; i++) {
    index = indexWeekday[backendSchedule[i].time_day];

    if (backendSchedule[i].enabled === 1) {
      // startString/endString are strings of hour:minutes:seconds, they are used to create start, end date for while to push the hours:minutes into the list for that weekday index
      const startString =
        backendSchedule[
          i
        ].lawyer_availability_time_day_intervals.time_start.split(':');

      const endString =
        backendSchedule[
          i
        ].lawyer_availability_time_day_intervals.time_end.split(':');
      // define the list the first time
      if (!schedule[index]) {
        schedule[index] = [];
      }
      // the start date/ end date using the hour and minutes from backend(dynamically)
      let time_start = moment().set({
        hour: startString[0],
        minute: startString[1],
        seconds: '00',
      });
      const time_end = moment().set({
        hour: endString[0],
        minute: endString[1],
        seconds: '00',
      });
      let hour = '';
      // add hours:minutes to the list
      while (time_start <= time_end) {
        hour = moment(time_start).format('HH:mm');
        schedule[index].push(hour);
        // increment the appointment minutes
        time_start = moment(time_start).add(30, 'minutes');
      }
    }
  }

  return schedule;
};
const tranformToCalendarTimestamp = (dateMoment) => {
  // "2022-11-21T13:00:00+02:00"
  const timestamp = moment(dateMoment).format();
  // ['2022', '11', '21T13:00:00+02:00']
  const timestampArray = timestamp.split('-');
  //["21T13:00:00","02:00"]
  const subTimestampArray = timestampArray[2].split('+');
  // ["21T13","00","00"]
  const dayHourArray = subTimestampArray[0].split(':');
  // "YYYY+MM+DD+T+HH+MM+SS"
  return `${timestampArray[0]}${timestampArray[1]}${dayHourArray[0]}${dayHourArray[1]}${dayHourArray[2]}`;
};

export {
  transformHolidays,
  transformReservedAppointments,
  transformSchedule,
  tranformToCalendarTimestamp,
};
