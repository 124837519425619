import { useNavigate } from 'react-router';
import styles from 'Assets/styles/lawyer/SubscriptionModal/SubscriptionFooterComponent.module.scss';

import avatarImage1 from 'Assets/pictures/AvatarImage1.png';
import avatarImage2 from 'Assets/pictures/AvatarImage2.png';
import avatarImage3 from 'Assets/pictures/AvatarImage3.png';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

const SubscriptionFooterComponent = () => {
  const navigate = useNavigate();

  const handleButton = () => {
    navigate('/kontakt');
  };
  return (
    <div className={styles['subscription-footer-container']}>
      <div className={styles['subscription-footer-container-gray']}>
        <div className={styles['subscription-footer-container-avatar-group']}>
          <div className={styles['subscription-footer-container-avatar-three']}>
            <img
              className={styles['img-dimensions']}
              src={avatarImage1}
              alt="AvatarProfile1"
            />
          </div>
          <div className={styles['subscription-footer-container-avatar-two']}>
            <img
              className={styles['img-dimensions']}
              src={avatarImage2}
              alt="avatarImage2"
            />
          </div>

          <div className={styles['subscription-footer-container-avatar-one']}>
            <img
              className={styles['img-dimensions']}
              src={avatarImage3}
              alt="avatarImage3"
            />
          </div>
        </div>

        <div
          className={
            styles[
              'subscription-footer-container-heading-and-supportingtext-container'
            ]
          }
        >
          <div className={styles['subscription-heading']}>
            {LPTranslate('Anwado_Any_Questions')}
          </div>
          <div className={styles['subscription-supporting-text']}>
            {LPTranslate('Anwado_Any_Questions_Description')}
          </div>
        </div>
        <div className={styles['subscription-footer-button-container']}>
          <button
            className={styles['subscription-footer-button']}
            data-cy="contact-us"
            onClick={handleButton}
          >
            <div className={styles['subscription-footer-button-text']}>
              <a
                href={`${process.env.REACT_APP_DNS_URI}/kontakt`}
                style={{ color: 'white' }}
                onClick={(e) => e.stopPropagation()}
              >
                {LPTranslate('LSUS_Bottom_Box_Action_Button')}
              </a>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
export default SubscriptionFooterComponent;
