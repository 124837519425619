import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';

import UpdateLawyerPasswordModal from 'Components/Modals/UpdateLawyerPasswordModal';
import DeleteAccountModal from 'Components/Modals/DeleteAccountModal';
import DeleteAccountModalLawyer from 'Components/Modals/DeleteAccountModalLawyer';
import UpdateLawyerEmailModal from 'Components/Modals/UpdateLawyerEmailModal';

import { VERIFY_IF_LAWYER_UPDATED_EMAIL } from 'Services/Queries/lawyer';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import mailIcon from 'Assets/pictures/dark-email.svg';

import styles from 'Assets/styles/updateLawyerDetails/UpdateLawyerDetailsSection.module.scss';
import { parseJwt } from 'Services/Utils/token';

const UpdateLawyerDetailsSection = (props) => {
  const [verifyIfLawyerUpdatedEmail] = useMutation(
    VERIFY_IF_LAWYER_UPDATED_EMAIL
  );
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);
  const [email, setEmail] = useState('');
  const [check, setCheck] = useState(false);
  const [error, setError] = useState(false);
  const [responseChangingPassword, setResponseChangingPassword] = useState('');

  let [errorStyles] = useState({
    errorMessageAndButton: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '512px',
      flex: 'none',
      order: '1',
      flexGrow: '0',
      alignItems: 'center',
    },
    frame14Button: {
      width: '512px',
      flex: 'none',
      order: '1',
      flexGrow: '0',
    },
  });

  useEffect(() => {
    const fetch = async () => {
      try {
        let lawyer = await verifyIfLawyerUpdatedEmail({
          variables: {
            email: clientInfoRedux.email,
          },
        });

        if (
          lawyer.data.verifyIfLawyerUpdatedEmail &&
          lawyer.data.verifyIfLawyerUpdatedEmail.newEmail !== ''
        ) {
          setEmail(lawyer.data.verifyIfLawyerUpdatedEmail.newEmail);
          setCheck(true);
        } else if (!lawyer.data.verifyIfLawyerUpdatedEmail) {
          setCheck(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyIfLawyerUpdatedEmail.loading]);

  const handleOpenDeleteLawyerModal = () => {
    if (parseJwt(clientInfoRedux.token).subscription === 1)
      props.setStepState(2);
    else props.setOpenModal(true);
  };

  return (
    <>
      <div className={styles['form']}>
        <div className={styles['frame10']}>
          <div className={styles['frame10-content']}>
            <div className={styles['text-and-supporting-text']}>
              <p className={styles['text']}>{LPTranslate('AUS_Email_Title')}</p>

              <p className={styles['supporting-text']}>
                {LPTranslate('AUS_Email_Description')}
              </p>
            </div>

            <div className={styles['input-field']}>
              <div className={styles['email-input-base']}>
                <img src={mailIcon} className={styles['mailIcon']} alt="mail" />

                <input
                  disabled
                  className={styles['frame10-input-email']}
                  id={'email'}
                  name="email"
                  type="email"
                  defaultValue={clientInfoRedux.email}
                  placeholder="Enter email"
                />

                {props.check && (
                  <div className={styles['check-step-icon-base']}>
                    <div className={styles['checked-icon-content']}>
                      <img
                        className={styles['check-icon']}
                        src={check}
                        alt="checked"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className={styles['message-text-and-button']}>
                <div className={styles['frame1031']}>
                  {check && (
                    <>
                      <div className={styles.unselectedStepIconBase}>
                        <div className={styles.unSelectedIconContent}>
                          <div className={styles.unSelectedDot} />
                        </div>
                      </div>

                      <p className={styles['frame1031-text']}>
                        {LPTranslate('Error_Email_Update_Pending1')} {email}{' '}
                        {LPTranslate('Error_Email_Update_Pending2')}
                      </p>
                    </>
                  )}

                  {error && !check && (
                    <>
                      <div className={styles.unselectedStepIconBase}>
                        <div className={styles.unSelectedIconContent}>
                          <div className={styles.unSelectedDot} />
                        </div>
                      </div>

                      <p className={styles['frame1031-text']}>
                        {LPTranslate('Error_Server_Down')}
                      </p>
                    </>
                  )}
                </div>
                <UpdateLawyerEmailModal
                  setCheck={() => setCheck(true)}
                  setError={setError}
                  handleUpdatedEmail={(newEmail) => setEmail(newEmail)}
                  email={email}
                  setOpenModals={props.setOpenModals}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles['Divider']} />

        <div className={styles['frame11']}>
          <div className={styles['frame11-content']}>
            <div className={styles['text-and-supporting-text']}>
              <p className={styles['text']}>
                {LPTranslate('AUS_Password_Title')}
              </p>

              <p className={styles['supporting-text']}>
                {LPTranslate('AUS_Password_Description')}
              </p>
            </div>

            <div className={styles['input-field']}>
              <div className={styles['input-base']}>
                <label className={styles['frame11-password-label']}>
                  {LPTranslate('CDPM_Step_1_Input_Title')}
                </label>
                <input
                  className={styles['frame11-password-input']}
                  name="password"
                  type="password"
                  onChange={(e) => props.setPassword(e.target.value)}
                  defaultValue=""
                  placeholder="••••••••"
                  readOnly={true}
                />

                <div
                  className={styles['frame14-container']}
                  style={
                    responseChangingPassword !== ''
                      ? errorStyles.errorMessageAndButton
                      : errorStyles.frame14Button
                  }
                >
                  {responseChangingPassword !== '' && (
                    <>
                      <div className={styles['error-icon-base']}>
                        <div className={styles.unselectedStepIconBase}>
                          <div className={styles.unSelectedIconContent}>
                            <div className={styles.unSelectedDot} />
                          </div>
                        </div>
                        <p className={styles['error-text']}>
                          {responseChangingPassword}
                        </p>
                      </div>
                    </>
                  )}
                  <div className={styles['frame14']}>
                    <UpdateLawyerPasswordModal
                      setResponseChangingPassword={setResponseChangingPassword}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles['Divider']} />

        <div className={styles['frame11']}>
          <div className={styles['frame11-content']}>
            <div className={styles['text-and-supporting-text']}>
              <p className={styles['text']}>{LPTranslate('DA_Delete_Title')}</p>

              <p className={styles['supporting-text']}>
                {LPTranslate('DA_Delete_Description')}
              </p>
            </div>
            <div className={styles['frame14-delete']}>
              {clientInfoRedux.role === 'client' ? (
                <DeleteAccountModal />
              ) : (
                // <button
                //   data-cy="delete-lawyer-button"
                //   className={styles['delete-account-button']}
                //   onClick={handleOpenDeleteLawyerModal}
                //   disabled={false}
                // >
                //   {LPTranslate('DA_Delete_Button')}
                // </button>
                <DeleteAccountModalLawyer
                  handleNext={handleOpenDeleteLawyerModal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateLawyerDetailsSection;
