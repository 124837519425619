import React, { useState, useEffect } from 'react';

// import { useNavigate } from 'react-router';
import { useMutation } from '@apollo/client';

import NavBarLanguageSelect from 'Layouts/NavBarLanguageSelect';

import trash from 'Assets/pictures/trashIcon.svg';
import checkCircle from 'Assets/pictures/checkCircle.svg';

import useAlert from 'Services/Utils/hooks/useAlert';
import { DELETE_LAWYER_REGISTER_LINK_UUID } from 'Services/Queries/lawyer';
import { parseJwt } from 'Services/Utils/token';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/footer.scss';
import styles from 'Assets/styles/lawyer/DeleteLawyerPage.module.scss';

const bottom = [
  {
    title: LPTranslate('LN_Title'),
    link: `${process.env.REACT_APP_DNS_URI}/impressum`,
    data_cy: 'footer-imprint',
  },
  {
    title: LPTranslate('Footer_Privacy'),
    link: `${process.env.REACT_APP_DNS_URI}/datenschutz-cookies`,
    data_cy: 'footer-privacy',
  },
];

const DeleteLawyerPage = () => {
  // const navigate = useNavigate();
  const { setAlert } = useAlert();

  const [deleteLawyerRegisterLinkUUID] = useMutation(
    DELETE_LAWYER_REGISTER_LINK_UUID
  );

  const [step, setStep] = useState(1);
  const [deleteToken, setDeleteToken] = useState('');

  const [lawyerFirstName, setLawyerFirstName] = useState('');
  const [lawyerLastName, setLawyerLastName] = useState('');
  const [lawyerEmail, setLawyerEmail] = useState('');

  const extractParams = (urlString) => {
    const url = new URL(urlString);
    const params = url.searchParams;

    setLawyerFirstName(params.get('firstName'));
    setLawyerLastName(params.get('lastName'));
    setLawyerEmail(params.get('email'));
  };

  useEffect(() => {
    const url = window.location.href;
    const urlArray = url.split('/');
    const token = urlArray[urlArray.length - 1];
    setDeleteToken(token);

    const tokenInfo = parseJwt(token);
    extractParams(tokenInfo.registerLink);
  }, []);

  const handleDeleteAccount = async () => {
    const response = await deleteLawyerRegisterLinkUUID({
      variables: {
        token: deleteToken,
      },
    });

    const success = response.data.deleteLawyerRegisterLinkUUID.message;

    if (success === 'Success') {
      setStep(2);
    } else {
      setAlert(LPTranslate('Error_Could_Not_Delete_Lawyer'));
    }
  };

  return (
    <div className={styles['container']}>
      {step === 1 && (
        <div className={styles['forgot-password-page-container']}>
          <div
            className={`${styles['forgot-password-box-container']} 
        ${styles['email-password-request-box-container']}`}
          >
            <div
              className={`${styles['forgot-password-header']} 
          ${styles['email-password-request-header']}`}
            >
              <div className={styles['forgot-password-header-icon']}>
                <img src={trash} alt="Key Icon" />
              </div>
              <div
                className={`${styles['forgot-password-header-text-support-container']} 
        ${styles['email-password-request-header-text-support']}`}
              >
                <h2
                  className={`${styles['forgot-password-header-text']} 
        ${styles['email-password-request-header-text']}`}
                >
                  {LPTranslate('DA_Lawyer_Delete_Title')}
                </h2>
                <h3
                  className={`${styles['forgot-password-header-support']} 
        ${styles['email-password-request-header-support']}`}
                >
                  {LPTranslate('DA_Laywer_Delete_Description_1')}
                  <br /> <br />
                  {lawyerFirstName} {lawyerLastName} ({lawyerEmail}) <br />{' '}
                  <br /> {LPTranslate('DA_Laywer_Delete_Description_2')}.
                </h3>
              </div>
            </div>

            <div className={`${styles['forgot-password-content']} `}>
              <div
                className={`${styles['forgot-password-form-button-container']} 
          ${styles['email-password-request-action-button-margin-top']}`}
              >
                <button
                  data-cy="submit-button"
                  className={styles['forgot-password-form-button']}
                  type="submit"
                  onClick={() => handleDeleteAccount()}
                >
                  {LPTranslate('DA_Delete_Lawyer_Profile_Button')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className={styles['forgot-password-page-container']}>
          <div
            className={`${styles['forgot-password-box-container']} 
          ${styles['password-reset-complete-box-container']}`}
          >
            <div
              className={`${styles['forgot-password-header']} 
            ${styles['password-reset-complete-header']}`}
            >
              <div className={styles['password-reset-complete-header-icon']}>
                <img src={checkCircle} alt="checkCircle Icon" />
              </div>
              <div
                className={`${styles['forgot-password-header-text-support-container']} 
          ${styles['password-reset-complete-header-text-support']}`}
              >
                <div
                  className={`${styles['forgot-password-header-text']} 
          ${styles['password-reset-complete-header-text']}`}
                >
                  {LPTranslate('DA_Lawyer_Account_Deleted')}
                </div>
                <div
                  className={`${styles['forgot-password-header-support']} 
          ${styles['password-reset-complete-header-support']}`}
                >
                  {LPTranslate('DA_Lawyer_Account_Deleted_Description')}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className="lawp-footer-container"
        style={{
          padding: '16px 24px 12px 24px',
        }}
      >
        <div className="bottom">
          <div className="left">
            {/* <h3>© ANWADO. {LPTranslate('Footer_All_Rights_Reserved')}</h3> */}

            <h4 id="app-version-info">Version 1.3.523</h4>
          </div>

          <div className="right">
            {bottom.map((item, index) => (
              <a
                className="bottom-link"
                href={item.link}
                data-cy={item.data_cy}
                key={index}
              >
                {item.title}
              </a>
            ))}

            <NavBarLanguageSelect />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteLawyerPage;
