import React from 'react';

import styles from 'Assets/styles/subscription/subscriptionProcess.module.scss';
import PaymentPlan from 'Components/Subscription/PaymentPlan';
import InvoiceList from 'Components/Subscription/InvoiceList';

const SubscriptionProcess = () => {
  return (
    <div className={styles['subscription-process-container']}>
      <div className={styles['Divider']} />

      <PaymentPlan />

      <InvoiceList />
    </div>
  );
};

export default SubscriptionProcess;
