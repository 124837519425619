import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import { useQuery, useLazyQuery } from '@apollo/client';

import SubscriptionTable from 'Components/Subscription/SubscriptionTable';

import { milisecondsToDDMMYYYY } from 'Services/Utils/moment';
import {
  GET_LAWYER_INVOICES,
  GET_LAWYER_INVOICES_AS_ZIP_BASE64_ENCODED,
} from 'Services/Queries/stripe';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import fileDownloadIcon from 'Assets/pictures/fileDownloadIcon.svg';
import styles from 'Assets/styles/subscription/invoiceList.module.scss';

const InvoiceList = (props) => {
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const [invoiceList, setInvoiceList] = useState([]);
  const [isAnyInvoiceSelected, setIsAnyInvoiceSelected] = useState(false);

  const getLawyerInvoices = useQuery(GET_LAWYER_INVOICES, {
    variables: { lawyer_id: clientInfoRedux.role_id },
  });

  const [getPDFZip, { data }] = useLazyQuery(
    GET_LAWYER_INVOICES_AS_ZIP_BASE64_ENCODED
  );

  const colNames = ['Invoice', 'Datum', 'Status', 'Betrag', 'Plan', 'download'];

  const downloadPDF = (url) => {
    let link = document.createElement('a');
    link.href = url;
    link.download = 'file.pdf';
    link.dispatchEvent(new MouseEvent('click'));
  };

  const handleDownloadSingle = (id) => {
    for (let i = 0; i < invoiceList.length; i++) {
      if (invoiceList[i].id === id) {
        downloadPDF(invoiceList[i].downloadLink);
        break;
      }
    }
  };

  useEffect(() => {
    if (
      getLawyerInvoices.data &&
      getLawyerInvoices.data.lawyerInvoices &&
      getLawyerInvoices.data.lawyerInvoices.length !== 0 &&
      invoiceList.length === 0
    ) {
      let databaseInvoices = [...getLawyerInvoices.data.lawyerInvoices];

      databaseInvoices.sort((a, b) => {
        return b.date - a.date;
      });

      let invoicesArray = [];
      for (let i = 0; i < databaseInvoices.length; i++) {
        invoicesArray.push({
          selected: false,
          id: i,
          name: databaseInvoices[i].name,
          date: milisecondsToDDMMYYYY(databaseInvoices[i].date),
          status: databaseInvoices[i].status,
          totalValue: databaseInvoices[i].sum,
          plan: databaseInvoices[i].plan,
          downloadLink: databaseInvoices[i].downloadLink,
        });
      }

      setInvoiceList(invoicesArray);
    }
  }, [getLawyerInvoices, invoiceList]);

  useEffect(() => {
    setIsAnyInvoiceSelected(false);
    for (let i = 0; i < invoiceList.length; i++) {
      if (invoiceList[i].selected) {
        setIsAnyInvoiceSelected(true);
        break;
      }
    }
  }, [invoiceList]);

  useEffect(() => {
    if (data !== undefined && data !== '' && data !== null) {
      let bufferTest = Buffer.from(
        data.lawyerInvoicesPDFAsZipBase64Encoded,
        'base64'
      );

      fileDownload(bufferTest, 'invoices.zip');
    }
  }, [data]);

  return (
    <div className={styles['invoice-list-section-container']}>
      {invoiceList.length > 0 && (
        <div className={styles['invoice-list-container']}>
          <div className={styles['invoice-list-header-container']}>
            <div className={styles['invoice-list-header-content']}>
              <div className={styles['invoice-list-header-title-text']}>
                <div className={styles['invoice-list-header-title']}>
                  {LPTranslate('LSS_Invoices_Title')}
                </div>
              </div>
              <div className={styles['invoice-list-header-action']}>
                {isAnyInvoiceSelected ? (
                  <button
                    data-cy="invoice-header-button"
                    className={styles['invoice-list-header-button']}
                    onClick={() => {
                      getPDFZip({
                        variables: {
                          input: invoiceList
                            .filter((invoice) => invoice.selected)
                            .map((invoice) => {
                              return {
                                name: invoice.name,
                                pdf_url: invoice.downloadLink,
                              };
                            }),
                        },
                      });
                    }}
                  >
                    <img
                      src={fileDownloadIcon}
                      className={styles['download-cloud']}
                      alt="download file"
                    />
                    {LPTranslate('LSS_Invoices_Action_Button')}
                  </button>
                ) : (
                  <button
                    disabled
                    data-cy="invoice-header-button"
                    className={styles['invoice-list-header-button-disabled']}
                  >
                    <img
                      src={fileDownloadIcon}
                      className={styles['download-cloud']}
                      style={{
                        filter:
                          'invert(91%) sepia(69%) saturate(0%) hue-rotate(180deg) brightness(99%) contrast(102%)',
                      }}
                      alt="download file"
                    />
                    {LPTranslate('LSS_Invoices_Action_Button')}
                  </button>
                )}
              </div>
            </div>
            <div className={styles['invoice-list-header-divider']}></div>
          </div>

          <SubscriptionTable
            list={invoiceList}
            colNames={colNames}
            handleDownloadSingle={handleDownloadSingle}
            handleInvoiceList={(data) => setInvoiceList(data)}
          />
        </div>
      )}
    </div>
  );
};

export default InvoiceList;
