import React from 'react';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import useAlert from 'Services/Utils/hooks/useAlert';

import 'Assets/styles/common/SnackbarComponent.scss';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackbarComponent = (props) => {
  const { open, handleClose, severity, message } = useAlert();

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <div>
        <Alert
          onClose={handleClose}
          severity={severity}
          className="snack-bar-component-style"
        >
          {message}
        </Alert>
      </div>
    </Snackbar>
  );
};

export default SnackbarComponent;
