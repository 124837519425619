import React from 'react';

import Skeleton from '@mui/material/Skeleton';

import SimpleTextField from 'Components/Inputs/SimpleTextField';
// import EditLawyerPhoneInput from 'Components/Lawyer/Edit/EditLawyerPhoneInput';
import GoogleAutocompleteCustomTextfield from 'Components/GoogleAutocompleteCustomTextfield';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/lawyer/EditLawyerSections.module.scss';

const EditLawyerContactInformationSection = (props) => {
  const { loading, error } = props;

  const {
    location,
    street,
    house_number,
    postcode,
    country,
    telephone,
    faxNumber,
    email,
    emailError,
    addressErrorMessages,
  } = props;

  const {
    onChangeLocation,
    onChangeStreet,
    onChangeHouseNumber,
    onChangePostcode,
    onChangeCountry,
    onChangeTelephone,
    onChangeFaxNumber,
    onChangeEmail,
  } = props;

  const GoogleAutocompleteDropdownHandler = (location, country, postcode) => {
    onChangeLocation(location);
    onChangeCountry(country);
    onChangePostcode(postcode);
  };

  return (
    <div className={styles.container}>
      <div className={styles.fullSection}>
        {loading || error ? (
          <>
            <Skeleton variant="rectangular" width={512} height={70} />
          </>
        ) : (
          location !== null && (
            // TODO: Replace this with default google autocomplete
            <GoogleAutocompleteCustomTextfield
              value={location}
              onTextfieldChange={(value) => onChangeLocation(value)}
              onDropdownSelect={GoogleAutocompleteDropdownHandler}
              title={`${LPTranslate('Input_City')}*`}
              errorMessage={addressErrorMessages.location}
            />
          )
        )}
      </div>

      <div className={styles.fullSection} style={{ marginTop: '15px' }}>
        {loading || error ? (
          <>
            <Skeleton
              variant="rectangular"
              width={512}
              height={70}
              sx={{ mt: '20px' }}
            />
          </>
        ) : (
          <>
            <SimpleTextField
              data_cy="street-input"
              value={street}
              onChange={(value) => onChangeStreet(value)}
              title={`${LPTranslate('Input_Street')}*`}
              textFieldWidthPercent={100}
              errorMessage={addressErrorMessages.street}
            />

            <div style={{ width: '15%', marginLeft: '20px' }}>
              <SimpleTextField
                data_cy="nr-input"
                value={house_number}
                onChange={(value) => onChangeHouseNumber(value)}
                title="Nr.*"
                errorMessage={addressErrorMessages.house_number}
              />
            </div>
          </>
        )}
      </div>

      <div className={styles.fullSection} style={{ marginTop: '15px' }}>
        {loading || error ? (
          <>
            <Skeleton
              variant="rectangular"
              width={512}
              height={70}
              sx={{ mt: '20px' }}
            />
          </>
        ) : (
          <SimpleTextField
            data_cy="zip-code-input"
            value={postcode}
            onChange={(value) => onChangePostcode(value)}
            title={`${LPTranslate('Input_Postcode')}*`}
            errorMessage={addressErrorMessages.postcode}
          />
        )}
      </div>

      <div className={styles.fullSection} style={{ marginTop: '15px' }}>
        {loading || error ? (
          <>
            <Skeleton
              variant="rectangular"
              width={512}
              height={70}
              sx={{ mt: '20px' }}
            />
          </>
        ) : (
          <SimpleTextField
            data_cy="country-input"
            value={country}
            onChange={(value) => onChangeCountry(value)}
            title={`${LPTranslate('Input_Country')}*`}
            textFieldWidthPercent={100}
            errorMessage={addressErrorMessages.country}
          />
        )}
      </div>

      <div className={styles.fullSection} style={{ marginTop: '15px' }}>
        {loading || error ? (
          <>
            <Skeleton
              variant="rectangular"
              width={512}
              height={70}
              sx={{ mt: '20px' }}
            />
          </>
        ) : (
          // <EditLawyerPhoneInput
          //   value={telephone}
          //   onChange={(value) => {
          //     onChangeTelephone(value);
          //   }}
          //   title={LPTranslate('Input_Telephone')}
          // />
          <SimpleTextField
            data_cy="phone-input"
            value={telephone}
            onChange={(value) => onChangeTelephone(value)}
            title={`${LPTranslate('Input_Telephone')}*`}
            textFieldWidthPercent={100}
            errorMessage={addressErrorMessages.telephone}
          />
        )}
      </div>

      <div className={styles.fullSection} style={{ marginTop: '15px' }}>
        {loading || error ? (
          <>
            <Skeleton
              variant="rectangular"
              width={512}
              height={70}
              sx={{ mt: '20px' }}
            />
          </>
        ) : (
          // <EditLawyerPhoneInput
          //   value={faxNumber}
          //   onChange={(value) => {
          //     onChangeFaxNumber(value);
          //   }}
          //   title={LPTranslate('Input_Telefax')}
          // />
          <SimpleTextField
            data_cy="phone-input"
            value={faxNumber}
            onChange={(value) => onChangeFaxNumber(value)}
            title={LPTranslate('Input_Telefax')}
            textFieldWidthPercent={100}
            // errorMessage={emailAltError}
          />
        )}
      </div>

      <div className={styles.fullSection} style={{ marginTop: '15px' }}>
        {loading || error ? (
          <>
            <Skeleton
              variant="rectangular"
              width={512}
              height={70}
              sx={{ mt: '20px' }}
            />
          </>
        ) : (
          <SimpleTextField
            data_cy="email-alt-input"
            value={email}
            onChange={(value) => onChangeEmail(value)}
            title="E-Mail"
            textFieldWidthPercent={100}
            errorMessage={emailError}
          />
        )}
      </div>
    </div>
  );
};

export default EditLawyerContactInformationSection;
