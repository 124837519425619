import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import subscriptionPageTextVariables from 'Services/TextVariables/subscriptionPageTextVariables.js';
import subscriptionTextVariables from 'Services/TextVariables/subscriptionCardTextVariables.js';
import { GET_PRICES, CREATE_PAYMENT_SESSION } from 'Services/Queries/stripe';

import checkCircleSmall from 'Assets/pictures/checkCircleSmall.svg';
import lightningIconCircle from 'Assets/pictures/lightningIconCircle.svg';
import lightningIcon from 'Assets/pictures/lightningIcon.svg';
import featuresSectionPurpleArrow from 'Assets/pictures/featuresSectionPurpleArrow.svg';

import styles from 'Assets/styles/lawyer/SubscriptionFeaturesSection.module.scss';

export default function SubscriptionFeaturesSection(props) {
  const [createPaymentSession] = useMutation(CREATE_PAYMENT_SESSION);

  const [recurringInterval, setRecurringInterval] = useState('year');

  const [DbPrices, setDbPrices] = useState([]);

  const yearly = subscriptionPageTextVariables.yearly;
  const monthly = subscriptionPageTextVariables.monthly;
  const percentage = subscriptionPageTextVariables.persentage;

  const pricesQuery = useQuery(GET_PRICES);

  useEffect(() => {
    if (pricesQuery.data) {
      setDbPrices(pricesQuery.data.prices);
    }
  }, [pricesQuery.data]);

  const handleRecurringInterval = (e, buttonPressed) => {
    switch (buttonPressed) {
      case 'month':
        const yearly = document.getElementById(
          'lawp-subscription-process-modal-year-button'
        );
        const badge = document.getElementById(
          'lawp-subscription-process-modal-year-button-badge'
        );
        if (yearly.classList.contains(styles['horizontal-tabs-year-active'])) {
          yearly.classList.remove(styles['horizontal-tabs-year-active']);
          badge.classList.remove(styles['horizontal-tabs-year-badge-active']);
        }
        if (
          !e.currentTarget.classList.contains(
            styles['horizontal-tabs-month-active']
          )
        ) {
          e.currentTarget.classList.add(styles['horizontal-tabs-month-active']);
          setRecurringInterval('month');
        }
        break;
      case 'year':
        const monthly = document.getElementById(
          'lawp-subscription-process-modal-month-button'
        );
        const badge2 = document.getElementById(
          'lawp-subscription-process-modal-year-button-badge'
        );
        if (
          !e.currentTarget.classList.contains(
            styles['horizontal-tabs-year-active']
          )
        ) {
          if (
            monthly.classList.contains(styles['horizontal-tabs-month-active'])
          ) {
            monthly.classList.remove(styles['horizontal-tabs-month-active']);
          }
          e.currentTarget.classList.add(styles['horizontal-tabs-year-active']);

          badge2.classList.add(styles['horizontal-tabs-year-badge-active']);
          setRecurringInterval('year');
        }
        break;

      default:
        break;
    }
  };

  const handleCreatePaymentSession = async (payment_id) => {
    // return;
    try {
      const CreatePaymentSessionData = await createPaymentSession({
        variables: {
          input: {
            payment_id: payment_id,
            email: props.clientInfoRedux.email,
            type: 'normal',
          },
        },
      });

      window.location.href =
        CreatePaymentSessionData.data.createPaymentSession.session_url;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className={styles['gold-container']}
      style={{
        display: 'block',
        width: '100%',
      }}
    >
      <Box className={styles['box']}>
        <div className={styles['header-horizontal-tabs']}>
          {/* month button */}
          <button
            id="lawp-subscription-process-modal-month-button"
            className={styles['horizontal-tabs-month']}
            onClick={(e) => handleRecurringInterval(e, 'month')}
          >
            {monthly}
          </button>
          {/* yearly button */}
          <div
            id="lawp-subscription-process-modal-year-button"
            className={`${styles['horizontal-tabs-year']} ${styles['horizontal-tabs-year-active']}`}
            onClick={(e) => handleRecurringInterval(e, 'year')}
          >
            <div className={styles['horizontal-tabs-year-text']}>{yearly}</div>
            <div
              id="lawp-subscription-process-modal-year-button-badge"
              className={`${styles['horizontal-tabs-year-badge']} ${styles['horizontal-tabs-year-badge-active']}`}
            >
              -{percentage}
            </div>
          </div>
        </div>
        {DbPrices?.length > 0 ? (
          <>
            {DbPrices.map(
              (price, index) =>
                price.stripe_product.tier === 'Gold' &&
                price.recurring_interval === recurringInterval && (
                  <React.Fragment key={index}>
                    <div className={styles['arrow-container']}>
                      <p className={styles['arrow-container-text']}>
                        {LPTranslate('LSSC_Card_Arrow')}
                      </p>
                      <img
                        src={featuresSectionPurpleArrow}
                        alt="arrow-container-icon"
                        className={styles['arrow-container-icon']}
                      />
                    </div>
                    <div className={styles['container']}>
                      <div className={styles['container-top']}>
                        <p className={styles['next-level']}>
                          {LPTranslate('LSSC_Card_Title')}
                        </p>
                        <img
                          src={lightningIconCircle}
                          alt="lightcircle"
                          className={styles['lightning-icon-circle']}
                        />
                        <p className={styles['price-text']}>
                          €
                          {price.recurring_interval === 'month'
                            ? price.unit_amount / 100
                            : price.unit_amount / 100 / 12}
                          {' / '} {LPTranslate('RS_Month_Ago')}
                        </p>
                        <div className={styles['under-price-text']}>
                          {
                            subscriptionPageTextVariables['Gold']
                              .priceDescription_1
                          }
                          {price.unit_amount / 100}€
                          {
                            subscriptionPageTextVariables['Gold']
                              .priceDescription_2
                          }
                        </div>
                        <p className={styles['subscription-type-text']}>
                          {LPTranslate('LSSC_Card_Gold_Text')}
                        </p>
                      </div>
                      <div className={styles['container-bottom']}>
                        {price.stripe_product.tier === 'Gold' &&
                          subscriptionTextVariables.Benefietsgold.map(
                            (row, index) => (
                              <div
                                key={index}
                                className={
                                  styles['card-content-check-items-text']
                                }
                              >
                                <div
                                  className={
                                    styles[
                                      'card-content-check-items-text-check'
                                    ]
                                  }
                                >
                                  <div
                                    className={
                                      styles[
                                        'card-content-check-items-text-checkIcon'
                                      ]
                                    }
                                  >
                                    <img
                                      src={checkCircleSmall}
                                      alt="purple check"
                                    />
                                  </div>
                                </div>
                                <div
                                  className={
                                    styles['card-content-check-items-text-wrap']
                                  }
                                >
                                  <div
                                    className={
                                      styles[
                                        'card-content-check-items-text-wrapText'
                                      ]
                                    }
                                  >
                                    {row}
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        <div>
                          <div className={styles['title-container']}>
                            <span className={styles['title-text']}>
                              {LPTranslate('LSSC_Card_Unlock_Features')} 🎉
                            </span>
                          </div>

                          <div className={styles['content-container']}>
                            <span className={styles['content-text']}>
                              {LPTranslate('LSSC_Card_Unlock_Features_2')}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles['button-container']}>
                      <Button
                        className={styles['save-button']}
                        startIcon={
                          <img
                            src={lightningIcon}
                            alt="icon"
                            className={styles['button-icon']}
                          />
                        }
                        // onClick={props.onSaveChanges}
                        onClick={() => {
                          handleCreatePaymentSession(price.price_id);
                        }}
                      >
                        {LPTranslate('LSSC_Card_Upgrade_Button')}
                      </Button>
                    </div>
                  </React.Fragment>
                )
            )}
          </>
        ) : (
          <div></div>
        )}
      </Box>
    </div>
  );
}
