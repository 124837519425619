import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { createUrlSlug } from 'Services/Utils/metaData';
import ArrowUpRight from 'Assets/pictures/arrow-up-right.svg';
import UserPlaceholderImage from 'Assets/pictures/userPlaceholderImage.png';

import 'Assets/styles/help/LawyerCardPreview.scss';

const LawyerCardPreview = (props) => {
  const { lawyer } = props;

  const [avatar, setAvatar] = useState(UserPlaceholderImage);

  const navigate = useNavigate();

  useEffect(() => {
    if (lawyer.profileImage) {
      setAvatar(lawyer.profileImage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="lawp-lawyer-card-preview-container"
      data-cy="lawyer-card"
      onClick={() => {
        let slug = createUrlSlug('', lawyer.first_name, lawyer.last_name, '');

        slug += `-${lawyer.id}`;
        navigate(`/anwaelte/${slug}`);
      }}
    >
      <div className="lawyer-card">
        <div className="top">
          <div className="lawyer-image-container">
            <img src={avatar} alt="avatar" className="lawyer-image" />
          </div>

          <h2 className="name-text">
            {' '}
            {lawyer.first_name + ' ' + lawyer.last_name}{' '}
          </h2>

          <h3 className="specialist-text">{lawyer.specialist_attourneys}</h3>

          <h3 className="description-text">{lawyer.short_description}</h3>
        </div>
        <div className="bottom">
          <h3
            className="profile-text"
            onClick={() => {
              let slug = createUrlSlug(
                '',
                lawyer.first_name,
                lawyer.last_name,
                ''
              );

              slug += `-${lawyer.id}`;
              navigate(`/anwaelte/${slug}`);
            }}
          >
            Zum Profil{' '}
            <img src={ArrowUpRight} alt="arrow" className="arrow-image" />
          </h3>
        </div>
      </div>
    </div>
  );
};

export default LawyerCardPreview;
