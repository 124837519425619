import React, { useState, useEffect } from 'react';

import LawyerReviewImage from 'Components/Lawyer/LawyerReviewImage.js';

import ReviewCardMenu from 'Components/Lawyer/ReviewCardMenu.js';
import RatingStars from 'Components/RatingStars/RatingStars';

import 'Assets/styles/lawyer/ReviewCard.scss';

const ReviewCard = (props) => {
  const { review, onUpdate } = props;

  const [articleImage, setArticleImage] = useState(null);

  useEffect(() => {
    if (review.lawyer.profile_image) {
      setArticleImage(review.lawyer.profile_image);
    }
  }, [review.lawyer.profile_image]);

  const onUpdatePress = () => {
    props.setLawyerId(review.lawyer_id);
    onUpdate(review);
  };

  const onDeletePress = async () => {
    props.onDelete(review);
  };

  return (
    <>
      <div className="lawp-review-card-container">
        <div className="lawp-review-card-center-content-container">
          <div className="lawp-review-card-menu-container">
            <ReviewCardMenu
              review={review}
              lawyerImage={review.lawyer.profile_image}
              lawyerID={review.lawyer.id}
              onUpdatePress={() => onUpdatePress()}
              onDeletePress={() => onDeletePress()}
            />
          </div>

          <div
            className={
              articleImage !== null
                ? 'lawp-review-card-image-container'
                : 'lawp-review-card-image-container-placeholder'
            }
          >
            <LawyerReviewImage image={articleImage} />
          </div>
          {review.lawyer.specialities.length > 0 && (
            <p className="lawp-review-card-lawyer-specialization">
              {review.lawyer.specialities.map((speciality, index) => {
                if (index === 0) {
                  return <>{`Fachanwalt für ${speciality}`}</>;
                } else {
                  return <>{`, ${speciality}`}</>;
                }
              })}
            </p>
          )}
          <div className="lawp-review-card-lawyer-name-text">
            {review.lawyer.title && review.lawyer.title + ' '}
            {review.lawyer.first_name + ' ' + review.lawyer.last_name}
            {review.lawyer.title_suffix && ', ' + review.lawyer.title_suffix}
          </div>

          <div className="lawp-review-card-rating-container">
            <RatingStars
              value={review.rating}
              width={20}
              height={20}
              readonly={true}
              colorFilter={
                'invert(91%) sepia(69%) saturate(1377%) hue-rotate(317deg) brightness(99%) contrast(102%)'
              }
            />

            {/* <Box className="lawp-review-card-rating-reviews">
              {`${milisecondsToTimeAgo(review.createdAt)}`}
            </Box> */}
          </div>
        </div>

        <div className="lawp-review-card-lawyer-description">
          <p className="lawp-review-card-lawyer-description-text">
            {review.content}
          </p>
        </div>
      </div>
    </>
  );
};

export default ReviewCard;
