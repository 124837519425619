import React from 'react';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/lawyer/DeleteComponentsStyle/DeleteModalForGreatTier.module.scss';

const DeleteModalForGreatTier = (props) => {
  let { subscription, openModal, handleClose } = props;

  return (
    <>
      <Modal
        className={styles['delete-modal']}
        open={openModal}
        onClose={handleClose}
      >
        <Box className={styles['delete-modal-container']}>
          <div className={styles['delete-modal-content']}>
            <div className={styles['delete-modal-text-and-supporting-text']}>
              <h2 className={styles['delete-modal-text']}>
                {LPTranslate('DM_Title')}
              </h2>
              <h3 className={styles['delete-modal-supporting-text']}>
                {LPTranslate('DM_Description_1')} {subscription}
                {LPTranslate('DM_Description_2')}
              </h3>
            </div>
          </div>

          <div className={styles['delete-modal-actions']}>
            <button
              data-cy={'schedule-modal-send'}
              className={styles['delete-modal-success-button']}
              onClick={handleClose}
            >
              {LPTranslate('DM_Confirm_Button')}
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteModalForGreatTier;
