import React, { useState } from 'react';

import Avatar from '@mui/material/Avatar';

import { milisecondsToDDMMYYYY } from 'Services/Utils/moment';

import RatingStars from 'Components/RatingStars/RatingStars';
import ReviewCardPublicFullInfoModal from 'Components/Modals/ReviewCardPublicFullInfoModal';

import 'Assets/styles/lawyer/ReviewCardPublic.scss';

const ReviewCardPublic = (props) => {
  const { review, dragMovement } = props;
  const [showFullInfoModal, setShowFullInfoModal] = useState(false);

  const addDotsAfterEachLetterInAString = (string) => {
    let stringWithDots = '';
    for (let i = 0; i < string.length; i++) {
      stringWithDots += string[i];
      stringWithDots += '.';
    }
    return stringWithDots;
  };

  const handleOpenModal = () => {
    if (dragMovement === 0 || props.noDrag) {
      setShowFullInfoModal(true);
      props.handleIsModalOpened(true);
    }
  };

  return (
    <>
      <ReviewCardPublicFullInfoModal
        show={showFullInfoModal}
        onClose={(e) => {
          setShowFullInfoModal(false);
          props.handleIsModalOpened(false);
          e.stopPropagation();
        }}
        clientName={addDotsAfterEachLetterInAString(review.client.name)}
        lawyerImage={props.lawyerImage ? props.lawyerImage : null}
        review={review}
      />

      <div
        style={{ userSelect: 'none' }}
        className="lawp-review-card-public-container"
        onClick={handleOpenModal}
        data-cy={'review-card'}
      >
        <div className="lawp-review-card-public-center-content-container">
          <h2 className="lawp-review-card-public-review-title">
            {review.title}
          </h2>

          <div
            id={`contentContainer${props.index}`}
            className="lawp-review-card-public-lawyer-description"
          >
            <h3
              id={`content${props.index}`}
              className="lawp-review-card-public-lawyer-description-text"
              style={{
                WebkitLineClamp: review.response ? 5 : 10,
              }}
            >
              {review.content}
            </h3>

            {review.response && (
              <div className="lawp-reviews-card-public-lawyer-response-container">
                <p className="review-content">{review.response}</p>
              </div>
            )}
          </div>
        </div>

        <div className="lawp-review-card-public-rating-container">
          <RatingStars
            value={Number(review.rating)}
            width={20}
            height={20}
            readonly={true}
            colorFilter={
              'invert(91%) sepia(69%) saturate(1377%) hue-rotate(317deg) brightness(99%) contrast(102%)'
            }
          />
          {/* <h3 className="lawp-review-card-public-rating-text">
            {Number(review.rating)}
          </h3> */}

          {/* <h3 className="lawp-review-card-public-rating-reviews">
            {`${milisecondsToTimeAgo(review.createdAt)}`}
          </h3> */}
        </div>

        <div className="lawp-review-card-public-article-author">
          <Avatar
            className="lawp-review-card-public-article-author-image"
            sx={{ backgroundColor: '#F9F5FF', color: '#7F56D9' }}
          >
            {review.client.name}
          </Avatar>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h3 className="lawp-review-card-public-author-name">
              {addDotsAfterEachLetterInAString(review.client.name)}
            </h3>
            <h3 className="lawp-review-card-public-review-date">
              {`${milisecondsToDDMMYYYY(review.createdAt)}`}
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewCardPublic;
