import React from 'react';

import Box from '@mui/material/Box';

import SimpleTextField from 'Components/Inputs/SimpleTextField';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import avatar from 'Assets/pictures/AvatarProfile.png';

import styles from 'Assets/styles/lawyer/SendMessageToLawyerModal.module.scss';

const SendMessageToLawyerLoggedBox = (props) => {
  let {
    lawyer,
    articleImage,
    formValues,
    formErrors,

    errorMessage,
  } = props;

  return (
    <>
      <Box className={styles['send-message-to-lawyer-modal-container']}>
        <form
          className={styles['send-message-to-lawyer-modal-content']}
          id={'send-lawyer-message-form'}
          onSubmit={props.sendMessage}
        >
          <div
            className={
              styles['send-message-to-lawyer-modal-avatar-label-group']
            }
          >
            {articleImage !== null ? (
              <img
                alt="articleImage"
                src={articleImage}
                className={styles['send-message-to-lawyer-modal-profile-image']}
              />
            ) : (
              <img
                alt="avatar"
                src={avatar}
                className={styles['send-message-to-lawyer-modal-profile-image']}
              />
            )}
            <div
              className={styles['send-message-to-lawyer-modal-name-and-email']}
            >
              <h3 className={styles['send-message-to-lawyer-modal-name']}>
                {lawyer.first_name} {lawyer.last_name}
              </h3>

              <h3 className={styles['send-message-to-lawyer-modal-email']}>
                {lawyer.email}
              </h3>
            </div>
          </div>

          {lawyer.subscription > 0 ? (
            <>
              <div
                className={
                  styles[
                    'send-message-to-lawyer-modal-text-and-supporting-text'
                  ]
                }
              >
                <h2 className={styles['send-message-to-lawyer-modal-text']}>
                  {LPTranslate('SM_Title')} {lawyer.first_name}{' '}
                  {lawyer.last_name}
                </h2>
                <h3
                  className={
                    styles['send-message-to-lawyer-modal-supporting-text']
                  }
                >
                  {LPTranslate('USM_Description_1')}{' '}
                  {lawyer.first_name + ' ' + lawyer.last_name}
                  {LPTranslate('USM_Description_2')}.
                </h3>
              </div>

              <div
                className={
                  styles['send-message-to-lawyer-modal-textarea-input-field']
                }
              >
                <SimpleTextField
                  data_cy="description_input"
                  title={LPTranslate('Common_Message')}
                  value={formValues.description}
                  onChange={(value) => {
                    props.handleInputChange(value);
                    props.setFormErrors({
                      ...formErrors,
                      description: '',
                    });
                  }}
                  textFieldWidthPercent={100}
                  multiline={true}
                  rows={2.5}
                  type="text"
                  placeholder={LPTranslate('CSA_Message_Placeholder')}
                  errorMessage={formErrors.description}
                />
              </div>
              <div
                className={
                  styles['send-message-to-lawyer-modal-textarea-input-hint']
                }
              >
                {LPTranslate('CSA_Message_Hint')}
              </div>

              {errorMessage && (
                <p className={styles['send-message-error-message']}>
                  {errorMessage}
                </p>
              )}
            </>
          ) : (
            <div
              className={
                styles['send-message-to-lawyer-modal-textarea-input-hint']
              }
            >
              {LPTranslate('CSA_Message_Subscription_0_1').replace(
                '<lawyer>',
                lawyer.first_name + ' ' + lawyer.last_name
              )}
            </div>
          )}
        </form>
        {lawyer.subscription > 0 && (
          <div className={styles['send-message-to-lawyer-modal-actions']}>
            <button
              data-cy="cancel-button"
              className={styles['send-message-to-lawyer-modal-cancel-button']}
              onClick={props.handleClose}
            >
              {LPTranslate('Button_Cancel')}
            </button>
            <button
              data-cy="submit-button"
              className={styles['send-message-to-lawyer-modal-success-button']}
              type="submit"
              form="send-lawyer-message-form"
            >
              {LPTranslate('LPP_Header_Send_Message')}
            </button>
          </div>
        )}

        {lawyer.subscription === 0 && (
          <div className={styles['send-message-to-lawyer-modal-actions']}>
            <button
              data-cy="cancel-button"
              className={styles['send-message-to-lawyer-modal-success-button']}
              onClick={props.handleClose}
              style={{ width: '100%' }}
            >
              Ok
            </button>
          </div>
        )}
      </Box>
    </>
  );
};

export default SendMessageToLawyerLoggedBox;
