import React from 'react';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import check from 'Assets/pictures/checkCircle.svg';

import styles from 'Assets/styles/lawyer/ConfirmAddReviewLawyer.module.scss';

const ConfirmAddReviewLawyerModal = (props) => {
  return (
    <div className={styles['confirm-review-to-lawyer-modal-container']}>
      <div className={styles['confirm-review-to-lawyer-modal-featured-icon']}>
        <img src={check} alt="key" />
      </div>
      <div
        className={
          styles['confirm-review-to-lawyer-modal-text-and-supporting-text']
        }
      >
        <h1 className={styles['confirm-review-to-lawyer-modal-text']}>
          {LPTranslate('ARM_Succes_Title')}
        </h1>
        <h3
          className={styles['confirm-review-to-lawyer-modal-supporting-text']}
        >
          {LPTranslate('ARM_Succes_Description')}
          {props.goToRegister
            ? ' Sie können die Registrierung fortsetzen, indem Sie auf die Schaltfläche "Weiter registrieren" drücken. '
            : ''}
        </h3>
      </div>

      <div className={styles['confirm-review-to-lawyer-modal-actions']}>
        <button
          data-cy="confirm-button"
          className={styles['confirm-review-to-lawyer-modal-success-button']}
          onClick={props.handleConfirm}
        >
          {props.goToRegister
            ? LPTranslate('FUP_Header_Action_button_3')
            : LPTranslate('ARM_Succes_Action_Button_Accept')}
        </button>
      </div>
    </div>
  );
};
export default ConfirmAddReviewLawyerModal;
