import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import AutocompleteAdress from 'Components/RegisterClient/AutocompleteAdress';
// import PhoneInputField from 'Components/Inputs/PhoneInputField';
import SimpleTextField from 'Components/Inputs/SimpleTextField';

import { SET_DETAIlS_ON_REGISTER } from 'Services/Queries/client';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/registerClient/setClientDetailsOnRegister.module.scss';
import anwadoLogo from 'Assets/pictures/logomark.svg';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SetClientDetailsOnRegister = (props) => {
  const [setClientDetailsOnRegister, { data: setClientDetailsOnRegisterData }] =
    useMutation(SET_DETAIlS_ON_REGISTER);

  const [formErrors, setFormErrors] = useState({
    phoneNumber: '',
  });
  const [AddressValue, setAddressValue] = useState(
    props.completeProfileInformation.address
  );
  const [HouseNumberValue, setHouseNumberValue] = useState(
    props.completeProfileInformation.nr
  );
  const [ZipCodeValue, setZipCodeValue] = useState(
    props.completeProfileInformation.postalCode
  );
  const [CityValue, setCityValue] = useState(
    props.completeProfileInformation.city
  );
  const [phoneNumber, setPhoneNumber] = useState(
    props.completeProfileInformation.phoneNumber
  );

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    props.setCompleteProfileInformation({
      address: AddressValue,
      nr: HouseNumberValue,
      postalCode: ZipCodeValue,
      city: CityValue,
      phoneNumber: phoneNumber,
    });

    let isError = false;
    let newInputErrors = {};

    if (AddressValue.length === 0) {
      isError = true;
      newInputErrors.address = LPTranslate('Error_Required_Field');
    }

    if (HouseNumberValue.length === 0) {
      isError = true;
      newInputErrors.nr = LPTranslate('Error_Required_Field');
    }

    if (ZipCodeValue.length === 0) {
      isError = true;
      newInputErrors.postalCode = LPTranslate('Error_Required_Field');
    }

    if (CityValue.length === 0) {
      isError = true;
      newInputErrors.city = LPTranslate('Error_Required_Field');
    }

    if (phoneNumber.length === 0) {
      isError = true;
      newInputErrors.phoneNumber = LPTranslate('Error_Required_Field');
    }

    console.log('ok?');
    console.log('isError', isError);

    setFormErrors(newInputErrors);

    if (isError) return;

    try {
      await setClientDetailsOnRegister({
        variables: {
          input: {
            email: props.email,
            address: AddressValue,
            house_number: HouseNumberValue,
            zipCode: ZipCodeValue,
            city: CityValue,
            phoneNumber: phoneNumber,
          },
        },
      });
    } catch (error) {
      setSnackbarMessage(LPTranslate('Error_Server_Down'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    try {
      if (setClientDetailsOnRegisterData) {
        if (setClientDetailsOnRegisterData.setClientDetailsOnRegister.message) {
          props.handleNext(4);
        }
      }
    } catch (e) {
      setSnackbarMessage(LPTranslate('Error_Server_Down'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setClientDetailsOnRegisterData]);

  const onChangeAddressValue = (value) => {
    setAddressValue(value);
  };

  const onChangeZipCode = (value) => {
    setZipCodeValue(value);
  };

  const onChangeCity = (value) => {
    setCityValue(value);
  };

  const onChangeTelephone = (value) => {
    setPhoneNumber(value);
  };

  return (
    <>
      <div className={styles.emailCheckerSection}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={
              snackbarSeverity === 'error' && {
                backgroundColor: '#7f56d9 !important',
              }
            }
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <div className={styles.emailCheckerContainer}>
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.textAndSupportingText}>
                <img
                  className={styles.logomark}
                  src={anwadoLogo}
                  alt="logomark"
                />
                <p className={styles.text}>{LPTranslate('RC_Page_4_Title')}</p>

                <p className={styles.supportingText}>
                  {LPTranslate('RC_Page_4_Description')}
                </p>
              </div>
            </div>

            <form
              className={styles.formContent}
              id="setPassword-form"
              onSubmit={handleSubmit}
            >
              <div
                style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}
                className={styles['address-input']}
              >
                <div style={{ width: '70%' }} className={styles.inputWithLabel}>
                  <AutocompleteAdress
                    addressValue={AddressValue}
                    textFieldWidthPercent={100}
                    onChangeAddressValue={onChangeAddressValue}
                    onChangeZipCode={onChangeZipCode}
                    onChangeCity={onChangeCity}
                    isSettingAppointment={false}
                    title={`${LPTranslate('Input_Address')}*`}
                    errorMessage={formErrors.address}
                  />
                </div>

                <div
                  style={{ width: '24%', marginRight: '20px' }}
                  className={styles.inputWithLabel}
                >
                  <label
                    style={{ width: '100%' }}
                    className={styles.passwordLabel}
                  >
                    Nr.*
                  </label>
                  <SimpleTextField
                    style={{ width: '100%' }}
                    data_cy="building-nr-input"
                    className={styles.setPasswordInput}
                    name="HouseNumberValue"
                    type="text"
                    placeholder="Nr."
                    maxLength={10}
                    hideHowManyCharactersLeft={true}
                    value={HouseNumberValue}
                    onChange={(value) => setHouseNumberValue(value)}
                    errorMessage={formErrors.nr}
                  />
                </div>
              </div>

              <div className={styles['zip-code-input']}>
                <div className={styles.inputWithLabel}>
                  <label className={styles.passwordLabel}>
                    {LPTranslate('Input_Postcode')}*
                  </label>

                  <SimpleTextField
                    className={styles.setPasswordInput}
                    data_cy="zip-code-input"
                    name="ZipCodeValue"
                    type="text"
                    placeholder={LPTranslate('Input_Postcode_Placeholder')}
                    value={ZipCodeValue}
                    onChange={(value) => setZipCodeValue(value)}
                    errorMessage={formErrors.postalCode}
                  />
                </div>
              </div>

              <div className={styles['city-input']}>
                <div className={styles.inputWithLabel}>
                  <label className={styles.passwordLabel}>
                    {LPTranslate('Input_City')}*
                  </label>

                  <SimpleTextField
                    data_cy="city-input"
                    className={styles.setPasswordInput}
                    name="CityValue"
                    type="text"
                    placeholder={LPTranslate('Input_City_Placeholder')}
                    value={CityValue}
                    onChange={(value) => setCityValue(value)}
                    errorMessage={formErrors.city}
                  />
                </div>
              </div>

              <div className={styles['phone-number-input']}>
                <div className={styles.inputWithLabel}>
                  {/* <PhoneInputField
                    specialLabel=""
                    country="de"
                    regions="europe"
                    autoFocus={true}
                    onChangeHandler={(e) => onChangeTelephone(e)}
                    value={phoneNumber}
                    phoneNumber={phoneNumber}
                    title={`${LPTranslate('Input_Telephone')}*`}
                    name="PhoneNumber"
                    placeholder="+49 123 4567890"
                    popupIcon={<KeyboardArrowDownIcon />}
                    req={true}
                    width="100%"
                    helperText=""
                    error={true}
                    isSelect={false}
                    errorMessage={formErrors.phoneNumber}
                  /> */}

                  <SimpleTextField
                    value={phoneNumber}
                    placeholder="+49 123 4567890"
                    onChange={(value) => onChangeTelephone(value)}
                    title={`${LPTranslate('Input_Telephone')}*`}
                    errorMessage={formErrors.phoneNumber}
                  />
                </div>
              </div>

              <div className={styles.setPasswordButtonContainer}>
                <button
                  data-cy="submit-button"
                  className={styles.setPasswordButtonBase}
                  type="submit"
                  form="setPassword-form"
                >
                  <p className={styles.setPasswordButtonText}>
                    {LPTranslate('Button_Next')}
                  </p>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetClientDetailsOnRegister;
