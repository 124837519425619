import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';

import ActualPlan from './ActualPlan';
import PaymentMethod from './PaymentMethod';

import CircularProgress from '@mui/material/CircularProgress';

import styles from 'Assets/styles/subscription/paymentPlan.module.scss';

import {
  GET_LAWYER_SUBSCRIPTION_INFORMATION,
  REDIRECT_CUSTOMER_PORTAL,
} from 'Services/Queries/stripe';
import { REFRESH_TOKEN } from 'Services/Queries/user';
import { parseJwt } from 'Services/Utils/token';
import { addAll } from 'Services/Redux/reducers/clientInfo';
import { removeTokenInfo } from 'Services/Utils/token';

import { useNavigate } from 'react-router-dom';

const SubscriptionProcess = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const dispatch = useDispatch();

  const [refreshToken] = useMutation(REFRESH_TOKEN, {
    fetchPolicy: 'network-only',
  });

  const lawyerSubscriptionInformation = useQuery(
    GET_LAWYER_SUBSCRIPTION_INFORMATION,
    {
      variables: { lawyer_id: clientInfoRedux.role_id },
    }
  );
  const [redirectCustomerPortal] = useMutation(REDIRECT_CUSTOMER_PORTAL);

  const [subscriptionInformation, setSubscriptionInformation] = useState({
    subscriptionName: 'Silver',
    subscriptionPrice: 0,
    subscriptionRecurringInterval: 'month',
  });

  const [paymentMethodInformation, setPaymentMethodInformation] = useState({
    card_brand: '',
    card_country: '',
    card_email: '',
    card_exp_month: '',
    card_exp_year: '',
    card_last4: '',
    card_name: '',
  });

  const handleRefreshToken = async (token, refresh_token) => {
    try {
      if (token && refresh_token) {
        const response = await refreshToken({
          variables: {
            input: {
              token: token,
              refresh_token: refresh_token,
            },
          },
        });

        if (
          response.data.refreshToken &&
          response.data.refreshToken.status === '200'
        ) {
          let jwtToken = response.data.refreshToken.jwtToken;
          let refresh_token = response.data.refreshToken.refresh_token;

          const token_decoded = parseJwt(jwtToken);

          console.log('new token:', jwtToken);

          let tokenInfo = {
            token: jwtToken,
            refresh_token: refresh_token,
            role: token_decoded.role,
            email: token_decoded.email,
            name: token_decoded.username,
            first_name: token_decoded.first_name,
            title: token_decoded.title,
            last_name: token_decoded.last_name,
            user_id: parseInt(token_decoded.user_id),
            role_id: parseInt(token_decoded.role_id),
          };

          removeTokenInfo();
          localStorage.setItem('anwadoTokenInfo', JSON.stringify(tokenInfo));

          dispatch(addAll(tokenInfo));
        } else {
          removeTokenInfo();

          window.location.href = '/login';
        }
      }
    } catch (error) {
      console.log('Router error', error);
    }
  };

  useEffect(() => {
    if (lawyerSubscriptionInformation.data) {
      setIsLoading(false);
      const data =
        lawyerSubscriptionInformation.data.lawyerSubscriptionInformation;
      if (!data) return;
      setPaymentMethodInformation({
        card_brand: data.card_brand,
        card_country: data.card_country,
        card_email: data.card_email,
        card_exp_month: data.card_exp_month,
        card_exp_year: data.card_exp_year,
        card_last4: data.card_last4,
        card_name: data.card_name,
      });
      setSubscriptionInformation({
        subscriptionName: data.subscription_name,
        subscriptionPrice: data.subscription_price,
        subscriptionRecurringInterval: data.recurring_interval,
      });
    }
  }, [lawyerSubscriptionInformation.data]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('success') === 'true') {
      handleRefreshToken(clientInfoRedux.token, clientInfoRedux.refresh_token);
      for (let i = 1; i < 5; i++) {
        setTimeout(() => {
          handleRefreshToken(
            clientInfoRedux.token,
            clientInfoRedux.refresh_token
          );
        }, 3000 * i);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();
  const handleOpen = () => navigate('/subscriptionPage');

  const handleRedirect = async () => {
    try {
      let redirectCustomer = await redirectCustomerPortal({
        variables: {
          input: {
            lawyer_id: clientInfoRedux.role_id,
          },
        },
      });
      if (redirectCustomer.data) {
        window.location.href =
          redirectCustomer.data.redirectCustomerPortal.session_url;
      }
    } catch (error) {}
  };

  if (isLoading) {
    return (
      <>
        <div
          style={{
            width: '384px',
            height: '500px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '200px',
            marginBottom: '200px',
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles['payment-section']}>
        <div className={styles['actual-plan-container']}>
          <ActualPlan
            subscriptionInformation={subscriptionInformation}
            handleOpen={handleOpen}
          />
          {paymentMethodInformation.card_brand.length > 0 && (
            <PaymentMethod
              paymentMethodInformation={paymentMethodInformation}
              handleRedirect={handleRedirect}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SubscriptionProcess;
