import React from 'react';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { useNavigate } from 'react-router-dom';

import arrowRight from 'Assets/pictures/arrow-right-red.svg';
import styles from 'Assets/styles/common/DeleteClientBox.module.scss';

const DeleteClientBox = (props) => {
  const navigate = useNavigate();

  return (
    <div className={styles['lawp-delete-account-box-container']}>
      {/* Stepper container */}
      {props.activeAppointments === 0 && (
        <div className={styles['lawp-delete-account-box-stepper-content']}>
          <div className={styles['lawp-delete-account-box-step-icon-base']}>
            <div
              className={
                styles['lawp-delete-account-box-selected-icon-content']
              }
            >
              <div className={styles['lawp-delete-account-box-selected-dot']} />
            </div>
          </div>

          <div
            className={styles['lawp-delete-account-box-unselected-line']}
          ></div>

          <div
            className={
              styles['lawp-delete-account-box-unselected-step-icon-base']
            }
          >
            <div
              className={
                styles['lawp-delete-account-box-unselected-icon-content']
              }
            >
              <div
                className={styles['lawp-delete-account-box-unselected-dot']}
              />
            </div>
          </div>
        </div>
      )}
      <div className={styles['lawp-delete-account-box-content']}>
        {/* Text container */}
        <div
          className={
            styles['lawp-delete-account-box-content-text-and-support-container']
          }
        >
          <div
            className={styles['lawp-delete-account-box-content-text-container']}
          >
            {props.activeAppointments === 0
              ? LPTranslate('CDAM_Important_Title')
              : LPTranslate('DA_Cannot_Delete_Account')}
          </div>
          {/* <div
            className={
              styles['lawp-delete-account-box-content-support-container']
            }
          >
            {LPTranslate('CDAM_Important_Label')}
          </div> */}
        </div>
        {/* Alert container */}
        <div
          className={styles['lawp-delete-account-box-content-alert-container']}
        >
          <div
            className={styles['lawp-delete-account-box-content-alert-content']}
          >
            <div
              className={
                styles[
                  'lawp-delete-account-box-content-alert-content-badge-group'
                ]
              }
            >
              <div
                className={
                  styles[
                    'lawp-delete-account-box-content-alert-content-badge-container'
                  ]
                }
              >
                <div
                  className={
                    styles[
                      'lawp-delete-account-box-content-alert-content-badge'
                    ]
                  }
                >
                  {LPTranslate('CDAM_Step_1_Info_Badge')}
                </div>
              </div>
              <div
                className={
                  styles[
                    'lawp-delete-account-box-content-alert-content-badge-message'
                  ]
                }
              >
                {LPTranslate('CDAM_Step_1_Info_Badge_Description')}
              </div>
            </div>
            <div
              className={
                styles['lawp-delete-account-box-content-alert-content-support']
              }
            >
              {props.activeAppointments === 0
                ? LPTranslate('CDAM_Step_1_Info_Description')
                : LPTranslate('DA_Appointments_Still_Exist')}
            </div>
          </div>
          {props.activeAppointments !== 0 && (
            <div
              className={
                styles['lawp-delete-account-box-content-alert-actions']
              }
            >
              <div
                onClick={() => {
                  navigate('/eventSettings');
                }}
                className={
                  styles[
                    'lawp-delete-account-box-content-alert-button-container'
                  ]
                }
              >
                <div
                  className={
                    styles['lawp-delete-account-box-content-alert-button']
                  }
                >
                  <div
                    className={
                      styles[
                        'lawp-delete-account-box-content-alert-button-text'
                      ]
                    }
                  >
                    {LPTranslate('CDAM_Step_1_Info_Action_Button')}
                  </div>
                  <div
                    className={
                      styles[
                        'lawp-delete-account-box-content-alert-button-arrow'
                      ]
                    }
                  >
                    <img src={arrowRight} alt="arrowRight"></img>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Actions container */}
        <div
          className={
            styles['lawp-delete-account-box-content-actions-container']
          }
        >
          <div
            data-cy="cancel-button"
            onClick={props.handleClose}
            className={
              styles[
                'lawp-delete-account-box-content-actions-cancel-button-container'
              ]
            }
          >
            <div
              className={
                styles['lawp-delete-account-box-content-actions-cancel-button']
              }
            >
              {props.activeAppointments === 0
                ? LPTranslate('Button_Cancel')
                : LPTranslate('DM_Confirm_Button')}
            </div>
          </div>
          {props.activeAppointments === 0 && (
            <div
              data-cy="submit-button"
              onClick={() => {
                props.changeModal();
              }}
              className={
                styles[
                  'lawp-delete-account-box-content-actions-accept-button-container'
                ]
              }
            >
              <div
                className={
                  styles[
                    'lawp--delete-account-modal-content-actions-accept-button'
                  ]
                }
              >
                {LPTranslate('CDAM_Step_1_Action_Button_Accept')}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeleteClientBox;
