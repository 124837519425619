import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';

import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { tooltipClasses } from '@mui/material/Tooltip';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import FavoriteLawyer from 'Components/CommonComponents/FavoriteLawyer';
import RatingStars from 'Components/RatingStars/RatingStars';
import AskLawyerToSetScheduleModal from 'Components/Modals/AskLawyerToSetScheduleModal';
// import AskLawyerToSetScheduleNotLoggedInModal from 'Components/Modals/AskLawyerToSetScheduleNotLoggedInModal';
import AreasOfLawDropdown from 'Components/AreasOfLawDropdown';

import { createUrlSlug } from 'Services/Utils/metaData';
import { GET_LAWYER_AVAILABILITY } from 'Services/Queries/client';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import badgeIcon from 'Assets/pictures/badgeIcon.svg';
import UserProfileDefaultIcon from 'Assets/pictures/UserProfileDefaultIcon.svg';
import { AreasOfLaw_Color } from 'Services/Utils/areaOfLaws';
import { countryToLanguage } from 'Services/Utils/countryToLanguage.js';

import 'Assets/styles/lawyer/LawyerCard.scss';

const AnwadoTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '14px !important',
    fontFamily: 'Inter !important',
    backgroundColor: '#f9f5ff !important',
    color: '#6941c6 !important',
    padding: '10px !important',
  },
}));

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

export default function LawyerCard2({ lawyer }) {
  const {
    id,
    title,
    title_suffix,
    first_name,
    last_name,
    subscription,
    areasOfLaw,
    specialities,
    country,
    location,
    reviewNr,
    rating,
    picture,
    email,
    telephone,
  } = lawyer;

  const LawyeAvailability = useQuery(GET_LAWYER_AVAILABILITY, {
    variables: { lawyer_id: lawyer.id },
  });

  const navigate = useNavigate();
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);
  const [profileImage, setProfileImage] = useState(null);
  const [areasOfLawHasOverflow, setAreasOfLawHasOverflow] = useState(false);
  const [areasOfLawOverflowIndexStart, setAreasOfLawOverflowIndexStart] =
    useState(0);

  const parseUserId = parseInt(clientInfoRedux.user_id);
  const parseRoleId = parseInt(clientInfoRedux.role_id);
  const parseLawyerId = parseInt(id);
  const roleStr = clientInfoRedux.role;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [disabledDaysIndex, setDisabledDaysIndex] = useState(0);
  const [availability, setAvailability] = useState([]);

  let areasOfLawOverflowRef = React.createRef();

  let avatarTagJSX = (text, tooltip) => {
    return (
      // <Tooltip
      //   classes={{ popper: 'tooltip-medium-size' }}
      //   PopperProps={{
      //     disablePortal: true,
      //   }}
      //   TransitionComponent={Zoom}
      //   title={tooltip}
      //   placement="top"
      // >
      <Box className="avatarTag">
        <img src={badgeIcon} className="avatarTagIconBadge" alt="badge" />
        <Box className="avatarTagText">{text}</Box>
      </Box>
      // </Tooltip>
    );
  };

  const getFlag = (country) => {
    let flag;
    try {
      const language = countryToLanguage[country];

      flag = require(`Assets/languageFlags/${language}.svg`).default;
    } catch (e) {
      flag = null;
    }

    return flag;
  };

  const locationTagText = (country, location) => {
    if (country && location) return `${country}, ${location}`;
    if (country) return country;
    if (location) return location;
    return null;
  };

  useEffect(() => {
    if (picture) setProfileImage(picture);
  }, [picture]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const AreaOfLawTagJSX = (value, key) => {
    return (
      <div
        className="lawp-add-lawyer-review-modal-area-of-law-tag-container"
        style={{
          display: 'inline-block',
          color: AreasOfLaw_Color[value]
            ? AreasOfLaw_Color[value].color
            : 'black',
          backgroundColor: AreasOfLaw_Color[value]
            ? AreasOfLaw_Color[value].background
            : 'white',
        }}
        onClick={(e) => {
          e.stopPropagation();

          let aol = value;

          aol = aol.replace(/\s+/g, '-');

          navigate(`/aol/${aol}`);
        }}
        key={key}
      >
        <span className="lawp-add-lawyer-review-modal-area-of-law-tag-text">
          {value}
        </span>
      </div>
    );
  };

  useEffect(() => {
    if (!LawyeAvailability.loading && LawyeAvailability.data) {
      let index = 0;
      for (
        let i = 0;
        i < LawyeAvailability.data.getLawyerAvailability.length;
        i++
      ) {
        if (!LawyeAvailability.data?.getLawyerAvailability[i].enabled) index++;
      }
      setAvailability(LawyeAvailability.data.getLawyerAvailability);
      setDisabledDaysIndex(index);
    }
  }, [LawyeAvailability.data, LawyeAvailability.loading]);

  let enabledAppointmentButton = (
    availability,
    disabledDaysIndex,
    LawyerIsavailable,
    lawyerSubscription
  ) => {
    return (
      <Button
        className={
          availability.length !== 0 &&
          disabledDaysIndex < 7 &&
          LawyerIsavailable &&
          lawyerSubscription > 1
            ? 'appointment-button'
            : 'request-appointment-button'
        }
        data-cy={'appointment-button'}
        variant="contained"
        onClick={(e) => {
          if (availability.length !== 0) {
            if (
              disabledDaysIndex < 7 &&
              LawyerIsavailable &&
              lawyerSubscription > 1
            ) {
              navigate('/setAppointment', {
                state: {
                  id: lawyer.id,
                  lawyer: lawyer,
                  specialities: specialities,
                  reviewsNr: reviewNr,
                  averageNr: rating,
                  backButtonText: '',
                  availability: lawyer.lawyerAvailability,
                },
              });
            } else setOpenModal(true);
          } else {
            setOpenModal(true);
          }
          e.stopPropagation();
        }}
        endIcon={<ArrowForwardIcon />}
      >
        {disabledDaysIndex < 7 && LawyerIsavailable && lawyer.subscription > 1
          ? LPTranslate('Button_Make_Appointment')
          : LPTranslate('Button_Make_Appointment_Request')}
      </Button>
    );
  };

  let disabledAppointmentButton = (
    disabledDaysIndex,
    LawyerIsavailable,
    lawyerSubscription
  ) => {
    return (
      <Tooltip
        className="tooltip-medium-size"
        PopperProps={{
          disablePortal: true,
        }}
        title={LPTranslate('Error_Lawyer_Cannot_Create_Appointment')}
        placement="top"
        TransitionComponent={Zoom}
      >
        <button
          data-cy={'appointment-button'}
          className="button-disabled"
          variant="text"
        >
          {disabledDaysIndex < 7 && LawyerIsavailable && lawyerSubscription > 1
            ? LPTranslate('LPP_FAQ_Action_button')
            : LPTranslate('Button_Make_Appointment_Request')}
        </button>
      </Tooltip>
    );
  };

  var appointmentButton = () => {
    let LawyerIsavailable;

    if (clientInfoRedux.role === 'lawyer') {
      if (
        lawyer.lawyerAvailability.personally ||
        (lawyer.lawyerAvailability.by_phone && telephone)
      )
        LawyerIsavailable = true;
      else LawyerIsavailable = false;

      return disabledAppointmentButton(
        disabledDaysIndex,
        LawyerIsavailable,
        lawyer.subscription
      );
    } else if (
      clientInfoRedux.role === 'client' ||
      clientInfoRedux.role === ''
    ) {
      if (
        lawyer.lawyerAvailability.personally ||
        (lawyer.lawyerAvailability.by_phone && telephone)
      )
        LawyerIsavailable = true;
      else LawyerIsavailable = false;

      return enabledAppointmentButton(
        availability,
        disabledDaysIndex,
        LawyerIsavailable,
        lawyer.subscription
      );
    }
  };

  useEffect(() => {
    if (
      areasOfLawOverflowRef.current.scrollHeight >
      areasOfLawOverflowRef.current.clientHeight
    ) {
      setAreasOfLawHasOverflow(true);

      // Figure out the id of the last child on the first line. The elements inside the select
      // element(...) will display the elements starting from the id of the last child on the first line
      let initialYPos =
        areasOfLawOverflowRef.current.children[0].getBoundingClientRect().y;
      let lastChildOnFirstLineIndex = 0;

      for (let i = 1; i < areasOfLawOverflowRef.current.children.length; i++) {
        if (
          areasOfLawOverflowRef.current.children[i].getBoundingClientRect()
            .y !== initialYPos
        ) {
          lastChildOnFirstLineIndex = i;
          break;
        }
      }

      // Remove the elements that are not on the first line
      while (
        areasOfLawOverflowRef.current.children.length >
        lastChildOnFirstLineIndex
      ) {
        areasOfLawOverflowRef.current.children[
          areasOfLawOverflowRef.current.children.length - 1
        ].remove();
      }

      setAreasOfLawOverflowIndexStart(lastChildOnFirstLineIndex);
    }
  }, [areasOfLawOverflowRef]);

  const onCardClickNavigateURL = () => {
    let slug = createUrlSlug('', lawyer.first_name, lawyer.last_name, '');

    slug += `-${lawyer.id}`;
    return `/anwaelte/${slug}`;
  };

  return (
    <React.Fragment>
      <div
        className="lawp-landing-page-paper-container"
        data-cy="lawyer-card"
        onClick={() => {
          navigate(onCardClickNavigateURL());
        }}
      >
        <div className="content-container">
          <div
            className="image-container"
            style={{ backgroundColor: profileImage !== null ? '' : '#f2f4f7' }}
          >
            <Img
              className={
                profileImage !== null ? 'avatarImage' : 'defaultAvatarImage'
              }
              alt="complex"
              src={
                profileImage !== null ? profileImage : UserProfileDefaultIcon
              }
            />

            <div className="lawyerAvatarTagsContainer">
              {subscription === 3 &&
                avatarTagJSX(
                  LPTranslate('SL_Lawyer_Card_Display'),
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                )}
            </div>

            {parseUserId !== 0 && (
              <AnwadoTooltip
                className="tooltip-medium-size"
                PopperProps={{
                  disablePortal: true,
                }}
                title={LPTranslate('Common_Add_To_Favorites')}
                placement="top"
                TransitionComponent={Zoom}
              >
                <div className="image-bookmark">
                  <FavoriteLawyer
                    user_id={parseUserId}
                    lawyer_id={parseLawyerId}
                    role_id={parseRoleId}
                    role={roleStr}
                  />
                </div>
              </AnwadoTooltip>
            )}
          </div>

          <div className="info-container">
            <div className="name-and-bookmark-section">
              <div className="name-and-badge-section">
                <a
                  href={`${window.location.origin}` + onCardClickNavigateURL()}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  data-cy="lawyer-name"
                  className="lawyer-name-text"
                >
                  {title && `${title}`} {first_name} {last_name}
                  {title_suffix && `, ${title_suffix}`}
                </a>
                {subscription >= 1 && (
                  <AnwadoTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    title={
                      subscription === 1
                        ? LPTranslate('SB_Silver')
                        : [
                            subscription === 2
                              ? LPTranslate('SB_Gold')
                              : [
                                  subscription === 3 &&
                                    LPTranslate('SB_Platinum'),
                                ],
                          ]
                    }
                    placement="top"
                    TransitionComponent={Zoom}
                  >
                    <div className="subscription-container">
                      <div className="subscription-inner-container">
                        <img
                          className="subscription-logo"
                          src={badgeIcon}
                          alt="badge"
                        />

                        <p className="subscription-type">
                          {subscription === 1
                            ? 'Silver'
                            : [subscription === 2 ? 'Gold' : 'Platin']}
                        </p>
                      </div>
                    </div>
                  </AnwadoTooltip>
                )}
              </div>
              {parseUserId !== 0 && (
                <AnwadoTooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  title={LPTranslate('Common_Add_To_Favorites')}
                  placement="top"
                  TransitionComponent={Zoom}
                >
                  <div className="bookmark-container">
                    <FavoriteLawyer
                      user_id={parseUserId}
                      lawyer_id={parseLawyerId}
                      role_id={parseRoleId}
                      role={roleStr}
                    />
                  </div>
                </AnwadoTooltip>
              )}
            </div>

            <p className="specialist-attourneys-section">
              {specialities.length > 0 &&
                specialities.map((speciality, index) => {
                  if (index === 0) {
                    return (
                      <>
                        <span>{`${LPTranslate(
                          'SL_Lawyer_Card_Specialist'
                        )} `}</span>
                        <span
                          className="speciality-tag"
                          onClick={(e) => {
                            e.stopPropagation();

                            let spec = speciality;

                            spec = spec.replace(/\s+/g, '-');

                            navigate(`/fachanwalt/${spec}`);
                          }}
                          key={index}
                        >
                          {`${speciality}`}
                        </span>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <span>{`, `}</span>
                        <span
                          className="speciality-tag"
                          onClick={(e) => {
                            e.stopPropagation();

                            let spec = speciality;

                            spec = spec.replace(/\s+/g, '-');

                            navigate(`/fachanwalt/${spec}`);
                          }}
                          key={index}
                        >{`${speciality}`}</span>
                      </>
                    );
                  }
                })}
            </p>

            <div className="rating-container">
              <RatingStars
                value={Number(rating)}
                width={20}
                height={20}
                readonly={true}
                colorFilter={
                  'invert(91%) sepia(69%) saturate(1377%) hue-rotate(317deg) brightness(99%) contrast(102%)'
                }
              />
              <p className="rating-text">{rating}</p>
              <p className="rating-reviews">
                {reviewNr}{' '}
                {reviewNr === 1
                  ? LPTranslate('Common_Review')
                  : LPTranslate('Common_Reviews')}
              </p>
            </div>

            <div className="area-of-laws-container">
              <div className="area-of-law-text" ref={areasOfLawOverflowRef}>
                {areasOfLaw.map((area, index) => {
                  return AreaOfLawTagJSX(area, index);
                })}
              </div>
              {areasOfLawHasOverflow && (
                <div className="area-of-law-overflow">
                  <AreasOfLawDropdown
                    areasOfLaw={areasOfLaw}
                    areasOfLawOverflowIndexStart={areasOfLawOverflowIndexStart}
                  />
                </div>
              )}
            </div>

            <div
              className="appointment-button-container"
              style={{
                justifyContent: locationTagText(country, location)
                  ? 'space-between'
                  : 'flex-end',
              }}
            >
              {country && location && (
                <div className="location-container">
                  {getFlag(country) && (
                    <Img
                      className="country-round-flag"
                      alt="flag"
                      src={getFlag(country)}
                    />
                  )}

                  <span>
                    <span>{`${country}, `}</span>

                    <span
                      className="speciality-tag"
                      onClick={(e) => {
                        e.stopPropagation();

                        navigate(`/ort/${location}`);
                      }}
                    >
                      {`${location}`}
                    </span>
                  </span>
                </div>
              )}

              {appointmentButton()}
            </div>
          </div>
        </div>
      </div>
      <AskLawyerToSetScheduleModal
        openModal={openModal}
        handleOpenModal={() => setOpenModal(false)}
        handleCloseSnackbar={handleCloseSnackbar}
        handleSnackbarMessage={(message) => setSnackbarMessage(message)}
        handleSnackbarSeverity={(type) => setSnackbarSeverity(type)}
        handleSnackbarOpen={() => setSnackbarOpen(true)}
        snackbarSeverity={snackbarSeverity}
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        profileImage={profileImage}
        title={title}
        first_name={first_name}
        last_name={last_name}
        email={email}
        subscription={subscription}
      />
    </React.Fragment>
  );
}
