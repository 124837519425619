import React, { useEffect } from 'react';

import Button from '@mui/material/Button';

import { useLazyQuery } from '@apollo/client';

import { GET_ALL_ARTICLES } from 'Services/Queries/lawyer';
import ArticleCard from 'Components/Articles/ArticleCard';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/lawyer/LawyerPageArticleSection.scss';

const NUMBER_OF_ARTICLES_PER_PAGE = 2;

const LawyerPageArticleSection = (props) => {
  const [articles, { data }] = useLazyQuery(GET_ALL_ARTICLES);

  useEffect(() => {
    articles({
      variables: {
        input: {
          search: '',
          area_of_law: [],
          limit: NUMBER_OF_ARTICLES_PER_PAGE,
          page: 1,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data || !data.articles || data.articles.articles < 0) return null;

  return (
    <div className="lawp-lawyer-page-article-section-center-container">
      <div className="lawp-lawyer-page-article-section-container">
        <div className="lawp-lawyer-page-article-section-description-container">
          <div className="lawp-lawyer-page-article-section-public-title-container">
            {props.lawyer && props.lawyer.first_name && (
              <p className="lawp-lawyer-page-article-section-client-name">
                {`${LPTranslate('AS_Latest_From')} ${
                  props.lawyer.title ? props.lawyer.title + '.' : ''
                } ${props.lawyer.first_name} ${props.lawyer.last_name}`}
                {props.lawyer.title_suffix && `, ${props.lawyer.title_suffix}`}
              </p>
            )}

            {props.lawyer && props.lawyer.first_name === undefined && (
              <p className="lawp-lawyer-page-article-section-client-name">
                {LPTranslate('AS_Latest_Info')}
              </p>
            )}

            <h2 className="lawp-lawyer-page-article-section-review-title">
              {LPTranslate('AS_Legal_Info')}
            </h2>

            <div className="lawp-lawyer-page-article-section-lawyer-description">
              <h3 className="lawp-lawyer-page-article-section-lawyer-description-text">
                {LPTranslate('AS_Legal_Info_Description')}
              </h3>
            </div>

            <div className="lawp-lawyer-page-article-section-view-all-articles-button-container">
              <Button
                className="lawp-lawyer-page-article-section-view-all-articles-button"
                data-cy="show-all-posts"
                onClick={() => {
                  props.changeContent('articles');
                }}
              >
                <a
                  href={`${process.env.REACT_APP_DNS_URI}/rechtsinfos`}
                  style={{ color: 'white' }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {LPTranslate('LPP_Articles_Action_Button')}
                </a>
              </Button>
            </div>
          </div>
        </div>

        <div className="lawp-lawyer-page-article-section-all-article-container">
          {data &&
            data.articles.articles.map((article, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="lawp-lawyer-page-article-section-article-container">
                    <ArticleCard
                      keyIndex={index}
                      article={article}
                      previousPage={props.previousPage}
                    />
                  </div>

                  {index === data.articles.articles.length - 1 &&
                    index === 0 && (
                      <div className="lawp-lawyer-page-article-section-only-one-articles-container">
                        <p className="lawp-lawyer-page-article-section-no-articles-container-text">
                          {LPTranslate('Error_Only_One_Article_Home_Page')}
                        </p>
                      </div>
                    )}
                </React.Fragment>
              );
            })}

          {data !== undefined && data.articles.articles.length === 0 && (
            <div className="lawp-lawyer-page-article-section-no-articles-container">
              <p className="lawp-lawyer-page-article-section-no-articles-container-text">
                {' '}
                {LPTranslate('Error_No_Articles_Home_Page')}
              </p>
            </div>
          )}
        </div>

        {/* <div className='lawp-lawyer-page-article-section-article-container'></div> */}
      </div>
    </div>
  );
};

export default LawyerPageArticleSection;
