import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';

import moment from 'moment';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { CANCEL_APPOINTMENT } from 'Services/Queries/common';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import SimpleTextField from 'Components/Inputs/SimpleTextField';

import styles from 'Assets/styles/client/CancelEventModal.module.scss';
import UpdatePasswordConfirmationModal from 'Components/Modals/UpdatePasswordConfirmationModal';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CancelEventModal = (props) => {
  let {
    handleRefetch,
    data,
    role,
    appointmentDate,
    startAppointment,
    endAppointment,
  } = props;

  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const [cancelAppointment, { data: CancelAppointmentData }] =
    useMutation(CANCEL_APPOINTMENT);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
  const [snackbarMessage, setSnackbarMessage] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const [shownModal, setShownModal] = useState(0);

  const [formValues, setFormValues] = useState({
    description: '',
  });
  const [formErrors, setFormErrors] = useState({
    description: '',
  });

  const weekdaysGerman = [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
  ];

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleModal = () => {
    handleOpen();
  };

  const handleClose = () => {
    handleRefetch();
    setFormValues({ description: '' });
    setFormErrors({ description: '' });
    setShownModal(0);
    setOpen(false);
  };

  const onChangeDescriptionValue = (value) => {
    setFormValues({ ...formValues, description: value });
  };

  const handleSubmit = async () => {
    let isError = false;
    let newInputErrors = {};

    if (formValues.description.length === 0) {
      isError = true;
      newInputErrors.description = LPTranslate('Error_Description_Required');
    }

    setFormErrors(newInputErrors);

    if (isError) return;

    let name,
      date = '';

    if (clientInfoRedux.role === 'lawyer') name = data.lawyer_name;
    else if (clientInfoRedux.role === 'client') name = data.client_name;

    let dateNow = new moment();

    date = weekdaysGerman[dateNow.day()] + ' ' + dateNow.format('HH:mm');

    const appointmentDateWeekday = moment(
      moment.unix(data.date).format('LLLL')
    );

    try {
      await cancelAppointment({
        variables: {
          lawyer_id: parseInt(data.lawyer_id),
          client_id: parseInt(data.client_id),
          appointment_id: parseInt(data.id),
          appointmentHour:
            startAppointment.getHours() +
            ':' +
            String(startAppointment.getMinutes()).padStart(2, '0'),
          appointmentDate: appointmentDate,
          meetingVal:
            data.appointment_type === 'On-site meeting'
              ? 'Vor-Ort Meeting'
              : data.appointment_type,
          name: name,
          date: date,
          dayOfWeek: weekdaysGerman[appointmentDateWeekday.day()],
          description: formValues.description,
        },
      });
    } catch (error) {
      setSnackbarMessage(LPTranslate('Error_Server_Down'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    if (
      CancelAppointmentData &&
      CancelAppointmentData.cancelAppointment?.message === 'message arrived!'
    ) {
      // props.handleAppointmentID(parseInt(data.id));
      setShownModal(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CancelAppointmentData]);

  return (
    <>
      {props.menuButtonSelected === 0 ? (
        <button
          data-cy="cancel-event-button"
          className={styles['cancel-event-modal-actions-cancel-event-button']}
          onClick={handleModal}
        >
          {LPTranslate('Button_Cancel_Event')}
        </button>
      ) : (
        <></>
      )}

      <Modal
        className={styles['cancel-event-modal']}
        open={open}
        onClose={handleClose}
      >
        <Box className={styles['cancel-event-modal-container']}>
          {shownModal === 0 ? (
            <>
              <div className={styles['cancel-event-modal-content']}>
                <Snackbar
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  open={snackbarOpen}
                  autoHideDuration={2000}
                  onClose={handleCloseSnackbar}
                >
                  <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity}
                    sx={
                      snackbarSeverity === 'error' && {
                        backgroundColor: '#7f56d9 !important',
                      }
                    }
                  >
                    {snackbarMessage}
                  </Alert>
                </Snackbar>
                <div
                  className={
                    styles['cancel-event-modal-text-and-supporting-text']
                  }
                >
                  <div className={styles['cancel-event-modal-text']}>
                    {LPTranslate('LSA_Cancel_Appointment_Title')}
                  </div>

                  <div className={styles['cancel-event-modal-supporting-text']}>
                    <p className={styles['cancel-event-modal-details-text']}>
                      {LPTranslate('LSA_Appointment_Type')}
                      {data.appointment_type === 'Anruf'
                        ? LPTranslate('LSA_Update_Appointment_Call')
                        : LPTranslate('LSA_Update_Appointment_Site_Meeting')}
                    </p>
                    <p className={styles['cancel-event-modal-details-text']}>
                      {LPTranslate('CSA_Cancel_Appointment_Title_Hint')}
                      {role === 'client' ? data.lawyer_name : data.client_name}
                    </p>
                    {role === 'lawyer' && data.client && (
                      <>
                        {data?.client.telephone && (
                          <p
                            className={
                              styles['cancel-event-modal-details-text']
                            }
                          >
                            {data.client.telephone}
                          </p>
                        )}
                        {data?.client.email && (
                          <p
                            className={
                              styles['cancel-event-modal-details-text']
                            }
                          >
                            {data.client.email}
                          </p>
                        )}
                      </>
                    )}
                    <p className={styles['cancel-event-modal-details-text']}>
                      {appointmentDate +
                        ' / ' +
                        startAppointment.getHours() +
                        ':' +
                        String(startAppointment.getMinutes()).padStart(2, '0') +
                        '-' +
                        endAppointment.getHours() +
                        ':' +
                        String(endAppointment.getMinutes()).padStart(2, '0')}
                    </p>
                  </div>
                </div>

                <div
                  className={styles['cancel-event-modal-textarea-input-field']}
                >
                  <SimpleTextField
                    data_cy="description-input"
                    title={LPTranslate('Common_Message')}
                    placeholder={`${
                      LPTranslate(
                        'LSA_Cancel_Appointment_Input_Description_1'
                      ) +
                      data.lawyer_name +
                      LPTranslate('LSA_Cancel_Appointment_Input_Description_2')
                    }`}
                    value={formValues.description}
                    onChange={(value) => onChangeDescriptionValue(value)}
                    textFieldWidthPercent={100}
                    multiline={true}
                    rows={2.5}
                    maxLength={140}
                    errorMessage={formErrors.description}
                  />
                </div>
              </div>

              <div className={styles['cancel-event-modal-actions']}>
                <button
                  data-cy="cancel-button"
                  className={styles['cancel-event-modal-cancel-button']}
                  onClick={handleClose}
                >
                  {LPTranslate('Button_Cancel')}
                </button>

                <button
                  data-cy="submit-button"
                  className={styles['cancel-event-modal-success-button']}
                  onClick={handleSubmit}
                >
                  {LPTranslate('LSA_Cancel_Appointment_Accept')}
                </button>
              </div>
            </>
          ) : (
            <UpdatePasswordConfirmationModal
              handleClose={handleClose}
              titleMessage={LPTranslate('CSA_Cancel_Appointment_Confirm_Title')}
              hintMessage={LPTranslate('CSA_Cancel_Appointment_Confirm_Hint')}
              buttonText={LPTranslate('CSA_Cancel_Appointment_Confirm_Button')}
            />
          )}
        </Box>
      </Modal>
    </>
  );
};
export default CancelEventModal;
