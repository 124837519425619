import React from 'react';

import PartOfCommunityMockupSection from 'Components/help/PartOfCommunityMockupSection';
import MetaDecorator from 'Layouts/MetaDecorator';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/help/CookiesPage.scss';

const AnwadoSearchPage = () => {
  return (
    <div className="lawp-cookies-page-container">
      <MetaDecorator
        title={LPTranslate('ASP_MetaDecorator_Title')}
        description={LPTranslate('ASP_MetaDecorator_Description')}
        canonical={'canonical'}
        link={`${process.env.REACT_APP_DNS_URI}/anwado-suche`}
      />
      <div className="lawp-cookies-page-faq-container">
        <div className="lawp-cookies-page-header-section">
          <h1 className="lawp-cookies-page-header">
            {' '}
            {LPTranslate('ASP_Title')}{' '}
          </h1>
        </div>
        <h3 className="lawp-cookies-page-text-block">
          {LPTranslate('ASP_Description_1')}
          <br />
          <br />
          {LPTranslate('ASP_Description_2')}
          <br />
          <br />
          {LPTranslate('ASP_Description_3')}
          <br />
          <br />
          {LPTranslate('ASP_Description_4')}
          <br />
          <br />
          {LPTranslate('ASP_Description_5')}
          <br />
          <br />
          {LPTranslate('ASP_Description_6')}
          <br />
          <br />
          {LPTranslate('ASP_Description_7')}
          <br />
          <br />
          {LPTranslate('ASP_Description_8')}
          <br />
          <br />
          {LPTranslate('ASP_Description_9')}
          <br />
          <br />
          {LPTranslate('ASP_Description_10')}
          <br />
          <br />
          {LPTranslate('ASP_Description_11')}
          <br />
          <br />
        </h3>

        <ul>
          <li>
            <h3 className="lawp-cookies-page-text-block">
              <b>{LPTranslate('ASP_Paragraph_1_Title')}</b>
              {LPTranslate('ASP_Paragraph_1_Description')}
            </h3>
          </li>
          <li>
            <h3 className="lawp-cookies-page-text-block">
              <b>{LPTranslate('ASP_Paragraph_2_Title')}</b>
              {LPTranslate('ASP_Paragraph_2_Description')}
            </h3>
          </li>
          <li>
            <h3 className="lawp-cookies-page-text-block">
              <b>{LPTranslate('ASP_Paragraph_3_Title')}</b>
              {LPTranslate('ASP_Paragraph_3_Description')}
            </h3>
          </li>
          <li>
            <h3 className="lawp-cookies-page-text-block">
              <b>{LPTranslate('ASP_Paragraph_4_Title')}</b>
              {LPTranslate('ASP_Paragraph_4_Description')}
            </h3>
          </li>
        </ul>
      </div>

      <PartOfCommunityMockupSection />
    </div>
  );
};

export default AnwadoSearchPage;
