import React, { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import { useLazyQuery } from '@apollo/client';
import { GET_ARTICLES_OF_LAWYER } from 'Services/Queries/lawyer';

import ArticleCard from 'Components/Articles/ArticleCard';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import rightArrow from 'Assets/pictures/rightArrow.svg';
import leftArrow from 'Assets/pictures/leftArrow.svg';

import 'Assets/styles/articles/ArticlesBlogPage.scss';

const NUMBER_OF_ARTICLES_PER_PAGE = 9;

const ArticlesView = (props) => {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);

  const [articles, { loading, error, data }] = useLazyQuery(
    GET_ARTICLES_OF_LAWYER,
    {
      fetchPolicy: 'network-only',
    }
  );

  const handlePageChange = (value) => {
    if (value >= 1 && value <= pageCount) {
      setPage(value);
    }
  };

  const onBackButtonEvent = () => {
    props.changeContent('overview');
    return;
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    articles({
      variables: {
        input: {
          user_id: Number(props.lawyer.user_id),
          limit: NUMBER_OF_ARTICLES_PER_PAGE,
          page: page,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, articles]);

  useEffect(() => {
    if (data && data.articlesOfLawyer) {
      setPageCount(data.articlesOfLawyer.number_of_pages);
    }
  }, [data]);

  if (loading || data === undefined) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '200px',
            marginBottom: '200px',
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      </>
    );
  }
  if (error) {
    console.log(error);
    return <p>error...</p>;
  }

  return (
    <>
      <div className="lawp-article-blog-page-articles-blog-container">
        <div className="lawp-reviews-view-back-to-profile-overview-button-container">
          <Button
            className="lawp-reviews-view-back-to-profile-overview-button"
            onClick={() => props.changeContent('overview')}
            startIcon={<ArrowBackRoundedIcon />}
          >
            {LPTranslate('Article_Blog_Details_To_Lawyer')}
          </Button>
        </div>

        <div className="lawp-article-blog-page-articles-container">
          {data.articlesOfLawyer &&
            data.articlesOfLawyer.articles.map((article, index) => {
              return (
                <div
                  className="lawp-article-blog-page-article-spacing"
                  key={index}
                >
                  <ArticleCard
                    article={article}
                    previousPage={'LawyerPageArticleView'}
                  />
                </div>
              );
            })}
        </div>

        {data && data.articlesOfLawyer.number_of_pages > 1 && (
          <>
            <div className="lawp-article-blog-page-article-pagination-divider" />

            <div className="lawp-article-blog-page-articles-pagination-container">
              <Button
                disableRipple
                className="lawp-article-blog-page-left-arrow-button"
                startIcon={<img src={leftArrow} alt="leftArrow" />}
                onClick={() => {
                  handlePageChange(page - 1);
                }}
              >
                {LPTranslate('Button_Previous')}
              </Button>

              <Stack>
                <Pagination
                  className="lawp-article-blog-page-pagination"
                  count={pageCount}
                  page={page}
                  onChange={(event, value) => {
                    handlePageChange(value);
                  }}
                />
              </Stack>

              <Button
                disableRipple
                className="lawp-article-blog-page-right-arrow-button"
                endIcon={<img src={rightArrow} alt="leftArrow" />}
                onClick={() => handlePageChange(page + 1)}
              >
                {LPTranslate('Button_Next')}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ArticlesView;
