import React from 'react';

import { useNavigate } from 'react-router';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import checkCircle from 'Assets/pictures/checkCircle.svg';
import commonStyles from 'Assets/styles/forgotPassword/ForgotPasswordCommon.module.scss';
import styles from 'Assets/styles/forgotPassword/PasswordResetComplete.module.scss';
// import arrowLeft from 'Assets/pictures/arrow-left.svg';

const PasswordResetComplete = () => {
  const navigate = useNavigate();

  return (
    <div className={commonStyles['forgot-password-page-container']}>
      <div
        className={`${commonStyles['forgot-password-box-container']} 
          ${styles['password-reset-complete-box-container']}`}
      >
        <div
          className={`${commonStyles['forgot-password-header']} 
            ${styles['password-reset-complete-header']}`}
        >
          <div className={styles['password-reset-complete-header-icon']}>
            <img src={checkCircle} alt="checkCircle Icon" />
          </div>
          <div
            className={`${commonStyles['forgot-password-header-text-support-container']} 
          ${styles['password-reset-complete-header-text-support']}`}
          >
            <div
              className={`${commonStyles['forgot-password-header-text']} 
          ${styles['password-reset-complete-header-text']}`}
            >
              {LPTranslate('FP_Page_4_Title')}
            </div>
            <div
              className={`${commonStyles['forgot-password-header-support']} 
          ${styles['password-reset-complete-header-support']}`}
            >
              {LPTranslate('FP_Page_4_Description')}
            </div>
          </div>
        </div>
        <div className={commonStyles['forgot-password-form-button-container']}>
          <button
            data-cy="submit-button"
            className={commonStyles['forgot-password-form-button']}
            onClick={() => navigate('/login')}
          >
            {LPTranslate('Button_Next')}
          </button>
        </div>
        {/* <div
          className={commonStyles['forgot-password-back-button-text-container']}
          onClick={() => navigate(-1)}
        >
          <div className={commonStyles['forgot-password-back-button']}>
            <img src={arrowLeft} alt="arrow left" />
          </div>
          <div
            data-cy="back-button"
            className={commonStyles['forgot-password-back-text']}
            onClick={(e) => navigate('/')}
          >
            {LPTranslate('Common_Back_To_Login')}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default PasswordResetComplete;
