import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import CheckIcon from '@mui/icons-material/Check';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Button from '@mui/material/Button';
import { tooltipClasses } from '@mui/material/Tooltip';

import SendMessageToLawyerModal from 'Components/Modals/SendMessageToLawyerModal';
import SendMessageToLawyerNotLoggedModal from 'Components/Modals/SendMessageToLawyerNotLoggedModal';
import RatingStars from 'Components/RatingStars/RatingStars';
import AskLawyerToSetScheduleModal from 'Components/Modals/AskLawyerToSetScheduleModal';
import FavoriteLawyer from 'Components/CommonComponents/FavoriteLawyer';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import avatar from 'Assets/pictures/AvatarProfile.png';

import styles from 'Assets/styles/lawyer/LawyerProfileCard.module.scss';

const AnwadoTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '14px !important',
    fontFamily: 'Inter !important',
    backgroundColor: '#f9f5ff !important',
    color: '#6941c6 !important',
    padding: '10px !important',
  },
}));

const LawyerProfileCard = (props) => {
  let {
    lawyer,
    availability,
    averageNr,
    clientInfoRedux,
    reviewsNr,
    user_id,
    lawyer_id,
  } = props;

  const [articleImage, setArticleImage] = useState(null);
  const openMessage = true;
  const navigate = useNavigate();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [disabledDaysIndex, setDisabledDaysIndex] = useState(0);

  useEffect(() => {
    if (availability && availability.length !== 0) {
      let index = 0;

      for (let i = 0; i < availability.length; i++) {
        if (!availability[i].enabled) index++;
      }
      setDisabledDaysIndex(index);
    }
  }, [availability]);

  useEffect(() => {
    if (lawyer.profileImage) {
      setArticleImage(lawyer.profileImage);
    } else {
      setArticleImage(null);
    }
    return () => {
      setArticleImage(null);
    };
  }, [lawyer.profileImage]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  let enabledAppointmentButton = (
    availability,
    disabledDaysIndex,
    LawyerIsavailable,
    lawyerShowAvailability,
    lawyerSubscription
  ) => {
    return (
      <Button
        data-cy={'appointment-button'}
        className={
          availability &&
          availability.length !== 0 &&
          disabledDaysIndex < 7 &&
          LawyerIsavailable &&
          lawyerShowAvailability &&
          lawyerSubscription > 1
            ? styles.appointmentButton
            : styles.requestAppointmentButton
        }
        variant="text"
        onClick={() => {
          if (availability && availability.length !== 0) {
            if (
              disabledDaysIndex < 7 &&
              LawyerIsavailable &&
              lawyer.lawyerAvailability.show_availability &&
              lawyer.subscription > 1
            ) {
              navigate('/setAppointment', {
                state: {
                  id: lawyer.id,
                  lawyer: lawyer,
                  averageNr: averageNr,
                  reviewsNr: reviewsNr,
                  profileImage: articleImage,
                  specialities: lawyer.specialities,
                  backButtonText: 'to Profil Overview',
                  availability: lawyer.lawyerAvailability,
                },
              });
            } else setOpenModal(true);
          } else {
            setOpenModal(true);
          }
        }}
      >
        <CalendarTodayIcon transform="scale(0.8)" />{' '}
        {disabledDaysIndex < 7 &&
        lawyer.lawyerAvailability.show_availability &&
        lawyer.subscription > 1
          ? LPTranslate('Button_Make_Appointment')
          : LPTranslate('Button_Make_Appointment_Request')}
      </Button>
    );
  };

  let disabledAppointmentButton = (
    disabledDaysIndex,
    LawyerIsavailable,
    lawyerSubscription
  ) => {
    return (
      <Tooltip
        className="tooltip-medium-size"
        PopperProps={{
          disablePortal: true,
        }}
        title={LPTranslate('Error_Lawyer_Cannot_Create_Appointment')}
        placement="top"
        TransitionComponent={Zoom}
      >
        <button
          data-cy="faq-appointment-button"
          className="faqsButton"
          style={{
            display: 'flex',
            alignItems: 'center',
            background: 'gray',
            border: '1px solid gray',
            height: '38.5px',
            padding: '10px 18px',
            boxSizing: 'border-box',
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            borderRadius: '8px',
            // margin: '0px 12px',
            color: '#FFF',
            fontSize: '14px',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            textTransform: 'none',
          }}
          variant="text"
        >
          {disabledDaysIndex < 7 && LawyerIsavailable && lawyerSubscription > 1
            ? LPTranslate('LPP_FAQ_Action_button')
            : LPTranslate('Button_Make_Appointment_Request')}
        </button>
      </Tooltip>
    );
  };

  var appointmentButton = () => {
    let LawyerIsavailable;

    if (clientInfoRedux.role === 'lawyer') {
      if (
        lawyer.lawyerAvailability.personally ||
        (lawyer.lawyerAvailability.by_phone && lawyer.telephone)
      )
        LawyerIsavailable = true;
      else LawyerIsavailable = false;

      return disabledAppointmentButton(
        disabledDaysIndex,
        LawyerIsavailable,
        lawyer.subscription
      );
    } else if (
      clientInfoRedux.role === 'client' ||
      clientInfoRedux.role === ''
    ) {
      if (
        lawyer.lawyerAvailability.personally ||
        (lawyer.lawyerAvailability.by_phone && lawyer.telephone)
      )
        LawyerIsavailable = true;
      else LawyerIsavailable = false;

      return enabledAppointmentButton(
        availability,
        disabledDaysIndex,
        LawyerIsavailable,
        lawyer.subscription
      );
    }
  };

  const navigateToReviewsSection = () => {
    const element = document.getElementById('reviewsSection');
    if (element) {
      const headerOffset = 45;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  // const SelectedTagJSX = (value, key) => {
  //   return (
  //     <div
  //       className="editLawyerTagContainer"
  //       style={{
  //         color: AreasOfLaw_Color[value]
  //           ? AreasOfLaw_Color[value].color
  //           : '#000000',
  //         backgroundColor: AreasOfLaw_Color[value]
  //           ? AreasOfLaw_Color[value].background
  //           : '#F2F4F7',
  //       }}
  //       key={key}
  //     >
  //       <div className="editLawyerTagAlignDiv">
  //         <p className="editLawyerTagText">{value}</p>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <>
      <div className={styles.lawyerProfileCardContainer}>
        <div className={styles.lawyerProfileImageContainer}>
          {articleImage !== null ? (
            <img
              alt={'articleImage'}
              src={articleImage}
              className={styles.lawyerProfileImage}
            />
          ) : (
            <img
              alt={'articleImage'}
              className={styles.lawyerProfileImagePlaceholder}
              src={avatar}
            />
          )}
        </div>

        <div className={styles.lawyerDataContainer}>
          <h1 className={styles.lawyerNameSubscriptionVerifyContainer}>
            {lawyer.title && `${lawyer.title} `}
            {lawyer.first_name + ' ' + lawyer.last_name}
            {lawyer.title_suffix && `, ${lawyer.title_suffix}`}
            <div className={styles.lawyerSubscriptionVerifyContainer}>
              {lawyer.isAccountVerified && (
                <div className={styles.verifyContainer}>
                  <div className={styles.verifyInnerContainer}>
                    <CheckIcon className={styles.verifyLogo} fontSize="small" />

                    <p className={styles.verifyText}>Verifiziert</p>
                  </div>
                </div>
              )}
            </div>
          </h1>

          <div className={styles.ratingContainer}>
            <RatingStars
              onClickHandler={navigateToReviewsSection}
              pointerOnHover={true}
              value={averageNr}
              width={20}
              height={20}
              readonly={true}
              colorFilter={
                'invert(91%) sepia(69%) saturate(1377%) hue-rotate(317deg) brightness(99%) contrast(102%)'
              }
            />

            <Button
              className={styles.ratingText}
              data-cy={'rating-button'}
              disableRipple
              onClick={navigateToReviewsSection}
            >
              {averageNr +
                ' ' +
                LPTranslate('LPP_Header_From') +
                ' ' +
                reviewsNr +
                ' ' +
                (reviewsNr === 1
                  ? LPTranslate('Common_Review')
                  : LPTranslate('Common_Reviews'))}
            </Button>
          </div>

          <div className={styles.fachanwaltContainer}>
            {/* {lawyer.specialities.length > 0 && ( */}
            {/* // <h3>{`${LPTranslate('SL_Lawyer_Card_Specialist')}`}</h3> */}
            {/* // )} */}

            {/* <div className={styles.specialitiesTagsContainer}> */}
            {/* {lawyer.specialities.length > 0 &&
                lawyer.specialities.map((speciality, index) => {
                  return (
                    <React.Fragment key={index}>
                      {SelectedTagJSX(speciality)}
                    </React.Fragment>
                  );
                })} */}
            {/* </div> */}
            <p className="specialist-attourneys-section">
              {lawyer.specialities.length > 0 &&
                lawyer.specialities.map((speciality, index) => {
                  if (index === 0) {
                    return (
                      <React.Fragment key={index}>
                        {`${LPTranslate(
                          'SL_Lawyer_Card_Specialist'
                        )} ${speciality}`}
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment
                        key={index}
                      >{`, ${speciality}`}</React.Fragment>
                    );
                  }
                })}
            </p>
          </div>

          <div className={styles.buttonsContainer}>
            <AnwadoTooltip
              PopperProps={{
                disablePortal: true,
              }}
              title={LPTranslate('Common_Add_To_Favorites')}
              placement="top"
              TransitionComponent={Zoom}
            >
              <div className="bookmark-container">
                <FavoriteLawyer
                  user_id={user_id}
                  lawyer_id={lawyer_id}
                  role_id={clientInfoRedux.role_id}
                  role={clientInfoRedux.role}
                />
              </div>
            </AnwadoTooltip>

            {clientInfoRedux.role.length !== 0 ? (
              <SendMessageToLawyerModal
                lawyer={lawyer}
                client={clientInfoRedux}
                openMessage={openMessage}
              />
            ) : (
              <SendMessageToLawyerNotLoggedModal
                lawyer={lawyer}
                client={clientInfoRedux}
                openMessage={openMessage}
              />
            )}

            {appointmentButton()}
          </div>
        </div>
      </div>

      <AskLawyerToSetScheduleModal
        openModal={openModal}
        handleOpenModal={() => setOpenModal(false)}
        handleCloseSnackbar={handleCloseSnackbar}
        handleSnackbarMessage={(message) => setSnackbarMessage(message)}
        handleSnackbarSeverity={(type) => setSnackbarSeverity(type)}
        handleSnackbarOpen={() => setSnackbarOpen(true)}
        snackbarSeverity={snackbarSeverity}
        snackbarOpen={snackbarOpen}
        snackbarMessage={snackbarMessage}
        profileImage={articleImage}
        title={lawyer.title}
        first_name={lawyer.first_name}
        last_name={lawyer.last_name}
        email={lawyer.email}
        subscription={lawyer.subscription}
      />
    </>
  );
};

export default LawyerProfileCard;
