import React from 'react';

import PartOfCommunityMockupSection from 'Components/help/PartOfCommunityMockupSection';
import MetaDecorator from 'Layouts/MetaDecorator';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/help/CookiesPage.scss';

const ImprintPage = () => {
  return (
    <div className="lawp-cookies-page-container">
      <MetaDecorator
        title={LPTranslate('LN_Meta_Title')}
        description={LPTranslate('LN_Meta_Description')}
        canonical={'canonical'}
        link={`${process.env.REACT_APP_DNS_URI}/impressum`}
      />
      <div className="lawp-cookies-page-faq-container">
        <div className="lawp-cookies-page-header-section">
          <h4 className="lawp-cookies-page-subheader">
            {' '}
            {LPTranslate('LN_Date')}{' '}
          </h4>

          <h1 className="lawp-cookies-page-header">
            {' '}
            {LPTranslate('LN_Title')}{' '}
          </h1>

          <h3 className="lawp-cookies-page-description">
            {' '}
            {LPTranslate('LN_Description')}{' '}
          </h3>
        </div>

        <h3 className="lawp-cookies-page-text-block">
          {LPTranslate('LN_Description_1')}
        </h3>

        <div className="lawp-cookies-page-faq-section">
          <h2 className="lawp-cookies-page-title-block">
            {LPTranslate('LN_Title_2')}
          </h2>
          <h3 className="lawp-cookies-page-text-block">
            {LPTranslate('LN_Description_2_1')}
            <a
              className={'lawp-cookies-page-link'}
              href="https://ec.europa.eu/consumers/odr/"
            >
              https://ec.europa.eu/consumers/odr/.
            </a>
            &nbsp;
            {LPTranslate('LN_Description_2_2')}
          </h3>

          <h2 className="lawp-cookies-page-title-block">
            {LPTranslate('LN_Title_3')}
          </h2>
          <h3 className="lawp-cookies-page-text-block">
            {LPTranslate('LN_Description_3')}
          </h3>

          <h2 className="lawp-cookies-page-title-block">
            {LPTranslate('LN_Title_4')}
          </h2>
          <h3 className="lawp-cookies-page-text-block">
            {LPTranslate('LN_Description_4')}
          </h3>

          <h2 className="lawp-cookies-page-title-block">
            {LPTranslate('LN_Title_5')}
          </h2>
          <h3 className="lawp-cookies-page-text-block">
            {LPTranslate('LN_Description_5')}
          </h3>

          {/* <h2 className="lawp-cookies-page-title-block">
            {LPTranslate('LN_Title_6')}
          </h2>
          <h3 className="lawp-cookies-page-text-block">
            {LPTranslate('LN_Description_6')}
          </h3>

          <h2 className="lawp-cookies-page-title-block">
            {LPTranslate('LN_Title_7')}
          </h2>
          <h3 className="lawp-cookies-page-text-block">
            {LPTranslate('LN_Description_7')}
          </h3>

          <h2 className="lawp-cookies-page-title-block">
            {LPTranslate('LN_Title_8')}
          </h2>
          <h3 className="lawp-cookies-page-text-block">
            {LPTranslate('LN_Description_8')}
          </h3> */}
        </div>
      </div>

      <PartOfCommunityMockupSection language="de" />
    </div>
  );
};

export default ImprintPage;
