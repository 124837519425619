import React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import Button from '@mui/material/Button';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/lawyer/ReviewCardPublicSeeMoreModal.scss';

export default function ReviewCardPublicSeeMoreModal(props) {
  const { review } = props;

  return (
    <div>
      <Modal open={props.open} onClose={props.handleClose}>
        <Box className="lawp-review-card-public-see-more-modal-container">
          <p className="lawp-review-card-public-see-more-modal-title">
            {review.title}
          </p>

          <div className="lawp-review-card-public-see-more-modal-lawyer-description">
            <p className="lawp-review-card-public-see-more-modal-lawyer-description-text">
              {review.content}
            </p>
          </div>

          <div className="lawp-review-card-public-see-more-modal-button-container">
            <Button
              className="lawp-review-card-public-see-more-modal-cancel-button"
              onClick={() => props.handleClose()}
            >
              {LPTranslate('Button_Cancel')}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
