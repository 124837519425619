import React, { useState } from 'react';

import { useDispatch } from 'react-redux';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { addLawyerSelected } from 'Services/Redux/reducers/RegisterReducer';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import refresh from 'Assets/pictures/refreshIcon.svg';
import UserPlaceholderImage from 'Assets/pictures/userPlaceholderImage.png';

import styles from 'Assets/styles/registerLawyer/ProfileChecker.module.scss';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LawyerProfileChecker = (props) => {
  const {
    title,
    salutation,
    firstName,
    lastName,
    email,
    availableLawyers,
    lawyerSelected,
    setLawyerSelected,
    handleRegisterLawyer,
    returnToRegister,
  } = props;

  const dispatch = useDispatch();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const snackbarSeverity = 'success';
  const snackbarMessage = false;

  const [showButtons, setShowButtons] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleButton = async () => {
    setIsProcessing(true);
    await handleRegisterLawyer(
      lawyerSelected,
      title,
      salutation,
      email,
      firstName,
      lastName,
      true
    );
    setIsProcessing(false);
  };

  const lawyerClicked = (e, lawyer) => {
    e.stopPropagation();

    localStorage.setItem('ClaimedLawyerId', lawyer.id);

    const parent = e.target.closest('[data-lawyer]');
    parent.classList.remove(styles['background-unselected']);
    parent.classList.add(styles['background-selected']);
    // show next button or return to register button
    setLawyerSelected(lawyer.id);
    dispatch(addLawyerSelected(lawyer.id));
    setShowButtons(true);
    var cards = document.getElementsByClassName(
      styles['available-lawyer-container']
    );
    for (var i = 0; i < cards.length; i++) {
      if (cards[i] !== e.currentTarget) {
        cards[i].parentNode.classList.add(styles['background-unselected']);
        cards[i].parentNode.classList.remove(styles['background-selected']);
      }
    }
  };

  const showAvailablaLawyers = (availableLawyers) => {
    return (
      <div className={styles['available-lawyers-container']}>
        {availableLawyers.map((lawyer, index) => (
          <div
            className={styles['background-unselected']}
            data-lawyer
            key={index}
          >
            <button
              // key={index}
              data-lawyer-button
              className={styles['available-lawyer-container']}
              onClick={(e) => lawyerClicked(e, lawyer)}
            >
              <img
                src={UserPlaceholderImage}
                alt="default"
                className={styles['available-lawyer-image']}
              />

              <div className={styles['text-container']}>
                <p className={styles['name-text']}>
                  {lawyer.title && lawyer.title} {lawyer.first_name}{' '}
                  {lawyer.last_name}
                </p>
                {lawyer.telephone && (
                  <p className={styles['profile-checker-text']}>
                    {lawyer.telephone}
                  </p>
                )}
                {lawyer.location && (
                  <p className={styles['profile-checker-text']}>
                    {lawyer.location}
                  </p>
                )}
                {lawyer.street && lawyer.house_number && (
                  <p className={styles['profile-checker-text']}>
                    {lawyer.street} {lawyer.house_number}
                  </p>
                )}
              </div>
            </button>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className={styles['emailCheckerContainer']}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={
              snackbarSeverity === 'error' && {
                backgroundColor: '#7f56d9 !important',
              }
            }
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <h1 className={styles['register-lawyer-header']}>
          {LPTranslate('RL_Multiple_Emails_Step_Title')}
        </h1>
        <h3 className={styles['register-lawyer-description']}>
          {LPTranslate('RL_Multiple_Emails_Step_Description')}
        </h3>
        {showAvailablaLawyers(availableLawyers)}
        {showButtons && (
          <>
            <div className={styles.emailCheckButton}>
              <div className={styles.Button}>
                <button
                  data-cy="access-account-button"
                  className={styles['buttonBase']}
                  onClick={handleButton}
                  disabled={isProcessing}
                >
                  <p className={styles['buttonText']}>
                    {LPTranslate('RL_Page_1_Next_Step_Button_Text')}
                  </p>
                </button>
              </div>

              <div className={styles['emailCheckerCreateAccountButton']}>
                <button
                  data-cy="delete-account-button"
                  className={styles['CreateAccountButtonBase']}
                  onClick={returnToRegister}
                  disabled={isProcessing}
                >
                  <img
                    className={styles['refreshIcon']}
                    src={refresh}
                    alt="key"
                  />
                  <p className={styles['CreateAccountButtonText']}>
                    {LPTranslate('RL_Page_1_Return_New_Account')}
                  </p>
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default LawyerProfileChecker;
