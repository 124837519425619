import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Avatar from '@mui/material/Avatar';

import RatingStars from 'Components/RatingStars/RatingStars';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { milisecondsToMMMDDYYYY } from 'Services/Utils/moment';

import 'Assets/styles/lawyer/ReviewCardPublicFullInfoModal.scss';

export default function ReviewCardPublicFullInfoModal(props) {
  const handleClose = (e) => props.onClose(e);
  const [lawyerImage, setLawyerImage] = useState(null);

  useEffect(() => {
    if (props.lawyerImage) setLawyerImage(props.lawyerImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal open={props.show} onClose={(e) => handleClose(e)}>
      <Box className="review-card-public-full-info-modal-container">
        <h2 className="title-date">
          {LPTranslate('RS_Rating_From')}{' '}
          {milisecondsToMMMDDYYYY(props.review.createdAt)}
        </h2>

        <p className="title-text">{props.review.title}</p>

        <div className="review-card">
          <div className="client-info-container">
            {props.review.client && props.review.client.name && (
              <Avatar
                className="client-name-image"
                sx={{ backgroundColor: '#F9F5FF', color: '#7F56D9' }}
              >
                {props.review.client.name}
              </Avatar>
            )}
            <div className="client-name-container">
              <RatingStars
                value={props.review.rating}
                width={20}
                height={20}
                readonly={true}
                colorFilter={
                  'invert(91%) sepia(69%) saturate(1377%) hue-rotate(317deg) brightness(99%) contrast(102%)'
                }
              />

              <p className="client-name">{props.clientName}</p>
            </div>
          </div>

          <p className="review-content">"{props.review.content}"</p>
        </div>

        {props.review.response && (
          <div className="review-card">
            <div className="lawyer-name-container">
              {lawyerImage && (
                <img className="lawyer-image" src={lawyerImage} alt="lawyer" />
              )}

              <p className="client-name">{props.review.lawyer_name}</p>
            </div>

            <p className="review-content">"{props.review.response}"</p>
          </div>
        )}

        <div className="button-container">
          <Button
            data-cy="cancel-button"
            className="cancel-button"
            onClick={(e) => handleClose(e)}
          >
            {LPTranslate('Button_Cancel')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
