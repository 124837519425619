import React, { useState, useEffect } from 'react';

import * as ReactQuill from 'react-quill';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/lawyer/DescriptionLawyer.module.scss';

const DescriptionLawyer = (props) => {
  let { lawyer } = props;
  const [profileImage, setProfileImage] = useState(null);

  useEffect(() => {
    if (lawyer.profileImage) {
      setProfileImage(lawyer.profileImage);
    }
  }, [lawyer]);

  return (
    <>
      {lawyer.subscription > 1 && lawyer.self_description && (
        <div className={styles.descriptionLawyerOuterContainer}>
          {lawyer.self_description && (
            <div className={styles.descriptionLawyerInnerContainer}>
              <div className={styles.descriptionLawyerContainer}>
                <h2 className={styles.descriptionLawyerTitle}>
                  {LPTranslate('LPP_Details_About_Me')}
                </h2>

                <div className={styles.descriptionLawyerText}>
                  <ReactQuill
                    value={lawyer.self_description}
                    readOnly={true}
                    theme={'bubble'}
                  />
                </div>

                {/* TODO: read more button */}
                {/* <button className={styles.moreButton} variant="text">Mehr</button> */}
              </div>

              {profileImage && (
                <div className={styles.descriptionLawyerImageContainer}>
                  <img
                    className={styles.descriptionLawyerImage}
                    src={profileImage}
                    alt="profilePicture"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DescriptionLawyer;
