import React from 'react';

import { useSelector } from 'react-redux';

import SettingsMenu from 'Components/CommonComponents/SettingsMenu';
import SubscriptionProcess from 'Components/Subscription/SubscriptionProcess';

import styles from 'Assets/styles/subscription/subscriptionPage.module.scss';
import { parseJwt } from 'Services/Utils/token';

const SubscriptionPage = () => {
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  return (
    <div className={styles['subscription-page-container']}>
      <div>
        <SettingsMenu
          setUpdateComponent={'/paymentSettings'}
          role={clientInfoRedux.role}
          subscription={parseJwt(clientInfoRedux.token).subscription}
        />
      </div>

      <SubscriptionProcess />
    </div>
  );
};

export default SubscriptionPage;
