import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router';
import { useMutation } from '@apollo/client';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import SimpleTextField from 'Components/Inputs/SimpleTextField';

import commonStyles from 'Assets/styles/forgotPassword/ForgotPasswordCommon.module.scss';
import styles from 'Assets/styles/forgotPassword/EmailPasswordRequest.module.scss';
import key from 'Assets/pictures/keyIcon.svg';
import arrowLeft from 'Assets/pictures/arrow-left.svg';

import { FORGOT_PASSWORD } from 'Services/Queries/user';
import { validateEmail } from 'Services/Utils/validations';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EmailPasswordRequest = (props) => {
  const navigate = useNavigate();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
  const [snackbarMessage, setSnackbarMessage] = useState(false);

  const [forgotPassword, { data: forgotPasswordData }] =
    useMutation(FORGOT_PASSWORD);

  const [formValues, setFormValues] = useState({ email: '' });
  const [formErrors, setFormErrors] = useState({ email: '' });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleInputChange = (inputName, value) => {
    switch (inputName) {
      case 'email':
        setFormValues({ ...formValues, email: value });
        break;

      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isError = false;
    let newInputErrors = { email: '' };

    if (formValues.email.length === 0) {
      isError = true;
      newInputErrors.email = LPTranslate('Error_Email_Required');
    } else if (!validateEmail(formValues.email)) {
      isError = true;
      newInputErrors.email = LPTranslate('Error_Valid_Email');
    }

    setFormErrors(newInputErrors);

    if (isError) return;
    props.handleEmail(formValues.email);

    try {
      await forgotPassword({
        variables: { input: { email: formValues.email } },
      });
    } catch (error) {
      setSnackbarMessage(LPTranslate('Error_Server_Down'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    if (forgotPasswordData) {
      const backendMsg = forgotPasswordData.forgotPassword;
      if (backendMsg.message.length > 0) {
        props.handleNext(0);
      } else if (backendMsg.error) {
        setSnackbarMessage(LPTranslate('Error_Server_Down'));
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forgotPasswordData]);

  return (
    <div className={commonStyles['forgot-password-page-container']}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={
            snackbarSeverity === 'error' && {
              backgroundColor: '#7f56d9 !important',
            }
          }
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <div
        className={`${commonStyles['forgot-password-box-container']} 
          ${styles['email-password-request-box-container']}`}
      >
        <div
          className={`${commonStyles['forgot-password-header']} 
            ${styles['email-password-request-header']}`}
        >
          <div className={commonStyles['forgot-password-header-icon']}>
            <img src={key} alt="Key Icon" />
          </div>
          <div
            className={`${commonStyles['forgot-password-header-text-support-container']} 
          ${styles['email-password-request-header-text-support']}`}
          >
            <h2
              className={`${commonStyles['forgot-password-header-text']} 
          ${styles['email-password-request-header-text']}`}
            >
              {LPTranslate('Common_Forgot_Password')}
            </h2>
            <h3
              className={`${commonStyles['forgot-password-header-support']} 
          ${styles['email-password-request-header-support']}`}
            >
              {LPTranslate('FP_Page_1_Description')}
            </h3>
          </div>
        </div>

        <div className={`${commonStyles['forgot-password-content']} `}>
          <div className={`${commonStyles['forgot-password-form-container']}`}>
            <form
              className={`${commonStyles['forgot-password-form']}`}
              id="forgot-password-form"
              onSubmit={async (e) => await handleSubmit(e)}
            >
              <div
                className={`${commonStyles['forgot-password-input-label-container']} 
                  `}
              >
                <SimpleTextField
                  data_cy="email-address-input"
                  title="Email"
                  placeholder={LPTranslate('Common_Email_Placeholder')}
                  onChange={(value) => {
                    handleInputChange('email', value);
                    setFormErrors({ ...formErrors, email: '' });
                  }}
                  value={formValues.email}
                  textFieldWidthPercent={100}
                  type="text"
                  errorMessage={formErrors.email}
                />
              </div>
            </form>
          </div>

          <div
            className={`${commonStyles['forgot-password-form-button-container']} 
            ${styles['email-password-request-action-button-margin-top']}`}
          >
            <button
              data-cy="submit-button"
              className={commonStyles['forgot-password-form-button']}
              type="submit"
              form="forgot-password-form"
            >
              {LPTranslate('Button_Reset_Password')}
            </button>
          </div>
        </div>

        <div
          data-cy="back-button"
          className={commonStyles['forgot-password-back-button-text-container']}
          onClick={() => navigate(-1)}
        >
          <div className={commonStyles['forgot-password-back-button']}>
            <img src={arrowLeft} alt="arrow left" />
          </div>
          <h4 className={commonStyles['forgot-password-back-text']}>
            {LPTranslate('Common_Back_To_Login')}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default EmailPasswordRequest;
