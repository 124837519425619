import React from 'react';

import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';

import SettingsMenu from 'Components/CommonComponents/SettingsMenu';
import EditSaveChangesSection from 'Components/Lawyer/Edit/EditSaveChangesSection';
import EventsSection from 'Components/Client/EventsComponents/EventsSection';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { parseJwt } from 'Services/Utils/token';
import { ACTIVE_APPOINTMENTS_COUNT } from 'Services/Queries/lawyer';

import styles from 'Assets/styles/settings/common/eventsSettings.module.scss';

const EventsPage = () => {
  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  const [numberOfActiveAppointments, setNumberOfActiveAppointments] =
    React.useState(0);

  const [getActiveAppointmentsCount, { data }] = useLazyQuery(
    ACTIVE_APPOINTMENTS_COUNT
  );

  React.useEffect(() => {
    if (data) {
      setNumberOfActiveAppointments(data.activeAppointmentsCount);
    }
  }, [data]);

  React.useEffect(() => {
    if (clientInfoRedux.role === 'lawyer') getActiveAppointmentsCount();
  }, [clientInfoRedux.role, getActiveAppointmentsCount]);

  const displayLawyerEvents = () => {
    let allowed = true;

    if (parseJwt(clientInfoRedux.token).subscription <= 1) allowed = false;

    if (clientInfoRedux.role !== 'lawyer') allowed = false;

    if (!allowed) {
      if (numberOfActiveAppointments > 0) allowed = true;
    }

    return allowed;
  };

  return (
    <>
      {displayLawyerEvents() ? (
        <div key={1} className={styles['events-settings-container']}>
          <div className={styles['events-settings-top-menu']}>
            <SettingsMenu
              setUpdateComponent={'/clientEventPage'}
              role={clientInfoRedux.role}
              subscription={parseJwt(clientInfoRedux.token).subscription}
            />
          </div>

          <div className={styles['events-settinngs-edit-save-changes-section']}>
            <EditSaveChangesSection
              pageTitle={LPTranslate('Common_Appointments')}
            />
          </div>

          <EventsSection />
        </div>
      ) : (
        [
          clientInfoRedux.role === 'client' ? (
            <div key={2} className={styles['events-settings-container']}>
              <div className={styles['events-settings-top-menu']}>
                <SettingsMenu
                  setUpdateComponent={'/clientEventPage'}
                  role={clientInfoRedux.role}
                />
              </div>

              <div
                className={styles['events-settinngs-edit-save-changes-section']}
              >
                <EditSaveChangesSection
                  pageTitle={LPTranslate('Common_Appointments')}
                />
              </div>

              <EventsSection />
            </div>
          ) : (
            <div key={3} className={styles.permissionText}>
              {LPTranslate('LSC_PermissionText')}
            </div>
          ),
        ]
      )}
    </>
  );
};

export default EventsPage;
