import heartIcon from 'Assets/pictures/heartIcon.svg';
import barCircleIcon from 'Assets/pictures/barCircleIcon.svg';
import CircularArrowsIcon from 'Assets/pictures/CircularArrowsIcon.svg';
import fileIcon from 'Assets/pictures/fileIcon.svg';
import mailIcon from 'Assets/pictures/mailIcon.svg';
import plusCircleIcon from 'Assets/pictures/plusCircleIcon.svg';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

const firstComponentTexts = [
  {
    cardText: LPTranslate('LPP_FAQ_Card_1_Description'),
    cardTitle: LPTranslate('LPP_FAQ_Card_1_Title'),
    picture: heartIcon,
  },

  {
    cardText: LPTranslate('LPP_FAQ_Card_2_Description'),
    cardTitle: LPTranslate('LPP_FAQ_Card_2_Title'),
    picture: CircularArrowsIcon,
  },

  {
    cardText: LPTranslate('LPP_FAQ_Card_3_Description'),
    cardTitle: LPTranslate('LPP_FAQ_Card_3_Title'),
    picture: barCircleIcon,
  },

  {
    cardText: LPTranslate('LPP_FAQ_Card_4_Description'),
    cardTitle: LPTranslate('LPP_FAQ_Card_4_Title'),
    picture: plusCircleIcon,
  },

  {
    cardText: LPTranslate('LPP_FAQ_Card_5_Description'),
    cardTitle: LPTranslate('LPP_FAQ_Card_5_Title'),
    picture: fileIcon,
  },

  {
    cardText: LPTranslate('LPP_FAQ_Card_6_Description'),
    cardTitle: LPTranslate('LPP_FAQ_Card_6_Title'),
    picture: mailIcon,
  },
];

export default firstComponentTexts;
