import React, { useState } from 'react';

import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

import LandingPageTopView from 'Components/LandingPageTopView';
import SearchBarComponent from 'Layouts/searchBar/SearchBarComponent';
// import AboutUsAchievement from 'Components/help/AboutUsAchievement';
import LawyerSection from 'Components/Lawyer/LawyerSection';
import RatingStars from 'Components/RatingStars/RatingStars';
import HomePageArticleSection from 'Components/Lawyer/HomePageArticleSection';
import PartOfCommunityMockupSection from 'Components/help/PartOfCommunityMockupSection';
import LawyerCategories from 'Components/Lawyer/LawyerCategories';
import MetaDecorator from 'Layouts/MetaDecorator';

// import statisticsIcon from 'Assets/pictures/statisticsIcon.svg';
// import lightIcon from 'Assets/pictures/lightIcon.svg';
// import dollarIcon from 'Assets/pictures/dollarIcon.svg';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import imagesGroup from 'Assets/pictures/imagesGroup3.png';
import reviewImage from 'Assets/pictures/reviewImage.png';
import HomePageImage1 from 'Assets/pictures/HomePageImage2.jpg';

import 'Assets/styles/user/HomePage.scss';

// const Achievemnts = [
//   {
//     title: '65.000+',
//     subtitle: LPTranslate('AU_Info_1_Box_1_Title'),
//     description: LPTranslate('AU_Info_1_Box_1_Description'),
//   },
//   {
//     title: '30.000+',
//     subtitle: LPTranslate('AU_Info_1_Box_2_Title'),
//     description: LPTranslate('AU_Info_1_Box_2_Description'),
//   },
//   {
//     title: '17.000+',
//     subtitle: LPTranslate('AU_Info_1_Box_3_Title'),
//     description: LPTranslate('AU_Info_1_Box_3_Description'),
//   },
//   {
//     title: '160',
//     subtitle: LPTranslate('AU_Info_1_Box_4_Title'),
//     description: LPTranslate('AU_Info_1_Box_4_Description'),
//   },
// ];

// let characteristicsDummyData = [
//   {
//     cardText: LPTranslate('LPB_Functions_Title_1'),
//     cardTitle: LPTranslate('LPB_Features_Box_1_Title'),
//     picture: lightIcon,
//   },
//   {
//     cardText: LPTranslate('LPB_Features_Box_2_Description'),
//     cardTitle: LPTranslate('LPB_Features_Box_2_Title'),
//     picture: dollarIcon,
//   },
//   {
//     cardText: LPTranslate('LPB_Features_Box_3_Description'),
//     cardTitle: LPTranslate('LPB_Features_Box_3_Title'),
//     picture: statisticsIcon,
//   },
// ];

const HomePage = () => {
  const [areasOfLaw, setAreasOfLaw] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [locationInput, setLocationInput] = useState('');
  const [locationDistanceInput, setLocationDistanceInput] = useState(0);

  const navigate = useNavigate();

  const handleSearchButton = () => {
    let navigateUrl = `/suche?`;

    if (searchInput) navigateUrl += `src=${searchInput}&`;

    if (locationInput) navigateUrl += `loc=${locationInput}&`;

    if (locationDistanceInput)
      navigateUrl += `srcdis=${locationDistanceInput}&`;

    if (areasOfLaw.length > 0) navigateUrl += `aol=${areasOfLaw}&`;

    navigateUrl = navigateUrl.slice(0, -1);

    navigate(navigateUrl);
  };

  const areaOfLawChangeHandler = (index) => {
    if (areasOfLaw.includes(index)) {
      setAreasOfLaw(areasOfLaw.filter((element) => element !== index));
    } else {
      setAreasOfLaw([...areasOfLaw, index]);
    }
  };

  const handleEnterKeyPress = (event) => {
    handleSearchButton();
  };

  return (
    <div className="lawp-home-page-container">
      <MetaDecorator
        title={LPTranslate('LP_Info_1_Meta_Title_1')}
        description={LPTranslate('LP_Info_1_Meta_Description_1')}
        canonical={'canonical'}
        link={`${process.env.REACT_APP_DNS_URI}`}
      />

      <LandingPageTopView lawyerLoading={false} lawyerError={false} />

      <SearchBarComponent
        updateLawyers={handleSearchButton}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        locationInput={locationInput}
        setLocationInput={setLocationInput}
        locationDistanceInput={locationDistanceInput}
        onChangeLocationDistanceSlider={setLocationDistanceInput}
        areasOfLawBoxes={areasOfLaw}
        onChangeAreasOfLaw={areaOfLawChangeHandler}
        onEnterKeyPress={handleEnterKeyPress}
      />

      {/* <div className="achievements-container">
        {Achievemnts.map((achievement, index) => {
          return (
            <AboutUsAchievement
              key={index}
              title={achievement.title}
              subtitle={achievement.subtitle}
              description={achievement.description}
            />
          );
        })}
      </div> */}

      <LawyerCategories />

      <div className="review-section-only-text">
        <div className="content">
          <h2 className="review-text">
            "{LPTranslate('LP_Founders_Citation')}"
          </h2>

          <h3 className="name">{LPTranslate('LP_Founders_Name')}</h3>
        </div>
      </div>

      <div className="video-container">
        <div className="video-container-info">
          <h4>{LPTranslate('LPB_Functions_Action_Button_1')}</h4>
          <h2>{LPTranslate('LPB_Functions_Title_1')}</h2>
          <h3>{LPTranslate('LPB_Functions_Description')}</h3>
        </div>
      </div>

      <LawyerSection
        changeContent={() => {
          window.scrollTo(0, 0);
        }}
      />

      <div className="create-profile-container">
        <div className="left">
          <h2>{LPTranslate('LPB_Info_1_Title')}</h2>
          <h3>{LPTranslate('LPB_Info_1_Description')}</h3>
        </div>
        <div className="right">
          <Button
            className="lawyer-portal-button"
            data-cy="lawyer-portal"
            onClick={() => navigate('/anwaelte')}
          >
            <a
              href={`${process.env.REACT_APP_DNS_URI}/anwaelte`}
              style={{ color: '#344054 ' }}
              onClick={(e) => e.stopPropagation()}
            >
              {LPTranslate('LPB_Info_1_Action_Button_1')}
            </a>
          </Button>

          <Button
            className="register-button"
            data-cy="register"
            onClick={() =>
              navigate('/registerLawyer', {
                state: {
                  isSentFromLawyerPage: false,
                },
              })
            }
          >
            <a
              href={`${process.env.REACT_APP_DNS_URI}/registerLawyer`}
              style={{ color: 'white ' }}
              onClick={(e) => e.stopPropagation()}
            >
              {LPTranslate('Button_Sign_Up')}
            </a>
          </Button>
        </div>
      </div>

      <div className="article-section">
        <HomePageArticleSection
          hideIfNoArticles={true}
          changeContent={() => {
            navigate('/rechtsinfos');
          }}
          previousPage="Home"
        />
      </div>

      <div className="characteristics-container">
        <div className="characteristics-info">
          <h4 className="characteristics-info-pre-title">
            {LPTranslate('LPB_Features_Tag')}
          </h4>
          <h2>{LPTranslate('LPB_Features_Title')}</h2>
          <h3>{LPTranslate('LPB_Features_Description')}</h3>
        </div>

        <div className="mockup-container">
          <img src={HomePageImage1} alt="mockup" className="mockup-image" />
        </div>

        {/* <div className="characteristics">
          {characteristicsDummyData.map((value, index) => {
            return (
              <div key={index} className="value">
                <div className="image-container">
                  <img src={value.picture} alt="value1" className="image" />
                </div>
                <h2 className="value-title">{value.cardTitle}</h2>
                <h3 className="value-text">{value.cardText}</h3>

                <Button
                  className="button"
                  variant="contained"
                  disableRipple
                  endIcon={<ArrowForwardIcon />}
                  data-cy={'start-search-now'}
                >
                  {LPTranslate('LPB_Features_Box_Action_Button_1')}
                </Button>
              </div>
            );
          })}
        </div> */}
      </div>

      <div className="lawp-home-page-experts-in-your-area-section">
        <div className="left-container">
          <div className="component-card" style={{ alignItems: 'flex-start' }}>
            <div className="component-text-card" style={{ textAlign: 'left' }}>
              <h2 className="component-card-one-title">
                {LPTranslate('LPB_Top_Lawyers_Title')}
              </h2>

              <h3 className="component-card-one-text-field">
                {LPTranslate('LPB_Top_Lawyers_Description')}
              </h3>
            </div>
          </div>

          <div className="buttons-container">
            <Button
              className="faq-button"
              onClick={() => navigate('/faq')}
              data-cy="faq-button"
            >
              <a
                href={`${process.env.REACT_APP_DNS_URI}/faq`}
                style={{ color: '#344054' }}
                onClick={(e) => e.stopPropagation()}
              >
                {LPTranslate('LPB_Top_Lawyers_Action_Button_1')}
              </a>
            </Button>

            <Button
              className="lawyer-button"
              data-cy="start-search"
              onClick={() => window.scrollTo(0, 0)}
            >
              {LPTranslate('LPB_Top_Lawyers_Action_Button_2')}
            </Button>
          </div>
        </div>

        <div className="right-container">
          <img src={imagesGroup} alt="placeholder" className="images-group" />
        </div>
      </div>

      <div className="review-section">
        <img src={reviewImage} alt="placeholder" className="review-image" />

        <div className="content">
          <RatingStars
            value={5}
            width={20}
            height={20}
            readonly={true}
            colorFilter={
              'invert(91%) sepia(69%) saturate(1377%) hue-rotate(317deg) brightness(99%) contrast(102%)'
            }
          />

          <h2 className="review-text">
            {LPTranslate('LPB_Functions_Title_3')}
          </h2>
          <h3 className="name">R.S.</h3>
        </div>
      </div>

      <div
        style={{
          width: '90%',
        }}
      >
        <PartOfCommunityMockupSection />
      </div>
    </div>
  );
};

export default HomePage;
