import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';

const CustomTextField = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? 'white' : '#2b2b2b',
    border: '1px solid #D0D5DD',
    fontSize: 16,
    height: 24,
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    fontFamily: ['Inter'].join(','),
  },
}));

export default function CustomizedInputs(props) {
  const [value, setValue] = useState('');
  const {
    title,
    textFieldWidthPercent,
    multiline,
    rows,
    maxLength,
    placeholder,
    data_cy,
  } = props;

  const widthTextField =
    textFieldWidthPercent !== undefined ? `${textFieldWidthPercent}%` : 300;

  const handleChange = (event) => {
    props.onChange(event.target.value);
  };

  useEffect(() => {
    if (props.value !== undefined) {
      setValue(props.value);
    }
  }, [props.value]);

  return (
    <div
      style={{
        display: 'block',
        width: widthTextField,
      }}
    >
      <p
        style={{
          fontFamily: 'Inter',
          fontWeight: 500,
          fontSize: 14,
          color: '#344054',
        }}
      >
        {' '}
        {title}{' '}
      </p>
      <FormControl
        sx={{ width: '100%', marginTop: '-12px' }}
        variant="standard"
      >
        <CustomTextField
          data-cy={data_cy}
          onChange={handleChange}
          multiline={multiline ? multiline : false}
          rows={rows ? rows : 1}
          value={value}
          inputProps={{ maxLength: maxLength ? maxLength : undefined }}
          placeholder={placeholder !== undefined ? placeholder : ''}
        />
      </FormControl>
      {maxLength !== undefined && props.value !== null && (
        <p
          style={{
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '20px',
            color: '#667085',
          }}
        >{`${maxLength - props.value.length} characters left`}</p>
      )}
    </div>
  );
}
