import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { ASK_LAWYER_TO_SET_SCHEDULE_VIA_EMAIl } from '../../Services/Queries/user';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/lawyer/SendMessageToLawyerNotLoggedModal.module.scss';

import RequestAppointmentMiniRegisterModal from './RequestAppointmentMiniRegisterModal';
import RequestAppointmentModal from './RequestAppointmentModal';

const AskLawyerToSetScheduleModal = (props) => {
  let {
    snackbarSeverity,
    snackbarOpen,
    snackbarMessage,
    profileImage,
    title,
    first_name,
    last_name,
    email,
    subscription,
  } = props;

  const [shownModal, setShownModal] = useState(0);

  const [askLawyerToSetSchedule, { data: askLawyer }] = useMutation(
    ASK_LAWYER_TO_SET_SCHEDULE_VIA_EMAIl
  );

  const clientInfoRedux = useSelector((state) => state.clientInfo.value);
  const [showMiniregister, setShowMiniregister] = useState(false);

  const handleClose = () => {
    props.handleOpenModal();
    setShownModal(0);
    if (showMiniregister) setShowMiniregister(false);
  };

  const handleCloseModal = (modalNr) => {
    setShownModal(modalNr);
  };

  const sendMessage = async (e) => {
    e.preventDefault();

    if (clientInfoRedux.role === '') {
      setShowMiniregister(true);
      return;
    }

    try {
      if (clientInfoRedux.token) {
        await askLawyerToSetSchedule({
          variables: {
            input: {
              token: clientInfoRedux.token,
              lawyer_name: first_name + ' ' + last_name,
              client_name: clientInfoRedux.name,
              client_email: clientInfoRedux.email,
              lawyer_email: email,
            },
          },
        });
      } else {
        props.handleSnackbarOpen();
        props.handleSnackbarSeverity('error');
        props.handleSnackbarMessage(LPTranslate('Error_User_Not_Logged_In'));
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (
      askLawyer &&
      askLawyer.askLawyerToSetSchedule.message === 'message arrived!'
    ) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'client-request-appointment-sent-custom-event',
      });
      setShownModal(1);
    }
  }, [askLawyer]);

  return (
    <>
      {!showMiniregister ? (
        <RequestAppointmentModal
          snackbarSeverity={snackbarSeverity}
          snackbarOpen={snackbarOpen}
          snackbarMessage={snackbarMessage}
          profileImage={profileImage}
          title={title}
          first_name={first_name}
          last_name={last_name}
          email={email}
          shownModal={shownModal}
          handleClose={handleClose}
          sendMessage={sendMessage}
          openModal={props.openModal}
          subscription={subscription}
        />
      ) : (
        <Modal
          className={styles['send-message-to-lawyer-not-logged-modal']}
          open={showMiniregister}
          onClose={handleClose}
        >
          <Box>
            <RequestAppointmentMiniRegisterModal
              showMiniregister={showMiniregister}
              setShowMiniregister={setShowMiniregister}
              profileImage={profileImage}
              shownModal={shownModal}
              setShownModal={setShownModal}
              first_name={first_name}
              last_name={last_name}
              email={email}
              handleModal={handleCloseModal}
              handleClose={handleClose}
            />
          </Box>
        </Modal>
      )}
    </>
  );
};

export default AskLawyerToSetScheduleModal;
