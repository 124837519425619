import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';
// import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import SimpleTextField from 'Components/Inputs/SimpleTextField';

import commonStyles from 'Assets/styles/forgotPassword/ForgotPasswordCommon.module.scss';
import styles from 'Assets/styles/forgotPassword/NewPassword.module.scss';
import key from 'Assets/pictures/keyIcon.svg';
// import arrowLeft from 'Assets/pictures/arrow-left.svg';
import { resetClientInfo } from 'Services/Redux/reducers/clientInfo';
import { CHANGE_USER_PASSWORD_WITH_TOKEN } from 'Services/Queries/user.js';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { validatePassword } from 'Services/Utils/validations';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NewPassword = (props) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [changeUserPasswordWithToken, { data }] = useMutation(
    CHANGE_USER_PASSWORD_WITH_TOKEN
  );

  const [formValues, setFormValues] = useState({
    password: '',
    repeatPassword: '',
  });
  const [formErrors, setFormErrors] = useState({
    password: '',
    repeatPassword: '',
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
  const [snackbarMessage, setSnackbarMessage] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const resetState = () => {
    setFormValues({ password: '', repeatPassword: '' });
    setFormErrors({ password: '', repeatPassword: '' });
  };

  const handleInputChange = (inputName, value) => {
    switch (inputName) {
      case 'password':
        setFormValues({ ...formValues, password: value });
        break;
      case 'repeatPassword':
        setFormValues({ ...formValues, repeatPassword: value });
        break;

      default:
        break;
    }
  };

  const handleChangePassword = async (event) => {
    event.preventDefault();
    let isError = false;
    let newInputErrors = { password: '', repeatPassword: '' };

    if (formValues.password.length === 0) {
      isError = true;
      newInputErrors.password = 'Passwort erforderlich*';
    } else if (formValues.password.length < 8) {
      isError = true;
      newInputErrors.password = 'Muss mindestens aus 8 Zeichen bestehen*';
    }
    if (formValues.repeatPassword.length === 0) {
      isError = true;
      newInputErrors.repeatPassword = 'Passwortbestätigung erforderlich*';
    } else if (formValues.password !== formValues.repeatPassword) {
      isError = true;
      newInputErrors.repeatPassword = 'Passwörter stimmen nicht überein*';
    }

    if (!validatePassword(formValues.password)) {
      isError = true;
      newInputErrors.password = LPTranslate(
        'Error_Password_Invalid_Requirements'
      );
    }

    setFormErrors(newInputErrors);

    if (isError) return;

    try {
      await changeUserPasswordWithToken({
        variables: {
          input: {
            token: props.forgotPasswordToken,
            newPassword: formValues.password,
          },
        },
      });
    } catch (error) { }
  };

  useEffect(() => {
    if (data && data.changeUserPasswordWithToken) {
      if (data.changeUserPasswordWithToken.message === 'Password updated') {
        resetState();
        sessionStorage.clear();
        dispatch(resetClientInfo());
        props.handleNext(2);
      } else if (data.changeUserPasswordWithToken.error.length > 0) {
        setSnackbarMessage(LPTranslate('Error_Server_Down'));
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={commonStyles['forgot-password-page-container']}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={
            snackbarSeverity === 'error' && {
              backgroundColor: '#7f56d9 !important',
            }
          }
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <div
        className={`${commonStyles['forgot-password-box-container']} 
          ${styles['new-password-box-container']}`}
      >
        <div
          className={`${commonStyles['forgot-password-header']} 
            ${styles['new-password-header']}`}
        >
          <div className={commonStyles['forgot-password-header-icon']}>
            <img src={key} alt="Key Icon" />
          </div>
          <div
            className={`${commonStyles['forgot-password-header-text-support-container']} 
          ${styles['new-password-header-text-support']}`}
          >
            <div
              className={`${commonStyles['forgot-password-header-text']} 
          ${styles['new-password-header-text']}`}
            >
              {LPTranslate('FP_Page_3_Title')}
            </div>
            <div
              className={`${commonStyles['forgot-password-header-support']} 
          ${styles['new-password-header-support']}`}
            >
              {LPTranslate('FP_Page_3_Description')}
            </div>
          </div>
        </div>

        <form
          className={`${commonStyles['forgot-password-content']} `}
          onSubmit={async (e) => await handleChangePassword(e)}
        >
          <div
            className={`${commonStyles['forgot-password-form-container']} 
          ${styles['new-password-margin-bot']}`}
          >
            <div className={`${commonStyles['forgot-password-form']} `}>
              <div
                className={`${commonStyles['forgot-password-input-label-container']}`}
              >
                <SimpleTextField
                  data_cy="password-input"
                  title={LPTranslate('Input_Password')}
                  placeholder="••••••••"
                  onChange={(value) => {
                    handleInputChange('password', value);
                    setFormErrors({ ...formErrors, password: '' });
                  }}
                  value={formValues.password}
                  textFieldWidthPercent={100}
                  type="password"
                  errorMessage={formErrors.password}
                />
              </div>
            </div>
          </div>
          <div className={`${commonStyles['forgot-password-form-container']} `}>
            <div className={`${commonStyles['forgot-password-form']} `}>
              <div
                className={`${commonStyles['forgot-password-input-label-container']} `}
              >
                <SimpleTextField
                  data_cy="repeat-password-input"
                  title="Passwort wiederholen*"
                  placeholder="••••••••"
                  onChange={(value) => {
                    handleInputChange('repeatPassword', value);
                    setFormErrors({ ...formErrors, repeatPassword: '' });
                  }}
                  value={formValues.repeatPassword}
                  textFieldWidthPercent={100}
                  type="password"
                  errorMessage={formErrors.repeatPassword}
                />
              </div>
            </div>
          </div>
          <div
            className={`${commonStyles['forgot-password-form-button-container']} 
        ${styles['new-password-action-button-margin-top']}`}
          >
            <button
              data-cy="submit-button"
              className={commonStyles['forgot-password-form-button']}
              type="submit"
            >
              {LPTranslate('Button_Reset_Password')}
            </button>
          </div>
        </form>

        {/* <div
          data-cy="back-button"
          className={commonStyles['forgot-password-back-button-text-container']}
          onClick={() => navigate('/')}
        >
          <div className={commonStyles['forgot-password-back-button']}>
            <img src={arrowLeft} alt="arrow left" />
          </div>
          <div className={commonStyles['forgot-password-back-text']}>
            {LPTranslate('Common_Back_To_Login')}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default NewPassword;
