import React from 'react';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { useSelector } from 'react-redux';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import avatar from 'Assets/pictures/AvatarProfile.png';

import styles from 'Assets/styles/client/AskLawyerToSetScheduleModal.module.scss';
import UpdatePasswordConfirmationModal from './UpdatePasswordConfirmationModal';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RequestAppointmentModal = (props) => {
  const {
    openModal,
    first_name,
    last_name,
    shownModal,
    snackbarOpen,
    snackbarSeverity,
    snackbarMessage,
    profileImage,
    title,
    email,
    subscription,
  } = props;
  const { handleClose, sendMessage } = props;

  const clientInfoRedux = useSelector((state) => state.clientInfo.value);

  return (
    <Modal
      className={styles['ask-lawyer-to-set-schedule-modal']}
      open={openModal}
      onClose={handleClose}
    >
      <Box className={styles['ask-lawyer-to-set-schedule-modal-container']}>
        {shownModal === 0 ? (
          <>
            <div className={styles['ask-lawyer-to-set-schedule-modal-content']}>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                onClose={props.handleCloseSnackbar}
              >
                <Alert
                  onClose={props.handleCloseSnackbar}
                  severity={snackbarSeverity}
                  sx={
                    snackbarSeverity === 'error' && {
                      backgroundColor: '#7f56d9 !important',
                    }
                  }
                >
                  {snackbarMessage}
                </Alert>
              </Snackbar>
              <div
                className={
                  styles['ask-lawyer-to-set-schedule-modal-avatar-label-group']
                }
              >
                {profileImage !== null ? (
                  <img
                    alt="profile"
                    src={profileImage}
                    className={
                      styles['ask-lawyer-to-set-schedule-modal-profile-image']
                    }
                  />
                ) : (
                  <img
                    alt="avatar"
                    src={avatar}
                    className={
                      styles['ask-lawyer-to-set-schedule-modal-profile-image']
                    }
                  />
                )}
                <div
                  className={
                    styles['ask-lawyer-to-set-schedule-modal-name-and-email']
                  }
                >
                  <div
                    className={styles['ask-lawyer-to-set-schedule-modal-name']}
                  >
                    {title && `${title} `}
                    {first_name} {last_name}
                  </div>

                  <div
                    className={styles['ask-lawyer-to-set-schedule-modal-email']}
                  >
                    {email}
                  </div>
                </div>
              </div>

              <div
                className={
                  styles[
                    'ask-lawyer-to-set-schedule-modal-text-and-supporting-text'
                  ]
                }
              >
                {subscription > 0 ? (
                  <>
                    <h2
                      className={
                        styles['ask-lawyer-to-set-schedule-modal-text']
                      }
                    >
                      {LPTranslate('USM_Title')} {first_name} {last_name}
                    </h2>

                    <h3
                      className={
                        styles[
                          'ask-lawyer-to-set-schedule-modal-supporting-text'
                        ]
                      }
                    >
                      {first_name + ' ' + last_name}{' '}
                      {LPTranslate(
                        'CSA_Ask_Lawyer_To_Set_Schedule_Description_1'
                      )}{' '}
                      {first_name} {last_name}{' '}
                      {LPTranslate(
                        'CSA_Ask_Lawyer_To_Set_Schedule_Description_2'
                      )}
                    </h3>
                  </>
                ) : (
                  <h3
                    className={
                      styles['ask-lawyer-to-set-schedule-modal-supporting-text']
                    }
                    style={{ padding: '0' }}
                  >
                    {LPTranslate(
                      'CSA_Request_Appointment_Miniregister_Description_Subscription_0_1'
                    ).replaceAll('<lawyer>', first_name + ' ' + last_name)}
                  </h3>
                )}
              </div>
            </div>

            {subscription > 0 ? (
              <div
                className={styles['ask-lawyer-to-set-schedule-modal-actions']}
              >
                <button
                  data-cy={'schedule-modal-cancel'}
                  className={
                    styles['ask-lawyer-to-set-schedule-modal-cancel-button']
                  }
                  onClick={handleClose}
                >
                  {LPTranslate('Button_Cancel')}
                </button>

                <button
                  data-cy={'schedule-modal-send'}
                  className={
                    styles['ask-lawyer-to-set-schedule-modal-success-button']
                  }
                  onClick={sendMessage}
                >
                  {LPTranslate('Button_Send_Request')}
                </button>
              </div>
            ) : (
              <div
                className={styles['ask-lawyer-to-set-schedule-modal-actions']}
              >
                <button
                  data-cy={'schedule-modal-send'}
                  className={
                    styles['ask-lawyer-to-set-schedule-modal-success-button']
                  }
                  onClick={handleClose}
                  style={{ width: '100%' }}
                >
                  {'Ok'}
                </button>
              </div>
            )}

            {subscription > 0 && clientInfoRedux.role !== 'client' && (
              <div className={styles['stepper-content']}>
                <div className={styles['step-icon-base']}>
                  <div className={styles['selected-icon-content']}>
                    <div className={styles['selected-dot']} />
                  </div>
                </div>

                <div className={styles['unselected-step-icon-base']}>
                  <div className={styles['unselected-icon-content']}>
                    <div className={styles['unselected-dot']} />
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <UpdatePasswordConfirmationModal
            handleClose={handleClose}
            titleMessage={LPTranslate(
              'CSA_Request_Appointment_Miniregister_Successful_Title'
            )}
            hintMessage={LPTranslate(
              'CSA_Create_Appointment_Message_Successful_Description'
            )}
            buttonText={LPTranslate('Button_Close_Modal')}
          />
        )}
      </Box>
    </Modal>
  );
};

export default RequestAppointmentModal;
