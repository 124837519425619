import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

const deleteAccountTextVariables = [
  {
    deleteTitle: LPTranslate('DA_Page_1_Title'),
    deleteDetails: LPTranslate('DA_Page_1_Description_1'),
    deleteSubDetails: LPTranslate('DA_Page_1_Description_2'),
    underButtonText: LPTranslate('DA_Page_2_Decline_Advantages_Button'),
    deleteTitle2: LPTranslate('DA_Page_2_Title'),
    deleteDetails2: LPTranslate('DA_Page_2_Advantage_Title'),
    Benefits1: LPTranslate('DA_Page_2_Advantage_1'),
    Benefits2: LPTranslate('DA_Page_2_Advantage_2'),
    Benefits3: LPTranslate('DA_Page_2_Advantage_3'),
    ButtonText: LPTranslate('DA_Page_2_Decline_Advantages_Button'),
    deleteTitle3:
      LPTranslate('DA_Page_3_Title_1') + ' ' + LPTranslate('DA_Page_3_Title_2'),
    deleteDetails3: LPTranslate('DA_Page_3_Description_1'),
    deleteSubDetails3: LPTranslate('DA_Page_3_Description_2'),
    keepAccountButton: LPTranslate('DA_Page_2_Decline_Delete_Acoount_Button'),
    keepAccountButtonState3: LPTranslate('DA_Page_3_Decline_Button'),
    deleteAccountButtonState3: LPTranslate('DA_Page_3_Accept_Button'),
    deleteModalTitle: LPTranslate('DA_Modal_Title'),
    deleteModalSubText: LPTranslate('DA_Modal_Description'),
    deleteModalSuccessButtonText: LPTranslate('DA_Modal_Success_Button'),
  },
];
export default deleteAccountTextVariables;
