const readingTime = (text) => {
  if (!text) return 0;

  const wpm = 225;
  const words = text.trim().split(/\s+/).length;
  const time = Math.ceil(words / wpm);

  return time;
};

export { readingTime };
