import React from 'react';

import 'Assets/styles/help/AboutUsAchievement.scss';

const AboutUsAchievement = (props) => {
  const { keyIndex, title, subtitle, description } = props;

  return (
    <div key={keyIndex} className="lawp-about-us-achievements-container">
      <h2 className="number">{title}</h2>
      <h2>{subtitle}</h2>
      <h3 className="description">{description}</h3>
    </div>
  );
};

export default AboutUsAchievement;
