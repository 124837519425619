import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import DeleteAccountCopmonent from 'Components/CommonComponents/DeleteAccount';
import DeleteModal from 'Components/Lawyer/DeleteComponents/DeleteModal';
import { removeTokenInfo } from 'Services/Utils/token';
import { resetClientInfo } from 'Services/Redux/reducers/clientInfo';

import { parseJwt } from 'Services/Utils/token';
import { DELETE_USER_LAWYER } from 'Services/Queries/user';
import deleteAccountTextVariables from 'Services/TextVariables/deleteAccountTextVariables';

const DeleteAccountPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.clientInfo.value);

  const [state, setState] = useState(1);
  const [deleteState, setDeleteState] = useState(false);
  const [eligible, setEligible] = useState(false);
  const [tokenValue, setTokenValue] = useState('');
  const [messageState, setMessageState] = useState('');
  const [deleteLawyer] = useMutation(DELETE_USER_LAWYER);

  const handleToken = (token) => {
    setMessageState(parseJwt(token).message);
    setTokenValue(token);
  };

  useEffect(() => {
    if (messageState === 'You are Eligible!') {
      setEligible(true);
      setState(state + 1);
    } else if (messageState === 'You are not Eligible!') {
      setEligible(false);
      setState(state + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageState]);

  const onBackButtonEvent = () => {
    props.setStepState(0);
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {
    removeTokenInfo();
    sessionStorage.clear();
    dispatch(resetClientInfo());
    navigate('/');
  };

  const onClick = () => {
    setState(state + 1);
  };

  const handleDelete = async () => {
    try {
      let deleteAccount = await deleteLawyer({
        variables: {
          email: userInfo.email,
          token: tokenValue,
          first_name: userInfo.first_name,
          last_name: userInfo.last_name,
        },
      });
      if (deleteAccount.data.deleteUser.message) {
        setDeleteState(true);
        setState(4);
      } else {
        alert(deleteAccount.data.deleteUser.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {state === 1 &&
        deleteAccountTextVariables?.length > 0 &&
        deleteAccountTextVariables?.map((row, index) => (
          <DeleteAccountCopmonent
            key={index}
            UserName={userInfo.name}
            email={userInfo.email}
            deleteTitle={row.deleteTitle}
            deleteDetails={row.deleteDetails}
            deleteSubDetails={row.deleteSubDetails}
            underButtonText={row.underButtonText}
            onClick={onClick}
            user_id={userInfo.user_id}
            state={state}
            keepAccountButton={row.keepAccountButton}
            eligible={eligible}
            handleToken={handleToken}
            onBackButtonEvent={onBackButtonEvent}
          />
        ))}

      {state === 2 &&
        eligible === true &&
        deleteAccountTextVariables?.length > 0 &&
        deleteAccountTextVariables?.map((row, index) => (
          <DeleteAccountCopmonent
            key={index}
            user_id={userInfo.user_id}
            UserName={userInfo.name}
            deleteTitle={row.deleteTitle2}
            deleteDetails={row.deleteDetails2}
            email={userInfo.email}
            benefits1={row.Benefits1}
            benefits2={row.Benefits2}
            benefits3={row.Benefits3}
            underButtonText={row.underButtonText}
            DeleteOnClick={handleDelete}
            state={state}
            keepAccountButton={row.keepAccountButton}
            eligible={eligible}
            onBackButtonEvent={onBackButtonEvent}
          />
        ))}

      {state === 2 &&
        eligible === false &&
        deleteAccountTextVariables?.length > 0 &&
        deleteAccountTextVariables?.map((row, index) => (
          <DeleteAccountCopmonent
            key={index}
            UserName={userInfo.name}
            deleteTitle={row.deleteTitle2}
            deleteDetails={row.deleteDetails2}
            email={userInfo.email}
            underButtonText={row.underButtonText}
            DeleteOnClick={handleDelete}
            benefits1={row.Benefits1}
            benefits2={row.Benefits2}
            benefits3={row.Benefits3}
            state={state}
            keepAccountButton={row.keepAccountButton}
            eligible={eligible}
            deleteState={deleteState}
            onBackButtonEvent={onBackButtonEvent}
          />
        ))}

      {state === 3 &&
        eligible === true &&
        deleteAccountTextVariables?.length > 0 &&
        deleteAccountTextVariables?.map((row, index) => (
          <DeleteAccountCopmonent
            key={index}
            UserName={userInfo.name}
            deleteTitle={row.deleteTitle3}
            deleteDetails={row.deleteDetails3}
            deleteComponentSubTitle={row.deleteDetails2}
            email={userInfo.email}
            underButtonText={row.underButtonText}
            deleteAccountButtonState3={row.deleteAccountButtonState3}
            benefits1={row.Benefits1}
            benefits2={row.Benefits2}
            benefits3={row.Benefits3}
            DeleteOnClick={handleDelete}
            state={state}
            deleteSubDetails={row.deleteSubDetails3}
            keepAccountButton={row.keepAccountButtonState3}
            eligible={eligible}
            deleteState={deleteState}
            token={tokenValue}
            first_name={userInfo.first_name}
            last_name={userInfo.last_name}
            onBackButtonEvent={onBackButtonEvent}
          />
        ))}
      {state === 4 &&
        deleteAccountTextVariables?.length > 0 &&
        deleteAccountTextVariables?.map((row) => (
          <DeleteModal
            userInfo={userInfo}
            deleteModalTitle={row.deleteModalTitle}
            deleteModalSubText={row.deleteModalSubText}
            deleteModalSuccessButtonText={row.deleteModalSuccessButtonText}
            closeModal={closeModal}
          />
        ))}
    </>
  );
};
export default DeleteAccountPage;
