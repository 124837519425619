const validateEmail = (email) => {
  // eslint-disable-next-line no-control-regex
  return /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test(
    email
  );
};

const validateWebsite = (website) => {
  // eslint-disable-next-line no-control-regex
  return /[(http(s)?):(www)?a-zA-Z0-9@:%._~#=]{2,256}[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/.test(
    website
  );
};

const validatePhoneNumber = (input_str) => {
  var re = /^\(?(\d{2})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

  return re.test(input_str);
};

const validatePassword = (password) => {
  // Minimum 8 and maximum 100 characters, at least one uppercase letter, one lowercase letter, one number and one special character:
  const password_regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=<>?/|{}[\]:;"',./~])[a-zA-Z0-9!@#$%^&*()_\-+=<>?/|{}[\]:;"',./~]{8,100}$/;
  return password_regex.test(password);
};

export {
  validateEmail,
  validateWebsite,
  validatePhoneNumber,
  validatePassword,
};
