import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import 'Assets/styles/client/UpcomingEventsAccordion.scss';

const AccordionSummary = withStyles({
  content: {
    flexGrow: 0,
  },
})(MuiAccordionSummary);

export default function SimpleAccordion(props) {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <div className="lawp-upcoming-events-accordion-container">
      <Accordion>
        <AccordionSummary
          data-cy="show-more-button"
          expandIcon={<ExpandMoreIcon style={{ color: '#6941c6' }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => setExpanded(!expanded)}
        >
          <p className="show-more-text">
            {expanded
              ? LPTranslate('LSA_Update_Appointment_Hide_Details_Button')
              : LPTranslate('LSA_Update_Appointment_Details_Button')}
          </p>
        </AccordionSummary>
        <AccordionDetails>
          <p className="title-text">{props.title}</p>
        </AccordionDetails>
        <AccordionDetails>
          <p className="description-text">{props.description}</p>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
