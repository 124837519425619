import React from 'react';

import Skeleton from '@mui/material/Skeleton';

import Checkbox from 'Components/Lawyer/FilterCheckBox';
import styles from 'Assets/styles/lawyer/FilterCheckBoxes.module.scss';

export default function Checkboxes(props) {
  const handleChange = (filter, subFilter) => {
    props.filterChange(filter, subFilter);
  };

  let data = Object.keys(props.boxes);

  return (
    <div>
      {data.map((key, value) => {
        return (
          <div key={value}>
            <div className={styles.checkboxContainer}>
              <Checkbox
                data_cy="checkbox-button"
                onChange={() => handleChange(props.filter, key)}
                checked={props.boxes[key].checked}
                available={props.boxes[key].available}
              />

              <div className={styles.checkboxText} variant="subtitle1">
                {/* <span>{key}</span> */}
                {props.boxes[key].label}
              </div>

              {props.lawyerLoading || props.lawyerError ? (
                <Skeleton
                  width="35px"
                  height="25px"
                  sx={{ ml: '10px', borderRadius: '20px' }}
                />
              ) : (
                props.boxes[key].resultsFound > 0 && (
                  <div className={styles.results}>
                    {props.boxes[key].resultsFound}
                  </div>
                )
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
