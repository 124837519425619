import React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { alpha } from '@material-ui/core/styles/colorManipulator';

import SimpleTextField from 'Components/Inputs/SimpleTextField.js';

import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import checkIcon from 'Assets/pictures/greenCheckIcon.svg';
import copyIcon from 'Assets/pictures/copyIcon.svg';

import styles from 'Assets/styles/lawyer/LawyerArticleGetLinkModal.module.scss';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 512,
  height: 338,

  bgcolor: 'background.paper',
  borderRadius: 3,
  boxShadow: 24,
  p: 3,
};

export default function BasicModal(props) {
  const handleClose = () => props.handleClose();

  return (
    <div>
      <Modal
        open={props.modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          '& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop': {
            backgroundColor: alpha('#344054', 0.6),

            backdropFilter: 'blur(16px)',
          },
        }}
      >
        <Box sx={style}>
          <div className={styles['container']}>
            <div className={styles['icon-container']}>
              <img
                src={checkIcon}
                alt="check"
                className={styles['check-icon']}
              ></img>
            </div>

            <div>
              <div className={styles['title-container']}>
                <span className={styles['title-text']}>
                  {LPTranslate('ULPP_Professional_Article_Add_Title')}
                </span>
              </div>

              <div className={styles['content-container']}>
                <span className={styles['content-text']}>
                  {LPTranslate('ULPP_Professional_Article_Add_Description')}
                </span>
              </div>
            </div>

            <div>
              <div className={styles['text-field-container']}>
                <SimpleTextField
                  title={LPTranslate('AAM_Created_Share_Link')}
                  onChange={() => {}}
                  value={`${process.env.REACT_APP_DNS_URI}/rechtsinfos/${props.link}`}
                  textFieldWidthPercent={90}
                  placeholder={'Bitte eintragen'}
                />
                <IconButton
                  data-cy="aam-copy-button"
                  className={styles['copy-button-container']}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${process.env.REACT_APP_DNS_URI}/rechtsinfos/${props.link}`
                    );
                  }}
                >
                  <img
                    src={copyIcon}
                    alt="copy"
                    className={styles['copy-icon']}
                  ></img>
                </IconButton>
              </div>
            </div>

            <Button
              data-cy="aam-done-button"
              className={styles['save-button']}
              // onClick={props.onSaveChanges} => there is no onSaveChanges function sent through props
              onClick={handleClose}
            >
              {LPTranslate('Button_Done')}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
