import React, { useState } from 'react';

import { useMutation } from '@apollo/client';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import useAlert from 'Services/Utils/hooks/useAlert';
import { VERIFY_OTP_CODE, GENERATE_OTP_CODE } from 'Services/Queries/user';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';

import styles from 'Assets/styles/registerLawyer/VerificationCode.module.scss';
import mailIcon from 'Assets/pictures/mailIcon.svg';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const VerificationCode = (props) => {
  const { setAlert } = useAlert();

  const { firstName, lastName, userEmail, roleEmail, hashedEmail, handleBack } =
    props;
  const [generateOtpCode] = useMutation(GENERATE_OTP_CODE);

  const [resendCodeDisabled, setResendCodeDisabled] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState(false);

  const [verifyOtpCode] = useMutation(VERIFY_OTP_CODE);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !e.target.number1.value ||
      !e.target.number2.value ||
      !e.target.number3.value ||
      !e.target.number4.value ||
      !e.target.number5.value ||
      !e.target.number6.value
    ) {
      setSnackbarMessage(LPTranslate('Error_OTP_Required'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);

      return;
    }

    try {
      let verifyCode = await verifyOtpCode({
        variables: {
          input: {
            firstName: firstName,
            lastName: lastName,
            email: userEmail,
            otpCode:
              e.target.number1.value +
              e.target.number2.value +
              e.target.number3.value +
              e.target.number4.value +
              e.target.number5.value +
              e.target.number6.value,
          },
        },
      });

      if (verifyCode.data.verifyOtpCode.message) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'lawyer-email-verified-code-custom-event',
        });
        props.handleNext(3);
      }
    } catch (error) {
      setSnackbarMessage(LPTranslate('Error_Server_Down'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const resendCodeOnEmail = async () => {
    try {
      setResendCodeDisabled(true);

      setTimeout(() => {
        setResendCodeDisabled(false);
      }, 5000);

      await generateOtpCode({
        variables: {
          input: {
            firstName: firstName,
            lastName: lastName,
            email: roleEmail,
          },
        },
      });

      setAlert(
        LPTranslate('Common_OTP_Code_Resent').replace(
          '*EMAIL_PLACEHOLDER*',
          roleEmail
        )
      );
    } catch (error) {
      setSnackbarMessage(LPTranslate('Error_Server_Down'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const inputfocus = (e) => {
    if (e.key === 'Delete' || e.key === 'Backspace' || e.key === 'ArrowLeft') {
      const next = e.target.tabIndex - 2;
      if (next > -1) {
        e.target.form.elements[next].setSelectionRange(
          e.target.form.elements[next].value.length,
          e.target.form.elements[next].value.length
        );
        e.target.form.elements[next].focus();
      }
    } else if (
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 96 && e.keyCode <= 105) ||
      e.key === 'ArrowRight'
    ) {
      const next = e.target.tabIndex;

      if (e.key.length === 1) e.target.value = e.key;

      if (next < 6) {
        e.target.form.elements[next].setSelectionRange(
          e.target.form.elements[next].value.length,
          e.target.form.elements[next].value.length
        );
        e.target.form.elements[next].focus();
      }
    }
  };

  const focusAtTheEnd = (e, index) => {
    e.target.setSelectionRange(e.target.value.length, e.target.value.length);
    e.target.focus();
  };

  const handleEnter = (e) => {
    const regex = /[0-9/]+/;

    if (!regex.test(e.key)) e.preventDefault();
  };

  return (
    <>
      <div className={styles.verificationCodeSection}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={
              snackbarSeverity === 'error' && {
                backgroundColor: '#7f56d9 !important',
              }
            }
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <div className={styles.verificationCodeContainer}>
          <div className={styles.frame2}>
            <div className={styles.content}>
              <div className={styles.header}>
                <div className={styles.featuredIcon}>
                  <div className={styles.mail}>
                    <img src={mailIcon} alt="key" />
                  </div>
                </div>

                <div className={styles.textAndSupportingText}>
                  <p className={styles.text}>
                    {LPTranslate('Common_Check_Email_Address')}
                  </p>

                  <p className={styles.supportingText}>
                    {LPTranslate('RL_Page_3_Description_1')} {hashedEmail}{' '}
                    {LPTranslate('RL_Page_3_Description_2')}
                  </p>
                </div>
              </div>

              <div className={styles.verificationCodeInputField}>
                <div className={styles.inputWithLabel}>
                  <form
                    className={styles.inputContent}
                    id="verify-form"
                    onSubmit={handleSubmit}
                  >
                    {Array(6)
                      .fill('')
                      .map((_, index) => (
                        <>
                          <div
                            key={index}
                            className={styles.megaInputFieldBase}
                          >
                            <input
                              data-cy="otp-input"
                              className={styles.verifyInput}
                              onKeyPress={(e) => handleEnter(e)}
                              name={`${'number' + Number(index + 1)}`}
                              defaultValue=""
                              placeholder="0"
                              maxLength="1"
                              tabIndex={index + 1}
                              onClick={(e) => focusAtTheEnd(e, index)}
                              onKeyUp={(e) => inputfocus(e)}
                              autoFocus={index === 0 ? true : false}
                              type="tel"
                              autoComplete="off"
                            ></input>
                          </div>

                          {index === 2 && (
                            <p className={styles.lineBetween}>-</p>
                          )}
                        </>
                      ))}
                  </form>
                </div>
              </div>

              <div className={styles.verifyCodeButtonContainer}>
                <div className={styles.verifyCodeButton}>
                  <button
                    data-cy="submit-button"
                    className={styles.verifyCodeButtonBase}
                    type="submit"
                    form="verify-form"
                  >
                    <p className={styles.verifyCodeButtonText}>
                      {LPTranslate('Common_Verify_Email_Address')}
                    </p>
                  </button>
                </div>
              </div>

              <div className={styles.resendCodeButtonContainer}>
                <p className={styles.resendText}>
                  {LPTranslate('Common_No_Email_Received')}
                </p>
                <div className={styles.resendCodeButton}>
                  <button
                    data-cy="resend-code-button"
                    className={styles.resendCodeButtonBase}
                    onClick={resendCodeOnEmail}
                    disabled={resendCodeDisabled}
                  >
                    <p
                      className={
                        resendCodeDisabled
                          ? `${styles.resendCodeButtonText} ${styles.resendCodeButtonTextDisabled}`
                          : styles.resendCodeButtonText
                      }
                    >
                      {LPTranslate('Common_Resend_Email')}
                    </p>
                  </button>
                </div>
              </div>

              <div className={styles.loginButtonContainer}>
                <button
                  data-cy="back-button"
                  className={styles.loginButtonBase}
                  onClick={() => handleBack(1)}
                >
                  <ArrowBackIcon sx={{ color: '#667085' }} />
                  <p className={styles.loginButtonText}>
                    {LPTranslate('Common_Back_To_Login')}
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerificationCode;
