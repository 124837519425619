import React, { useEffect } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import AddLawyerArticleModal from 'Components/Lawyer/Edit/AddLawyerArticleModal';
import LawyerArticleGetLinkModal from 'Components/Lawyer/Edit/LawyerArticleGetLinkModal';
import EditArticleCard from 'Components/Lawyer/Edit/EditArticleCard';

import { GET_ARTICLES_OF_LAWYER } from 'Services/Queries/lawyer';
import {
  UPDATE_ARTICLE,
  DELETE_ARTICLE,
  ADD_ARTICLE,
} from 'Services/Queries/lawyer';
import { LPTranslate } from 'Services/Utils/LPTranslate/translate';
import { createUrlSlug } from 'Services/Utils/metaData';

import PlusIcon from 'Assets/pictures/plusIcon.svg';
import styles from 'Assets/styles/lawyer/EditLawyerArticleSection.module.scss';

const NR_ARTICLES_PER_PAGE = 3;

const EditLawyerArticleSection = (props) => {
  const { userId, profileImage } = props;

  const [page, setPage] = React.useState(1);

  const [addLawyerModalOpen, setAddLawyerModalOpen] = React.useState(false);
  const [editLawyerModalOpen, setEditLawyerModalOpen] = React.useState(false);
  const [getLinkModalOpen, setGetLinkModalOpen] = React.useState(false);
  const [articleLink, setArticleLink] = React.useState('');

  const [articles, setArticles] = React.useState([]);
  const [newArticle, setNewArticle] = React.useState({
    id: null,
    user_id: null,
    coverImage: null,
    title: '',
    description: '',
    content: '',
    areasOfLaw: [],
  });

  const [editArticle, setEditArticle] = React.useState({
    id: null,
    user_id: null,
    coverImage: null,
    title: '',
    description: '',
    content: '',
    areasOfLaw: [],
  });

  const [inputErrorsNewArticle, setInputErrorsNewArticle] = React.useState({
    coverImage: '',
    title: '',
    description: '',
    content: '',
    areasOfLaw: '',
  });

  const [inputErrorsEditArticle, setInputErrorsEditArticle] = React.useState({
    coverImage: '',
    title: '',
    description: '',
    content: '',
    areasOfLaw: '',
  });

  const [lawyerArticles, { loading, error, data }] = useLazyQuery(
    GET_ARTICLES_OF_LAWYER
  );

  const [addArticle] = useMutation(ADD_ARTICLE);
  const [updateArticle] = useMutation(UPDATE_ARTICLE);
  const [deleteArticle] = useMutation(DELETE_ARTICLE);

  const fetchArticles = React.useCallback(() => {
    lawyerArticles({
      variables: {
        input: {
          user_id: userId,
          limit: NR_ARTICLES_PER_PAGE,
          page: page,
        },
      },
    });
  }, [lawyerArticles, userId, page]);

  useEffect(() => {
    fetchArticles();
  }, [fetchArticles]);

  useEffect(() => {
    if (!loading && data !== undefined) {
      let articleDto = data.articlesOfLawyer.articles;

      let articlesData = [];

      for (let i = 0; i < articleDto.length; i++) {
        articlesData.push({
          id: articleDto[i].id,
          user_id: articleDto[i].user_id,
          author_name: articleDto[i].author_name,
          coverImage: articleDto[i].cover_image,
          title: articleDto[i].title,
          description: articleDto[i].description,
          content: articleDto[i].content,
          areasOfLaw: articleDto[i].areas_of_law,
          link: articleDto[i].link,
          createdAt: articleDto[i].created_at,
          updatedAt: articleDto[i].updated_at,
        });
      }

      setArticles(articlesData);
    }
  }, [data, loading]);

  useEffect(() => {
    fetchArticles();
  }, [page, fetchArticles]);

  const handleArticlesNavigationButtons = (articleIndexModifier) => {
    if (page === 1 && articleIndexModifier === -1) return;

    if (
      page === data.articlesOfLawyer.number_of_pages &&
      articleIndexModifier === 1
    )
      return;

    setPage((prev) => prev + articleIndexModifier);
  };

  const onChangeArticle = (index, value) => {
    let article = { ...newArticle };
    let errorMessages = { ...inputErrorsNewArticle };

    article[index] = value;

    if (article.coverImage !== null) errorMessages.coverImage = '';
    if (article.title.length > 0) errorMessages.title = '';
    if (article.description.length > 0) errorMessages.description = '';
    if (article.content.length > 0) errorMessages.content = '';
    if (article.areasOfLaw.length > 0) errorMessages.areasOfLaw = '';

    setNewArticle(article);
    setInputErrorsNewArticle(errorMessages);
  };

  const onUpdateArticle = (index, value) => {
    let newEditArticle = { ...editArticle };
    let errorMessages = { ...inputErrorsEditArticle };

    newEditArticle[index] = value;

    if (newEditArticle.coverImage !== null) errorMessages.coverImage = '';
    if (newEditArticle.title.length > 0) errorMessages.title = '';
    if (newEditArticle.description.length > 0) errorMessages.description = '';
    if (newEditArticle.content.length > 0) errorMessages.content = '';
    if (newEditArticle.areasOfLaw.length > 0) errorMessages.areasOfLaw = '';

    setEditArticle(newEditArticle);
    setInputErrorsEditArticle(errorMessages);
  };

  const createArticleHandler = async () => {
    try {
      let isError = false;
      let errorMessages = { ...inputErrorsNewArticle };

      if (newArticle.coverImage === null) {
        errorMessages.coverImage = 'Cover image is required';
        isError = true;
      }

      if (newArticle.title.length === 0) {
        errorMessages.title = 'Title is required';
        isError = true;
      }

      if (newArticle.description.length === 0) {
        errorMessages.description = 'Description is required';
        isError = true;
      }

      if (newArticle.content.length === 0) {
        errorMessages.content = 'Content is required';
        isError = true;
      }

      if (newArticle.areasOfLaw.length === 0) {
        errorMessages.areasOfLaw = 'Areas of law is required';
        isError = true;
      }

      if (isError) {
        setInputErrorsNewArticle(errorMessages);
        return;
      }

      let articleData = {
        title: newArticle.title.trim(),
        description: newArticle.description.trim(),
        content: newArticle.content.trim(),
        areas_of_law: newArticle.areasOfLaw,
        cover_image: newArticle.coverImage,
      };

      setAddLawyerModalOpen(false);

      let response = await addArticle({ variables: { input: articleData } });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'lawyer-create-article-custom-event',
      });
      fetchArticles();
      setGetLinkModalOpen(true);
      let slug = createUrlSlug(
        '',
        response.data.addArticle.title,
        response.data.addArticle.author_name,
        ''
      );

      slug += `-${response.data.addArticle.id}`;

      setArticleLink(slug);
    } catch (e) {
      console.log(e);
    }
  };

  const handleEdit = (articleId) => {
    setEditLawyerModalOpen(true);
    for (let i = 0; i < articles.length; i++) {
      if (articles[i].id === articleId) {
        setEditArticle(articles[i]);
        break;
      }
    }
  };

  const updateArticleHandler = async () => {
    try {
      let isError = false;
      let errorMessages = { ...inputErrorsEditArticle };

      if (editArticle.coverImage === null) {
        errorMessages.coverImage = 'Cover image is required';
        isError = true;
      }

      if (editArticle.title.length === 0) {
        errorMessages.title = 'Title is required';
        isError = true;
      }

      if (editArticle.description.length === 0) {
        errorMessages.description = 'Description is required';
        isError = true;
      }

      if (editArticle.content.length === 0) {
        errorMessages.content = 'Content is required';
        isError = true;
      }

      if (editArticle.areasOfLaw.length === 0) {
        errorMessages.areasOfLaw = 'Areas of law is required';
        isError = true;
      }

      if (isError) {
        setInputErrorsEditArticle(errorMessages);
        return;
      }

      let input = {
        id: editArticle.id,
        title: editArticle.title.trim(),
        description: editArticle.description.trim(),
        content: editArticle.content.trim(),
        areas_of_law: editArticle.areasOfLaw,
        cover_image: editArticle.coverImage,
      };

      setEditLawyerModalOpen(false);

      await updateArticle({ variables: { input: input } });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteArticleHandler = async (id) => {
    try {
      await deleteArticle({ variables: { input: id } });
      setPage(1);
      fetchArticles();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {loading || error ? (
        <>
          <div className={styles.loadingContainer}>
            <CircularProgress color="secondary" />
          </div>
        </>
      ) : (
        <div className={styles.container}>
          <AddLawyerArticleModal
            modalOpen={addLawyerModalOpen}
            handleClose={() => setAddLawyerModalOpen(false)}
            operation="create"
            article={newArticle}
            onChange={onChangeArticle}
            onCreate={createArticleHandler}
            onImageUpdate={(image) => onChangeArticle('coverImage', image)}
            areasOfLaw={props.areasOfLawDB}
            inputErrors={inputErrorsNewArticle}
          />

          <AddLawyerArticleModal
            modalOpen={editLawyerModalOpen}
            handleClose={() => setEditLawyerModalOpen(false)}
            operation="edit"
            article={editArticle}
            onChange={onUpdateArticle}
            onCreate={updateArticleHandler}
            onImageUpdate={(image) => onUpdateArticle('coverImage', image)}
            areasOfLaw={props.areasOfLawDB}
            inputErrors={inputErrorsEditArticle}
          />

          <LawyerArticleGetLinkModal
            modalOpen={getLinkModalOpen}
            handleClose={() => setGetLinkModalOpen(false)}
            link={articleLink}
          />

          <button
            data-cy="professional_article_button"
            onClick={() => setAddLawyerModalOpen(true)}
            className={styles.CreateArticleButton}
          >
            <img src={PlusIcon} alt="plusIcon" className={styles.PlusIcon} />
            <span className={styles.CreateArticleButtonText}>
              {LPTranslate('ULPP_Professional_Article_Add_Title')}
            </span>
          </button>

          {articles.length > 0 && (
            <>
              <div className={styles.ArticlesContainer}>
                {articles.length > 0 &&
                  articles.map((article, index) => {
                    return (
                      <EditArticleCard
                        key={index}
                        article={article}
                        handleDelete={(articleId) =>
                          deleteArticleHandler(articleId)
                        }
                        handleEdit={(articleId) => handleEdit(articleId)}
                        authorProfileImage={profileImage}
                        previousPage={'LawyerSettings'}
                      />
                    );
                  })}

                {articles.length === 0 && (
                  <p>{LPTranslate('ULPP_Professional_Article_Empty_List')}</p>
                )}
              </div>

              {data && data.articlesOfLawyer.number_of_pages > 1 && (
                <>
                  <div className={styles.divider} />

                  <div className={styles.NavigationContainer}>
                    <span className={styles.PaginationText}>
                      {LPTranslate('ULPP_Professional_Article_Page_Title1')}
                      <span
                        className={styles.PaginationTextBlack}
                      >{` ${page} `}</span>
                      {LPTranslate('ULPP_Professional_Article_Page_Title2')}
                      <span className={styles.PaginationTextBlack}>{` ${data && data.articlesOfLawyer.number_of_pages
                        } `}</span>
                    </span>

                    <div className={styles.NavigationButtons}>
                      <Button
                        data-cy="previous-button"
                        className={styles.NavigationButton}
                        onClick={() => handleArticlesNavigationButtons(-1)}
                      >
                        {LPTranslate('Button_Previous')}
                      </Button>
                      <Button
                        data-cy="next-button"
                        className={styles.NavigationButton}
                        onClick={() => handleArticlesNavigationButtons(1)}
                      >
                        {LPTranslate('Button_Next')}
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default EditLawyerArticleSection;
